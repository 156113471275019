import {
	IUserEditFormRequest, IUserEditFormResponse,
	UpdateNamePayload,
	UpdateUserDataPayload,
	UpdateUserResponsePayload,
	User,
	UserAccountData,
	UserListResponse,
} from "../../../../types";
import { Client } from "../../Client";

export const UsersService = {
	me: (token?: string) =>
		Client.get<User>(
			"/users/me",
			token
				? {
						headers: { Authorization: `Bearer ${token}` },
				  }
				: {}
		),
	get: (userId: string) => Client.get<User>(`/users/${userId}`),

	list: (scopes?: string[]) => Client.get<UserListResponse>(`/users?scopes=${scopes?.join(",")}`),
	getAccountData: (id: string) => Client.get(`/account/${id}`),
	updateAccount: ({ id, payload }: UpdateUserResponsePayload) =>
		Client.put<UserAccountData>(`/account/${id}`, {
			...payload,
		}),
	update: (id: string, data: UpdateUserDataPayload) => Client.put<boolean>(`/user/setUserData/${id}`, data),
	updateName: (accountId: string, payload: UpdateNamePayload) =>
		Client.put<UserAccountData>(`/account/name/${accountId}`, payload),
	updatePhoto: (accountId: string, data: { avatar: string }) =>
		Client.put<UserAccountData>(`/account/photo/${accountId}`, data),
	delete: (id: string) => Client.delete(`/users/dismissUser/${id}`),

	updateUser: (id: string, payload: IUserEditFormRequest) => Client.put(`/users/editForm/${id}`, payload),
	getUser: (id: string) => Client.get<IUserEditFormResponse>(`/users/editForm/${id}`),
	changeOffice: (id: string, officeId: string) => Client.put(`/users/${id}/office/${officeId}`),
	fire: (id: string,  newResponsibleUserId: string) => Client.post(`/users/dismiss/${id}`, {newResponsibleUserId}),
	setCurrentOffice: (officeId: string) => Client.post(`/users/setCurrentOffice/${officeId}`),
	deletefromDepartment: (userId: string, departmentId: string) => Client.patch(`/users/${userId}/removeFromDepartment/${departmentId}`)
};
