import { RealEstateFilter, SellBusinessProcessEnum } from "../../../../types";

export const DEFAULT_FILTER: RealEstateFilter = {
	responsibleUsers: {
		userId: [],
	},
	feedAds: [{
		feedId: [],
		status: "Ошибка",
		endDate: "",
	}],
	type: {
		value: SellBusinessProcessEnum.sale,
	},
	category: [],
	address: {
		label: "",
		value: "",
		location: undefined,
	},
};
