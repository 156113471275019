import { ShowsViews } from "../../../../../types";
import { InfoRow, TransparentInput } from "../../../../UI";
import { createNumberMask } from "text-mask-addons";
import { Control, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import styles from "./index.module.scss"
import { useLayoutEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { Tooltip } from "@sdelka_crm/sdelka-crm-component-library";
import { ReactComponent as InfoIcon } from "../../../../../assets/icons/iconInfo.svg";

type Props = {
  isViews: boolean;
  item: ShowsViews;
  register: UseFormRegister<any>;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  inputName: string;
}

export const CardFeedback = (
  {
    isViews,
    item,
    setValue,
    control,
    register,
    inputName,
  }: Props): JSX.Element => {
  const {
    offer,
    status
  } = item

  const [offerValue, setOfferValue] = useState<number | undefined>(offer)

  const handleOnChange = (value) => {
    setOfferValue(value)
  }

  useLayoutEffect(() => {
    setValue("offer", offer)
  }, [offer, setValue])

  const debounced = useDebouncedCallback(handleOnChange, 100);

  const inputValue = useWatch({
    control,
    name: inputName,
    defaultValue: offerValue,
  });

  const handleOnChangeWithDebounce = (value: number | string) => {
    debounced(value);
  };

  return (
    <div className={styles.column}>
      <form>
        <InfoRow
          label={<>
            <span> {`Предложение ${isViews ? "покупателя" : "продавца"}`}</span>
            <Tooltip
              classNames={{
                root: styles.tooltipDescription,
                card: styles.tooltipCard,
              }}
              iconSize="medium"
              CustomIcon={InfoIcon}
            >
              {`Цена, которую предложил ${isViews ? "покупатель" : "продавец"} за данный объект недвижимости`}
            </Tooltip>
          </>}
          classNames={{root: styles.infoRow}}
          aspect={{label: 10, content: 8}}
        >
          <TransparentInput
            register={register}
            maxLenght={11}
            placeholder={(status === "finished" || status === "successFinished") ? "Не заполнено" : "Указать"}
            name="offer"
            value={inputValue}
            readOnly={status === "finished" || status === "successFinished"}
            onChange={handleOnChangeWithDebounce}
            mask={createNumberMask({
              prefix: "",
              thousandsSeparatorSymbol: " ",
            })}
            setValueForMask={setValue}
          />
        </InfoRow>
      </form>
    </div>
  )
}