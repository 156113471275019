import { Task, TaskStatusEnum } from "../../../../types";
import { memo } from "react";
import styles from "./index.module.scss"
import cn from "classnames";
import { ReactComponent as IconDeadlineWarn } from "../../../../assets/icons/iconClock.svg";
import { ReactComponent as IconDeadlineInfo } from "../../../../assets/icons/iconDeadlineInfo.svg";
import { formatDate } from "@sdelka_crm/sdelka-crm-component-library";
import { Avatar } from "../../Avatar";

type Props = {
  task: Task
  mini?: boolean
  inTable?: boolean
}

export const CardTask = memo(({task, mini, inTable}: Props): JSX.Element => {
  const {
    name,
    description,
    result,
    deadline,
    finishedAt,
    status,
    executor,
    relatedKindTitle,
  } = task

  const isSucceed = status === TaskStatusEnum.done
  const isFailed = status === TaskStatusEnum.failed
  const diffSec = Math.round((Date.now() - new Date(deadline).getTime()) / 1000);
  const min = 60;
  const hour = 60 * min;
  const day = 24 * hour;
  const expired = diffSec >= 0
  const willExpired = !expired && diffSec >= day

  return (
    <div className={cn(styles.root,
      {
        [ styles.rootSuccess ]: isSucceed,
        [ styles.inTable ]: inTable,
        [ styles.rootMini ]: mini
      })}
    >
      <div className={cn(styles.content, {[ styles.contentMini ]: mini})}>
        <div>
          <div className={styles.row}>
            <div className={cn(styles.title, {
              [ styles.titleFailed ]: isFailed,
              [ styles.titleMini ]: mini
            })}>
              <div className={styles.titleText}>
                {name}
              </div>
              {!isSucceed &&
                <>
                  {expired ? <IconDeadlineWarn/> : <>
                    {willExpired && <IconDeadlineInfo/>}
                  </>
                  }
                </>
              }
            </div>
            <div className={styles.date}>
              {isSucceed
                ? `вып. ${formatDate(finishedAt ?? new Date())} ${formatDate(finishedAt ?? new Date(), "time")}`
                : `назн. ${formatDate(deadline ?? new Date())} ${formatDate(deadline ?? new Date(), "time")}`}
            </div>
          </div>
          <div className={cn(styles.row, styles.description, {[ styles.descriptionMini ]: mini})}>
            {isSucceed ? result : description}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.relatedTitle}>
            {relatedKindTitle}
          </div>
          <div className={styles.executor}>
            <Avatar
              imgSrc={executor.user?.avatar}
              name={executor.user?.name}
              lastName={executor.user?.lastName}
              color="primary"
              mini
            />
            {`${executor.user?.name ?? ""} ${executor.user?.lastName ?? ""}`}
          </div>
        </div>
      </div>
    </div>
  )
})