import { ReactNode } from "react";
import cn from "classnames";

import { ReactComponent as InfoIcon } from "../../../assets/icons/info-filled.svg";
import { ReactComponent as WarnIcon } from "../../../assets/icons/alert-info.svg";
import styles from "./index.module.scss";

type Props = {
	children: ReactNode;
	iconType?: "info" | "warning";
	iconColor?: "grey" | "red";
	backgroundColor?: "light" | "dark";
	classNames?: {
		root?: string;
	};
	iconPosition?: "top-left" | "top-right" | "bottom-left" | "bottom-right";
	withButton?: {
		buttonText: string;
		onButtonClick: () => void;
	};
};

export const Alerter = ({
	children,
	iconType = "info",
	iconColor = "grey",
	backgroundColor = "dark",
	classNames,
	iconPosition = "top-right",
	withButton,
}: Props): JSX.Element => {
	const iconStyle = cn(styles[`position__${iconPosition}`], styles[`iconColor__${iconColor}`]);

	return (
		<div className={cn(styles.root, classNames?.root, styles[`cardBackround__${backgroundColor}`])}>
			{iconType === "info" && <InfoIcon className={iconStyle} />}
			{iconType === "warning" && <WarnIcon className={iconStyle} />}

			{children}
			{withButton && (
				<button type="button" onClick={withButton.onButtonClick} className={styles.button}>
					{withButton.buttonText}
				</button>
			)}
		</div>
	);
};
