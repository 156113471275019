import { useMutation } from "react-query";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/invite/reload-public-link/DEFAULT_MN";

type UseReloadPublicLink = {
	onSuccess: () => void;
};

export const useReloadPublicLink = ({ onSuccess }: UseReloadPublicLink) => {
	const onError = () => {
		window.alert("Ошибка");
	};

	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: () => api.invites.generatePublicLink(),
		onSuccess,
		onError,
	});

	return {
		mutate: mutate.mutate,
	};
};
