import styles from "./index.module.scss"
import { ReactComponent as Alert } from "../../../../../../../../../../../../../../../assets/icons/iconWarnBig.svg";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../../../../../../../../../../../service/redux/modules/modal/modal.action";
import { useCancelApplication } from "../../../../../../../../../../../../../../../service/api/mutations";

type Props = {
  whoIsClient: "seller" | "buyer" | undefined
  applicationId: string
  refetch?: () => void
}

export const CancelApplicationModal = (
  {
    whoIsClient,
    applicationId,
    refetch
  }: Props): JSX.Element => {
  const dispatch = useDispatch()
  const onDeny = () => {
    dispatch(closeModal())
  }

  const onSuccessCancel = () => {
    if (refetch) {
      refetch()
    }
    dispatch(closeModal())
  }

  const {mutate: mutateCancelApplication, isLoading} = useCancelApplication({onSuccess: onSuccessCancel})

  const handleCancelApplication = () => {
    mutateCancelApplication(applicationId)
  }

  const text = whoIsClient === "seller"
    ? <>
      Данное действие приведет к: <br />
      - Заявка попадет в архив <br />
      - Реклама объекта будет остановлена <br />
      - Статус объекта изменится на “Без активной заявки” <br />
      - Назначенные показы будут отменены
    </>
    : <>
      Данное действие приведет к: <br />
      - Заявка попадет в архив <br />
      - Назначенные просмотры будут отменены
    </>

  return (
    <>
      <div className={styles.row}>
        <div>
          <Alert/>
        </div>
        <div className={styles.column}>
          <div className={styles.title}>Вы уверены, что хотите отказаться от заявки?</div>
          <div className={styles.text}>
            {text}
          </div>
        </div>
      </div>
      <div className={styles.rowEnd}>
        <Button onClick={onDeny} variant="outlined">
          Отмена
        </Button>
        <Button onClick={handleCancelApplication} isLoading={isLoading} classNames={{root: styles.buttonCreate}}>
          Продолжить
        </Button>
      </div>
    </>
  )
}