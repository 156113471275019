import { AdStatusEnum, AdStatusesEnumV2, RealEstateFilter } from "../../../../../types";
import { Dispatch, SetStateAction } from "react";
import { StageButtons } from "./StageButtons";
import { adButtons } from "./adButtons";

type Props = {
  filter: RealEstateFilter;
  setFilter: Dispatch<SetStateAction<RealEstateFilter>>;
  handleFilterClick: (status: AdStatusEnum, fieldName: string) => void;
};

export const AdStatus = ({filter, setFilter, handleFilterClick}: Props): JSX.Element => {
  const filterClick = (val: any) => handleFilterClick(val, "status");
  const buttons = adButtons(filter as { status?: AdStatusesEnumV2[] }, setFilter, filterClick);
  return <StageButtons title="Статус" buttons={buttons}/>;
};
