import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { ReactComponent as EditObjectIcon } from "../../../../../../../../../assets/icons/edit-object.svg";
import { ReactComponent as CloseIcon } from "../../../../../../../../../assets/icons/close.svg";
import { ReactComponent as PaperIcon } from "../../../../../../../../../assets/icons/paper.svg";
import { ReactComponent as PlusIcon } from "../../../../../../../../../assets/icons/plus.svg";
import styles from "./index.module.scss";
import cn from "classnames";
import { PopupMenu, PopupMenuItems } from "../../../../../../../../UI";
import { useState } from "react";

type Props = {
	adIsStarted: boolean;
	adIsWaiting: boolean;
	onAdStopClick?: () => void;
	onAdRestartClick?: () => void;
	onEditClick: () => void;
	onComplaintAutoRejectClick: () => void;
	isCian?: boolean;
	complaintAutoReject?: boolean;
	isPostedAndWaitingForStop?: boolean;
	adIsError?: boolean;
	adIsInArchive?: boolean;
};

type SortableMenuItem = PopupMenuItems & {
	order: number;
};

const PaperIconWithWrapper = () => (
	<div className={styles.iconWrapper}>
		<PaperIcon />
	</div>
);

const EditObjectIconWithWrapper = () => (
	<div className={styles.iconWrapper}>
		<EditObjectIcon />
	</div>
);

const CloseIconWithWrapper = () => (
	<div className={styles.iconWrapper}>
		<CloseIcon />
	</div>
);

const PlusIconWithWrapper = () => (
	<div className={styles.iconWrapper}>
		<PlusIcon />
	</div>
);

export const AdPartnerHeaderButtons = ({
	adIsStarted,
	adIsWaiting,
	adIsError,
	adIsInArchive,
	onEditClick,
	onAdStopClick,
	onAdRestartClick,
	isCian,
	isPostedAndWaitingForStop,
	complaintAutoReject,
	onComplaintAutoRejectClick,
}: Props): JSX.Element => {
	const [localComplaintAutoReject, setLocalComplaintAutoReject] = useState(complaintAutoReject);

	const menu: SortableMenuItem[] = [
		{
			label: isCian && (
				<Button
					variant="text"
					Icon={PaperIconWithWrapper}
					onClick={() => {
						onComplaintAutoRejectClick();
						setLocalComplaintAutoReject((prev) => !prev);
					}}
					classNames={{
						root: cn(styles.cianButton, localComplaintAutoReject ? styles.enabled : styles.disabled),
					}}
				>
					Автоотклонение жалоб на неактуальность {localComplaintAutoReject ? "вкл" : "выкл"}.
				</Button>
			) as any,
			action: () => null,
			order: 0,
		},
		{
			label: (
				<Button
					variant="text"
					Icon={EditObjectIconWithWrapper}
					onClick={onEditClick}
					classNames={{ root: styles.editButton }}
				>
					Редактировать
				</Button>
			) as any,
			action: () => null,
			order: 1,
		},
		{
			label: (adIsError || adIsInArchive) && (
				<Button variant="text" Icon={PlusIconWithWrapper} onClick={onAdRestartClick}>
					Разместить как новое
				</Button>
			) as any,
			action: () => null,
			order: 2,
		},
		{
			label: (adIsStarted || adIsWaiting || adIsError) && !isPostedAndWaitingForStop && !adIsInArchive && (
				<Button color="red" variant="text" Icon={CloseIconWithWrapper} onClick={onAdStopClick}>
					Остановить
				</Button>
			) as any,
			action: () => null,
			order: 3,
		},
	];
	return (
		<div className={styles.buttons}>
			<PopupMenu
				// contextClick={null}
				classNames={{
					wrapper: styles.popupWrapper,
					card: styles.popupWrapperContainer,
				}}
				options={{ primaryOpen: "left" }}
				menuItems={menu}
			/>
			{/* Отсутствует в текущей версии */}
			{/* {adIsStarted && (
			<Button
				variant="text"
				Icon={TimePlusIcon}
				color={autoRenewal ? "green" : "grey"}
				onClick={onAutoRenewalClick}
			>
				{`Автопродление ${autoRenewal ? "вкл" : "выкл"}.`}
			</Button>
		)} */}

			{/* <Button variant="text" Icon={EditObjectIcon} onClick={onEditClick}>
				Редактировать
			</Button> */}

			{/* Отсутствует в текущей версии */}
		</div>
	);
};
