import { RealEstateSchemaConfigField, FormPropsEditRealEstate } from "../../../../../../../../../../types";

import { Checkbox, InfoRow, RadioButton } from "../../../../../../../../index";
import { RequiredDots } from "../../RequiredDots";

import styles from "./index.module.scss";

type Props = {
	form: FormPropsEditRealEstate;
	field: RealEstateSchemaConfigField;
};

export const Communications = ({ form, field }: Props): JSX.Element => {
	const {
		label,
		reqiredFor,
		dataType: { nameGroup },
	} = field;
	const { control } = form;

	if (!nameGroup) {
		return <></>;
	}

	const radioOptions = [
		{ label: "Есть", value: "Есть" },
		{ label: "На участке", value: "На участке" },
		{ label: "Нет", value: "Нет" },
	];

	return (
		<>
			<InfoRow label={label} labelFont="ubuntu" aspect={{ label: 1, content: 1 }}>
				<RequiredDots required={reqiredFor} />

				<RadioButton
					name={nameGroup[0]}
					control={control}
					options={radioOptions}
					separate
					size="small"
					canUnselect
				/>
			</InfoRow>

			<div className={styles.checkBoxContainer}>
				<Checkbox control={control} name={nameGroup[1]} label="Возможно подключить" />
			</div>
		</>
	);
};
