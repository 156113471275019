import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { FormPropsEditRealEstate, RealEstateSchemaConfigField } from "../../../../../../../../../../types";

import { Checkbox, InfoRow, TransparentInput } from "../../../../../../../../index";
import { RequiredDots } from "../../RequiredDots";

import styles from "./index.module.scss";

type Props = {
	form: FormPropsEditRealEstate;
	field: RealEstateSchemaConfigField;
};

export const CommercialPartsSquare = ({ form, field }: Props): JSX.Element => {
	const {
		reqiredFor,
		dataType: { nameGroup = [] },
	} = field;
	const { register, control, watch, setValue } = form;

	const isPosibleField = nameGroup[0];
	const partsAmountField = nameGroup[1];
	const partsSqareField = nameGroup[2];

	const watcherIsPosibleField = watch(isPosibleField);
	const watcherPartsAmountField = Number(watch(partsAmountField));
	const watcherPartsSqareField = watch(partsSqareField);

	const SHOW_SQUARES_LIST =
		watcherIsPosibleField && watcherPartsAmountField > 0 && watcherPartsAmountField < 10;

	const handleRemoveExtraSquares = () => {
		if (watcherPartsSqareField?.length && watcherPartsSqareField.length > watcherPartsAmountField) {
			for (let i = watcherPartsAmountField || 0; i < watcherPartsSqareField.length; i += 1) {
				setValue(`${partsSqareField}.${i}` || "", "");
			}
		}
	};

	const deleteExtraSquaresWithDebounce = useDebouncedCallback(handleRemoveExtraSquares, 500);

	useEffect(() => {
		deleteExtraSquaresWithDebounce();
	}, [watcherPartsAmountField]);

	if (!nameGroup.length) {
		return <></>;
	}

	return (
		<>
			<RequiredDots required={reqiredFor} />

			<InfoRow
				label="Возможна продажа частями"
				labelFont="ubuntu"
				aspect={{ label: 1, content: 1 }}
				classNames={{ root: styles.customInfoRow }}
			>
				<Checkbox name={isPosibleField} control={control} />
			</InfoRow>

			{watcherIsPosibleField && (
				<InfoRow
					label="Кол-во частей"
					labelFont="ubuntu"
					aspect={{ label: 1, content: 1 }}
					classNames={{ root: styles.customInfoRow }}
				>
					<TransparentInput
						placeholder="0000"
						name={partsAmountField}
						register={register}
						setValueAs={(value: string) => value?.toString().replaceAll(/[^\d.-]/g, "")}
					/>
				</InfoRow>
			)}

			{SHOW_SQUARES_LIST &&
				Array.from(Array(watcherPartsAmountField).keys()).map((item) => (
					<InfoRow
						key={item}
						label={`${item + 1} часть — площадь м2`}
						labelFont="ubuntu"
						aspect={{ label: 1, content: 1 }}
						classNames={{ root: styles.customInfoRow }}
					>
						<TransparentInput
							placeholder="0000"
							name={`${partsSqareField}.[${item}]`}
							register={register}
							setValueAs={(value: string) => value?.toString().replaceAll(/[^\d.-]/g, "")}
						/>
					</InfoRow>
				))}
		</>
	);
};
