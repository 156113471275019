import { useEffect, useState } from "react";
import { ColumnInstance } from "react-table";
import { DndContext, DragEndEvent, DragStartEvent } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { restrictToVerticalAxis, restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";

import { SettingsWrapper, SortableItem } from "./components";

type Props = {
	data: any;
	allColumns: ColumnInstance[];
	changeColumnOrder: (updater: string[] | ((columnOrder: string[]) => string[])) => void;
};

export const ColumnsSetting = ({ data, allColumns, changeColumnOrder }: Props): JSX.Element => {
	const [activeId, setActiveId] = useState<string | undefined>(undefined);
	const [columns, setColumns] = useState<ColumnInstance[]>(allColumns);

	useEffect(() => {
		setColumns((prev) =>
			prev.map((col) => ({
				...col,
				isVisible: true,
			}))
		);
	}, [data]);

	useEffect(() => {
		setColumns(allColumns)
	}, [allColumns])

	const reorder = (list: ColumnInstance[], startIndex: number, endIndex: number) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const updateColumn = (index: number, column: ColumnInstance) => {
		const result = Array.from(columns);
		result.splice(index, 1);

		result.splice(index, 0, {
			...column,
			isVisible: !column.isVisible,
		});

		return setColumns(result);
	};

	const onDragStart = (event: DragStartEvent) => {
		if (!event) {
			return;
		}

		setActiveId(event.active.id);
	};

	const onDragEnd = (result: DragEndEvent) => {
		setActiveId(undefined);

		const { over, active } = result;

		if (!over || !active) {
			return;
		}

		const items = reorder(
			columns,
			active?.data?.current?.sortable.index,
			over?.data?.current?.sortable.index
		);
		const newColumnOrder = items.map((column) => column.id);

		changeColumnOrder((newColumnOrder));

		setColumns(items);
	};

	return (
		<SettingsWrapper>
			<DndContext
				onDragStart={onDragStart}
				onDragEnd={onDragEnd}
				modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
			>
				<SortableContext items={columns}>
					{columns.map(
						(column, index) =>
							column.id !== "account.lastName" && (
								<SortableItem
									key={column.id}
									index={index}
									activeId={activeId}
									column={column}
									updateColumn={updateColumn}
								/>
							)
					)}
				</SortableContext>
			</DndContext>
		</SettingsWrapper>
	);
};
