// import { MouseEvent } from 'react';
import cn from "classnames";
import { Loader } from "@sdelka_crm/sdelka-crm-component-library";

import { formatPhone } from "../../../../utils/formatters";

import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import styles from "./index.module.scss";
import { Avatar } from "../../Avatar";
import { ReactComponent as Telegramm } from "../../../../assets/icons/telegram.svg";
import { ReactComponent as Whatsapp } from "../../../../assets/icons/whatsapp.svg";
import { ContactStrap } from "../../../pages/Contacts/ContactStrap/ContactStrap";
import { Kind, ProductTypeEnum, UserStatusEnum, userStatuses } from "../../../../types";
import { useAddToCart } from "../../../../service/api/mutations";
import { useAddInCartModal } from "../../../pages/Servises/cart/helpers/addInCartModal/useAddInCartModal";
import { PopupMenu, PopupMenuItems } from "../../Popup";
import { ReactComponent as ToCart } from "../../../../assets/icons/Shopping_Cart.svg";
import { ReactComponent as Cancel } from "../../../../assets/icons/cancel.svg";
import {
  useOpenCancelApplicationModal
} from "../../../pages/office-card/components/delete-from-department-modal/useOpenDeletefromDepartmentModal";
import { selectCurrentAccount, useSelect } from "../../../../service/redux/selectors";
import { useIsTelegram } from "../../../../utils/telegram/useIsTelegram";

type Props = {
  id: string;
  mini?: boolean;
  isContact?: boolean;
  fio: {
    lastName: string;
    firstName: string;
    secondName?: string;
    pseudonym?: string;
  };
  phone: string;
  position?: string;
  avatar?: string;
  onCardClick?: (id: string) => void;
  onClickClose?: (id: string) => void;
  isOnClickCloseLoading?: boolean;
  onDialogClick?: (id: string) => void;
  onPhoneClick?: (id: string) => void;
  omitAvatar?: boolean;
  backgroundWhite?: boolean
  relatedKinds?: {
    applications: Kind[];
    deals: Kind[];
    preApplications: Kind[];
  };
  canOpenNew?: boolean
  status?: UserStatusEnum
  specialisation?: string
  hasCart?: boolean
  isInCart?: boolean
  departmentId?: string
  canRemoveFromDepartment?: boolean
  refetch?: () => void
  withOutDots?: boolean
  refetchCart?: () => void
};

export const CardPerson = (
  {
    id,
    fio,
    phone,
    position,
    avatar,
    onCardClick,
    onClickClose,
    isOnClickCloseLoading,
    onDialogClick,
    onPhoneClick,
    omitAvatar,
    isContact,
    mini,
    backgroundWhite,
    relatedKinds,
    canOpenNew,
    hasCart,
    specialisation,
    status,
    isInCart,
    departmentId,
    canRemoveFromDepartment,
    refetch,
    withOutDots,
    refetchCart
  }: Props): JSX.Element => {

  const {lastName, firstName, secondName, pseudonym} = fio;

  const {currentAccount} = useSelect(selectCurrentAccount)

  const isCoWorking = currentAccount && currentAccount.position?.name === "Агент в коворкинге"

  const handleCardClick = () => {
    if (onCardClick) {
      onCardClick(id);
    }
  };

  const openInNewTab = () => {
    executeUrl(`/contacts?id=${id}`)
  }

  const {mutate} = useAddToCart({
    onSuccess: () => {
      if (refetch) {
        refetch()
      }
      if (refetchCart) {
        refetchCart()
      }
    }
  })

  const {openAddInCartModal} = useAddInCartModal({
    type: ProductTypeEnum.employerSubscribtion,
    title: "Выберите срок продления",
    action: mutate,
    isMobile: mini,
    refetchCart
  })

  const {handleDeleteFromDepartmentModal} = useOpenCancelApplicationModal({id, departmentId: departmentId ?? ""})

  const handleClick = () => {
    if (hasCart) {
      openAddInCartModal([id ?? ""])
    } else {
      openInNewTab()
    }
  }

  const menu: PopupMenuItems[] = [
    {
      label: "Добавить в корзину",
      icon: <ToCart/>,
      action: () => openAddInCartModal([id ?? ""]),
    },
    {
      label: "Убрать из отдела",
      icon: <Cancel/>,
      action: handleDeleteFromDepartmentModal,
      className: styles.cancel
    },
  ]

  // eslint-disable-next-line no-nested-ternary
  const actualMenu = canRemoveFromDepartment ? (isInCart ? menu.slice(1) : menu) : (isInCart ? menu.slice(1) : menu).slice(0, 1)

  const {executeUrl} = useIsTelegram()

  return (
    <div className={cn(styles.root)}>
      <div
        className={cn(styles.card, {[ styles.cardShorter ]: onClickClose}, {[ styles.backgroundWhite ]: backgroundWhite})}
        onClick={handleCardClick}>
        {avatar && <Avatar imgSrc={avatar} name={firstName} lastName={lastName} classNames={{root: styles.img}}/>}
        <div
          style={avatar ? undefined : {paddingLeft: "20px"}}
          className={cn(
            styles.content,
            mini ? styles.mini : "", {
              [ styles.withMarginLeft ]: omitAvatar,
            })}
        >
          <div className={styles.col}>
              <div className={styles.row}>
                <div className={styles.name}>
                  {`${pseudonym && isContact ? pseudonym : ""} ${lastName} ${firstName} ${secondName || ""}`}
                </div>
                {!withOutDots &&
                  <div className={styles.row}>
                    {!isCoWorking &&
                      <div>
                        {userStatuses[ status ?? UserStatusEnum.Expired ]}
                      </div>}
                    {!hasCart && <PopupMenu
                      classNames={{
                        wrapper: styles.popupWrapper,
                        card: styles.cardPopup
                      }}
                      options={{primaryOpen: "left"}}
                      menuItems={actualMenu}
                    />}
                  </div>}
              </div>

              <div className={styles.spec}>
                {specialisation}
              </div>
            </div>
            <div className={styles.phone}>
              <div>
                {phone && <span className={styles.phone_number}>{formatPhone(phone)}</span>}
                {position && <span className={styles.position}>{position}</span>}
              </div>
              {isContact &&
                <div className={styles.socials}>
                  <div onClick={(event) => {
                    event?.stopPropagation();
                    executeUrl(`https://t.me/+${phone || ""}`)
                  }}>
                    <Telegramm/>
                  </div>
                  <div onClick={(event) => {
                    event?.stopPropagation();
                    executeUrl(`https://api.whatsapp.com/send?phone=${phone || ""}`)
                  }}>
                    <Whatsapp width={24}/>
                  </div>
                </div>
              }
            </div>
          {(onDialogClick || onPhoneClick) && <div className={styles.phoneAndDialog}/>}
        </div>
      </div>
      {onClickClose && (
        <>
          {isOnClickCloseLoading ? (
            <div className={styles.closeLoading}>
              <Loader/>
            </div>
          ) : (
            <div className={styles.closeIcon} onClick={() => onClickClose(id)}>
              <CloseIcon/>
            </div>
          )}
        </>
      )}
      <div onClick={(event) => {
        event?.stopPropagation();
        handleClick()
      }}>
        <ContactStrap
          relatedKinds={relatedKinds || {
            applications: [],
            deals: [],
            preApplications: []
          }}
          mini={mini}
          canOpenANewTab={canOpenNew}
          hasCart={hasCart}
          isInCart={isInCart}
          refetch={refetch}
          id={id}
        />
      </div>
    </div>
  );
};
