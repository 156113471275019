import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/position/load-positions/DEFAULT_QN";
export const LOAD_POSITIONS_REQUESTS_QN = "sdelka/position/load-positions-requests/DEFAULT_QN";

type UseLoadPositions = {
	enabled?: boolean;
	queryName?: string;
};

export const UseLoadUserPositions = ({ queryName = DEFAULT_QN, enabled = true }: UseLoadPositions) => {
	const loadPositions = () => api.positions.list();

	const { data, isLoading, refetch } = useQuery(queryName, loadPositions, {
		enabled,
	});

	const getPositionName = (id: string | undefined): string | undefined => {
		if (id === undefined) {
			return undefined;
		}

		return data?.data.find((position) => position.id === id)?.name || "";
	};

	return {
		positions: data?.data || [],
		isLoading,
		refetch,
		getPositionName,
	};
};
