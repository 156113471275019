import { useDispatch } from "react-redux";
import { openModal } from "../../../../service/redux/modules/modal/modal.action";
import { PreCreateApplicationPayload, PreCreateObjectPayload } from "../../../../types";
import { PreCreateModal } from "./components";

import styles from "./index.module.scss";
import { selectDevice, useSelect } from "../../../../service/redux/selectors";

type Props = {
	title: string;
	onCreated: (values: PreCreateApplicationPayload | PreCreateObjectPayload) => void;
	isFull?: boolean;
	isLoading?: boolean
	objectCategory?: string
};

type Return = {
	handleCreateObject: () => void;
};

type UsePreCreatePreApplication = (props: Props) => Return;

export const usePreCreatePreApplication: UsePreCreatePreApplication = (
	{
		title,
		onCreated,
		isFull,
		isLoading,
		objectCategory,
	}) => {
	const dispatch = useDispatch();
	const {isMobile} = useSelect(selectDevice)

	const handleCreateObject = () => {
		dispatch(
			openModal({
				title,
				body: <PreCreateModal
					onCreated={onCreated}
					isFull={isFull}
					isLoading={isLoading}
					objectCategory={objectCategory}
				/>,
				width: isMobile ? 500 : 633,
				classNames: {
					contentWrapper: isMobile ? styles.contentWrapperMobile : styles.contentWrapper,
					buttonClose: isMobile ? styles.modalClose : ""
				},
			})
		);
	};

	return {
		handleCreateObject,
	};
};
