import { AxiosResponse } from "axios";
import { UseMutateFunction, useMutation } from "react-query";
import { PlatformAdDescription, PlatformDescriptionIdData } from "../../../../types";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/real-estate-ad/update-platform-description/DEFAULT_MN";

type Props = {
	onSuccess?: (
		data: AxiosResponse<string>,
		variables: PlatformDescriptionIdData & PlatformAdDescription,
		context: unknown
	) => void;
};

type Return = {
	mutate: UseMutateFunction<
		AxiosResponse<string>,
		unknown,
		PlatformDescriptionIdData & PlatformAdDescription,
		unknown
	>;
	isLoading: boolean;
};

type UseUpdatePlatformDescription = (props: Props) => Return;

export const useUpdatePlatformDescription: UseUpdatePlatformDescription = ({ onSuccess }) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (data: PlatformDescriptionIdData & PlatformAdDescription) =>
			api.realEstateAd.updatePlatformDescription(data),
		onSuccess,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
