import { useDispatch } from "react-redux";
import { openDrawer, openDrawerInDrawer } from "../../../../redux/modules/drawer/drawer.action";
import { selectDevice, useSelect } from "../../../../redux/selectors";
import { useQueryParams } from "../../../../../utils/hooks";
import { FixedNavVerticalMenu } from "../../../../../components/UI";
import { ProductOrderCard } from "../../../../../components/pages/product-order-card/ProductOrderCard";

type Return = {
  openProductOrderDrawer: (orderId?: string) => void;
};

type Props = {
  refetch?: () => void;
  atDrawerInDrawer?: boolean
};

type UseOpenProductOrder = (props: Props) => Return;

export const useOpenProductOrder: UseOpenProductOrder = ({refetch, atDrawerInDrawer}) => {
  const dispatch = useDispatch();
  const {isMobile} = useSelect(selectDevice)

  const {removeQueryParam} = useQueryParams<{
    orderId: string;
  }>([{name: "orderId"}]);

  const orderNav = {
    label: "Заказ",
    height: 120,
    title: "order"
  };

  const yNavigation: Omit<FixedNavVerticalMenu, "id" | "changeTab">[] = [orderNav]

  const openProductOrderDrawer = (orderId?: string) => {
    if (atDrawerInDrawer) {
      dispatch(openDrawerInDrawer(
        {
          children: <ProductOrderCard orderId={orderId} refetchAll={refetch}/>,
          width: isMobile ? 500 : 800,
          yNavigation: {
            navigation: yNavigation,
            initialTab: 0
          },
          onClose: () => {
            removeQueryParam("orderId")
          },
        }))
    } else {
      dispatch(
        openDrawer({
          children: <ProductOrderCard orderId={orderId} refetchAll={refetch}/>,
          width: isMobile ? 500 : 800,
          yNavigation: {
            navigation: yNavigation,
            initialTab: 0
          },
          onClose: () => {
            removeQueryParam("orderId")
          },
        })
      );
    }
  };

  return {
    openProductOrderDrawer,
  };
};
