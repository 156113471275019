import type {
	AddressValue,
	AdStatusEnum,
	BusinessProcess,
	CategoryEnum, Condition,
	Conditions,
	CreateApplication,
	RealEstate,
	RealEstatePhoto,
} from "../index";

type FeedAds = {
	status: AdStatusEnum;
} & any;

export type WithAds = "Требуют внимания";

export enum ActualEnum {
	"Не проверен" = "Не проверен",
	"Актуальный" = "Актуальный",
	"Продан" = "Продан",
	"Продажа приостановлена" = "Продажа приостановлена",
	"Участвует в сделке" = "Участвует в сделке",
	"В архиве" = "В архиве",
}

export type RealEstateFilter = {
	responsibleUsers?: {
		userId?: string[];
	};
	polygon?: number[][][][];
	values?: any;
	priceFrom?: string;
	priceTo?: string;
	pricePerUnitFrom?: string;
	pricePerUnitTo?: string;
	squareFrom?: number;
	squareTo?: number;
	squareAreaFrom?: number;
	squareAreaTo?: number;
	squareValue?: string;
	categories?: string[];
	rooms?: string[];
	roomsType?: string[];
	matrialDoma?: string[];
	roomsSquare?: string[];
	objectId?: string;
	includeWords?: string[];
	excludeWords?: string[];
	unit?: string;
	id?: string[]
	objectType?: string;
	address?: AddressValue
	addressParser?: string;
	contactId?: string;
	category?: CategoryEnum[];
	objectLocationType?: string[];
	feedAds?: FeedAds[];
	withAds?: WithAds[];
	actual?: ActualEnum[];
	sourceSelect?: string[];
	type?: {
		value?: BusinessProcess;
	};
	responsible?: string[]
};

export type RealEstateCollectionsPayload = {

}

export type RealEstateCollectionsFilter = {
	search: {
		filterType: string
		conditions: Condition[]
		take: number
		skip: number
	}
}

export type RealEstateCreatePayload = Omit<RealEstate, "id"> & {
	applicationData: Pick<
		CreateApplication,
		"additionalProperties" | "sellerContacts" | "comment" | "responsibleUserId"
	>;
};

export type RealEstateGetConfigPayload = {
	category: string;
	businessProcessId: string;
};

export type RealEstateUpdatePayload = {
	id: string;
	data: Partial<RealEstate>;
};

export type RealEstateAddPhotoPayload = {
	applicationId: string;
	data: Omit<RealEstatePhoto, "id">[];
};

export type RealEstateUpdatePriceWithDiscount = {
	id: string
	priceWithDiscount: number
}

export type RealEstateUpdatePhotoPayload = {
	applicationId: string;
	data: Omit<
		RealEstatePhoto,
		"photoOriginal" | "photo1280" | "photo1280Watermark" | "photo96x96" | "width" | "height"
	>[];
};

export type RealEstateRotatePhotoPayload = {
	photoId: string;
	rotation: "left" | "right";
};

export type RealEstateSort = {
	field: "price" | "createdAt";
	sort: "ASC" | "DESC";
};

export type RealEstateListPayload = {
	take: number;
	skip: number;
	sort?: RealEstateSort;
	join?: string[];
	search?: RealEstateFilter;
	onlyCount?: boolean;
	absoluteSearchConditions: Conditions;
};

export type GetRealEstateFiltersPayload = {
	category: CategoryEnum[];
	type: {
		value: BusinessProcess;
	};
};

export type ApplicationGeneratePresentationPayload = {
	objectApplicationId: string;
	price: number;
};

export type ParserPresentationPayload = {
	parserObjectId: string;
	price: number;
};

export type RealEstateGeneratePresentationPayload =
	| ApplicationGeneratePresentationPayload
	| ParserPresentationPayload;

export type CreateObjectToApplicationPayload = {
	applicationId: string;
	data: Omit<RealEstateCreatePayload, "applicationData">;
};
