import styles from "./index.module.scss";
import { ReactComponent as Icon } from "../../../assets/icons/info-filled.svg";

type Props = {
	children: JSX.Element | string;
};

export const Information = ({ children }: Props): JSX.Element => (
	<div className={styles.root}>
		{children}
		<Icon className={styles.icon} />
	</div>
);
