import { useState } from "react";
import {
	H2,
	LoadByScroll,
	MarkerSentence,
	MedianPrice,
	Underlay,
} from "@sdelka_crm/sdelka-crm-component-library";

import { PercentRecommendationsItem, StageConditions } from "../../../../types";
import { CardPercent, ProgressCircular } from "../../index";
import { useLoadAdRecommendations } from "../../../../service/api/queries";
import { HiddenWidget } from "../HiddenWidget";

// import { ReactComponent as Roll } from 'assets/icons/roll.svg';
// import { ReactComponent as ClockTimer } from 'assets/icons/clock-timer.svg';
import styles from "./index.module.scss";

// const options = [
//   { label: 'Все', value: 'all' },
//   { label: 'Выполненые', value: 'done' },
//   { label: 'Не выполненые', value: 'inProgress' },
// ];

type Props = {
	applicationId: string;
	scrollY: number;
	stageConditions?: StageConditions;
};

// const renderIcon = (active: boolean, type?: 'wait' | 'info'): ReactNode => {
//   if (type === 'wait') {
//     return (
//       <div className={styles.wait}>
//         <ClockTimer />
//       </div>
//     );
//   }
//
//   if (type === 'info') {
//     return (
//       <div className={cn(styles.icon, { [styles.active]: active })}>
//         <Roll />
//       </div>
//     );
//   }
//
//   return <div />;
// };

const renderChildren = ({ children }: PercentRecommendationsItem) => {
	if (!children) return "";

	return (
		<div className={styles.children}>
			<MedianPrice
				border={children.border}
				median={children.median}
				medianPrice={children.medianPrice}
				price={children.price}
				classNames={{ wrapper: styles.medianWrapper }}
			/>

			<div className={styles.markers}>
				<ul>
					{children.labels.map((item, index) => (
						// eslint-disable-next-line react/no-array-index-key
						<li key={index}>
							<MarkerSentence markerColor={item.color}>{item.label}</MarkerSentence>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export const Recommendations = ({ applicationId, scrollY, stageConditions }: Props): JSX.Element => {
	const [startFetching, setStartFetching] = useState(!scrollY);

	const { data, isLoading } = useLoadAdRecommendations({
		applicationId,
		enabled: startFetching,
	});

	// const { control } = useForm({
	//   defaultValues: {
	//     recType: options[0].value,
	//   },
	// });

	const { percent } = data || {};
	// const [activeIcons, setActiveIcons] = useState(
	//   (
	//     data || { recommendationsList: [] as PercentRecommendationsItem[] }
	//   ).recommendationsList.reduce(
	//     (acc, cur) => ({ ...acc, [cur.id]: false }),
	//     {},
	//   ),
	// );

	const handleInView = () => setStartFetching(true);

	return (
		<>
			<H2>Рекомендации к рекламе</H2>

			{((stageConditions ? stageConditions.isNext : false) && <HiddenWidget />) || (
				<LoadByScroll isLoading={isLoading} height={400} scrollY={scrollY} onInView={handleInView}>
					<Underlay className={styles.main}>
						<div>
							<ProgressCircular percent={percent || 0} />
						</div>

						<div className={styles.form}>
							<div className={styles.formHeader}>Коэффицент успешности</div>

							<div className={styles.formInfo}>Для успешной рекламы объекта выполните следующие пункты.</div>

							{/* <div className={styles.radio}> */}
							{/*  <RadioButton name="recType" options={options} control={control} /> */}
							{/* </div> */}
						</div>
					</Underlay>

					<ul className={styles.list}>
						{(data?.recommendationsList || []).map((item) => (
							<li key={item.id}>
								<CardPercent
									// onIconClick={() => setActiveIcons({
									//   ...activeIcons,
									//   [item.id]: !activeIcons[item.id],
									// })}
									icon={item.type === "info" && <span className={styles.success}>Выполнено</span>}
									label={item.label}
									percent={item.percent}
								>
									{item.children && renderChildren(item)}
								</CardPercent>
							</li>
						))}
					</ul>
				</LoadByScroll>
			)}
		</>
	);
};
