import styles from "./index.module.scss"
import { ReactComponent as Success } from "../../../../../../../assets/icons/Success.svg";
import { WhoIsClient } from "../../../../../../../types";
import { useDispatch } from "react-redux";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { closeModal } from "../../../../../../../service/redux/modules/modal/modal.action";

type Props = {
  whoIsClient: WhoIsClient
}

export const SuccessCreateModal = ({whoIsClient}: Props) => {
  const dispatch = useDispatch()

  return (
    <div>
      <div className={styles.row}>
        <Success/>
        <div className={styles.title}>
          {whoIsClient === "buyer" ? "Показ создан" : "Просмотр создан"}
        </div>
      </div>
      <div className={styles.rowEnd}>
        <Button onClick={() => dispatch(closeModal())}>
          {whoIsClient === "buyer" ? "К показу" : "К просмотру"}
        </Button>
      </div>
    </div>
  )
}