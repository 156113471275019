import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useGetAgencyAgrement } from "service/api/mutations/agency-agrement";
import { Header } from "./components/Header/Header";
import { Form } from "./components/Form/Form";
import { Loader } from "@sdelka_crm/sdelka-crm-component-library";
import { ACStickyFooter } from "./components/sticky-footer/ACStickyFooter";
import cn from "classnames";

type Props = {
  applicationId: string
  isMobile?: boolean
  isArchived?: boolean
  isApplication?: boolean
}

export const AgencyAgrement = (
  {
    applicationId,
    isMobile,
    isArchived,
    isApplication
  }: Props): JSX.Element => {
  const [isEdit, setIsEdit] = useState(false)

  const {
    mutate,
    data,
    isLoading,
    error,
    mutation,
  } = useGetAgencyAgrement(applicationId)

  useEffect(() => {
    mutate()
  }, [mutate])

  const errorCode = (mutation?.error as any)?.response?.data?.error?.errorCode

  const formatDate = (inputDate: Date) => {
    const date = new Date(inputDate);
    const year = date.getFullYear().toString().slice(-2);
    const month = (`0${  date.getMonth() + 1}`).slice(-2);
    const day = (`0${  date.getDate()}`).slice(-2);
    return `${day}.${month}.${year}`;
  }

  useEffect(() => {
    if (errorCode === "AP005") {
      setIsEdit(true)
    }
  }, [errorCode, error, mutate])

  const submitCallback: any = {
    test: 'no',
    text: "",
    reset: "",
    buttonActive: "",
    buttonLoading: ""
  }
  const [text1, setText1] = useState("")
  const [active, setActive] = useState(true)
  const [loading, setLoading] = useState(true)

  const call = (arr, text, reset, buttonActive, buttonLoading) => {
    submitCallback.test = arr
    setText1(text)
    submitCallback.reset = reset
    setActive(buttonActive)
    setLoading(buttonLoading)
  }

  const submit = () => {
    if (typeof submitCallback.test === 'function') {
      submitCallback.test()
    }
  }
  const reset = () => {
    if (typeof submitCallback.reset === 'function') {
      submitCallback.reset()
    }
  }
  return (
    <div className={styles.root}>
      {!isLoading ?
        <div className={styles.wrap}>
            <Header
              data={data?.data}
              formatDate={formatDate}
              errorCode={errorCode}
              applicationId={applicationId}
              edit={isEdit}
              refetch={() => mutate()}
              isMobile={isMobile}
            />
            <div className={styles.stripe}>
              <div />
            </div>
            <div className={styles.content}>
                <div className={cn( styles.body) }>
                  <Form
                    isEdit={isArchived ? false : isEdit}
                    setIsEdit={setIsEdit}
                    data={data?.data}
                    applicationId={applicationId}
                    errorCode={errorCode}
                    refetch={mutate}
                    err={error}
                    call={call}
                  />
                </div>
                <ACStickyFooter
                    errorCode={errorCode}
                    initialSaveText={text1}
                    isEdit={isEdit}
                    resetFormValues={reset.bind(this)}
                    data={data?.data}
                    onClick={submit.bind(this)}
                    setIsEdit={setIsEdit}
                    isButtonActive={active}
                    appliactionId={applicationId}
                    refetch={() => mutate()}
                    isMobile={isMobile}
                    isLoading={loading}
                    isApplication={isApplication}
                  />
              </div>
          </div>
        : <Loader className={styles.loader}/>
      }
    </div>
  );
};
