import { Lead } from "../../../../../../../../../types";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../../../../../service/redux/modules/modal/modal.action";
import { useOpenCreateLeadCard } from "../../../../helpers";
import styles from "./index.module.scss";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { ReactComponent as Alert } from "../../../../../../../../../assets/icons/alert-big.svg";

type Props = {
  lead: Lead
  refetch: () => void;
}

export const RelatedLeadModal = ({lead, refetch}: Props): JSX.Element => {

  const dispatch = useDispatch()
  const {openCreateLeadDrawerInDrawer} = useOpenCreateLeadCard({
    lead,
    refetch,
  });
  const onApply = () => {
    openCreateLeadDrawerInDrawer()
    dispatch(closeModal())
  }
  const onDeny = () => {
    dispatch(closeModal())
  }

  return (
    <>
      <div className={styles.row}>
        <div>
          <Alert/>
        </div>
        <div className={styles.column}>
          <div className={styles.title}>Создание побочного лида</div>
          <div className={styles.text}>
            {`Вы создадите побочный лид из лида №${lead.shortId}`}
          </div>
        </div>
      </div>
      <div className={styles.rowEnd}>
        <Button onClick={onDeny} variant="outlined">
          Отмена
        </Button>
        <Button onClick={onApply} classNames={{root: styles.buttonCreate}}>
          Создать
        </Button>
      </div>
    </>
  )
}