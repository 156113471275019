import { Dispatch, SetStateAction, useState } from "react";
import { RealEstateSort } from "../../../../types";
import { useLoadAppliedHistory, useLoadOffices, useLoadServices, useLoadUsers } from "../../../../service/api/queries";
import { useUrlWatcher } from "../../../../service/servise";

type TabType = "PRODUCTS" | "OFFICES" | "EMPLOYERS" | "ORDERS";

enum TabTypeEnum {
  ORDERS = 0,
  PRODUCTS = 1,
  OFFICES = 2,
  EMPLOYERS = 3,
}

type Props = {
  tab: TabType;
  defaultPage: number;
};

type Return = {
  list: any[];
  isFetching: boolean;
  isLoading: boolean;
  total?: number;
  take?: number;
  setTake?: Dispatch<SetStateAction<number | undefined>>;
  page?: number;
  setPage?: Dispatch<SetStateAction<number | undefined>>;
  sort?: RealEstateSort;
  setSort?: Dispatch<SetStateAction<RealEstateSort | undefined>>;
  // filter: RealEstateFilter;
  // setFilter: Dispatch<SetStateAction<RealEstateFilter>>;
  refetch: () => void
};

export const useServices = ({tab}: Props) => {
  const [curTab, setCurTab] = useState(0);


  const scopes = ["withCart"]

  const {
    isLoading: isLoadingUsers,
    users,
    take: usersTake,
    setTake: usersSetTake,
    page: usersPage,
    setPage: usersSetPage,
    sort: usersSort,
    setSort: usersSetSort,
    total: usersTotal,
    refetch: refetchUsers,
    isFetching: isFetchingUsers
  } = useLoadUsers({
    enabled: TabTypeEnum[ tab ] === TabTypeEnum.EMPLOYERS,
    scopes
  })

  const {
    isLoading: isLoadingOffices,
    offices,
    take: officesTake,
    setTake: officesSetTake,
    page: officesPage,
    setPage: officesSetPage,
    sort: officesSort,
    setSort: officesSetSort,
    total: officesTotal,
    refetch: refetchOffices,
    isFetching: isFetchingOffices
  } = useLoadOffices({
    enabled: TabTypeEnum[ tab ] === TabTypeEnum.OFFICES
  })

  const {
    isLoading: isLoadingOrders,
    appliedHistoryItems,
    take: ordersTake,
    setTake: ordersSetTake,
    page: ordersPage,
    setPage: ordersSetPage,
    sort: ordersSort,
    setSort: ordersSetSort,
    total: ordersTotal,
    refetch: refetchOrders,
    isFetching: isFetchingOrders
  } = useLoadAppliedHistory({
    enabled: TabTypeEnum[ tab ] === TabTypeEnum.ORDERS
  })

  const {
    isLoading: isLoadingServices,
    services,
    take: servicesTake,
    setTake: servicesSetTake,
    page: servicesPage,
    setPage: servicesSetPage,
    sort: servicesSort,
    setSort: servicesSetSort,
    total: servicesTotal,
    refetch: refetchServices,
    isFetching: isFetchingServices
  } = useLoadServices({
    enabled: TabTypeEnum[ tab ] === TabTypeEnum.PRODUCTS
  })


  const TABS: Record<TabType, Return> = {
    EMPLOYERS: {
      list: users,
      isFetching: isFetchingUsers,
      isLoading: isLoadingUsers,
      total: usersTotal,
      take: usersTake,
      setTake: usersSetTake,
      page: usersPage,
      setPage: usersSetPage,
      sort: usersSort,
      setSort: usersSetSort,
      // filter: filterParsedRealEstates,
      // setFilter: setFilterParsedRealEstates,
      refetch: refetchUsers
    },
    PRODUCTS: {
      list: services,
      isFetching: isFetchingServices,
      isLoading: isLoadingServices,
      total: servicesTotal,
      take: servicesTake,
      setTake: servicesSetTake,
      page: servicesPage,
      setPage: servicesSetPage,
      sort: servicesSort,
      setSort: servicesSetSort,
      // filter: filterParsedRealEstates,
      // setFilter: setFilterParsedRealEstates,
      refetch: refetchServices
    },
    OFFICES: {
      isLoading: isLoadingOffices,
      list: offices,
      take: officesTake,
      setTake: officesSetTake,
      page: officesPage,
      setPage: officesSetPage,
      sort: officesSort,
      setSort: officesSetSort,
      total: officesTotal,
      refetch: refetchOffices,
      isFetching: isFetchingOffices,
      // filter: filterParsedRealEstates,
      // setFilter: setFilterParsedRealEstates,
    },
    ORDERS: {
      isLoading: isLoadingOrders,
      list: appliedHistoryItems,
      take: ordersTake,
      setTake: ordersSetTake,
      page: ordersPage,
      setPage: ordersSetPage,
      sort: ordersSort,
      setSort: ordersSetSort,
      total: ordersTotal,
      refetch: refetchOrders,
      isFetching: isFetchingOrders,
      // filter: filterParsedRealEstates,
      // setFilter: setFilterParsedRealEstates,
    }
  }


  const {
    list, isFetching, total, take, setTake, page, setPage, sort, setSort, isLoading,
    // filter, setFilter,
    refetch
  } = TABS[ tab ];
  const TAB_KEYS = Object.keys(TABS);
  const filterType = TAB_KEYS[ curTab ];
  const refetchList = TABS[ filterType ].refetch;

  useUrlWatcher({
    refetch: refetchList,
    setCurTab,
    curTab
  })

  return {
    list,
    isFetching,
    isLoading,
    total,
    take,
    setTake,
    page,
    setPage,
    sort,
    setSort,
    // filter,
    // setFilter,
    refetch
  }
}