import styles from "./index.module.scss";
import { CardOffice, CardOfficeForSelect } from "../../../../UI";
import { Office, SelectedOffice } from "../../../../../types";


export const useColumns = (isMobile?: boolean, isSelect?: boolean) => [
  {
    accessor: "user",
    render: ({row}: { row: Office & SelectedOffice}) => (
        <div className={styles.root}>
          {isSelect
            ? <CardOfficeForSelect
              office={row}
              onCardClick={() => {}}
              mini={isMobile}
            />
            : <CardOffice
            office={row}
            onCardClick={() => {
            }}
            mini={isMobile}
          />
          }
        </div>
      ),
  },
];
