import { Office } from "../../../../types";
import styles from "./index.module.scss"
import cn from "classnames";
import { ReactComponent as Avatar } from "../../../../assets/icons/office-briefcase-svgrepo-com 1.svg";

type Props = {
  office: Office
  selected?: boolean
  onCardClick: (id: string) => void
  mini?: boolean
}

export const CardOffice = (
  {
    office,
    selected,
    onCardClick,
    mini
  }: Props): JSX.Element => {
  const {
    name,
    id,
    addressLabel,
    mainPhone,
    tariff,
    image
  } = office

  return (
    <div
      className={cn(styles.root, {[ styles.selected ]: selected, [ styles.mini ]: mini})}
      onClick={() => onCardClick(id)}
    >
      <div className={styles.content}>
        <div className={styles.row}>
          {image
            ? <img src={image} alt="avatar"/>
            : <div className={styles.logo}><Avatar/></div>
          }
          <div className={styles.mainCol}>
            <div className={styles.col}>
              <div className={styles.titleRow}>
                <div className={styles.title}>
                  {name}
                </div>
                {tariff &&
                  <div className={styles.tariff}>
                    {tariff}
                  </div>
                }
              </div>
              <div>
                {addressLabel}
              </div>
            </div>
            <div>
              {mainPhone} {" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}