export type LeadTargetPinPayload = {
	type: LeadTargetPinType;
	entityId: string;
};

export enum LeadTargetPinType {
	OBJECT = "object",
	APPLICATION = "application",
}

export enum RelatedLeadPinType {
	AsNew = "newLead",
	AsDouble = "doubleLead",
}
