import {
	CollectionObject,
	RealEstate,
	AddProps,
	RemoveProps,
	ObjectCompareAdd,
	ObjectCompareRemove,
} from "../../../../../../../../../types";
import { useDispatch } from "react-redux";

import {
	useGetCompare,
	COMPARE_PAGE_LOAD_COMP_QN,
} from "../../../../../../../../../service/api/queries/object-compare";
import {
	useAddObject,
	useDeleteObjectFromCompare,
} from "../../../../../../../../../service/api/mutations/object-compare";
import { setHoveredRealEstateImg } from "../../../../../../../../../service/redux/modules/compilation/compilation.action";

type Props = {
	sampledApplicationId: string;
	isCompare: boolean;
};

type Return = {
	compareObjects: CollectionObject[];
	refetchCompare: () => void;
	onAddCompare: AddProps;
	onDeleteCompare: RemoveProps;
};

type UseCompare = (props: Props) => Return;

export const useCompare: UseCompare = ({ sampledApplicationId, isCompare }) => {
	const dispatch = useDispatch();

	const { data, refetch: refetchCompare } = useGetCompare({
		id: sampledApplicationId,
		enabled: isCompare,
		queryName: COMPARE_PAGE_LOAD_COMP_QN,
	});

	const compareObjects = data || [];

	const { mutate: mutateAdd, isLoading: isAddLoading } = useAddObject({
		onSuccess: () => refetchCompare(),
	});
	const { mutate: mutateRemove, isLoading: isRemoveLoading } = useDeleteObjectFromCompare({
		onSuccess: () => {
			refetchCompare();
			dispatch(setHoveredRealEstateImg(undefined));
		},
	});

	const handleOnAdd = (objectData: RealEstate) => {
		if (sampledApplicationId) {
			const { id, applicationId } = objectData;

			const payload: ObjectCompareAdd = {
				comparedApplicationId: sampledApplicationId,
			};

			if (applicationId) {
				payload.clientObjectApplicationIds = [applicationId];
			} else if (id) {
				payload.parserObjectIds = [id];
			}

			mutateAdd(payload);
		}
	};

	const handleOnRemove = (objectData: RealEstate) => {
		if (sampledApplicationId) {
			const { applicationId, id } = objectData;

			const payload: ObjectCompareRemove = {
				objectId: applicationId || id,
				comparedApplicationId: sampledApplicationId,
			};

			mutateRemove(payload);
		}
	};

	const onAddCompare: AddProps = {
		onAdd: handleOnAdd,
		isAddLoading,
	};

	const onDeleteCompare: RemoveProps = {
		onRemove: handleOnRemove,
		isRemoveLoading,
	};

	return {
		compareObjects,
		refetchCompare,
		onAddCompare,
		onDeleteCompare,
	};
};
