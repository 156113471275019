import { useEffect } from "react";
import { PreCreateObjectPayload, RealEstate } from "../../../../../types";

import {
  ApplicationBusinessProcess,
  ContactsLocal,
  ObjectEditLoading,
  ObjectLayoutLoading,
  RealEstateForm,
  ResponsiblesLocal,
} from "../../../../UI";
import { useComment, useContacts, useOnSubmit, useQuiz, useResponsible } from "./helpers";
import { useCreateObjectForm, useLoadCreateConfig } from "../../../../../utils/hooks";

import styles from "./index.module.scss";
import { ImportObject } from "./components/ImportObject";

type Props = {
  defaultValues: PreCreateObjectPayload;
  onObjectCreate?: (object: RealEstate) => void;
  options?: {
    disableMap?: boolean;
    notFullHeight?: boolean;
  };
  isMobile?: boolean
};

export const RealEstateCreate = (
  {
    options = {},
    onObjectCreate,
    defaultValues,
    isMobile
  }: Props): JSX.Element => {
  const {disableMap} = options;

  const {
    category,
    type: {id: businessProcessId},
  } = defaultValues;
  const defaultCategory = {
    category,
    businessProcessId,
  };

  const {objectConfig, isConfigLoading, currentCategory} = useLoadCreateConfig({defaultCategory});
  const {form, handleSubmit} = useCreateObjectForm({defaultValues});

  const {
    clearErrors,
    setError,
  } = form;

  useEffect(() => {
    clearErrors();
  }, [currentCategory]);

  const {contacts, onChangeContacts} = useContacts();

  const {responsiblesIds, onChangeResponsible} = useResponsible();

  const {quizGetValues} = useQuiz();

  const {comment} = useComment();

  const {onSubmit, isCreateLoading, photos, setPhotos} = useOnSubmit({
    setError,
    clearErrors,
    disableMap,
    onObjectCreate,
    contacts,
    responsiblesIds,
    quizGetValues,
    comment,
  });

  if (isConfigLoading) {
    return <ObjectEditLoading/>;
  }

  return (
    <>
      {objectConfig
        ?
        <div className={styles.root}>
          <h2>Создание объекта</h2>
          <ApplicationBusinessProcess form={form}/>
          <ImportObject/>
          <div className={styles.seller}>
            <ContactsLocal
              type="seller"
              data={contacts}
              onChange={onChangeContacts}
              isMobile={isMobile}
              withOutDots
            />
          </div>
          <div className={styles.responsibles}>
            <ResponsiblesLocal
              responsibles={responsiblesIds}
              onChange={onChangeResponsible}
              isMobile={isMobile}
              withOutDots
            />
          </div>
          <RealEstateForm
            id="new"
            form={form}
            isMutationLoading={isCreateLoading}
            objectConfig={objectConfig}
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            isNewMod
            photos={photos}
            setPhotos={setPhotos}
          />
        </div>
        : <ObjectLayoutLoading/>
      }
    </>

  );
};
