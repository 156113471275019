import { CartItemProduct } from "../../../../types";
import styles from "./index.module.scss"
import { useState } from "react";
import { ReactComponent as Plus } from "../../../../assets/icons/plus.svg";
import { ReactComponent as Minus } from "../../../../assets/icons/minus.svg";
import { ReactComponent as Delete } from "../../../../assets/icons/cancel.svg";
import { useDeleteFromCart, useUpdateCart } from "../../../../service/api/mutations";
import cn from "classnames";
import { formatThousand } from "../../../../utils/formatters";


type Props = {
  product: CartItemProduct
  onSuccess: () => void
  isHovered: boolean
}

export const CardProduct = ({product, onSuccess, isHovered}: Props) => {
  const {
    id,
    tariff,
    tariffOptions,
    totalPrice,
    photo,
    description,
    title,
    amount,
  } = product

  const {mutate: updateCart, data} = useUpdateCart({onSuccess})
  const {mutate: mutateDelete} = useDeleteFromCart({
    onSuccess
  })

  const [, setHovered] = useState(false);
  const [count, setCount] = useState(data?.amount ?? amount)

  const increase = () => {
    setCount(count + 1)

    setTimeout(() => {
      const payload = {
        id,
        amount: count + 1,
        tariff
      }
      updateCart(payload)
    }, 50)

  }
  const decrease = () => {
    if (count >= 2) {
      setCount(count - 1)

      setTimeout(() => {
        const payload = {
          id,
          amount: count - 1,
          tariff
        }
        updateCart(payload)
      }, 50)
    }
  }

  return (
    <div
      className={styles.root}
      onMouseEnter={() => {
        setHovered(true)
      }}
      onMouseLeave={() => {
        setHovered(false)
      }}>
      <div className={cn(styles.content, {[ styles.open ]: isHovered})}>
        <div className={cn(styles.row, styles.image, {[ styles.imageOpen ]: !isHovered})}
             style={{width: (isHovered ? "200px" : "86px")}}>
          <img src={photo} alt="Photo"/>
          {isHovered && <div>
            {title} <br/>
            {description}
          </div>}
        </div>
        {isHovered &&
          <>
            <div className={styles.select}>
              {tariffOptions.find(el => el.value === tariff)?.label}
            </div>
            <div className={styles.amount}>
              <Minus onClick={() => decrease()}/>
              {count}
              <Plus onClick={() => increase()}/>
            </div>
            <div className={styles.price}>
              {data?.totalPrice ? `${formatThousand(data?.totalPrice)} ₽` : `${formatThousand(totalPrice)} ₽`}
            </div>
            <div className={styles.delete} onClick={() => mutateDelete(id)}>
              <Delete/>
            </div>
          </>}
      </div>
    </div>
  )
}