import { RealEstateView } from "../mode";
import { useLoadApplicationRealEstate } from "../../../../service/api/queries";
import { useGetNavigation } from "../helpers";

type Props = {
  applicationId: string;
};

export const RealEstatePageNoTabs = ({applicationId}: Props): JSX.Element => {

  const {realEstate, refetch} = useLoadApplicationRealEstate({id: applicationId});

  const {blocksIds} = useGetNavigation({
    realEstate,
  });

  return (
    <RealEstateView
        realEstate={realEstate}
        refetch={refetch}
        id={applicationId}
        blocksIds={blocksIds}
      />
  );
};
