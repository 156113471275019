import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { QuizQuestion } from "../../../../types";

import { CreateQuest } from "./components";

type Props = {
	questions: QuizQuestion[];
	applicationId: string;
	onChange: () => any;
	register: UseFormRegister<any>;
	setValue: UseFormSetValue<any>;
	getValues: UseFormGetValues<any>;
	control: Control<any>;
	refetch: () => void;
	disabled?: boolean;
};

export const QuizForm = ({
	questions,
	applicationId,
	onChange,
	register,
	setValue,
	getValues,
	control,
	refetch,
	disabled,
}: Props): JSX.Element => (
	<div>
			{questions.map((quest) => (
				<CreateQuest
					applicationId={applicationId}
					key={quest.questionText}
					quest={quest}
					onChange={onChange}
					register={register}
					setValue={setValue}
					getValues={getValues}
					control={control}
					refetch={refetch}
					disabled={disabled}
				/>
			))}
		</div>
);
