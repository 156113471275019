import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/real-estate/load-official-data/DEFAULT_QN";

type UseOfficialData = {
	id?: string;
};

export const useOfficialData = ({ id }: UseOfficialData) => {
	const loadOfficialData = () => api.realEstate.getOfficialData(id || "");

	const { data, refetch, isFetching } = useQuery(DEFAULT_QN, loadOfficialData, {
		enabled: !!id,
	});

	return {
		officialData: data?.data,
		isFetching,
		refetch,
	};
};
