import { RoundInput } from "../../../../../index";
import { List } from "./components";
import { OptionsListWrapper } from "../../../components";
import { DefaultSelectProps, MultiLayerSelectOption } from "../../../../../../../types";

import selectStyles from "../../../components/selectStyles.module.scss";

type FOption = MultiLayerSelectOption & {
  hidden?: boolean;
  hideChildren?: boolean;
};

type Props = {
  alwaysOpen?: boolean;
  filteredOption: FOption[];
  isLoading?: boolean;
  searchVal: string;
  selectOne?: boolean;
  selected: string[];
  selectAllLowerLevel: (value: string, layer: 0 | 1 | 2) => MultiLayerSelectOption[];
  setHiddenOptions: ({value, layer}: MultiLayerSelectOption) => void;
  selectItem: ({value, layer}: MultiLayerSelectOption) => void;
  isListOpen: boolean;
  onSearch: (value: string) => void;
  showLastCheckbox?: boolean
} & Pick<DefaultSelectProps, "classNames" | "size" | "isSearchable">;

export const Options = (
  {
    filteredOption,
    alwaysOpen,
    isLoading,
    searchVal,
    classNames,
    selectOne,
    selectAllLowerLevel,
    setHiddenOptions,
    selectItem,
    selected,
    isListOpen,
    size,
    isSearchable,
    onSearch,
    showLastCheckbox
  }: Props): JSX.Element => (
  <>
    {alwaysOpen ? (
      <div className={selectStyles.alwayOpenOption}>
        <List
          filteredOption={filteredOption}
          classNames={classNames}
          isLoading={isLoading}
          searchVal={searchVal}
          selectOne={selectOne}
          selectAllLowerLevel={selectAllLowerLevel}
          setHiddenOptions={setHiddenOptions}
          selectItem={selectItem}
          selected={selected}
          showLastCheckbox={showLastCheckbox}
        />
      </div>
    ) : (
      <OptionsListWrapper
        isListOpen={isListOpen}
        classNames={classNames}
        size={size}
        thereIsOptions={!!filteredOption.length}
        searchChildren={
          isSearchable && (
            <RoundInput
              className={selectStyles.searchInput}
              name="search"
              value={searchVal}
              onChange={onSearch}
              placeholder="Поиск"
              append="search"
            />
          )
        }
      >
        <List
          filteredOption={filteredOption}
          classNames={classNames}
          isLoading={isLoading}
          searchVal={searchVal}
          selectOne={selectOne}
          selectAllLowerLevel={selectAllLowerLevel}
          setHiddenOptions={setHiddenOptions}
          selectItem={selectItem}
          selected={selected}
          showLastCheckbox={showLastCheckbox}
        />
      </OptionsListWrapper>
    )}
  </>
);
