import cn from "classnames";
import { Pagination } from "@sdelka_crm/sdelka-crm-component-library";

import { useTasksContext } from "../../../../../../../../../service/context/Tasks";
import { GroupTranslationEnum, useGroupedTasks } from "./helpers";
import { NotFound, Skeleton, TasksGroupedList } from "./components";

import styles from "./index.module.scss";

type Props = {
  className?: string;
  isMobile?: boolean
};

export const TasksList = ({className, isMobile}: Props): JSX.Element => {
  const {
    tasksQuery: {data: tasks, total = 0, page, setPage, take, isFetching},
  } = useTasksContext();

  const groupedTasks = useGroupedTasks(tasks);

  const tasksNotFound = tasks?.length === 0;

  if (isFetching) {
    return (
      <div className={cn(styles.root, className)}>
        <Skeleton/>
      </div>
    );
  }

  if (tasksNotFound) {
    return (
      <div className={cn(styles.root, className)}>
        <NotFound/>
      </div>
    );
  }

  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.groups}>
        {groupedTasks.map(([group, items]) => (
          <TasksGroupedList
            key={group}
            groupName={GroupTranslationEnum[ group ]}
            tasks={items}
            isMobile={isMobile}
          />
        ))}
      </div>

      <Pagination
        currentPage={page}
        onPageChange={setPage}
        pageSize={take}
        totalCount={total}
        classNames={{root: styles.pagination}}
      />
    </div>
  );
};
