import { KindType, RealEstateWithSchema } from "../../../../types";
import { useQuery } from "react-query";
import { api } from "../../api";

export const LOAD_APPLICATION_REAL_ESTATE_DEFAULT_QN =
  "sdelka/real-estate/load-application-real-estate/DEFAULT_QN";
export const LOAD_APPLICATION_REAL_ESTATE_EDIT_QN =
  "sdelka/real-estate/load-application-real-estate-edit/DEFAULT_QN";

type Props = {
  id: string;
  enabled?: boolean;
  queryName?: string;
  onSuccess?: () => void;
  onError?: () => void;
  kind?: KindType;
};

type Return = {
  realEstate: RealEstateWithSchema | undefined;
  isLoading: boolean;
  isFetching: boolean;
  refetch: () => void;
  remove: () => void;
};

type UseLoadApplicationRealEstate = (props: Props) => Return;

export const useLoadApplicationRealEstate: UseLoadApplicationRealEstate = (
  {
    id,
    enabled = true,
    queryName,
    onError,
    onSuccess,
    kind
  }) => {
  const loadRealEstate = () => kind === KindType.DEAL ? api.dealRealEstate.get({id})
      : api.applicationRealEstate.get({id});

  const defaultQueryName = `${LOAD_APPLICATION_REAL_ESTATE_DEFAULT_QN}/${id}`;

  const {data, refetch, remove, isLoading, isFetching} = useQuery(
    queryName || defaultQueryName,
    loadRealEstate,
    {
      enabled: !!enabled && Boolean(id) && id !== "new",
      onError,
      onSuccess,
    }
  );
  // sort photos by order field
  data?.data?.photos?.sort((a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }

    return 0;
  });

  return {
    realEstate: data?.data,
    isLoading,
    isFetching,
    refetch,
    remove,
  };
};
