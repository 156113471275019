import styles from "./index.module.scss"
import { useDispatch } from "react-redux";
import { selectDevice, useSelect } from "../../../../../../../service/redux/selectors";
import { openModal } from "../../../../../../../service/redux/modules/modal/modal.action";
import { ConfirmationCodeModal } from "./ConfirmationCodeModal";

export const useOpenConfirmationCodeModal = () => {
  const dispatch = useDispatch();
  const {isMobile} = useSelect(selectDevice)


  const openConfirmationCodeModal = () =>
    dispatch(
      openModal({
        title: <div className={styles.heading}>Подтверждение согласия</div>,
        body: <ConfirmationCodeModal/>,
        width: isMobile ? 500 : 660,
        classNames: {
          contentWrapper: isMobile ? styles.modal : "",
          buttonClose: isMobile ? styles.modalClose : ""
        }
      })
    );

  return {
    openConfirmationCodeModal,
  };

}