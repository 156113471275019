import { useEffect, useState } from "react";
import { RealEstateTabs, SidebarLayout } from "../../UI";
import { AdvertisedObjectsTable, Filters } from "./components";
import { useLoadApplicationAds } from "../../../service/api/queries";
import { DEFAULT_FILTER } from "../../../service/api/helpers";
import { useFilterAction } from "./helpers";
import { AdStatusEnum, KindType } from "../../../types";
import styles from "./index.module.scss";
import { AdListHeader } from "./components/AdvertisedObjectsList/components/header/AdListHeader";
import { AdStatus } from "./helpers/ad-status";
import { selectDevice, useSelect } from "../../../service/redux/selectors";
import { useQueryParams } from "../../../utils/hooks";
import { useOpenApplicationAd } from "../../../service/servise";

export const AdvertisingCabinet = (): JSX.Element => {
  const [, setCurrentStatus] = useState<AdStatusEnum[]>([AdStatusEnum.NotPublished]);
  const {isMobile} = useSelect(selectDevice)
  const {
    realEstatesList,
    isFetching,
    setPage,
    total,
    filter,
    setFilter,
    setTake,
    setSort,
    counts,
    refetch
  } =
    useLoadApplicationAds({
      defaultPage: 1,
      options: {keepPreviousData: true},
      defaultFilter: DEFAULT_FILTER,
    });

  const {changeFilter} = useFilterAction({
    filter,
    setFilter,
    setCurrentStatus,
    setPage,
  });

  const {openApplicationAd} = useOpenApplicationAd({
    atDrawerInDrawer: false
  })

  const {queryParams} = useQueryParams<{ applicationId: string }>([
    {name: "applicationId"},
  ]);

  useEffect(() => {
    if (queryParams.applicationId) {
      openApplicationAd({
        applicationId: queryParams.applicationId,
        hasMap: true,
        kind: KindType.APPLICATION,
        refetchParent: refetch,
      })
    }
  }, [])

  const handleFilterClick = (status: AdStatusEnum | string, fieldName: string) => {
    const newSelected = filter?.[ fieldName ] ? [...filter[ fieldName ]] : [];
    if (newSelected && newSelected.includes(status)) {
      const index = newSelected.indexOf(status);
      if (index > -1) {
        newSelected.splice(index, 1);
        setFilter({
          ...filter,
          [ fieldName ]: [...newSelected],
        });
        return;
      }
    }
    newSelected.push(status);
    setFilter({
      ...filter,
      [ fieldName ]: [...newSelected],
    });
  };

  return (
    <SidebarLayout
      fullSize
      isMobile={isMobile}
      hat={!isMobile ? {
        element: <Filters filter={filter} changeFilter={changeFilter}/>,
        elementHeight: 50,
      } : {element: <></>, elementHeight: 0}}
    >
      <div className={styles.root}>
        <div className={styles.tabs}>
          <RealEstateTabs/>
        </div>
        {!isMobile &&
          <div className={styles.statusFilter}>
            <div className={styles.statusArea}>
              {!isMobile && <AdStatus
                filter={filter}
                handleFilterClick={handleFilterClick}
                setFilter={setFilter}
              />}
            </div>
          </div>}
        <div className={styles.content}>
          <AdListHeader
            total={total}
            setTake={setTake}
            setSort={setSort}
            isLoading={isFetching}
            isMobile={isMobile}
          />
          <AdvertisedObjectsTable
            objects={realEstatesList}
            isFetching={isFetching}
            counts={counts}
            isMobile={isMobile}
          />
          {/* {!isFetching && total > 0 && ( */}
          {/*  <Pagination */}
          {/*    totalCount={total} */}
          {/*    isLoading={isFetching} */}
          {/*    pageSize={take} */}
          {/*    currentPage={page} */}
          {/*    onPageChange={(nextPage: number) => handlePageChange(nextPage)} */}
          {/*    classNames={{root: styles.pagination}} */}
          {/*  /> */}
          {/* )} */}
        </div>
      </div>
    </SidebarLayout>
  );
};
