import styles from "./index.module.scss"
import { ReactComponent as DocumentIcon } from "../../../../../assets/icons/document.svg";
import { ReactComponent as SecondaryHousingIcon } from "../../../../../assets/icons/secondary-housing.svg";
import { ReactComponent as Service } from "../../../../../assets/icons/ShopSidebar.svg";
import { ReactComponent as Add } from "../../../../../assets/icons/iconButtonAddBlue.svg";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { SideBarMenu } from "../../../../../types";
import { paths } from "../../../../../utils/paths";
import { ReactComponent as Roll } from "../../../../../assets/icons/roll.svg";
import { openModal } from "../../../../../service/redux/modules/modal/modal.action";
import { AddModal } from "../Sidebar/components";
import { setFiltersShowButton } from "../../../../../service/redux/modules/object-list/object-list.action";
import cn from "classnames";
import { selectCurrentAccount, useSelect } from "../../../../../service/redux/selectors";
import { Avatar } from "../../../Avatar";
import { useOpenProfileCard } from "../../../../../service/servise";
import { ReactComponent as Sdelka } from "../../../../../assets/icons/sdelkaIcon.svg";
import { ReactComponent as PersonGroup } from "../../../../../assets/icons/personGroupIcon.svg";

const menuItemIdPrefix = "bottombar-menu-item-";

export const BottomBar = () => {

  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const {currentAccount} = useSelect(selectCurrentAccount)

  const handleOpenCreateModal = () => {
    dispatch(
      openModal({
        title: "Выберите, что хотите создать",
        body: <AddModal/>,
        classNames: {
          contentWrapper: styles.modal,
          buttonClose: styles.modalClose
        }
      })
    );
  };

  const {openProfileDrawer} = useOpenProfileCard({isMobile: true})

  const bottomBarMenu: SideBarMenu = [
    {
      Icon: <SecondaryHousingIcon/>,
      text: "Объекты",
      url: paths.objectsPath.list,
    },
    {
      Icon: <DocumentIcon/>,
      text: "CRM",
      url: paths.leadsAndDealsPath.list,
    },
    {
      Icon: <Roll/>,
      text: "Задачи",
      url: paths.tasks.list,
    },
    {
      Icon: <Service style={{opacity: "0.7"}}/>,
      text: "Магазин",
      url: paths.services.list,
    },
    {
      Icon: <Avatar
        isLoading={!currentAccount}
        imgSrc={currentAccount?.account.avatar}
        name={currentAccount?.account.name}
        lastName={currentAccount?.account.lastName}
        color="primary"
        classNames={{
          root: styles.avatar,
        }}
      />,
      text: "Аккаунт",
      url: "",
      action: () => openProfileDrawer(currentAccount?.id)
    },
  ];

  const sidebarMenuUnAuthorized: SideBarMenu = [
    {
      Icon: <Sdelka/>,
      text: "О платформе",
      url: paths.dashboardPath,
      isInnerMenu: false,
    },
    {
      Icon: <PersonGroup/>,
      text: "Клубы",
      url: `${paths.selectOffice.list}?tab=SELECT_OFFICE`,
      isInnerMenu: false,
    },
  ]

  const authorizedMenu = currentAccount !== undefined ? bottomBarMenu : sidebarMenuUnAuthorized

  const handleLinkClick = (url: string): void => {
    if (url === paths.objectsPath.list) {
      dispatch(setFiltersShowButton(true));
    }
  };

  return (
    <>
      {currentAccount !== undefined &&
        <div className={styles.bottomBar_root}>
          <div className={styles.buttonAdd} onClick={handleOpenCreateModal}>
            <Add/>
          </div>
          {currentAccount && authorizedMenu.map((it) => (
            <div
              id={`${menuItemIdPrefix}${it.url}`}
              key={it.url}
              className={cn(styles.sidebarMenuItem, {
                [ styles.sidebarMenuItemHide ]: it.hide,
              })}
            >
              {it.url !== "" ? <Link
                  to={it.url}
                  onClick={() => handleLinkClick(it.url)}
                  className={cn(styles.sidebarMenuItem__link, {
                    [ styles.sidebarMenuItem__link_active ]: pathname.includes(it.url),
                  })}
                >
                  <div className={styles.sidebarMenuItem__icon}>{it.Icon}</div>
                  {it.children}
                </Link>
                : <div onClick={it.action}>
                  <div className={styles.sidebarMenuItem__button}>{it.Icon}</div>
                </div>
              }
            </div>
          ))}
        </div>}
    </>
  )
}