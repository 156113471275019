import { DefaultTasksFiltersType } from "../../../../../../types";

export const defaultTopFilters: DefaultTasksFiltersType = {
	taskType: [],
	responsibleUserId: [],
	creatorId: [],
	participantUserId: [],
	createdAtFrom: "",
	createdAtTo: "",
	deadlineFrom: "",
	deadlineTo: "",
	status: []
};
