import { useEffect, useState } from "react";
import { Pagination } from "@sdelka_crm/sdelka-crm-component-library";
import { SidebarLayout, Table } from "../../UI";
import { ContactsFilters } from "./ContactsFilters";
import { useColumns } from "./columns";
import { useContacts } from "../../../service/api/queries";
import { RoleGuard } from "../../../service/router/RoleGuard";
import { useCreateContact } from "./helpers";
import { useQueryParams } from "../../../utils/hooks";
import { capitalizeFirstLetter } from "../../../utils/formatters";
import { Contact, ContactFilter, ContactTypeMap } from "../../../types";
import styles from "./index.module.scss";
import { selectCurrentAccount, useSelect } from "../../../service/redux/selectors";
import cn from "classnames";
import { useOpenContact, useOpenUnknownContact } from "../../../service/servise";
import { MobileAppFilterButtons } from "../LeadsAndDeals/components";

const CONTACT_INFO_WIDTH = 800;

type Props = {
  isMobile?: boolean
  contactsList: Contact[]
  total: number
  page: number
  take: number
  isFetching: boolean
  setPage: React.Dispatch<React.SetStateAction<number>>
  refetch: () => void,
  filter: ContactFilter
  setFilter: React.Dispatch<React.SetStateAction<ContactFilter>>
}

export const Contacts = (
  {
    isMobile,
    contactsList,
    total,
    page,
    take,
    isFetching,
    setPage,
    refetch,
    filter,
    setFilter
  }: Props): JSX.Element => {
  const {queryParams} = useQueryParams<{
    contactId: string;
    phone: string;
  }>([{name: "contactId"}, {name: "phone"}]);
  const {currentAccount} = useSelect(selectCurrentAccount);
  const alertMargin = currentAccount?.employerSubscribtionFinished ? "20" : (currentAccount?.isPremium === false ? "50" : "0")

  const [contextClick, setContextClick] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [checked, setChecked] = useState<string[]>([]);

  useCreateContact({
    refetchContacts: refetch,
    drawerWidth: CONTACT_INFO_WIDTH,
  });

  const columns = useColumns(checked, setChecked, contextClick, refetch, isMobile);

  const {openContact} = useOpenContact()
  const {openUnknownContact} = useOpenUnknownContact()

  const tableRowClick = ({id}) => {
    openContact({id, refetchList: refetch})
  }

  useEffect(() => {
    if (selectAll) {
      setChecked([...contactsList.map((item) => item.id)]);
    } else {
      setChecked([]);
    }
  }, [selectAll]);


  const openUnknownContactDrawer = (phone: string) => {
    openUnknownContact({phone})
  };

  useEffect(() => {
    if (queryParams.phone) {
      openUnknownContactDrawer(queryParams.phone);
    }
  }, [queryParams.phone]);

  const transformTitle = (title: string): JSX.Element => (
    <div className={styles.tableGroupTitle}>{capitalizeFirstLetter(ContactTypeMap[ title ])}</div>
  );

  const onPageChange = (pageNumber: number) => {
    setChecked([]);
    setSelectAll(false);
    setPage(pageNumber);
  };

  const [filtersCount] = useState(Number(Object.values(filter).filter(el =>
    el !== undefined || el !== "" || Object.keys(el).length !== 0 || (el as Array<string>).length !== 0).length))

  return (
    <RoleGuard only="estateAgencyUser">
      <SidebarLayout
        isMobile={isMobile}
        withOutLabel
        isContact
        hat={!isMobile ? {
          element: isMobile ? <></> :
            <ContactsFilters filter={filter} formSubmit={setFilter} setPage={setPage} alertMargin={alertMargin}/>,
          elementHeight: 0,
        } : {element: <></>, elementHeight: 0}}
      >
        <div className={styles.container}>
          <div className={styles.tableContainer}>
            {contactsList.length > 0 ?
              <Table<Contact>
                idKey="id"
                groupKey="type"
                transformGroupTitle={transformTitle}
                data={contactsList}
                columns={columns}
                rowClass={cn(styles.row, {[ styles.isMobile ]: isMobile})}
                colClass={styles.contactsTableCol}
                onRowClick={tableRowClick}
                isMobile={isMobile}
                activeRow={{
                  accessor: queryParams.contactId || "",
                  activeId: queryParams.contactId || "",
                  onContextMenu: ({id}) => setContextClick(id),
                }}
                loading={{
                  isLoading: isFetching,
                  rowsCount: 10,
                }}
              />
              : <div className={styles.empty}>Таких контактов не найдено</div>
            }
          </div>
          <Pagination
            totalCount={total}
            pageSize={take}
            currentPage={page}
            onPageChange={onPageChange}
            isLoading={isFetching}
            classNames={{root: styles.pagination}}
          />
        </div>
      </SidebarLayout>
    </RoleGuard>
  );
};
