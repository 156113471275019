import React, { MouseEvent, useEffect, useState } from "react";
import { ReactComponent as Play } from "../../../../../../assets/icons/iconPlay.svg";
import { ReactComponent as Stop } from "../../../../../../assets/icons/iconPause.svg";
import styles from "./index.module.scss"


type Props = {
  audio:  HTMLAudioElement
}

export const AudioPlayer = ({audio}: Props): JSX.Element => {

  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    window.addEventListener('mousedown', (e: any) => {
      const elementId = e.target?.id
      if (elementId !== "trafficCard") {
        setPlaying(false)
        audio.pause()
      }
    });
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
      window.removeEventListener('mousedown', (e: any) => {
        const elementId = e.target?.id
        if (elementId !== "trafficCard") {
          setPlaying(false)
          audio.pause()
        }
      });
    };
  }, [audio]);


  const clickHandler = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    setPlaying(!playing)
    if (!playing) {
      audio.play()
    } else {
      audio.pause()
    }
  };

  return (
    <div
      className={styles.root}
      onClick={clickHandler}
    >
      {playing ? <Stop/> : <Play/>}
    </div>
  );
};
