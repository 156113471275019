import { RealEstate, RealEstateFilter, RealEstateSort } from "../../../../types";
import { Dispatch, SetStateAction } from "react";
import { useLoadApplicationRealEstates, useLoadParsedRealEstates } from "../../../../service/api/queries";
import { defaultValues } from "../../../../utils/clientObjects";

// type TabType = "PARSED" | "AGENCY" | "SDELKA";
type TabType = "PARSED" | "AGENCY"

enum TabTypeEnum {
	PARSED = 0,
	AGENCY = 1,
	// SDELKA = 2,
}

type Props = {
	tab?: TabType;
	defaultPage: number;
};

type Return = {
	list: RealEstate[];
	isFetching: boolean;
	total: number;
	take: number;
	setTake: Dispatch<SetStateAction<number>>;
	page: number;
	setPage: Dispatch<SetStateAction<number>>;
	sort?: RealEstateSort;
	setSort: Dispatch<SetStateAction<RealEstateSort | undefined>>;
	filter: RealEstateFilter;
	setFilter: Dispatch<SetStateAction<RealEstateFilter>>;
	refetch: () => void
};

type UseLoadData = (props: Props) => Return;

export const useLoadData: UseLoadData = ({ tab = "PARSED", defaultPage }) => {
	const {
		parsedRealEstatesList,
		isFetching: isFetchingParsedRealEstates,
		total: totalParsedRealEstates,
		take: takeParsedRealEstates,
		setTake: setTakeParsedRealEstates,
		page: pageParsedRealEstates,
		setPage: setPageParsedRealEstates,
		sort: sortParsedRealEstates,
		setSort: setSortParsedRealEstates,
		filter: filterParsedRealEstates,
		setFilter: setFilterParsedRealEstates,
		refetch: refetchParser
	} = useLoadParsedRealEstates({
		defaultPage,
		options: { keepPreviousData: true },
		defaultFilter: defaultValues,
		enabled: TabTypeEnum[tab] === TabTypeEnum.PARSED,
	});

	const {
		realEstatesList,
		isFetching: isFetchingRealEstates,
		total: totalRealEstates,
		take: takeRealEstates,
		setTake: setTakeRealEstates,
		page: pageRealEstates,
		setPage: setPageRealEstates,
		sort: sortRealEstates,
		setSort: setSortRealEstates,
		filter: filterRealEstates,
		setFilter: setFilterRealEstates,
		refetch: refetchApp
	} = useLoadApplicationRealEstates({
		defaultPage,
		options: { keepPreviousData: true },
		defaultFilter: defaultValues,
		enabled: TabTypeEnum[tab] === TabTypeEnum.AGENCY,
	});

	const TABS: Record<TabType, Return> = {
		PARSED: {
			list: parsedRealEstatesList,
			isFetching: isFetchingParsedRealEstates,
			total: totalParsedRealEstates,
			take: takeParsedRealEstates,
			setTake: setTakeParsedRealEstates,
			page: pageParsedRealEstates,
			setPage: setPageParsedRealEstates,
			sort: sortParsedRealEstates,
			setSort: setSortParsedRealEstates,
			filter: filterParsedRealEstates,
			setFilter: setFilterParsedRealEstates,
		  refetch: refetchParser
		},
		AGENCY: {
			list: realEstatesList,
			isFetching: isFetchingRealEstates,
			total: totalRealEstates,
			take: takeRealEstates,
			setTake: setTakeRealEstates,
			page: pageRealEstates,
			setPage: setPageRealEstates,
			sort: sortRealEstates,
			setSort: setSortRealEstates,
			filter: filterRealEstates,
			setFilter: setFilterRealEstates,
		  refetch: refetchApp
		},
		// SDELKA: {
		// 	list: parsedRealEstatesList,
		// 	isFetching: isFetchingParsedRealEstates,
		// 	total: totalParsedRealEstates,
		// 	take: takeParsedRealEstates,
		// 	setTake: setTakeParsedRealEstates,
		// 	page: pageParsedRealEstates,
		// 	setPage: setPageParsedRealEstates,
		// 	sort: sortParsedRealEstates,
		// 	setSort: setSortParsedRealEstates,
		// 	filter: filterParsedRealEstates,
		// 	setFilter: setFilterParsedRealEstates,
		// 	refetch: refetchParser
		// },
	};

	const { list, isFetching, total, take, setTake, page, setPage, sort, setSort, filter, setFilter, refetch } =
		TABS[tab];

	return {
		list,
		isFetching,
		total,
		take,
		setTake,
		page,
		setPage,
		sort,
		setSort,
		filter,
		setFilter,
		refetch
	};
};
