import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Loader } from "@sdelka_crm/sdelka-crm-component-library";
import { RealEstateWithSchema, ShowAndViewsTabs, ShowsOrViews, ShowsViewsListResponse } from "../../../types";
import { CardCreation, CardWrapper, Header } from "./components";
import { useLoadApplicationRealEstate, useLoadShowsOrViews } from "../../../service/api/queries";
import {
  setDefaultValues,
  setDefaultValuesForOne,
} from "../../../service/redux/modules/shows-and-views/shows-and-views.action";
import { ReactComponent as IconNotification } from "../../../assets/icons/iconHasNotifications.svg";
import styles from "./index.module.scss";
import { StickyFooter } from "./components/sticky-footer/StickyFooter";
import { selectDevice, useSelect } from "../../../service/redux/selectors";
import { Plug } from "./components/plug/Plug";

type Counters = {
  finished?: number;
  waiting?: number;
  requests?: number,
  hasNewRequest?: boolean,
  hasNewPlnned?: boolean,
};

type Props = {
  applicationId: string;
  type: ShowsOrViews,
};

const showOrViewTypeOptions = ({finished, waiting, hasNewPlnned}: Counters) => [
  {label: `Запланированный (${(waiting || 0)})`, value: "waiting", icon: hasNewPlnned && <IconNotification/>},
  {label: `Завершенный (${finished || 0})`, value: "finished"},
];

export const ShowsAndViews = ({applicationId, type}: Props): JSX.Element => {
  const [fullFetch, setFullFetch] = useState(false);
  const [tab, setTab] = useState<ShowAndViewsTabs>(ShowAndViewsTabs.waiting);
  const dispatch = useDispatch();
  const {isMobile} = useSelect(selectDevice)

  const [showCreateCard, setShowCreateCard] = useState(false);

  const onTypeChange = (value: string | number) => {
    switch (value) {
      case "finished":
        return setTab(ShowAndViewsTabs.finished)
      case "waiting":
        return setTab(ShowAndViewsTabs.waiting)
      case "requests":
        return setTab(ShowAndViewsTabs.requests)
      default:
        return setTab(ShowAndViewsTabs.waiting)
    }
  };

  const plugText = tab === "waiting" ? "Нет запланированных показов" : "Нет проведенных показов"

  const {
    data: list,
    refetch,
    isFetched,
  } = useLoadShowsOrViews({
    applicationId,
    tab,
    type,
  });
  const showOrViewOptions = showOrViewTypeOptions((list?.count || {}) as Counters);

  useEffect(() => {
    if (!isFetched) return;

    setFullFetch(true);
  }, [isFetched]);

  const {control} = useForm({
    defaultValues: {
      showOrViewType: showOrViewOptions[ 0 ].value,
    },
  });

  const onStartShow = (id: string) => {
    setShowCreateCard(false);
    refetch();
    dispatch(setDefaultValuesForOne(id));
  };

  useEffect(() => {
    if (isFetched) {
      dispatch(setDefaultValues((list as ShowsViewsListResponse).data.map((item) => item.id)));
    }
  }, [isFetched]);

  const isViews = type === "views";

  const {realEstate, isLoading} = useLoadApplicationRealEstate({
    id: applicationId,
    enabled: !isViews,
  });
  const disabled = isLoading || (isViews && Boolean(realEstate));

  return (
    <>
      <div className={styles.root}>
        {/* <Statistic */}
        {/*  applicationId={applicationId} */}
        {/*  whoIsClient={whoIsClient} */}
        {/*  isFromShows */}
        {/*  isMobile={isMobile} */}
        {/* /> */}
        <Header
          name="showOrViewType"
          control={control}
          options={showOrViewOptions}
          onChange={onTypeChange}
        />
        {showCreateCard && !disabled && (
          <div className={styles.cardCreation}>
            <CardCreation
              objectData={realEstate as RealEstateWithSchema}
              isViews={isViews}
              applicationId={applicationId}
              onStartShow={onStartShow}
            />
          </div>
        )}
        <Loader height={200} style={{display: !fullFetch ? "block" : "none"}}/>
        <div className={styles.cardWrapper} style={{display: !fullFetch ? "none" : "block"}}>
          {list?.data && list.data.length > 0 ? list?.data.map((item) => (
              <CardWrapper
                key={item.id}
                item={item}
                onSuccess={refetch}
                isViews={isViews}
                isMobile={isMobile}
                hasSuccessShow={list?.hasSuccessShow}
                hasSuccessView={list?.hasSuccessView}
              />
            ))
            : <Plug plugText={plugText} mini={isMobile} rootClassName={styles.plug}/>
          }
        </div>
      </div>
      {list && <StickyFooter
        isViews={isViews}
        applicationId={applicationId}
        refetch={refetch}
        hasSuccessShow={list?.hasSuccessShow}
        hasSuccessView={list?.hasSuccessView}
      />}
    </>
  );
};
