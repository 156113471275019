import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { Loader, Tooltip } from "@sdelka_crm/sdelka-crm-component-library";
import { closeDrawer, closeDrawerInDrawer } from "../../../../service/redux/modules/drawer/drawer.action";
import { useLoadCompilation } from "../../../../service/api/queries";
import styles from "./index.module.scss";
import { ReactComponent as BuildingsIcon } from "../../../../assets/icons/buildings.svg";
import { useState } from "react";
import { ReactComponent as HasNotifications } from "../../../../assets/icons/iconHasNotifications.svg";
import { getPriceLabel } from "../../../../utils/clientObjects";
import { CardApplicationContact } from "../card-application/card-application-contact/CardApplicationContact";
import {
  CardApplicationResponsible
} from "../card-application/card-application-responsible/CardApplicationResponsible";
import { Popover } from "../../index";
import { ReactComponent as SignedByMyAgency } from "../../../../assets/icons/signed.svg";
import { ReactComponent as Like } from "../../../../assets/icons/iconLike.svg";
import { ReactComponent as LikeALot } from "../../../../assets/icons/iconLikeALot.svg";
import { ReactComponent as Dislike } from "../../../../assets/icons/iconDidNotLike.svg";
import { ReactComponent as WantToSee } from "../../../../assets/icons/iconWantToSee.svg";
import { Compilation } from "../../../../types";

type Props = {
  applicationId: string;
  collection?: Compilation
  isFetchingCollection?: boolean
  classnames?: {
    body?: string
    countBlock?: string
  }
  onCardClick?: () => void
  fromObject?: boolean
  mini?: boolean
  isFromCompilation?: boolean
};

export const CardObjectBuyElement = (
  {
    applicationId,
    collection,
    isFetchingCollection,
    classnames,
    onCardClick,
    fromObject,
    mini,
    isFromCompilation
  }: Props): JSX.Element => {
  let {compilation, isFetching} = useLoadCompilation({applicationId});
  if (collection) {
    compilation = collection
  }
  if (isFetchingCollection) {
    isFetching = isFetchingCollection
  }

  const mainPhoto = compilation?.mainPhoto
  const icons = compilation?.icons
  const title = compilation?.title
  const contact = compilation?.contact
  const responsibleUser = compilation?.responsibleUser
  const priceRange = compilation?.priceRange
  const shortType = compilation?.shortType
  const stage = compilation?.stage
  const stageNo = compilation?.stageNo

  const dispatch = useDispatch();
  const {push, location} = useHistory();

  const countBlocks = compilation
    ? [
      {count: `Всего ${compilation.totalCount}`, label: "totalCount"},
      {count: compilation.didNotLikeCount, label: "didNotLikeCount", icon: <Dislike/>},
      {count: compilation.likeALotCount, icon: <LikeALot/>, label: "Like"},
    ]
    : [];

  const countBlocksMini = compilation
    ? [
      {count: compilation.didNotLikeCount, label: "didNotLikeCount", icon: <Dislike/>},
      {count: compilation.likeALotCount, icon: <LikeALot/>, label: "Like"},
    ]
    : [];

  const blocks = mini ? countBlocksMini : countBlocks

  const handleCompilationClick = () => {
    if (isFromCompilation) { /* empty */
    } else if (onCardClick) {
      onCardClick()
    } else {
      const encodedBackPath = encodeURIComponent(`${location.pathname}${location.search}`);
      dispatch(closeDrawerInDrawer())
      setTimeout(() => {
        dispatch(closeDrawer());
      }, 50)

      push(
        `/real-estate?compilation=true&compilationApplicationId=${applicationId}&backPath=${encodedBackPath}&fromObject=${fromObject}`
      );
    }
  };

  const priceRangeFrom = priceRange?.from
  const priceRangeTo = priceRange?.to
  const [isImageError, setIsImageError] = useState(false);
  const onImageError = () => setIsImageError(true);

  const getColor = () => {
    switch (shortType?.kind) {
      case 'preApplication':
        return "#D5D3CE";
      case  'application':
        return "#30CFB1"
      case 'deal':
        return "#336BEB"
      default:
        return "#000"
    }
  }

  return (
    <div
      className={cn(styles.root, classnames?.body, {[ styles.mini ]: mini})}
      onClick={handleCompilationClick}
    >
      <div
        className={cn(styles.loading, "animate__animated", "animate__faster", "animate__fadeIn", {
          [ styles.loadingHide ]: !isFetching,
        })}
      >
        <Loader/>
      </div>
      <div className={styles.wrapper}>
        <div
          className={cn(styles.img, {
            [ styles.noImg ]: !mainPhoto || isImageError,
          })}
        >
          {mainPhoto && !isImageError ? (
            <img onError={onImageError} alt="object" src={mainPhoto}/>
          ) : (
            <BuildingsIcon/>
          )}
        </div>
        <div className={cn(styles.flexRow, styles.content)}>
          <div className={styles.flexColumn}>
            <div className={styles.title}>
              {icons?.hasNotifications &&
                <div className={styles.notification}>
                  <HasNotifications/>
                </div>}
              <span>{title}</span>
              {icons?.signedByMyAgency &&
                <Tooltip
                  classNames={{
                    root: styles.tooltipDescription,
                    card: styles.tooltipCard,
                  }}
                  CustomIcon={SignedByMyAgency}
                  iconSize="medium"
                >
                  Есть подписанный агентский договор
                </Tooltip>}
            </div>
            <div className={styles.countsContainer}>
              {blocks.map((count) => (
                <div key={count.label} className={cn(styles.countBlock, classnames?.countBlock)}>
                  <div className={cn(styles.count)}>{count.count}</div>
                  {count.icon}
                </div>
              ))}
            </div>
            <CardApplicationContact contact={contact}/>
          </div>
          <div className={styles.flexColumn}>
            {priceRange &&
              <span className={styles.price}>
                    {getPriceLabel({priceRangeFrom})} <br/>
                {getPriceLabel({priceRangeTo})}
              </span>}
            <CardApplicationResponsible responsible={responsibleUser}/>
          </div>
        </div>
      </div>
      <div style={{background: getColor()}} className={styles.stage}>
        <Popover
          classNames={{over: styles.stagePopup}}
          overElement={
            <>
								<span className={styles.stageCircle} style={{background: getColor()}}>
									{stageNo}
								</span>
              {`Этап ${stageNo}: ${stage}`}
            </>
          }
        />
      </div>
    </div>
  );
};
