import { CardObjectMini } from "../../../../../UI";
import { RealEstate } from "../../../../../../types";

import { ReactComponent as Edit } from "../../../../../../assets/icons/edit.svg";
import styles from "../index.module.scss";

type Props = {
	onClick?: () => void;
	object?: RealEstate;
};

export const CardCreationObject = ({ onClick, object }: Props): JSX.Element => (
	<>
		{object ? (
			<div className={styles.objectWrapper}>
				<CardObjectMini id={object.id} objectData={object} />

				{onClick && (
					<div onClick={onClick} className={styles.objectEdit}>
						<Edit />
					</div>
				)}
			</div>
		) : (
			<div className={styles.wrapper}>
				<div onClick={onClick} className={styles.button}>
					Выберите объект
				</div>
			</div>
		)}
	</>
);
