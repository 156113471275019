import { Client } from "../../Client";
import { getRequestFilters } from "../../helpers";
import {
	ApplicationListPayload,
	ApplicationListResponse,
	Conditions,
	AddResponsibleToApplication,
	ApplicationUpdatePayload,
	Application,
	AddLeadToApplication,
	ApplicationUserResponse,
	ChangeStagePayload,
	ChangeStageResponse,
	WhoIsClient,
	CreateApplication,
	Bank,
	Contact,
	FinishApplicationPayload,
	DocumentTypesResponse,
	GeneratedDocumentsWarningResponse,
	GeneratedDocumentUrl,
	GeneratedDocuments, DocumentsTabType, DocumentsResponse, DocumentPayload,
} from "../../../../types";

export const DealsService = {
	getClient: ({ applicationId }) => Client.get(`/deal/${applicationId}/whoIsClient`),
	getCard: ({ applicationId }) => Client.get<Application>(`/deal/${applicationId}`),
	getCardBlocks: ({ applicationId, stage }) => Client.get(`/business-process/deal/${applicationId}/blocks/${stage}`),

	contacts: ({ applicationId, type }: { applicationId: string; type?: WhoIsClient }) =>
		Client.get<Contact[]>(`/deal/${applicationId}/contacts-${type}`),
	employee: ({ applicationId }) =>
		Client.get<ApplicationUserResponse[]>(`deal/${applicationId}/employee`),
	putAdittioanlProperties: ({ id, data }: {id:string, data: any}) => Client.put(`/deal/${id}`, data),
	create: ({
		additionalProperties,
		typeId,
		responsibleUserId,
		comment,
		sellerContacts,
		buyerContacts,
		clientObjectId,
	}: CreateApplication) =>
		Client.post("/application", {
			additionalProperties,
			typeId,
			responsibleUserId,
			comment,
			sellerContacts,
			buyerContacts,
			clientObjectId,
		}),
	createFromLead: ({ leadId }: { leadId: string }) => Client.post(`application/fromLead/${leadId}`),
	setContactFromParentLead: ({ applicationId }: { applicationId: string }) =>
		Client.patch(`application/relations-to-leads/${applicationId}/setContactFromLead`),

	deleteLead: ({ applicationId, leadId }: AddLeadToApplication) =>
		Client.delete(`/application/relations-to-leads/${leadId}/${applicationId}`),
	addEmployee: ({ applicationId, responsibleUsers }: AddResponsibleToApplication) =>
		Client.put(`/application/${applicationId}`, { responsibleUsers }),

	deleteResponsible: ({ applicationId, id }: { applicationId: string; id: string }) =>
		Client.delete(`/application/${applicationId}/responsible/${id}`),

	deleteContact: ({ applicationId, id, type }: { applicationId: string; id: string; type: WhoIsClient }) =>
		Client.delete(`/application/${applicationId}/contact-${type}/${id}`),
	updateContact: ({ applicationId, id, type }: { applicationId: string; id: string; type: WhoIsClient }) =>
		Client.post(`/application/${applicationId}/contact-${type}/${id}`),

	updateInfo: ({ applicationId, applicationData }: ApplicationUpdatePayload) =>
		Client.put(`/deal/${applicationId}`, applicationData),

	list: ({ take, skip, search, value }: ApplicationListPayload) => {
		const searchConditions: Conditions = search ? getRequestFilters(search) : [];

		return Client.post<ApplicationListResponse>(
			`/${value}/search`,
			JSON.stringify({
				search: {
					filterType: "and",
					conditions: searchConditions,
				},
				take,
				skip,
			})
		);
	},

	object: ({ applicationId }: { applicationId: string }) =>
		Client.get(`/deal/${applicationId}/object`),

	changeStage: ({ applicationId, stageName }: ChangeStagePayload) =>
		Client.patch<ChangeStageResponse>(`/deal/${applicationId}/stage/${stageName}`),

	remoteBanks: (query: string) => Client.get<Bank[]>(`/application/remote-selection/banks?query=${query}`),

	getBank: (id: string) => Client.get<Bank>(`/application/remote-selection/bank/${id}`),

	createTasks: ({ applicationId, payload }: { applicationId: string; payload: any }) =>
		Client.post(`application/${applicationId}/fastCreateTasks`, payload),

	nextStage: (id: string) => Client.patch(`/application/${id}/nextStage`),

	finish: ({ applicationId, action }: FinishApplicationPayload) =>
		Client.post(`application/${applicationId}/${action}`),

  finishDeal: (id: string) => 	Client.patch(`/deal/${id}/finish`),

	terminateDeal: (id: string) => Client.patch(`/deal/${id}/terminate`),

	getGeneratedDocumentTypes: (id: string) => Client.get<DocumentTypesResponse>(`/deal/${id}/docGen/types`),

	getGeneratedDocumentWarnings: (id: string, type?: string, template?: string) =>
		Client.get<GeneratedDocumentsWarningResponse>(`/deal/${id}/docGen/warnings?type=${type}&template=${template}`),

	getGeneratedDocumentPreview: (id: string, type?: string, template?: string) =>
		Client.get(`/deal/${id}/docGen/preview?type=${type}&template=${template}`),

	generateDocument:  (id: string, type?: string, template?: string) =>
		Client.post<GeneratedDocumentUrl>(`/deal/${id}/docGen/generate?type=${type}&template=${template}`),

	getGeneratedDocuments: (id: string) => Client.get<GeneratedDocuments>(`/deal/${id}/docGen/generatedDocuments`),

	getDocuments: (id: string, tab: DocumentsTabType) => Client.get<DocumentsResponse>(`/deal/${id}/documents?tab=${tab}`),

	addDocument: (id: string, payload: DocumentPayload) => Client.post(`/deal/${id}/document`, payload)
};
