import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Alerter, IndividualInput, toastError } from "../../../../../../UI";
import { useDepartmentDelete, useDepartmentUpdate } from "../../../../../../../service/api/mutations";
import { commonErrors } from "../../../../../../../utils/errors";
import { Department } from "../../../../../../../types";

import { ReactComponent as TrashIcon } from "../../../../../../../assets/icons/trash.svg";
import { ReactComponent as EmployerIcon } from "../../../../../../../assets/icons/employer.svg";
import styles from "./index.module.scss";

type Props = {
	refetchOffices: () => void;
	department: Department;
};

export const OfficeDepartments = ({ refetchOffices, department }: Props): JSX.Element => {
	const [deleteDepartmentState, setDeleteDepartmentState] = useState("");
	const schema = yup.object().shape({
		name: yup.string().required(commonErrors.required).max(30, "Слишком длинное название"),
	});

	const { register, handleSubmit, setError, formState } = useForm<Department>({
		resolver: yupResolver(schema),
		defaultValues: {
			name: department.name,
		},
	});

	const updateDepartment = useDepartmentUpdate({
		onSuccess: () => refetchOffices(),
		setError,
	});

	const deleteDepartment = useDepartmentDelete({
		onSuccess: () => refetchOffices(),
		onError: () => {
			setDeleteDepartmentState(department.id);
			toastError({ text: "Удаление отдела невозможно" });
		},
	});

	const editDepartment = (values: Department) => {
		const payload = {
			...values,
			id: department.id,
		};

		updateDepartment.mutate(payload);
	};

	return (
		<>
			<form onSubmit={handleSubmit(editDepartment)} className={styles.form}>
				<IndividualInput
					name="name"
					defaultValue={department.name}
					isLoading={updateDepartment.isLoading}
					register={register}
					error={formState.errors.name?.message?.toString()}
					options={{
						displayInsteadOfValue: (
							<div key={department.id} className={styles.department}>
								<div className={styles.departmentName}>
									- {department.name}
									<div className={styles.employers}>
										<EmployerIcon />
										{department.users ? department.users.length : "0"}
									</div>
								</div>
							</div>
						),
					}}
				/>

				<TrashIcon onClick={() => deleteDepartment.mutate(department.id)} className={styles.delete} />
			</form>

			{deleteDepartmentState === department.id && (
				<div className={styles.alertWrapper}>
					<Alerter iconPosition="top-left">Для удаления перенесите сотрудников в другой отдел</Alerter>
				</div>
			)}
		</>
	);
};
