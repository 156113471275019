import { useEffect, useState } from "react";

import {
	NativeSelect,
	OptionsList,
	SelectedContainer,
	SelectWrapper,
	SelectError,
	SelectedWrapper,
} from "../components";
import { arrayIsEqual } from "../../../../../utils/arrayIsEqual";
import { DefaultMultiSelectProps, SelectOption } from "../../../../../types";

type MultiSelectProps = {
	options: SelectOption[];
	isSortSelected?: boolean;
} & DefaultMultiSelectProps;

export const MultiSelect = ({
	name,
	defaultValue,
	options,
	register,
	setValue,
	size = "normal",
	onChange,
	placeholder,
	error,
	classNames,
	append,
	prepend,
	required,
	disabled,
	isLoading,
	customSearchFunction,
	isSearchable,
	isSortSelected,
	hideArrow,
	withBorder,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
MultiSelectProps): JSX.Element => {
	const [searchHighLight, setSearchHighLight] = useState<undefined | string>(undefined);
	const [curDefaultValue, setCurDefaultValue] = useState(defaultValue || []);
	const [selected, setSelected] = useState(curDefaultValue);
	const [isListOpen, setIsListOpen] = useState(false);
	const [filteredOption, setFilteredOptions] = useState(options);

	const handleSetListOpen = (value: boolean) => {
		if (disabled) {
			return;
		}

		setIsListOpen(value);
	};

	const selectTrigger = () => {
		if (disabled) {
			return;
		}
		setIsListOpen((prev) => !prev);
	};

	const sortingCheckedOptions = () => {
		if (!isSortSelected) {
			setFilteredOptions(options);

			return;
		}

		const top: SelectOption[] = [];
		const sorted = options.filter((option) => {
			const { value } = option;

			if (selected.includes(value)) {
				top.push(option);

				return false;
			}

			return true;
		});

		setFilteredOptions([...top, ...sorted]);
	};

	useEffect(() => {
		setFilteredOptions(options);
	}, [options, options.length]);

	useEffect(() => {
		sortingCheckedOptions();
		setSearchHighLight(undefined);
	}, [isListOpen]);

	useEffect(() => {
		const isEqual = arrayIsEqual(defaultValue, curDefaultValue);

		if (!isEqual && defaultValue) {
			setCurDefaultValue(defaultValue);
			setSelected(defaultValue);
		}
	}, [defaultValue]);

	useEffect(() => {
		setValue(name, selected);
	}, [selected]);

	const selectItem = (value: string) => {
		// Unselect value (remove from selected array)
		if (selected.includes(value)) {
			const newSelected = selected.filter((i) => i !== value);
			if (onChange) {
				onChange(newSelected);
			}

			return setSelected(newSelected);
		}

		// Select value (concat with selected array)
		const newSelected = selected.concat(value);
		if (onChange) {
			onChange(newSelected);
		}

		return setSelected(newSelected);
	};

	const onSearch = (value) => {
		setSearchHighLight(value);

		if (customSearchFunction) {
			customSearchFunction(value);
		} else {
			setFilteredOptions(
				options.filter((option) =>
					option.label
						.toLowerCase()
						// eslint-disable-next-line no-useless-escape
						.match(value.toLowerCase().replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\[\]\\]/gi, ""))
				)
			);
		}
	};

	return (
		<SelectWrapper
			setListOpen={handleSetListOpen}
			size={size}
			classNames={classNames}
			withBorder={withBorder}
			error={error}
			disabled={disabled}
		>
			<NativeSelect
				name={name}
				disabled={disabled}
				required={required}
				multiple
				register={register}
				options={filteredOption}
			/>

			<SelectedContainer
				isListOpen={isListOpen}
				onSelectClick={selectTrigger}
				append={append}
				prepend={prepend}
				hideArrow={hideArrow}
				disabled={disabled}
				isLoading={isLoading}
				size={size}
				classNames={classNames}
			>
				<SelectedWrapper placeholder={placeholder} thereIsSelected={!!selected?.length}>
					{options
						.filter((option) => selected.includes(option.value))
						.map((option) => option.label)
						.join("/")}
				</SelectedWrapper>
			</SelectedContainer>

			<SelectError error={error} classNames={classNames} />

			<OptionsList
				isListOpen={isListOpen}
				options={filteredOption}
				handleOptionClick={(value: string) => selectItem(value)}
				checkIfSelected={(value: string) => selected?.includes(value)}
				searchHighLight={searchHighLight}
				withIndividualCheckbox={{
					checkedCheck: (value: string) => selected?.includes(value),
				}}
				withRoundInput={isSearchable ? { onSearch } : undefined}
				classNames={classNames}
				size={size}
			/>
		</SelectWrapper>
	);
};
