import styles from "./index.module.scss"
import cn from "classnames";
import { ReactNode } from "react";

type Props = {
  plugText: string | ReactNode
  mini?: boolean
  classNames?: string
  atGP?: boolean
}

export const Plug = ({plugText, mini, classNames, atGP}: Props): JSX.Element => (
    <div className={cn(styles.root, classNames, {[ styles.mini ]: mini, [styles.atGP]: atGP})}>
      <div className={styles.text}>
        {plugText}
      </div>
    </div>
  )