import styles from "./index.module.scss"
import { useEffect, useState } from "react";
import { formatDateWithTime } from "../../../../../../../utils/date";
import cn from "classnames";
import { Button, InfoRow } from "@sdelka_crm/sdelka-crm-component-library";
import { CalendarInPopup, Select, Time, TransparentInput } from "../../../../../../UI";
import { FieldErrors, FieldValues, useForm } from "react-hook-form";
import { ReactComponent as Play } from "../../../../../../../assets/icons/iconVideoPlaySmall.svg";
import { Contact, ContactTypeMap, LeadSourceEnum } from "../../../../../../../types";
import { useFormWatcher } from "../helpers";
import { commonPlaceholders } from "../../../../../../../utils/placeholders";
import { phoneMask } from "../../../../../../../utils/masks";
import {
  useGetAdSources
} from "../../../../../../UI/layout/SidebarLayout/Sidebar/components/AddModal/helpers/useAddLeadModal/components/CreateContactModalForm/helpers";
import { useContacts, useLoadApplications } from "../../../../../../../service/api/queries";
import { Plug } from "../../../plug/Plug";
import {
  ContactCard
} from "../../../../../Application/PreApplicationTemplate/components/AgencyAgrement/components/ContactCard/ContactCard";
import { CardApplication } from "../../../../../../UI/Card/card-application";
import { CardApplicationMini } from "../../../../../../UI/Card/card-application/card-application-mini";
import {
  useContactCreate,
  useCreateShow,
  useCreateShowWithoutApplication
} from "../../../../../../../service/api/mutations";
import { useOpenSuccessCreateModal } from "../success-modal";
import * as yup from "yup";
import { commonErrors } from "../../../../../../../utils/errors";
import { yupResolver } from "@hookform/resolvers/yup";

type SourceOption = {
  label: string;
  value: LeadSourceEnum;
};

type Props = {
  applicationId: string
  refetchList: () => void
  isMobile?: boolean
}

export const CreateShowModal = (
  {
    applicationId,
    refetchList,
    isMobile
  }: Props) => {
  const schema = yup.object().shape({
    phone: yup.string().required(commonErrors.required),
    pseudonym: yup.string().required(commonErrors.required),
    type: yup.string().required(commonErrors.required),
    source: yup.string().required(commonErrors.required),
  })

  const dateSchema = yup.object().shape({
    deadlineDate: yup
    .string()
      .test("deadlineDate", "Невалидная дата", (date) => (new Date(date || "0")).getTime() < Date.now())
      .required(),
  })

  const [currentTab, setCurrentTab] = useState(1)
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined)
  const [selectedContact, setSelectedContact] = useState<Contact | undefined>(undefined)
  const [selectedAppId, setSelectedAppId] = useState<string | undefined>(undefined)
  const [error, setError] = useState<FieldErrors<FieldValues> | undefined>(undefined)

  const {register, getValues, setValue, control, handleSubmit, formState} = useForm({
    resolver: currentTab !== 2 ? undefined : (selectedContact ? undefined : yupResolver(schema))
  });

  const {errors} = formState

  useEffect(() => {
    setError(errors)
  }, [errors])

  const tabs = [
    {
      label: "Дата и время",
      value: selectedDate
        ? `${formatDateWithTime(selectedDate, true, true)} ${formatDateWithTime(selectedDate, true, false)}`
        : "Укажите дату и время",
      id: 1
    },
    {
      label: "Контакт",
      value: selectedContact ? `${selectedContact.pseudonym} ${selectedContact.lastName} ${selectedContact.name}` : "Выберите или создайте контакт",
      id: 2
    },
    {
      label: "Заявка",
      value: "Выберите или создайте заявку",
      id: 3
    }
  ]

  const onClickVideoLesson = () => {
    window.open(`https://www.youtube.com/@sdelka.sakhar`)
  }

  const {
    typeWatcher,
    sourceWatcher,
    deadlineDateWatcher,
    pseudonymWatcher,
    deadlineTimeWatcher,
    phoneWatcher,
  } = useFormWatcher({
    control,
    getValues,
  });

  function timeToMilliseconds(timeStr?: string) {
    if (timeStr === undefined) {
      return 0
    }
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours * 60 * 60 * 1000 + minutes * 60 * 1000;
  }

  const [hasDateError, setHasDateError] = useState(false)
  const [hasPhoneError, setHasPhoneError] = useState(true)
  const [time, setTime] = useState("")
  const date1 = new Date(getValues("deadlineDate"));
  const date2 = new Date(timeToMilliseconds(time));
  const timeError = +time < 60000 ? "Выберите время" : undefined
  const sumMilliseconds = date1.getTime() + date2.getTime();
  const sumDate = new Date(sumMilliseconds);
  const [isCreating, setIsCreating] = useState(false)
  const dateError = !getValues("deadlineDate") ? "Выберите дату" : (sumDate.getTime() <= Date.now() ? "Невалидная дата" : undefined)

  const {contactsList, setFilter} = useContacts({
    enabled: String(phoneWatcher).replaceAll(" ", "").replaceAll("_", "").length === 14
  })
  const {list} = useLoadApplications({
    enabled: selectedContact !== undefined,
    defaultFilter: {
      contacts: {
        contactId: selectedContact?.id
      },
      whoIsClient: "buyer"
    }
  })

  useEffect(() => {
    if (String(phoneWatcher).replaceAll(" ", "").replaceAll("_", "").length === 14) {
      setFilter({
        phoneForSearch: String(phoneWatcher)
          .replaceAll(" ", "")
          .replaceAll("+", "")
          .replaceAll("(", "")
          .replaceAll(")", "")
      })
    }
  }, [phoneWatcher, setFilter])

  useEffect(() => {
    if (currentTab === 1 && !dateError && !timeError) {
      setHasDateError(false)
    } else {
      setHasDateError(true)
    }
    if (String(phoneWatcher).replaceAll(" ", "").replaceAll("_", "").length === 14) {
      setHasPhoneError(false)
    } else {
      setHasPhoneError(true)
    }
  }, [currentTab, dateError, phoneWatcher, timeError])

  const typeOptions = Object.keys(ContactTypeMap).map((key) => ({
    value: key,
    label: ContactTypeMap[ key ],
  }))
  const {adSources} = useGetAdSources()
  const adSourceOptions: SourceOption[] = adSources.map(el => Object.create({label: el, value: el}))

  const {openSuccessCreateModal} = useOpenSuccessCreateModal({whoIsClient: "buyer"})

  const onSuccessCreate = () => {
    refetchList()
    openSuccessCreateModal()
  }

  const {
    mutate: createShowWithoutApp,
    isLoading: isLoadingShowWithoutApp
  } = useCreateShowWithoutApplication({onSuccess: onSuccessCreate})

  const onSuccessCreateContact = (data: Contact) => {
    createShowWithoutApp({
      applicationId,
      contactId: data.id,
      plannedDate: selectedDate || new Date(),
      leadSource: sourceWatcher
    })
  }

  const {mutate: createShowWithApp, isLoading: isLoadingShow} = useCreateShow({onSuccess: onSuccessCreate})
  const {
    mutate: createContact,
    isLoading: isLoadingCreateContact
  } = useContactCreate({onSuccess: onSuccessCreateContact})

  const onApply = () => {
    if (currentTab === 1) {
      setSelectedDate(sumDate)
      setCurrentTab(currentTab + 1)
    }
    if (currentTab === 2) {
      if (contactsList.length !== 1) {
        const payload = {
          pseudonym: pseudonymWatcher,
          type: typeWatcher,
          phones: [{
            phone: String(phoneWatcher)
              .replaceAll(" ", "")
              .replaceAll("+", "")
              .replaceAll("(", "")
              .replaceAll(")", "")
            , type: "main"
          }],
        }

        setIsCreating(true)

        createContact(payload)
      }
    }
    if (currentTab === 3) {
      if (selectedAppId) {
        createShowWithApp({
            id: applicationId,
            relatedApplicationOrPreApplicationId: selectedAppId ?? "",
            plannedDate: selectedDate ?? new Date(),
          }
        )
      } else {
        createShowWithoutApp({
            applicationId,
            contactId: selectedContact?.id ?? "",
            plannedDate: selectedDate || new Date(),
            leadSource: sourceWatcher
          }
        )
      }
    }
  }

  const onSelectContact = () => {
    setSelectedContact(contactsList[ 0 ])
    setCurrentTab(currentTab + 1)
  }

  useEffect(() => {

  }, [selectedContact])

  const disableButton = () => {
    if (currentTab === 1) {
      return hasDateError
    }
    if (currentTab === 2) {

      return hasPhoneError
    }
    return false
  }

  const buttonText = () => {
    if (currentTab === 1) {
      return "Далее"
    }
    if (currentTab === 2) {
      if (contactsList.length === 1) {
        return "Прикрепить"
      }
      return `Создать ${isMobile ? "" : "новый контакт и заявку"}`
    }
    return "Создать показ"
  }

  return (
    <div className={styles.root}>
      <form onSubmit={handleSubmit(onApply)}>
        <div className={styles.title}>
          Назначение показа
        </div>
        <div className={styles.tabsRow}>
          {tabs.map(elem => (
            <div className={cn(styles.tab, {[ styles.currentTab ]: currentTab === elem.id})}>
              <div className={cn(styles.tabLabel, {[ styles.tabLabelExist ]: currentTab > elem.id})}>{elem.label}</div>
              <div className={cn(styles.tabValue, {[ styles.tabValueExist ]: currentTab > elem.id})}>{elem.value}</div>
            </div>
          ))}
        </div>
        {currentTab === 1 &&
          <InfoRow
            classNames={{
              root: cn(styles.infoRow, styles.calendarChild),
              content: styles.flexRow,
            }}
            labelFont="ubuntu"
            label="Дата и время показа"
          >
            <Time
              classNames={{
                root: styles.timeRoot,
                wrapper: styles.timeWrapper,
                inputs: styles.timeInputs
              }}
              variant="noLabel"
              name="deadlineTime"
              onChange={(value) => setTime(value)}
              register={register}
              value={deadlineTimeWatcher}
            />
            <CalendarInPopup
              form={{name: "deadlineDate", register, setValue, getValues, required: true}}
              defaultDate={deadlineDateWatcher}
              classNames={{
                wrapper: styles.calendar,
                label: styles.calendarLabel,
                trigger: styles.popupTrigger,
                error: styles.calendarError
              }}
            />
            {dateError && <div className={styles.error}>{dateError}</div>}
          </InfoRow>
        }
        {currentTab === 2 &&
          <div className={styles.formList}>
            <InfoRow label="Телефон" classNames={{root: styles.row}} aspect={{label: 5, content: 7}}>
              <TransparentInput
                name="phone"
                register={register}
                placeholder={commonPlaceholders.notSpecified}
                mask={phoneMask}
                setValueForMask={setValue}
                value={phoneWatcher}
              />
            </InfoRow>
            <InfoRow label="Псевдоним" classNames={{root: styles.row}} aspect={{label: 5, content: 7}}>
              <TransparentInput
                className={styles.input}
                placeholder={commonPlaceholders.notSpecified}
                name="pseudonym"
                withCleanUp={{setValue}}
                register={register}
                value={pseudonymWatcher}
              />
              {error?.pseudonym && <div className={styles.error}>{error?.pseudonym?.message?.toString()}</div>}
            </InfoRow>
            <InfoRow label="Тип" classNames={{root: styles.row}} aspect={{label: 5, content: 7}}>
              <Select
                name="type"
                defaultValue={typeOptions[ 1 ].value}
                register={register}
                setValue={setValue}
                options={typeOptions}
                // onChange={onChangeType}
                placeholder="Выбрать"
                classNames={{
                  arrowContainer: styles.arrow,
                  root: styles.selectRoot
                }}
              />
              {error?.type && <div className={styles.error}>{error?.type?.message?.toString()}</div>}
            </InfoRow>
            {contactsList.length !== 1 &&
              <InfoRow label="Источник" classNames={{root: styles.row}} aspect={{label: 5, content: 7}}>
                {adSourceOptions &&
                  <Select
                    name="source"
                    defaultValue={adSourceOptions[ 0 ].value}
                    register={register}
                    setValue={setValue}
                    options={adSourceOptions}
                    // onChange={onChangeType}
                    placeholder="Выбрать"
                    classNames={{
                      arrowContainer: styles.arrow,
                      root: styles.selectRoot
                    }}
                  />}
                {error?.source && <div className={styles.error}>{error?.source?.message?.toString()}</div>}
              </InfoRow>
            }
            <div className={styles.subtitle}>
                Совпадение по номеру телефона
              </div>
              {contactsList && contactsList.length === 1 ?
                <div className={styles.selectedContact}>
                  <ContactCard data={contactsList[ 0 ]} isMobile={isMobile} withoutTitle atAC={isMobile}/>
                </div>
                : <Plug plugText="Контактов не найдено" rootClassName={styles.plug} mini={isMobile}/>
              }
          </div>
        }
        {currentTab === 3 &&
          <div>
            <div className={styles.subtitle}>
              Заявки контакта
            </div>
            <div className={styles.applicationList}>
              {list && list.map(el => (
                <div className={cn(styles.application, {[ styles.applicationSelected ]: el.id === selectedAppId})}
                     onClick={() => setSelectedAppId(el.id)}>
                  {!isMobile
                    ? <CardApplication application={el} withStage kind={el.shortType.kind}/>
                    : <CardApplicationMini application={el} isMobile={isMobile} withoutStage/>
                  }
                </div>
              ))}
            </div>
          </div>
        }
        <div className={styles.footer}>
          <div className={styles.videoLink} onClick={onClickVideoLesson}>
            <Play/>
            <div>
              Видео-инструкция
            </div>
          </div>
          {currentTab !== 2 && <Button
            type="submit"
            disabled={disableButton()}
            isLoading={currentTab === 3 ? isLoadingShow : (isLoadingCreateContact || isLoadingShowWithoutApp)}
          >
            {buttonText()}
          </Button>}
          {currentTab === 2 && <>
            {contactsList.length === 1
              ? <Button
                type="button"
                onClick={onSelectContact}
                disabled={disableButton()}
                isLoading={isLoadingShowWithoutApp}
              >
                {buttonText()}
              </Button>
              : <Button
                type="submit"
                disabled={disableButton()}
                isLoading={isLoadingCreateContact || isCreating}
              >
                {buttonText()}
              </Button>
            }
          </>
          }
        </div>
      </form>
    </div>
  )
}