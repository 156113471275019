import styles from "./index.module.scss"
import { useEffect, useState } from "react";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import {
  ReactComponent as IconDownload,
  ReactComponent as Download
} from "../../../../../assets/icons/iconDownload20.svg";
import { AxiosResponse } from "axios";
import { useDownloadReports } from "../../../../../service/api/mutations";
import { useForm } from "react-hook-form";
import { CalendarRangeInPopup, InfoRow, Select } from "../../../../UI";
import { ReportsResponse } from "../../../../../types";
import { ReactComponent as IconFolderUp } from "../../../../../assets/icons/iconDocumentFolderUp.svg";
import { ReactComponent as IconFolderDown } from "../../../../../assets/icons/iconDocumentFolderDown.svg";
import cn from "classnames";
import { ReactComponent as IconFile } from "../../../../../assets/icons/iconFileSmall.svg";
import { ReactComponent as New } from "../../../../../assets/icons/iconNew.svg";
import { useLoadReportTypes } from "../../../../../service/api/queries";
import { selectDevice, useSelect } from "../../../../../service/redux/selectors";

export const Reports = () => {
  const {isMobile} = useSelect(selectDevice)
  const [reports, setReports] = useState<ReportsResponse>({url: "", folders: []})
  const [type, setType] = useState<string>("")
  const [dateFrom, setDateFrom] = useState<Date>(new Date(Date.now() - 30 * 60 * 60 * 24 * 1000))
  const [dateTo, setDateTo] = useState<Date>(new Date())
  const [url, setUrl] = useState("")
  
  const onSuccessDownload = (res: AxiosResponse<ReportsResponse>) => {
    setReports(res.data)
    setUrl(res.data.url)
  }

  const {mutate} = useDownloadReports({onSuccess: onSuccessDownload})

  const [foldersArray, setFoldersArray] = useState<Array<string>>([])
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState<number>(0)
  let docFoldersArray: string[] = foldersArray

  const handleToggleOpenFolder = (name: string) => {
    if (!docFoldersArray.find(el => el === name)) {
      docFoldersArray = docFoldersArray.concat([name])
      setOpen(Math.random())
    } else {
      const index = docFoldersArray.findIndex(el => el === name)
      docFoldersArray.splice(index, 1)
      setOpen(Math.random())
    }
    setFoldersArray(docFoldersArray as any)
  }
  // Оставлено, когда будет раскомменчен блок в верстке
  // const {REACT_APP_API_PROD_URL} = process.env

  // const handleDownloadAll = (name: string) => {
  //   window.open(
  //     `${REACT_APP_API_PROD_URL}/dashboard/downloadDocuments/${name}`)
  // }

  const handleClickDownload = (urlText: string) => {
    window.open(`${urlText}`)
  }

  const {types} = useLoadReportTypes()
  const selectableTypes = types || []

  const {register, getValues, setValue} = useForm();

  const handleDateChange = (date: Date[] | Date | null | undefined) => {
    if (date) {
      setDateFrom(date[ 0 ])
      setDateTo(date[ 1 ])
    }
  };

  const handleDownload = () => {
    window.open(`${url}`)
  }
  
  useEffect(() => {
    mutate({type, dateFrom, dateTo})
  }, [dateFrom, dateTo, type])

  return (
    <div className={styles.root}>
      <div className={styles.title}>Отчеты</div>
      <div className={styles.content}>
        <div>
          <div className={styles.subtitle}>
            Скачать отчет
          </div>
          <div className={styles.inputs}>
            <InfoRow label="Тип отчета" aspect={{label: 6, content: 6}} classNames={{root: styles.infoRowLabel}}>
              <Select
                options={selectableTypes}
                name="type"
                register={register}
                setValue={setValue}
                onChange={(value) => setType(value)}
                isSearchable={false}
                classNames={{
                  selectListWrapper: styles.selectWrapper,
                  arrowContainer: styles.arrow,
                  placeholder: styles.arrow
                }}
              />
            </InfoRow>
            <InfoRow label="Период" aspect={{label: 6, content: 6}}>
              <CalendarRangeInPopup
                form={{name: ["dateFrom", "dateTo"], register, setValue, getValues, required: true}}
                options={{primaryOpen: isMobile ? undefined : "left", arrowClass: styles.arrow}}
                classNames={{
                  card: styles.calendar,
                  label: styles.openTrigger,
                }}
                onDateChange={handleDateChange}
                placeholder="ДД.ММ.ГГ - ДД.ММ.ГГ"
              />
            </InfoRow>
          </div>
          <div className={styles.buttonContainer}>
            <Button Icon={Download} onClick={handleDownload}>
              Скачать
            </Button>
          </div>
        </div>
        <div>
          <div className={styles.subtitle}>
            История
          </div>
          <div>
            {reports && reports.folders.map((elem) => (
              <div
                className={cn(styles.fileContainer, {[ styles.fileContainerNotOpen ]: !foldersArray.includes(elem.name)})}>
                <div className={cn(styles.fileRow, {[ styles.rowOpen ]: foldersArray.includes(elem.name)})}
                     onClick={() => {
                       handleToggleOpenFolder(elem.name)
                     }}
                >
                  <div className={styles.row}>
                    <div className={styles.counterWrapper}>
                      <div className={styles.counter}>{elem.files.length ?? 0}</div>
                      {foldersArray.includes(elem.name) ? <IconFolderUp/> : <IconFolderDown/>}
                    </div>
                    <div className={styles.fileRowTitle}>{elem.name}</div>
                  </div>
                  {/* <div className={styles.fileRow}> */}
                  {/*  <IconDownload onClick={() => handleDownloadAll(elem.name)}/> */}
                  {/* </div> */}
                </div>
                {foldersArray.includes(elem.name) ?
                  <div className={styles.filesList}>
                      {elem.files.map((item) => (
                        <div className={styles.fileItem} key={item.name}>
                          <div className={styles.fileRow}>
                            <IconFile style={{marginRight: "10px"}}/>
                            <span>
                              {item.name}
                            </span>
                            {item.new &&
                              <New style={{marginLeft: "10px"}}/>
                            }
                          </div>
                          <IconDownload style={{cursor: "pointer"}} onClick={() => handleClickDownload(item.url)}/>
                        </div>
                      ))}
                    </div>
                  : <></>
                }
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}