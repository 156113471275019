import cn from "classnames";
import { User } from "../../../../../../types";
import { Avatar } from "../../../../index";
import { ReactComponent as NotificationIcon } from "../../../../../../assets/icons/notification.svg";
import styles from "./index.module.scss";
import { Tooltip } from "@sdelka_crm/sdelka-crm-component-library";
import { ReactComponent as InfoIcon } from "../../../../../../assets/icons/iconWarnYellow.svg";
import { daysNounHelper } from "../../../../../../utils/string";
import { useOpenProfileCard } from "../../../../../../service/servise";

type Props = {
	account?: User;
	current?: boolean;
	isOpen?: boolean
};

export const UserInfo = ({ account, current, isOpen }: Props): JSX.Element => {
	const { openProfileDrawer } = useOpenProfileCard({});

	const handleProfileDrawer = () => openProfileDrawer(account?.id);

	return (
		<div className={styles.root}>
			<div className={cn(styles.base, { [styles.baseHover]: !current })}>
				<div className={styles.avatarContainer}>
					<Avatar
						isLoading={!account}
						imgSrc={account?.account.avatar}
						name={account?.account.name}
						lastName={account?.account.lastName}
						color={current ? "primary" : "secondary"}
						isPremium={account?.isPremium}
					/>

					{current && (
						<div className={styles.notifications}>
							<NotificationIcon />
						</div>
					)}
				</div>

				<div
					className={cn(styles.info, {
						[styles.infoCurrent]: current,
					})}
				>
					<div className={styles.name} onClick={handleProfileDrawer}>
						{`${account?.account.lastName} ${account?.account?.name?.slice(0, 1) || ""}.${
							account?.account?.secondName?.slice(0, 1) || ""
						}`}
					</div>
					<div className={styles.company}>
						{account?.company?.companyName}
						{account?.premiumDaysLeftWarning && isOpen &&
							<div className={styles.tooltipWrapper}>
								<Tooltip
									classNames={{
										root: styles.tooltipDescription,
										card: styles.tooltipCard,
									}}
									iconSize="big"
									CustomIcon={InfoIcon}
								>
									{`Premium закончится через ${account.premiumDaysLeftWarning}
							 ${daysNounHelper(account.premiumDaysLeftWarning)}. Продлите подписку в магазине.`}
								</Tooltip>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
};
