import { AxiosError, AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { Compilation } from "../../../../types";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/compilation/use-load-compilation/DEFAULT_QN";
export const COMPILATION_PAGE_LOAD_COMP_QN =
	"sdelka/compilation/use-load-compilation/COMPILATION_PAGE_LOAD_COMP_QN";

type UseLoadCompilation = {
	applicationId: string;
	queryName?: string;
	enabled?: boolean;
	retry?: boolean | number;
	onSuccess?: (res: AxiosResponse<Compilation>) => void;
};

export const useLoadCompilation = ({
	applicationId,
	queryName = DEFAULT_QN,
	enabled = true,
	retry = false,
	onSuccess,
}: UseLoadCompilation) => {
	const loadCompilation = () => api.compilations.get(applicationId);

	const { data, isLoading, refetch, remove, error, isFetching, isFetched } = useQuery(
		queryName,
		loadCompilation,
		{
			enabled,
			retry,
			onSuccess,
		}
	);

	return {
		compilation: data?.data,
		isLoading,
		isFetching,
		refetch,
		remove,
		error: error as AxiosError,
		isFetched,
	};
};
