import styles from "./index.module.scss"
import { ReactComponent as AddInCart } from "../../../../../../../../../../../../../../assets/icons/Shopping_Cart.svg";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CardProductForTable, RadioButton } from "../../../../../../../../../../../../../UI";
import {
  useLoadAppliedServicesHistory,
  useLoadAvailableServices,
  useLoadPurchasedServices
} from "../../../../../../../../../../../../../../service/api/queries";
import cn from "classnames";
import { useApplyService, useBuyInOneClick } from "../../../../../../../../../../../../../../service/api/mutations";
import { useDispatch } from "react-redux";
import { useOpenConfirmPaymentModal } from "../confirm-payment-modal";
import { AxiosResponse } from "axios";
import { useOpenOrderCreatedModal } from "../order-created-modal";
import { CartResponse, ProductTypeEnum } from "../../../../../../../../../../../../../../types";
import {
  useAddInCartModal
} from "../../../../../../../../../../../../Servises/cart/helpers/addInCartModal/useAddInCartModal";
import { useIsTelegram } from "../../../../../../../../../../../../../../utils/telegram/useIsTelegram";

type Props = {
  applicationId: string
  isMobile?: boolean
  adPlatformAccountId?: string
}

const typeOptions = [
  {label: `Доступные`, value: "purchased"},
  {label: `Можно купить`, value: "available"},
];

type TabType = "available" | "purchased"

export const ApplyServiceModal = (
  {
    applicationId,
    isMobile,
    adPlatformAccountId
  }: Props): JSX.Element => {
  const [tab, setTab] = useState<TabType | undefined>(adPlatformAccountId ? "available" : "purchased")
  const [selected, setSelected] = useState("")
  const {control} = useForm({});

  const {isLoading: isFetchingPurchase, services: purchasedServices, refetch: refetchPurchased} =
    useLoadPurchasedServices({applicationId})
  const {isLoading: isFetchingAvailable, services: availableServices, refetch: refetchAvailable} =
    useLoadAvailableServices({applicationId, adPlatformAccountId})

  useLayoutEffect(() => {
    refetchPurchased()
    refetchAvailable()
  }, [refetchAvailable, refetchPurchased])

  const [data, setData] = useState(purchasedServices)
  const onTypeChange = (value: string | number) => {
    if (value === "purchased") {
      setTab("purchased")
    } else {
      setTab("available")
    }
  };

  // eslint-disable-next-line no-unused-vars
  let loading = isFetchingPurchase

  useEffect(() => {
    if (tab === "available") {
      refetchAvailable()
      setData(availableServices)
      loading = isFetchingAvailable
    } else {
      refetchPurchased()
      setData(purchasedServices)
      loading = isFetchingPurchase
    }
  }, [tab, availableServices, purchasedServices])

  const {executeUrl} = useIsTelegram()

  const onOpenShop = () => {
    executeUrl(`/services?tab=PRODUCTS`)
  }

  const {openOrderCreatedModal} = useOpenOrderCreatedModal({applicationId, isMobile})

  const {refetch} = useLoadAppliedServicesHistory({applicationId})

  const {mutate, isLoading} = useApplyService({
    onSuccess: (dataApply: AxiosResponse<{ orderId: string }>) => {
      tab === "purchased" ? refetchPurchased() : refetchAvailable()
      openOrderCreatedModal(dataApply.data.orderId)
      refetch()
    }
  })

  const {openConfirmPaymentModal} = useOpenConfirmPaymentModal({
    applicationId,
    serviceId: selected,
    isMobile,
  })

  const {mutate: buyInOneClick} = useBuyInOneClick({
    onSuccess: (dataBuy: AxiosResponse<CartResponse>) => {
      document.location.href = dataBuy?.data.paymentUrl ?? ""
    }
  })

  const foundedItem = data?.find(el => el.id === selected)

  const {openAddInCartModal} = useAddInCartModal({
    type: ProductTypeEnum.service,
    title: "Выберите тариф",
    action: buyInOneClick,
    tariffLabel: foundedItem?.tariffLabel,
    tariffOptions: foundedItem?.tariffOptions,
    applicationId,
    isMobile,
    adPlatformAccountId,
  })

  const handleApply = () => {
    if (tab === "purchased") {
      mutate({applicationId, serviceId: selected})
    } else if (adPlatformAccountId) {
      openAddInCartModal([selected], true)
    } else {
      openConfirmPaymentModal(foundedItem?.tariffLabel, foundedItem?.tariffOptions)
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {adPlatformAccountId ? "Покупка рекламы" : "Создание заказа"}
      </div>
      <div className={styles.select}>
        <RadioButton
          name="historyType"
          control={control}
          options={typeOptions}
          handleOnChange={onTypeChange}
          background="blue"
          backgroundButtons="blue"
          currentValue={tab}
        />
      </div>
      {data && data.length > 0 ? <div className={styles.content}> {data.map(elem => (
          <div
            className={cn(styles.item,
              {[ styles.selected ]: elem.id === selected})}
            onClick={() => setSelected(elem.id)}
            key={elem.id}
          >
            {tab === "purchased" && !isFetchingPurchase && !isFetchingAvailable
              ? <CardProductForTable
                product={elem}
                refetch={refetchPurchased}
                type={tab}
                mini={isMobile}
              />
              : <CardProductForTable
                product={elem}
                refetch={refetchAvailable}
                type={tab}
                checked={elem.id === selected}
                fromModal
                mini={isMobile}
                onCardClick={() => {
                  setSelected(elem.id)
                }}
              />
            }
          </div>
        ))}  </div>
        : <div className={styles.plug}>
          {`${!isMobile ? "У вас нет" : "Нет"} доступных купонов, вы можете купить их в `} <b
          onClick={onOpenShop}> Магазине</b>
        </div>
      }
      <div className={styles.footer}>
        {!adPlatformAccountId && <div className={styles.row} onClick={onOpenShop}>
          <AddInCart/>
          <div className={styles.openCart}>
            Открыть магазин
          </div>
        </div>}
        <Button disabled={isLoading || (adPlatformAccountId !== undefined && !selected)}
                onClick={handleApply}>
          {tab === "purchased" ? "Применить" : (adPlatformAccountId ? "Купить" : "Создать")}
        </Button>
      </div>
    </div>
  )
}