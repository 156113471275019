import { AdStatusEnum } from "../../../types";

type AdStatusTranslationMap = {
	[K in AdStatusEnum]?: string;
};

export const adStatusTranslationMap: AdStatusTranslationMap = Object.freeze({
	[AdStatusEnum.NotPublished]: "Не размещён",
	[AdStatusEnum.NotRequested]: "Не запрошен",
	[AdStatusEnum.PassedToPlatform]: "Передан на площадку",
	[AdStatusEnum.Published]: "Размещён",
	[AdStatusEnum.Removed]: "Снят с публикации",
	[AdStatusEnum.Error]: "Ошибка",

	[AdStatusEnum.Finished]: "Закончилась",
	[AdStatusEnum.Pending]: "Ожидает размещения",
	[AdStatusEnum.Released]: "Передан на площадку",
	[AdStatusEnum.Placed]: "Размещен",
	[AdStatusEnum.Archive]: "Архивная",
});
