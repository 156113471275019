import { KindType } from "../../../../../types";
import { ApplicationCard } from "../../../../../components/pages/Application";
import { FixedNavVerticalMenu } from "../../../../../components/UI";
import { useDispatch } from "react-redux";
import { openDrawer, openDrawerInDrawer } from "../../../../redux/modules/drawer/drawer.action";

type Props = {
  atDrawerInDrawer?: boolean
}

type OpenDeal = {
  applicationId: string
  refetchParent?: () => void;
  onClose?: () => void
  disableClose?: boolean;
  isMobile?: boolean
  classNames?: {
    wrapper?: string;
    rightBlock?: string;
  };
}

export const useOpenDeal = (
  {
    atDrawerInDrawer
  }: Props) => {
  const dispatch = useDispatch()

  const openDeal = (
    {
      applicationId,
      refetchParent,
      onClose,
      disableClose,
      isMobile,
      classNames
    }: OpenDeal
  ) => {

    const appNav = {
      label: "Сделка",
      height: 160,
      title: "deal",
      query: {
        name: "dealId",
        value: applicationId
      }
    }
    const appChildren = (
      <ApplicationCard
        applicationId={applicationId}
        kind={KindType.DEAL}
        refetch={refetchParent}
      />
    )

    const yNavigation: Omit<FixedNavVerticalMenu, "id" | "changeTab">[] = [appNav]

    if (atDrawerInDrawer) {

      dispatch(openDrawerInDrawer(
        {
          children: [appChildren],
          width: 800,
          yNavigation: {
            navigation: yNavigation,
            initialTab: 0
          },
          onClose,
        }))
    } else {
      dispatch(openDrawer(
        {
          children: [appChildren],
          width: 800,
          yNavigation: {
            navigation: yNavigation,
            // initialTab: 0,
          },
          onClose,
          disableClose,
          isMobile,
          classNames,
        }))
    }
  }

  return {
    openDeal
  }
}