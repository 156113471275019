import { useDispatch } from "react-redux";
import { H2 } from "@sdelka_crm/sdelka-crm-component-library";

import { openModal } from "../../../../../../../../../service/redux/modules/modal/modal.action";

import styles from "./styles.module.scss";
import { CreateContactModalForm } from "./components/CreateContactModalForm";
import { selectDevice, useSelect } from "../../../../../../../../../service/redux/selectors";

export const useAddLeadModal = (contactId?: string, refetch?: () => void) => {
	const dispatch = useDispatch();
	const {isMobile} = useSelect(selectDevice)

	const openLeadModal = (showId?: string) =>
		dispatch(
			openModal({
				title: <H2 classNames={{ root: styles.heading }}>Создать лид</H2>,
				body: <CreateContactModalForm  contactId={contactId} showId={showId} refetch={refetch}/>,
				width: isMobile ? 500 : 660,
				classNames: {
					contentWrapper: isMobile ? styles.modal : "",
					buttonClose: isMobile ? styles.modalClose : ""
				}
			})
		);

	return {
		openLeadModal,
	};
};
