import { useDispatch } from "react-redux";
import { DeleteContactModal } from "./DeleteContactModal";
import { openModal } from "../../../../../service/redux/modules/modal/modal.action";
import styles from "./index.module.scss"

type Return = {
  openDeleteContactModal: () => void
}

type Props = {
  onDelete: () => void
}

export const useDeleteContactModal = ({onDelete}: Props): Return => {
  const dispatch = useDispatch()
  const openDeleteContactModal = () => {
    dispatch(
      openModal(
        {
          width: 660,
          body: <DeleteContactModal
            onDelete={onDelete}
          />,
          classNames: {
            contentBody: styles.modal
          }
        }
      ))
  }

  return {
    openDeleteContactModal
  }
}

