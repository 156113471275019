import {
	CategoryEnum,
	GetRealEstateFiltersPayload,
	RealEstateFilter,
	BusinessProcess,
} from "../../../../../../../types";
import { UseFormGetValues, UseFormReset } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Dispatch, SetStateAction } from "react";

import { makeEmpty } from "../../../../../../../utils/objects";
import { wasChangeVariant } from "../../../../../../../utils/clientObjects";
import { closeDrawer } from "../../../../../../../service/redux/modules/drawer/drawer.action";

type UseDrawerFormActions = {
	countFilter: GetRealEstateFiltersPayload;
	setCountFilter: Dispatch<SetStateAction<GetRealEstateFiltersPayload>>;
	getValues: UseFormGetValues<any>;
	prepareSubmitForm: (values: RealEstateFilter) => void;
	reset: UseFormReset<any>;
};

export const useDrawerFormActions = ({
	setCountFilter,
	getValues,
	prepareSubmitForm,
	countFilter,
	reset,
}: UseDrawerFormActions) => {
	const dispatch = useDispatch();

	const setCategory = (category: CategoryEnum[], prev: CategoryEnum[]) => {
		if (wasChangeVariant(category, prev)) {
			setCountFilter({ category, type: countFilter.type });
			reset({
				objectLocationType: category,
				type: countFilter.type,
				actual: getValues()?.actual || [],
				category,
			} as any);
		} else {
			setCountFilter({
				...countFilter,
				category,
			});
		}
	};

	const onChangeTypeValue = (value: BusinessProcess) => {
		setCountFilter({
			category: countFilter.category,
			type: {
				value,
			},
		});
		reset({
			dealType: value,
			actual: getValues()?.actual || [],
			objectLocationType: countFilter.category,
			category: countFilter.category,
		} as any);
	};

	// eslint-disable-next-line sonarjs/cognitive-complexity
	const onSubmit = (values) => {
		const newForm = values;
		Object.keys(newForm).forEach((key) => {
			const type = typeof newForm[key];

			if (key === "additionalProperties") {
				const additionalProperties = newForm[key];
				Object.keys(additionalProperties).forEach((addKey) => {
					const addType = typeof newForm[addKey];

					if (newForm[key][addKey]) {
						return;
					}

					if (Array.isArray(newForm[key][addKey])) {
						newForm[key][addKey] = [];
					} else if (addType === "object") {
						newForm[key][addKey] = {};
					} else {
						newForm[key][addKey] = "";
					}
				});
			}

			if (newForm[key]) {
				return;
			}

			if (Array.isArray(newForm[key])) {
				newForm[key] = {};
			} else if (type === "object") {
				newForm[key] = {};
			} else {
				newForm[key] = "";
			}
		});

		prepareSubmitForm({
			rooms: values.rooms?.length && [values.rooms],
			...newForm,
		});

		dispatch(closeDrawer());
	};

	const resetForm = () => {
		const emptyForm = makeEmpty(getValues());

		const category = emptyForm?.category || [];
		const countPayload: GetRealEstateFiltersPayload = {
			category: emptyForm?.category || [],
			type: emptyForm.type,
		};

		setCountFilter(countPayload);
		if (getValues("category")?.length === 0) {
			reset({
				...emptyForm,
				objectLocationType: category || [],
				category: category || [],
			});
		} else {
			reset({
				objectLocationType: category,
				category,
			} as any);
		}
	};

	return {
		onSubmit,
		resetForm,
		setCategory,
		onChangeTypeValue,
	};
};
