import { api } from "../../api";
import { useQuery } from "react-query";

type UseGetChatAvito = {
  contactId: string
}

export const useGetChatAvito = ({contactId}: UseGetChatAvito) => {

  const loadChat = () => api.contacts.contactsChatAvito(contactId)

  const { data, isLoading, isFetching, refetch } = useQuery(["loadChatAvito"], loadChat, {
    keepPreviousData: true,
    enabled: true,
  });

  return {
    chat: data?.data.reverse() || [],
    isLoading,
    isFetching,
    refetch
  }
}