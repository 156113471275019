import { useForm, useWatch } from "react-hook-form";
import { CategoryEnum, BusinessProcess, PreCreateForm } from "../../../../../../../types";

export const useFormData = () => {
	const { register, setValue, control, getValues, handleSubmit } = useForm<PreCreateForm>();

	const [watchTypeValue, watchCategory, watchTypeId] = [
		useWatch({
			name: "type.value",
			control,
			defaultValue: getValues("type.value") || "sale",
		}),
		useWatch({
			name: "category",
			control,
			defaultValue: getValues("category") || [],
		}),
		useWatch({
			name: "type.id",
			control,
			defaultValue: getValues("type.id") || "",
		}),
	] as ["" | BusinessProcess, CategoryEnum[], string];

	return {
		register,
		setValue,
		handleSubmit,
		watchTypeValue,
		watchCategory,
		watchTypeId,
		control
	};
};
