import { memo } from "react";
import styles from "./index.module.scss";
import { Popover } from "components/UI";
import cn from "classnames";
import { Kind } from "types";

export type Props = {
  relatedKinds: {
    applications: Kind[]
    deals: Kind[]
    preApplications: Kind[]
    successDeals: Kind[]
  };
  classNames?: string
  mini?: boolean
}

export const LeadStrap = memo(({relatedKinds, classNames, mini}: Props): JSX.Element => {
  const {
    applications,
    deals,
    preApplications,
    successDeals
  } = relatedKinds

  const sumLength = preApplications.length + deals.length + preApplications.length + successDeals.length

  return (
    <>
      {((applications && applications.length > 0) ||
          (deals && deals.length > 0) ||
          (preApplications && preApplications.length > 0) ||
          (successDeals && successDeals.length > 0)) &&
        <div className={cn(styles.wrap, classNames, {[ styles.wrapMini ]: mini})} onClick={(e) => e.stopPropagation()}>
          {
            successDeals && successDeals.length > 0 &&
            <div className={cn(styles.successDeals, styles.strap)} />
          }
          {
            deals && deals.length > 0 &&
            <div className={cn(styles.deals, styles.strap)} />
          }
          {
            applications && applications.length > 0 &&
            <div className={cn(styles.applications, styles.strap)} />
          }
          {
            preApplications && preApplications.length > 0 &&
            <div className={cn(styles.preApplications, styles.strap)} />
          }
          <Popover
            classNames={{over: styles.stagePopup, root: styles.root}}
            overElement={
              <div className={styles.shedule}>
                {preApplications && preApplications.length > 0 &&
                  <div className={styles.stage}>
                        <span className={styles.stageCircle} style={{background: "#D5D3CE"}}>
                          {preApplications[ 0 ].stageNo}
                        </span>
                    <span className={styles.stagesText}>
                          {`Предзаявка. ${(preApplications)[ 0 ].businessProcessTitle}. Этап: 
                          ${(preApplications)[ 0 ].stage}`}
                        </span>
                  </div>
                }
                {applications && applications.length > 0 &&
                  <div className={styles.stage}>
                          <span className={styles.stageCircle} style={{background: "#30CFB1"}}>
                            {applications[ 0 ].stageNo}
                          </span>
                    <span className={styles.stagesText}>
                          {`Заявка. ${(applications)[ 0 ].businessProcessTitle}. Этап: 
                          ${(applications)[ 0 ].stage}`}
                          </span>
                  </div>
                }
                {deals && deals.length > 0 &&
                  <div className={styles.stage}>
                          <span className={styles.stageCircle} style={{background: "#336BEB"}}>
                            {deals[ 0 ].stageNo}
                          </span>
                    <span className={styles.stagesText}>
                          {`Сделка. ${(deals)[ 0 ].businessProcessTitle}. Этап: 
                          ${(deals)[ 0 ].stage}`}
                          </span>
                  </div>
                }
                {successDeals && successDeals.length > 0 &&
                  <div className={styles.stage}>
                          <span className={styles.stageCircle} style={{background: `${styles.linear}`}}>
                            {successDeals[ 0 ].stageNo}
                          </span>
                    <span className={styles.stagesText}>
                          {`Успешная сделка. ${(successDeals)[ 0 ].businessProcessTitle}. Этап: 
                          ${(successDeals)[ 0 ].stage}`}
                          </span>
                  </div>
                }
                {/* todo */}
                {sumLength > 4 &&
                  <div>{`еще ${sumLength - (preApplications && 1) - (applications && 1) - (deals && 1)}`}</div>}
              </div>
            }
          />
        </div>}
    </>
  )
})