import { BusinessProcess, CategoryEnum } from "../../../../../../../types";
import { objectFilters } from "../../../../../../clientObjects";

type Props = {
	watchTypeValue: BusinessProcess | "";
	watchCategory: CategoryEnum[];
};

type Return = {
	showCategory: boolean;
	showBusinessProcess: boolean;
};

type UseDisplaySelect = (props: Props) => Return;

export const useDisplaySelect: UseDisplaySelect = ({ watchCategory, watchTypeValue }) => {
	const isBuy = !!objectFilters.buyBusinessProcess.find((item) => item.value === watchTypeValue);

	const showCategory = isBuy ? false : watchTypeValue !== "";

	const showBusinessProcess = isBuy ? watchTypeValue !== "" : watchTypeValue !== "" && watchCategory.length > 0;

	return {
		showBusinessProcess,
		showCategory,
	};
};
