import { UserAccountData } from "../../../../../../types";
import cn from "classnames";
import styles from "./index.module.scss";
import { Avatar } from "../../../../Avatar";

type Props = {
  account: UserAccountData
}

export const CardObjectResponsible = ({account}: Props): JSX.Element => (
    <div className={cn(styles.contact)}>
      <div className={styles.avatar}>
        <Avatar
          imgSrc={account?.avatar}
          name={account?.name}
          lastName={account?.lastName}
          color="primary"
          mini
        />
      </div>
      <span className={styles.ownerText}>
        {`${account?.name || ""} ${account?.secondName || ""} ${account?.lastName || ""}`}
      </span>
    </div>
  )