import { ReactNode } from "react";

import { ReactComponent as TrashIcon } from "../../../../../../assets/icons/trash.svg";
import { ReactComponent as PlusIcon } from "../../../../../../assets/icons/plus.svg";
import { ReactComponent as AlertIcon } from "../../../../../../assets/icons/alert.svg";
import styles from "./index.module.scss";

type AlertIconType = "warning" | "trash" | "plus";

type Props = {
	alertIcon: AlertIconType | ReactNode;
};

export const AlertIconComponent = ({ alertIcon }: Props): JSX.Element => {
	const getIcon = () => {
		switch (alertIcon) {
			case "warning":
				return <AlertIcon className={styles.warningIcon} />;
			case "trash":
				return <TrashIcon className={styles.trashIcon} />;
			case "plus":
				return (
					<div className={styles.plusIconRoot}>
						<PlusIcon className={styles.plus} />
					</div>
				);
			default:
				return alertIcon;
		}
	};

	return <div className={styles.root}>{getIcon()}</div>;
};
