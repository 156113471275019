import { ParserSiteEnum, SourceLink } from "../../../../../../types";
import { JSX, memo } from "react";
import styles from "../Owner/index.module.scss";
import LogoCian from "../../../../../../assets/icons/logoCian.svg";
import LogoAvito from "../../../../../../assets/icons/logoAvito.svg";
import LogoYoula from "../../../../../../assets/icons/logoYoula.svg";
import LogoDomClick from "../../../../../../assets/icons/logoDomClick.svg";
import LogoYandexNed from "../../../../../../assets/icons/logoYandexNed.svg";

export type Props = {
  sourceLinks?: SourceLink[]
}

export const EstateBaseRow = memo(
  ({sourceLinks}: Props): JSX.Element => {
    const otherNumber = sourceLinks?.filter(site => site.site === "other").length


    return (
      <div className={styles.iconBlock}>
        {sourceLinks?.find(link => link.site === ParserSiteEnum.cian) &&
            <div className={styles.svgIcon}>
                <img src={LogoCian} alt="SVG logo"/>
            </div>
        }
        {sourceLinks?.find(link => link.site === ParserSiteEnum.avito) &&
            <div className={styles.svgIcon}>
                <img src={LogoAvito} alt="SVG logo"/>
            </div>
        }
        {sourceLinks?.find(link => link.site === ParserSiteEnum.youla) &&
            <div className={styles.svgIcon}>
                <img src={LogoYoula} alt="SVG logo"/>
            </div>
        }
        {sourceLinks?.find(link => link.site === ParserSiteEnum.domclick) &&
            <div className={styles.svgIcon}>
                <img src={LogoDomClick} alt="SVG logo"/>
            </div>
        }
        {sourceLinks?.find(link => link.site === ParserSiteEnum.yandex) &&
            <div className={styles.svgIcon}>
                <img src={LogoYandexNed} alt="SVG logo"/>
            </div>
        }
        {otherNumber !== 0 &&
            <div className={styles.other}>
                        <span>
                             {`+${otherNumber}`}
                        </span>
            </div>
        }
      </div>
    )
  }
)