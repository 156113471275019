import styles from "./index.module.scss"
import { useLoadStatistic } from "../../../service/api/queries";
import {
  GroupByDetalizationParams,
  PeriodDatesType,
  ShowsViewsStatisticKind,
  ShowsViewsStatisticPayload,
  WhoIsClient
} from "../../../types";
import { CollapseDown, Loader } from "@sdelka_crm/sdelka-crm-component-library";
import { Popup } from "../Popup";
import {
  PeriodSelector
} from "../../pages/RealEstatePage/mode/RealEstateAd/components/RealEstateAdPlatform/components/RealEstateAdPartnersInfo/components/PeriodSelector";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import {
  DATETYPES,
  formatDate,
  getDefaultDate
} from "../../pages/RealEstatePage/mode/RealEstateAd/components/RealEstateAdPlatform/components/RealEstateAdPartnersInfo/components/PeriodSelector/utils";
import { CardContact } from "../Card/card-contact/CardContact";
import { Plug } from "../ApplicationComponents/helpers";
import { RadioButton } from "../Form";
import cn from "classnames";
import { useForm, useWatch } from "react-hook-form";
import { StatisticDetalization } from "./detalization/StatisticDetalization";

type Props = {
  applicationId?: string
  whoIsClient?: WhoIsClient
  isFromShows?: boolean
  isMobile?: boolean
}

const kindOptions = [
  {label: "Назначенных показов", value: ShowsViewsStatisticKind.planned},
  {label: "Проведенных показов", value: ShowsViewsStatisticKind.finished},
  {label: "Новых предзаявок", value: ShowsViewsStatisticKind.newPreApplications},
  {label: "Макс. сумма", value: ShowsViewsStatisticKind.maxOffer},
]

export const Statistic = (
  {
    applicationId,
    whoIsClient,
    isFromShows,
    isMobile
  }: Props) => {

  const grouByOptions = ["неделю", "месяц", "9 месяцев"];
  const [selectedGroupByIndex, setSelectedGroupByIndex] = useState(0);
  const selectedGroupBy = Object.values(GroupByDetalizationParams)[ selectedGroupByIndex ];
  const [, setIsOpen] = useState(false);
  const clientIs = whoIsClient
  const [, setEnabled] = useState(false);
  const [periodDates, updatePeriodDates] = useState<PeriodDatesType>({
    startDate: formatDate(getDefaultDate(selectedGroupBy, DATETYPES.START)),
    endDate: formatDate(getDefaultDate(selectedGroupBy, DATETYPES.END)),
  });
  const [kind, setKind] = useState<ShowsViewsStatisticKind>(ShowsViewsStatisticKind.planned)
  const {control} = useForm()
  const handleChangeKind = (value) => {
    setKind(value)
  }

  const radioValue = useWatch({
    control,
    name: "kind",
    defaultValue: kind,
  });

  useLayoutEffect(() => {
    setKind(ShowsViewsStatisticKind.planned)
  })

  const defaultPayload: ShowsViewsStatisticPayload = {
    type: clientIs === "seller" ? "views" : "shows",
    applicationId: applicationId ?? "",
    dateFrom: periodDates.startDate,
    dateTo: periodDates.endDate,
    groupBy: selectedGroupBy,
    kind
  }

  const {statistic, isLoading, refetch} = useLoadStatistic({payload: defaultPayload})

  useEffect(() => {
    refetch()
  }, [applicationId, clientIs, kind, periodDates.endDate, periodDates.startDate, refetch, selectedGroupBy])

  const itemClickHandler = (index) => {
    setSelectedGroupByIndex(index);
    setIsOpen(false);
  };

  const handleClick = useCallback(
    (value: boolean) => {
      if (value) {
        setEnabled(true);
        refetch();
      } else setEnabled(false);
    },
    [refetch]
  );

  return (
    <div className={styles.root}>
      {!isFromShows &&
        <div className={styles.title}>
          Поиск покупателя
        </div>}
      <div className={cn(styles.content, {[ styles.mini ]: isMobile})}>
        <CollapseDown
          label={
            <div className={styles.label}>
              <div>Детализация</div>
              <Popup
                openTrigger={
                  <p className={styles.popupSelectedOption}>
                    за {grouByOptions.find((_, index) => index === selectedGroupByIndex)}
                  </p>
                }
                options={{withArrow: true}}
                classNames={{arrow: styles.popupArrow}}
              >
                <div className={styles.popupBody}>
                  {grouByOptions
                    .map((item, index) => <p key={item} onClick={() => itemClickHandler(index)}>за {item}</p>)
                    .filter((_, index) => index !== selectedGroupByIndex)}
                </div>
              </Popup>
            </div>
          }
          headerChildren={
            <PeriodSelector
              selectedGroupBy={selectedGroupBy}
              updatePeriodDates={updatePeriodDates}
            />
          }
          onClick={handleClick}
          classNames={{arrow: styles.arrow, root: styles.root}}
          children={<></>}/>
        {isLoading && (
          <div style={{padding: "24px"}}>
            <Loader/>
          </div>
        )}
        <div className={styles.info}>
          <div className={styles.row}>
            <div className={styles.row}>
              <div className={styles.description}>
                Назначенных показов:
              </div>
              <div className={styles.text}>
                {statistic?.totalForPeriod?.planned ?? 0}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.description}>
                Новых предзаявок:
              </div>
              <div className={styles.text}>
                {statistic?.totalForPeriod?.newPreApplications ?? 0}
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.row}>
              <div className={styles.description}>
                Проведенных показов:
              </div>
              <div className={styles.text}>
                {statistic?.totalForPeriod?.finished ?? 0}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.description}>
                Макс. предложение:
              </div>
              <div className={styles.text}>
                {`${statistic?.totalForPeriod?.maxOffer ?? 0} ₽`}
              </div>
            </div>
          </div>
        </div>
        {isFromShows &&
          <RadioButton
            separate
            className={cn(styles.radioButton)}
            name="kind"
            options={kindOptions}
            control={control}
            handleOnChange={(val) => handleChangeKind(val)}
            currentValue={radioValue}
          />
        }
        {isFromShows &&
          <div>
            {statistic &&
              <StatisticDetalization
                chartPointsData={statistic.chartPoints}
                isRefetching={isLoading}
                selectedOption={kind}
              />
            }
          </div>
        }
      </div>
      {!isFromShows &&
        <div>
          {statistic?.contact
            ? <CardContact contact={statistic.contact} withoutDots mini={isMobile}/>
            : <Plug plugText="Покупатель пока не найден" mini={isMobile}/>
          }
        </div>}

    </div>
  )
}