import { Button, Tooltip } from "@sdelka_crm/sdelka-crm-component-library";
import cn from "classnames";
import { PlatformData } from "../../../../../../../types";
import { getCurrentStatusLayout } from "../../../../../../../utils/ads";
import styles from "./index.module.scss";

export const generateSimpleTableData = (
	platforms: PlatformData[],
	allAdResults: Record<string, boolean>,
	{ onStartAd, onStopAd }: { onStartAd: (id: string) => void; onStopAd: (id: string) => void },
	loading,
	currentlyUpdating
) =>
	platforms.map((platformItem) => {
		const { adStatus } = platformItem;

		const platform = <div className={styles.platform}>{platformItem.name}</div>;

		const [{ isPosted, isWaiting, isError }, , Status] = getCurrentStatusLayout({
			adStatus,
		});

		let buttonText = "Разместить";
		let status: string | JSX.Element = "Не рекламируется";

		if (isPosted) {
			buttonText = "Остановить";
			status = "В рекламе";
		}

		if (isWaiting) {
			buttonText = "Отменить";
			status = "Ожидает размещения";
		}

		if (isError) buttonText = "Отменить";

		const isLoading = loading && currentlyUpdating === platformItem.id;

		if (isError) {
			status = (
				<>
					<span>Ошибка</span>
					<Tooltip
						classNames={{
							root: styles.errorTooltip,
							card: styles.errorTooltipCard,
						}}
					>
						Ошибка в размещении объекта из-за нарушение правил площадки
					</Tooltip>
				</>
			);
		}

		const onClick =
			isPosted || isWaiting || isError ? () => onStopAd(platformItem.id) : () => onStartAd(platformItem.id);

		const actions: JSX.Element = (
			<Button variant="text" disabled={ isLoading} onClick={onClick}>
				{isLoading ? "Загрузка..." : buttonText}
			</Button>
		);

		return {
			platform,
			status: (
				<span className={cn(styles.status, { [styles.statusError]: isError })}>
					{isLoading ? "Загрузка..." : status}
				</span>
			),
			time: !isWaiting ? Status : "",
			actions,
		};
	});
