import styles from "./index.module.scss"
import { useState } from "react";
import { CardTaskList, Popup, TabsRadio } from "../../../../UI";
import cn from "classnames";
import { TaskCountInfo, TaskStatusVariant } from "../../../../../types";
import { useLoadTasks } from "../../../../../service/api/queries";
import { LoadByScroll } from "@sdelka_crm/sdelka-crm-component-library";
import { Plug } from "../../../../UI/ApplicationComponents/helpers";
import { selectDevice, useSelect } from "../../../../../service/redux/selectors";

const getOptions = (countData?: TaskCountInfo) => [
    {label: "Взаимодействие", count: countData?.communications || 0},
    {label: "По показам", count: countData?.showOrViews || 0},
    {label: "Обычные", count: countData?.defaults || 0},
  ];

const activeStages: TaskStatusVariant[] = ["new", "inWork", "done", "failed"];

export const DashboardStatisticTasks = () => {
  const {isMobile} = useSelect(selectDevice)
  const [date, setDate] = useState<Date>(new Date())
  const atToday = "на сегодня"
  const [title, setTitle] = useState<string>(atToday)
  const [type, setType] = useState<string[]>(["communications"])

  const getFilter = (taskType: string) => ({
      taskType: [taskType],
      variant: activeStages,
      date
    })

  const defaultFilter = () => ({
      taskType: ["communications"],
      variant: activeStages,
      date,
    })

  const {
    data = [],
    tabsCount,
    setFilter,
    refetch: refetchTask,
    isLoading
  } = useLoadTasks({
    defaultFilter: defaultFilter(),
    defaultStage: ["new", "inWork", "done", "failed"],
    enabled: true,
  });

  const handleChangeDate = (value: Date) => {
    setDate(value)

    setFilter({
      taskType: type,
      variant: activeStages,
      date,
    })
    setTimeout(() => {
      refetchTask()
    }, 50)
  }

  const actualMenu = [
    {
      label: "На сегодня",
      onClick: () => {
        handleChangeDate(new Date())
        setTitle(atToday)
      },
      id: 1,
      selected: title === atToday
    },
    {
      label: "На завтра",
      onClick: () => {
        handleChangeDate(new Date(Date.now() + (1000 * 60 * 60 * 24)))
        setTitle("на завтра")
      },
      id: 2,
      selected: title === "на завтра"
    },
  ]

  const onTabChange = (idx: number) => {
    if (idx === 0) {
      setType(["communications"])
      setFilter(getFilter("communications"))
    }
    if (idx === 1) {
      setType(["showOrViews"])
      setFilter(getFilter("showOrViews"))
    }
    if (idx === 2) {
      setType(["defaults"])
      setFilter(getFilter("defaults"))
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <div className={styles.title}>Задачи</div>
        <Popup
          openTrigger={<div className={styles.openTrigger}>{title}</div>}
          options={{withArrow: true}}
          classNames={
            {
              trigger: styles.clicked,
              arrow: styles.arrow,
              card: styles.popupCard
            }
          }
        >
          {actualMenu.map((item) => (
            <div key={item.id} className={cn(
              styles.actionItem,
              {[ styles.selected ]: item.selected}
            )} onClick={item.onClick}>
              <span>{item.label}</span>
            </div>
          ))}
        </Popup>
      </div>
      <div className={styles.content}>
        <div className={styles.menu}>
          <TabsRadio initialTab={0} titles={getOptions(tabsCount)} onTabChange={onTabChange} background={styles.radio}>
            <div/>
            <div/>
          </TabsRadio>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.tasks}>
          {/* eslint-disable-next-line no-restricted-globals */}
          <LoadByScroll height={250} scrollY={scrollY} isLoading={isLoading} onInView={() => {
          }}>
            {data?.length ? (
              <CardTaskList inTable tasks={data || []} refetch={refetchTask} mini={isMobile}/>
            ) : (
              <div className={styles.noTasks}><Plug plugText="Задачи на выполнение отсутствуют"/></div>
            )}
          </LoadByScroll>
        </div>
      </div>
    </div>
  )
}
