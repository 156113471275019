import cn from "classnames";

import { NO_IMG_VALUE_COMP } from "../../../../../../../../service/redux/modules/compilation/compilation.action";
import { selectCompilation, useSelect } from "../../../../../../../../service/redux/selectors";

import { ReactComponent as BuildingsIcon } from "../../../../../../../../assets/icons/buildings.svg";
import { ReactComponent as HelpIcon } from "../../../../../../../../assets/icons/help-outlined.svg";
import { ReactComponent as PlusIcon } from "../../../../../../../../assets/icons/plus.svg";
import styles from "./index.module.scss";

type Props = {
	isBlindOpen: boolean;
};

export const Stub = ({ isBlindOpen }: Props): JSX.Element => {
	const { hoveredRealEstateImg } = useSelect(selectCompilation);
	const NO_IMG = hoveredRealEstateImg === NO_IMG_VALUE_COMP;

	return (
		<div className={styles.root}>
			<div className={cn(styles.block, { [styles.blockWhite]: NO_IMG })}>
				<div
					className={cn(styles.imgBlock, {
						[styles.imgBlockBlindOpen]: isBlindOpen,
					})}
				>
					{hoveredRealEstateImg && !isBlindOpen ? (
						<>
							{NO_IMG ? (
								<BuildingsIcon className={styles.buildingIcon} />
							) : (
								<img src={hoveredRealEstateImg} />
							)}
						</>
					) : (
						<HelpIcon className={styles.helpIcon} />
					)}
				</div>

				{isBlindOpen && (
					<div className={cn(styles.help, { [styles.helpBlindOpen]: isBlindOpen })}>
						<span>
							Добавьте объект, нажав на
							<br />
							на карточке объекта
							<PlusIcon />
						</span>
					</div>
				)}
			</div>
		</div>
	);
};
