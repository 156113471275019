import { Clusterer } from "react-yandex-maps";
import { PointType } from "../Map";
import { PointWithBalloon } from "./components";
import useYMaps from "../map-utils/useYMaps";
import { clusterShape, createIconsClusterTemplate } from "../map-utils/utils";

interface Props {
  point: PointType;
  priceType: string;
  onUnselectPoint: () => void;
  filterByMapClick?: (ids?: string[]) => void
}

export const SelectedPoint = (
  {
    point,
    priceType,
    onUnselectPoint,
    filterByMapClick,
  }: Props): JSX.Element => {
  const {ymaps} = useYMaps();

  return (
    <Clusterer
      options={{
        clusterIconLayout: ymaps?.templateLayoutFactory.createClass(
          createIconsClusterTemplate("properties.geoObjects.length")
        ),
        iconShape: clusterShape,
      }}
    >
      <PointWithBalloon
        point={point}
        priceType={priceType}
        onUnselectPoint={onUnselectPoint}
        filterByMapClick={filterByMapClick}
      />
    </Clusterer>
  );
};
