import { useQuery } from "react-query";
import { ShowAndViewsTabs, ShowsOrViews, ShowsViewsStatisticPayload } from "../../../../types";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/shows-views/load-shows-views-statistic/DEFAULT_QN";

type Props = {
  payload: ShowsViewsStatisticPayload
}

export const useLoadStatistic = ({payload}: Props) => {
  const {
    applicationId,
    dateFrom,
    dateTo,
    kind,
    type,
    groupBy
  } = payload

  const loadStatistic = () => api.showsViews.getStatistic({applicationId,groupBy, kind, dateTo, dateFrom, type})

  const {data, refetch, isLoading, isRefetching} = useQuery([DEFAULT_QN], loadStatistic, {cacheTime: 0})

  return {
    statistic: data?.data,
    refetch,
    isLoading,
    isRefetching
  }
}