import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import cn from "classnames";
import isEqual from "lodash.isequal";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { ButtonSquare, Checkbox, MultiLayerSelect, MultiSelect, RoundInput } from "../../../UI";
import { CONTACT_DEFAULT_FILTERS } from "../../../../service/api/helpers";
import { useLoadUsers } from "../../../../service/api/queries";
import { prepareResponsibleUsers } from "../../../../utils/transformation";
import { mapFilterInputValues } from "./utils";
import { ApplicationsFilter, ContactFilter, LeadFiltersType, MultiLayerSelectOption } from "../../../../types";

import { ReactComponent as CancelIcon } from "../../../../assets/icons/cancel.svg";
import styles from "./index.module.scss";


type Props = {
	filter: ContactFilter;
	formSubmit: Dispatch<SetStateAction<ApplicationsFilter | LeadFiltersType | ContactFilter>>;
	setPage?: (page: number) => void;
	alertMargin?: string
};

export const ContactsFilters = ({ filter, formSubmit, setPage, alertMargin }: Props): JSX.Element => {
	const [showBtn, setShowBtn] = useState(false);
	const { register, handleSubmit, setValue, getValues, reset, control, watch } = useForm({
		defaultValues: CONTACT_DEFAULT_FILTERS,
	});

	const formWatcher = watch();
	const [typeWatcher, responsiblesWatcher] = [
		useWatch({
			name: "type",
			control,
			defaultValue: getValues("type") || [],
		}),
		useWatch({
			name: "responsibles",
			control,
			defaultValue: getValues("responsibles") || [],
		}),
	];

	const prepareSubmitForm = (values) => {
		reset(values);

		const mappedValues = mapFilterInputValues(values);
		formSubmit({
			...filter,
			...mappedValues,
		});
		if (setPage) {
			setPage(1);
		}

		setShowBtn(false);
	};

	const { users } = useLoadUsers({});
	const responsibleUsers = useMemo((): MultiLayerSelectOption[] => prepareResponsibleUsers(users), [users]);

	const contactTypeOptions = [
		{ label: "Агент", value: "agency" },
		{ label: "Клиент", value: "customer" },
		{ label: "Другое", value: "other" },
	];

	useEffect(() => {
		if (!isEqual(formWatcher, filter)) {
			if (!showBtn) {
				setShowBtn(true);
			}
		} else {
			setShowBtn(false);
		}
	}, [Object.values(formWatcher), filter]);

	const resetFilter = () => {
		reset(CONTACT_DEFAULT_FILTERS);
		formSubmit(CONTACT_DEFAULT_FILTERS);
	};

	return (
		<form className={cn(styles.root)} style={{top: `${alertMargin}px`}} onSubmit={handleSubmit(prepareSubmitForm)}>
			<div className={styles.firstLine}>
				<div className={cn(styles.filterWrapper, styles.searchWrapper)}>
					<RoundInput register={register} name="fullTextSearch" prepend="search" placeholder="Поиск" />
				</div>

				<div className={cn(styles.filterWrapper, styles.typeWrapper)}>
					<MultiSelect
						name="type"
						setValue={setValue}
						options={contactTypeOptions}
						register={register}
						placeholder="Тип контакта"
						defaultValue={typeWatcher}
					/>
				</div>

				<div className={cn(styles.filterWrapper, styles.responsibleWrapper)}>
					<MultiLayerSelect
						name="responsibles"
						options={responsibleUsers || []}
						register={register}
						placeholder="Ответственный"
						setValue={setValue}
						isSearchable
						defaultValue={responsiblesWatcher}
					/>
				</div>

				<div className={cn(styles.filterWrapper, styles.withActiveWrapper)}>
					<div>С активной</div>

					<Checkbox
						control={control}
						name="withApplication"
						label="Заявкой"
						variant="noOpacity"
						weight="bold"
					/>

					<Checkbox control={control} name="withObject" label="Объектом" variant="noOpacity" weight="bold" />
				</div>
			</div>

			<div className={styles.line}>
				<Button
					variant="text"
					classNames={{ root: styles.resetBtn, icon: styles.cancelIcon }}
					onClick={resetFilter}
					Icon={CancelIcon}
				>
					<span>Сбросить</span>
				</Button>
			</div>

			{showBtn && (
				<ButtonSquare type="submit" classNames={{ root: styles.submitBtn }}>
					Показать
				</ButtonSquare>
			)}
		</form>
	);
};
