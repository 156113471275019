import { Control, useForm, UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form";

type Return = {
	quizGetValues: UseFormGetValues<any>;
	quizSetValue: UseFormSetValue<any>;
	quizRegister: UseFormRegister<any>;
	quizControl: Control<any>;
};

type UseQuiz = () => Return;

export const useQuiz: UseQuiz = () => {
	const {
		control: quizControl,
		register: quizRegister,
		setValue: quizSetValue,
		getValues: quizGetValues,
	} = useForm<any>({});

	return {
		quizGetValues,
		quizSetValue,
		quizRegister,
		quizControl,
	};
};
