import { Tooltip } from "@sdelka_crm/sdelka-crm-component-library";

import { FormPropsEditRealEstate, RealEstatePhoto, RealEstateSchema } from "../../../../../types";
import { Address, BuildingInfo, FieldsForAd, Gallery } from "./components";
import styles from "./index.module.scss";

type Props = {
  id: string;
  schema: RealEstateSchema;
  form: FormPropsEditRealEstate;
  photos?: RealEstatePhoto[],
  isCreation?: boolean
  setPhotos?: React.Dispatch<React.SetStateAction<RealEstatePhoto[]>>
};

export const ObjectEditLayout = (
  {
    id,
    schema,
    form,
    photos,
    setPhotos,
    isCreation
  }: Props): JSX.Element => {
  const {objectEditConfig} = schema;
  const aboutRealEstate = objectEditConfig.slice(1, objectEditConfig.length - 1);
  const dealInfo = objectEditConfig.slice(objectEditConfig.length - 1)?.[ 0 ]?.blocks?.[ 0 ]?.fields;

  const {formState} = form;

  return (
    <>
      <div className={styles.card}>
        <h3 className={styles.addressH3}>
          Адрес объекта
          <Tooltip
            classNames={{
              root: styles.addressTooltip,
              card: styles.addressTooltipCard,
            }}
          >
            Введите адрес вручную или передвиньте маркер на карте.
          </Tooltip>
        </h3>
        <Address form={form} fields={objectEditConfig?.[ 0 ]?.blocks?.[ 1 ]?.fields}/>
      </div>
      <FieldsForAd/>
      <div className={styles.cardsContainer}>
        <div className={styles.leftCardsContainer}>
          {aboutRealEstate.map((about, aboutIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${about.bigTitle}-${aboutIndex}`}>
              {about.bigTitle && <h2>{about.bigTitle}</h2>}
              {about.blocks.map((block, blockIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`${block.blockName}-${blockIndex}`} className={styles.card}>
                  {block.blockName && <h3>{block.blockName}</h3>}
                  <BuildingInfo form={form} fields={block.fields}/>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <Gallery applicationId={id} photos={photos} setPhotos={setPhotos} isCreation={isCreation}/>
      <div className={styles.card}>
        <div className={styles.aboutDealContainer}>
          <h3>О сделке</h3>
          {dealInfo && <BuildingInfo form={form} fields={dealInfo}/>}
        </div>
      </div>
      {Object.keys(formState.errors).length ? (
        <div className={styles.error}>Заполните все поля правильно</div>
      ) : (
        <></>
      )}
    </>
  );
};
