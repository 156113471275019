import { useState } from "react";
import { useForm } from "react-hook-form";
import { Loader } from "@sdelka_crm/sdelka-crm-component-library";
import { KindType, ShowsViews, ShowsViewsFinishPayload } from "../../../../../types";
import { useCardStatusHandler } from "./useCardStatusHandler";
import styles from "./index.module.scss";
import { CardHeader } from "../card-header/CardHeader";
import { CardFooter } from "../card-footer/CardFooter";
import { CardActions } from "../card-actions/CardActions";
import { CardFeedback } from "../card-feedback/CardFeedback";
import { CardApplication } from "../../../../UI/Card/card-application";
import { CardApplicationMini } from "../../../../UI/Card/card-application/card-application-mini";

type Props = {
  isViews: boolean;
  item: ShowsViews;
  onSuccess: () => void;
  isMobile?: boolean
  hasSuccessShow?: boolean
  hasSuccessView?: boolean
};

export const CardWrapper = (
  {
    item,
    onSuccess,
    isViews,
    isMobile,
    hasSuccessShow,
    hasSuccessView
  }: Props): JSX.Element => {
  const [currentItem, setCurrentItem] = useState(item);

  const type = isViews ? "buyer" : "seller";

  const form = useForm({
    defaultValues: {
      offer: "",
    },
  });
  const {getValues, watch, setValue, control, register} = form;
  const watchForm = watch();

  const {isLoading} = useCardStatusHandler({
    item: currentItem,
    onSuccess,
    setCurrentItem,
    type,
    form: {getValues, setValue, watchForm},
  });

  const openApplication = (id?: string, kind?: KindType) => {
    window.open(`/leadsanddeals?tab=applications&${kind === KindType.PREAPPLICATION ? "preApplicationId" : "applicationId"}=${id}`)
  }

  const payload: ShowsViewsFinishPayload = {
    id: item.id,
    offer: getValues("offer") !== null ? Number(getValues("offer").replaceAll(" ", "")) : undefined,
  }

  return (
    <div className={styles.root}>
      <Loader height={450} style={{display: isLoading ? "block" : "none"}}/>
      <div style={{display: isLoading ? "none" : "block"}}>
        <div className={styles.cardRoot}>
          <CardHeader
            isViews={isViews}
            item={item}
            isMobile={isMobile}
          />
          <div className={styles.content}>
            {item.selectedApplication && <>
              <div className={styles.title}>
                {`Заявка на ${isViews ? "продажу" : "покупку"}`}
              </div>
              <div className={styles.cardApp} onClick={() => openApplication(
                item.selectedApplication?.id, item.selectedApplication?.shortType.kind
              )}>
                {isMobile
                  ? <CardApplicationMini
                    application={item.selectedApplication}
                    withoutStage
                    isMobile={isMobile}
                  />
                  : <CardApplication
                    application={item.selectedApplication}
                    kind={item.selectedApplication.shortType.kind}
                  />}
              </div>
            </>
            }
            <CardFeedback
              isViews={isViews}
              item={item}
              register={register}
              control={control}
              setValue={setValue}
              inputName="offer"
            />
          </div>
          <CardFooter isViews={isViews} item={item}/>
          <CardActions
            isViews={isViews}
            item={item}
            onSuccess={onSuccess}
            payload={payload}
            isMobile={isMobile}
            hasSuccessShow={hasSuccessShow}
            hasSuccessView={hasSuccessView}
          />
        </div>
      </div>
    </div>
  );
};
