import { UserNotLoaded } from "./components";
import { MainInfo } from "./components/main";
import { Characteristics } from "./components/characteristics/Characteristics";
import { PersonalCardStickyFooter } from "./components/sticky-footer/PersonalCardStickyFooter";
import { IUserEditFormRequest } from "../../../types";
import { CardOffice, MarkdownEditor, Textarea } from "../../UI";
import styles from "./index.module.scss"
import { useForm, useWatch } from "react-hook-form";
import { initialize } from "../../../service/redux/modules/auth/auth.thunk";
import { useGetUser, useUpdateUserCard } from "../../../service/api/mutations";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import cn from "classnames";
import { ReactComponent as SaveText } from "../../../assets/icons/saveComment.svg";
import { selectCurrentAccount, selectDevice, useSelect } from "../../../service/redux/selectors";
import { BuyHistory } from "./components/my-orders/components";
import { LayoutLoading } from "../RealEstatePage/mode/RealEstateViewWithTabs/LayoutLoading";
import { useIsTelegram } from "../../../utils/telegram/useIsTelegram";

type Props = {
  refetchAll?: () => void;
  userId?: string;
};

export const PersonalCard = (
  {
    refetchAll,
    userId,
  }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const {currentAccount} = useSelect(selectCurrentAccount);
  const {isMobile} = useSelect(selectDevice)
  const isCoWorking = currentAccount && currentAccount.position?.name === "Агент в коворкинге"
  const {id: currentUserId} = currentAccount || {};

  const {
    user,
    isFetching,
    refetch: refetchUser,
  } = useGetUser({userId: userId || currentUserId});

  const [editMode, setEditMode] = useState(false)
  const toggleMode = () => setEditMode((prev) => !prev);
  const onEditFormSuccessfulSubmit = () => {
    refetchAll?.();
    refetchUser()
    setEditMode(false);
    dispatch(initialize());
  };
  const {userUpdate, isLoading} = useUpdateUserCard({
    onSuccess: onEditFormSuccessfulSubmit,
  });
  const {handleSubmit, register, setValue, formState, control, getValues} = useForm<IUserEditFormRequest>({
    defaultValues: {name: user?.name, secondName: user?.secondName, lastName: user?.lastName}
  });
  const onSubmit = (values: IUserEditFormRequest) => {
    const payload = {
      ...values,
    }
    userUpdate({id: user?.id || "", payload});
  };
  const CAN_EDIT = user?.canEdit

  if (!isFetching && !user) {
    return <UserNotLoaded refetch={refetchAll}/>;
  }
  const {executeUrl} = useIsTelegram()

  const onOfficeClick = (id: string) => {
   executeUrl(`/services?tab=OFFICES&officeId=${id}`)
  }
  const [valueSpec, setValueSpec] = useState<string>(user?.specialisations ?? "")
  const onSaveSpec = (val: string) => {
    if (val !== user?.specialisations) {
      onSubmit({id: user?.id || "", specialisations: val});
    }
  };

  const onSaveAbout = () => {
    onSubmit({id: user?.id || "", aboutMe: getValues("aboutMe")});
  };

  useEffect(() => {
    if (user) {
      setValue("aboutMe", user.aboutMe)
      setValue("specialisations", user.specialisations)
    }
  }, [setValue, user])

  const specWatcher = useWatch({
    control,
    name: "specialisations",
    defaultValue: user?.specialisations || "",
  })

  const aboutMeWatcher = useWatch({
    name: "aboutMe",
    control,
    defaultValue: user?.aboutMe || getValues("aboutMe"),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {user
        ? <div className={styles.root}>
          {user?.department?.office &&
            <div className={styles.office}>
              <CardOffice
                office={user.department.office}
                onCardClick={() => onOfficeClick(user?.department?.officeId ?? "")}
                mini={isMobile}
              />
            </div>
          }
          <MainInfo
            user={user}
            canEdit={CAN_EDIT}
            refetch={refetchAll}
            control={control}
            editMode={editMode}
            formState={formState}
            register={register}
            setValue={setValue}/>
          {!isCoWorking &&
            <Characteristics
              user={user}
              control={control}
              editMode={editMode}
              formState={formState}
              register={register}
              setValue={setValue}
            />
          }
          <div>
            <div className={styles.title}>
              Описание
            </div>
            <div className={styles.subtitle}>
              Специализация
            </div>
            <Textarea
              name="specialisations"
              rows={4}
              register={register}
              className={styles.comment}
              onChange={(text) => setValueSpec(text)}
              readOnly={isLoading}
              value={specWatcher}
            />
            <div
              className={cn(styles.saveText, {[ styles.saveTextSaving ]: isLoading})}
              onClick={() => onSaveSpec(valueSpec)}
              hidden={isLoading}
            >
              <SaveText/>
            </div>
            <div className={styles.subtitle}>
              Обо мне
            </div>
            <MarkdownEditor
              name="aboutMe"
              setValue={setValue}
              getValues={getValues}
              register={register}
              error={formState.errors.aboutMe?.message?.toString()}
              curValue={aboutMeWatcher}
            />
            <div
              className={cn(styles.saveText)}
            >
              <div onClick={() => onSaveAbout()}>
                <SaveText/>
              </div>
            </div>
          </div>
          {isCoWorking &&
            <div className={styles.orders}>
              {/* <PurchasedServices/> */}
              <BuyHistory/>
            </div>
          }
          <div className={styles.footer}>
            <div>
              <div>ID сотрудника:</div>
              <div>{user?.shortId}</div>
            </div>
          </div>
        </div>
        : <LayoutLoading/>
      }
      <PersonalCardStickyFooter
        user={user}
        canEdit={CAN_EDIT}
        refetch={refetchAll}
        editMode={editMode}
        isLoading={isLoading}
        toggle={toggleMode}
        isMobile={isMobile}
      />
    </form>
  );
};
