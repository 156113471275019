import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../../../../service/redux/modules/modal/modal.action";
import { useChangeOffice } from "../../../../../service/api/mutations";
import { closeDrawer } from "../../../../../service/redux/modules/drawer/drawer.action";
import { SelectOfficeModal } from "./SelectOfficeModal";

type Props = {
  id: string
  refetch: () => void
}

type Return = {
  openChangeModal: () => void;
}

export const useChangeOfficeModal = (
  {
    id,
    refetch
  }: Props): Return => {
  const dispatch = useDispatch();

  const {mutate} = useChangeOffice({
    userId: id, onSuccess: () => {
      dispatch(closeModal())
      dispatch(closeDrawer())
      refetch()
    }
  })

  const title = "Сменить офис"

  const handleChange = (officeId: string | undefined) => {
    if (officeId) {
      mutate(officeId)
    }
  }

  const openChangeModal = () => {
    dispatch(
      openModal({
        body: (
          <SelectOfficeModal
            onSelect={handleChange}
            title={title}
          />
        ),
        width: 800,
      })
    );
  };

  return {
    openChangeModal
  }
}