import { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import cn from "classnames";

import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-down.svg";
import styles from "./index.module.scss";

export type FixedNavMenu = {
	label: string;
	action: () => void;
	id?: string;
	hidden?: boolean;
};

export type FixedNavProps = {
	navigation: FixedNavMenu[];
	additionalLabel?: ReactNode | string;
	classNames?: {
		root?: string;
	};
	activeNav?: {
		activeLabel: string;
		setActive?: Dispatch<SetStateAction<string>>;
	};
	navVariant?: "white" | "grey";
};

export const FixedNav = ({
	navigation,
	additionalLabel,
	classNames,
	activeNav,
	navVariant = "white",
}: FixedNavProps): JSX.Element => {
	const [leftArrowVisible, setLeftArrowVisible] = useState(false);
	const [rightArrowVisible, setRightArrowVisible] = useState(false);

	const [rightArrowPositionRight, setRightArrowPositionRight] = useState(0);

	const navRef = useRef<HTMLDivElement>(null);
	const labelRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (additionalLabel && labelRef.current?.offsetWidth) {
			setRightArrowPositionRight(labelRef.current?.offsetWidth);
		} else {
			setRightArrowPositionRight(0);
		}
	}, [navigation, additionalLabel, labelRef.current?.offsetWidth]);

	const checkLeftOffset = (futureScrollLeft: number) => {
		if (futureScrollLeft <= 2) {
			setLeftArrowVisible(false);
		} else if (!leftArrowVisible) {
			setLeftArrowVisible(true);
		}
	};

	const checkRightOffset = (futureScrollRight: number) => {
		if (futureScrollRight <= 2) {
			setRightArrowVisible(false);
		} else if (!rightArrowVisible) {
			setRightArrowVisible(true);
		}
	};

	const checkAfterNavUpdate = useDebouncedCallback(() => {
		if (navRef.current) {
			const { scrollWidth, scrollLeft, clientWidth } = navRef.current;
			checkRightOffset(scrollWidth - scrollLeft - clientWidth);
		}
	}, 250);

	useEffect(() => {
		checkAfterNavUpdate();
	}, [navRef.current, navigation]);

	const handleLeftArrowClick = () => {
		if (navRef.current) {
			const { scrollWidth, scrollLeft, clientWidth } = navRef.current;
			const newLeftValue = scrollLeft - 100;

			navRef.current.scroll({ top: 0, left: newLeftValue, behavior: "smooth" });
			checkLeftOffset(newLeftValue);
			checkRightOffset(scrollWidth - newLeftValue - clientWidth);
		}
	};

	const handleRightArrowClick = () => {
		if (navRef.current) {
			const { scrollWidth, scrollLeft, clientWidth } = navRef.current;
			const newLeftOffset = scrollLeft + 100;

			navRef.current.scroll({
				top: 0,
				left: newLeftOffset,
				behavior: "smooth",
			});
			checkRightOffset(scrollWidth - newLeftOffset - clientWidth);
			checkLeftOffset(newLeftOffset);
		}
	};

	const handleNavItemClick = (navItem: FixedNavMenu, index: number) => {
		if (activeNav?.setActive) {
			activeNav.setActive(navItem.label);
		}

		navItem.action();

		const navItemElement = document.getElementById(`fixed-nav-item-${index}`);
		if (navItemElement && navRef.current) {
			const { offsetLeft: elementOffsetLeft, offsetWidth: elementWidth } = navItemElement;
			const {
				scrollLeft: navScrollLeft,
				clientWidth: navWidth,
				scrollWidth: navScrollWidth,
			} = navRef.current;

			const elementOffsetRight = elementOffsetLeft + elementWidth;
			const navScrollRight = navScrollLeft + navWidth;

			if (elementOffsetRight > navScrollRight) {
				const newLeftOffset = navScrollLeft + elementWidth;
				navRef.current.scroll({
					top: 0,
					left: newLeftOffset,
					behavior: "smooth",
				});
				checkRightOffset(navScrollWidth - newLeftOffset - navWidth);
				checkLeftOffset(newLeftOffset);

				return;
			}

			if (elementOffsetLeft < navScrollLeft) {
				const newLeftOffset = navScrollLeft - elementWidth;
				navRef.current.scroll({
					top: 0,
					left: navScrollLeft - elementWidth,
					behavior: "smooth",
				});

				checkRightOffset(navScrollWidth - newLeftOffset - navWidth);
				checkLeftOffset(newLeftOffset);
			}
		}
	};

	const colorVariant = navVariant && navVariant.charAt(0).toUpperCase() + navVariant.slice(1);

	return (
		<div className={cn(styles.container, classNames?.root, styles[`container${colorVariant}`])}>
			<div className={cn(styles.fixedNavContainer)}>
				{leftArrowVisible && (
					<div
						className={cn(styles.leftArrow, styles[`arrow${colorVariant}`])}
						onClick={handleLeftArrowClick}
					>
						<ArrowIcon />
					</div>
				)}

				<div ref={navRef} className={styles.navigation}>
					{navigation.map((nav, index) => (
						<div
							id={`fixed-nav-item-${index}`}
							key={nav.label}
							className={cn(styles.navItem, styles[`navItem${colorVariant}`], {
								[styles.navItemActive]: activeNav?.activeLabel === nav.label,
								[styles.navItemHidden]: nav.hidden,
							})}
							onClick={() => handleNavItemClick(nav, index)}
						>
							{nav.label}
						</div>
					))}
				</div>

				{rightArrowVisible && (
					<div
						style={{ right: rightArrowPositionRight }}
						className={cn(styles.rightArrow, styles[`arrow${colorVariant}`])}
						onClick={handleRightArrowClick}
					>
						<ArrowIcon />
					</div>
				)}

				<div className={styles.notNavigation}>
					{additionalLabel ? (
						<div ref={labelRef} className={styles.label}>
							{additionalLabel}
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};
