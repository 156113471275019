import { useMutation } from "react-query";
import { api } from "../../api";
import { CartPayload, CartResponse } from "../../../../types";
import { AxiosResponse } from "axios";

const DEFAULT_MN = "sdelka/compilation/use-buy-in-one/DEFAULT_MN";

type UseBuyInOneClick = {
  onSuccess?: (data: AxiosResponse<CartResponse>) => void;
};

export const useBuyInOneClick = ({ onSuccess }: UseBuyInOneClick) => {
  const mutate = useMutation(DEFAULT_MN, {
    mutationFn: (payload: CartPayload[]) => api.cart.buyInOneClick(payload),
    onSuccess,
  });

  return {
    data: mutate.data?.data,
    mutate: mutate.mutate,
    mutateAsych: mutate.mutateAsync,
    isLoading: mutate.isLoading,
  };
};
