import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { UseFormGetValues, UseFormReset, UseFormWatch } from "react-hook-form";
import isEqual from "lodash.isequal";

import { setFiltersShowButton } from "../../../../../../../service/redux/modules/object-list/object-list.action";
import { clearObject, deleteEmptyField, makeEmpty } from "../../../../../../../utils/objects";
import { useQueryParams } from "../../../../../../../utils/hooks";
import { wasChangeVariant } from "../../../../../../../utils/clientObjects";
import { CategoryEnum, RealEstateFilter } from "../../../../../../../types";
import { getWantedFiltersForCategory } from "./getWantedFiltersForCategory";

type UseFormActions = {
	filter: RealEstateFilter;
	setFilter: Dispatch<SetStateAction<RealEstateFilter>>;
	getValues: UseFormGetValues<any>;
	formWatcher: UseFormWatch<any>;
	reset: UseFormReset<any>;
	setObjectsFilterTypes: Dispatch<SetStateAction<string[]>>;
	globalFilter: RealEstateFilter;
	changeGlobalFilter: (payload: RealEstateFilter) => void;
	changeActualFilter: (payload: RealEstateFilter) => void;
	filtersShowButton: boolean;
};

export const useFormActions = ({
	filter,
	setFilter,
	getValues,
	formWatcher,
	reset,
	setObjectsFilterTypes,
	globalFilter,
	changeGlobalFilter,
	changeActualFilter,
	filtersShowButton,
}: UseFormActions) => {
	const dispatch = useDispatch();

	const filtersShowButtonWrapper = () => filtersShowButton && dispatch(setFiltersShowButton(false));

	const { queryParams, changeQueryParams } = useQueryParams<{
		compilation: boolean;
		filter: string;
	}>([{ name: "compilation" }, { name: "filter" }]);

	const onChangePriceFrom = (price: string) => {
		setFilter({
			...filter,
			priceFrom: price.replace(/\s/g, ""),
		});
		changeActualFilter({
			...filter,
			priceFrom: price.replace(/\s/g, ""),
		});

		filtersShowButtonWrapper();
	};

	const onChangePriceTo = (price: string) => {
		setFilter({
			...filter,
			priceTo: price.replace(/\s/g, ""),
		});
		changeActualFilter({
			...filter,
			priceFrom: price.replace(/\s/g, ""),
		});

		filtersShowButtonWrapper();
	};

	const onChangeDealType = (newTypeValue) => {
		setFilter({
			...filter,
			type: {
				value: newTypeValue.length ? newTypeValue : "",
			},
		});
		changeActualFilter({
			...filter,
			type: {
				value: newTypeValue.length ? newTypeValue : "",
			},
		});

		filtersShowButtonWrapper();
	};

	const onChangeUnit = (unit: string) => {
		setFilter({
			...filter,
			unit: unit.length ? unit : "",
		});
		changeActualFilter({
			...filter,
			unit: unit.length ? unit : "",
		});

		filtersShowButtonWrapper();
	};

	const onChangeResponsible = (responsible?: string[]) => {
		setFilter({
			...filter,
			responsible: responsible?.length ? responsible : undefined,
		});
		changeActualFilter({
			...filter,
			responsible: responsible?.length ? responsible : undefined,
		});
	}

 const	onChangeAddressParser = (value: string) => {
		setFilter({
			...filter,
			address: undefined
		})
 }

	const	onChangeAddress = (value: string) => {
		setFilter({
			...filter,
			addressParser: undefined
		})
	}

	// eslint-disable-next-line sonarjs/cognitive-complexity
	const onChangeObjectType = (category: CategoryEnum[], prev: CategoryEnum[]) => {
		if (wasChangeVariant(category, prev)) {
			const currentFormValue = getValues();
			const form = makeEmpty(currentFormValue);
			const persistentFields = getWantedFiltersForCategory(category, currentFormValue);

			setFilter({ category });
			reset({
				...form,
				...persistentFields,
				category,
			} as any);
		} else {
			setFilter({
				...globalFilter,
				...filter,
				category,
			});
		}

		filtersShowButtonWrapper();

		setObjectsFilterTypes(category);
	};

	const saveFilter = (filterForSave: RealEstateFilter) => {
		if (!queryParams.compilation) {
			const encodedBackPath = encodeURIComponent(JSON.stringify(filterForSave));
			changeQueryParams([{ name: "filter", newValue: encodedBackPath, isEncode: true }]);
		}
	};

	const onFormSubmit = (values: RealEstateFilter) => {
		const newFilter = clearObject(values);

		reset(newFilter);
		setFilter(newFilter);
		changeGlobalFilter(newFilter);
		saveFilter(newFilter);
	};

	const [showBtn, setShowBtn] = useState(false);

	useEffect(() => {
		if (!isEqual(deleteEmptyField(globalFilter), deleteEmptyField(filter))) {
			reset(globalFilter);
			setFilter(globalFilter);
			setObjectsFilterTypes(globalFilter?.category || []);
		}
	}, [globalFilter]);

	useEffect(() => {
		const clearedFormWatcher = deleteEmptyField(formWatcher);

		if (!isEqual(clearedFormWatcher, deleteEmptyField(globalFilter))) {
			if (!isEqual(clearedFormWatcher, deleteEmptyField(filter))) {
				setFilter(formWatcher);
				setShowBtn(true);
			}
		} else {
			setShowBtn(false);
		}
	}, [formWatcher, globalFilter]);

	return {
		showBtn,
		onChangePriceFrom,
		onChangePriceTo,
		onChangeDealType,
		onChangeUnit,
		onChangeObjectType,
		onFormSubmit,
		onChangeResponsible,
		onChangeAddressParser,
		onChangeAddress
	};
};
