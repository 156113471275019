import { Dispatch, SetStateAction, useState } from "react";
import { useQuery } from "react-query";

import { Department, DepartmentFilters } from "../../../../types";
import { api } from "../../index";

type Props = {
	defaultFilters?: DepartmentFilters;
	onError?: () => void;
	onSuccess?: () => void;
	enabled?: boolean;
};

type Return = {
	data?: Department[];
	isFetching: boolean;
	remove: () => void;
	refetch: () => void;
	filter: DepartmentFilters;
	setFilter: Dispatch<SetStateAction<DepartmentFilters>>;
};

type UseLoadDepartments = (props: Props) => Return;

export const useLoadDepartments: UseLoadDepartments = ({
	defaultFilters = {},
	onError,
	onSuccess,
	enabled = true,
}) => {
	const [filter, setFilter] = useState<DepartmentFilters>(defaultFilters);

	const loadDepartments = () => api.departments.getByIds({ search: filter });
	const { data, isFetching, remove, refetch } = useQuery(
		["loadDepartments", filter, enabled],
		loadDepartments,
		{ enabled, onError, onSuccess }
	);

	return {
		data: data?.data?.data,
		isFetching,
		remove,
		refetch,
		filter,
		setFilter,
	};
};
