import { useEffect } from "react";
import { Loader } from "@sdelka_crm/sdelka-crm-component-library";
import { formatPhone } from "../../../../../utils/formatters";
import { prepareShortUserOutput } from "../../../../../utils/transformation";
import { useOpenApplicationCard } from "../../../../../utils/application";
import { UserData } from "../../../../../types";
import styles from "./index.module.scss";
import { useLoadDeals } from "service/api/queries/deals/useLoadDeals";

type UserLocalData = UserData & { name: string };

export const AlertDeals = (): JSX.Element => {
  const {list: dealsList, setFilter, isFetching} = useLoadDeals({enabled: true});

  const {handleClickApplication} = useOpenApplicationCard({});

  useEffect(() => {
    setFilter({requiresAttention: true});
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {"Ваши заявки требующие внимания "}
        {!isFetching && <span>{`(${dealsList.length})`}</span>}
      </div>
      <Loader height={250} style={{display: isFetching ? "block" : "none"}}/>
      {!dealsList.length && !isFetching && (
        <div className={styles.noAlertApplications}>Заявки, требующие внимания, отсутствуют</div>
      )}
      <div className={styles.body} style={{display: isFetching ? "none" : "block"}}>
        {dealsList.map((deal) => {
          const {
            id,
            type: {whoIsClient, typeName},
            clientObject,
            contact
          } = deal;
          const {addressLabel} = clientObject || {};
          const phone = contact?.phone
          const user: UserLocalData = (contact || {}) as UserLocalData;
          const {name = "", secondName = "", lastName = ""} = user;
          const openApplication = () => handleClickApplication({applicationId: id, whoIsClient});
          return (
            <div key={id} className={styles.block} onClick={openApplication}>
              <div className={styles.address}>{`${typeName} ${addressLabel || ""}`}</div>
              <div className={styles.userInfo}>
                <span>{formatPhone(phone || "")}</span>
                {Boolean(name) && (
                  <span className={styles.name}>
										{prepareShortUserOutput({name, secondName, lastName})}
									</span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
