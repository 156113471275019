import { Dispatch, SetStateAction } from "react";
import { Contact } from "../../../../types";
import { CardContact } from "../../../UI/Card/card-contact/CardContact";

export const useColumns = (
  checked: string[],
  setChecked: Dispatch<SetStateAction<string[]>>,
  contextClick: string,
  refetch: () => void,
  isMobile?: boolean,
) => [
  {
    accessor: "contactCard",
    render: ({row}: { row: Contact, item: string, }) => (
      <CardContact contact={row} backgroundWhite mini={isMobile} withoutDots/>
    ),
  },
];