import { RealEstate } from "../../../../../../../types";

import styles from "./index.module.scss";
import { formatDate } from "@sdelka_crm/sdelka-crm-component-library";

type Props = {
  realEstate: RealEstate;
};

export const Footer = ({realEstate}: Props): JSX.Element => {
  const {shortId, createdAt, modifiedAt} = realEstate;

  return (
    <div className={styles.root}>
      <div className={styles.column}>
        <div className={styles.lightGrey}>
          ID объекта:
        </div>
        <div className={styles.darkGrey}>
          {shortId}
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.lightGrey}>
          Дата добавления:
        </div>
        <div className={styles.darkGrey}>
          {createdAt ? `${formatDate(createdAt)} в ${formatDate(createdAt, "time")}` : "Не указано"}
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.lightGrey}>
          Дата обновления:
        </div>
        <div className={styles.darkGrey}>
          {modifiedAt ? `${formatDate(modifiedAt)} в ${formatDate(modifiedAt, "time")}` : "Не указано"}
        </div>
      </div>
    </div>
  );
};
