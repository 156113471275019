import { useRouteMatch } from "react-router";
import cn from "classnames";
import { Loader, CollapseDown, H2 } from "@sdelka_crm/sdelka-crm-component-library";

import { CardObjectPublic, PublicLayout } from "../../../../UI";
import { CompareCompetitors, CompetitorsList } from "../../components";
import { useLoadCompare } from "../../../../../service/api/queries/public-page";

import styles from "./index.module.scss";

export const ComparisonPublic = (): JSX.Element => {
	const match = useRouteMatch<{ contactId: string; objectId: string }>();
	const { contactId, objectId } = match.params;

	const { data, isLoading } = useLoadCompare({ contactId, objectId });

	return (
		<PublicLayout classNames={{ content: styles.root }}>
			<div>
				<H2>Ваш объект</H2>

				<Loader height={250} style={{ display: isLoading ? "block" : "none" }} />
				{!isLoading && data && <CardObjectPublic clientObject={data.objectData} />}
			</div>

			<div>
				<H2>Сравнение с конкурентами</H2>

				<Loader height={250} style={{ display: isLoading ? "block" : "none" }} />
				{!isLoading && data && <CompareCompetitors charts={data.charts} />}
			</div>

			<div>
				<CollapseDown
					label={<H2 classNames={{ root: cn(styles.h2, styles.collapseHeader) }}>Список конкурентов</H2>}
				>
					<>
						<Loader height={250} style={{ display: isLoading ? "block" : "none" }} />
						{!isLoading && data && <CompetitorsList list={data.objectsToCompare} />}
					</>
				</CollapseDown>
			</div>
		</PublicLayout>
	);
};
