import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RealEstate, RealEstateFilter } from "../../../../../types";

import { RealEstateMap } from "../../../../UI";
import { setObjectsGroupIds } from "../../../../../service/redux/modules/object-list/object-list.action";
import { selectCurrentAccount, useSelect } from "../../../../../service/redux/selectors";
import { findCenterOfCoordinates } from "../../../../../utils/coordinates";
import { formatPhone } from "../../../../../utils/formatters";
import { useLoadCluster } from "./helpers";
import { PolygonGeometry } from "react-yandex-maps";
import { useLoadData } from "../../helpers";

type Props = {
  filter: RealEstateFilter;
  setFilter: (value) => void
  isParser?: boolean;
  isMobile?: boolean
  filterByMapClick?: (ids?: string[]) => void
  selectedPoint?: boolean
  setSelectedPoint?: React.Dispatch<React.SetStateAction<boolean>>
};

export const ObjectsMap = (
  {
    filter,
    setFilter,
    isParser,
    isMobile,
    filterByMapClick,
    selectedPoint,
    setSelectedPoint
  }: Props): JSX.Element => {

  const {
    list: realEstatesList,
    isFetching: isLoading,
    filter: filterMap,
    setFilter: setFilterMap,
    refetch: refetchList
  } = useLoadData({
    defaultPage: 1,
    tab: "PARSED",
  });

  const filterNew = Object.assign(filter, filterMap)
  const dispatch = useDispatch();
  const {handleMapChange, pointsToMap, clustersToMap} = useLoadCluster({
    isParser,
    filter,
  });
  const [centerXY, setCenterXY] = useState<[number | typeof NaN, number | typeof NaN]>([NaN, NaN]);
  const {currentAccount} = useSelect(selectCurrentAccount);

  const mapZoom = Number(currentAccount?.company?.mapZoom);
  const mapCenter = [currentAccount?.settings.mapCenter?.lon, currentAccount?.settings?.mapCenter?.lat] as [
    number,
    number
  ];

  useEffect(() => {
    if (!isLoading) {
      const coordinatesXY = findCenterOfCoordinates(
        realEstatesList?.map((object) => [
          object.location?.coordinates[ 0 ] as number,
          object.location?.coordinates[ 1 ] as number,
        ])
      )
      setCenterXY(coordinatesXY);
    }
  }, [realEstatesList.length, isLoading]);

  const getDefaultCenter = (initialCenter?: [number, number]): [number, number] => {
    if (!initialCenter || !initialCenter[ 0 ] || !initialCenter[ 1 ]) {
      return [59.94506601286178, 30.31705281156423];
    }
    return [initialCenter[ 0 ], initialCenter[ 1 ]];
  };

  const fetchPhoneNumber = (id: string): Promise<string> =>
    new Promise((resolve) => {
      setTimeout(() => {
        const objectToPreview = realEstatesList.find((object) => object.id === id) as RealEstate;
        const phone = formatPhone(objectToPreview?.responsibleUser?.account?.phone || "");

        resolve(phone);
      }, 500);
    });

  const onDrawPolygon = (polygon: number[][]) => {
    const newPolygons = filterNew?.polygon?.length ? [...filterNew.polygon] : [];
    newPolygons?.push([polygon]);
    setFilter({
      ...filterNew,
      polygon: newPolygons,
    });
    setFilterMap({
      ...filterNew,
      polygon: newPolygons,
    });
  };

  const onRemovePolygon = () => {
    const withNoPolygon = {...filterNew};
    withNoPolygon.polygon = [];
    setFilter({...filter, polygon: []});
    setFilterMap({...filterMap, polygon: []});
    refetchList()
  };

  useEffect(() => {
  }, [isParser]);

  const defaultPolygons: PolygonGeometry[] = filterNew?.polygon?.length ? [...filterNew.polygon] : []

  return (
    <RealEstateMap
      defaultPolygons={defaultPolygons}
      points={pointsToMap}
      clusters={clustersToMap}
      selectPointWithObjects={(point) =>
        dispatch(setObjectsGroupIds(point.objects.map((object) => object.id)))
      }
      getContactPhone={(id) => fetchPhoneNumber(id)}
      onDraw={onDrawPolygon}
      onRemovePolygon={onRemovePolygon}
      onMapChange={handleMapChange}
      options={{
        order: "map-first",
        center: mapCenter ?? getDefaultCenter(centerXY),
        zoom: mapZoom,
        updateCenterIfChanged: true,
      }}
      isParser={isParser}
      isMobile={isMobile}
      filterByMapClick={filterByMapClick}
      selectedPointOne={selectedPoint}
      setSelectedPoint={setSelectedPoint}
    />
  );
};
