import { ShowsViewsStatus } from "../../../../../../types";
import { formatDateWithTime } from "../../../../../../utils/date";

export const getShowViewDate = (
  status: ShowsViewsStatus,
  plannedDate?: Date,
  cancelDate?: Date,
  realDate?: Date
  ) => {
  switch (status) {
    case "waiting":
      return formatDateWithTime(plannedDate ?? new Date())
    case "successFinished":
      return formatDateWithTime(realDate ?? new Date())
    case "success":
      return formatDateWithTime(plannedDate ?? new Date())
    case "canceled":
      return formatDateWithTime(cancelDate ?? new Date())
    case "finished":
      return formatDateWithTime(plannedDate ?? new Date())
    default:
      return formatDateWithTime(plannedDate ?? new Date())
  }
}