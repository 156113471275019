import { useDispatch } from "react-redux";
import { closeDrawer, closeDrawerInDrawer } from "../../../../redux/modules/drawer/drawer.action";

type Props = {
  atDrawerInDrawer?: boolean
}

export const useCloseDrawer = (
  {
    atDrawerInDrawer
  }: Props) => {

  const dispatch = useDispatch()

  const close = () => {
    if (atDrawerInDrawer) {
      dispatch(closeDrawerInDrawer())
    } else {
      dispatch(closeDrawer())
    }
  }

  return {
    close
  }
}