import { ProductTypeEnum, User, UserStatusEnum, userStatuses } from "../../../../../types";
import { useBlinkRow, useQueryParams } from "../../../../../utils/hooks";
import { useColumns } from "./columns";
import { useCallback } from "react";
import styles from "./index.module.scss";
import { Table } from "../../../../UI";
import { ReactComponent as Cart } from "../../../../../assets/icons/Shopping_Cart.svg";
import { useAddToCart } from "../../../../../service/api/mutations";
import { useAddInCartModal } from "../../cart/helpers/addInCartModal/useAddInCartModal";
import cn from "classnames";
import { useOpenProfileCard } from "../../../../../service/servise";

type Props = {
  usersList: User[];
  isLoading: boolean;
  refetch: () => void;
  isMobile?: boolean
  refetchCart?: () => void
};

export const UsersTable = ({usersList, isLoading, refetch, isMobile, refetchCart}: Props): JSX.Element => {
  const {changeQueryParams} = useQueryParams<{
    userId: string;
  }>([{name: "userId"}]);
  const columns = useColumns(refetch, isMobile, refetchCart);
  const {rowsToBlink} = useBlinkRow();

  const {openProfileDrawer} = useOpenProfileCard({
    refetch,
    isMobile
  });

  const openUserSettingsDrawer = (accountId: string, userId: string) => {
    changeQueryParams([
      {name: "userId", newValue: userId},
    ]);
    openProfileDrawer(userId);
  };

  const {mutate} = useAddToCart({
    onSuccess: () => {
      refetch()
    }
  })

  const {openAddInCartModal} = useAddInCartModal({
    type: ProductTypeEnum.employerSubscribtion,
    title: "Выберите срок продления",
    action: mutate,
    isMobile,
    refetchCart,
  })

  const renderTitle: (item: string) => JSX.Element = useCallback(
    (status) => {
      const ids = usersList.filter(elem => elem.status === status).map(elem => elem.id)

      return (
        <div className={styles.row}>
          <div className={styles.title}>
            {userStatuses[ status ]}
          </div>
          {status !== UserStatusEnum.Active && status !== UserStatusEnum.Waiting &&
            <div className={styles.cart} onClick={() => openAddInCartModal(ids)}>
              <Cart/>
              Добавить всех
            </div>}
        </div>
      )
    },
    []
  );

  const handleRowClick = (row: User) => {
    openUserSettingsDrawer(row.account.id, row.id)
  };

  return (
    <div className={cn(styles.wrapper, {[ styles.isMobile ]: isMobile})}>
      {usersList?.length > 0 || isLoading ? (
        <Table<User>
          idKey="id"
          data={usersList}
          columns={columns}
          groupKey="status"
          rowClass={cn(styles.row, {[ styles.rowIsMobile ]: isMobile})}
          transformGroupTitle={renderTitle}
          onRowClick={handleRowClick}
          loading={{isLoading, rowsCount: 5}}
          activeRow={{
            accessor: "",
            activeId: 1,
          }}
          blinkRows={rowsToBlink}
        />
      ) : (
        <div className={styles.empty}>Похожих пользователей не найдено</div>
      )}
    </div>
  );
}