import { useDispatch } from "react-redux";
import { openModal } from "../../../service/redux/modules/modal/modal.action";
import { Discount } from "./discount/Discount";
import styles from "./index.module.scss"

type Props = {
  price: string | number
  priceWithDiscount?: string | number
  id: string
  realEstateRefetch?: () => void
}

type Return = {
  openDiscountModal: () => void
}

// eslint-disable-next-line @typescript-eslint/naming-convention
type UseDiscountModal = (props: Props) => Return

export const useDiscountModal: UseDiscountModal = (
  {
    price,
    id,
    realEstateRefetch,
    priceWithDiscount
  }) => {

  const dispatch = useDispatch()

  const openDiscountModal = () => {
    dispatch(
      openModal({
        width: 660,
        body: <Discount
          id={id}
          price={price}
          realEstateRefetch={realEstateRefetch}
          priceWithDiscount={priceWithDiscount}
        />,
        classNames: {
          contentBody: styles.content
        }
      })
    )
  }
  return {
    openDiscountModal
  }
}