import { UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";

import { CalendarInPopup, InfoRow, Time } from "../../../index";
import { useDatetime, useInitialDatetime } from "./helpers";

import styles from "./index.module.scss";

type Props = {
	name: string;
	setValue: UseFormSetValue<any>;
	getValues: UseFormGetValues<{ additionalProperties: any }>;
	handleOnChange: (value: string | number) => void;
	register: UseFormRegister<any>;
};

export const DateInput = ({ name, setValue, getValues, handleOnChange, register }: Props): JSX.Element => {
	const handleOnChangeDebounced = useDebouncedCallback(handleOnChange, 500);

	const { initialDate, initialTime } = useInitialDatetime({
		fieldName: name,
		getFormValues: getValues,
	});

	const { onDateChange, onTimeChange } = useDatetime({
		datetimeFieldName: name,
		onChangeGlobal: handleOnChangeDebounced,
		setValue,
	});

	const dateFieldName = `${name}-date__internal__`;
	const timeFieldName = `${name}-time__internal__`;

	return (
		<InfoRow
			label="Выберите дату"
			classNames={{
				content: styles.root,
			}}
		>
			<CalendarInPopup
				onDateChange={onDateChange}
				defaultDate={initialDate.current}
				form={{
					name: dateFieldName,
					getValues,
					setValue,
					register,
				}}
			/>

			<Time
				name={timeFieldName}
				onChange={onTimeChange}
				register={register}
				setValue={setValue}
				value={initialTime.current}
				hideLabel
				classNames={{
					root: styles.timeRoot,
					wrapper: styles.timeWrapper,
				}}
			/>

			<input {...register(name)} type="hidden" />
		</InfoRow>
	);
};
