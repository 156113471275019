import { Task } from "../../../../types";
import { useOpenTaskModal } from "../../../../utils/hooks";
import styles from "./index.module.scss";
import { CardTask } from "../card-task/CardTask";
import { selectDevice, useSelect } from "../../../../service/redux/selectors";

type Props = {
  tasks: Task[];
  refetch?: () => void;
  mini?: boolean
  inTable?: boolean
};

export const CardTaskList = ({tasks, refetch, mini, inTable}: Props) => {
  const onUpdatedTask = () => {
    refetch?.();
  };

  const {isMobile} = useSelect(selectDevice)

  const {handleOpenTaskModal} = useOpenTaskModal({onUpdatedTask, refetchParent: refetch, mini: isMobile});

  return (
    <div className={styles.cardTaskList}>
      {tasks.map((item) => {
        const {
          id,
        } = item;
        const onCardClick = () => {
          handleOpenTaskModal(id);
        };
        return (
          <div key={item.id} onClick={onCardClick} className={styles.taskWrapper} style={{cursor: "pointer"}}>
            <CardTask
              task={item}
              mini={mini}
              inTable={inTable}
            />
          </div>
        );
      })}
    </div>
  );
};
