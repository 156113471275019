import { memo } from "react";
import { formatDate } from "@sdelka_crm/sdelka-crm-component-library";

import { CommonListItem } from "../../../../types";

import HandBankWalk from "../../../../assets/images/hand-bank-walk.png";
import styles from "./index.module.scss";

export type Props = {
	title: string;
	subTitle?: string;
	currentStage: {
		tooltip: string;
		text: string;
	};
	createdDate: Date;
	sideData: Array<CommonListItem<number>>;
};

export const CardRequestInfo = memo(
	({ title, subTitle, currentStage, createdDate, sideData }: Props): JSX.Element => (
		<div className={styles.root}>
			<div className={styles.img}>
				<img src={HandBankWalk} alt="_hank-bank-walk" />
			</div>

			<div className={styles.body}>
				<div className={styles.title}>{title}</div>

				{subTitle && <div className={styles.subTitle}>{subTitle}</div>}

				<div className={styles.stage}>
					<div className={styles.onStage}>На этапе:</div>

					<div className={styles.tooltip}>
						<div>{currentStage.text}</div>
					</div>
				</div>

				<div className={styles.createdDate}>{`Создана ${formatDate(createdDate)}`}</div>
			</div>

			<div className={styles.sideData}>
				{sideData.map((item) => (
					<div key={item.id as string} className={styles.sideDataRoot}>
						<div className={styles.sideDataLabel}>{item.label}</div>

						<div className={styles.sideDataValue}>{item.value}</div>
					</div>
				))}
			</div>
		</div>
	)
);
