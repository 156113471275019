export const SET_DISABLED = "sdelka/shows-and-views/SET_DISABLED";
export const SET_SHOW_VALUES = "sdelka/shows-and-views/SET_SHOW_VALUES";
export const SET_DEFAULT_VALUES = "sdelka/shows-and-views/SET_DEFAULT_VALUES";
export const SET_DEFAULT_VALUES_FOR_ONE = "sdelka/shows-and-views/SET_DEFAULT_VALUES_FOR_ONE";
export const CLEAN_UP = "sdelka/reset-password-steps/CLEAN_UP";

export type ShowAndViewsActions =
	| { type: typeof SET_DISABLED; id: string; disabledValues: Array<boolean> }
	| { type: typeof SET_SHOW_VALUES; id: string; showValues: Array<boolean> }
	| { type: typeof SET_DEFAULT_VALUES; ids: string[] }
	| { type: typeof SET_DEFAULT_VALUES_FOR_ONE; id: string }
	| { type: typeof CLEAN_UP };

export const setDefaultValues = (ids: string[]) => ({
	type: SET_DEFAULT_VALUES,
	ids,
});

export const setDefaultValuesForOne = (id: string) => ({
	type: SET_DEFAULT_VALUES_FOR_ONE,
	id,
});

export const setDisabled = (id: string, disabledValues: Array<boolean>): ShowAndViewsActions => ({
	type: SET_DISABLED,
	id,
	disabledValues,
});

export const setShowValues = (id: string, showValues: Array<boolean>): ShowAndViewsActions => ({
	type: SET_SHOW_VALUES,
	id,
	showValues,
});

export const cleanUp = (): ShowAndViewsActions => ({
	type: CLEAN_UP,
});
