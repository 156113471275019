import cn from "classnames";

import { useSelector } from "../../../../../../../../../service/redux/useSelector";
import { RealEstateRequiredAdFields } from "../../../../../../../../../types";

import styles from "./index.module.scss";

type Props = {
	required?: RealEstateRequiredAdFields;
};

export const RequiredDots = ({ required }: Props): JSX.Element => {
	const { showReqiredAdFields } = useSelector((state) => state.objectEdit);

	const adPlatforms: string[] = ["avito", "yandex", "cian"];

	return (
		<div className={styles.root}>
			{adPlatforms.map((ad) => (
				<div key={ad}>
					{required && required[ad] && showReqiredAdFields[ad] && (
						<div className={cn(styles.dot, styles[`dot__${ad}`])} />
					)}
				</div>
			))}
		</div>
	);
};
