import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import cn from "classnames";
import { Button, formatDate } from "@sdelka_crm/sdelka-crm-component-library";

import {
  CianPaidServices,
  PaidServices,
  RealEstateAdPartnerHeader,
  RealEstateAdPartnersInfo,
  RealEstateAdStatusHeader,
  StartAd,
} from "./components";
import { useGetPlatform } from "../../../../../../../service/api/queries";
import { useUpdateAutoRenewal } from "../../../../../../../service/api/mutations";
import { getCurrentStatusLayout } from "../../../../../../../utils/ads";
import { useStartAdActions } from "../hooks";
import { prepareErrors, useAdDescriptionModal, useCian } from "./helpers";
import {
  CianTariff,
  CianTariffNames,
  Limits,
  PaymentMethods,
  PlatformData,
  PlatformPaidServicesData,
} from "../../../../../../../types";
import styles from "./index.module.scss";
import { PostedErrors } from "./components/StartAd/components/PostedErrors";
import {
  useComplaintAutoReject
} from "../../../../../../../service/api/mutations/real-estate-ad/useComplaintAutoReject";
import { CianSelectTariff } from "./components/CianSelectTariff";
import { Popup, PopupMenuItems } from "../../../../../../UI";
import { ReactComponent as IconDots } from "../../../../../../../assets/icons/iconWithDotsInCircleWhite.svg";
import { ReactComponent as PaperIcon } from "../../../../../../../assets/icons/paper.svg";
import { ReactComponent as EditObjectIcon } from "../../../../../../../assets/icons/edit-object.svg";
import { ReactComponent as CloseIcon } from "../../../../../../../assets/icons/close.svg";

type Props = {
  className?: string;
  platform: PlatformData;
  refetchPlatforms?: () => void;
  applicationId: string;
  isMobile?: boolean
};

const renderActiveFooter: (
  isCian: boolean,
  paidServicesProps: PlatformPaidServicesData,
  onCianChange: (tariff: CianTariff) => void,
) => JSX.Element = (isCian, paidServicesProps, onCianChange) =>
  !isCian ? (
    <PaidServices data={paidServicesProps}/>
  ) : (
    <CianPaidServices onTariffChange={onCianChange} data={paidServicesProps}/>
  );

type SortableMenuItem = PopupMenuItems & {
  order: number;
};

const PaperIconWithWrapper = () => (
  <div className={styles.iconWrapper}>
    <PaperIcon/>
  </div>
);

const EditObjectIconWithWrapper = () => (
  <div className={styles.iconWrapper}>
    <EditObjectIcon/>
  </div>
);

const CloseIconWithWrapper = () => (
  <div className={styles.iconWrapper}>
    <CloseIcon/>
  </div>
);

export const RealEstateAdPlatform = (
  {
    platform,
    className = styles.root,
    refetchPlatforms,
    applicationId,
    isMobile
  }: Props): JSX.Element => {
  const [innerPlatform, setInnerPlatform] = useState(platform);

  const {adStatus, supportData, limits, requiredFields, name} = innerPlatform;

  const statistic = adStatus?.statistic;

  const feedId = innerPlatform.id;

  const {
    data,
    isLoading: isPlatformLoading,
    refetch,
    remove,
  } = useGetPlatform({
    applicationId,
    feedId,
  });

  const watingStatusInitialValues = useMemo(() => {
    let startAdPayment: null | number = null;
    let startAdTariff: null | number = null;
    let startAdDuration = 1;

    if (adStatus?.payMethod)
      startAdPayment = Object.values(PaymentMethods).indexOf(adStatus.payMethod as any) + 1;

    if (adStatus?.period) startAdDuration = adStatus?.period;

    if (adStatus?.tariff) startAdTariff = CianTariffNames[ adStatus?.tariff ];

    return {
      startAdDuration,
      startAdTariff,
      startAdPayment,
    };
  }, [adStatus?.payMethod, adStatus?.period]);

  const {control, getValues, watch, setValue} = useForm({
    defaultValues: watingStatusInitialValues,
  });

  const {
    currentlyUpdating,
    setCurrentlyUpdating,
    loading,
    setIsLoading,
    fillFields,
    startAd,
    stopAd,
    restartAd,
    changeTariff,
    tariffJustChangedArray,
  } = useStartAdActions({applicationId, feedId, refetch});

  const {mutate: mutateAutoRenewal} = useUpdateAutoRenewal({
    onSuccess: () => {
      if (refetchPlatforms) {
        refetchPlatforms();
      }
    },
  });

  const {cianError, onCianChange, isCian} = useCian({
    platform: innerPlatform,
  });

  const isDomclick = platform.format === "domclick-cian";
  const isYandex = platform.format === "yandex";
  const isYandexOrDomclick = isDomclick || isYandex;

  useEffect(() => {
    if (!isPlatformLoading && data && data?.id === currentlyUpdating) {
      setInnerPlatform(data);
      setCurrentlyUpdating("");
      setIsLoading(false);
      remove();
    }
  }, [isPlatformLoading]);

  useEffect(() => {
    setInnerPlatform(platform);
  }, [platform.adStatus?.autoRenewal]);

  useEffect(
    () => () => {
      remove();
    },
    []
  );

  const [curStatus, Status] = getCurrentStatusLayout({adStatus});
  const {
    isWaiting,
    wasInAd,
    editError,
    notPosted,
    isError,
    isPosted,
    isPostedAndHasErrors,
    isArchived,
    isPostedAndWaitingForStop,
    isArchivedWithError,
  } = curStatus;

  const placementsLeft =
    !isWaiting && limits ? `${(limits as Limits).remainder}/${(limits as Limits).limit}` : "";
  const disableStatistics = (isWaiting && !wasInAd) || isError;

  const adPaidServices: PlatformPaidServicesData = {
    applicationId,
    feedId: platform.id,
    feedAdId: adStatus.id,
  };

  const handleStartAd = () => {
    const {startAdPayment} = getValues();
    if (startAdPayment !== null) {
      const currentValue = watch("startAdTariff");
      const selectedTariffOption = Object.values(CianTariffNames)[ (currentValue as any) - 1 ] as CianTariff;

      return startAd(feedId, {months: getValues().startAdDuration}, startAdPayment, selectedTariffOption);
    }
    const currentValue = watch("startAdTariff");
    const selectedTariffOption = Object.values(CianTariffNames)[ (currentValue as any) - 1 ] as CianTariff;
    return startAd(feedId, {months: getValues().startAdDuration}, 1, selectedTariffOption);
  };

  const handleStopAd = () => stopAd(feedId);

  const {mutate: mutateComplaintAutoReject} = useComplaintAutoReject({
    onSuccess: () => {
      if (refetchPlatforms) {
        refetchPlatforms();
      }
    },
  });

  const handleComplaintAutoRejectClick = () =>
    mutateComplaintAutoReject({applicationId, feedId, isEnabled: !adStatus.complaintAutoReject});

  const restartAdHandler = () => {
    restartAd(feedId);
  };

  const onChangeTariff = (value: number | string) => {
    if (typeof value === "number") {
      if (isCian && (isWaiting || isError || isPosted)) {
        // FIXME: Не уверен, что это хорошо для производительности
        const currentValue = watch("startAdTariff");
        if (currentValue !== value) {
          const selectedTariffOption = Object.values(CianTariffNames)[ (value as any) - 1 ];
          changeTariff({applicationId, feedId, newTariffValue: selectedTariffOption as CianTariffNames});
        }
      }
    }
  };

  // FIXME: Bad performance
  watch("startAdTariff");

  const {onModalOpen: onEditClick} = useAdDescriptionModal({
    feedId,
    error: cianError,
    applicationId,
  });


  const [localComplaintAutoReject, setLocalComplaintAutoReject] = useState(adStatus.complaintAutoReject);

  const menu: SortableMenuItem[] = [
    {
      label: isCian && (
        <Button
          variant="text"
          Icon={PaperIconWithWrapper}
          onClick={() => {
            handleComplaintAutoRejectClick();
            setLocalComplaintAutoReject((prev) => !prev);
          }}
          classNames={{
            root: cn(styles.cianButton, localComplaintAutoReject ? styles.enabled : styles.disabled),
          }}
        >
          Автоотклонение жалоб на неактуальность {localComplaintAutoReject ? "вкл" : "выкл"}.
        </Button>
      ) as any,

      action: () => null,
      order: 0,
    },
    {
      label: (
        <Button
          variant="text"
          Icon={EditObjectIconWithWrapper}
          onClick={onEditClick}
          classNames={{root: styles.editButton}}
        >
          Редактировать
        </Button>
      ) as any,

      action: () => null,
      order: 1,
    },
    {
      label: (isError || isArchived) && (
        <Button variant="text" onClick={restartAdHandler}>
          Разместить как новое
        </Button>
      ) as any,

      action: () => null,
      order: 2,
    },
    {

      label: (isPosted || isArchived || isWaiting || isError) && !isPostedAndWaitingForStop && !isArchived && (
        <Button color="red" variant="text" onClick={handleStopAd} Icon={CloseIconWithWrapper}>
          Остановить
        </Button>
      ) as any,

      action: () => null,
      order: 3,
    },
    {
      label: (isArchived || isPostedAndWaitingForStop) && isMobile && (
        <Button variant="text" onClick={restartAdHandler}>
          Разместить как новое
        </Button>
      ) as any,

      action: () => null,
      order: 4,
    },
  ];

  return (
    <RealEstateAdPartnersInfo
      isLoading={loading}
      headerClassName={styles.partnerAdHeader}
      className={cn(className, {[ styles.archived ]: isArchived})}
      disableStatistics={disableStatistics || notPosted}
      applicationId={applicationId}
      feedId={feedId}
      headerLabel={name}
      isYandexOrDomclick={isYandexOrDomclick}
      editError={
        editError
          ? {
            data: {
              platformName: platform.name,
              feedId,
              applicationId,
              onRetry: handleStartAd,
            },
            applicationId,
            errors: prepareErrors(adStatus),
          }
          : undefined
      }
      header={
        <>
          <RealEstateAdPartnerHeader
            applicationId={applicationId}
            feedId={feedId}
            img={platform.icon}
            link={adStatus.link}
            alt={platform.name}
            adIsStarted={isPosted || isArchived}
            adIsWaiting={isWaiting}
            onAdStopClick={handleStopAd}
            onAdRestartClick={restartAdHandler}
            onComplaintAutoRejectClick={handleComplaintAutoRejectClick}
            cianError={cianError}
            isCian={isCian}
            isMobile={isMobile}
            requiredFields={requiredFields}
            complaintAutoReject={adStatus.complaintAutoReject}
            isPostedAndWaitingForStop={isPostedAndWaitingForStop}
            adIsInArchive={isArchived}
            adIsError={isError}
            statusObject={
              <RealEstateAdStatusHeader
                statusClassName={String(isWaiting) && styles.adWaiting}
                status={Status}
                date={`Рекламируется с ${formatDate(adStatus.startedAt ?? "")}`}
                tariff={(limits as Limits)?.tariff}
                placementsLeft={placementsLeft}
                isError={isError}
                isPosted={isPosted || isArchived}
              />
            }
          />
          {isCian && isPosted && (
            <CianSelectTariff
              format={platform.format}
              onChangeTariff={onChangeTariff}
              control={control}
              watch={watch}
              isPostedAndWaitingForStop={isPostedAndWaitingForStop}
              isTariffJustChanged={tariffJustChangedArray.includes(feedId)}
            />
          )}
          {(isPostedAndHasErrors || isArchivedWithError) && (
            <PostedErrors
              messages={{
                errors: isArchivedWithError ? adStatus.errors : adStatus.updateStatus?.errors,
                warinigs: isArchivedWithError ? adStatus.warinigs : adStatus.updateStatus?.warinigs,
              }}
            />
          )}
        </>
      }
      footerClassName={cn(styles.partnersAdEditFooter)}
      footer={
        <>
          {(notPosted || isWaiting || isError) && (
            <>
              {isMobile &&
                <div className={cn(styles.dotsNot, {[ styles.dotsNotWithoutButton ]: (isWaiting || isError)})}>
                  <Popup
                    openTrigger={isMobile ? <IconDots/> : <div className={styles.openTrigger}>Другие действия</div>}
                    options={{withArrow: true, primaryOpen: "left"}}
                    classNames={
                      {
                        trigger: styles.clicked,
                        arrow: styles.arrow,
                        card: styles.popupCardNot
                      }
                    }
                  >
                    {menu.map((item) => (
                      <div key={item.order} className={cn(styles.actionItem, item.className)}>
                        {item.icon}
                        <span>{item.label}</span>
                      </div>
                    ))}
                  </Popup>
                </div>
              }
              <StartAd
                disabled={
                  !requiredFields.valid ||
                  getValues().startAdPayment === null ||
                  (isCian && getValues().startAdTariff === null)
                }
                isMobile={isMobile}
                format={platform.format}
                onStartAdClick={handleStartAd}
                control={control}
                requiredFields={requiredFields}
                watch={watch}
                setValue={setValue}
                isWaiting={isWaiting}
                isError={isError}
                messages={{errors: adStatus.errors, warinigs: adStatus.warinigs}}
                onChangeTariff={onChangeTariff}
                isPostedAndWaitingForStop={isPostedAndWaitingForStop}
              />
            </>
          )}
          {!isCian && (isWaiting || isError || isPosted || isArchived) && !isDomclick && (
            <div style={isWaiting || isError ? {marginTop: "40px"} : {}}>
              {renderActiveFooter(isCian, adPaidServices, onCianChange)}
            </div>
          )}
          {(isArchived || isPostedAndWaitingForStop) &&
            <div className={styles.archiveContainer}>
              {isMobile && <div>
                <Popup
                  openTrigger={isMobile ? <IconDots/> : <div className={styles.openTrigger}>Другие действия</div>}
                  options={{withArrow: true, primaryOpen: "left"}}
                  classNames={
                    {
                      trigger: styles.clicked,
                      arrow: styles.arrow,
                      card: styles.popupCard
                    }
                  }
                >
                  {menu.map((item) => (
                    <div key={item.order} className={cn(styles.actionItem, item.className)}>
                      {item.icon}
                      <span>{item.label}</span>
                    </div>
                  ))}
                </Popup>
              </div>}
              <Button onClick={handleStartAd}>{`Вернуть ${isMobile ? "" : "в рекламу"}`}</Button>
              {!isMobile &&
                <Button variant="outlined" onClick={restartAdHandler}>
                  Разместить как новое
                </Button>
              }
            </div>
          }
        </>
      }
      supportData={{...supportData, id: adStatus.externalId}}
    />
  );
};
