import { toastSuccess } from "../../../../../../../../UI";
import { useUpdateApplication as useMutateApplication } from "../../../../../../../../../service/api/mutations";
import { KindType } from "types";

type Props = {
	refetch?: () => void;
	applicationId: string;
	kind?: KindType
};

type Return = {
	unpinObject: () => void;
	pinObject: (objectId) => void;
};

type UseUpdateApplication = (props: Props) => Return;

export const useUpdateApplication: UseUpdateApplication = ({ refetch, applicationId, kind }) => {
	const onSuccess = () => {
		if (refetch) {
			refetch();
		}

		toastSuccess({ text: "Заявка обновлена" });
	};

	const { mutate } = useMutateApplication({ onSuccess, kind });

	const unpinObject = () => {
		mutate({
			applicationId,
			applicationData: {
				clientObjectId: null,
			},
		});
	};

	const pinObject = (objectId: string) => {
		mutate({
			applicationId,
			applicationData: {
				clientObjectId: objectId,
			},
		});
	};

	return {
		unpinObject,
		pinObject,
	};
};
