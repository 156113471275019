import { useState } from "react";
import { NoData, Pagination, Tabs } from "@sdelka_crm/sdelka-crm-component-library";
import { SidebarLayout } from "../../UI";
import {
  AlertApplications,
  ApplicationTable,
  DealsTable,
  FreeLeads,
  LeadsTable,
  MobileAppFilterButtons,
  StatusFilter,
} from "./components";
import { useLeadsAndDealsFilter, useLeadsAndDealsTabs, useLoadBusinessProcess } from "./helpers";
import { KindType, Stage } from "../../../types";
import styles from "./index.module.scss";
import { AlertDeals } from "./components/AlertDeals";
import { Contacts } from "../Contacts";
import { selectDevice, useSelect } from "../../../service/redux/selectors";
import { useContacts } from "../../../service/api/queries";

const tabs = ["Контакты", "Лиды", "Заявки", "Сделки"].map((tab) => (
  <div className={styles.tabLabel}>{tab}</div>
));

export const LeadList = (): React.ReactElement => {
  const {
    curTab,
    onChangeCurTab,
    filter,
    setFilter,
    refetch,
    leadsData,
    applicationsData,
    trafficData,
    isFetching,
    dealsData,
    lessonsList,
  } = useLeadsAndDealsTabs();

  const {isMobile} = useSelect(selectDevice)
  // const isMobile = true

  useLoadBusinessProcess();

  const withStage = true;

  const [requestStages, setRequestStages] = useState<Stage[]>([]);

  const {currentFilter} = useLeadsAndDealsFilter({
    curTab,
    filter,
    setFilter,
    requestStages,
    setRequestStages,
  });

  const join = ["company", "user", "phones", "comments"];

  const {
    contactsList,
    total,
    page,
    take,
    isFetching: isFetchingContacts,
    setPage,
    refetch: refetchContacts,
    filter: filterContacts,
    setFilter: setFilterContacts,
  } = useContacts({
    join,
    defaultSort: {lastCommunicationDate: "ASC", dateModified: "ASC"},
    options: {keepPreviousData: true},
  });

  const [filtersCount] = useState(Number(Object.values(filter).filter(el =>
    el !== undefined || el !== "" || Object.keys(el).length !== 0 || (el as Array<string>).length !== 0).length))
  return (
    <SidebarLayout
      fullSize
      isMobile={isMobile}
      hat={!isMobile ? {
        element: currentFilter,
        elementHeight: 50,
      } : {element: <></>, elementHeight: 0}}
      withScrollBtn
    >
      <div className={styles.root}>
        <div className={styles.list}>
          {isMobile && curTab !== 0 && curTab !== 1 &&
            <MobileAppFilterButtons
              filtersCount={filtersCount}
              formSubmit={setFilter}
              variant={"applications"}
            />
          }
          {isMobile && curTab === 1 &&
            <MobileAppFilterButtons
            filtersCount={filtersCount}
            formSubmit={setFilter}
            variant={"leads"}
          />}
          {isMobile && curTab === 0 &&
            <MobileAppFilterButtons
              filtersCount={filtersCount}
              formSubmit={setFilterContacts}
              variant={"contacts"}
            />}
          <div className={styles.tabs}>
            <Tabs
              size="large"
              active={curTab}
              onChange={onChangeCurTab}
              tabs={tabs}
              offset={36}
              classNames={{root: styles.bottomLine, wrapper: styles.tabsRoot}}
            />
          </div>
          {curTab === 0 && (
            <div className={styles.tabContent}>
              <div className={styles.tableWithPagination}>
                {!isFetching && trafficData.isError ? (
                  <NoData onRefetch={trafficData.refetch}/>
                ) : (
                  <Contacts
                    isMobile={isMobile}
                    contactsList={contactsList}
                    refetch={refetchContacts}
                    total={total}
                    page={page}
                   filter={filterContacts}
                    isFetching={isFetchingContacts}
                   setFilter={setFilterContacts}
                    setPage={setPage}
                    take={take}
                  />
                )}
              </div>
            </div>
          )}
          {curTab === 1 && (
            <div className={styles.tabContent}>
              {!isMobile &&
                <StatusFilter filterType="leads" filter={filter} setFilter={setFilter} requestStages={[]}/>}
              <div className={styles.tableWithPagination}>
                {!isFetching && leadsData.isError ? (
                  <NoData onRefetch={leadsData.refetch}/>
                ) : (
                  <>
                    <LeadsTable
                      leadsList={leadsData.list}
                      isLoading={isFetching}
                      refetch={refetch}
                      isMobile={isMobile}
                      lesson={lessonsList[ 0 ]}
                    />
                    {!isFetching && leadsData?.total > 0 && (
                      <Pagination
                        classNames={{root: styles.pagination}}
                        totalCount={leadsData.total}
                        pageSize={leadsData.take}
                        currentPage={leadsData.page}
                        onPageChange={(page: number) => leadsData.setPage(page)}
                        isLoading={isFetching}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          {curTab === 2 && (
            <div className={styles.tabContent}>
              <div className={styles.tableWithPagination}>
                {!isFetching && applicationsData.isError ? (
                  <NoData onRefetch={applicationsData.refetch}/>
                ) : (
                  <>
                    <ApplicationTable
                      requestsList={applicationsData.list}
                      filter={filter}
                      kind={KindType.APPLICATION}
                      isLoading={isFetching}
                      withStage={withStage}
                      total={applicationsData.total}
                      totalCounts={applicationsData.totalCounts}
                      refetchApplications={applicationsData.refetch}
                      isMobile={isMobile}
                      lesson={lessonsList[ 0 ]}
                    />
                    {!isFetching && applicationsData?.total > 0 && (
                      <Pagination
                        classNames={{root: styles.pagination}}
                        totalCount={applicationsData.total}
                        pageSize={applicationsData.take}
                        currentPage={applicationsData.page}
                        onPageChange={(page: number) => applicationsData.setPage(page)}
                        isLoading={isFetching}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          {curTab === 3 &&
            <div className={styles.tabContent}>
              {!isMobile && <StatusFilter
                filterType="deals"
                filter={filter}
                requestStages={requestStages}
                setFilter={setFilter}
              />}
              <div className={styles.tableWithPagination}>
                {!isFetching && dealsData.isError ? (
                  <NoData onRefetch={dealsData.refetch}/>
                ) : (
                  <>
                    <DealsTable
                      requestsList={dealsData.list}
                      kind={KindType.DEAL}
                      filter={filter}
                      isLoading={isFetching}
                      withStage={withStage}
                      total={dealsData.total}
                      isMobile={isMobile}
                      totalCounts={dealsData.totalCounts}
                      refetchApplications={dealsData.refetch}
                      lesson={lessonsList[ 0 ]}
                    />
                    {!isFetching && dealsData?.total > 0 && (
                      <Pagination
                        classNames={{root: styles.pagination}}
                        totalCount={dealsData.total}
                        pageSize={dealsData.take}
                        currentPage={dealsData.page}
                        onPageChange={(page: number) => dealsData.setPage(page)}
                        isLoading={isFetching}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          }
        </div>
        {!isMobile && curTab !== 0 && curTab !== 2 && curTab !== 3 &&
          <div className={styles.sideContainer}>
            {curTab === 1 && <FreeLeads refetch={refetch}/>}
            {curTab === 2 && <AlertApplications/>}
            {curTab === 3 && <AlertDeals/>}
          </div>
        }
      </div>
    </SidebarLayout>
  );
};
