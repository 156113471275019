import { Dispatch, SetStateAction, useEffect } from "react";
import { useForm } from "react-hook-form";
import { RealEstateSort } from "../../../../../types";
import { Select } from "../../../../UI";
import { useQueryParams } from "../../../../../utils/hooks";
import styles from "./index.module.scss";

type Props = {
  total: number;
  take: number;
  setTake: Dispatch<SetStateAction<number>>;
  setSort: Dispatch<SetStateAction<RealEstateSort | undefined>>;
  isLoading: boolean;
  isMobile?: boolean
};

export const ObjectListHeader = (
  {
    take,
    setTake,
    setSort,
    isLoading,
    total,
    isMobile
  }: Props): JSX.Element => {
  const {setValue, register} = useForm();
  const {queryParams, changeQueryParams} = useQueryParams<{
    compilation: boolean;
    pageSize: string;
    sort: string;
  }>([{name: "compilation", type: "boolean"}, {name: "pageSize"}, {name: "sort"}]);

  const sizeOptions = [
    {label: "5 объектов", value: "5"},
    {label: "25 объектов", value: "25"},
    {label: "50 объектов", value: "50"},
    {label: "75 объектов", value: "75"},
    {label: "100 объектов", value: "100"},
  ];

  const priceOptions = [
    {label: "Сначала новые", value: "createdAt DESC"},
    {label: "Сначала дешевые", value: "price ASC"},
    {label: "Сначала дорогие", value: "price DESC"},
    {label: "Сначала старые", value: "createdAt ASC"},
  ];

  const handleChangeTake = (value: string) => {
    setTake(Number(value));
    changeQueryParams([{name: "pageSize", newValue: encodeURIComponent(value)}]);
  };

  const handleChangeSort = (value: string) => {
    const splited = value.split(" ") as ["price" | "createdAt", "ASC" | "DESC"];
    setSort({field: splited[ 0 ], sort: splited[ 1 ]});
    changeQueryParams([{name: "sort", newValue: value}]);
  };

  useEffect(() => {
    if (queryParams.pageSize) {
      setTake(Number(queryParams.pageSize));
    }
  }, [queryParams.pageSize]);

  useEffect(() => {
    if (queryParams.sort) {
      const decodedSort = decodeURIComponent(queryParams.sort).split(" ") as [
          "price" | "createdAt",
          "ASC" | "DESC"
      ];
      setSort({field: decodedSort[ 0 ], sort: decodedSort[ 1 ]});
    }
  }, [queryParams.sort]);

  return (
    <div className={styles.root}>
      <form className={styles.objectManipulations}>
        <div className={styles.total}>
          {`${total} объектов`}
        </div>
        {!isMobile && <div className={styles.objectDisplay}>
          <Select
            name="size"
            defaultValue={take.toString()}
            onChange={handleChangeTake}
            options={sizeOptions}
            register={register}
            setValue={setValue}
            size="small"
            classNames={{selectListWrapper: styles.select}}
            disabled={isLoading}
          />
          <Select
            name="price"
            defaultValue="createdAt DESC"
            onChange={handleChangeSort}
            options={priceOptions}
            register={register}
            setValue={setValue}
            size="small"
            disabled={isLoading}
            classNames={{selectListWrapper: styles.select}}
          />
        </div>}
      </form>
    </div>
  );
};
