import { useMutation } from "react-query";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/user/user-set-current-office/DEFAULT_QN";

type Props = {
  officeId: string
  onSuccess?: () => void;
  onError?: () => void;
};

export const useSetCurrentOffice = ({ onSuccess, onError, officeId }: Props) => {
  const mutate = useMutation(DEFAULT_MN, {
    mutationFn: () =>
      api.users.setCurrentOffice(officeId),
    onSuccess,
    onError
  });

  return {
    mutate: mutate.mutate,
    isLoading: mutate.isLoading,
  };
};