import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Tabs } from "@sdelka_crm/sdelka-crm-component-library";

import { LoadingRows } from "../../components";
import { CompanyRealtorForm } from "./Forms/CompanyRealtorForm";
import { NewCompanyForm } from "./Forms/NewCompanyForm";
import { SingleRealtorForm } from "./Forms/SingleRealtorForm";
import { useLoadUserInvites } from "../../../../../service/api/queries";
import { setStep } from "../../../../../service/redux/modules/registration-steps/reg-steps.action";
import { useSelector } from "../../../../../service/redux/useSelector";

import styles from "./index.module.scss";

export const ChooseRole = (): JSX.Element => {
	const [active, setActive] = useState(0);
	const { sawInvites } = useSelector((state) => state.regSteps);
	const dispatch = useDispatch();

	const { invites, isLoading } = useLoadUserInvites();

	useEffect(() => {
		if (invites.length && !sawInvites) {
			dispatch(setStep("has-active-invites"));
		}
	}, [invites, invites.length]);

	if (isLoading) {
		return <LoadingRows />;
	}

	return (
		<div className={styles.root}>
			<h1>Здравствуйте</h1>

			<div className={styles.tabs}>
				<Tabs
					size="medium"
					noWrap
					tabs={[
						<div style={{ width: 200 }}>Я создаю новую компанию</div>,
						<div style={{ width: 160 }}>Я риэлтор в&nbsp;компании</div>,
						// <div style={{ width: 82 }}>Я риэлтор одиночка</div>,
					]}
					color="yellow"
					active={active}
					onChange={setActive}
				/>
			</div>

			{active === 0 && <NewCompanyForm />}

			{active === 1 && <CompanyRealtorForm />}

			{active === 2 && <SingleRealtorForm />}
		</div>
	);
};
