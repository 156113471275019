import { useDispatch } from "react-redux";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Location } from "history";
import isEqual from "lodash.isequal";
import { RealEstateFilter } from "../../../../../types";
import { toastSuccess } from "../../../../UI";
import { useUpdateCompilationFilter } from "../../../../../service/api/mutations";
import { COMPILATION_PAGE_LOAD_COMP_QN, useLoadCompilation } from "../../../../../service/api/queries";
import { closeModal, openModal } from "../../../../../service/redux/modules/modal/modal.action";

type Props = {
  applicationId: string;
  isCompilationPage: boolean;
  filter: RealEstateFilter;
  setFilter: Dispatch<SetStateAction<RealEstateFilter>>;
};

export const useSyncCompilationFilters = ({applicationId, isCompilationPage, filter, setFilter}: Props) => {

  const [isComplicationSaved, setIsCompilationSaved] = useState(true);
  const dispatch = useDispatch();
  const {push} = useHistory();
  const {compilation, remove} = useLoadCompilation({
    applicationId,
    queryName: COMPILATION_PAGE_LOAD_COMP_QN,
    enabled: isCompilationPage,
  });
  const {mutate} = useUpdateCompilationFilter({
    onSuccess: () => {
      toastSuccess({text: "Фильтр успешно сохранен"});
    },
  });

  const {filterData: compilationFilterData} = compilation || {};

  useEffect(() => {
    const isFiltersEqual = isEqual(filter, compilationFilterData);

    if (isFiltersEqual !== isComplicationSaved) {
      setIsCompilationSaved(isFiltersEqual);
    }
  }, [compilationFilterData, filter]);

  useEffect(() => {
    if (compilationFilterData) {
      setFilter({
        ...filter,
        ...compilationFilterData,
      });
    }
  }, [compilationFilterData]);

  const handleOnSaveCompilationFilter = () => {
    if (!isComplicationSaved) {
      setIsCompilationSaved(true);
    }

    const payload = {
      applicationId,
      filterData: {
        ...filter,
        priceFrom: filter.priceFrom?.replaceAll(" ", ""),
        priceTo: filter.priceTo?.replaceAll(" ", ""),
      },
    };

    mutate(payload);
  };

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      remove();
    };
  }, []);

  const openSaveModal = (nextLocation: Location<unknown>) => {
    const {pathname, search} = nextLocation;

    const fullNewUrl = pathname + search;
    dispatch(
      openModal({
        title: "Изменения в фильтрах не сохранены",
        body: "Сохранить внесенные вами изменения в фильтре?",
        variant: "alert",
        alertIcon: "warning",
        alertButtonSubmit: {
          label: "Сохранить",
          onClick: async () => {
            dispatch(closeModal());
            await handleOnSaveCompilationFilter();
            push(fullNewUrl);
          },
        },
        alertButtonDecline: {
          label: "Не сохранять",
          onClick: async () => {
            await setIsCompilationSaved(true);
            dispatch(closeModal());
            push(fullNewUrl);
          },
        },
      })
    );
  };

  const handleAlertFilterWasntSaved = (nextLocation: Location<unknown>) => {
    const {search} = nextLocation;
    const nextPageNotCompilation = search.indexOf("compilation=true") !== 1;

    if (nextPageNotCompilation && !isComplicationSaved) {
      if (compilationFilterData === null) {
        openSaveModal(nextLocation);
        return false;

      }
      const isFiltersEqual = isEqual(filter, compilationFilterData);
      if (!isFiltersEqual) {
        openSaveModal(nextLocation);
        return false;
      }
    }

    return true;
  };

  return {
    handleAlertFilterWasntSaved,
    handleOnSaveCompilationFilter,
  };
};
