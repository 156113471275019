import { useDispatch } from "react-redux";
import { Lead } from "../../../../../../types";
import { toastError, toastSuccess } from "../../../../../UI";
import { closeDrawerInDrawer, openDrawerInDrawer } from "../../../../../../service/redux/modules/drawer/drawer.action";
import { useCreateSubLead } from "../../../../../../service/api/mutations";
import { AxiosResponse } from "axios";
import { closeModal } from "../../../../../../service/redux/modules/modal/modal.action";
import { NewLeadInfo } from "../NewLeadInfo";

type Props = {
  lead: Lead;
  refetch: () => void;
};

type Return = {
  openCreateLeadDrawerInDrawer: () => void;
};

type UseOpenCreateLeadCard = (props: Props) => Return;

export const useOpenCreateLeadCard: UseOpenCreateLeadCard = ({lead}) => {
  const dispatch = useDispatch();

  const {mutate} = useCreateSubLead({
    onSuccess: (data: AxiosResponse<Lead>) => {
      setTimeout(() => {

        dispatch(
          openDrawerInDrawer({
            children: <NewLeadInfo
              lead={data.data}
            />,
            width: 500,
          }));
      }, 0);
    },
  });


  const openCreateLeadDrawerInDrawer = () => {
    if (lead.contact?.id) {
      const SUCCESS_TEXT = "Создание лида успешно";
      toastSuccess({text: SUCCESS_TEXT});
      mutate({parentId: lead.id});

    } else {
      const ERROR_TEXT = "Без контакта прикрепленного к лиду нельзя начинать создание нового лида";
      toastError({text: ERROR_TEXT});
      dispatch(closeModal())
      dispatch(closeDrawerInDrawer())
    }
  };

  return {openCreateLeadDrawerInDrawer};
};

