import { AppliedProduct, appliedProductStatus, AppliedProductStatusEnum } from "../../../../types/product";
import styles from "./index.module.scss"
import { ReactComponent as Avatar } from "../../../../assets/icons/office-briefcase-svgrepo-com 1.svg";
import cn from "classnames";
import { ReactComponent as ToCart } from "../../../../assets/icons/Shopping_Cart.svg";
import { ReactComponent as Check } from "../../../../assets/icons/Check_Big.svg";
import { useAddToCart, useDeleteFromCart } from "../../../../service/api/mutations";
import { useAddInCartModal } from "../../../pages/Servises/cart/helpers/addInCartModal/useAddInCartModal";
import { formatDateWithTime } from "../../../../utils/date";
import { formatThousand } from "../../../../utils/formatters";

type Props = {
  product: AppliedProduct
  onCardClick?: (id: string) => void
  refetch: () => void
  type?: "available" | "purchased" | "applied"
  checked?: boolean
  fromModal?: boolean
  mini?: boolean
  refetchCart?: () => void
  withOutStrap?: boolean
}

export const CardProductForTable = (
  {
    product,
    onCardClick,
    refetch,
    type,
    checked,
    fromModal,
    mini,
    refetchCart,
    withOutStrap
  }: Props) => {
  const {
    title,
    id,
    photo,
    productType,
    tariffOptions,
    price,
    isInCart,
    tariffLabel,
    tariff,
    availableAmount,
    priceWithDiscount,
    applicationTitle,
    createdAt,
    status
  } = product

  const {mutate} = useAddToCart({
    onSuccess: () => {
      refetch()
    }
  })

  const discount = priceWithDiscount ? `-${Math.floor(((price - priceWithDiscount) / price) * 100)}%` : undefined

  const {openAddInCartModal} = useAddInCartModal({
    type: productType,
    title: "Выберите условия",
    action: mutate,
    tariffLabel,
    tariffOptions,
    isMobile: mini,
    refetchCart: () => {
      refetch()
      if (refetchCart) {
        refetchCart()
      }
    },
  })

  const handleOnCardClick = () => {
    if (onCardClick) {
      onCardClick(id)
    }
  }

  const {mutate: mutateDelete} = useDeleteFromCart({
    onSuccess: refetch
  })

  const handleClick = () => {
    if (isInCart) {
      mutateDelete(id ?? "")
      if (refetchCart) {
        refetchCart()
      }
    } else {
      openAddInCartModal([id])
    }
  }
  const showCheck = fromModal ? checked : isInCart
  const countExtra = (tariffOptions && tariffOptions.length <= 3) ? undefined : `+${tariffOptions && tariffOptions.length - 2}`
  const tariffOptionsOptimised = (tariffOptions && tariffOptions.length > 3) ? tariffOptions.slice(0, 2) : tariffOptions
  const tariffText = (value: string) => tariffOptions?.find(el => el.value === value)?.label ?? ""

  return (
    <div
      className={cn(styles.tableContent,
        {
          [ styles.tableContentSuccess ]: status === AppliedProductStatusEnum.success,
          [ styles.mini ]: mini
        })}
      onClick={handleOnCardClick}>
      <div className={styles.rowMain}>
        {photo
          ? <img src={photo} alt="avatar"/>
          : <div className={styles.logo}><Avatar/></div>
        }
        {discount && type === "available" && !withOutStrap &&
          <div className={styles.discount}>
            {discount}
          </div>
        }
        <div className={cn(styles.tableCol, {
          [ styles.tableColWithOutCard ]: type !== "available",
          [ styles.tableColMini ]: mini
        })}>
          <div className={styles.tableRow}>
            <div className={styles.title}>
              {title}
            </div>
            {type === "available" &&
              <div className={cn(styles.title, {[ styles.priceWhenIsDiscount ]: priceWithDiscount})}>
                {`от ${formatThousand(priceWithDiscount || price)} ₽`}
              </div>}
            {type === "purchased" &&
              <div className={styles.available}>
                {`Доступно ${availableAmount}`}
              </div>
            }
            {type === "applied" &&
              <div
                className={cn(styles.status, {[ styles.statusSuccess ]: status === AppliedProductStatusEnum.success})}>
                {appliedProductStatus[ status ?? "inProgress" ]}
              </div>
            }
          </div>
          <div className={styles.tableRow}>
            <div className={styles.optionsRow} id="productOptionsRow">
              {type === "available" ?
                <>
                  {tariffOptions && tariffOptionsOptimised.map(option => (
                    <div className={styles.option}>
                      {option.label}
                    </div>
                  ))}
                  {countExtra &&
                    <div className={styles.countExtra}>
                      {countExtra}
                    </div>}
                </>
                : <div className={styles.option}>
                  {tariffText(tariff ?? "")}
                </div>
              }
            </div>
            {priceWithDiscount && type === "available" &&
              <div className={styles.priceWithoutDiscount}>
                {`${formatThousand(price)} ₽`}
              </div>}
          </div>
          {type === "applied" &&
            <div className={styles.tableRow} style={{marginTop: mini ? "4px" : "14px"}}>
              <div className={styles.applicationTitle}>
                {applicationTitle}
              </div>
              <div className={styles.created}>
                {formatDateWithTime(createdAt ?? new Date())}
              </div>
            </div>
          }
        </div>
      </div>
      {type === "available" && !withOutStrap &&
        <div
          className={cn(styles.cart, {[ styles.purchased ]: showCheck, [ styles.cartMini ]: mini})}
          onClick={(event) => {
            event?.stopPropagation();
            if (onCardClick) {
              handleOnCardClick()
            } else {
              handleClick()
            }
          }}
        >
          {showCheck ? <Check/> : <ToCart/>}
        </div>
      }
    </div>
  )
}