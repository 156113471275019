import { RealEstateSchemaConfigField } from "../../../../../../types";

type ReturnValue = { label: string; value: string }[];

export const getSelectOptions = (field: RealEstateSchemaConfigField): ReturnValue => {
	const {
		dataType: { allowedValues },
	} = field;

	return (
		allowedValues?.map((value) => ({
			label: value.toString() as string,
			value: value.toString() as string,
		})) || []
	);
};
