import { memo } from "react";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import styles from "./index.module.scss";

type Props = {
	unlikedObjects?: number;
};

export const VoteBanner = memo(
	({ unlikedObjects = 0 }: Props): JSX.Element => (
		<div className={styles.root}>
			<div className={styles.main}>
				<div>Неоцененных объектов</div>
				<div>{unlikedObjects}</div>
			</div>

			<Button classNames={{ root: styles.button }}>Проголосовать</Button>
		</div>
	)
);
