import { useQuery } from "react-query";
import { ShowsViewsCountPayload } from "../../../../types";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/shows-views/load-shows-or-views-count/DEFAULT_QN";

type UseLoadShowsOrViewsCount = ShowsViewsCountPayload;

export const useLoadShowsOrViewsCount = ({ applicationId, type }: UseLoadShowsOrViewsCount) => {
	const loadViewsCount = () => api.showsViews.count({ applicationId, type });

	const { data, refetch, remove, isLoading, isFetched } = useQuery(DEFAULT_QN, loadViewsCount);

	return {
		data: data?.data,
		isLoading,
		refetch,
		remove,
		isFetched,
	};
};
