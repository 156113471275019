import { useMutation } from "react-query";
import { UseFormSetError } from "react-hook-form";
import { AxiosError } from "axios";
import { Office } from "../../../../types";
import { api } from "../../api";
import { axiosError } from "../../../../utils/errors";

const DEFAULT_MN = "sdelka/office/update-office/DEFAULT_MN";

type UseOfficeUpdate = {
	onSuccess?: () => void;
	setError?: UseFormSetError<any>;
};

export const useOfficeUpdate = ({ onSuccess, setError }: UseOfficeUpdate) => {
	const onError = (error: AxiosError) => {
		const message = axiosError({ error });
		if (setError) {
			setError("name", {message});
		}
	};

	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (data: Office) =>
			api.offices.update(data.id, data),
		onSuccess,
		onError,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
