import { Contact } from "../../../../../../types";
import { useState } from "react";

type Return = {
	contacts: Contact[];
	onDeleteContacts: (id: string) => void;
	onChangeContacts: (contact: Contact) => void;
};

type UseContacts = () => Return;

export const useContacts: UseContacts = () => {
	const [contacts, setContacts] = useState<Contact[]>([]);

	const onDeleteContacts = (id: string) => {
		const contactsWithoutContact = contacts.filter((contact) => contact.id !== id);

		setContacts(contactsWithoutContact);
	};

	const onChangeContacts = (newContact: Contact) => {
		setContacts((prev) => [...prev, newContact]);
	};

	return {
		contacts,
		onDeleteContacts,
		onChangeContacts,
	};
};
