import styles from "./index.module.scss"
import { AppliedProductStatusEnum } from "../../../types/product";
import { CardApplication } from "../../UI/Card/card-application";
import { CardPerson, TabsStage } from "../../UI";
import React from "react";
import { useLoadProductOrder } from "../../../service/api/queries";
import { UseLoadUserPositions } from "../../../service/api/queries/position/useLoadUserPositions";
import { ProductOrderComment } from "./component/ProductOrderComment";
import { CardTask } from "../../UI/Card/card-task/CardTask";
import { formatDateWithTime } from "../../../utils/date";
import { ReactComponent as Cancel } from "../../../assets/icons/cancel.svg";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useDeleteProductOrder, useSetDisputProductOrder } from "../../../service/api/mutations";
import { selectDevice, useSelect } from "../../../service/redux/selectors";
import { CardApplicationMini } from "../../UI/Card/card-application/card-application-mini";
import { Plug } from "../ShowsAndViews/components/plug/Plug";
import { LayoutLoading } from "../RealEstatePage/mode/RealEstateViewWithTabs/LayoutLoading";
import { useIsTelegram } from "../../../utils/telegram/useIsTelegram";

type Props = {
  refetchAll?: () => void
  orderId?: string
}

export const ProductOrderCard = (
  {
    refetchAll,
    orderId
  }: Props) => {

  const {productOrder: order, refetch: refetchOrder} = useLoadProductOrder({id: orderId})
  const {getPositionName} = UseLoadUserPositions({});
  const {isMobile} = useSelect(selectDevice)

  const getButtonText = () => {
    switch (order?.status) {
      case AppliedProductStatusEnum.success:
        return "Оспорить"
      case AppliedProductStatusEnum.notPaid:
        return "Оплатить"
      case AppliedProductStatusEnum.inProgress:
        return "Открыть чат"
      case AppliedProductStatusEnum.atDisput:
        return "Оспорить"
      default:
        return ""
    }
  }

  const {mutate: setDisput} = useSetDisputProductOrder({
    onSuccess: () => {
      if (refetchAll) {
        refetchAll()
      }
      refetchOrder()
    }
  })

  const buttonAction = () => {
    switch (order?.status) {
      case AppliedProductStatusEnum.notPaid:
        return executeUrl(`${order?.paymentUrl}`)
      case AppliedProductStatusEnum.inProgress:
        return executeUrl(`${order?.chatUrl}`)
      case AppliedProductStatusEnum.atDisput:
      case AppliedProductStatusEnum.success:
        return setDisput(order?.id)
      default:
        return () => {
        }
    }
  }

  const {mutate: deleteOrder} = useDeleteProductOrder({
    onSuccess: () => {
      if (refetchAll) {
        refetchAll()
      }
      refetchOrder()
    }
  })
  const cancelProductOrder = () => {
    deleteOrder(order?.id ?? "")
  }
  const {executeUrl} = useIsTelegram()
  const openApplication = () => {
    executeUrl(`/leadsanddeals?tab=applications&whoIsClient=${order?.application.shortType.whoIsClient}&${order?.application.shortType.kind}Id=${order?.application.id}`)
  }

  return (
    <div className={styles.wrapper}>
      {order
        ? <div className={styles.root}>
          <div className={styles.content}>
            <div className={styles.application} onClick={openApplication}>
              {isMobile
                ? <CardApplicationMini application={order.application} isMobile={isMobile} withoutStage/>
                : <CardApplication
                  application={order.application}
                  kind={order.application.shortType.kind}
                />}
            </div>
            <TabsStage
              stages={order.stages}
              currentStage={order.stageNo}
              purchasedProductTitle={order.purchasedProductTitle}
            />
            <div className={styles.title}>
              Исполнитель
            </div>
            <div className={styles.user}>
              {order.executorUser ? <CardPerson
                  id={order.executorUser.id}
                  phone={order.executorUser.account.phone}
                  position={getPositionName(order.executorUser.position?.id)}
                  avatar={order.executorUser.account.avatar}
                  specialisation={order.executorUser.specialisations}
                  status={order.executorUser.status}
                  fio={{
                    firstName: order.executorUser.account.name,
                    lastName: order.executorUser.account.lastName,
                    secondName: order.executorUser.account.secondName
                  }}
                  withOutDots
                />
                : <Plug plugText="Исполнитель не назначен" mini={isMobile}/>
              }
            </div>
            {order.status !== AppliedProductStatusEnum.notPaid &&
              <>
                <div className={styles.title}>
                  Задачи
                </div>
                <div className={styles.task}>
                  {order.task
                    ? <CardTask task={order.task} mini={isMobile}/>
                    : <Plug plugText="Задач нет" mini={isMobile}/>
                  }
                </div>
              </>
            }
            <ProductOrderComment orderId={order.id} comment={order.description}/>
          </div>
          <div className={styles.footer}>
            <div>
              <div className={styles.label}>
                ID заказа:
              </div>
              <div className={styles.text}>
                {order.shortId}
              </div>
            </div>
            <div>
              <div className={styles.label}>
                ID заявки:
              </div>
              <div className={styles.link}>
                {order.applicationShortId}
              </div>
            </div>
            <div>
              <div className={styles.label}>
                Дата создания заказа:
              </div>
              <div className={styles.text}>
                {formatDateWithTime(order.createdAt)}
              </div>
            </div>
          </div>

        </div>
        : <LayoutLoading/>
      }
      <div className={styles.stickyFooter}>
        {(order?.status === AppliedProductStatusEnum.notPaid || order?.status === AppliedProductStatusEnum.inProgress) &&
          <div className={styles.cancel} onClick={cancelProductOrder}>
            <Cancel/>
            <div>Отменить</div>
          </div>}
        <Button
          classNames={{root: styles.button}}
          onClick={buttonAction}
        >
          {getButtonText()}
        </Button>
      </div>
    </div>
  )
}