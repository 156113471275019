import { useDispatch } from "react-redux";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { PinContact } from "../../../../UI";
import { ContactInfo } from "../../../ContactCard";
import { openDrawerInDrawer } from "../../../../../service/redux/modules/drawer/drawer.action";
import { closeModal } from "../../../../../service/redux/modules/modal/modal.action";
import { Contact, ContactPinDefaultValues } from "../../../../../types";

import { ReactComponent as PlusIcon } from "../../../../../assets/icons/plus.svg";
import styles from "./index.module.scss";

type Props = {
	phone: string;
	fio?: string;
	email?: string;
};

export const Title = ({ phone, fio, email }: Props): JSX.Element => {
	const dispatch = useDispatch();

	const names = fio?.split(" ") ?? [];

	const defaultValues: ContactPinDefaultValues = {
		phone: phone.slice(1),
		email,
		lastName: names[0],
		name: names[1],
		secondName: names[2],
	};

	const onPinContact = (contact: Contact) => {
		dispatch(closeModal());
		dispatch(
			openDrawerInDrawer({
				children: <ContactInfo contactId={contact.id} />,
				width: 680,
			})
		);
	};

	return (
		<div className={styles.root}>
			<span className={styles.title}>Неизвестный контакт</span>
			<PinContact onPin={onPinContact} defaultValues={defaultValues}>
				<Button variant="text">
					<PlusIcon />
					Создать контакт
				</Button>
			</PinContact>
		</div>
	);
};
