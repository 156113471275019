import { AxiosResponse } from "axios";
import { UseMutateFunction, useMutation } from "react-query";
import { ProductOrder } from "../../../../types/product";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/servises/update-order/DEFAULT_MN";

type Props = {
  onSuccess?: () => void;
};

type Return = {
  mutate: UseMutateFunction<AxiosResponse<ProductOrder>, unknown, {
    id: string,
    payload: {
      description: string
    }
  }, unknown>;
  isLoading: boolean;
  isSuccess: boolean;
};

type UseUpdateProductOrder = (props: Props) => Return;

export const useUpdateProductOrder: UseUpdateProductOrder = ({onSuccess}) => {

  const mutation = useMutation<AxiosResponse<ProductOrder>, unknown, {
    id: string,
    payload: {
      description: string
    }
  }, unknown>(
    DEFAULT_MN,
    {
      mutationFn: ({id, payload}) => api.services.updateProductOrder(id, payload),
      onSuccess,
    }
  )

  return {
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
    isSuccess: mutation.isSuccess,
  };
}