import * as yup from "yup";
import { OptionalObjectSchema } from "yup/lib/object";

import { commonErrors } from "../../errors";

type CreateSchemaProp = {
	descriptionFieldName?: string;
	hideVideoLink?: boolean;
};

type SchemaProp = {
	descriptionFieldName: string;
};

const additionalPropertiesSchema = yup.object().shape({
	videoUrl: yup
		.string()
		.url(
			"Скопируйте полный интернет адрес, включая https:// в начале. Убедитесь в отсутсвии пробелов и запятых"
		),
});

const adTitleSchema = yup
	.string()
	.nullable()
	.required(commonErrors.required)
	.max(255, "Заголовок слишком длинный");

const descriptionFieldNameSchema = yup
	.string()
	.max(5000, "Описание слишком длинное")
	.required(commonErrors.required);

const schemaWithVideo = ({ descriptionFieldName }: SchemaProp) =>
	yup.object({
		additionalProperties: additionalPropertiesSchema,
		adTitle: adTitleSchema,
		[descriptionFieldName]: descriptionFieldNameSchema,
	});

const schema = ({ descriptionFieldName }: SchemaProp) =>
	yup.object({
		adTitle: adTitleSchema,
		[descriptionFieldName]: descriptionFieldNameSchema,
	});

export const createObjectDescriptionSchema = ({
	descriptionFieldName = "adDescription",
	hideVideoLink = false,
}: CreateSchemaProp): OptionalObjectSchema<any> =>
	hideVideoLink ? schema({ descriptionFieldName }) : schemaWithVideo({ descriptionFieldName });
