import { Lead, LeadStatusEnum } from "../../../../../../../../../types";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import styles from "../../index.module.scss";
import { useRefuseModal } from "../../helpers/useChangeLeadStatus/Modals";
import { useTakeLead } from "../../../../../../../../../service/api/mutations";
import { useOpenRelatedLeadModal } from "../../../RelatedLeads/addRelatedLeadModal/UseOpenRelatedLeadModal";

type Props = {
  lead: Lead
  refetch: () => void;
  isLoading?: boolean
}

export const MainActionButton = ({lead, refetch, isLoading}: Props): JSX.Element => {
  const {
    id,
    status,
  } = lead
  const isLeadTaken = false;
  const {openAddRelatedLeadModal} = useOpenRelatedLeadModal({lead, refetch})
  const {mutate: mutateTakeLead} = useTakeLead({onSuccess: () => refetch()});

  const handleClickTakeLead = (takenID: string) => {
    if (!isLeadTaken) {
      mutateTakeLead(takenID);
    }
    if (lead.status === LeadStatusEnum.deferred) {
      mutateTakeLead(takenID);
    }
  };
  const {openRefuseModal} = useRefuseModal({leadId: id, refetch});

  const getLeadButton = () => {
    switch (status) {
      case LeadStatusEnum.free:
      case LeadStatusEnum.new:
        return <Button
          onClick={() => handleClickTakeLead(lead.id)}
          isLoading={isLoading}
          disabled={isLeadTaken}
        >
          {!isLeadTaken ? "Взять в работу" : "Нельзя взять чужой лид в работу"}
        </Button>
      case LeadStatusEnum.deferred:
        return <Button
          onClick={() => handleClickTakeLead(lead.id)}
          isLoading={isLoading}
        >
          Разморозить
        </Button>
      case LeadStatusEnum.done:
        return <Button
          onClick={openAddRelatedLeadModal}
          isLoading={isLoading}
        >
          Создать побочный лид
        </Button>
      case LeadStatusEnum.in_progress:
        return <Button
          classNames={{root: styles.refuseButton}}
          onClick={openRefuseModal}
          isLoading={isLoading}
        >
          Убрать в архив
        </Button>
      default:
        return <> </>
    }
  }

  return (
    <>
      {getLeadButton()}
    </>
  )
}