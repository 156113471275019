import { useQuery } from "react-query";
import {
	BusinessProcessSettings,
	BusinessProcessStageType,
	CategoryEnum,
	BusinessProcess,
} from "../../../../types";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/settings/load-businsess-process/DEFAULT_QN";

type Props = {
	businessProcessStageTypes?: BusinessProcessStageType[];
	businessProcessType?: BusinessProcess | "";
	category?: CategoryEnum;
	enabled?: boolean;
};

type Return = {
	list?: BusinessProcessSettings[];
	isLoading: boolean;
	isFetching: boolean;
};

type UseLoadBusinessProcess = (props: Props) => Return;

export const useLoadBusinessProcess: UseLoadBusinessProcess = ({
	businessProcessStageTypes = [],
	enabled = true,
	businessProcessType,
	category,
}) => {
	const loadBusinessProcess = () =>
		api.settings.businessProcessList(businessProcessStageTypes, businessProcessType, category);

	const { data, isLoading, isFetching } = useQuery(
		[DEFAULT_QN, businessProcessStageTypes, businessProcessType, category],
		loadBusinessProcess,
		{
			enabled,
		}
	);

	return {
		list: data?.data || [],
		isLoading,
		isFetching,
	};
};
