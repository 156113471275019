import { AxiosResponse } from "axios";
import { useDispatch } from "react-redux";
import { KindType, Task } from "../../../../types";

import { CreateTaskModal } from "../../../../components/UI";
import { openModal } from "../../../../service/redux/modules/modal/modal.action";

import styles from "./index.module.scss";
import { selectDevice, useSelect } from "../../../../service/redux/selectors";

type Props = {
	applicationId?: string;
	kind: KindType
	onCreate?: (data: AxiosResponse<Task>) => void;
	refetchParent?: () => void
};

export const useCreateTaskModal = ({ applicationId, onCreate, kind, refetchParent }: Props) => {
	const dispatch = useDispatch();
	const {isMobile} = useSelect(selectDevice)

	const openCreateTaskModal = () =>
		dispatch(
			openModal({
				title: <h3 className={styles.title}>Создать задачу</h3>,
				body: <CreateTaskModal applicationId={applicationId} onCreate={onCreate} kind={kind} refetch={refetchParent}/>,
				width: 800,
				classNames: {
					contentWrapper: isMobile ? styles.contentWrapperClassNameMobile : styles.contentWrapperClassName,
					wrapper: styles.wrapperClassName,
					contentBody: styles.contentBody,
					buttonClose: isMobile ? styles.modalClose : ""
				},
			})
		);

	return { openCreateTaskModal };
};
