export const SET_DEVICE = "sdelka/device/SET_DEVICE";
export const CLEAN_UP = "sdelka/device/CLEAN_UP";

export type DeviceAction =
  | { type: typeof SET_DEVICE; isMobile?: boolean, isTelegram?: boolean }
  | { type: typeof CLEAN_UP };

export const setIsMobileDevice = (isMobile?: boolean, isTelegram?: boolean): DeviceAction => ({
  type: SET_DEVICE,
  isMobile,
  isTelegram
})

export const cleanUp = (): DeviceAction => ({
  type: CLEAN_UP
})