import { ShowsViews } from "../../../../../types";
import styles from "./index.module.scss"
import { formatDate } from "@sdelka_crm/sdelka-crm-component-library";

type Props = {
  isViews: boolean;
  item: ShowsViews
}

export const CardFooter = ({isViews, item}: Props): JSX.Element => {
  const {
    showShortId,
    viewShortId,
    applicationShortId,
    applicationCreatedAt,
    showApplicationId,
    viewApplicationId
  } = item

  const textLabel = !isViews ? "ID показа" : "ID просмотра"
  const mirrorTextLabel = !isViews ? "ID просмотра" : "ID показа"
  const mirrorShortId = isViews ? showShortId : viewShortId
  const textId = !isViews ? showShortId : viewShortId
  const mirrorId = isViews ? showApplicationId : viewApplicationId

  const onLinkClick = () => {
    window.open(`/leadsanddeals?tab=applications&id=${mirrorId}&client=${isViews ? "seller" : "buyer"}&mode=1`, "_blank");
  }

  return (
    <div className={styles.root}>
      <div className={styles.column}>
        <div className={styles.label}>
          {textLabel}
        </div>
        <div className={styles.text}>
          {textId}
        </div>
      </div>
      {mirrorShortId &&
        <div className={styles.column}>
        <div className={styles.label}>
          {mirrorTextLabel}
        </div>
        <div className={styles.link} onClick={onLinkClick}>
          {mirrorShortId}
        </div>
      </div>
      }
      <div className={styles.column}>
        <div className={styles.label}>
          ID заявки
        </div>
        <div className={styles.text}>
          {applicationShortId}
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.label}>
          Дата создания заявки:
        </div>
        <div className={styles.text}>
          {`${formatDate(applicationCreatedAt ?? new Date())} в ${formatDate(applicationCreatedAt ?? new Date(), "time")}`}
        </div>
      </div>
    </div>
  )
}