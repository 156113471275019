import { ReactNode, useEffect, useRef, useState } from "react";
import cn from "classnames";
import { DefaultSelectProps } from "../../../../../../types";
import styles from "./index.module.scss";

type Props = {
  isListOpen: boolean;
  searchChildren?: ReactNode;
  children: ReactNode;
  thereIsOptions: boolean;
} & Pick<DefaultSelectProps, "classNames" | "size">;

export const OptionsListWrapper = (
  {
    isListOpen,
    searchChildren,
    children,
    thereIsOptions,
    classNames,
    size,
  }: Props): JSX.Element => {
  const [openToTop, setOpenToTop] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const animationStyles = "animate__animated animate__faster animate__fadeInDown";

  const setWhereToOpen = () => {
    if (contentRef && contentRef.current) {
      const position = contentRef.current.getBoundingClientRect();
      const {innerHeight} = window;
      const {offsetHeight} = contentRef.current;
      const {top} = position;

      if (top + offsetHeight >= innerHeight) {
        setOpenToTop(true);
      }
    } else {
      setOpenToTop(false);
    }
  };

  useEffect(() => {
    setWhereToOpen();
  }, [isListOpen]);

  return (
    <>
      {isListOpen && (
        <div
          ref={contentRef}
          className={cn(
            styles.optionsWrapper,
            animationStyles,
            classNames?.selectListWrapper,
            styles[ `size__${size}` ],
            {
              [ styles.openToTop ]: openToTop,
            }
          )}
        >
          {searchChildren}
          {thereIsOptions ? children : <span className={styles.noChoice}>Введите текст</span>}
        </div>
      )}
    </>
  );
};
