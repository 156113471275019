import { useMutation } from "react-query";

import { CompanyUpdateAccession } from "../../../../types";
import { api } from "../../index";

const DEFAULT_MN = "sdelka/company/update-accession/DEFAULT_MN";

type UseCompanyUpdateAccession = {
	onSuccess?: () => void;
};

export const useCompanyUpdateAccession = ({ onSuccess }: UseCompanyUpdateAccession) => {
	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: ({ id, data }: { id: string; data: CompanyUpdateAccession }) =>
			api.companies.updateAccession(id, data),
		onSuccess,
	});

	return {
		mutate: mutate.mutate,
		mutateAsync: mutate.mutateAsync,
		isLoading: mutate.isLoading,
	};
};
