export const defaultApplicationBlockData = {
	clientObject: undefined,
	clientObjectId: "",
	comment: "",
	creator: {
		id: "",
		account: {
			id: "",
			name: "",
			lastName: "",
			secondName: "",
		},
		createdAt: new Date(),
	},
	stages: [
		{
			name: "",
			order: 1,
			background: "yellow",
			image: "https://sdelka-crm.ru/werwerwerwerwe.png",
			title: "",
			stageNumber: 1,
		},
	],
	stageNo: 1,
	additionalProperties: {},
	type: {
		whoIsClient: "seller",
	},
	collectionDescription: "",
};
