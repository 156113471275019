import { TasksList, TasksMainFilters } from "./components";
import styles from "./index.module.scss";
import cn from "classnames";

type Props = {
  isMobile?: boolean
}

export const TasksMain = ({isMobile}: Props): JSX.Element => (
  <div className={cn(styles.root, {[ styles.mobile ]: isMobile})}>
    <TasksMainFilters className={styles.mainFilters}/>
    <TasksList className={styles.tasksList} isMobile={isMobile}/>
  </div>
);
