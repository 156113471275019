import { TrafficWithDate } from "../../../../../../types";
import { CardTraffic } from "../../../../../UI/Card/card-traffic/CardTraffic";

export const useColumns = () => [
    {
      accessor: "checkbox",
      render: ({row}: { row: TrafficWithDate }) => (
        <CardTraffic callData={row.callData}/>
      ),
    },
  ];
