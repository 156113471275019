import { Dispatch, SetStateAction } from "react";
import { Body, Header } from "./components";
import { useCardScroll, useChangeStage, useUpdateApplication } from "./helpers";
import { ApplicationCardResponse, KindType, RealEstate } from "../../../../../../../../types";
import styles from "./index.module.scss";
import { CardLead } from "components/UI/Card/card-lead/CardLead";
import { CardApplication } from "../../../../../../../UI/Card/card-application";
import { CardApplicationMini } from "../../../../../../../UI/Card/card-application/card-application-mini";
import { useIsTelegram } from "../../../../../../../../utils/telegram/useIsTelegram";

type Props = {
  applicationId: string;
  application: ApplicationCardResponse;
  refetch: () => void;
  onTab?: (tab: number) => void;
  stageNum: number;
  setStageNum: Dispatch<SetStateAction<number>>;
  currentBlocks: any[];
  isFetchingBlocks: boolean;
  kind?: KindType
  isMobile?: boolean
};
export const Info = (
  {
    applicationId,
    application,
    refetch,
    onTab,
    stageNum,
    setStageNum,
    isFetchingBlocks,
    currentBlocks,
    kind,
    isMobile
  }: Props): JSX.Element => {
  const {
    stages,
    clientObject,
    type: {whoIsClient},
  } = application;

  const {
    onNextStage,
    isLoading: isLoadingNextStage,
    errorsLabel,
  } = useChangeStage({stages, applicationId, refetch, stageNum, kind});
  const {onScroll, scrollY} = useCardScroll({currentBlocks});

  const {unpinObject, pinObject} = useUpdateApplication({
    refetch,
    applicationId,
    kind
  });
  const addObject = (object: RealEstate) => pinObject(object.id);

  const {executeUrl} = useIsTelegram()

  const openApplicationFromDeal = (id?: string, kindApp?: KindType) => {
    executeUrl(`/leadsanddeals?tab=applications&${kindApp === KindType.APPLICATION ? "applicationId" : "preApplicationId"}=${id}`)
  }

  const openLead = (id: string) => {
    executeUrl(`/leadsanddeals?tab=leads&leadId=${id}`)
  }

  return (
    <div onScroll={onScroll} className={styles.root}>
      {kind === KindType.APPLICATION ?
        <Header
          whoIsClient={whoIsClient}
          clientObject={clientObject}
          applicationId={applicationId}
          isMobile={isMobile}
        />
        : <div className={application.parentLead ? styles.cardLead : styles.dealHeader}>
          {application.parentLead ?
            <div onClick={() => openLead(application.parentLead.id)} style={{cursor: "pointer"}}>
              <CardLead lead={application.parentLead} className={styles.card_lead} mini={isMobile}/>
            </div>
            : <>
              {application.buyApplication &&
                <div className={styles.item}
                     onClick={() => openApplicationFromDeal(application.buyApplication?.id, application.buyApplication?.shortType.kind)}>
                  {isMobile
                    ? <CardApplicationMini
                      application={application.buyApplication}
                      isMobile={isMobile}
                      withoutStage
                    />
                    : <CardApplication
                      application={application.buyApplication}
                      withStage
                      kind={KindType.APPLICATION}
                    />}
                </div>
              }
              {application.saleApplication &&
                <div className={styles.item}
                     onClick={() => openApplicationFromDeal(application.saleApplication?.id, application.saleApplication?.shortType.kind)}>
                  {isMobile ? <CardApplicationMini
                      application={application.saleApplication}
                      isMobile={isMobile}
                      withoutStage
                    />
                    : <CardApplication
                      application={application.saleApplication}
                      withStage
                      kind={KindType.APPLICATION}
                    />}
                </div>
              }
            </>
          }
        </div>
      }
      <Body
        applicationId={applicationId}
        application={application}
        onTab={onTab}
        refetch={refetch}
        scrollY={scrollY}
        unpinObject={unpinObject}
        addObject={addObject}
        setStageNum={setStageNum}
        currentBlocks={currentBlocks}
        onNextStage={onNextStage}
        errorsLabel={errorsLabel}
        isFetching={isLoadingNextStage}
        isFetchingBlocks={isFetchingBlocks}
        onPinObject={addObject}
        kind={kind}
        isMobile={isMobile}
      />
    </div>
  );
};
