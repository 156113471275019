import { useMutation, useQueryClient } from "react-query";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/address/load-address-detailed/DEFAULT_MN";

export const useFindAddressDetailed = () => {
  const queryClient = useQueryClient();

  const mutate = useMutation(DEFAULT_MN, {
    mutationFn: (addressOrPoint: string) => api.address.findAddressDetailed(addressOrPoint),
  });

  const getLastMutationCache = () => {
    const allMutations = queryClient.getMutationCache().findAll({mutationKey: DEFAULT_MN});
    return allMutations[ allMutations.length - 1 ];
  };

  return {
    data: mutate.data?.data,
    mutate: mutate.mutate,
    mutateAsync: mutate.mutateAsync,
    isLoading: mutate.isLoading,
    getLastMutationCache,
  };
};
