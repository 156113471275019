import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { api } from "../../api";
import { RegistrationPayloadV2, RegistrationResponse2 } from "../../../../types";

const mutationKey = "sdelka/otp/request";

type Props = {
  onSuccess?: (response: AxiosResponse<RegistrationResponse2>) => void;
  onError?: ((error: unknown, variables: RegistrationPayloadV2, context: unknown) => (void | Promise<unknown>)) | undefined
};

export const usePublicOfferRequest = ({onSuccess, onError}: Props) => {
  const mutation = useMutation({
    mutationKey,
    mutationFn: (data: RegistrationPayloadV2) => api.auth.publicOfferRequest(data),
    onSuccess,
    onError
  },);

  return {
    mutate: mutation.mutate,
    isSuccess: mutation.isSuccess,
    data: mutation.data,
    error: mutation.error
  };
}
