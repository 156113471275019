import styles from "./index.module.scss"
import { ApplicationCardPayload, KindType, StageConditions, User, WhoIsClient } from "../../../../types";
import { Contacts } from "../Contacts";
import React from "react";
import { Responsibles } from "../Responsibles";

type Props = ApplicationCardPayload & {
  type: WhoIsClient;
  kind?: KindType,
  scrollY?: number;
  applicationId?: string;
  stageConditions?: StageConditions;
  responsibleUser?: User;
  refetch: () => void;
  isMobile?: boolean
};

export const DealContacts = (
  {
    type,
    applicationId = "",
    scrollY,
    stageConditions,
    kind = KindType.APPLICATION,
    refetch,
    responsibleUser,
    isMobile
  }: Props) => {

  const title = type === "seller" ? "Сторона продавца" : "Сторона покупателя"

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {title}
      </div>
      <div>
        <Contacts
          kind={kind}
          type={type}
          scrollY={scrollY}
          applicationId={applicationId}
          stageConditions={stageConditions}
          atDeal
        />
      </div>
      <div>
        <Responsibles
          scrollY={scrollY}
          applicationId={applicationId}
          stageConditions={stageConditions}
          responsibleUser={responsibleUser}
          refetch={refetch}
          kind={kind}
          atDeal
          withoutDots
          isMobile={isMobile}
        />
      </div>
    </div>
  )
}