import { RefObject, useEffect } from "react";

type Props = {
	wrapperRef: RefObject<HTMLDivElement>;
	isFetching: boolean;
	page: number;
};

export const useAutoScroll = ({ wrapperRef, isFetching, page }: Props): void => {
	useEffect(() => {
		if (wrapperRef && !isFetching) {
			wrapperRef.current?.scrollTo({ top: 0, behavior: "smooth" });
		}
	}, [wrapperRef, isFetching, page]);
};
