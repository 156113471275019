import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { AuthLayout } from "../components";
import { EnterCodeResetPasswordPage } from "./EnterCode";
import { EnterPassword } from "./EnterPassword";
import { PhoneVerification } from "./PhoneForm";
import { useSelector } from "../../../../service/redux/useSelector";
import { cleanUp } from "../../../../service/redux/modules/reset-pass-steps/reset-pass-steps.action";
import { AuthGuard } from "../../../../service/router/AuthGuard";

export const ResetPassword = (): JSX.Element => {
	const { currentStep } = useSelector((state) => state.resetPassSteps);
	const dispatch = useDispatch();

	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		return () => {
			dispatch(cleanUp());
		};
	}, []);

	return (
		<AuthGuard onlyNotAuthorized>
			<AuthLayout>
				{currentStep === "phone-verification" && <PhoneVerification />}
				{currentStep === "reset-password" && <EnterPassword />}
				{currentStep === "sms-code" && <EnterCodeResetPasswordPage />}
			</AuthLayout>
		</AuthGuard>
	);
};
