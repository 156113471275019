import { CreateTaskForm, CreateTaskPayload, KindType, Task } from "../../../../types";
import { AxiosResponse } from "axios";

import { useCreateTask } from "../../../../service/api/mutations/tasks";
import { clearObject } from "../../../../utils/objects";
import { useOpenTaskModal } from "../../../../utils/hooks";
import { getDeadlineByDateAndTime } from "../../../../utils/date";

type Props = {
  applicationId?: string;
  onCreate?: (data: any) => void;
  refetch?: () => void;
  kind: KindType
};

export const useSubmitForm = ({applicationId, onCreate, refetch, kind}: Props) => {
  const onUpdatedTask = () => {
    refetch?.();
  };

  const {handleOpenTaskModal} = useOpenTaskModal({onUpdatedTask, refetchParent: refetch});

  const onSuccess = (data: AxiosResponse<Task>) => {
    onCreate?.(data);

    const {
      data: {id},
    } = data;
    handleOpenTaskModal(id);
  };
  const {mutate, isLoading} = useCreateTask({
    onSuccess,
  });

  const clearCreateForm = (form: CreateTaskForm) => {
    const clearedObject: Partial<CreateTaskForm> = clearObject(form);
    Object.keys(form).forEach((key) => {
      if (clearedObject[ key ] === "") {
        delete clearedObject[ key ];
      }
    });

    return clearedObject;
  };

  const onSubmit = (values: CreateTaskForm) => {
    const clearedObject = clearCreateForm(values);
    const deadline = getDeadlineByDateAndTime({
      date: clearedObject?.deadlineDate,
      time: clearedObject?.deadlineTime,
    });

    if (clearedObject?.deadlineTime) {
      delete clearedObject?.deadlineTime;
    }
    if (clearedObject?.deadlineDate) {
      delete clearedObject?.deadlineDate;
    }

    let payload: CreateTaskPayload

    if (kind === KindType.PREAPPLICATION) {
      payload = {
        ...clearedObject,
        deadline,
        connectedPreApplicationId: applicationId,
        remindFor: values?.remindFor,
        executor: {
          userId: (values.executor.userId as any)[ 0 ].value
        }
      }
    } else if (kind === KindType.DEAL) {
      payload = {
        ...clearedObject,
        deadline,
        connectedDealId: applicationId,
        remindFor: values?.remindFor,
        executor: {
          userId: (values.executor.userId as any)[ 0 ].value
        }
      }
    } else {
      payload = {
        ...clearedObject,
        deadline,
        connectedApplicationId: applicationId,
        remindFor: values?.remindFor,
        executor: {
          userId: (values.executor.userId as any)[ 0 ].value
        }
      }
    }

    mutate(payload);
  };

  return {
    isLoading,
    onSubmit,
  };
};
