import { Button, Tooltip } from "@sdelka_crm/sdelka-crm-component-library";
import { InfoRow, PopupObjectType, RadioButton, Select } from "../../../../../../components/UI";
import { useBusinessProcessOptions, useDisplaySelect, useFormData } from "./helpers";
import { objectFilters } from "../../../../../clientObjects";
import { ApplicationBusinessProcessType, PreCreateApplicationPayload, PreCreateForm, } from "../../../../../../types";
import { ReactComponent as Info } from "../../../../../../assets/icons/iconInfo.svg";

import styles from "./index.module.scss";
import { SubmitHandler } from "react-hook-form";
import cn from "classnames";

type Props = {
  onCreated: (values: PreCreateApplicationPayload) => void;
  isFull?: boolean;
  isMobile?: boolean
};

export const PreCreateModal = ({onCreated, isFull, isMobile}: Props): JSX.Element => {
  const {register, setValue, handleSubmit, watchTypeId, watchCategory, watchTypeValue, control} = useFormData();

  const businessProcessOption = isFull
    ? [...objectFilters.sellBusinessProcess, ...objectFilters.buyBusinessProcess]
    : objectFilters.sellBusinessProcess;

  const {businessProcessOptions, isFetching, list} = useBusinessProcessOptions({
    businessProcessType: watchTypeValue,
    category: watchCategory,
  });

  const {showBusinessProcess, showCategory} = useDisplaySelect({
    watchCategory,
    watchTypeValue,
  });

  const handleOnCreate: SubmitHandler<PreCreateForm> = (values: PreCreateForm) => {
    const {
      type: {value, id},
      category,
    } = values;

    const found = list?.find((item) => item.id === id);

    if (found) {
      const type: ApplicationBusinessProcessType = {
        value: value || "sale",
        id: found.id,
        label: found.typeName,
      };

      if (category) {
        onCreated({
          category: category[ 0 ],
          type,
        });
      } else {
        onCreated({
          type,
        });
      }
    }
  };

  const typeLabel = (
    <div className={styles.row}>
      <div>
        Тип воронки
      </div>
      <Tooltip
        CustomIcon={Info}
        classNames={{
          root: styles.tooltipRoot,
          card: styles.tooltipCard,
        }}
        iconSize="medium"
      >
        Если у вас существует несколько видов воронки продаж,
        вы можете выбрать любую из доступных для данного объекта.
        Изменить воронку для созданного объекта нельзя.
      </Tooltip>
    </div>
  )

  return (
    <form className={styles.root} onSubmit={handleSubmit(handleOnCreate)}>
      <div className={cn(styles.warningContainer, {[styles.warningMobile]: isMobile})}>
        <div className={styles.title}>
          Внимание:
        </div>
        <div className={cn(styles.warning)}>
          Создав объект, СДЕЛКА автоматически создаст "Предзаявку на продажу", но вы не обязаны в ней работать. Это
          нужно для того, чтобы вы могли отслеживать отдельно объекты с "Предзаявками" и "Заявками
        </div>
      </div>
      <div className={styles.type}>
        <InfoRow label="Тип сделки" classNames={{root: styles.info}} />
        <RadioButton
          background="white"
          backgroundButtons="blue"
          name="type.value"
          control={control}
          className={styles.RadioButton}
          options={businessProcessOption}
          currentValue={watchTypeValue}
        />
      </div>
      {showCategory && (
        <InfoRow label="Тип объекта" classNames={{root: styles.select}} aspect={{label: 3, content: 2}}>
          <PopupObjectType
            register={register}
            setValue={setValue}
            placeholder="Выбрать"
            defaultValues={watchCategory || []}
            variant="light"
            isCreation
            isMobile
          />
        </InfoRow>
      )}
      {showBusinessProcess && (
        <InfoRow label={typeLabel} classNames={{root: styles.select}} aspect={{label: 3, content: 2}}>
          <Select
            name="type.id"
            register={register}
            setValue={setValue}
            options={businessProcessOptions}
            disabled={isFetching}
            defaultValue={businessProcessOptions && businessProcessOptions[ 0 ]?.value || ""}
          />
        </InfoRow>
      )}
      <div className={cn(styles.buttonContainer, {[styles.buttonContainerMobile]: isMobile})}>
        <Button disabled={!watchTypeId} type="submit">
          Создать
        </Button>
      </div>
    </form>
  );
};
