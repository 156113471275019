import { useHistory } from "react-router-dom";

import { useQueryParams } from "../hooks";

type UseGoBackRealEstate = {
	defaultBackPath?: string;
};

export const useGoBackRealEstate = ({ defaultBackPath }: UseGoBackRealEstate) => {
	const { push, goBack: goBackRouter } = useHistory();
	const { queryParams } = useQueryParams<{ backPath: string }>([{ name: "backPath" }]);

	const goBack = () => {
		if (queryParams.backPath) {
			const backPath = decodeURIComponent(queryParams.backPath);
			push(backPath);
		} else if (defaultBackPath) {
			push(defaultBackPath);
		} else {
			goBackRouter();
		}
	};

	return { goBack };
};
