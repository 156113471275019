import { Loader, formatDate } from "@sdelka_crm/sdelka-crm-component-library";

import { useLoadMyShowsViews } from "../../../../../../service/api/queries";

import styles from "./index.module.scss";

type Props = {
	title: string;
	selectedDate: Date;
};

export const TasksList = ({ title, selectedDate }: Props): JSX.Element => {
	const { data, isFetching } = useLoadMyShowsViews({ date: selectedDate });

	return (
		<div className={styles.root}>
			<div className={styles.title}>{title}</div>

			<div className={styles.wrapper}>
				<ul className={styles.body}>
					{data.length ? (
						data.map((task, index) => (
							// eslint-disable-next-line react/no-array-index-key
							<li key={index} className={styles.task}>
								<div className={styles.date}>{formatDate(task.plannedDate, "time")}</div>

								<div className={styles.info}>
									<div>{task.type === "show" ? "Показ объекта" : "Просмотр объекта"}</div>

									{task?.clientObject?.addressLabel && <div>{task.clientObject.addressLabel}</div>}
								</div>
							</li>
						))
					) : (
						<div className={styles.noTasks}>
							{isFetching ? (
								<div className={styles.loading}>
									<div>Загрузка</div>

									<div className={styles.loaderContainer}>
										<Loader />
									</div>
								</div>
							) : (
								"Нет задач на этот день"
							)}
						</div>
					)}
				</ul>
			</div>
		</div>
	);
};
