import { useState } from "react";
import { Underlay, LoadByScroll, H2, Button } from "@sdelka_crm/sdelka-crm-component-library";

import { InfoTable } from "./components";
import { HiddenWidget } from "../HiddenWidget";
import { useLoadShowsOrViewsCount } from "../../../../service/api/queries";
import { ApplicationCardPayload, ShowsViewsApplicationCardCount, ShowsOrViews } from "../../../../types";

import styles from "./index.module.scss";

type Props = ApplicationCardPayload & {
	type: ShowsOrViews;
	onTab?: (tab: number) => void;
};

export const ObjectShowsOrViews = ({
	type,
	applicationId = "",
	scrollY,
	onTab,
	stageConditions,
}: Props): JSX.Element => {
	const [enabled, setEnabled] = useState(false);

	const { data } = useLoadShowsOrViewsCount({ applicationId, type });

	const { count } = data || { count: {} as ShowsViewsApplicationCardCount };
	const { waiting, finished, total } = count;

	const isViews = type === "views";

	let title = "Показы объекта";
	let buttonText = "Смотреть показы";

	if (isViews) {
		title = "Просмотры объектов";
		buttonText = "Смотреть просмотры";
	}

	return (
		<div className={styles.root}>
			<H2>{title}</H2>

			{((stageConditions ? stageConditions.isNext : false) && <HiddenWidget />) || (
				<LoadByScroll height={250} scrollY={scrollY} isLoading={!enabled} onInView={() => setEnabled(true)}>
					<Underlay className={styles.wrapper}>
						<div className={styles.total}>
							<span className={styles.totalLabel}>Всего:</span>

							<span className={styles.totalNumber}>{total}</span>
						</div>

						<InfoTable planned={waiting} canceled={finished} />
					</Underlay>

					{onTab ? (
						<Button classNames={{ root: styles.button }} onClick={() => onTab(1)}>
							{buttonText}
						</Button>
					) : null}
				</LoadByScroll>
			)}
		</div>
	);
};
