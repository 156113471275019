import { ApplicationTemplate, ApplicationCreatePayload } from "../../../../types";
import { Client } from "../../Client";

export const ApplicationTemplateService = {
	create: ({ leadId, businessProcessId, category }: ApplicationCreatePayload) =>
		Client.post("/application-template", {
			leadId,
			businessProcessId,
			category,
		}),

	load: ({ applicationId }: { applicationId: string }) =>
		Client.get<ApplicationTemplate>(`/application-template/${applicationId}`),

	delete: ({ applicationId }: { applicationId: string }) =>
		Client.delete(`/application-template/${applicationId}`),

	getQuiz: ({ typeId }: { typeId: string }) =>
		Client.get(`application-template/type/${typeId}/blockData/quiz`),
};
