import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { H1 } from "@sdelka_crm/sdelka-crm-component-library";

import { toastSuccess } from "../../../UI";
import { ContactForm } from "./ContactForm";
import { useContactCreate } from "../../../../service/api/mutations";
import { closeDrawer } from "../../../../service/redux/modules/drawer/drawer.action";
import { useSelector } from "../../../../service/redux/useSelector";
import { Contact, CreateContactForm } from "../../../../types";

import styles from "./index.module.scss";

type Props = {
	onSubmitted?: () => void;
	onClose: () => void;
	mainPhone: string;
};

export const CreateContact = ({ onSubmitted, onClose, mainPhone }: Props): JSX.Element => {
	const dispatch = useDispatch();
	const { push } = useHistory();
	const { currentAccount } = useSelector((state) => state.currentAccount);

	const onCancel = () => {
		onClose();
		dispatch(closeDrawer());
	};

	const onSuccess = (data: Contact): void => {
		toastSuccess({
			text: "Контакт создан",
		});

		push(`/contacts?id=${data.id}`);

		if (onSubmitted) {
			onSubmitted();
		}
	};

	const { mutate } = useContactCreate({ onSuccess });

	const onSubmit = (payload: CreateContactForm) => {
		mutate(payload);
	};

	return (
		<div className={styles.wrapper}>
			<H1 classNames={{ root: styles.h1 }}>Создать контакт</H1>

			<ContactForm onSubmit={onSubmit} onCancel={onCancel} creator={currentAccount} mainPhone={mainPhone} />
		</div>
	);
};
