import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import styles from "./index.module.scss"
import { useAddResponsibleModal } from "../../index";
import { UserListLocal } from "../UserList";
import { arrValuesToMap } from "../../../../utils/transformation";
import { useResponsibleUsers } from "../../../../utils/hooks";
import { formatPhone } from "../../../../utils/formatters";
import { User, UserAccountData } from "../../../../types";

import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";
import { selectCurrentAccount, useSelect } from "../../../../service/redux/selectors";
import { useLayoutEffect } from "react";

type Props = {
  responsibles: string[];
  onChange: (values: string[]) => void;
  isMobile?: boolean
  withOutDots?: boolean
};

export const ResponsiblesLocal = (
  {
    responsibles,
    onChange,
    isMobile,
    withOutDots
  }: Props): JSX.Element => {
  const {users} = useResponsibleUsers();
  const {currentAccount} = useSelect(selectCurrentAccount);
  const mapResponsibles = arrValuesToMap(responsibles);

  const prepareData = (map: Record<string, number>, returnFunc) => users.filter((user) => map[ user.id ]).map(returnFunc)

  useLayoutEffect(() => {
    if (currentAccount?.id) {
      onChange([currentAccount?.id] as string[])
    }

  }, [])

  const userListLocal = (user: User) => ({
    id: user.id,
    phone: formatPhone(user.account.phone),
    name: user.account.name,
    secondName: user.account.secondName,
    lastName: user.account.lastName,
    avatar: user.account.avatar,
    email: user.account.connectInfo?.email,
    deleted: user.account.deleted,
    createdAt: user.account.createdAt,
    position: user.position?.name,
  });

  const {openAddResponsibleModal} = useAddResponsibleModal({
    title: "Выбрать ответственного",
    buttonLabel: "Добавить",
    onPin: ({responsiblesIds: selectedResponsibles}) => onChange(selectedResponsibles),
    checkedResponsibles: responsibles,
    isPinOne: true,
  });

  return (
    <UserListLocal
      title="Ответственный"
      data={prepareData(mapResponsibles, userListLocal) as UserAccountData[]}
      classNames={styles.root}
      isMobile={isMobile}
      withOutDots={withOutDots}
    >
      <Button Icon={PlusIcon} variant="text" onClick={openAddResponsibleModal}>
        Сменить ответственного
      </Button>
    </UserListLocal>
  );
};
