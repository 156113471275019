import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { LeadFiltersType } from "../../../../types";

import { api } from "../../index";
import { DEFAULT_LEAD_FILTERS } from "../../helpers";

type UseLoadLeads = {
	enabled?: boolean;
	defaultFilter?: LeadFiltersType;
	onSuccess?: () => void;
	name?: string;
	defaultTake?: number;
};

export const useLoadLeads = ({
	defaultTake = 30,
	enabled,
	defaultFilter = DEFAULT_LEAD_FILTERS,
	onSuccess,
	name = "loadLeads",
}: UseLoadLeads) => {
	const [filter, setFilter] = useState(defaultFilter);
	const [page, setPage] = useState(1);
	const [take, setTake] = useState(defaultTake);
	const skip = useMemo(() => (page - 1) * take, [page]);

	const loadLeads = () => api.leads.list({ take, skip, search: filter, value: "lead", sort: {field: "createdAt", order: "ASC"}});

	const { data, isLoading, refetch, isFetching, isFetched, isError } = useQuery(
		[name, take, skip, filter],
		loadLeads,
		{ enabled, onSuccess }
	);

	useEffect(() => {
		setPage(1);
		refetch();
	}, [filter]);

	return {
		list: data?.data?.data || [],
		count: data?.data?.count || 0,
		isLoading,
		isFetching,
		isFetched,
		isError,
		total: data?.data?.total || 0,
		page: data?.data?.page || 1,
		setPage,
		refetch,
		take,
		setTake,
		filter,
		setFilter,
	};
};
