import { useMemo, useState } from "react";
import { formatDate } from "@sdelka_crm/sdelka-crm-component-library";
import { Table } from "../../../../UI";
import { useColumns } from "./columns";
import { sortInObject } from "../../../../../utils/formatters";
import { useBlinkRow } from "../../../../../utils/hooks";
import { Traffic, TrafficWithDate } from "../../../../../types";
import styles from "./index.module.scss";
import cn from "classnames";

type Props = {
  setSelect?: (row: TrafficWithDate) => void
  trafficList: Traffic[];
  isLoading: boolean;
  className?: {
    root?: string
    title?: string
    row?: string
    col?: string
  }
};

const Title = ({title, className}: {
  title: string, className?: {
    root?: string
    title?: string
    row?: string
    col?: string
  }
}) => <div className={cn(styles.title, className?.title)}>{title}</div>;

export const TrafficTable = ({trafficList, isLoading, className, setSelect}: Props): JSX.Element => {
  const columns = useColumns();
  const {rowsToBlink} = useBlinkRow();
  const [selected, setSelected] = useState<TrafficWithDate | undefined>(undefined)

  const handleRowClick = (row: TrafficWithDate) => {
    if (setSelect) {
      setSelect(row)
    }
    setSelected(row)
  };

  const sortedByDate: TrafficWithDate[] = useMemo(() => {
    const trafficWithDate = trafficList.map((traffic) => ({
      ...traffic,
      groupKeyDate: formatDate(traffic.date, "full"),
    }));

    return sortInObject(trafficWithDate, "date");
  }, [trafficList]);

  return (
    <div className={cn(styles.wrapper, className?.root)}>
      {((trafficList?.length > 0 || isLoading) && (
        <Table<TrafficWithDate>
          idKey="id"
          groupKey="groupKeyDate"
          transformGroupTitle={(title) => <Title title={title} className={className}/>}
          colClass={cn(styles.col, className?.col)}
          rowClass={cn(styles.row, className?.row)}
          selectedRowId={selected?.id}
          data={sortedByDate}
          columns={columns}
          onRowClick={handleRowClick}
          loading={{isLoading, rowsCount: 5}}
          blinkRows={rowsToBlink}
        />
      )) || <div className={styles.empty}>Похожего трафика не найдено</div>}
    </div>
  );
};
