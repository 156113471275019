import { formatRelative } from "date-fns";
import { ru } from "date-fns/locale";

import { formatPhone } from "../../../../utils/formatters";
import { Office } from "../../../../types";

import styles from "./index.module.scss";

export const usersColumns = (offices: Office[], onUserClick: (accountId: string, userId: string) => void) => [
	{
		Header: "ФИО",
		accessor: "account.lastName",
		sortable: true,
		customTemplate: ({ value, row }: any) => (
			<button
				onClick={() => {
					onUserClick(row.original.account.id, row.original.id);
				}}
				type="button"
				className={styles.userInfo}
			>
				<span className={styles.lastName}>{value || "-"}</span>
				<span className={styles.firstName}>
					{`${row.original.account.name || ""} ${row.original.account?.secondName || ""}`}
				</span>
			</button>
		),
	},
	{
		Header: "Должность",
		accessor: "position.name",
		sortable: true,
		customTemplate: ({ value }: any) => <div className={styles.defaultRow}>{value}</div>,
	},
	{
		Header: "Офис / Отдел",
		accessor: "company.id",
		sortable: true,
		customTemplate: ({ value, row }: any) => {
			const neededOffices = offices ? offices.filter((office) => office.companyId === value) : [];
			let found;

			for (let i = 0; i < neededOffices.length; i += 1) {
				const curOffice = neededOffices[i];
				found = curOffice.departments?.find((department) => department.id === row.original.department.id);
				if (found) break;
			}

			return (
				<div className={styles.defaultRow}>
					{Boolean(neededOffices.length) && `${neededOffices?.[0]?.name} / ${found?.name || ""}`}
				</div>
			);
		},
	},
	{
		Header: "Телефон",
		accessor: "account.phone",
		sortable: true,
		customTemplate: ({ value }: any) => <div className={styles.phone}>{formatPhone(value)}</div>,
	},
	{
		Header: "Дата посл. действия",
		accessor: "lastActionDate",
		sortable: true,
		customTemplate: ({ value }: any) => (
			<span className={styles.date}>{formatRelative(new Date(value), new Date(), { locale: ru })}</span>
		),
	},
];
