import { ReactNode } from "react";
import { UseFormRegister } from "react-hook-form";

import { SelectOption } from "../../../../../../types";

type Props = {
	name: string;
	disabled?: boolean;
	required?: boolean;
	multiple?: boolean;
	register: UseFormRegister<any>;
	options: SelectOption[];
	children?: ReactNode;
};

export const NativeSelect = ({
	name,
	disabled,
	required,
	multiple,
	register,
	options,
	children,
}: Props): JSX.Element => (
	<select
		id={name}
		disabled={disabled}
		multiple={multiple}
		style={{ display: "none" }}
		{...register(name, { required: required ? "Обязательное поле" : false })}
	>
		{children ??
			options.map((item, index) => (
				// eslint-disable-next-line react/no-array-index-key
				<option key={`${item.value}-${index}`} value={item.value}>
					{item.label}
				</option>
			))}
	</select>
);
