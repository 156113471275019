import { Task, TaskVirtualStatus } from "../../../../../../../../../../types";
import { useMemo } from "react";

import { groupTasks } from "./groupTasks";

const StatusPrioritiesEnum: Record<TaskVirtualStatus, number> = Object.freeze({
	paused: 4,
	checking: 1,
	failed: 2,
	success: 3,
	inWork: 0,
	hot: 5,
	missed: 6,
});

/**
 * Get memoized array of grouped tasks, sorted by priority. Group is represented as array with
 *  group name (0)
 *  and group-related tasks (1)
 *
 * Return must be an array because not all browsers persist the items order in Javascript objects,
 *  as opposed to arrays
 */
export const useGroupedTasks = (tasks: Task[] = []) =>
	useMemo(
		() =>
			Object.entries(groupTasks(tasks)).sort(
				([groupLeft], [groupRight]) => StatusPrioritiesEnum[groupRight] - StatusPrioritiesEnum[groupLeft]
			),
		[tasks]
	);
