import { useDispatch } from "react-redux";
import { openModal } from "../../../service/redux/modules/modal/modal.action";
import { AddToCollection } from "./add-to-collection/AddToCollection";
import styles from "./index.module.scss"
import { RealEstate } from "../../../types";
import { selectDevice, useSelect } from "../../../service/redux/selectors";

type Props = {
  objectData: RealEstate
}

type Return = {
  openAddToCollectionModal: () => void
}

type UseAddToCollectionModal = (props: Props) => Return

export const useAddToCollectionModal: UseAddToCollectionModal = (
  {
    objectData,
  }: Props
) => {

  const dispatch = useDispatch()
  const {isMobile} = useSelect(selectDevice)

  const openAddToCollectionModal = () => {
    dispatch(
      openModal(
        {
          width: 800,
          body: <AddToCollection objectData={objectData} isMobile={isMobile}/>,
          classNames: {
            contentBody: styles.contentBody,
            contentWrapper: isMobile ? styles.modal : "",
            buttonClose: isMobile ? styles.modalClose : ""
          },
        }
      )
    )
  }
  return {
    openAddToCollectionModal
  }
}