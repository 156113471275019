import { useDispatch } from "react-redux";
import { openDrawer } from "../../../../redux/modules/drawer/drawer.action";
import { ContactInfo } from "../../../../../components/pages/ContactCard";

type OpenContact = {
  id: string
  refetchList: () => void
}

export const useOpenContact = () => {
  const dispatch = useDispatch()

  const openContact = (
    {
      id,
      refetchList
    }: OpenContact) => {

    const contactNav = {
      label: "Контакт",
      height: 160,
      title: "contact",
      query: {
        name: "contactId",
        value: id
      }
    }

    dispatch(
      openDrawer({
        children: [<ContactInfo contactId={id} refetchList={refetchList}/>],
        width: 800,
        yNavigation: {
          initialTab: 0,
          navigation: [contactNav],
        },
      })
    );
  }

  return {
    openContact
  }
}