import { useMutation } from "react-query";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/shows-views/new-date/DEFAULT_MN";

type UseNewDate = {
	onSuccess?: (data: any) => void;
	isViews?: boolean
};

export const useNewDate = ({ onSuccess, isViews }: UseNewDate) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (data: { id: string; plannedDate: Date }) => isViews ? api.showsViews.newDateView(data) : api.showsViews.newDateShow(data),
		onSuccess,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
