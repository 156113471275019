import { useDispatch } from "react-redux";
import { Button, InfoRow } from "@sdelka_crm/sdelka-crm-component-library";
import { closeModal } from "../../../../../../../service/redux/modules/modal/modal.action";
import cn from "classnames";
import { CalendarInPopup, Time, toastError, toastSuccess } from "../../../../../../UI";
import { useForm, useWatch } from "react-hook-form";
import { useState } from "react";
import { UseMutateFunction } from "react-query";
import { AxiosResponse } from "axios";
import styles from "./index.module.scss"
import { formatDateWithTime } from "../../../../../../../utils/date";

type Props = {
  onChange: UseMutateFunction<AxiosResponse<any>, unknown, { id: string, plannedDate: Date }, unknown>
  id: string
  onSuccess: () => void;
  isViews?: boolean
  plannedDate: Date
}

export const ChangeDateModal = (
  {
    onChange,
    id,
    onSuccess,
    isViews,
    plannedDate
  }: Props): JSX.Element => {
  const createText = "Изменить"
  const changeTitle = isViews ? "Изменить дату просмотра" : "Изменить дату показа"
  const dispatch = useDispatch()
  const {register, getValues, setValue, control} = useForm();

  function timeToMilliseconds(timeStr?: string) {
    if (timeStr === undefined) {
      return 0
    }
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours * 60 * 60 * 1000 + minutes * 60 * 1000;
  }

  const dateName = "deadlineDate";
  const currentDate = useWatch({
    name: dateName,
    control,
    defaultValue: plannedDate || getValues(dateName),
  });

  const [time, setTime] = useState(String(formatDateWithTime(plannedDate, true, true)))

  const timeError = +time < 60000 ? "Выберите время" : undefined

  const onApply = () => {
    const date1 = new Date(plannedDate).getTime() - timeToMilliseconds(formatDateWithTime(plannedDate, true, true))
    const date2 = new Date(timeToMilliseconds(time));
    const sumMilliseconds = date1 + date2.getTime();
    const sumDate = new Date(sumMilliseconds);

    const payload = {
      id,
      plannedDate: sumDate
    }

    onChange(payload)
    toastSuccess({text: `Вы успешно изменили дату`})
    setTimeout(() => {
      onSuccess()
    }, 500)

    setTime("")

    dispatch(closeModal())
  }
  const onDeny = () => {
    toastError({text: `Вы не изменили дату`})
    dispatch(closeModal())
  }

  const timeName = "deadlineTime";
  const currentTime = useWatch({
    name: timeName,
    control,
    defaultValue: time || getValues(timeName),
  });

  const dateError = !currentDate ? "Выберите дату" : undefined

  return (
    <>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.title}>
            {changeTitle}
          </div>
          <div className={styles.text}>
            {`Выберите дату ${isViews ? "просмотра" : "показа"}, после этого вы сможете добавить контакт`}
          </div>
        </div>
      </div>
      <InfoRow
        classNames={{
          root: cn(styles.infoRow, styles.calendarChild),
          content: styles.flexRow,
        }}
        labelFont="ubuntu"
        label="Дедлайн"
      >
        <CalendarInPopup
          form={{name: dateName, register, setValue, getValues, required: true}}
          defaultDate={currentDate}
          classNames={{
            wrapper: styles.calendar,
            label: styles.calendarLabel,
            trigger: styles.popupTrigger,
          }}
          error={dateError}
        />
        <Time
          classNames={{
            root: styles.timeRoot,
            wrapper: styles.timeWrapper,
            error: styles.timeError
          }}
          variant="noLabel"
          name={timeName}
          onChange={(value) => setTime(value)}
          register={register}
          value={currentTime}
          error={timeError}
        />
      </InfoRow>
      <div className={styles.rowEnd}>
        <Button onClick={onDeny} variant="outlined">
          Отмена
        </Button>
        <Button
          onClick={onApply}
          classNames={{root: styles.buttonCreate}}
          disabled={dateError !== undefined || timeError !== undefined}
        >
          {`${createText}`}
        </Button>
      </div>
    </>
  )
}