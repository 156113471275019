import {
	ChartData,
	GroupByDetalizationParams,
	KindDetalizationParams,
	PeriodDatesType,
	PlatformDetailsData,
} from "../../../../types";
import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/real-estate-ad/get-platform-details/DEFAULT_QN";

type Props = {
	applicationId: string;
	feedId: string;
	groupBy?: GroupByDetalizationParams;
	kind?: KindDetalizationParams;
	enabled?: boolean;
	periodDates: PeriodDatesType;
};

type Return = {
	data?: ChartData;
	isLoading: boolean;
	refetch: () => void;
	remove: () => void;
	isFetched: boolean;
	isRefetching: boolean;
};

type UseGetPlatformDetails = (props: Props) => Return;

export const useGetChartData: UseGetPlatformDetails = ({
	applicationId,
	feedId,
	groupBy = GroupByDetalizationParams.MONTH,
	kind = KindDetalizationParams.UNIQUE_VIEWS,
	enabled,
	periodDates,
}) => {
	const loadChartData = () =>
		api.realEstateAd.stats({
			applicationId,
			feedId,
			groupBy,
			kind,
			periodDates,
		});

	const { data, refetch, remove, isLoading, isRefetching, isFetched } = useQuery(
		[DEFAULT_QN, kind, groupBy, periodDates],
		loadChartData,
		{
			enabled,
			keepPreviousData: true,
		}
	);

	return {
		data: data?.data,
		isLoading,
		refetch,
		isRefetching,
		remove,
		isFetched,
	};
};
