import { useEffect } from "react";
import { Loader } from "@sdelka_crm/sdelka-crm-component-library";
import { CardObjectHeader, ObjectAdDescriptionForm, ObjectAdGlobalStatistics, } from "../../../../UI";
import { RealEstateAdPlatform, RealEstateAdPlatformsTable } from "./components";
import { useLoadApplicationRealEstate, useLoadPlatforms } from "../../../../../service/api/queries";
import { KindType, RealEstateDescriptionFormValues } from "../../../../../types";
import styles from "./index.module.scss";
import { selectDevice, useSelect } from "../../../../../service/redux/selectors";
import { LayoutLoading } from "../RealEstateViewWithTabs/LayoutLoading";

type Props = {
  id: string;
  applicationId: string
  kind?: KindType
  enabled?: boolean
};

export const RealEstateAd = ({id, kind, applicationId, enabled}: Props): JSX.Element => {
  const {
    data,
    isLoading: isLoadingPlatforms,
    refetch: refetchPlatforms,
    remove,
  } = useLoadPlatforms({applicationId: id});
  const {isMobile} = useSelect(selectDevice)
  const {realEstate, refetch} = useLoadApplicationRealEstate({
    id: applicationId,
    kind,
    enabled
  });


  useEffect(() => {
    refetch();
    refetchPlatforms();

    return () => remove();
  }, [id]);

  return (
    <div className={styles.root}>
      {realEstate && <div className={styles.cardObjectHeader}>
        <CardObjectHeader clientObject={realEstate} isMobile={isMobile}/>
      </div>}
      {data
        ? <>
          {data && <div className={styles.content}>
            {!isMobile && <div id="ad_common">
              <ObjectAdGlobalStatistics title="Размещения на площадках" applicationId={id}/>
            </div>}
            <div className={styles.adDescription}>
              {realEstate &&
                <ObjectAdDescriptionForm
                  realEstate={realEstate as RealEstateDescriptionFormValues}
                  refetch={refetch}
                  applicationId={id}
                />}
            </div>
            {isLoadingPlatforms ? (
              <Loader height={200}/>
            ) : (
              data?.data.map((platform) => (
                <div id={`ad_${platform.name}`} key={`${platform.id}_${platform.name}`}>
                  <RealEstateAdPlatform
                    applicationId={id}
                    platform={platform}
                    refetchPlatforms={refetchPlatforms}
                    isMobile={isMobile}
                  />
                </div>
              ))
            )}
            <div id="ad_free" className={styles.block}>
              <RealEstateAdPlatformsTable applicationId={id} title="Бесплатные площадки" type="freeTable" isOpen/>
            </div>
            <div id="ad_non_free" className={styles.block}>
              <RealEstateAdPlatformsTable applicationId={id} title="Платные площадки" type="paidTable"/>
            </div>
          </div>}
        </>
        : <LayoutLoading/>
      }
    </div>
  );
};
