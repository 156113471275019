import { useMemo } from "react";

import { User } from "../../../../../../types";
import { useLoadOffices } from "../../../../../../service/api/queries";

type Props = {
	currentAccountId?: string;
	currentAccountPositionName?: string;
};

export const useShowAccountSwitcher = ({ currentAccountId, currentAccountPositionName }: Props) => {
	const { offices } = useLoadOffices({});

	const isMe = (user: User) => user.id === currentAccountId;

	const isChief = currentAccountPositionName?.toLowerCase().startsWith("руководитель");

	// Every user of every department of every office is me
	const isSinglePersonInOffice = useMemo(
		() => offices.every((office) => office.departments.every((department) => department.users?.every(isMe))),
		[offices, currentAccountId]
	);

	return isChief && !isSinglePersonInOffice;
};
