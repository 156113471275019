export type DashboardTableResponse = {
  structure: {
    preset: string
    columns: DashboardTableResponseColumn[]
  }
  data: DashboardTableRow[]
  totals: DashboardTableRow
}

export type DashboardTableResponseColumn = {
  header: string,
  name: string,
  isHiddenByUser?: boolean,
  isPinned?: boolean,
  type: DashboardTableColumnTypeEnum
}

export type DashboardTableValue = DashboardTableValueNumber | DashboardTableValueString

export enum DashboardTableColumnTypeEnum {
  number = 'number',
  string = 'string',
}

export type DashboardTableRow = {
  [columnName: string]: DashboardTableValue
}

export type DashboardTableValueString = { text: string }

export type DashboardTableValueNumber = {
  color?: string
  mainValue: number
  fullValue?: number
}


