import React from "react";
import { ErrorsAndWarnings } from "../../../../../../../../../../../types";
import { StartAdErrors } from "../StartAdError";
import styles from "./index.module.scss";

type Props = {
	messages: ErrorsAndWarnings;
};

export const PostedErrors: React.FC<Props> = ({ messages }) => (
		<div className={styles.container}>
			<StartAdErrors
				messages={messages}
				errorsTitle={`Ошибк${messages?.errors?.length > 1 ? "и" : "а"} при изменениях`}
			/>
		</div>
	);
