import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { Col, Container, GroupedSelect, Row, Select, TextField } from "../../../../../UI";
import { useInviteSend } from "../../../../../../service/api/mutations";
import { phoneMask } from "../../../../../../utils/masks";
import { getFlag } from "../../../../../../utils/getFlag";
import { commonErrors } from "../../../../../../utils/errors";
import { IMultiSelect, ISelect } from "../../../../../../types";

import styles from "./index.module.scss";

type InviteCreate = {
	phone: string;
	departmentId: string;
	positionId: string;
};

type Props = {
	positionOptions: ISelect[];
	officeOptions: IMultiSelect[];
	setInviteState: Dispatch<SetStateAction<boolean>>;
	refetchInvites: () => void;
};

export const PersonalInviteForm = ({
	positionOptions,
	officeOptions,
	setInviteState,
	refetchInvites,
}: Props): JSX.Element => {
	const [flagIcon, setFlagIcon] = useState<ReactNode | undefined>(undefined);

	const schema = yup.object().shape({
		phone: yup
			.string()
			.test("phone", commonErrors.required, (phone) => phone?.replace(/\D/gi, "").length === 11)
			.required(),
		departmentId: yup.string().required(commonErrors.required),
		positionId: yup.string().required(commonErrors.required),
	});

	const { control, handleSubmit, setError, reset, formState, register, setValue } = useForm<InviteCreate>({
		resolver: yupResolver(schema),
	});

	const { mutate } = useInviteSend({
		onSuccess: () => {
			refetchInvites();
			reset();
			setInviteState((prev) => !prev);
		},
		setError,
	});

	const onSubmit = (data: InviteCreate) => mutate(data);

	const getFlagIcon = (phone: string) => {
		setFlagIcon(<div className={styles.flagIconContainer}>{getFlag(phone)}</div>);
	};

	return (
		<div className={styles.root}>
			<span className={styles.title}>Персональное приглашение</span>

			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.fieldsContainer}>
					<Container>
						<Row marginBottom={20}>
							<Col xs={12}>
								<TextField
									label="Телефон"
									variant="small"
									mask={phoneMask}
									name="phone"
									control={control}
									error={formState.errors?.phone?.message?.toString()}
									onChange={getFlagIcon}
									append={flagIcon}
								/>
							</Col>
						</Row>

						<Row marginBottom={20}>
							<Col xs={12}>
								<Select
									name="positionId"
									withBorder
									options={positionOptions}
									placeholder="Должность"
									register={register}
									setValue={setValue}
									error={formState.errors?.positionId?.message?.toString()}
								/>
							</Col>
						</Row>

						<Row>
							<Col xs={12}>
								<GroupedSelect
									name="departmentId"
									isSearchable
									withBorder
									groups={officeOptions}
									register={register}
									setValue={setValue}
									placeholder="Отдел"
									error={formState.errors?.departmentId?.message?.toString()}
								/>
							</Col>
						</Row>
					</Container>
				</div>

				<Button type="submit" fullWidth>
					Отправить
				</Button>
			</form>
		</div>
	);
};
