import styles from "./index.module.scss"
import { AddressSelect, InfoRow } from "../../../UI";
import * as yup from "yup";
import { commonErrors } from "../../../../utils/errors";
import { useForm } from "react-hook-form";
import { JoinToSdelkaProUserPayload } from "../../../../types";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useTimer } from "../../../../utils/hooks";
import { useEffect } from "react";

type Props = {
  onChangeAddress: (val: string) => void
  onChangePoint?: (val?: [number, number]) => void
}

export const SelectOfficeAddress = (
  {
    onChangeAddress,
    onChangePoint
  }: Props) => {
  const [timer, handleTimer] = useTimer(10);

  const schema = yup.object().shape({
    address: yup.object().shape({
      value: yup.string().required(commonErrors.required),
    }),
  });
  const {setValue, formState, register, watch} = useForm<JoinToSdelkaProUserPayload>({
    resolver: yupResolver(schema),
    defaultValues: {
      address: {
        label: "",
        value: ''
      }
    }
  })

  const [watchAddress] = watch(["address"])

  useEffect(() => {
    handleTimer();
  }, []);

  return (
    <div className={styles.root}>
      <InfoRow label="Город" classNames={{root: styles.infoRow}}>
        <AddressSelect
          classNames={{selected: `${styles.addressSelect}`, error: styles.error}}
          name="address"
          register={register}
          setValue={setValue}
          error={formState.errors.address?.value?.message?.toString()}
          value={watchAddress}
          onChange={onChangeAddress}
          withBorder
          autoDetect
          timer={timer}
          onChangePoint={onChangePoint}
        />
      </InfoRow>
    </div>
  )
}