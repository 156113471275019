import cn from "classnames";
import styles from "./index.module.scss";
import { RealEstate } from "../../../../types";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { CardObjectHeader } from "../CardObjectHeader";
import { ReactComponent as PhotoIcon } from "../../../../assets/icons/photo-camera.svg";

type Props = {
	clientObject?: RealEstate;
	onAddObject?: () => void;
	textIfNoObject?: string;
	isMobile?: boolean
};

export const CardObjectExistOrNot = ({
	clientObject,
	onAddObject,
	textIfNoObject = "В заявку не добавлен объект",
	isMobile
}: Props): JSX.Element => {
	const handleAddObject = () => {
		if (onAddObject) {
			onAddObject();
		}
	};

	return (
		<div className={cn(styles.root, { [styles.rootWithObject]: clientObject })}>
			<div className={styles.content}>
				{!clientObject ? (
					<div className={styles.main}>
						<div className={styles.noImg}>
							<PhotoIcon />
						</div>
						<div className={styles.contentNoObject}>
							<span className={styles.contentNoObjectLabel}>{textIfNoObject}</span>
							<Button variant="text" onClick={handleAddObject}>
								Добавить объект
							</Button>
						</div>
					</div>
				) : (
					<CardObjectHeader clientObject={clientObject} isMobile={isMobile}/>
				)}
			</div>
		</div>
	);
};
