import { ReactNode } from "react";
import cn from "classnames";

import styles from "./index.module.scss";

type Props = {
	menuComponents: Array<ReactNode>;
	children?: ReactNode;
	active: number;
	onClick?: (index: number) => void;
};

export const SideMenu = ({ menuComponents, children, active = 0, onClick }: Props): JSX.Element => (
	<div className={styles.root}>
		<div className={styles.sideMenu}>
			{menuComponents.map((MenuItem, index) => (
				<div
					// eslint-disable-next-line react/no-array-index-key
					key={index}
					onClick={() => onClick && onClick(index)}
					className={cn(styles.sideMenuItem, {
						[styles.active]: active === index,
					})}
				>
					{MenuItem}
				</div>
			))}
		</div>

		<div className={styles.children}>{children}</div>
	</div>
);
