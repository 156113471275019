import { Client } from "../../Client";
import { Compare, PublicApplications, PublicResponsible, RealEstate } from "../../../../types";

const PREFIX_URL = "public-link";

export const PublicService = {
	applications: (contactId: string) =>
		Client.get<PublicApplications[]>(`/${PREFIX_URL}/${contactId}/applications`),
	objects: (contactId: string) => Client.get<RealEstate[]>(`${PREFIX_URL}/${contactId}/objects`),
	agents: (contactId: string) => Client.get<PublicResponsible[]>(`${PREFIX_URL}/${contactId}/responsible`),
	compare: (contactId: string, objectId: string) =>
		Client.get<Compare>(`${PREFIX_URL}/${contactId}/object/${objectId}/compare`),
};
