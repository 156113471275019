import { useQuery } from "react-query";

import { api } from "../../index";

export const useLoadObjectSignatures = () => {
	const loadObjectSignatures = () => api.objectSignature.list();

	const { data, isLoading } = useQuery(["loadObjectSignatures"], loadObjectSignatures);

	return {
		objectSignaturesList: data?.data || [],
		isLoading,
	};
};
