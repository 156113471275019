import { Client } from "../../Client";
import { getRequestFilters } from "../../helpers";
import {
	ApplicationListPayload,
	ApplicationListResponse,
	Conditions,
	AddResponsibleToApplication,
	ApplicationUpdatePayload,
	Application,
	AddLeadToApplication,
	ApplicationUserResponse,
	ChangeStagePayload,
	ChangeStageResponse,
	WhoIsClient,
	CreateApplication,
	Bank,
	Contact,
	FinishApplicationPayload,
	AgencyAgrementType,
	AgencyAgrementPayloadType,
} from "../../../../types";

export const PreApplicationService = {
	create: ({ leadId, businessProcessId }: { leadId: string; businessProcessId?: string }) =>
		Client.post<Contact[]>(`http://localhost:3333/sdelka/pre-application/`, { leadId, businessProcessId }),
	getCardBlocks: ({ applicationId, stage }) =>
		Client.get(`/business-process/pre-application/${applicationId}/blocks/${stage}`),
	updateInfo: ({ applicationId, applicationData }: ApplicationUpdatePayload) =>
		Client.put(`/pre-application/${applicationId}`, applicationData),
	deleteContact: ({ applicationId, id }: { applicationId?: string; id: string }) =>
		Client.delete(`/pre-application/contacts/${applicationId}/contact/${id}`),
	updateContact: ({ applicationId, id, type }: { applicationId: string; id: string; type: WhoIsClient }) =>
		Client.post(`/pre-application/contacts/${applicationId}/contact/${id}`),
	setContactFromParentLead: ({ applicationId, id }: { applicationId: string, id: string }) =>
		Client.put(`/pre-application/contacts/${applicationId}/contact/${id}`),
	contacts: ({ applicationId, type }: { applicationId: string; type?: WhoIsClient }) =>
		Client.get<Contact[]>(`/pre-application/contacts/${applicationId}/contacts`),

	// агентское соглашение
	getAgencyAgrement: ({ applicationId }) => Client.get<AgencyAgrementType>(`/agent-agreement/${applicationId}`),

	postAgencyAgrement: ({ applicationId, data }: { applicationId: string, data: AgencyAgrementPayloadType }) => Client.post<AgencyAgrementType>(`/agent-agreement/${applicationId}`, data),
	putAgencyAgrement: ({ applicationId, data }: { applicationId: string, data: AgencyAgrementPayloadType }) => Client.put<AgencyAgrementType>(`/agent-agreement/${applicationId}`, data),
	deleteAgencyAgrement: (applicationId) => Client.delete(`/agent-agreement/${applicationId}`),

	changeStage: ({ applicationId, stageName }: ChangeStagePayload) =>
		Client.patch<ChangeStageResponse>(`/business-process/pre-application/${applicationId}/stage/${stageName}`),

	getClient: ({ applicationId }) => Client.get(`/application/${applicationId}/whoIsClient`),
	getCard: ({ applicationId }) => Client.get<Application>(`/application/${applicationId}`),

	// /pre-application/contacts/{preapplicationId}/contacts

	employee: ({ applicationId }) =>
		Client.get<ApplicationUserResponse[]>(`application/${applicationId}/employee`),

	// create: ({
	// 	additionalProperties,
	// 	typeId,
	// 	responsibleUserId,
	// 	comment,
	// 	sellerContacts,
	// 	buyerContacts,
	// 	clientObjectId,
	// }: CreateApplication) =>
	// 	Client.post("/application", {
	// 		additionalProperties,
	// 		typeId,
	// 		responsibleUserId,
	// 		comment,
	// 		sellerContacts,
	// 		buyerContacts,
	// 		clientObjectId,
	// 	}),
	createFromLead: ({ leadId }: { leadId: string }) => Client.post(`application/fromLead/${leadId}`),

	deleteLead: ({ applicationId, leadId }: AddLeadToApplication) =>
		Client.delete(`/application/relations-to-leads/${leadId}/${applicationId}`),
	addEmployee: ({ applicationId, responsibleUsers }: AddResponsibleToApplication) =>
		Client.put(`/application/${applicationId}`, { responsibleUsers }),

	deleteResponsible: ({ applicationId, id }: { applicationId: string; id: string }) =>
		Client.delete(`/application/${applicationId}/responsible/${id}`),

	list: ({ take, skip, search, value }: ApplicationListPayload) => {
		const searchConditions: Conditions = search ? getRequestFilters(search) : [];

		return Client.post<ApplicationListResponse>(
			`/${value}/search`,
			JSON.stringify({
				search: {
					filterType: "and",
					conditions: searchConditions,
				},
				take,
				skip,
			})
		);
	},

	object: ({ applicationId }: { applicationId: string }) =>
		Client.get(`/application/${applicationId}/object`),

	remoteBanks: (query: string) => Client.get<Bank[]>(`/application/remote-selection/banks?query=${query}`),

	getBank: (id: string) => Client.get<Bank>(`/application/remote-selection/bank/${id}`),

	createTasks: ({ applicationId, payload }: { applicationId: string; payload: any }) =>
		Client.post(`application/${applicationId}/fastCreateTasks`, payload),

	nextStage: (id: string) => Client.patch(`/application/${id}/nextStage`),

	finish: ({ applicationId, action }: FinishApplicationPayload) =>
		Client.post(`application/${applicationId}/${action}`),
};