import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import isEqual from "lodash.isequal";
import cn from "classnames";
import { ButtonSquare, Checkbox, MultiLayerSelect, MultiSelect, RoundInput } from "../../../../UI";
import { RequestFiltersModal } from "../RequestFiltersModal";
import { useLoadBusinessProcessSources } from "../../../../../service/api/queries";
import { closeDrawer, openDrawer } from "../../../../../service/redux/modules/drawer/drawer.action";
import { DEFAULT_TRAFFIC } from "../../../../../service/api/helpers";
import { useResponsibleUsers, useRopeTemplatesOneLevel, useWindowWidth } from "../../../../../utils/hooks";
import { selectBusinessProcesses, useSelect } from "../../../../../service/redux/selectors";
import { useWatchers } from "./helpers";
import { ApplicationsFilter, KindType, SelectOption, Stage } from "../../../../../types";
import { ReactComponent as CancelIcon } from "../../../../../assets/icons/cancel.svg";
import { ReactComponent as Filters } from "../../../../../assets/icons/filters.svg";
import styles from "./index.module.scss";

const requestAgencyAgreementFormOptions = [
  {value: "ORAL", label: "Устная"},
  {value: "ONLINE", label: "Онлайн"},
  {value: "OFFLINE", label: "Оффлайн"},
  {value: "UNSIGNED", label: "Без договора"},
];

const requestStatusOptions = [
  {value: "inWork", label: "В работе"},
  {value: "success", label: "Успешная"},
  {value: "refusal", label: "Отказ"},
  {value: "onDeal", label: "На сделке"},
]

const dealsStatusOptions = [
  {value: "IN_WORK", label: "Активные"},
  {value: "SUCCESSFULLY", label: "Успешные"},
  {value: "UNSUCCESSFUL", label: "Сорванные"},
]

const requestKindOptions = [
  {value: KindType.PREAPPLICATION, label: "Предзаявки"},
  {value: KindType.APPLICATION, label: "Заявки"},
]

type Props = {
  formSubmit: (values: ApplicationsFilter) => void;
  filter: ApplicationsFilter;
  setRequestStages: Dispatch<SetStateAction<Stage[]>>;
  requestStages: Stage[];
  kind?: KindType
};

export const RequestFastFilters = (
  {
    formSubmit,
    setRequestStages,
    requestStages,
    filter,
    kind
  }: Props): JSX.Element => {
  const {register, setValue, control, handleSubmit, getValues, watch, reset} = useForm({
    defaultValues: DEFAULT_TRAFFIC,
  });

  const {watcherResponsibleUsers, watcherTypeId} = useWatchers({
    control,
    getValues,
  });
  const [expanded, setExpanded] = useState(false);

  const width = useWindowWidth();

  useEffect(() => {
    if (width > 1600 && !expanded) {
      setExpanded(true);
    } else if (expanded) {
      setExpanded(false);
    }
  }, [width]);
  const [showBtn, setShowBtn] = useState(false);

  const formWatcher = watch();

  useEffect(() => {
    if (!isEqual(formWatcher, filter)) {
      if (!showBtn) {
        setShowBtn(true);
      }
    } else {
      setShowBtn(false);
    }
  }, [Object.values(formWatcher)]);

  useEffect(() => {
    if (filter) {
      reset(filter as any);
    }
  }, [filter]);

  const dispatch = useDispatch();
  const [showViews, setShowViews] = useState(false);
  const [, setRequestStageOptions] = useState<SelectOption[]>([]);

  const {businessProcesses} = useSelect(selectBusinessProcesses);

  const {users, responsibleUsers} = useResponsibleUsers();
  const {preparedTemplates} = useRopeTemplatesOneLevel()

  const {data: sourcesList} = useLoadBusinessProcessSources();
  const sourcesOptions = useMemo(
    () =>
      sourcesList.map((source) => ({
        value: source,
        label: source,
      })),
    [sourcesList]
  );

  useEffect(() => {
    if (watcherTypeId?.length === 1 && watcherTypeId) {
      const foundedBusinessProcess = businessProcesses.find((business) => business.id === watcherTypeId[ 0 ]);
      setRequestStages(
        foundedBusinessProcess?.applicationStages ? foundedBusinessProcess.applicationStages : []
      );
    } else {
      setRequestStageOptions([]);
      setRequestStages([]);
      if (getValues()?.stage?.length !== 0) {
        setValue("stage", []);
      }
    }
  }, [formWatcher.typeId, businessProcesses]);

  useEffect(() => {
    const requestOption: SelectOption[] = requestStages.map((stage) => ({
      value: String(stage.order),
      label: stage.name,
    }));

    setRequestStageOptions(requestOption);
  }, [requestStages]);

  const prepareSubmitForm = (values) => {
    reset(values);
    formSubmit(values);
    setShowBtn(false);
  };

  const submittedFromModal = (values) => {
    dispatch(closeDrawer());
    prepareSubmitForm({
      ...values,
      fullTextSearch: formWatcher.fullTextSearch,
      typeId: formWatcher.typeId,
      responsibleUsers: formWatcher.responsibleUsers,
      signed: formWatcher.signed,
    });
  };

  const onOpenRequests = () => {
    const curShowViews = !showViews;
    setShowViews(curShowViews);
    dispatch(
      openDrawer({
        children: (
          <RequestFiltersModal
            defaultValues={getValues()}
            users={users}
            sourcesOptions={sourcesOptions}
            submitFilter={submittedFromModal}
          />
        ),
        width: 500,
      })
    );
  };

  const resetFilter = () => {
    formSubmit({...DEFAULT_TRAFFIC});
    reset({...DEFAULT_TRAFFIC} as any);
    setShowBtn(false);
  };

  return (
    <form
      className={cn(styles.root, {
        [ styles.expanded ]: expanded,
      })}
      onSubmit={handleSubmit(prepareSubmitForm)}
    >
      <div className={styles.firstLine}>
        <div onClick={onOpenRequests} className={styles.moreWrapper}>
          <Filters/>
        </div>
        {kind === KindType.DEAL &&
          <>
            <div className={cn(styles.filterWrapper, styles.responsibleWrapper)}>
              <MultiLayerSelect
                name="responsibleUsers"
                options={responsibleUsers || []}
                register={register}
                placeholder="Ответственный"
                setValue={setValue}
                isSearchable
                selectOne={false}
                defaultValue={watcherResponsibleUsers}
                showLastCheckbox
              />
            </div>
            <div className={cn(styles.filterWrapper, styles.statusWrapper)}>
              <MultiSelect
                name="status"
                setValue={setValue}
                options={dealsStatusOptions}
                register={register}
                placeholder="Статус"
                defaultValue={[dealsStatusOptions[ 0 ].value]}
              />
            </div>
          </>
        }
        {kind !== KindType.DEAL &&
          <>
            <div className={cn(styles.filterWrapper, styles.typeWrapper)}>
              <MultiSelect
                name="kind"
                setValue={setValue}
                options={requestKindOptions}
                register={register}
                placeholder="Тип"
                defaultValue={formWatcher.kind}
              />
            </div>
            <div className={cn(styles.filterWrapper, styles.responsibleWrapper)}>
              <MultiSelect
                name="ropeTemplate"
                options={preparedTemplates}
                register={register}
                placeholder="Шаблон воронки"
                setValue={setValue}
                defaultValue={formWatcher.ropeTemplate}
              />
            </div>
            <div className={cn(styles.filterWrapper, styles.statusWrapper)}>
              <MultiSelect
                name="agencyAgreementForm"
                setValue={setValue}
                options={requestAgencyAgreementFormOptions}
                register={register}
                placeholder="Форма соглашения"
                defaultValue={formWatcher.agencyAgreementForm}
              />
            </div>

            <div className={cn(styles.filterWrapper, styles.statusWrapper)}>
              <MultiSelect
                name="status"
                setValue={setValue}
                options={requestStatusOptions}
                register={register}
                placeholder="Статус"
                defaultValue={formWatcher.status}
              />
            </div>
            <div className={cn(styles.filterWrapper, styles.responsibleWrapper)}>
              <MultiLayerSelect
                name="responsibleUsers"
                options={responsibleUsers || []}
                register={register}
                placeholder="Ответственный"
                setValue={setValue}
                isSearchable
                selectOne={false}
                defaultValue={watcherResponsibleUsers}
                showLastCheckbox
              />
            </div>
            <div className={cn(styles.filterWrapper, styles.searchWrapper)}>
              <RoundInput register={register} name="fullTextSearch" prepend="search" placeholder="Поиск"/>
            </div>
          </>
        }
      </div>
      <div className={styles.secondLine}>
        <div className={styles.filtersWrapper}>
          {kind !== KindType.DEAL &&
            <div className={cn(styles.filterWrapper, styles.signedWrapper)}>
              <Checkbox
                control={control}
                name="withTaskForToday"
                label="С задачей на сегодня"
                variant="noOpacity"
                weight="bold"
              />
            </div>}
          <div className={styles.resetBtn} onClick={resetFilter}>
            <div>Сбросить</div>
            <CancelIcon/>
          </div>
          {showBtn && (
            <div className={styles.submitWrapper}>
              <ButtonSquare type="submit">Показать</ButtonSquare>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};
