import { Route, Switch } from "react-router-dom";
import { AuthGuard } from "./AuthGuard";
import { InvitePage, LoginPage, ResetPassword, } from "../../components/pages/Authentication";
import { RegistrationPageV2 } from "components/pages/Authentication/RegistrationPageV2";
import { RealEstateList } from "../../components/pages/RealEstateList";
import { CreateRealEstatePage, RealEstatePage, } from "../../components/pages/RealEstatePage";
import { EmployeesList } from "../../components/pages/EmployeesList";
import { LeadList } from "../../components/pages/LeadsAndDeals";
import {
  AgentObjectCompilation,
  ApplicationPublic,
  ComparisonPublic,
  MainPublic,
} from "../../components/pages/public-part";
import { Tasks } from "../../components/pages/Tasks";
import { AdvertisingCabinet } from "../../components/pages/AdvertisingCabinet";
import { paths } from "../../utils/paths";
import { Services } from "../../components/pages/Servises";
import { DashboardStatistic } from "../../components/pages/dashbord/DashboardStatistic";
import { SelectOffice } from "../../components/pages/select-office/SelectOffice";
import { selectCurrentAccount, useSelect } from "../redux/selectors";


export const Router = (): JSX.Element => {
  const {currentAccount} = useSelect(selectCurrentAccount)

  return (
    <Switch>
      {/* Unauthenticated Routes */}
      <Route exact path={paths.dashboardPath} component={RealEstateList}/>
      <Route exact path={paths.selectOffice.list} component={SelectOffice}/>
      <Route exact path={paths.indexPath} component={LoginPage}/>
      <Route exact path={paths.loginPath} component={LoginPage}/>
      <Route exact path={paths.registrationPath.default} component={RegistrationPageV2}/>
      <Route exact path={paths.registrationPath.linkInvite} component={InvitePage}/>
      <Route exact path={paths.resetPasswordPath} component={ResetPassword}/>
      <Route exact path={paths.publicPath.publicMain} component={MainPublic}/>
      <Route exact path={paths.publicPath.application} component={ApplicationPublic}/>
      <Route exact path={paths.publicPath.agentCompilation} component={AgentObjectCompilation}/>
      <Route exact path={paths.publicPath.publicComparison} component={ComparisonPublic}/>
      {/* Authenticated Routes */}
      <AuthGuard>
        <Switch>
          <Route exact path={paths.indexPath} component={currentAccount?.isOfficeSelected ? Services : SelectOffice}/>
          <Route exact path={paths.selectOffice.list} component={SelectOffice}/>
          <Route exact path={paths.dashboardStatisticPath.list} component={DashboardStatistic}/>
          <Route exact path={paths.objectsPath.createApplicationObject} component={CreateRealEstatePage}/>
          <Route exact path={paths.objectsPath.list} component={RealEstateList}/>
          <Route exact path={paths.objectsPath.viewApplicationObject} component={RealEstatePage}/>
          <Route exact path={paths.advertisingPath.list} component={AdvertisingCabinet}/>
          <Route exact path={paths.employeesPath.list} component={EmployeesList}/>
          <Route exact path={paths.leadsAndDealsPath.list} component={LeadList}/>
          <Route exact path={paths.tasks.list} component={Tasks}/>
          <Route exact path={paths.tasks.view} component={Tasks}/>
          <Route exact path={paths.services.list} component={Services}/>
        </Switch>
      </AuthGuard>
    </Switch>
  )
};
