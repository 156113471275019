import { ContactPreview } from "../contact";

export type CreateGuaranteePayment = {
  activeFrom: Date
  activeTo: Date
  sum: number
  refundTerms: 'refundable' | 'nonRefundable'
}

export type CreateGuaranteePaymentWithExistsApplication = CreateGuaranteePayment & {
  applicationId: string // ID заявки с которой создается
}
export type CreateGuaranteePaymentWithNewApplication = CreateGuaranteePayment & {
  contactId: string
  leadSource: string
}

export type GuaranteePaymentStatusResponse = {
  activeFrom?: Date
  activeTo?: Date
  sum?: string
  refundTerms?: 'refundable' | 'nonRefundable'
  status: GuaranteePaymentEnum
  contact: ContactPreview
}

export enum GuaranteePaymentEnum {
  active = "active",
  inactive = "inactive"
}

export const GuaranteePaymentStatusMap: Record<GuaranteePaymentEnum, string> = {
  active: "Заключено",
  inactive: "Не заключено"
}
