import { useEffect, useState } from "react";
import { Select } from "../Select";
import { useFindAddressDetailed, useFindPointDetailed } from "../../../../../service/api/mutations";
import { DefaultSelectProps, FindAddressDetailed, LocationType } from "../../../../../types";
import { AxiosResponse } from "axios";

type AddressValue = {
  label: string;
  value: string;
  location?: LocationType;
};

type Props = {
  value: AddressValue;
  label?: string
  autoDetect?: boolean
  onChange?: (value: string) => void
  timer?: number
  onChangePoint?: (val?: [number, number]) => void
} & Omit<DefaultSelectProps, "isSearchable" & "arrowVariant" & "onChange">;

export const AddressSelect = (
  {
    value,
    placeholder = "Введите адрес",
    name,
    register,
    setValue,
    error,
    classNames,
    prepend,
    append,
    size,
    required,
    disabled,
    withBorder,
    label,
    autoDetect,
    onChange,
    timer,
    onChangePoint
  }: Props): JSX.Element => {
  const [addressObject, setAddressObject] = useState<AddressValue>({
    label: value?.label || "",
    value: value?.value || "",
    location: value?.location || undefined,
  });

  const [options, setOptions] = useState<AddressValue[]>([
    {
      label: addressObject.label,
      value: addressObject.value,
      location: addressObject.location,
    },
  ]);

  const [loc, setLoc] = useState<LocationType | undefined>(undefined)

  const onSuccess = (res: AxiosResponse<FindAddressDetailed[]>) => {

    const resArray: AddressValue[] = res.data.map(el => ({label: el.label, value: el.label}))
    setOptions(resArray)
    setAddressObject({label: res.data[ 0 ].locality, value: res.data[ 0 ].id})

    setValue(name, {
      label: res.data[ 0 ].locality,
      value: res.data[ 0 ].id,
      location: undefined,
    });
  }
  const findAddress = useFindAddressDetailed();
  const findAddressByCoords = useFindPointDetailed({onSuccess});


  if (autoDetect && loc === undefined) {
    navigator.geolocation.getCurrentPosition(position => {
      const {latitude, longitude} = position.coords
      setLoc({coordinates: [latitude, longitude]})
      findAddressByCoords.mutate(`${longitude},${latitude}`)
    })
  }

  useEffect(() => {
    if (autoDetect && findAddressByCoords.data) {
      if (onChange) {
        onChange(findAddressByCoords.data.data[0].id)
      }
    }
  }, [findAddressByCoords.data])

  const onSelectWrite = async (searchString: string) => {
    if (searchString === "" && !loc) {
      setOptions([]);

      setValue(name, {
        label: "",
        value: "",
        location: undefined,
      });

      return;
    }

    const {data} = await findAddress.mutateAsync(searchString);

    if (data) {
      const foundAddresses = data.filter((address) => address.label);
      const withoutDuplicate = foundAddresses.filter(
        (address, index, self) => index === self.findIndex((t) => t.label === address.label)
      );

      if (data.length > 0) {
        const newOptions = [
          {
            label: addressObject.label as string,
            value: addressObject.value as string,
          },
        ].concat(
          withoutDuplicate.map((address) => ({
            label: address.label,
            value: address.id,
          }))
        );

        setOptions(newOptions);
      }
    }
  };

  const onSelectChange = (id: string) => {
    if (options?.length === 0) {
      return;
    }

    if (onChange) {
      onChange(id)
    }

    if (id === value.value) {
      return;
    }

    const lastMutation: any = findAddress?.getLastMutationCache();

    const selectedAddress: FindAddressDetailed = lastMutation?.state?.data?.data?.find(
      (address: FindAddressDetailed) => address.id.toString() === id.toString()
    );

    const coords = selectedAddress?.point?.split(",");

    if (!selectedAddress) {
      if (autoDetect) {
        setValue(name, {
          label: addressObject.label,
          value: addressObject.value,
          location: undefined,
        });
      } else {
        setValue(name, {
          label: "",
          value: "",
          location: undefined,
        });
      }
      return;
    }

    const newOptions: AddressValue[] = [
      {
        label: selectedAddress.label,
        value: selectedAddress.id,
        location: {
          coordinates: [Number(coords[ 1 ]), Number(coords[ 0 ])],
        },
      },
    ];

    setOptions(newOptions);
    setValue(name, newOptions[ 0 ]);
    setAddressObject(newOptions[ 0 ]);
    if (onChangePoint) {
      onChangePoint(newOptions[0]?.location?.coordinates)
    }
  };

  const setCurrentAddress = (_, values: string) => {
    onSelectChange(values);
  };

  return (
    <Select
      label={label}
      placeholder={placeholder}
      name={name}
      hideArrow
      defaultValue={addressObject.value}
      defaultSearchValue={addressObject.label}
      isSearchable
      size={size}
      options={options}
      register={register}
      setValue={setCurrentAddress}
      classNames={classNames}
      customSearchFunction={timer && timer > 0 ? () => {} : onSelectWrite}
      onChange={onSelectChange}
      error={error}
      append={append}
      prepend={prepend}
      required={required}
      isLoading={timer && timer > 0 ? true : findAddress.isLoading}
      disabled={timer && timer > 0 ? true : disabled}
      withBorder={withBorder}
    />
  );
};
