import { useDispatch } from "react-redux";
import { TaskModal } from "../../../components/UI";
import { openModal } from "../../../service/redux/modules/modal/modal.action";
import styles from "./index.module.scss"
import { selectDevice, useSelect } from "../../../service/redux/selectors";
import { useQueryParams } from "../helpers";

type Props = {
  onUpdatedTask: () => void;
  refetchParent?: () => void
  isLast?: boolean
  mini?: boolean
};

export const useOpenTaskModal = ({onUpdatedTask, refetchParent, isLast, mini}: Props) => {
  const dispatch = useDispatch();
  const {isMobile} = useSelect(selectDevice)

  const {changeQueryParams, removeQueryParam} = useQueryParams<{taskId: string}>([{name: "taskId"}])

  const handleOpenTaskModal = (id: string) => {
    changeQueryParams([{name: "taskId", newValue: id}])
    dispatch(
      openModal({
        body: <TaskModal
          id={id}
          onUpdatedTask={onUpdatedTask}
          refetchParent={refetchParent}
          isLast={isLast}
          isMobile={mini}
        />,
        width: isMobile ? 500 : 800,
        classNames: {
          contentWrapper: isMobile ? styles.modal : "",
          buttonClose: isMobile ? styles.modalClose : "",
          wrapper: styles.wrapper
        },
        onClose: () => {
          removeQueryParam(["taskId"])
        }
      })
    );
  };

  return {handleOpenTaskModal};
};
