

export const parsePhone = (value: string): string => {
	if (!value) return "";

	const onlyNumbers = value.replace(/[^\d]/g, "");

	const v: string[] = [];
	for (let i = 0; i < 11; i += 1) {
		v[i] = onlyNumbers[i] ? onlyNumbers[i] : "";
	}

	if (onlyNumbers.length === 0) {
		return "";
	}
	if (onlyNumbers.length === 1) {
    if (v[0] === String(7)) {
			v.shift()
		}
		return `+7 (${v[0]}`;
	}
	if (onlyNumbers.length <= 4) {
		return `+7 (${v[1]}${v[2]}${v[3]}`;
	}
	if (onlyNumbers.length <= 7) {
		return `+7 (${v[1]}${v[2]}${v[3]}) ${v[4]}${v[5]}${v[6]}`;
	}
	if (onlyNumbers.length <= 9) {
		return `+7 (${v[1]}${v[2]}${v[3]}) ${v[4]}${v[5]}${v[6]}-${v[7]}${v[8]}`;
	}
	if (onlyNumbers.length <= 10) {
		return `+7 (${v[1]}${v[2]}${v[3]}) ${v[4]}${v[5]}${v[6]}-${v[7]}${v[8]}-${v[9]}`;
	}

	return `+7 (${v[1]}${v[2]}${v[3]}) ${v[4]}${v[5]}${v[6]}-${v[7]}${v[8]}-${v[9]}${v[10]}`;
};
