import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { ChangeStagePayload, ChangeStageResponse, KindType } from "../../../../types";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/application/change-application-stage/DEFAULT_MN";

type UseChangeApplicationStage = {
	onSuccess?: (data: ChangeStageResponse) => void;
	kind?: KindType
};

export const useChangeApplicationStage = ({ onSuccess, kind }: UseChangeApplicationStage) => {
	const onSuccessContactCreate = (res: AxiosResponse<ChangeStageResponse>) => {
		if (onSuccess) {
			onSuccess(res.data);
		}
	};

	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: ({ applicationId, stageName }: ChangeStagePayload) => {
			
				if (kind === KindType.APPLICATION) {
						return api.application.changeStage({ applicationId, stageName });
				} 
				if (kind === KindType.PREAPPLICATION) {
					return api.PreApplicationService.changeStage({ applicationId, stageName });
			} 
			if (kind === KindType.DEAL) {
				return api.deals.changeStage({ applicationId, stageName });
		} 
				
						return new Promise<AxiosResponse<ChangeStageResponse>>((resolve, reject) => {
								reject(new Error("Invalid kind provided."));
						});
				
		},
		onSuccess: (data: AxiosResponse<ChangeStageResponse>) => onSuccessContactCreate(data),
});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
