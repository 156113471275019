import { useWatch } from "react-hook-form";
import { RealEstateSchemaConfigField, FormPropsEditRealEstate } from "../../../../../../../../../../types";

import { RadioButton } from "../../../../../../../../index";

import styles from "./index.module.scss";

type Props = {
	form: FormPropsEditRealEstate;
	field: RealEstateSchemaConfigField;
};

export const RoomsForSale = ({ form, field }: Props): JSX.Element => {
	const {
		dataType: { nameGroup = [] },
	} = field;
	const { control, setValue } = form;

	const roomsAmountValue = useWatch({
		control,
		name: nameGroup[0],
	});
	const roomsForSaleValue = useWatch({
		control,
		name: nameGroup[1],
	});

	const roomsAmountOptions = [
		{ label: "2", value: "2" },
		{ label: "3", value: "3" },
		{ label: "4", value: "4" },
		{ label: "5", value: "5" },
		{ label: "6+", value: "6+" },
	];

	const isForSaleBtnDisabled = (amount: number): boolean => {
		if (roomsAmountValue === "6+") {
			return false;
		}

		return amount >= Number(roomsAmountValue);
	};

	const roomsForSaleOptions = [
		{ label: "1", value: "1", disabled: isForSaleBtnDisabled(1) },
		{ label: "2", value: "2", disabled: isForSaleBtnDisabled(2) },
		{ label: "3", value: "3", disabled: isForSaleBtnDisabled(3) },
		{ label: "4", value: "4", disabled: isForSaleBtnDisabled(4) },
		{ label: "5", value: "5", disabled: isForSaleBtnDisabled(5) },
		{ label: "6+", value: "6+", disabled: isForSaleBtnDisabled(6) },
	];

	const onChangeRoomsAmount = (curValue) => {
		if (roomsForSaleValue && (Number(roomsForSaleValue) >= curValue || roomsForSaleValue === "6+")) {
			setValue(nameGroup[1], undefined);
		}
	};

	if (!nameGroup.length) {
		return <></>;
	}

	return (
		<>
			<h3>Количество комнат в квартире</h3>
			<RadioButton
				name={nameGroup[0]}
				options={roomsAmountOptions}
				control={control}
				separate
				required
				className={styles.radio}
				handleOnChange={onChangeRoomsAmount}
				currentValue={roomsAmountValue}
			/>

			<div className={styles.space} />

			<h3>Комнат на продажу</h3>
			<RadioButton
				name={nameGroup[1]}
				options={roomsForSaleOptions}
				control={control}
				separate
				required
				className={styles.radio}
				currentValue={roomsForSaleValue}
			/>
		</>
	);
};
