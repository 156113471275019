import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import cn from "classnames";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadByScroll, Tooltip } from "@sdelka_crm/sdelka-crm-component-library";
import { MarkdownEditor } from "../../Form";
import { HiddenWidget } from "../HiddenWidget";
import { useUpdateApplication } from "../../../../service/api/mutations";
import { useBlockAction } from "../helpers";
import { ApplicationCardPayload, ApplicationUpdatePayload, KindType } from "../../../../types";
import styles from "./index.module.scss";
import { ReactComponent as SaveText } from "../../../../assets/icons/saveComment.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/iconInfo.svg";

type Props = ApplicationCardPayload & {
  comment: string;
  onChange?: (value: string) => void;
  defaultEditMode?: boolean;
  kind?: string
};

export const ApplicationComment = (
  {
    scrollY,
    applicationId = "",
    onChange,
    defaultEditMode = false,
    comment,
    stageConditions,
    kind
  }: Props): JSX.Element => {
  const schema = yup.object().shape({
    innerComment: yup.string().max(2510, "Слишком длинный комментарий"),
  });

  const {checkAllow} = useBlockAction({
    isLast: stageConditions ? stageConditions.isLast : false,
  });

  const {handleSubmit, setValue, getValues, register, formState, control} = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      innerComment: comment,
    },
  });

  const commentWatcher = useWatch({
    name: "innerComment",
    control,
    defaultValue: getValues("innerComment"),
  });

  const [editMode, setEditMode] = useState(defaultEditMode);
  const [enabled, setEnabled] = useState(false);

  const {mutate, isLoading} = useUpdateApplication({
    onSuccess: () => {
      setEditMode(false);
    },
    kind
  });

  useEffect(() => {
    setEnabled(true)
  }, [])

  useEffect(() => {
    if (comment !== commentWatcher) {
      setValue("innerComment", comment)
    }
    setEditMode(false)
  }, [comment])

  const onSubmit = () => {
    const curComment = getValues().innerComment;

    if (applicationId) {
      const payload: ApplicationUpdatePayload = {
        applicationId,
        applicationData: {comment: curComment},
      };
      if (isLoading) {
        return;
      } else {
        mutate(payload);
        return;
      }
    }
    if (onChange) {
      onChange(curComment);
      setEditMode(false);
    }
  };

  const startEdit = () => checkAllow(() => setEditMode(true));

  const titleText = kind === KindType.DEAL ? "Примечания от юриста" : "Примечание к потребности"
  const tooltipText = kind === KindType.DEAL
    ? "Мы разработали максимально точный перечень вопросов для знакомства с клиентом. Эти вопросы составлены юристами и предназначены для успешного прохождения сделки. Воспользуйтесь этим инструментом, чтобы беопасно провести сделку."
    : "Мы разработали самый точный перечень вопросов для знакомства с клиентом. Воспользуйтесь им, чтобы\n" +
    "  по-настоящему понять его потребность."

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.row}>
        <div className={styles.title}>{titleText}</div>
        <Tooltip
          classNames={{
            root: styles.tooltipDescription,
            card: styles.tooltipCard,
          }}
          iconSize="big"
          CustomIcon={InfoIcon}
        >
          {tooltipText}
        </Tooltip>
      </div>

      {(stageConditions ? stageConditions.isNext : false) ? (
        <HiddenWidget/>
      ) : (
        <LoadByScroll scrollY={scrollY} height={250} isLoading={!enabled} onInView={() => setEnabled(true)}>
          <div className={styles.textareaWrapper}>
            <MarkdownEditor
              name="innerComment"
              setValue={setValue}
              getValues={getValues}
              register={register}
              curValue={commentWatcher}
              error={formState.errors.innerComment?.message?.toString()}
              setEditMode={startEdit}
            />
            {editMode &&
              <div
                className={cn(styles.saveText, {[styles.saveTextSaving]: isLoading})}
              >
                <div onClick={handleSubmit(onSubmit)}>
                  <SaveText/>
                </div>
              </div>
            }
          </div>
        </LoadByScroll>
      )}
    </form>
  );
};
