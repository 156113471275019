import { ChangeEvent, useEffect, useRef, useState } from "react";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import cn from "classnames";

import { useClickOutside } from "../../../../utils/hooks";
import { padTimeWithZeroes } from "../../../../utils/date";
import { AnyInputProps } from "../../../../types";

import styles from "./index.module.scss";

type Props = AnyInputProps & {
	classNames?: {
		root?: string;
		input?: string;
		wrapper?: string;
		inputs?: string
		error?: string
	};
	register?: UseFormRegister<any>;
	onChange?: (value: string) => void;
	setValue?: UseFormSetValue<any>;
	variant?: "noLabel";
	label?: string;
	hideLabel?: boolean;
};

const mask = (value: string) => /^\d{0,2}((\d{1,2}):)?\d{0,2}$/.test(value);

const notValid = (innerVal: string, newVal: string, { minutes }: { minutes: number }) =>
	(newVal.length === 4 && !newVal.includes(":")) ||
	(innerVal.length < newVal.length && !mask(newVal)) ||
	(minutes < 10 && minutes >= 10);

export const Time = ({
	name,
	onChange,
	register,
	classNames,
	disabled,
	value,
	setValue,
	error,
	label = "Время",
	variant,
	hideLabel,
}: Props): JSX.Element => {
	const wrapRef = useRef(null);
	const [wasFocused, setWasFocused] = useState(false);
	const [innerValue, setInnerValue] = useState("");

	useClickOutside({
		ref: wrapRef,
		onClick: () => {
			const [hours, minutes] = innerValue.split(":");

			if (!wasFocused) return;
			setInnerValue(`${padTimeWithZeroes(hours, "hours")}:${padTimeWithZeroes(minutes, "minutes")}`);
		},
	});

	useEffect(() => {
		if (setValue) {
			setValue(name, innerValue);
		}

		if (onChange) {
			onChange(innerValue);
			if (setValue) {
				setValue(name, innerValue);
			}
		}
	}, [innerValue, name, onChange, setValue]);

	useEffect(() => {
		if (value) setInnerValue(value);
	}, [value]);

	const onInnerChange = (e: ChangeEvent<HTMLInputElement>) => {
		let newVal = e.target.value;

		const [hours, minutes] = newVal.split(":").map((item) => +item);

		if (notValid(innerValue, newVal, { minutes })) return;

		if (minutes > 59 || minutes < 0) {
			if (minutes > 59) {
				newVal = `${newVal.slice(0, 3)}0${newVal.slice(4)}`;
			}

			setInnerValue(newVal);
		}

		if (hours > 23 || hours < 0) {
			newVal = `0${newVal.slice(1)}`;

			setInnerValue(newVal);
		}

		if (innerValue && innerValue.length === 3 && newVal.length === 2)
			newVal = newVal.slice(0, newVal.length - 1);

		if (
			newVal[newVal.length - 1] !== ":" &&
			innerValue &&
			newVal.length === 2 &&
			newVal.length > innerValue.length
		)
			newVal += ":";

		setInnerValue(newVal);
	};

	return (
		<div ref={wrapRef} className={cn(styles.root, classNames?.root)}>
			<div
				className={cn(styles.wrapper, classNames?.wrapper, {
					[styles.noLabel]: variant === "noLabel",
				})}
			>
				{!hideLabel && <div className={styles.prepend}>{label}</div>}
				<div className={cn(styles.inputs, classNames?.inputs)}>
					<input
						disabled={disabled}
						autoComplete="off"
						placeholder="00:00"
						maxLength={5}
						value={innerValue}
						{...(register ? register(name, { value: innerValue }) : {})}
						className={cn(styles.input, classNames?.input)}
						onChange={onInnerChange}
						onFocus={() => setWasFocused(true)}
					/>
				</div>
			</div>
			{error && <span className={cn(styles.error, classNames?.error)}>{error}</span>}
		</div>
	);
};
