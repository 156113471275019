import { NoData } from "@sdelka_crm/sdelka-crm-component-library";

import styles from "./index.module.scss";

type Props = {
  refetch?: () => void;
};

export const UserNotLoaded = ({refetch}: Props): JSX.Element => {

  const onRefectch = () => {
    if (refetch) {
      refetch()
    }
  }
  return (
    <div className={styles.root}>
      <NoData onRefetch={onRefectch}/>
    </div>
  );
}
