import { KindType } from "../../../../../types";
import { Tabs } from "./components";
import { RealEstateView } from "../RealEstateView";
import { memo } from "react";
import { useLoadApplicationRealEstate } from "../../../../../service/api/queries";
import { useGetNavigation } from "../../helpers";
import { LayoutLoading } from "./LayoutLoading";

type Props = {
  id: string;
  applicationTemplateId?: string
  kind?: KindType
  inDrawerInDrawer?: boolean;
  enabled?: boolean
  isFromCompilation?: boolean
};

export const RealEstateViewWithTabs = memo((
  {
    id,
    applicationTemplateId,
    kind,
    inDrawerInDrawer,
    enabled,
    isFromCompilation
  }: Props): JSX.Element => {

  const {realEstate, refetch: refetchRealEstate} = useLoadApplicationRealEstate({
    id,
    kind,
    enabled
  });

  const {blocksIds} = useGetNavigation({
    realEstate,
  });

  const {activeApplications} = realEstate || {};

  return (
    <div style={{width: "800px"}}>
      {
        activeApplications &&
        <Tabs activeApplications={activeApplications} currentApplicationId={id}/>
      }
      {realEstate
       ? <RealEstateView
          id={applicationTemplateId ?? id}
          blocksIds={blocksIds}
          realEstate={realEstate}
          refetch={refetchRealEstate}
          inDrawerInDrawer={inDrawerInDrawer}
          enabled={enabled}
          isFromCompilation={isFromCompilation}
        />
        : <LayoutLoading/>
      }
    </div>
  );
});
