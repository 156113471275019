import { useDispatch } from "react-redux";
import { openModal } from "../../../../../../../../../service/redux/modules/modal/modal.action";
import styles from "./styles.module.scss";
import { Contacts } from "../../../../../../../Pin/PinContact/Contacts";
import { selectDevice, useSelect } from "../../../../../../../../../service/redux/selectors";

export const useContactModal = () => {
  const dispatch = useDispatch();
  const {isMobile} = useSelect(selectDevice)

  const openContactModal = () =>
    dispatch(
      openModal({
        body: <Contacts onPin={() => {
        }} isCreation isMobile={isMobile}/>,
        width: isMobile ? 500 : 1000,
        classNames: {
          contentWrapper: isMobile ? styles.contentWrapperClassNameMobile : "",
          buttonClose: isMobile ? styles.modalClose : ""
        }
      })
    );

  return {
    openContactModal,
  };
};
