import { Client } from "../../Client";
import { getRealEstateFilters } from "../../helpers";
import {
	CallCenterResponseData,
	DefaultRequestArgs,
	MarkSuccessRequestArgs,
	RealEstateFilter,
} from "../../../../types";

const getSearchParamByFilter = (filter: RealEstateFilter) => ({
	filterType: "and",
	conditions: getRealEstateFilters({ searchPayload: filter }),
});

export const CallCenterService = {
	markSuccess: ({ parserObjectId, ...payload }: MarkSuccessRequestArgs) =>
		Client.put<CallCenterResponseData>(`/callCenterChecking/${parserObjectId}/success`, {
			...payload,
			// Request must not contain field "filter". Use "search" instead
			filter: undefined,
			search: getSearchParamByFilter(payload.filter),
		}),

	markAgent: ({ parserObjectId, filter }: DefaultRequestArgs) =>
		Client.put<CallCenterResponseData>(`/callCenterChecking/${parserObjectId}/isAgent`, {
			search: getSearchParamByFilter(filter),
		}),

	/**
	 * Object has been sold OR similar scenario
	 */
	markArchive: ({ parserObjectId, filter }: DefaultRequestArgs) =>
		Client.put<CallCenterResponseData>(`/callCenterChecking/${parserObjectId}/archive`, {
			search: getSearchParamByFilter(filter),
		}),

	markNotAvailable: ({ parserObjectId, filter }: DefaultRequestArgs) =>
		Client.put<CallCenterResponseData>(`/callCenterChecking/${parserObjectId}/notAvailable`, {
			search: getSearchParamByFilter(filter),
		}),

	/**
	 * Skip object. It will be hidden for call-center API for some time. This time is configured on backend
	 */
	markSkip: ({ parserObjectId, filter }: DefaultRequestArgs) =>
		Client.put<CallCenterResponseData>(`/callCenterChecking/${parserObjectId}/skip`, {
			search: getSearchParamByFilter(filter),
		}),
};
