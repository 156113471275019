import { useDispatch } from "react-redux";
import { LeadCard } from "../../../../../components/pages/LeadCard";
import { openDrawer } from "../../../../redux/modules/drawer/drawer.action";
import { useQueryParams } from "../../../../../utils/hooks";

type OpenLeadPayload = {
  id: string;
  beforeOpen?: () => void;
  afterClose?: () => void;
};

type Props = {
  refetch?: () => void;
};

type Return = {
  openLeadInDrawer: (payload: OpenLeadPayload) => void;
};

type UseOpenLeadInDrawer = (props: Props) => Return;

export const useOpenLeadInDrawer: UseOpenLeadInDrawer = ({refetch}) => {
  const dispatch = useDispatch();
  const {changeQueryParams, removeQueryParam} = useQueryParams<{ leadId: string }>([{name: "leadId"}])

  const openLeadInDrawer = ({id, beforeOpen, afterClose}: OpenLeadPayload) => {
    if (beforeOpen) {
      beforeOpen();
    }

    changeQueryParams([{name: "leadId", newValue: id}])
    dispatch(
      openDrawer({
        children: <LeadCard id={id} refetch={refetch}/>,
        width: 500,
        onClose: () => {
          if (afterClose) {
            afterClose();
            removeQueryParam(["leadId"])
          }
        },
      })
    );
  };

  return {
    openLeadInDrawer,
  };
};
