import { useQuery } from "react-query";
import { ShowAndViewsTabs, ShowsOrViews } from "../../../../types";

import { api } from "../../api";

const DEFAULT_QN = "sdelka/shows-views/load-shows-views/DEFAULT_QN";

type UseLoadShowsOrViews = {
	applicationId: string;
	tab?: ShowAndViewsTabs;
	type: ShowsOrViews;
};

export const useLoadShowsOrViews = ({ applicationId, tab = ShowAndViewsTabs.waiting, type }: UseLoadShowsOrViews) => {
	const loadViews = () => api.showsViews.listViews(applicationId, tab);
	const loadShows = () => api.showsViews.listShows(applicationId, tab);

	const loadFn = type === "shows" ? loadShows : loadViews

	const { data, refetch, remove, isLoading, isFetched } = useQuery([DEFAULT_QN, tab], loadFn);

	return {
		data: data?.data,
		isLoading,
		refetch,
		remove,
		isFetched,
	};
};
