import { FeedAd, RealEstate } from "../../../../../../../types";
import { memo } from "react";

import { ReactComponent as IconPublished } from "../../../../../../../assets/icons/iconPublished.svg"
import { ReactComponent as IconNotPublished } from "../../../../../../../assets/icons/iconNotPublished.svg"
import { ReactComponent as IconError } from "../../../../../../../assets/icons/iconError.svg"
import { ReactComponent as IconRNotRequested } from "../../../../../../../assets/icons/iconNotRequested.svg"
import { Tooltip } from "@sdelka_crm/sdelka-crm-component-library";
import styles from "./index.module.scss";
import cn from "classnames";
import isEqual from "lodash.isequal";
import { ReactComponent as LogoOwner } from "../../../../../../../assets/icons/logoOwner.svg";
import { ReactComponent as ImResponsibleIcon } from "../../../../../../../assets/icons/IAmResponsibleIconSmall.svg";
import { ReactComponent as IconGrayCircle } from "../../../../../../../assets/icons/IconGrayCircle.svg";

export type Props = {
  feedAds?: FeedAd[]
  object: RealEstate
}

const mainSiteName = "Циан" || "Яндекс Недвижимость" || "Юла" || "Домклик" || "Авито"

export const AdStatRow = memo(({feedAds, object}: Props): JSX.Element => {
  const {
    parserData,
    isAgency,
  } = object;
  const otherNumber = feedAds?.filter(feed => feed.feedName !== mainSiteName).length
  const isParser = !isEqual(parserData, undefined);

  const icon = (feedAd: FeedAd) => {
    switch (feedAd.status) {
      case "Published":
        return (
          <Tooltip
            classNames={{
              root: styles.tooltipDescription,
              card: cn(styles.tooltipCard, styles.tooltipActiveInfoLess),
            }}
            iconSize="big"
            CustomIcon={IconPublished}
          >
            В рекламе
          </Tooltip>
        )
      case "Error":
        return (
          <Tooltip
            classNames={{
              root: styles.tooltipDescription,
              card: cn(styles.tooltipCard, styles.tooltipActiveInfoLess),
            }}
            iconSize="big"
            CustomIcon={IconError}
          >
            Ошибка <br/>
            {feedAd.errorText}
          </Tooltip>
        )
      case "NotRequested":
        return (
          <Tooltip
            classNames={{
              root: styles.tooltipDescription,
              card: cn(styles.tooltipCard, styles.tooltipActiveInfoLess),
            }}
            iconSize="big"
            CustomIcon={IconRNotRequested}
          >
            Ожидает размещения
          </Tooltip>
        )
      case "Removed":
        return (
          <Tooltip
            classNames={{
              root: styles.tooltipDescription,
              card: cn(styles.tooltipCard, styles.tooltipActiveInfoLess),
            }}
            iconSize="big"
            CustomIcon={IconNotPublished}
          >
            Закончилась
          </Tooltip>
        )
      default:
        return <></>
    }
  }
  const rightPart: JSX.Element =
    isParser && parserData ? (
      <div className={styles.owner}>
        {parserData.agencyName ? (
          <span>{parserData.agencyName}</span>
        ) : (
          <>
            {!isAgency && <div className={styles.owner}>
              <LogoOwner/>
            </div>}
            <span>
              {parserData?.ownerName ? parserData?.ownerName : "Без названия"}
            </span>
          </>
        )}
      </div>
    ) : (
      <div className={styles.responsible}>
        <div className={styles.owner}>
          <ImResponsibleIcon/>
        </div>
      </div>
    );

  // eslint-disable-next-line react/no-unstable-nested-components
  const OtherNode = () => (<span>{`+${otherNumber}`}</span>)

  const feedAdsNormal = feedAds?.slice(0, 4)

  return (
    <div className={cn(styles.flexRow, styles.flexBetween)}>
      <div className={styles.iconBlock}>
        {feedAdsNormal?.map((feedAd) => (
          <div key={feedAd.id} className={styles.flexRow}>
            {icon(feedAd)}
            <span className={styles.iconRow}>
               {feedAd.feedName}
            </span>
          </div>)
        )
        }
        {otherNumber !== 0 &&
          <div className={styles.other}>
            <Tooltip
              classNames={{
                root: cn(styles.tooltipDescription, styles.tooltipNone),
                card: cn(styles.tooltipCard, styles.tooltipActiveInfoLess),
              }}
              iconSize="big"
              CustomIcon={IconGrayCircle}
            >
              {`+${otherNumber} остальных площадок`}
            </Tooltip>
            <OtherNode/>
          </div>
        }
      </div>
      {rightPart}
    </div>
  )
})