import styles from "./index.module.scss"
import { FixedNavVerticalMenu } from "../FixedNavVertical";
import cn from "classnames";
import {ReactComponent as ArrowBack} from "../../../assets/icons/Chevron_Left_L.svg";
import { useQueryParams } from "../../../utils/hooks";
import { useEffect } from "react";

type Props = {
  onCloseClick: () => void
  navigation?: FixedNavVerticalMenu[]
  activeTab?: string | number
  disableClose?: boolean
}

export const FixedNavHorizontal = (
  {
    onCloseClick,
    navigation,
    activeTab,
    disableClose
  }: Props) => {

  const queryNames = navigation?.map(el => ({
    name: el.query?.name || ""
  })).concat({name: "mode"})

  const {changeQueryParams} = useQueryParams(queryNames || []);

  useEffect(() => {
    if (!navigation) {
      return
    }
    if (navigation[ 0 ]?.query) {
      changeQueryParams([{name: navigation[ 0 ].query.name, newValue: navigation[ 0 ].query.value}, {
        name: "mode",
        newValue: navigation[ 0 ].title ?? ""
      }])
    }
  }, [navigation])

  return (
    <div className={styles.root}>
      {!disableClose && <div className={styles.arrow}> <ArrowBack onClick={onCloseClick}/> </div>}
      <div className={styles.row}>

        {navigation && navigation.map((nav) => (
          <div
            key={nav.id}
            style={{
              minWidth: 90,
            }}
            onClick={() => {
              if (!nav.disabled) {
                nav.changeTab();
                if (nav.query) {
                  changeQueryParams([{name: nav.query.name, newValue: nav.query.value}])
                }
                if (nav.title) {
                  changeQueryParams([{name: "mode", newValue: nav.title}])
                }
              }
            }}
            className={cn(styles.buttonItem, {
              [ styles.buttonItemActive ]: nav.id === activeTab,
              [ styles.buttonItemDisabled ]: nav.disabled,
            })}
          >
            <div className={styles.buttonText}>{nav.label}</div>
          </div>
        ))}

      </div>

    </div>
  )
}