import { ReactNode } from "react";
import cn from "classnames";

import { CommonListItem } from "../../../types";
import { TextInfo } from "../index";

import styles from "./index.module.scss";

type Props = {
	className?: string;
	items: Array<CommonListItem<string | ReactNode> & { className?: string }>;
};

export const TextInfoRows = ({ className, items }: Props): JSX.Element => (
	<div className={cn(styles.root, className)}>
		{items.map((item) => (
			<TextInfo key={item.id} classNames={{ root: cn(styles.info, item.className) }} label={item.label}>
				<div>{item.value}</div>
			</TextInfo>
		))}
	</div>
);
