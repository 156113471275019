import { useMutation } from "react-query";
import { CompilationAddObjectPayload } from "../../../../types";

import { api } from "../../index";
import { axiosError } from "../../../../utils/errors";
import { AxiosError } from "axios";
import { toastError } from "../../../../components/UI";
import { closeModal, openModal } from "../../../redux/modules/modal/modal.action";
import { useDispatch } from "react-redux";

const DEFAULT_MN = "sdelka/compilation/add-object-to-compilation/DEFAULT_MN";

type UseAddObjectToCompilation = {
	onSuccess?: (data) => void;
	onError?: () => void;
};


export const useAddObjectToCompilation = ({ onSuccess, onError }: UseAddObjectToCompilation) => {
	const dispatch = useDispatch()
	const handleError = () => {
		const openAlertModal = () => {
			dispatch(
				openModal({
					body: "Объект уже присутствует в данной подборке",
					variant: "alert",
					alertIcon: "warning",
					alertButtonSubmit: {
						label: "Вернуться",
						onClick: () => {
							dispatch(closeModal());
						},
					},
				})
			);
			return openAlertModal
		};

		if (onError) {
			openAlertModal();
		}
	};

	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: ({
			applicationId,
			parserObjectIds,
			clientObjectApplicationIds,
		}: CompilationAddObjectPayload) =>
			api.compilations.addObjects({
				applicationId,
				parserObjectIds,
				clientObjectApplicationIds,
			}),
		onSuccess,
		onError,
	});

	return {
		mutate: mutate.mutate,
		mutateAsync: mutate.mutateAsync,
		isLoading: mutate.isLoading,
		onError: handleError,
		onSuccess,
	};
};
