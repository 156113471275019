import { ReactNode } from "react";

import { DefaultSelectProps } from "../../../../../../types";

import selectStyles from "../selectStyles.module.scss";
import cn from "classnames";

type Props = {
	children: ReactNode;
	thereIsSelected: boolean;
} & Pick<DefaultSelectProps, "placeholder" | "classNames">;

export const SelectedWrapper = ({
	children,
	thereIsSelected,
	placeholder = "Выбрать",
	classNames
}: Props): JSX.Element => (
	<span className={selectStyles.selectedOptionWrapper}>
		{placeholder && !thereIsSelected ? (
			<span className={cn(selectStyles.placeholder, classNames?.placeholder)}>{placeholder}</span>
		) : (
			<>{children}</>
		)}
	</span>
);
