import { useMemo } from "react";
import { InfoRow } from "@sdelka_crm/sdelka-crm-component-library";
import { MultiLayerSelect } from "../../../../../Form";
import { useLoadUsers } from "../../../../../../../service/api/queries";
import { prepareResponsibleUsers } from "../../../../../../../utils/transformation";
import { useTaskFormContext } from "../../../../../../../service/context/TaskForm";
import { MultiLayerSelectOption } from "../../../../../../../types";
import { selectCurrentAccount, useSelect } from "../../../../../../../service/redux/selectors";

type Props = {
  classnames: string
}

export const Executor = ({classnames}: Props): JSX.Element => {
  const {register, setValue, errors} = useTaskFormContext();
  const {users} = useLoadUsers({enabled: true});
  const {currentAccount} = useSelect(selectCurrentAccount)
  const responsibleUsers = useMemo((): MultiLayerSelectOption[] => prepareResponsibleUsers(users), [users]);

  const defaultUser = responsibleUsers.find(el => el.value === currentAccount?.id)

  return (
    <InfoRow labelFont="ubuntu" label="Исполнитель">
      {users && users.length > 0 && <MultiLayerSelect
        placeholder="Выбрать"
        name="executor.userId"
        register={register}
        setValue={setValue}
        defaultValue={[defaultUser] as any}
        error={errors?.executor?.message?.toString()}
        required
        selectOne
        isSearchable
        classNames={{
          wrapper: classnames
        }}
        options={responsibleUsers}/>}
    </InfoRow>
  );
};
