import {
  AgentDocument,
  ApplicationComment,
  ChildDeals,
  Contacts,
  DealCharacteristics,
  DealProperties,
  Employees,
  GuaranteePayment,
  MirrorApplication,
  ObjectAd,
  ObjectCreation,
  ObjectShowsOrViews,
  Quiz,
  Recommendations,
  Responsibles,
  TasksToCompleteInApplication,
} from "../../../../../../../../../../../../../../UI";
import {
  ApplicationCardPayload,
  KindType,
  PropsAdditionalProperties,
  QuizQuestion,
  RealEstate,
  StageConditions,
  User
} from "../../../../../../../../../../../../../../../types";
import styles from "./blocks.module.scss";
import React from "react";
import { AgencyAgrement } from "components/pages/Application/PreApplicationTemplate/components/AgencyAgrement";
import { CardObjectBuyWrapper } from "../../../../../../Header/components";
import {
  AppliedServicesHistory
} from "../../../../../../../../../../../../../../UI/ApplicationComponents/applied-services-history/AppliedServicesHistory";
import {
  DealContacts
} from "../../../../../../../../../../../../../../UI/ApplicationComponents/deal-contacts/DealContacts";
import { DealLawyer } from "../../../../../../../../../../../../../../UI/ApplicationComponents/deal-lawyer/DealLawyer";
import {
  DocumentGeneration
} from "../../../../../../../../../../../../../../UI/ApplicationComponents/document-generation/DocumentGeneration";
import { Documents } from "../../../../../../../../../../../../../../UI/ApplicationComponents/documents/Documents";
import { Statistic } from "../../../../../../../../../../../../../../UI/statistic/Statistic";


type MapComponentsByBlockType = {
  agentDocument: (props) => React.ReactElement;
  ads: (props) => React.ReactElement;
  requirementsComment: (props) => React.ReactElement;
  responsibles: (props) => React.ReactElement;
  sellerContacts: (props) => React.ReactElement;
  buyerContacts: (props) => React.ReactElement;
  tasks: (props) => React.ReactElement;
  viewData: (props) => React.ReactElement;
  showData: (props) => React.ReactElement;
  adsRecommendations: (props) => React.ReactElement;
  jointObject: (props) => React.ReactElement;
  employees: (props) => React.ReactElement;
  jointPodbor: (props) => React.ReactElement;
  quiz: (props) => React.ReactElement;
  dealProperties: (props) => React.ReactElement;
  dealCharacteristics: (props) => React.ReactElement;
  agencyAgreement: (props) => React.ReactElement;
  mirrorApplication: (props) => React.ReactElement;
  childDeals: (props) => React.ReactElement;
  appliedServicesHistory: (props) => React.ReactElement,
  dealSellerContacts: (props) => React.ReactElement,
  dealBuyerContacts: (props) => React.ReactElement,
  lawyer: (props) => React.ReactElement,
  documentGeneration: (props) => React.ReactElement
  documents: (props) => React.ReactElement
  showOrViewsStatistic: (props) => React.ReactElement
  guaranteePayment: (props) => React.ReactElement
};

export const mapComponentsByBlockType: MapComponentsByBlockType = {
  agentDocument: ({applicationId}: ApplicationCardPayload): React.ReactElement => (
    <div id="agentDocument">
      <AgentDocument applicationId={applicationId}/>
    </div>
  ),

  showOrViewsStatistic: (
    {
      applicationId,
      application,
      isMobile
    }: ApplicationCardPayload & { isMobile?: boolean }): React.ReactElement => (
    <div id="showOrViewsStatistic">
      <Statistic
        applicationId={applicationId}
        whoIsClient={application?.type.whoIsClient}
        isMobile={isMobile}
      />
    </div>
  ),

  guaranteePayment: (
    {
      applicationId,
      application,
      isMobile,
      refetch,
    }): React.ReactElement => (
    <div id="guaranteePayment">
      <GuaranteePayment
        applicationId={applicationId}
        isMobile={isMobile}
        refetch={refetch}
        whoIsClient={application?.type.whoIsClient}
      />
    </div>
  ),

  ads: ({
          scrollY,
          stageConditions,
          applicationId,
        }: {
    scrollY: number;
    stageConditions: StageConditions;
    applicationId: string;
  }): React.ReactElement => (
    <div id="ads" className={styles.objectAd}>
      <ObjectAd scrollY={scrollY} stageConditions={stageConditions} applicationId={applicationId}/>
    </div>
  ),
  documentGeneration: (
    {
      applicationId,
    }: ApplicationCardPayload): React.ReactElement => (
    <div id="documentGeneration">
      <DocumentGeneration
        applicationId={applicationId ?? ""}
      />
    </div>
  ),

  sellerContacts: (
    {
      scrollY,
      applicationId,
      stageConditions,
      kind,
    }: ApplicationCardPayload): React.ReactElement => (
    <div id="sellerContacts">
      <Contacts
        kind={kind}
        type="seller"
        scrollY={scrollY}
        applicationId={applicationId}
        stageConditions={stageConditions}
      />
    </div>
  ),

  buyerContacts: (
    {
      scrollY,
      applicationId,
      stageConditions,
      kind,
    }: ApplicationCardPayload): React.ReactElement => (
    <div id="buyerContacts">
      <Contacts
        kind={kind}
        type="buyer"
        scrollY={scrollY}
        applicationId={applicationId}
        stageConditions={stageConditions}
      />
    </div>
  ),

  requirementsComment: (
    {
      comment,
      scrollY,
      applicationId,
      stageConditions,
      kind
    }: { comment: string } & ApplicationCardPayload): React.ReactElement => (
    <div id="comment" className={styles.comment}>
      <ApplicationComment
        comment={comment}
        scrollY={scrollY}
        applicationId={applicationId}
        stageConditions={stageConditions}
        kind={kind}
      />
    </div>
  ),

  dealProperties: (): React.ReactElement => (
    <div id="dealProperties">
      <DealProperties/>
    </div>
  ),

  dealCharacteristics: (
    {
      applicationId,
      block,
      application
    }: any): React.ReactElement => (
    <div id="dealCharacteristics">
      <DealCharacteristics id={applicationId || ""} block={block} application={application}/>
    </div>
  ),

  dealSellerContacts: (
    {
      scrollY,
      stageConditions,
      responsibleUser,
      refetch,
      kind,
      application,
      isMobile
    }: ApplicationCardPayload & {
      responsibleUser?: User;
      refetch: () => void;
      isMobile?: boolean
    }
  ) => (
    <div id="dealSellerContacts">
      <DealContacts
        scrollY={scrollY}
        applicationId={application?.saleApplicationId ?? ""}
        stageConditions={stageConditions}
        responsibleUser={responsibleUser}
        refetch={refetch}
        kind={kind}
        type="seller"
        isMobile={isMobile}
      />
    </div>
  ),

  dealBuyerContacts: (
    {
      scrollY,
      stageConditions,
      responsibleUser,
      refetch,
      kind,
      application,
      isMobile
    }: ApplicationCardPayload & {
      responsibleUser?: User;
      refetch: () => void;
      isMobile?: boolean
    }
  ) => (
    <div id="dealBuyerContacts">
      <DealContacts
        scrollY={scrollY}
        applicationId={application?.buyApplicationId ?? ""}
        stageConditions={stageConditions}
        responsibleUser={responsibleUser}
        refetch={refetch}
        kind={kind}
        type="buyer"
        isMobile={isMobile}
      />
    </div>
  ),

  lawyer: (
    {
      scrollY,
      applicationId,
      stageConditions,
      application,
      refetch,
      kind,
      isMobile
    }: ApplicationCardPayload & {
      refetch: () => void;
      isMobile?: boolean
    }): React.ReactElement => (
    <div id="lawyer">
      <DealLawyer
        scrollY={scrollY}
        applicationId={applicationId}
        stageConditions={stageConditions}
        lawyer={application?.lawyerUser}
        refetch={refetch}
        kind={kind}
        isMobile={isMobile}
      />
    </div>
  ),

  documents: (
    {
      applicationId,
    }: ApplicationCardPayload): React.ReactElement => (
    <div id="documents">
      <Documents applicationId={applicationId ?? ""}/>
    </div>
  ),

  responsibles: (
    {
      scrollY,
      applicationId,
      stageConditions,
      responsibleUser,
      refetch,
      kind,
      isMobile
    }: ApplicationCardPayload & {
      responsibleUser?: User;
      refetch: () => void;
      isMobile?: boolean
    }): React.ReactElement => (
    <div id="responsibles">
      <Responsibles
        scrollY={scrollY}
        applicationId={applicationId}
        stageConditions={stageConditions}
        responsibleUser={responsibleUser}
        refetch={refetch}
        kind={kind}
        withoutDots
        withoutLinks
        isMobile={isMobile}
        withAvatar
      />
    </div>
  ),

  tasks: (
    {
      scrollY,
      applicationId,
      stageConditions,
      kind,
      refetch,
      isMobile
    }: ApplicationCardPayload & { isMobile?: boolean, refetch: () => void; }): React.ReactElement => (
    <div id="tasks">
      <TasksToCompleteInApplication
        applicationId={applicationId}
        scrollY={scrollY}
        stageConditions={stageConditions}
        kind={kind}
        isMobile={isMobile}
        refetch={refetch}
      />
    </div>
  ),

  agencyAgreement: (
    {
      applicationId,
      isMobile,
      application,
      kind
    }: ApplicationCardPayload & { isMobile?: boolean }): React.ReactElement => (
    <div id="agentDocument" style={{marginTop: "60px", marginBottom: "30px"}}>
      <AgencyAgrement
        applicationId={applicationId ?? ""}
        isMobile={isMobile}
        isArchived={application?.isArchived}
        isApplication={kind === KindType.APPLICATION}
      />
    </div>
  ),

  viewData: (
    {
      scrollY,
      applicationId,
      stageConditions,
      onTab,
    }: ApplicationCardPayload & {
      onTab?: (tab: number) => void;
    }): React.ReactElement => (
    <div id="viewData">
      <ObjectShowsOrViews
        type="views"
        applicationId={applicationId}
        scrollY={scrollY}
        onTab={onTab}
        stageConditions={stageConditions}
      />
    </div>
  ),

  showData: (
    {
      scrollY,
      applicationId,
      stageConditions,
      onTab,
    }: ApplicationCardPayload & {
      onTab?: (tab: number) => void;
    }): React.ReactElement => (
    <div id="showData">
      <ObjectShowsOrViews
        type="shows"
        applicationId={applicationId}
        scrollY={scrollY}
        onTab={onTab}
        stageConditions={stageConditions}
      />
    </div>
  ),

  adsRecommendations: (
    {
      scrollY,
      applicationId,
      stageConditions,
    }: {
      scrollY: number;
      applicationId: string;
      stageConditions: StageConditions;
    }): React.ReactElement => (
    <div id="recommendations">
      <Recommendations scrollY={scrollY} applicationId={applicationId} stageConditions={stageConditions}/>
    </div>
  ),

  jointObject: (
    {
      scrollY,
      addObject,
      onPinObject,
      unpinObject,
      clientObject,
      stageConditions,
      applicationId,
      kind,
      isMobile
    }: {
      scrollY: number;
      clientObject: RealEstate | undefined;
      addObject: (object: RealEstate) => void;
      onPinObject: (object: RealEstate) => void;
      unpinObject: () => void;
      stageConditions: StageConditions;
      applicationId: string;
      isMobile?: boolean
      kind?: "pre-applications" | "applications",
    }): React.ReactElement => (
    <div id="jointObject">
      <ObjectCreation
        kind={kind}
        scrollY={scrollY}
        object={clientObject}
        stageConditions={stageConditions}
        onRemoveObject={unpinObject}
        onObjectChange={addObject}
        onPinObject={onPinObject}
        applicationId={applicationId}
        isMobile={isMobile}
      />
    </div>
  ),

  employees: (
    {
      scrollY,
      applicationId,
      stageConditions,
    }: ApplicationCardPayload & {
      applicationId: string;
    }): React.ReactElement => (
    <div id="employees">
      <Employees scrollY={scrollY} applicationId={applicationId} stageConditions={stageConditions}/>
    </div>
  ),

  mirrorApplication: (
    {
      application,
      whoIsClient,
      isMobile
    }): React.ReactElement => (
    <div id="mirrorApplication">
      <MirrorApplication
        applicationId={application.mirrorApplicationId}
        application={application.mirrorApplication}
        whoIsClient={whoIsClient}
        kind={KindType.APPLICATION}
        isArchived={application.isArchived}
        isMobile={isMobile}
      />
    </div>
  ),

  appliedServicesHistory: (
    {
      applicationId,
      isMobile
    }): React.ReactElement => (
    <AppliedServicesHistory
      applicationId={applicationId}
      isMobile={isMobile}
    />
  ),

  childDeals: (
    {
      applicationId,
      application,
      isMobile
    }): React.ReactElement => (
    <ChildDeals
      applicationId={applicationId}
      isArchived={application.isArchived}
      isMobile={isMobile}
    />
  ),

  jointPodbor: (
    {
      applicationId,
      application,
      isMobile
    }: {
      applicationId: string;
      application: any
      stageConditions: StageConditions;
      refetch: () => void;
      selectedTab: number;
      collectionDescription?: string;
      isMobile?: boolean
    }): React.ReactElement => (
    <div id="jointPodbor">
      <CardObjectBuyWrapper
        applicationId={application.applicationTemplateId ?? applicationId}
        isMobile={isMobile}
      />
    </div>
  ),
  quiz: (
    {
      title,
      questions,
      additionalProperties,
      stageConditions,
      applicationId,
      refetch,
      kind
    }: {
      title: string;
      questions: QuizQuestion[];
      additionalProperties: PropsAdditionalProperties;
      stageConditions: StageConditions;
      applicationId: string;
      refetch: () => void;
      kind: KindType
    }): React.ReactElement => (
    <div>
      <Quiz
        title={title}
        questions={questions}
        stageConditions={stageConditions}
        additionalProperties={additionalProperties}
        applicationId={applicationId}
        refetch={refetch}
        kind={kind}
      />
    </div>
  ),
};
