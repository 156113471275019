import styles from "./index.module.scss"
import { ReactComponent as Play } from "../../../../../assets/icons/iconVideoPlaySmall.svg";
import { useOpenSuccessCreateModal } from "../success-modal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Contact, ContactTypeMap, CreateGuaranteePayment, LeadSourceEnum } from "../../../../../types";
import { commonErrors } from "../../../../../utils/errors";
import { useEffect, useState } from "react";
import { FieldErrors, FieldValues, useForm } from "react-hook-form";
import { formatDateWithTime } from "../../../../../utils/date";
import { useFormWatcher } from "../helpers";
import { useContacts, useLoadApplications } from "../../../../../service/api/queries";
import {
  useGetAdSources
} from "../../../layout/SidebarLayout/Sidebar/components/AddModal/helpers/useAddLeadModal/components/CreateContactModalForm/helpers";
import cn from "classnames";
import { commonPlaceholders } from "../../../../../utils/placeholders";
import { phoneMask } from "../../../../../utils/masks";
import { Button, InfoRow } from "@sdelka_crm/sdelka-crm-component-library";
import { Select, TransparentInput } from "../../..";
import { CardApplicationMini } from "../../../Card/card-application/card-application-mini";
import { CardApplication } from "../../../Card/card-application";
import { Plug } from "../../helpers";
import {
  ContactCard
} from "../../../../pages/Application/PreApplicationTemplate/components/AgencyAgrement/components/ContactCard/ContactCard";
import {
  useContactCreate,
  useCreateGuaranteePaymentWithExistsApplication,
  useCreateGuaranteePaymentWithNewApplication
} from "../../../../../service/api/mutations";
import { useIsTelegram } from "../../../../../utils/telegram/useIsTelegram";

type SourceOption = {
  label: string;
  value: LeadSourceEnum;
};

type Props = {
  applicationId: string
  refetchList: () => void
  isMobile?: boolean
  payloadTerms: CreateGuaranteePayment
}

export const SelectContactModal = (
  {
    applicationId,
    refetchList,
    isMobile,
    payloadTerms
  }: Props) => {
  const schema = yup.object().shape({
    phone: yup.string().required(commonErrors.required),
    pseudonym: yup.string().required(commonErrors.required),
    type: yup.string().required(commonErrors.required),
    source: yup.string().required(commonErrors.required),
  })

  const [currentTab, setCurrentTab] = useState(2)
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(new Date)
  const [selectedContact, setSelectedContact] = useState<Contact | undefined>(undefined)
  const [selectedAppId, setSelectedAppId] = useState<string | undefined>(undefined)
  const [error, setError] = useState<FieldErrors<FieldValues> | undefined>(undefined)

  const {register, getValues, setValue, control, handleSubmit, formState} = useForm({
    resolver: currentTab !== 2 ? undefined : (selectedContact ? undefined : yupResolver(schema))
  });

  const selectApplication = (id: string) => {
    if (id === selectedAppId) {
      setSelectedAppId(undefined)
    } else {
      setSelectedAppId(id)
    }
  }

  const {errors} = formState

  useEffect(() => {
    setError(errors)
  }, [errors])

  const tabs = [
    {
      label: "Дата и время",
      value: selectedDate
        ? `${formatDateWithTime(selectedDate, true, true)} ${formatDateWithTime(selectedDate, true, false)}`
        : "Укажите дату и время",
      id: 1
    },
    {
      label: "Контакт",
      value: selectedContact ? `${selectedContact.pseudonym} ${selectedContact.lastName} ${selectedContact.name}` : "Выберите или создайте контакт",
      id: 2
    },
    {
      label: "Заявка",
      value: "Выберите или создайте заявку",
      id: 3
    }
  ]

  const {executeUrl} = useIsTelegram()

  const onClickVideoLesson = () => {
    executeUrl(`https://www.youtube.com/@sdelka.sakhar`)
  }

  const {
    typeWatcher,
    sourceWatcher,
    pseudonymWatcher,
    phoneWatcher,
  } = useFormWatcher({
    control,
    getValues,
  });

  function timeToMilliseconds(timeStr?: string) {
    if (timeStr === undefined) {
      return 0
    }
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours * 60 * 60 * 1000 + minutes * 60 * 1000;
  }

  const [hasDateError, setHasDateError] = useState(true)
  const [hasPhoneError, setHasPhoneError] = useState(true)
  const [time] = useState("")
  const date1 = new Date(getValues("deadlineDate"));
  const dateError = !getValues("deadlineDate") ? "Выберите дату" : undefined
  const date2 = new Date(timeToMilliseconds(time));
  const timeError = +time < 60000 ? "Выберите время" : undefined
  const sumMilliseconds = date1.getTime() + date2.getTime();
  const sumDate = new Date(sumMilliseconds);

  const {contactsList, setFilter} = useContacts({
    enabled: String(phoneWatcher).replaceAll(" ", "").replaceAll("_", "").length === 14
  })
  const {list} = useLoadApplications({
    enabled: selectedContact !== undefined,
    defaultFilter: {
      contacts: {
        contactId: selectedContact?.id
      },
      whoIsClient: "buyer"
    }
  })

  useEffect(() => {
    if (String(phoneWatcher).replaceAll(" ", "").replaceAll("_", "").length === 14) {
      setFilter({
        phoneForSearch: String(phoneWatcher)
          .replaceAll(" ", "")
          .replaceAll("+", "")
          .replaceAll("(", "")
          .replaceAll(")", "")
      })
    }
  }, [phoneWatcher, setFilter])

  useEffect(() => {
    if (currentTab === 1 && !dateError && !timeError) {
      setHasDateError(false)
    } else {
      setHasDateError(true)
    }
    if (String(phoneWatcher).replaceAll(" ", "").replaceAll("_", "").length === 14) {
      setHasPhoneError(false)
    } else {
      setHasPhoneError(true)
    }
  }, [currentTab, dateError, phoneWatcher, timeError])

  const typeOptions = Object.keys(ContactTypeMap).map((key) => ({
    value: key,
    label: ContactTypeMap[ key ],
  }))
  const {adSources} = useGetAdSources()
  const adSourceOptions: SourceOption[] = adSources.map(el => Object.create({label: el, value: el}))

  const {openSuccessCreateModal} = useOpenSuccessCreateModal()

  const onSuccessCreate = () => {
    refetchList()
    openSuccessCreateModal()
  }

  const {
    createGuaranteePaymentWithExistsApplication,
    isLoading
  } = useCreateGuaranteePaymentWithExistsApplication({onSuccess: onSuccessCreate})
  const {
    createGuaranteePaymentWithNewApplication,
    isLoading: isLoadingCreate
  } = useCreateGuaranteePaymentWithNewApplication({onSuccess: onSuccessCreate})

  const onSuccessCreateContact = (data: Contact) => {
    createGuaranteePaymentWithNewApplication({
      applicationId,
      payload: {
        ...payloadTerms,
        contactId: data.id,
        leadSource: sourceWatcher
      }
    })
  }

  const {
    mutate: createContact,
    isLoading: isLoadingCreateContact
  } = useContactCreate({onSuccess: onSuccessCreateContact})

  const onApply = () => {
    if (currentTab === 1) {
      setSelectedDate(sumDate)
      setCurrentTab(currentTab + 1)
    }
    if (currentTab === 2) {
      if (contactsList.length !== 1) {
        const payload = {
          pseudonym: pseudonymWatcher,
          type: typeWatcher,
          phones: [{
            phone: String(phoneWatcher)
              .replaceAll(" ", "")
              .replaceAll("+", "")
              .replaceAll("(", "")
              .replaceAll(")", "")
            , type: "main"
          }],
        }

        createContact(payload)
      }
    }
    if (currentTab === 3) {
      if (selectedAppId) {
        createGuaranteePaymentWithExistsApplication({
          applicationId,
          payload: {
            ...payloadTerms,
            applicationId: selectedAppId ?? ""
          }
        })
      } else {
        createGuaranteePaymentWithNewApplication({
          applicationId,
          payload: {
            ...payloadTerms,
            contactId: selectedContact?.id || "",
            leadSource: sourceWatcher
          }
        })
      }
    }
  }

  const onSelectContact = () => {
    setSelectedContact(contactsList[ 0 ])
    setCurrentTab(currentTab + 1)
  }

  useEffect(() => () => {
      setSelectedContact(undefined)
    }, [])

  const disableButton = () => {
    if (currentTab === 1) {
      return hasDateError
    }
    if (currentTab === 2) {

      return hasPhoneError
    }
    return false
  }

  const buttonText = () => {
    if (currentTab === 1) {
      return "Далее"
    }
    if (currentTab === 2) {
      if (contactsList.length === 1) {
        return "Прикрепить"
      }
      return `Создать ${isMobile ? "" : "новый контакт и заявку"}`
    }
    return "Прикрепить заявку на покупку"
  }

  return (
    <div className={styles.root}>
      <form onSubmit={handleSubmit(onApply)}>
        <div className={styles.title}>
          Создайте покупателя
        </div>
        <div className={styles.tabsRow}>
          {tabs.map(elem => (
            <div className={cn(styles.tab, {[ styles.currentTab ]: currentTab === elem.id})}>
              <div className={cn(styles.tabLabel, {[ styles.tabLabelExist ]: currentTab > elem.id})}>{elem.label}</div>
              <div className={cn(styles.tabValue, {[ styles.tabValueExist ]: currentTab > elem.id})}>{elem.value}</div>
            </div>
          ))}
        </div>
        {currentTab === 2 &&
          <div className={styles.formList}>
            <InfoRow label="Телефон" classNames={{root: styles.row}} aspect={{label: 5, content: 7}}>
              <TransparentInput
                name="phone"
                register={register}
                placeholder={commonPlaceholders.notSpecified}
                mask={phoneMask}
                setValueForMask={setValue}
                value={phoneWatcher}
              />
            </InfoRow>
            <InfoRow label="Псевдоним" classNames={{root: styles.row}} aspect={{label: 5, content: 7}}>
              <TransparentInput
                className={styles.input}
                placeholder={commonPlaceholders.notSpecified}
                name="pseudonym"
                withCleanUp={{setValue}}
                register={register}
                value={pseudonymWatcher}
              />
              {error?.pseudonym && <div className={styles.error}>{error?.pseudonym?.message?.toString()}</div>}
            </InfoRow>
            <InfoRow label="Тип" classNames={{root: styles.row}} aspect={{label: 5, content: 7}}>
              {typeOptions && <Select
                name="type"
                defaultValue="customer"
                register={register}
                setValue={setValue}
                options={typeOptions}
                // onChange={onChangeType}
                placeholder="Выбрать"
                classNames={{
                  arrowContainer: styles.arrow,
                  root: styles.selectRoot
                }}
              />}
              {error?.type && <div className={styles.error}>{error?.type?.message?.toString()}</div>}
            </InfoRow>
            {contactsList.length !== 1 && adSources && adSources.length > 0 &&
              <InfoRow label="Источник" classNames={{root: styles.row}} aspect={{label: 5, content: 7}}>
                {adSourceOptions &&
                  <Select
                    name="source"
                    defaultValue={adSourceOptions[ 0 ].value}
                    register={register}
                    setValue={setValue}
                    options={adSourceOptions}
                    // onChange={onChangeType}
                    placeholder="Выбрать"
                    classNames={{
                      arrowContainer: styles.arrow,
                      root: styles.selectRoot
                    }}
                  />}
                {error?.source && <div className={styles.error}>{error?.source?.message?.toString()}</div>}
              </InfoRow>
            }
            <div className={styles.subtitle}>
              Совпадение по номеру телефона
            </div>
            {contactsList && contactsList.length === 1 ?
              <div className={styles.selectedContact}>
                <ContactCard data={contactsList[ 0 ]} isMobile={isMobile} withoutTitle atAC={isMobile}/>
              </div>
              : <Plug plugText="Контактов не найдено" classNames={styles.plug} mini={isMobile}/>
            }
          </div>
        }
        {currentTab === 3 &&
          <div>
            <div className={styles.subtitle}>
              Заявки контакта
            </div>
            <div className={styles.applicationList}>
              {list && list.map(el => (
                <div className={cn(styles.application, {[ styles.applicationSelected ]: el.id === selectedAppId})}
                     onClick={() => selectApplication(el.id)}>
                  {!isMobile
                    ? <CardApplication application={el} withStage kind={el.shortType.kind}/>
                    : <CardApplicationMini application={el} isMobile={isMobile} withoutStage/>
                  }
                </div>
              ))}
            </div>
          </div>
        }
        <div className={styles.footer}>
          <div className={styles.videoLink} onClick={onClickVideoLesson}>
            <Play/>
            <div>
              Видео-инструкция
            </div>
          </div>
          {currentTab !== 2 && <Button
            type="submit"
            disabled={disableButton()}
            isLoading={currentTab === 3 ? isLoading : (isLoadingCreateContact || isLoadingCreate)}
          >
            {buttonText()}
          </Button>}
          {currentTab === 2 && <>
            {contactsList.length === 1
              ? <Button
                type="button"
                onClick={onSelectContact}
                disabled={disableButton()}
                isLoading={isLoadingCreate}
              >
                {buttonText()}
              </Button>
              : <Button
                type="submit"
                disabled={disableButton()}
                isLoading={isLoadingCreateContact || isLoadingCreate}
              >
                {buttonText()}
              </Button>
            }
          </>
          }
        </div>
      </form>
    </div>
  )
}