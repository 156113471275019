import { addHours, addMinutes } from "date-fns";

type Props = {
	date?: Date;
	time?: string;
};

export const getDeadlineByDateAndTime = ({ date, time }: Props) => {
	const splitedTime = time?.split(":");
	const [hours, minutes] = splitedTime || [0, 0];

	const choseDate = date ? new Date(date) : new Date();

	return time
		? addMinutes(addHours(choseDate, Number(hours)), Number(minutes)).toISOString()
		: choseDate.toISOString();
};
