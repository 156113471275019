import { Dispatch, SetStateAction } from "react";
import { ApplicationFilterPayload, LeadStatusEnum, Stage } from "../../../../../types";
import { ApplicationStatus, LeadStatus } from "./components";
import styles from "./index.module.scss";

type Props = {
  filterType: "traffics" | "leads" | "requests" | "contracts" | "deals" | "contacts";
  filter: ApplicationFilterPayload | any;
  requestStages: Stage[];
  setFilter: Dispatch<SetStateAction<ApplicationFilterPayload>>;
};

export const StatusFilter = ({filterType, filter, setFilter, requestStages}: Props): JSX.Element => {
  const handleFilterClick = (status: LeadStatusEnum | string, fieldName: string) => {
    const newSelected = filter?.[ fieldName ] ? [...filter[ fieldName ]] : [];

    if (newSelected && newSelected.includes(status)) {
      const index = newSelected.indexOf(status);

      if (index > -1) {
        newSelected.splice(index, 1);
        setFilter({
          ...filter,
          [ fieldName ]: [...newSelected],
        });

        return;
      }
    }

    newSelected.push(status);

    setFilter({
      ...filter,
      [ fieldName ]: [...newSelected],
    });
  };

  const isShow = () => {
    switch (filterType) {
      case "traffics":
        return <></>;
      case "leads":
        return <LeadStatus
          filter={filter}
          setFilter={setFilter}
          handleFilterClick={handleFilterClick}
        />;
      case "requests":
        return (
          <ApplicationStatus
            filter={filter}
            setFilter={setFilter}
            requestStages={requestStages}
            handleFilterClick={handleFilterClick}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      <div className={styles.root}>{isShow()}</div>
    </div>
  );
};
