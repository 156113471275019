import { H1 } from "@sdelka_crm/sdelka-crm-component-library";

import { CreateOfficeForm, OfficeCard } from "./components";
import { useLoadOffices } from "../../../service/api/queries";
import { Office } from "../../../types";

import styles from "./index.module.scss";

export const OfficeSettings = (): JSX.Element => {
	const { offices, refetch } = useLoadOffices({});

	return (
		<div className={styles.root}>
			<H1>Настройки офисов</H1>

			{offices?.map((office: Office) => (
				<OfficeCard key={office.id} refetchOffices={refetch} office={office} />
			))}

			<CreateOfficeForm refetchOffices={refetch} />
		</div>
	);
};
