import { MouseEvent as MouseEventReact, TouchEvent, useEffect, useRef, useState } from "react";

import styles from "./index.module.scss";

const strokeStyle = "#336BEB";
const lineWidth = 3;

type Props = {
	coordsHandler: (coords: number[][]) => void;
};

type CanvasSizeType = {
	width: number;
	height: number;
};

/**
 * Компонент для рисования полигонов.
 */
export const Canvas = ({ coordsHandler }: Props): JSX.Element => {
	const ref = useRef<HTMLCanvasElement>(null);
	const [draw, setDraw] = useState(false);
	const [coordinates, setCoordinates] = useState<number[][]>([]);
	const [context, setContext] = useState<CanvasRenderingContext2D>();
	const [canvasSize, setCanvasSize] = useState<CanvasSizeType>({
		width: 0,
		height: 0,
	});

	useEffect(() => {
		const canvasParent = ref.current?.parentElement;

		if (!canvasParent) return;

		const setSizes = () => {
			const { clientHeight: height, clientWidth: width } = canvasParent;

			setCanvasSize({ width, height });
		};

		setSizes();
		window.addEventListener("resize", setSizes);

		// eslint-disable-next-line consistent-return
		return () => {
			window.removeEventListener("resize", setSizes);
		};
	}, []);

	useEffect(() => {
		const canvas = ref.current;

		if (!canvas) return;

		const ctx = canvas.getContext("2d")!;
		setContext(ctx);

		canvas.style.width = `${canvasSize.width}px`;
		canvas.style.height = `${canvasSize.height}px`;
		canvas.width = canvasSize.width;
		canvas.height = canvasSize.height;
	}, [canvasSize]);

	useEffect(() => {
		const canvas = ref.current;
		if (context && canvas) {
			context.strokeStyle = strokeStyle;
			context.lineWidth = lineWidth;

			context.clearRect(0, 0, canvas.width, canvas.height);
		}
	}, [context]);

	const onMouseDownHandler = (e: MouseEventReact<HTMLCanvasElement, MouseEvent>) => {
		setDraw(true);
		setCoordinates((lastCoords) => [...lastCoords, [e.nativeEvent.offsetX, e.nativeEvent.offsetY]]);
	};

	const onTouchDownHandler = (e: TouchEvent<HTMLCanvasElement>) => {
		setDraw(true);
		setCoordinates((lastCoords) => [...lastCoords,
			[e.nativeEvent.touches.item(0)!.clientX,
				e.nativeEvent.targetTouches.item(0)!.clientY - 50]]);
	};

	const onMouseMoveHandler = (e: MouseEventReact<HTMLCanvasElement, MouseEvent>) => {
		if (draw && context) {
			const last = coordinates[coordinates.length - 1];
			context.beginPath();
			context.moveTo(last[0], last[1]);
			context.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
			context.stroke();

			// eslint-disable-next-line sonarjs/no-identical-functions
			setCoordinates((lastCoords) => [...lastCoords, [e.nativeEvent.offsetX, e.nativeEvent.offsetY]]);
		}
	};

	const onTouchMoveHandler = (e: TouchEvent<HTMLCanvasElement>) => {
		if (draw && context) {
			const last = coordinates[coordinates.length - 1];
			context.beginPath();
			context.moveTo(last[0], last[1]);
			context.lineTo(e.nativeEvent.touches.item(0)?.clientX!,
				e.nativeEvent.touches.item(0)?.clientY! - 50);
			context.stroke();

			// eslint-disable-next-line sonarjs/no-identical-functions
			setCoordinates((lastCoords) => [...lastCoords, [e.nativeEvent.touches.item(0)?.clientX!,
				e.nativeEvent.touches.item(0)?.clientY! - 50]]);
		}
	};

	const onTouchUpHandler = (e: TouchEvent<HTMLCanvasElement>) => {
		coordinates.push([e.nativeEvent.touches.item(0)?.clientX!,
			e.nativeEvent.touches.item(0)?.clientY! - 50]);
		const canvas = ref.current;

		if (!canvas || !context) return;

		setDraw(false);
		const newCoords = coordinates.map((coords) => [coords[0] / canvas.width, coords[1] / canvas.height]);

		// Add to end of coords array first coord to close ring
		newCoords.push([coordinates[0][0] / canvas.width, coordinates[0][1] / canvas.height]);

		setCoordinates([]);

		coordsHandler(newCoords);
		context.clearRect(0, 0, canvas.width, canvas.height);
	};

	const onMouseUpHandler = (e: MouseEventReact<HTMLCanvasElement, MouseEvent>) => {
		coordinates.push([e.nativeEvent.offsetX, e.nativeEvent.offsetY]);
		const canvas = ref.current;

		if (!canvas || !context) return;

		setDraw(false);
		const newCoords = coordinates.map((coords) => [coords[0] / canvas.width, coords[1] / canvas.height]);

		// Add to end of coords array first coord to close ring
		newCoords.push([coordinates[0][0] / canvas.width, coordinates[0][1] / canvas.height]);

		setCoordinates([]);

		coordsHandler(newCoords);
		context.clearRect(0, 0, canvas.width, canvas.height);
	};

	return (
		<canvas
			ref={ref}
			className={styles.drawCanvas}
			onMouseDown={onMouseDownHandler}
			onMouseMove={onMouseMoveHandler}
			onMouseUp={onMouseUpHandler}
			onTouchStart={onTouchDownHandler}
			onTouchEnd={onTouchUpHandler}
			onTouchMove={onTouchMoveHandler}
		/>
	);
};
