import { SampleType } from "../../../../types/real-estate";

type Props = {
	isCompilation: boolean;
	isCompare: boolean;
};

type Return = {
	sampleType: () => SampleType;
};

type UseSampleType = (props: Props) => Return;

export const useSampleType: UseSampleType = ({ isCompilation, isCompare }) => {
	const sampleType = (): SampleType => {
		if (isCompilation) return "compilation";
		if (isCompare) return "compare";

		return "none";
	};

	return { sampleType };
};
