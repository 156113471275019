import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { RealEstateGetConfigPayload } from "../../../types";
import { cleanUp } from "../../../service/redux/modules/object-edit/object-edit.action";
import { useLoadConfig } from "../../../service/api/queries";

type Props = {
	defaultCategory: RealEstateGetConfigPayload;
};

export const useLoadCreateConfig = ({ defaultCategory }: Props) => {
	const dispatch = useDispatch();

	const {
		objectConfig,
		isFetching: isConfigLoading,
		currentCategory,
		removeData: removeConfig,
	} = useLoadConfig({ defaultCategory });

	useEffect(() => {
		dispatch(cleanUp());
		removeConfig();

		return () => {
			removeConfig();
			dispatch(cleanUp());
		};
	}, []);

	return {
		objectConfig,
		isConfigLoading,
		currentCategory,
	};
};
