import { useQuery } from "react-query";
import { api } from "../../api";

const DEFAULT_QN = "sdelka/pre-application/application-template/DEFAULT_QN";

type UseLoadPreApplicationTemplate = {
  applicationId: string;
};

export const useLoadPreApplicationTemplate = (
  {
    applicationId,
  }: UseLoadPreApplicationTemplate) => {
  const loadPreApplicationTemplate = () => api.preApplicationTemplate.load({applicationId});
  const {data, refetch, remove, isLoading, isFetched} = useQuery(DEFAULT_QN, loadPreApplicationTemplate);

  return {
    data: data?.data,
    isLoading,
    refetch,
    remove,
    isFetched,
  };
};
