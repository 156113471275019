import { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { Col, Container, Row, TextField, TogglePass } from "../../../../UI";
import { LoadingRows, TermsBlock } from "../../components";
import { validationFirstStepSchema } from "../formResolvers";
import {
	setCompanyData,
	setCompanyName,
	setShowCompany,
	setStep,
} from "../../../../../service/redux/modules/registration-steps/reg-steps.action";
import { useRegistration } from "../../../../../service/api/mutations";
import { useLoadCompanyPublic } from "../../../../../service/api/queries";
import { getFlag } from "../../../../../utils/getFlag";
import { paths } from "../../../../../utils/paths";
import { phoneMask } from "../../../../../utils/masks";
import { formatPhone } from "../../../../../utils/formatters";
import { useRegisteredPhoneRedirect } from "./utils";
import { Invite, RegistrationPayload } from "../../../../../types";

import { ReactComponent as InfoIcon } from "../../../../../assets/icons/info.svg";
import { ReactComponent as LockIcon } from "../../../../../assets/icons/lock.svg";
import { ReactComponent as PhoneIcon } from "../../../../../assets/icons/phone.svg";
import { ReactComponent as UserIcon } from "../../../../../assets/icons/user-icon.svg";
import styles from "./index.module.scss";

type Props = {
	invite: Invite;
};

export const InvitePersonalDataForm = ({ invite }: Props): JSX.Element => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [flagIcon, setFlagIcon] = useState<ReactNode | undefined>(undefined);
	const [passwordInputType, setPasswordInputType] = useState<"password" | "text">("password");

	const { companyPublic, isLoading } = useLoadCompanyPublic({
		id: invite.companyId,
	});

	useRegisteredPhoneRedirect(invite.phoneIsRegistered, invite.accountPhone);

	useEffect(() => {
		if (companyPublic) {
			dispatch(setCompanyData(companyPublic));
		}
	}, [companyPublic]);

	const { control, handleSubmit, setError, formState } = useForm({
		resolver: yupResolver(validationFirstStepSchema),
		defaultValues: {
			name: "",
			lastName: "",
			phone: invite.type === "Публичная ссылка" ? "" : formatPhone(invite.accountPhone),
			password: "",
		},
	});

	const onRegistrationSuccess = () => {
		dispatch(setCompanyName(companyPublic?.companyName || ""));
		dispatch(setStep("sms-code"));
	};

	const { mutate } = useRegistration({
		onSuccess: onRegistrationSuccess,
		setError,
	});

	useEffect(() => {
		if (!invite.active && companyPublic) {
			history.push({
				pathname: paths.registrationPath.default,
				search: `?companyName=${companyPublic.companyName}`,
			});
		}
	}, []);

	const onSubmit = async (values: RegistrationPayload) => mutate(values);

	const getFlagIcon = (phone: string) => {
		setFlagIcon(getFlag(phone));
	};

	if (isLoading) {
		return <LoadingRows />;
	}

	return (
		<div className={styles.root}>
			<h1>
				Регистрация в компанию
				{` «${companyPublic?.companyName}»`}
				{invite.type === "Публичная ссылка" ? (
					<InfoIcon
						onMouseOver={() => dispatch(setShowCompany(true))}
						onMouseOut={() => dispatch(setShowCompany(false))}
						className={styles.companyInfo}
					/>
				) : undefined}
			</h1>

			<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
				<Container>
					<Row marginBottom={20}>
						<Col xs={6}>
							<TextField
								control={control}
								label="Имя"
								name="name"
								prepend={<UserIcon />}
								error={formState?.errors?.name?.message?.toString()}
							/>
						</Col>
						<Col xs={6}>
							<TextField
								control={control}
								label="Фамилия"
								name="lastName"
								error={formState?.errors?.lastName?.message?.toString()}
							/>
						</Col>
					</Row>

					<Row marginBottom={20}>
						<Col xs={12}>
							<TextField
								control={control}
								disabled={invite.type === "Персональная ссылка"}
								label="Телефон"
								name="phone"
								mask={invite.type === "Персональная ссылка" ? undefined : phoneMask}
								onChange={getFlagIcon}
								prepend={<PhoneIcon />}
								append={flagIcon}
								error={formState?.errors?.phone?.message?.toString()}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<TextField
								label="Пароль для входа в систему"
								hintText="Пароль должен содержать заглавные и строчные буквы, цифры и спецсимволы"
								type={passwordInputType}
								name="password"
								autoComplete={false}
								control={control}
								error={formState?.errors?.password?.message?.toString()}
								append={
									<TogglePass
										type={passwordInputType}
										onClick={() =>
											setPasswordInputType((prev) => {
												if (prev === "password") {
													return "text";
												}

												return "password";
											})
										}
									/>
								}
								prepend={<LockIcon />}
							/>
						</Col>
					</Row>
				</Container>

				<Button type="submit" classNames={{ root: styles.button }}>
					Зарегистрироваться
				</Button>

				<TermsBlock />
			</form>
		</div>
	);
};
