import { useOpenLeadInDrawer, useQueryParams } from "../../../utils/hooks";
import { KindType, WhoIsClient } from "../../../types";
import { useOpenLead } from "../../../components/pages/LeadsAndDeals/helpers";
import { openDrawer } from "../../redux/modules/drawer/drawer.action";
import { ContactInfo } from "../../../components/pages/ContactCard";
import {
  useChangeTab,
  useOpenApplication,
  useOpenDeal,
  useOpenObjectApplication,
  useOpenObjectPreApplication,
  useOpenOfficeCard,
  useOpenParserObject,
  useOpenPreApplication,
  useOpenProductCard,
  useOpenProductOrder,
  useOpenProfileCard
} from "../drawer-service";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { selectDevice, useSelect } from "../../redux/selectors";

type Props = {
  refetch?: () => void
  setCurTab?: React.Dispatch<React.SetStateAction<number>>
  curTab?: number
}

export const useUrlWatcher = (
  {
    refetch,
    setCurTab,
    curTab
  }: Props) => {
  const {queryParams, removeQueryParam} = useQueryParams<{
    tab: string;
    leadId: string;
    mode: string
    client: WhoIsClient;
    preApplicationId: string
    applicationId: string
    dealId: string
    contactId: string
    parserObjectId: string
    whoIsClient: string
    officeId: string
    userId: string
    productId: string
    accountId: string
    orderId: string
  }>([
    {name: "tab"},
    {name: "mode"},
    {name: "leadId"},
    {name: "client"},
    {name: "preApplicationId"},
    {name: "applicationId"},
    {name: "dealId"},
    {name: "parserObjectId"},
    {name: "contactId"},
    {name: "whoIsClient"},
    {name: "officeId"},
    {name: "userId"},
    {name: "productId"},
    {name: "accountId"},
    {name: "orderId"},
  ]);

  const {
    leadId,
    tab,
    mode,
    preApplicationId,
    applicationId,
    dealId,
    contactId,
    parserObjectId,
    whoIsClient,
    userId,
    officeId,
    productId,
    orderId
  } = queryParams;

  const dispatch = useDispatch();
  const {isMobile} = useSelect(selectDevice)
  const updateCurTab = (index: number): void => {
    if (curTab !== index) {
      if (setCurTab) {
        setCurTab(index);
      }
    }
  };

  const closeDrawer = () => {
    if (refetch) {
      refetch()
    }
    removeQueryParam("contactId");
  };

  const navigationY = [{label: "Контакт"}];
  const {handleOpenLead} = useOpenLead({refetch});

  const openContactDrawer = () => {
    dispatch(
      openDrawer({
        children: <ContactInfo contactId={queryParams.contactId} refetchList={refetch}/>,
        width: 800,
        onClose: closeDrawer,
        yNavigation: {
          initialTab: 0,
          navigation: navigationY,
        },
      })
    );
  };

  const {openPreApplication} = useOpenPreApplication()
  const {openApplication} = useOpenApplication()
  const {openObjectApplication} = useOpenObjectApplication({})
  const {openObjectPreApplication} = useOpenObjectPreApplication({})
  const {openParserObject} = useOpenParserObject({atDrawerInDrawer: leadId !== undefined && parserObjectId !== undefined})
  const {changeTab} = useChangeTab({})
  const {openDeal} = useOpenDeal({})
  const {openProfileDrawer} = useOpenProfileCard({refetch})
  const {openProductOrderDrawer} = useOpenProductOrder({refetch})
  const {openProductDrawer} = useOpenProductCard({refetch})
  const {openOfficeDrawer} = useOpenOfficeCard({refetch})
  const {openLeadInDrawer} = useOpenLeadInDrawer({})

  useEffect(() => {
    if (userId) {
      openProfileDrawer(userId)
    }
  }, [])

  useEffect(() => {
    if (parserObjectId && !leadId) {
      openParserObject({
        parsedRealEstateId: parserObjectId,
        hasMap: true
      })
    }

    if (orderId) {
      openProductOrderDrawer(orderId)
    }

    if (parserObjectId && leadId) {
      openLeadInDrawer({id: leadId})
      setTimeout(() => {
        openParserObject({
          parsedRealEstateId: parserObjectId,
          hasMap: false
        })
      }, 100)
    }
  }, [])

  useEffect(() => {
    if (tab) {
      switch (tab) {
        case "contacts":
          if (contactId) {
            setTimeout(() => {
              openContactDrawer();
            }, 0);
          }
          updateCurTab(0);
          break;
        case "leads": {
          if (leadId) {
            setTimeout(() => {
              handleOpenLead(leadId);
            }, 0);
          }
          if (preApplicationId) {
            setTimeout(() => {
              openPreApplication({
                applicationId: preApplicationId,
                atDrawerInDrawer: !isMobile,
                refetchLead: refetch,
                isFromLead: true,
                whoIsClient: whoIsClient as WhoIsClient
              })
            }, 200)

            if (mode) {
              setTimeout(() => {
                changeTab({title: mode})
              }, 300)
            }
          }
          if (applicationId) {
            setTimeout(() => {
              openApplication({
                applicationId,
                atDrawerInDrawer: !isMobile,
                kind: KindType.APPLICATION,
                refetchParent: refetch,
                whoIsClient: whoIsClient as WhoIsClient
              })
            }, 300);

            if (mode) {
              setTimeout(() => {
                changeTab({title: mode})
              }, 300)
            }
          }

          if (dealId) {
            setTimeout(() => {
              openDeal({
                applicationId: dealId,
              })
            }, 300);

            if (mode) {
              setTimeout(() => {
                changeTab({title: mode})
              }, 300)
            }
          }

          if (mode) {
            setTimeout(() => {
              changeTab({title: mode})
            }, 100)
          }

          updateCurTab(1);
          break;
        }
        case "applications": {
          if (preApplicationId) {
            setTimeout(() => {
              openPreApplication({
                applicationId: preApplicationId,
                atDrawerInDrawer: false,
                refetchLead: refetch,
                isFromLead: false,
                whoIsClient: whoIsClient as WhoIsClient
              })
            }, 0);

            if (mode) {
              setTimeout(() => {
                changeTab({title: mode})
              }, 100)
            }
          }
          if (applicationId) {
            setTimeout(() => {
              openApplication({
                applicationId,
                atDrawerInDrawer: false,
                hasMap: true,
                kind: KindType.APPLICATION,
                whoIsClient: whoIsClient as WhoIsClient
              })
            }, 0);

            if (mode) {
              setTimeout(() => {
                changeTab({title: mode})
              }, 100)
            }
          }

          if (dealId) {
            setTimeout(() => {
              openDeal({
                applicationId: dealId,
              })
            }, 300);

            if (mode) {
              setTimeout(() => {
                changeTab({title: mode})
              }, 300)
            }
          }

          updateCurTab(2);
          break;
        }
        case "AGENCY": {
          if (preApplicationId) {
            setTimeout(() => {
              openObjectPreApplication({
                applicationId: preApplicationId,
                refetchLead: refetch,
                isFromLead: true
              })

              if (mode) {
                setTimeout(() => {
                  changeTab({title: mode})
                }, 100)
              }
            }, 0);

          }
          if (applicationId) {
            setTimeout(() => {
              openObjectApplication({
                applicationId,
                atDrawerInDrawer: true,
                hasMap: true,
                kind: KindType.APPLICATION
              })
            }, 0);

            if (mode) {
              setTimeout(() => {
                changeTab({title: mode})
              }, 100)
            }
          }
          updateCurTab(1);
          break;
        }

        case "PARSED": {
          if (parserObjectId && !leadId && !preApplicationId) {
            openParserObject({
              parsedRealEstateId: parserObjectId,
              hasMap: true
            })
          }

          if (parserObjectId && leadId) {
            openLeadInDrawer({id: leadId})
            setTimeout(() => {
              openParserObject({
                parsedRealEstateId: parserObjectId,
                hasMap: false
              })
            }, 100)
          }

          if (leadId && preApplicationId) {
            openLeadInDrawer({id: leadId})
            setTimeout(() => {
              openPreApplication({
                applicationId: preApplicationId,
                atDrawerInDrawer: !isMobile,
                refetchLead: refetch,
                isFromLead: false,
                whoIsClient: whoIsClient as WhoIsClient,
                onClose: () => {
                  openLeadInDrawer({id: leadId})
                }
              })

              if (mode) {
                setTimeout(() => {
                  changeTab({title: mode})
                }, 100)
              }
            }, 100);

          }
          break
        }

        case "EMPLOYERS":
          if (userId) {
            openProfileDrawer(userId)
          }
          break

        case "OFFICES":
          if (officeId) {
            openOfficeDrawer(officeId)
          }
          break

        case "PRODUCTS":
          if (productId) {
            openProductDrawer(productId)
          }
          break

        case "ORDERS":
          if (orderId) {
            openProductOrderDrawer(orderId)
          }
          break

        case "deals":
          if (dealId) {
            setTimeout(() => {
              openDeal({
                applicationId: dealId,
              })
            }, 0);
          }
          updateCurTab(3);
          break;

        default:
          updateCurTab(0);
          break;
      }
    }
  }, [tab]);
}