import { DefaultTasksFiltersType } from "../../../../types";
import { useHistory } from "react-router-dom";
import { getQueryVariable } from "./getQueryVariable";

export const useFilterQuery = (defaultTasksFilter: DefaultTasksFiltersType) => {
  const history = useHistory()
  const filters = defaultTasksFilter
  if (history.location.search.includes("leadId")) {
    filters.communicationsForLeadId = getQueryVariable("leadId")
  }
  if (history.location.search.includes("applicationId")) {
    filters.connectedApplicationId = getQueryVariable("applicationId")
  }
  if (history.location.search.includes("preApplicationId")) {
    filters.connectedPreApplicationId  = getQueryVariable("preApplicationId")
  }
  if (history.location.search.includes("dealId")) {
    filters.connectedDealId  = getQueryVariable("dealId")
  }
  return filters
}