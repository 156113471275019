import { ParserSiteEnum, RealEstate } from "../../../../../../../types";
import { ReactNode, useRef, useState } from "react";
import { useCompare, useOpenTaskModal } from "../../../../../../../utils/hooks";
import { useColdCall } from "../StickyMenu/helpers";
import styles from "./index.module.scss";
import { ReactComponent as AddToCompilationIcon } from "../../../../../../../assets/icons/iconAddToCompilation.svg";
import { ReactComponent as IconPriceWithDiscount } from "../../../../../../../assets/icons/iconPriceWithDiscount.svg";
import OpenInNew from "../../../../../../../assets/icons/openInNewBlue.svg";
import { ReactComponent as IconCompetitorAnalysis } from "../../../../../../../assets/icons/iconCompetitorAnalysis.svg";
import { ReactComponent as IconHouseShow } from "../../../../../../../assets/icons/iconHouseShow.svg";
import { ReactComponent as IconEdit } from "../../../../../../../assets/icons/iconEdit.svg";
import { ReactComponent as IconCancel } from "../../../../../../../assets/icons/close.svg";
import { ReactComponent as IconCall } from "../../../../../../../assets/icons/iconCallBlue.svg";
import { ReactComponent as IconDots } from "../../../../../../../assets/icons/iconDotsInCircle.svg";
import LogoCian from "../../../../../../../assets/icons/iconCian.svg";
import LogoAvito from "../../../../../../../assets/icons/iconAvito2.svg";
import LogoYoula from "../../../../../../../assets/icons/iconYoula.svg";
import LogoDomClick from "../../../../../../../assets/icons/iconDomClick.svg";
import LogoYandexNed from "../../../../../../../assets/icons/logoYandexNed.svg";
import cn from "classnames";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { Popup } from "../../../../../../UI";
import { useDiscountModal } from "../../../../../../UI/discount-modal";
import { EstateBaseRow } from "../../../../../../UI/Card/CardObject/components/Owner/EstateBaseRow";
import { useAddToCollectionModal } from "../../../../../../UI/collections-modal/useAddToCollectionModal";
import { useGetFromArchive } from "./helpers/useGetFromArchive";
import { useArhivePreApplications } from "../../../../../../../service/api/queries";
import {
  useOpenCancelApplicationModal
} from "../../../../../Application/ApplicationCard/components/ApplicationInfo/component/Info/components/Body/components/BodyInfo/components/sticky-footer/cancel-modal/useOpenCancelApplicationModal";
import { formatPhone } from "../../../../../../../utils/formatters";
import { useOpenCreateShowModal } from "../../../../../ShowsAndViews/components/sticky-footer/create-show-view-modal";

type Props = {
  realEstate: RealEstate;
  realEstateRefetch: () => void;
  isFromParser?: boolean;
  isMobile?: boolean
  openEdit?: () => void
  isFromLead?: boolean
  isFromCompilation?: boolean
};

type MenuItem = {
  label: string;
  icon: ReactNode;
  onClick: () => void;
  hide?: boolean;
  disabled?: boolean;
  id?: number
  className?: string
  additionalIcon?: ReactNode
};

export const StickyFooter = (
  {
    realEstate,
    isFromParser,
    realEstateRefetch,
    isMobile,
    openEdit,
    isFromLead,
    isFromCompilation
  }: Props): JSX.Element => {

  const {
    id: realEstateId,
    price,
    priceWithDiscount,
    applicationId,
    preApplicationId,
    parserData,
    sourceLinks,
    isArchived,
    shortType,
    lastActiveCommunicationId,
    hasPreApplication,
    hasActiveLead,
  } = realEstate;

  const [disableAddObject] = useState(false)

  const menuRef = useRef<HTMLDivElement>(null)

  // const {mutate: addObject} = useAddObjectToCompilation({
  //   onSuccess: () => setDisableAddObject(true),
  // });

  let createColdCall = (id: string) => {
    console.log(id)
  }
  const {handleCreateColdCall, isLoading} = useColdCall(realEstateId);

  if (isFromParser) {
    createColdCall = handleCreateColdCall
  }

  const {handleGetFromArchive} = useGetFromArchive(applicationId, hasPreApplication)

  const {openDiscountModal} = useDiscountModal({
    price,
    id: realEstateId,
    realEstateRefetch,
    priceWithDiscount,
  })

  const {openCreateShowModal} = useOpenCreateShowModal({
    applicationId, refetchList: () => {
    }
  })

  const {openObjectCompare} = useCompare({applicationId});

  const {openAddToCollectionModal} = useAddToCollectionModal({objectData: realEstate})

  const {handleCancelApplicationModal} = useOpenCancelApplicationModal({
    applicationId,
    whoIsClient: shortType?.whoIsClient ?? "seller",
    isMobile
  })

  const actionsMenu: MenuItem[] = [
    {
      label: "Добавить в подборку",
      icon: <AddToCompilationIcon/>,
      onClick: openAddToCollectionModal,
      disabled: disableAddObject,
      id: 1
    },
    {
      label: "Указать цену со скидкой",
      icon: <IconPriceWithDiscount/>,
      id: 2,
      onClick: openDiscountModal
    },
    {
      label: "Назначить просмотр",
      icon: <IconHouseShow/>,
      onClick: openCreateShowModal,
      id: 6,
    },
    {
      label: "Редактировать объект",
      icon: <IconEdit/>,
      onClick: () => {
        if (openEdit) {
          openEdit()
        }
      },
      id: 5,
    },
    {
      label: "Анализ конкурентов",
      icon: <IconCompetitorAnalysis/>,
      onClick: openObjectCompare,
      id: 3,
    },
    {
      label: `Отказаться от ${preApplicationId ? "предзаявки" : "заявки"}`,
      icon: <IconCancel className={styles.cancel}/>,
      onClick: () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        preApplicationId
          ? handleOpenTaskModal(lastActiveCommunicationId ?? "")
          : handleCancelApplicationModal()
      },
      id: 7,
      className: styles.cancelText
    }
  ]

  let actualMenu = !isFromParser
    ? (isFromLead ? actionsMenu.slice(2, 6) : actionsMenu.slice(2, 7))
    : actionsMenu.slice(0, 2)

  if (isFromCompilation) {
    actualMenu = actualMenu.filter(el => el.id !== 3)
  }

  const getIcon = (site?: ParserSiteEnum) => {
    // eslint-disable-next-line default-case
    switch (site) {
      case ParserSiteEnum.avito:
        return LogoAvito
      case ParserSiteEnum.cian:
        return LogoCian
      case ParserSiteEnum.yandex:
        return LogoYandexNed
      case ParserSiteEnum.domclick:
        return LogoDomClick
      case ParserSiteEnum.youla:
        return LogoYoula
      default:
        return LogoAvito
    }
  }

  const openInNew = (url: string) => {
    window.open(`${url}`)
  }

  const contactsMenu = realEstate.sourceLinks?.map(el => ({
    label: el.phone ? formatPhone(el.phone) : "Телефон не определен",
    icon: getIcon(el.site),
    additionalIcon: OpenInNew,
    onClick: () => openInNew(el.url),
    site: el.site,
  }))

  const {
    mutate: arhiveMutate,
  } = useArhivePreApplications({applicationId: preApplicationId})
  const arhivePreApplication = () => {
    arhiveMutate()
  }

  const {handleOpenTaskModal} = useOpenTaskModal({
    onUpdatedTask: arhivePreApplication,
    refetchParent: () => {
    },
    isLast: true
  });

  return (
    <div className={cn(styles.root)} ref={menuRef}>
      {isFromParser && <div className={styles.footerContact}>
        <div className={styles.flexRow}>
          <div className={styles.contact}>
            {parserData?.ownerName}
          </div>
          <div className={styles.iconBlock}>
            <EstateBaseRow
              sourceLinks={sourceLinks}
              isIcon
            />
          </div>
        </div>
        <div className={styles.actionsContacts}>
          <Popup
            openTrigger={isMobile ? <IconDots/> : <div className={styles.openTrigger}>Показать контактные данные</div>}
            options={{withArrow: true, primaryOpen: "left"}}
            classNames={
              {
                trigger: styles.clicked,
                arrow: styles.arrow,
                card: styles.popupCardContacts
              }
            }
          >
            {contactsMenu && contactsMenu.map((item) => (
              <div key={item.label} className={cn(styles.actionItem)} onClick={item.onClick}>
                <div>
                  <img src={getIcon(item.site)}/>
                  <span>{item.label}</span>
                </div>
                <div>
                  <img src={item.additionalIcon}/>
                </div>
              </div>
            ))}
          </Popup>
        </div>
      </div>}
      <div>
        <div className={styles.flexRow}>
          {!isArchived &&
            <div className={styles.actions}>
              <Popup
                openTrigger={isMobile ? <IconDots/> : <div className={styles.openTrigger}>Другие действия</div>}
                options={{withArrow: true, primaryOpen: "left"}}
                classNames={
                  {
                    trigger: styles.clicked,
                    arrow: styles.arrow,
                    card: styles.popupCard
                  }
                }
              >
                {actualMenu.map((item) => (
                  <div key={item.id} className={cn(styles.actionItem, item.className)} onClick={item.onClick}>
                    {item.icon}
                    <span>{item.label}</span>
                  </div>
                ))}
              </Popup>
            </div>}
          <div className={styles.buttonContainer}>
            {isFromParser && !isArchived && !hasActiveLead &&
              <>
                {isMobile ?
                  <Button
                    isLoading={isLoading}
                    Icon={IconCall}
                    onClick={() => createColdCall(realEstateId)}
                    classNames={{root: styles.createColdCallButtonMobile}}
                  />
                  : <Button
                    onClick={() => createColdCall(realEstateId)}
                    classNames={{root: styles.createColdCallButton}}
                    isLoading={isLoading}
                  >
                    Холодный звонок
                  </Button>}
              </>
            }
            {isArchived &&
              <Button
                onClick={() => handleGetFromArchive(applicationId)}
                classNames={{root: styles.createLead}}
              >
                Создать лид
              </Button>}
          </div>
        </div>
      </div>
    </div>
  );
};