import { api } from "../../api";
import { useQuery } from "react-query";

const DEFAULT_QN = "sdelka/applications/load-application-guarantee-payment/DEFAULT_QN";

type UseLoadGuaranteePayment = {
  applicationId: string;
};

export const useLoadGuaranteePayment = ({applicationId}: UseLoadGuaranteePayment) => {
  const loadGuaranteePayment = () => api.application.getGuaranteePaymentStatus(applicationId)

  const {data, isLoading, refetch} = useQuery(DEFAULT_QN, loadGuaranteePayment, {enabled: !!applicationId})

  return {
    guaranteePayment: data?.data,
    isLoading,
    refetch
  }
}