import styles from "./index.module.scss";
import cn from "classnames";
import { Contact } from "../../../../../../../types";
import { ReactComponent as CloseIcon } from "../../../../../../../assets/icons/close.svg";
import { ReactComponent as PlusIcon } from "../../../../../../../assets/icons/plus-small.svg";
import { PinContact } from "../../../../../../UI";
import { useOnPinContact } from "../../helpers";
import { useDeleteContactModal } from "./helpers/delete-contact-modal/useDeleteContactModal";

type Props = {
  contact?: Contact | null
  refetch: () => void
  leadId: string
  freezed?: boolean
  isMobile?: boolean
}

export const AddContactLabel = (
  {
    contact,
    refetch,
    leadId,
    freezed,
    isMobile
  }: Props) => {
  const {onPinContact} = useOnPinContact({leadId, refetch})
  const {openDeleteContactModal} = useDeleteContactModal({onPinContact})
  const handlePinContact = (contactItem: Contact | null | undefined) => {
    onPinContact(contactItem, refetch)
  };
  return (
    <>
      {contact
        ? <div className={cn(styles.row, styles.delete)} onClick={openDeleteContactModal}>
          {!freezed && <>
            <CloseIcon/>
            <div>Открепить</div>
          </>}
        </div>
        : <div className={cn(styles.row, styles.add)}>
          {!freezed && <>
            <PlusIcon/>
            <PinContact onPin={handlePinContact} isMobile={isMobile}>
              <div className={styles.add}>Добавить контакт</div>
            </PinContact>
          </>}
        </div>
      }
    </>
  )
}