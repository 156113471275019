import styles from "./index.module.scss";
import { Contact } from "types";
import { CardContact } from "../../../../../../../UI/Card/card-contact/CardContact";
import cn from "classnames";

type Props = {
  data?: Contact,
  whoIsClient?: 'seller' | 'buyer';
  canDelete?: boolean
  isMobile?: boolean
  atAC?: boolean
  withoutTitle?: boolean
};

export const ContactCard = (
  {
    data,
    whoIsClient,
    canDelete,
    isMobile,
    atAC,
    withoutTitle
  }: Props): JSX.Element => (
  <div className={cn(styles.root, {[styles.withoutPadding]: withoutTitle})}>
    {!withoutTitle &&
      <>
        {whoIsClient === "seller" ? <h2>Продавец</h2> : <h2>Покупатель</h2>}
      </>
    }
    {data &&
      <CardContact
        contact={data}
        backgroundWhite
        canDelete={canDelete}
        mini={isMobile}
        atAC={atAC}
        withoutDots
      />}
  </div>
);
