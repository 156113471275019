import { useLoadFilterRopeTemplatesOneLevel } from "../../../service/api/queries";
import { SelectOption } from "../../../types";

export const useRopeTemplatesOneLevel = () => {
  const {data: templates} = useLoadFilterRopeTemplatesOneLevel()
  const preparedTemplates: SelectOption[] = templates?.map(el => ({
    label: el.label,
    value: el.id
  })) || []

  return {preparedTemplates}
}