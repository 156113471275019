import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  FindAddressDetailed,
  FormPropsEditRealEstate,
  RealEstateSchemaConfigField,
} from "../../../../../../../../../types";
import { Checkbox, Select, TransparentInput } from "../../../../../../../Form";
import { useFindAddressDetailed } from "../../../../../../../../../service/api/mutations";
import {
  setAddress,
  setAllowChangeByMap,
  setNoHomeNumber,
} from "../../../../../../../../../service/redux/modules/object-edit/object-edit.action";
import { selectObjectEdit, useSelect } from "../../../../../../../../../service/redux/selectors";
import styles from "./index.module.scss";
import { useDebouncedCallback } from "use-debounce";

type Props = {
  form: FormPropsEditRealEstate;
  field: RealEstateSchemaConfigField;
};

export const AddressSelect = ({form, field}: Props): JSX.Element => {
  const {register, control, setValue, getValues, watch, formState} = form;
  const dispatch = useDispatch();
  const {addressLabel, addressId, noHomeNumber} = useSelect(selectObjectEdit);
  const [options, setOptions] = useState<{ value: string; label: string }[]>([
    {
      label: addressLabel as string,
      value: addressId as string,
    },
  ]);

  const findAddress = useFindAddressDetailed();

  const flatNumberName = field.dataType.nameGroup?.[ 1 ];

  const noHomeNumberValue = watch("additionalProperties.noHomeNumber");

  useEffect(() => {
    dispatch(setNoHomeNumber(!!noHomeNumberValue));
    if (addressLabel && addressLabel?.length > 0) {
      // dispatch(setAllowChangeByMap(false))
    }
  }, [noHomeNumberValue]);

  const debounced = useDebouncedCallback(findAddress.mutate as any, 300);
  const {data, isLoading} = findAddress;

  const onSelectWrite = (value: string) => {
    if (value === "") {
      dispatch(setAllowChangeByMap(true))
    }
    debounced(value)
  };

  useEffect(() => {
    if (data && !isLoading) {
      if (data) {
        const newOptions = data.map(el => JSON.parse(JSON.stringify({
          label: el.label,
          value: el.id
        })))

        setOptions(newOptions);

      } else {
        const newOpt = {
          label: "Результатов не найдено",
          value: " ",
        }
        setOptions([newOpt])
      }
    }
  }, [data])

  const onSelectChange = (id: string) => {
    if (id === addressId) {
      return;
    }
    const lastMutation: any = findAddress.getLastMutationCache();
    const selectedAddress: FindAddressDetailed = lastMutation.state?.data?.data?.find(
      (address: FindAddressDetailed) => address.id.toString() === id.toString()
    );
    if (!selectedAddress) {
      return;
    }
    const coords = selectedAddress.point.split(",");
    const newOptions = [
      {
        label: selectedAddress.label,
        value: selectedAddress.id,
      },
    ];
    setOptions(newOptions);

    dispatch(
      setAddress({
        coord:
          {
            lat: Number(coords[ 1 ]),
            lon: Number(coords[ 0 ]),
          },
        addressId: selectedAddress.id,
        addressLabel: selectedAddress.label,
        dontMarkOnMap: noHomeNumber ? false : selectedAddress.type !== "house",
      })
    );
  };

  useEffect(() => {
    if (addressLabel) {
      onSelectWrite(addressLabel as string);
    }
  }, [addressLabel, addressId]);

  return (
    <div className={styles.addressContainer}>
      <div className={styles.selectContainer}>
        <Select
          placeholder="Введите адрес"
          name="addressLabel"
          hideArrow
          defaultValue={addressId}
          defaultSearchValue={addressLabel}
          isSearchable
          options={options}
          register={register}
          setValue={setValue}
          customSearchFunction={onSelectWrite}
          onChange={onSelectChange}
          isLoading={findAddress.isLoading}
          error={formState.errors?.addressLabel?.message?.toString()}
        />
      </div>

      {getValues("category") !== "Участок" && (
        <div className={styles.noHomeNumber}>
          <Checkbox label="Нет номера дома" name="additionalProperties.noHomeNumber" control={control}/>
        </div>
      )}

      {flatNumberName && (
        <>
          <div className={styles.verticalDivider}/>

          <div className={styles.inputWrapper}>
            <TransparentInput name={flatNumberName} register={register} placeholder="Квартира"/>
          </div>
        </>
      )}
    </div>
  );
};
