import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { toastLoading } from "../../../components/UI";

type Props = {
	loadingText: string;
};

type Return = {
	createLoader: () => void;
	updateLoader: () => void;
	closeToast: () => void;
	toastId: string | number | null;
};

type UseLoadingToast = (props: Props) => Return;

export const useLoadingToast: UseLoadingToast = ({ loadingText }) => {
	const [toastId, setToastId] = useState<null | string | number>(null);

	const closeToast = () => {
		toast.dismiss(toastId as string);
		setToastId(null);
	};

	const createLoader = () => {
		if (!toastId) {
			const id = toastLoading({ text: loadingText });
			setToastId(id);
		}
	};

	const updateLoader = () => {
		if (toastId) {
			closeToast();
		}
	};

	useEffect(
		() => () => {
			closeToast();
		},
		[]
	);

	return {
		createLoader,
		updateLoader,
		closeToast,
		toastId,
	};
};
