import { getShortIdBySource, getSourceDescription, getStatusDescription } from "./components";
import { Lead, LeadSourceEnum } from "../../../../../../../types";
import styles from "./index.module.scss";
import { useQueryParams } from "../../../../../../../utils/hooks";
import { useDispatch } from "react-redux";
import { openDrawerInDrawer } from "../../../../../../../service/redux/modules/drawer/drawer.action";
import { useLoadApplicationRealEstate, useLoadParsedRealEstate } from "../../../../../../../service/api/queries";
import { useGetNavigation } from "../../../../../RealEstatePage/helpers";
import { RealEstateView } from "../../../../../RealEstatePage/mode";
import { selectDevice, useSelect } from "../../../../../../../service/redux/selectors";
import { useIsTelegram } from "../../../../../../../utils/telegram/useIsTelegram";

type Props = {
  lead: Lead;
};

export const LeadTitle = ({lead}: Props): JSX.Element => {
  const {
    status,
    source,
    adSource,
    parserObject,
    archivedEstateObject,
  } = lead;

  const {removeQueryParam} = useQueryParams([
    {name: "parserRealEstateId"},
    {name: "applicationId"},
    {name: "preApplicationId"},
    {name: "mode"},
  ]);
  const ESTATE_ID = parserObject?.id ?? (archivedEstateObject?.preApplicationId || archivedEstateObject?.applicationId) ?? ""
  const {isMobile} = useSelect(selectDevice)

  const {realEstate, refetch} = useLoadParsedRealEstate({
    id: ESTATE_ID,
  });

  const {
    realEstate: realEstateApp,
    refetch: refetchApp,
  } = useLoadApplicationRealEstate({
    id: ESTATE_ID,
  });

  const {blocksIds} = useGetNavigation({
    realEstate: (archivedEstateObject?.preApplicationId || archivedEstateObject?.applicationId) ? realEstateApp : realEstate,
    isParser: true,
  });
  const dispatch = useDispatch();
  const handleClose = () => {
    removeQueryParam(["parserRealEstateId", "mode", "applicationId", "preApplicationId"]);
  };
  const {executeUrl} = useIsTelegram()

  const openObjectInNew = (url: string) => {
   executeUrl(`${url}`)
  }

  const handleClick = () => {
    if (source === LeadSourceEnum.coldCall || source === LeadSourceEnum.archivedEstateObject) {
      dispatch(
        openDrawerInDrawer({
          width: 800,
          children: <RealEstateView
            id={ESTATE_ID}
            blocksIds={blocksIds}
            realEstate={(archivedEstateObject?.preApplicationId || archivedEstateObject?.applicationId) ? realEstateApp : realEstate}
            refetch={(archivedEstateObject?.preApplicationId || archivedEstateObject?.applicationId) ? refetchApp : refetch}
            isFromLead
          />,
          onClose: handleClose,
        }))
    }
  }

  const {color, label} = getStatusDescription(status)

  return (
    <>
      <div style={{color}}>
        {label}
      </div>
      <div className={styles.header}>
        <div className={styles.title}>
          {getSourceDescription(source)}
        </div>
        <div className={styles.shortId} onClick={() =>
          isMobile
            ? openObjectInNew(getShortIdBySource(lead).url)
            : handleClick()}>
          {getShortIdBySource(lead).shortId}
        </div>
      </div>
      {source === LeadSourceEnum.external &&
        <div className={styles.row}>
          <div className={styles.adLabel}>
            Рекламный канал
          </div>
          <div className={styles.adSource}>
            {`${adSource}`}
          </div>
        </div>
      }
    </>
  );
};
