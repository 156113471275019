import { ChangeEvent, FocusEvent, useEffect } from "react";
import { Control, Controller, FieldArrayWithId, UseFieldArrayAppend, useWatch } from "react-hook-form";
import cn from "classnames";

import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash.svg";
import styles from "./index.module.scss";

type Props = {
	fieldName: string;
	fields: FieldArrayWithId<any, any, any>[];
	control: Control<any>;
	/**
	 * This function specifies how to name each next input
	 * */
	nameBuilder?: (baseName: string, index: number, objectKey: string) => string;
	append: UseFieldArrayAppend<any, any>;
	remove: (index: number) => void;
	firstLabel?: string;
	otherLabels?: string;
	objectKey: string;
	parser?: (value: string) => string;
	haveFirstLabel?: boolean;
	placeholder?: string;
	required?: boolean;
	className?: string;
	aspect?: {
		label: number;
		content: number;
	};
	disabled?: boolean;
	children?: JSX.Element;
};

const defaultNameBuilder = (baseName, index, objectKey) => `${baseName}.${index}.${objectKey}`;

export const InputList = ({
	fieldName,
	fields,
	control,
	nameBuilder = defaultNameBuilder,
	append,
	remove,
	firstLabel,
	otherLabels,
	objectKey,
	parser,
	haveFirstLabel = true,
	placeholder = "",
	required = false,
	className,
	aspect = { label: 6, content: 6 },
	disabled,
	children,
}: Props): JSX.Element => {
	const watcher = useWatch({
		control,
		name: fieldName,
		defaultValue: fields ?? [],
	});

	const addField = () => {
		if (watcher && watcher.length && watcher[watcher.length - 1]?.value !== "") {
			append({ [objectKey]: "" });
		}
	};

	useEffect(() => {
		if (fields.length <= 0) {
			addField();
		}
	}, [fields]);

	const checkEmpty = (value: string, index: number) => {
		if (watcher.length !== 1 && value === "") {
			remove(index);
		}
	};

	const label = (index) => (((haveFirstLabel && index === 0) || fields.length === 0 )
		? firstLabel : otherLabels || firstLabel);

	return (
		<div className={styles.root}>
			{fields.map((item, index) => (
				<div key={item.id}>
					<div className={cn(styles.inputWrapper, className)}>
						{firstLabel ? (
							<div style={{ flex: `${aspect.label} 1` }} className={styles.label}>
								{label(index)}
							</div>
						) : null}
						<Controller
							render={({ field }) => (
								<input
									className={styles.input}
									style={{ flex: `${aspect.content} 1` }}
									required={required}
									placeholder={placeholder}
									disabled={disabled}
									{...field}
									onChange={(event: ChangeEvent<HTMLInputElement>) => {
										if (parser) {
											field.onChange(parser(event.target.value));
										} else {
											field.onChange(event.target.value);
										}
									}}
									onBlur={(event: FocusEvent<HTMLInputElement>) => {
										checkEmpty(event.target.value, index);
									}}
								/>
							)}
							name={nameBuilder(fieldName, index, objectKey)}
							defaultValue={item[objectKey]}
							control={control}
						/>
						<div className={styles.addDelete}>
							{!disabled && (
								<>
									{index !== 0 && (
										<div className={styles.delete} onClick={() => remove(index)}>
											<TrashIcon />
										</div>
									)}
									<div className={styles.add} onClick={addField}>
										<PlusIcon />
									</div>
								</>
							)}
						</div>
					</div>
					{index === 0 && !!children && children}
				</div>
			))}
		</div>
	);
};
