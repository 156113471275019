import { SortOption, ContactSort, ContactFilter, Conditions } from "../../../../types";

export const CONTACT_DEFAULT_FILTERS = {
	fullTextSearch: "",
	type: [],
	responsibles: [],
	withApplication: false,
	withObject: false,
};

export const getContactSort = (sort: ContactSort): SortOption[] => {
	const sortConditions: SortOption[] = [];

	if (sort.subtype) {
		sortConditions.push({
			field: "subtype",
			order: sort.subtype,
		});
	}

	sortConditions.push({
		field: "createdAt",
		order: sort?.createdAt || "ASC",
	});

	return sortConditions;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const getContactCondition = (search: ContactFilter) => {
	const searchConditions: Conditions = [];

	if (search?.lastName) {
		searchConditions.push({
			field: "lastName",
			operator: "like",
			value: search?.lastName,
		});
	}

	if (search?.name) {
		searchConditions.push({
			field: "name",
			operator: "like",
			value: search?.name,
		});
	}

	if (search?.secondName) {
		searchConditions.push({
			field: "secondName",
			operator: "like",
			value: search?.secondName,
		});
	}
	if (search?.pseudonym) {
		searchConditions.push({
			field: "pseudonym",
			operator: "like",
			value: search?.pseudonym,
		});
	}
	if (search?.phone) {
		searchConditions.push({
			field: "phones.phone",
			operator: "like",
			value: search?.phone || "",
		});
	}

	if (search?.phoneForSearch) {
		searchConditions.push({
			field: "phones.phone",
			operator: "eq",
			value: search?.phoneForSearch || "",
		});
	}

	if (search?.hideThisIds) {
		searchConditions.push({
			field: "id",
			operator: "nin",
			value: search?.hideThisIds,
		});
	}

	if (search?.type) {
		const typeofType = typeof search.type;

		if (typeofType === "string") {
			searchConditions.push({
				field: "type",
				operator: "eq",
				value: search?.type || "",
			});
		} else {
			searchConditions.push({
				field: "type",
				operator: "in",
				value: search?.type || [],
			});
		}
	}

	if (search?.email) {
		searchConditions.push({
			field: "email",
			operator: "like",
			value: search?.email || "",
		});
	}

	if (search?.parents?.childId) {
		searchConditions.push({
			field: "parents.childId",
			operator: "eq",
			value: search.parents.childId,
		});
	}

	if (search?.children?.parentId) {
		searchConditions.push({
			field: "children.parentId",
			operator: "eq",
			value: search.children.parentId,
		});
	}

	if (search?.fullTextSearch) {
		searchConditions.push({
			field: "fullTextSearch",
			operator: "like",
			value: search.fullTextSearch,
		});
	}

	if (search?.department) {
		searchConditions.push({
			field: "department",
			operator: "in",
			value: search.department,
		});
	}

	if (search?.responsibles) {
		searchConditions.push({
			field: "responsibles",
			operator: "in",
			value: search.responsibles,
		});
	}

	if (search?.withObject) {
		searchConditions.push({
			field: "withObject",
			operator: "eq",
			value: search.withObject,
		});
	}

	if (search?.withApplication) {
		searchConditions.push({
			field: "withApplication",
			operator: "eq",
			value: search.withApplication,
		});
	}

	return searchConditions;
};
