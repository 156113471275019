import { ApplicationInListV2, ApplicationsFilter, ILessonVideoPreviewItem, KindType } from "../../../../../types";
import { CardVideoLesson, TableApplication } from "../../../../UI";
import { StageTitle } from "../StageTitle";
import { useOpenApplicationCard } from "../../../../../utils/application";
import { useBlinkRow, useQueryParams } from "../../../../../utils/hooks";
import styles from "./index.module.scss";
import cn from "classnames";
import { useOpenDeal } from "../../../../../service/servise";
import { useEffect } from "react";

type Props = {
  requestsList: ApplicationInListV2[];
  filter?: ApplicationsFilter;
  isLoading: boolean;
  withStage: boolean;
  total?: number;
  totalCounts?: Record<string, number>;
  refetchApplications?: () => void;
  kind?: KindType
  isMobile?: boolean
  lesson?: ILessonVideoPreviewItem
};

export const DealsTable = (
  {
    requestsList,
    filter,
    isLoading,
    withStage,
    total,
    totalCounts,
    refetchApplications,
    kind,
    isMobile,
    lesson
  }: Props): JSX.Element => {
  const {rowsToBlink, callback} = useBlinkRow();
  const {handleClickApplication} = useOpenApplicationCard({
    callbackAfterClose: (id: string) => callback(id), kind
  });
  const {openDeal} = useOpenDeal({atDrawerInDrawer: false})
  const handleTableRowClick = (row: ApplicationInListV2) => {
    openDeal({
      applicationId: row.id,
      refetchParent: refetchApplications,
    })
  };

  const {
    queryParams: {tab, mode, preApplicationId, applicationId, dealId}
  } = useQueryParams([{name: "id"}, {name: "tab"}, {name: "mode"}, {name: "preApplicationId"}, {name: "applicationId"}, {name: "dealId"}]);

  const getColor = ((application) => {
    switch (application.type.kind) {
      case 'preApplication':
        return "#D5D3CE";
      case  'application':
        return "#FFE254"
      case 'deal':
        return "#7F8DEF"
      default:
        return "#000"
    }
  });

  useEffect(() => {
    if (tab === "applications" && applicationId && mode !== "pre-applications") {
      handleClickApplication({applicationId: applicationId as string, kind: KindType.APPLICATION})
    }
    if (tab === "applications" && preApplicationId === `${preApplicationId}`) {
      handleClickApplication({applicationId: preApplicationId as string, kind: KindType.PREAPPLICATION})
    }
    if (tab === "deals" && dealId) {
      handleClickApplication({applicationId: dealId as string, kind: KindType.DEAL})
    }
  }, [])

  return (
    <div className={cn(styles.wrapper, {[ styles.mobile ]: isMobile})}>
      {requestsList?.length > 0 || isLoading ? (
        <TableApplication
          data={requestsList}
          filter={filter}
          kind={kind}
          groupKey="stage"
          withStage={withStage}
          tableRowClick={handleTableRowClick}
          isMobile={isMobile}
          transformGroupTitle={(title, data) => (
            <StageTitle stageNum={data?.stageNo || 1} color={getColor(data)}>
              {title}
              {totalCounts && total ? (
                <span className={styles.counts}>{` (${totalCounts[ title ]}/${total})`}</span>
              ) : null}
            </StageTitle>
          )}
          isLoading={isLoading}
          refetchApplications={refetchApplications}
          blinkRows={rowsToBlink}
        />
      ) : (
        <>
          <div
            className={styles.empty}>{kind === KindType.DEAL ? "Здесь будут ваши сделки. Посмотрите видео-инструкцию и начните работу"
            : "Здесь будут ваши заявки. Посмотрите видео-инструкцию и начните работу"}</div>
          {lesson && <CardVideoLesson lesson={lesson}/>}
        </>
      )}
    </div>
  );
};
