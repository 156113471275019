import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { contactSchema } from "../../../../../../../../../utils/contact";
import { parsePhone } from "../../../../../../../../../utils/parsers";
import { Contact } from "../../../../../../../../../types";

type Props = {
	contact: Contact;
};

export const useContactForm = ({ contact }: Props) => {
	const defaultValues = {
		phones:
			contact && contact.phones && contact.phones.length !== 0
				? contact.phones.map((phone) => ({
						phone: parsePhone(phone.phone),
						type: phone?.type || "",
				  }))
				: [{ phone: "" }],
		name: contact?.name,
		lastName: contact?.lastName,
		secondName: contact?.secondName,
		pseudonym: contact?.pseudonym,
		type: contact?.type,
		otherType: contact?.otherType,
		email: contact?.email || "",
		dob: contact?.dob ? new Date(contact.dob).toISOString() : "",
		addressObject: {
			value: contact?.addressId || "",
			label: contact?.addressLabel || "",
			location: contact?.location || {},
		},
		passportInfo: {
			series: contact.passportInfo?.series || "",
			passportNumber: contact.passportInfo?.passportNumber || "",
			dateOfIssue: contact.passportInfo?.dateOfIssue ? new Date(contact.passportInfo?.dateOfIssue).toISOString() : "",
			issuedBy: contact.passportInfo?.issuedBy || "",
			issuedByCode: contact.passportInfo?.issuedByCode || "",
			registerAddress: contact.passportInfo?.registerAddress || ""
		}
	};

	const { control, register, reset, formState, getValues, handleSubmit, setValue } = useForm<
		Contact & { mainPhone: string }
	>({
		resolver: yupResolver(contactSchema),
		defaultValues,
	});

	useEffect(() => {
		if (contact && contact.phones && contact.phones.length) {
			reset(defaultValues);
		}
	}, [contact?.phones]);

	return {
		control,
		register,
		formState,
		getValues,
		handleSubmit,
		setValue,
	};
};
