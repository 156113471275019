import { UserListItem } from "../../../../../../types";
import styles from "./index.module.scss";
import cn from "classnames";
import { CardContact } from "../../../../Card/card-contact/CardContact";

type Props = {
  data: UserListItem[];
  mini?: boolean;
  classNames?: string
  atModal?: boolean
  refetch?: () => void;
  onDelete?: (id: string) => void
  withoutDots?: boolean
  withoutLinks?: boolean
  withAvatar?: boolean
  canDelete?: boolean
  atSelect?: boolean
  allowOpenInNewTab?: boolean
};

export const UserListCards = (
  {
    data,
    mini = false,
    classNames,
    atModal,
    refetch,
    onDelete,
    withoutDots,
    withoutLinks,
    withAvatar,
    canDelete,
    atSelect,
    allowOpenInNewTab
  }: Props): JSX.Element => {
  const isThereData = data && data.length > 0;

  return (
    <>
      <ul className={cn(styles.body, classNames)}>
        {isThereData &&
          data.map((user) => (
            <li
              key={user.id}
            >
              <CardContact
                contact={user}
                mini={mini}
                backgroundWhite={atModal}
                canOpenNew={atModal}
                atList={atModal}
                refetch={refetch}
                ondelete={onDelete}
                withoutDots={withoutDots}
                withoutLinks={withoutLinks}
                withAvatar={withAvatar}
                canDelete={canDelete}
                atSelect={atSelect}
                allowOpenInNewTab={allowOpenInNewTab}
              />
            </li>
          ))}
      </ul>
      {!isThereData && <div className={styles.noData}>Не назначено</div>}
    </>
  );
};
