import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { api } from "../../../../../service/api";
import { setCurrentUser } from "../../../../../service/redux/modules/auth/currentAccountReducer";
import { setUsers } from "../../../../../service/redux/modules/auth/accountsReducer";
import { useSelect, selectCurrentAccount } from "../../../../../service/redux/selectors";
import { paths } from "../../../../../utils/paths";
import { User } from "../../../../../types";

import { ReactComponent as CheckMark } from "../../../../../assets/icons/check-mark.svg";
import styles from "./index.module.scss";

export const CreatingSingleRealtor = (): JSX.Element => {
	const { currentAccount } = useSelect(selectCurrentAccount);
	const title = `Вы вошли в систему как «Риэлтор-одиночка» ${currentAccount?.account?.name}`;
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		if (currentAccount) {
			api.users
				.getAccountData(currentAccount?.account.id)
				.then((res) => {
					const newUserData: User = Object.assign(currentAccount, {
						account: res.data,
					});
					dispatch(setUsers([newUserData]));
					dispatch(setCurrentUser(newUserData));

					return true;
				})
				.catch();
		}

		setTimeout(() => {
			history.push(paths.dashboardPath);
		}, 3000);
	}, []);

	return (
		<div className={styles.root}>
			<CheckMark />
			<h2>{title}</h2>
			<p>Через несколько секунд вы будете перенаправленны в систему.</p>

			<Button onClick={() => history.push(paths.dashboardPath)}>Продолжить</Button>
		</div>
	);
};
