export const openInNewWindow = (url: string, allowCreate?: boolean): void => {
  const link = url;
  if (allowCreate) {
    const a = document.createElement("a");
    a.setAttribute("href", link);
    a.setAttribute("target", "_blank");
    a.setAttribute("rel", "noreferrer noopener");
    a.click();
    a.remove();
  }

};
