import { NoData } from "@sdelka_crm/sdelka-crm-component-library";
import { useDisplayFetching } from "./helpers";
import { TaskModalContextProvider, useTaskModalContext } from "../../../../service/context/TaskModalContext";
import { TaskCard } from "../../../pages/Tasks/task/Task";

type PropsTaskInProvider = {
  refetchParent?: () => void
  isLast?: boolean
  isMobile?: boolean
}

const TaskInProvider = (
  {
    refetchParent,
    isLast,
    isMobile
  }: PropsTaskInProvider
) => {
  const {task, refetch, isFetching} = useTaskModalContext();

  useDisplayFetching({isFetching});

  if (!task) {
    return (
      <NoData onRefetch={refetch}/>
    );
  }

  return (
    <div>
      <TaskCard
        task={task}
        refetchParent={refetchParent}
        refetch={refetch}
        isLast={isLast}
        isMobile={isMobile}
      />
    </div>
  );
};

type Props = {
  id: string;
  onUpdatedTask: () => void;
  refetchParent?: () => void
  isLast?: boolean
  isMobile?: boolean
};

export const TaskModal = ({id, onUpdatedTask, refetchParent, isLast, isMobile}: Props): JSX.Element => (
  <TaskModalContextProvider id={id} onUpdatedTask={onUpdatedTask}>
    <TaskInProvider refetchParent={refetchParent} isLast={isLast} isMobile={isMobile}/>
  </TaskModalContextProvider>
);
