import { useMutation } from "react-query";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/contact/add-access-to-user/DEFAULT_MN";

type Props = {
	onSuccess: () => void;
	onError: () => void;
};

export const useAddAccessToUser = ({ onSuccess, onError }: Props) => {
	const fetch = (contactId: string) => api.contacts.addAccessToUser(contactId);

	const { mutate, isLoading } = useMutation(DEFAULT_MN, {
		mutationFn: fetch,
		onSuccess,
		onError,
	});

	return {
		mutate,
		isLoading,
	};
};
