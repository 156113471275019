import { UseMutateFunction, useMutation } from "react-query";
import { RealEstateUpdateAutoRenewalPayload, RealEstateUpdateAutoRenewalResponse } from "../../../../types";

import { api } from "../../index";
import { AxiosResponse } from "axios";

const DEFAULT_MN = "sdelka/real-estate-ad/update-auto-renewal/DEFAULT_MN";

type Props = {
	onSuccess?: (
		data: AxiosResponse<RealEstateUpdateAutoRenewalResponse>,
		variables: RealEstateUpdateAutoRenewalPayload,
		context: unknown
	) => void | Promise<unknown>;
};

type Return = {
	mutate: UseMutateFunction<
		AxiosResponse<RealEstateUpdateAutoRenewalResponse>,
		unknown,
		RealEstateUpdateAutoRenewalPayload,
		unknown
	>;
	isLoading: boolean;
};

type UseUpdateAutoRenewal = (props: Props) => Return;

export const useUpdateAutoRenewal: UseUpdateAutoRenewal = ({ onSuccess }) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (data: RealEstateUpdateAutoRenewalPayload) => api.realEstateAd.updateAutoRenewal(data),
		onSuccess,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
