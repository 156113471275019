import { UseMutateFunction, useMutation } from "react-query";
import { RealEstateUpdateAutoRenewalResponse, StopAdPayload } from "../../../../types";
import { api } from "../../api";
import { AxiosError, AxiosResponse } from "axios";

const DEFAULT_MN = "sdelka/real-estate-ad/change-tariff/DEFAULT_MN";

type Props = {
  onSuccess?: (
    data: AxiosResponse<RealEstateUpdateAutoRenewalResponse>,
    variables: StopAdPayload,
    context: unknown
  ) => void | Promise<unknown>;
  onError?: (
    error: AxiosError,
  ) => void;
};

type Return = {
  mutate: UseMutateFunction<AxiosResponse<RealEstateUpdateAutoRenewalResponse>,
    unknown,
    StopAdPayload,
    unknown>;
  isLoading: boolean;
};

type UseComplaintAutoReject = (props: Props) => Return;

export const useRestartAd: UseComplaintAutoReject = ({onSuccess, onError}) => {
  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: (data: StopAdPayload) => api.realEstateAd.restartAd(data),
    onSuccess,
    onError,
  });

  return {
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
  };
};
