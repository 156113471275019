import { useHistory } from "react-router";
import { Loader } from "@sdelka_crm/sdelka-crm-component-library";

import { useLoadYourObjects } from "../../../../../service/api/queries/public-page";
import { CardObjectPublic } from "../../../../UI";
import { RealEstate } from "../../../../../types";

type Props = {
	contactId: string;
};

export const ObjectList = ({ contactId }: Props): JSX.Element => {
	const { data, isLoading } = useLoadYourObjects({ contactId });
	const { push } = useHistory();

	const openObjectCompare = (objectId: string) => push(`${contactId}/object/${objectId}`);

	return (
		<div>
			<Loader height={250} style={{ display: isLoading ? "block" : "none" }} />
			<div style={{ display: isLoading ? "none" : "block" }}>
				{data.map((item) => (
					<div
						onClick={() => openObjectCompare(item.id)}
						key={item.id}
						style={{ marginBottom: 20, cursor: "pointer" }}
					>
						<CardObjectPublic clientObject={item as RealEstate} />
					</div>
				))}
			</div>
		</div>
	);
};
