import {
	BusinessProcessSettings,
	BusinessProcessStageType,
	CategoryEnum,
	BusinessProcess,
} from "../../../../types";

import { Client } from "../../Client";

export const SettingsService = {
	businessProcessList: (
		businessProcessStageTypes: BusinessProcessStageType[],
		businessProcessType?: BusinessProcess | "",
		category?: CategoryEnum
	) => {
		let query = "";

		if (businessProcessStageTypes) {
			query = "?";
			businessProcessStageTypes.forEach((stageType) => {
				query += `${stageType},`;
			});
		}

		if (businessProcessType) {
			query += `&businessProcessType=${businessProcessType},`;
		}

		if (category) {
			query += `&category=${category},`;
		}

		return Client.get<BusinessProcessSettings[]>(`settings/business-process${query}`);
	},
	businessProcessSources: () => Client.get<string[]>("settings/business-process/sources"),
};
