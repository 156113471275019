import styles from "./index.module.scss"
import { useForm } from "react-hook-form";
import { Button, InfoRow } from "@sdelka_crm/sdelka-crm-component-library";
import { toastError, TransparentInput } from "../../../../../../UI";
import { useImportObject } from "../../../../../../../service/api/mutations";
import { AxiosResponse } from "axios";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../../../service/redux/modules/modal/modal.action";

export const ImportObjectModal = () => {
  const {register, watch, handleSubmit} = useForm();
  const history = useHistory()
  const dispatch = useDispatch()
  const selectedValue = watch("url");

  const onSuccess = (res: AxiosResponse<{ applicationId: string }>) => {
    history.push(`/real-estate?tab=AGENCY&applicationId=${res.data.applicationId}`)
    dispatch(closeModal())
  }

  const onError = () => {
    toastError({text: "Не удалось импортировать объект!"})
  }

  const {mutate, isLoading} = useImportObject({
    url: selectedValue,
    onError,
    onSuccess
  })

  const onSubmit = () => {
    mutate()
  }

  return (
    <form className={styles.root} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.title}>
        Скопировать объект из другого источника
      </div>
      <InfoRow label="Ссылка на объект">
        <TransparentInput
          name="url"
          register={register}
          placeholder="https://"
        />
      </InfoRow>
      <div className={styles.submitBtnContainer}>
        <Button type="submit" disabled={!selectedValue} isLoading={isLoading}>
          Скопировать объект
        </Button>
      </div>
    </form>
  )
}