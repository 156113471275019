import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { loadBusinessProcesses } from "../../../../service/redux/modules/business-process/business-process.thunk";

export const useLoadBusinessProcess = (): void => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadBusinessProcesses());
	}, []);
};
