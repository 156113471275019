import { Placemark, withYMaps, YMapsApi } from "react-yandex-maps";

import { createIconsClusterTemplate } from "../map-utils/utils";
import { ClusterType } from "../../../../types";

type Props = {
	cluster: ClusterType;
	clickOnCluster: (coords: number[], ids?: string[]) => void;
	ymaps?: YMapsApi;
};

export const ClusterPoint = withYMaps(({ cluster, clickOnCluster, ymaps }: Props): JSX.Element => {
	const { lat, lon, count } = cluster;

	const onClickHandler = () => {
		clickOnCluster([lat, lon], cluster.ids);
	};

	return (
		<Placemark
			geometry={[lat, lon]}
			properties={{
				iconContent: count,
			}}
			onClick={onClickHandler}
			options={{
				iconLayout: ymaps?.templateLayoutFactory.createClass(
					createIconsClusterTemplate("properties.iconContent")
				),
				iconShape: {
					type: "Circle",
					coordinates: [0, 0],
					radius: 16,
				},
			}}
		/>
	);
});
