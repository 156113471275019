import { Position } from "../../../../../types";
import { CreatePositionForm, PositionForm } from "./components";

import styles from "./index.module.scss";

type Props = {
	refetch: () => void;
	positions: Position[];
	activePosition: string;
	setActivePosition: (id: string) => void;
};

// Available positions wrapper
export const Positions = ({ refetch, positions, activePosition, setActivePosition }: Props): JSX.Element => (
	<div className={styles.root}>
		<div className={styles.sticky}>
			<span className={styles.header}>Должность</span>

			{positions?.map((position: Position) => (
				<PositionForm
					key={position.id}
					refetch={refetch}
					position={position}
					activePosition={activePosition}
					setActivePosition={setActivePosition}
				/>
			))}

			<CreatePositionForm refetch={refetch} />
		</div>
	</div>
);
