import { useState } from "react";

type Return = {
	comment: string;
	onChangeComment: (value: string) => void;
};

type UseComment = () => Return;

export const useComment: UseComment = () => {
	const [comment, setComment] = useState("");

	const onChangeComment = (newComment: string) => {
		setComment(newComment);
	};

	return {
		comment,
		onChangeComment,
	};
};
