import {
  CLEAN_UP,
  ObjectEditAction,
  SET_ADDRESS,
  SET_ALLOW_BY_MAP,
  SET_NO_HOME_NUMBER,
  SET_RESPONSIBLES,
  SET_ROOMS_AMOUNT,
  SET_SHOW_REQUIRED_AD_FIELDS,
} from "./object-edit.action";
import { ObjectEditTInitialState } from "../../types";

const initialState: ObjectEditTInitialState = {
  showReqiredAdFields: {
    cian: false,
    avito: false,
    yandex: false,
  },
  responsibles: [],
  coord: {lat: NaN, lon: NaN},
  dontMarkOnMap: false,
  noHomeNumber: false,
  allowChangeByMap: true
};

export const objectEditReducer = (
  state = initialState,
  action: ObjectEditAction
): ObjectEditTInitialState => {


  switch (action.type) {
    case SET_SHOW_REQUIRED_AD_FIELDS:
      return {
        ...state,
        showReqiredAdFields: action.fields,
      };
    case SET_ROOMS_AMOUNT:
      return {
        ...state,
        roomsAmount: action.amount,
      };
    case SET_RESPONSIBLES:
      return {
        ...state,
        responsibles: action.users,
      };
    case SET_ADDRESS:
      return {
        ...state,
        coord: action.address.coord,
        addressId: action.address.addressId,
        addressLabel: action.address.addressLabel,
        dontMarkOnMap: action.address.dontMarkOnMap,
      };
    case SET_NO_HOME_NUMBER:
      return {
        ...state,
        noHomeNumber: action.noHomeNumber,
      };
    case CLEAN_UP:
      return {
        ...state,
        showReqiredAdFields: {
          cian: false,
          avito: false,
          yandex: false,
        },
        responsibles: [],
        roomsAmount: undefined,
        coord: {lat: NaN, lon: NaN},
        addressId: undefined,
        addressLabel: undefined,
        noHomeNumber: false,
      };
    case SET_ALLOW_BY_MAP:
      return {
        ...state,
        allowChangeByMap: action.allowChangeByMap,
      };
    default:
      return state;
  }

};
