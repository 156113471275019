import { useEffect, useState } from "react";
import { RealEstateWithSchema } from "../../../../types";

import { FixedNavProps, FixedNavVerticalMenu } from "../../../UI";
import { useQueryParams } from "../../../../utils/hooks";
import { scrollIntoView } from "../../../../utils/scroll";
import { ReactComponent as Edit } from "../../../../assets/icons/edit-page.svg";
import { ReactComponent as Ad } from "../../../../assets/icons/ad.svg";
import { ReactComponent as Shows } from "../../../../assets/icons/shows_and_views.svg";

type BlockIdType = {
	common: string;
	info: string;
	description: string;
	maybe: string;
	official: string;
};

type Props = {
	realEstate?: RealEstateWithSchema;
	isNew?: boolean;
	isParser?: boolean;
	isDisableEdit?: boolean;
};

type Return = {
	activeNav: FixedNavProps;
	onAdNav: (
		platforms: Array<{
			name: string;
			id: string;
		}>
	) => void;
	navigationY: FixedNavVerticalMenu[];
	blocksIds: BlockIdType;
	setNavigationY: React.Dispatch<React.SetStateAction<FixedNavVerticalMenu[]>>;
	setActiveNav: React.Dispatch<React.SetStateAction<FixedNavProps>>;
};
type UseGetNavigation = (props: Props) => Return;
// realEstate - lанные по объекту applications[0].stage
export const useGetNavigation: UseGetNavigation = ({ realEstate, isParser, isNew, isDisableEdit }) => {

	const {
		queryParams: { mode },
		changeQueryParams,
	} = useQueryParams<{ mode: string }>([{ name: "mode" }]);

	const handleChangeTab = (newTab: string) => {
		changeQueryParams([{name: "mode", newValue: newTab}]);
	}

	const blocksIds = {
		common: "common-block-object-view",
		info: "info-block-object-view",
		description: "description-block-object-view",
		maybe: "maybe-block-object-view",
		official: "official-block-object-view",
	};

	const navigationCommon = {
		navigation: [
			{ label: "Общая", action: () => scrollIntoView(blocksIds.common) },
			{ label: "Информация", action: () => scrollIntoView(blocksIds.info) },
			{
				label: "Описание",
				action: () => scrollIntoView(blocksIds.description),
				hidden: !!realEstate?.parserData,
			},
			// Hide while is not ready
			// { label: 'Подходящие заявки', action: () => document.getElementById(blocksIds.maybe)?.scrollIntoView() },
			{
				label: "Официальные ресурсы",
				action: () => scrollIntoView(blocksIds.official),
			},
		],
		additionalLabel: realEstate?.shortId ? `id ${realEstate.shortId}` : "",
	};
	const [navigationY, setNavigationY] = useState<FixedNavVerticalMenu[]>([]);
	const navigationYGenerator = (): FixedNavVerticalMenu[] => {
		const nav = [
			{
				id: "edit",
				label: <Edit />,
				changeTab: () => handleChangeTab("edit"),
				height: 90,
				disabled: isDisableEdit,
			},
			{
				id: "view",
				label: "Объект",
				changeTab: () => handleChangeTab("view"),
				height: 130,
				disabled: isNew,
			},
			{
				id: "leads",
				label:  isParser ? "Предзаявка" : "Заявка",
				changeTab: () => handleChangeTab("leads"),
				height: isParser ? 170 : 140,
				disabled: isNew || !!realEstate?.parserData,
			},
			{
				id: "ad",
				label: <Ad />,
				changeTab: () => handleChangeTab("ad"),
				height: 90,
				disabled: isNew || !!realEstate?.parserData,
			},
			{
				id: "impressionsAndViews",
				label: <Shows />,
				changeTab: () => handleChangeTab("impressionsAndViews"),
				height: 90,
				disabled: isNew || !!realEstate?.parserData,
			},
			// Hide while is not ready
			// { id: 'documents', label: 'Документы', changeTab: () => handleChangeTab('documents'), height: 160, disabled: isNew },
		];
		
		if (mode!=="edit") {
			const editIndex = nav.findIndex((navItem) => navItem.id === "edit");
			nav.splice(editIndex, 1);
		}

		if (isNew || !!realEstate?.parserData) {
			const adIndex = nav.findIndex((navItem) => navItem.id === "ad");
			nav.splice(adIndex, 1);
		}

		if (realEstate && !realEstate.applications) {
			const impressionsAndViewsIndex = nav.findIndex((navItem) => navItem.id === "impressionsAndViews");
			nav.splice(impressionsAndViewsIndex, 1);
		}
		return nav;
	};

	const navigationMap = {
		view: navigationCommon,
		documents: undefined,
		edit: undefined,
	};

	const [activeNav, setActiveNav] = useState<FixedNavProps>(navigationMap.view);
  useEffect(() => {
    setNavigationY(navigationYGenerator());
  }, [realEstate, isParser, isNew, isDisableEdit, setNavigationY]);

	useEffect(() => {
		if (mode !== "ad") {
			setActiveNav(navigationMap[mode]);
		}
	}, [mode, navigationCommon.additionalLabel]);

	// useEffect(() => {
	// 	if (!!realEstate?.parserData && mode === "edit") {
	// 		changeQueryParams([{ name: "mode", newValue: "view" }]);
	// 	}
	// }, [realEstate?.parserData, mode]);

	const onAdNav = (platforms: Array<{ name: string; id: string }>) =>
		setActiveNav({
			navigation: [
				{
					label: "Общая",
					action: () => document.getElementById("ad_common")?.scrollIntoView(),
				},
				...platforms.map((i) => ({
					label: i.name,
					action: () => document.getElementById(i.id)?.scrollIntoView(),
				})),
				{
					label: "Бесплатные",
					action: () => document.getElementById("ad_free")?.scrollIntoView(),
				},
				{
					label: "Платные",
					action: () => document.getElementById("ad_non_free")?.scrollIntoView(),
				},
			],
		});

	return {
		activeNav,
    onAdNav,
    navigationY,
    blocksIds,
    setNavigationY,
		setActiveNav
	};
};
