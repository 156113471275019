import { memo, ReactNode } from "react";
import cn from "classnames";

import { Tag } from "../../../../../../UI";

import styles from "./index.module.scss";

type Props = {
	Icon: ReactNode;
	date: string;
	dayOfWeek: string;
	active: boolean;
};

export const SideMenuTime = memo(
	({ Icon, date, dayOfWeek, active }: Props): JSX.Element => (
		<div className={cn(styles.root, { [styles.active]: active })}>
			<div className={styles.icon}>{Icon}</div>

			<div className={styles.body}>
				<div className={styles.date}>
					<div>{date}</div>

					<Tag classNames={{ wrapper: styles.soon }}>СКОРО</Tag>
				</div>

				<div className={styles.dayOfWeek}>{dayOfWeek}</div>
			</div>
		</div>
	)
);
