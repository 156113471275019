/* eslint-disable sonarjs/cognitive-complexity */
import { BusinessProcess, CategoryEnum, RealEstateFilterField, User } from "../../../../types";
import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import { InfoRow, TransparentInput } from "../../index";
import { cadastralCodeMask, phoneMask } from "../../../../utils/masks";
import {
	Bathroom,
	Category,
	Commission,
	Communication,
	Creator,
	CustomWords,
	Date,
	DealType,
	Floor,
	Metro,
	NumberTemplate,
	RadioAvailability,
	RangeNumber,
	Repair,
	Responsible,
	SelectTemplate,
	Subtitle,
	TextTemplate,
	TimeTemplate,
} from "./Components";
import { capitalizeFirstLetter } from "../../../../utils/formatters";

type Props = {
	register: UseFormRegister<any>;
	setValue: UseFormSetValue<any>;
	getValues: UseFormGetValues<any>;
	control: Control<any>;
	field: RealEstateFilterField;
	users?: User[];
	setCategory?: (category: CategoryEnum[], prevValue: CategoryEnum[]) => void;
	onChangeTypeValue?: (value: BusinessProcess) => void;
	disabled?: boolean;
	isParser?: boolean
};

// Temporary
const withCatchingError = (renderFunction: (props) => JSX.Element) => (props) => {
	const isProduction = process.env.NODE_ENV === "production";

	try {
		return renderFunction(props);
	} catch (err) {
		console.log(err);

		if (isProduction) {
			return <></>;
		}

		throw new Error();
	}
};

// Generate right components by backend type.
export const GenerateComponentByType = withCatchingError(
	({
		register,
		setValue,
		getValues,
		control,
		field,
		users = [],
		setCategory,
		onChangeTypeValue,
		disabled = false,
		isParser
	}: Props): JSX.Element => {
		const {
			dataType: { name: formattedName, nameGroup: formattedNameGroup, type: componentType, mask },
		} = field;

		// Formatting options for selects/checkbox/radios
		const formatOptions = (values) => {
			if (componentType === "materialMultiple" ) {
				return values?.map((value) => ({
					label: value.value ? capitalizeFirstLetter(value.value) :"",
					value: value.value,
				}));
			}

			return values?.map((value) => ({
				label: value ? capitalizeFirstLetter(value) : "",
				value,
			}));
		};

		if (componentType === "metroFilter") {
			return (
				<Metro
					control={control}
					setValue={setValue}
					register={register}
					name={formattedNameGroup as [string, string]}
					disabled={disabled}
				/>
			);
		}

		if (componentType === "subTitle") {
			return <Subtitle field={field} />;
		}

		if (componentType === "homeBathRoom") {
			return (
				<Bathroom
					field={field}
					setValue={setValue}
					register={register}
					control={control}
					disabled={disabled}
				/>
			);
		}

		if (componentType === "communications") {
			return (
				<Communication
					field={field}
					nameGroup={formattedNameGroup as [string, string]}
					setValue={setValue}
					control={control}
					getValues={getValues}
					disabled={disabled}
				/>
			);
		}

		if (componentType === "floors") {
			return (
				<Floor
					field={field}
					setValue={setValue}
					register={register}
					control={control}
					options={formatOptions(field.dataType.allowedValues)}
					disabled={disabled}
				/>
			);
		}

		if (componentType === "numberRange") {
			return (
				<RangeNumber
					setValue={setValue}
					field={field}
					register={register}
					name={formattedName}
					control={control}
					getValues={getValues}
					mask={mask}
					disabled={disabled}
				/>
			);
		}

		if (componentType === "text") {
			return (
				<TextTemplate
					name={formattedName || "unknownText"}
					register={register}
					setValue={setValue}
					control={control}
					label={field.label}
					disabled={disabled}
				/>
			);
		}

		if (
			componentType === "select" ||
			componentType === "multiSelect" ||
			componentType === "materialMultiple"
		) {
			return (
				<SelectTemplate
					field={field}
					name={formattedName || formattedNameGroup[0]}
					options={formatOptions(field.dataType.allowedValues)}
					setValue={setValue}
					register={register}
					control={control}
					getValues={getValues}
					disabled={disabled}
				/>
			);
		}

		if (componentType === "groupedSelectWithPreview") {
			return (
				<Repair
					field={field}
					name={formattedName}
					register={register}
					setValue={setValue}
					control={control}
					disabled={disabled}
				/>
			);
		}

		if (componentType === "cadastralNum") {
			const watcherCadastralNum = useWatch({
				control,
				name: formattedName,
				defaultValue: getValues(formattedName) || "",
			});

			return (
				<InfoRow aspect={{ label: 8, content: 7.3 }} label={field.label}>
					<TransparentInput
						placeholder="00:00:000000:0000"
						register={register}
						name={formattedName}
						mask={cadastralCodeMask}
						setValueForMask={setValue}
						variant="noPadding"
						value={watcherCadastralNum}
						disabled={disabled}
						guide={false}
					/>
				</InfoRow>
			);
		}

		if (componentType === "boolean") {
			return (
				<InfoRow aspect={{ label: 8, content: 7.3 }} label={field.label}>
					<RadioAvailability name={formattedName} control={control} disabled={disabled} />
				</InfoRow>
			);
		}

		if (componentType === "number") {
			return (
				<NumberTemplate
					register={register}
					name={formattedName || "unknownNumber"}
					setValue={setValue}
					control={control}
					label={field.label}
					maxSymbols={field.dataType ? Number(field.dataType.maxSymbols) : undefined}
					disabled={disabled}
				/>
			);
		}

		if (componentType === "publishDateFilter") {
			return (
				<Date control={control} setValue={setValue} register={register} field={field} disabled={disabled} />
			);
		}

		if (componentType === "booleanSearch") {
			return (
				<InfoRow aspect={{ label: 8, content: 7.5 }} label={field.label}>
					<RadioAvailability name={formattedName} control={control} disabled={disabled} />
				</InfoRow>
			);
		}

		if (componentType === "phoneFilter") {
			const watcherPhone = useWatch({
				control,
				name: "phone",
				defaultValue: getValues("phone") || "",
			});

			return (
				<InfoRow aspect={{ label: 8, content: 7.3 }} label={field.label}>
					<TransparentInput
						placeholder="+7 ХХХ ХХХ ХХ-ХХ"
						name="phone"
						register={register}
						withCleanUp={{ setValue }}
						mask={phoneMask}
						setValueForMask={setValue}
						variant="noPadding"
						value={watcherPhone}
						disabled={disabled}
					/>
				</InfoRow>
			);
		}

		if (componentType === "customWordsFilter") {
			return <CustomWords control={control} field={field} name={formattedName} disabled />;
		}

		if (componentType === "responsible" && isParser) {
			return (
				<Responsible
					control={control}
					setValue={setValue}
					getValues={getValues}
					register={register}
					name={formattedName}
					label={field.label}
					users={users}
					disabled={disabled}
				/>
			);
		}

		if (componentType === "creator") {
			return (
				<Creator
					control={control}
					setValue={setValue}
					getValues={getValues}
					register={register}
					name={formattedName}
					label={field.label}
					users={users}
					disabled={disabled}
				/>
			);
		}

		if (componentType === "objectTypeSelect" && setCategory) {
			return (
				<Category
					control={control}
					setValue={setValue}
					getValues={getValues}
					register={register}
					label={field.label}
					setCategory={setCategory}
					disabled={disabled}
				/>
			);
		}

		if (componentType === "commission") {
			return (
				<Commission
					control={control}
					register={register}
					field={field}
					setValue={setValue}
					disabled={disabled}
				/>
			);
		}

		if (componentType === "time") {
			return (
				<TimeTemplate
					register={register}
					control={control}
					getValues={getValues}
					name={formattedName}
					label={field.label}
					disabled={disabled}
				/>
			);
		}

		if (componentType === "dealTypeSelect") {
			return (
				<DealType
					control={control}
					onChangeTypeValue={onChangeTypeValue}
					label={field?.label}
					disabled={disabled}
				/>
			);
		}

		console.error("Warning: We have not component: ", field.label, componentType);

		return <></>;
	}
);
