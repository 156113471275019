import { KindType, WhoIsClient } from "../../../../../types";
import { RealEstateAd, RealEstateViewWithTabs } from "../../../../../components/pages/RealEstatePage/mode";
import { ReactComponent as Ad } from "../../../../../assets/icons/ad.svg";
import { ApplicationCard } from "../../../../../components/pages/Application";
import { ReactComponent as Shows } from "../../../../../assets/icons/shows_and_views.svg";
import { ShowsAndViews } from "../../../../../components/pages/ShowsAndViews";
import { FixedNavVerticalMenu } from "../../../../../components/UI";
import { useDispatch } from "react-redux";
import { openDrawer, openDrawerInDrawer } from "../../../../redux/modules/drawer/drawer.action";

type Props = {
  atDrawerInDrawer?: boolean
}

type OpenObjectApplication = {
  applicationId: string
  kind: KindType
  whoIsClient?: WhoIsClient
  refetchParent?: () => void;
  atDrawerInDrawer?: boolean
  onClose?: () => void
  disableClose?: boolean;
  hasMap?: boolean
  isMobile?: boolean
  isFromCompilation?: boolean
  classNames?: {
    wrapper?: string;
    rightBlock?: string;
  };
}

export const useOpenObjectApplication = (
  {
    atDrawerInDrawer
  }: Props) => {
  const dispatch = useDispatch()

  const openObjectApplication = (
    {
      applicationId,
      kind,
      whoIsClient,
      refetchParent,
      onClose,
      disableClose,
      hasMap,
      isMobile,
      classNames,
      isFromCompilation
    }: OpenObjectApplication
  ) => {

    const viewNav = {
      label: "Объект",
      height: 160,
      title: "view",
      query: {
        name: "applicationId",
        value: applicationId
      }
    };

    const viewChildren = (
      <RealEstateViewWithTabs
        id={applicationId}
        kind={kind}
        inDrawerInDrawer={atDrawerInDrawer}
        isFromCompilation={isFromCompilation}
      />
    )

    const adNav = {
      label: <Ad/>,
      height: 90,
      title: "ad"
    };
    const adChildren = (
      <RealEstateAd
        id={applicationId}
        applicationId={applicationId}
        kind={kind}
        enabled={whoIsClient === "seller"}
      />
    )

    const appNav = {
      label: "Заявка",
      height: 160,
      title: "app",
      query: {
        name: "applicationId",
        value: applicationId
      }
    }
    const appChildren = (
      <ApplicationCard
        applicationId={applicationId}
        kind={KindType.APPLICATION}
        inDrawerInDrawer={atDrawerInDrawer}
        refetch={refetchParent}
      />
    )

    const showNav = {
      label: <Shows/>,
      title: "shows",
      height: 90,
    };

    const showChildren = (
      <ShowsAndViews
        applicationId={applicationId}
        type={whoIsClient === "buyer" ? "views" : "shows"}
      />
    );

    const yNavigation: Omit<FixedNavVerticalMenu, "id" | "changeTab">[] = [viewNav, appNav, showNav, adNav]

    if (atDrawerInDrawer) {
      dispatch(openDrawerInDrawer(
        {
          children: [viewChildren, appChildren, showChildren, adChildren],
          width: 800,
          yNavigation: {
            navigation: yNavigation,
            initialTab: 0,
          },
          onClose,
        }))
    } else {
      dispatch(openDrawer(
        {
          children: [viewChildren, appChildren, showChildren, adChildren],
          width: 800,
          yNavigation: {
            navigation: yNavigation,
            initialTab: 0,
          },
          onClose,
          disableClose,
          hasMap,
          isMobile,
          classNames,
        }))
    }
  }

  return {
    openObjectApplication
  }
}