import { useEffect, useRef } from "react";

type Props = {
	onTick: (fps: number) => void;
	enabled?: boolean;
	/** How often tick will proc */
	stepMs?: number;
};

export const useFps = ({ onTick, enabled = true, stepMs = 100 }: Props) => {
	const frames = useRef(0);
	const prevTime = useRef<number | null>(null);
	const isFinish = useRef(false);

	const tick = () => {
		if (isFinish.current) return;

		prevTime.current = prevTime.current ?? Date.now();
		frames.current += 1;

		const time = Date.now();
		const diff = time - prevTime.current;

		if (diff > stepMs) {
			const fps = (frames.current * 1000) / diff;
			frames.current = 0;
			prevTime.current = time;

			onTick(fps);
		}

		requestAnimationFrame(tick);
	};

	useEffect(() => {
		if (enabled) {
			isFinish.current = false;
			requestAnimationFrame(tick);
		} else {
			// drop to initial
			frames.current = 0;
			prevTime.current = null;
			isFinish.current = true;
		}
	}, [enabled]);
};
