import { memo } from "react";
import cn from "classnames";
import { P, formatDate } from "@sdelka_crm/sdelka-crm-component-library";

import { formatNumbers } from "../../../../utils/formatters";
import { RealEstate } from "../../../../types";

import { ReactComponent as BuildingsIcon } from "../../../../assets/icons/buildings.svg";
import styles from "./index.module.scss";

type Props = {
	classNames?: {
		root?: string;
	};
	clientObject: RealEstate;
	variant?: "vertical" | "horizontal";
	onCardClick?: (id: string) => void;
};

export const CardObjectPublic = memo(
	({ classNames, clientObject, variant = "vertical", onCardClick }: Props): JSX.Element => {
		const IS_VERTICAL = variant === "vertical";

		const { title } = clientObject;

		const { photos, shortId, addressLabel, createdAt, pricePerUnit, price } = clientObject;

		const handleCardClick = () => {
			if (onCardClick) {
				onCardClick(clientObject.id);
			}
		};

		return (
			<div
				className={cn(styles.root, classNames?.root, styles[`root__${variant}`])}
				onClick={handleCardClick}
			>
				<div className={styles.imageContainer}>
					{photos.length ? <img alt="real-estate" src={photos[0].photo1280} /> : <BuildingsIcon />}
				</div>

				<div className={styles.content}>
					<div className={styles.titleId}>
						<p className={styles.title}>{title}</p>

						{!IS_VERTICAL && <span className={styles.id}>{`id ${shortId}`}</span>}
					</div>

					<div>
						<P variant="medium-thin" classNames={{ root: styles.address }}>
							{addressLabel}
						</P>
					</div>

					<div className={styles.priceDateContainer}>
						<div className={styles.priceContainer}>
							<p className={styles.price}>{`${formatNumbers(price, 3)} ₽`}</p>

							<p className={styles.pricePerUnit}>{`${formatNumbers(pricePerUnit || "", 3)} ₽/м²`}</p>
						</div>

						{!IS_VERTICAL && <span className={styles.createdAt}>{`Создан ${formatDate(createdAt)}`}</span>}
					</div>
				</div>
			</div>
		);
	}
);
