import { MouseEvent, ReactNode } from "react";
import cn from "classnames";

import styles from "./index.module.scss";

type Props = {
	src?: string;
	onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
	children?: ReactNode;
	classNames?: {
		root?: string;
	};
	inner?: boolean;
};

export const Link = ({ classNames, src = "#", onClick, children, inner = false }: Props): JSX.Element => (
	<a
		href={src}
		target="_blank"
		rel="noreferrer"
		onClick={onClick}
		className={cn(styles.root, classNames?.root, { [styles.inner]: inner })}
	>
		{children}
	</a>
);
