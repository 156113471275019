import { AdStatusesEnumV2 } from "./ad";

export const unitValue = {
  м2: "м²",
  сотка: "сот.",
  гектар: "га",
};

export const currencyValues = {
  rub: {label: "₽", value: "rub"},
  usd: {label: "$", value: "usd"},
  eur: {label: "€", value: "eur"},
};

const REPAIR_TYPE_A = "Дизайнерский";
const REPAIR_TYPE_B = "Евроремонт";
const REPAIR_TYPE_C = "Косметический";

export const repairValues = {
  "A+": REPAIR_TYPE_A,
  A: REPAIR_TYPE_A,
  "A-": REPAIR_TYPE_A,
  "B+": REPAIR_TYPE_B,
  B: REPAIR_TYPE_B,
  "B-": REPAIR_TYPE_B,
  "C+": REPAIR_TYPE_C,
  C: REPAIR_TYPE_C,
  D: "Требует ремонта",
  E: "Чистовая",
  "E-": "Предчистовая",
  F: "Черновая",
};

export const adStatuses: Record<AdStatusesEnumV2, string> = {
  [ AdStatusesEnumV2.NOT_POSTED ]: "Без рекламы",
  [ AdStatusesEnumV2.WAITING ]: "Ожидает размещения",
  [ AdStatusesEnumV2.ERROR ]: "Ошибка",
  [ AdStatusesEnumV2.POSTED ]: "В рекламе",
  [ AdStatusesEnumV2.ARCHIVE ]: "Закончилась",
};
