import { Button, P } from "@sdelka_crm/sdelka-crm-component-library";

import { CardTask } from "../../../../../Card";
import { useOpenTaskModal } from "../../../../../../../utils/hooks";
import { Task, TaskTypeLabelEnum, TaskSubTypeLabelEnum } from "../../../../../../../types";

import styles from "./index.module.scss";

type Props = {
	exists: Task[];
	templates: Task[];
	tasksCreated: boolean;
	isLoading: boolean;
	handleAcceptTasks: () => void;
	refetch: () => void;
};

export const TasksList = ({
	exists,
	templates,
	tasksCreated,
	isLoading,
	handleAcceptTasks,
	refetch,
}: Props): JSX.Element => {
	const haveExists = exists && exists.length > 0;

	const tasks = haveExists ? exists : templates;

	const onUpdatedTask = () => refetch();

	const { handleOpenTaskModal } = useOpenTaskModal({ onUpdatedTask });

	const onCardClick = (id?: string) => {
		if (tasksCreated && id) {
			handleOpenTaskModal(id);
		}
	};

	const createLabel = (task: Task) => {
		const { type, subtype } = task;

		return `${TaskTypeLabelEnum[type]} ${subtype ? `- ${TaskSubTypeLabelEnum[subtype]}` : ""}`;
	};

	return (
		<>
			{tasks?.length ? (
				<div className={styles.tasksContainer}>
					<div className={styles.infoContainer}>
						<P variant="small" classNames={{ root: styles.text }}>
							{tasksCreated
								? "Задачи созданы"
								: `При выборе этого варианта вам будут поставлены задачи (${tasks.length})`}
						</P>

						{!tasksCreated && (
							<Button variant="text" isLoading={isLoading} onClick={handleAcceptTasks}>
								Подтвердить
							</Button>
						)}
					</div>

					{tasks?.map((task, index) => (
						<div
							// eslint-disable-next-line react/no-array-index-key
							key={index}
							className={styles.cardTaskContainer}
						>
							<CardTask
								description={task.description}
								date={new Date()}
								status={task.status}
								important={task.priority === "important"}
								label={createLabel(task)}
								onCardClick={onCardClick}
								id={task.id}
								isSuccessFinished={task.isSuccessFinished}
								deadline={task.deadline}
							/>
						</div>
					))}
				</div>
			) : null}
		</>
	);
};
