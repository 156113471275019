import { CardObjectExistOrNot , CardObjectBuyElement } from "../../../../../../../../../UI";

import { scrollIntoView } from "../../../../../../../../../../utils/scroll";
import { RealEstate } from "../../../../../../../../../../types";

import styles from "./index.module.scss";

type Props = {
	whoIsClient: string;
	clientObject: RealEstate;
	applicationId: string;
	isMobile?: boolean
};
export const Header = (
	{
		whoIsClient,
		clientObject,
		applicationId,
		isMobile,
	}: Props): JSX.Element => {
	const toScroll = () => scrollIntoView(whoIsClient === "seller" ? "jointObject" : "jointPodbor");

	return (
		<div className={styles.cardObjectExist}>
			{(whoIsClient === "seller" && (
				<CardObjectExistOrNot clientObject={clientObject} onAddObject={toScroll} isMobile={isMobile}/>
			)) ||
				(clientObject ? (
					<CardObjectExistOrNot clientObject={clientObject} onAddObject={toScroll} isMobile={isMobile}/>
				) : (
					<div>
						<CardObjectBuyElement applicationId={applicationId} mini={isMobile} fromObject={false}/>
					</div>
				))}
		</div>
	);
};
