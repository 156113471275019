import { useEffect, useState } from "react";
import { UseFormClearErrors, UseFormSetError } from "react-hook-form";
import { toastError, toastSuccess } from "../../../../../UI";
import { selectObjectEdit, useSelect } from "../../../../../../service/redux/selectors";
import { useApplicationRealEstateUpdate } from "../../../../../../service/api/mutations";
import { RealEstatePhoto } from "../../../../../../types";

type Props = {
  refetchRealEstate: () => void;
  setError: UseFormSetError<any>;
  clearErrors: UseFormClearErrors<any>;
  applicationId: string;
  disableMap?: boolean;
  realEstatePhotos?: RealEstatePhoto[]
  onClose?: () => void
};

type Return = {
  onSubmit: (data: any) => void;
  isUpdateLoading: boolean;
  photos: RealEstatePhoto[],
  setPhotos: React.Dispatch<React.SetStateAction<RealEstatePhoto[]>>
};

type UseOnSubmit = (props: Props) => Return;

export const useOnSubmit: UseOnSubmit = (
  {
    applicationId,
    setError,
    clearErrors,
    disableMap,
    refetchRealEstate,
    realEstatePhotos,
    onClose
  }) => {
  const {coord, addressId, addressLabel} = useSelect(selectObjectEdit);
  const [photos, setPhotos] = useState<RealEstatePhoto[]>(realEstatePhotos || [])

  useEffect(() => {
    if (coord.lat && coord.lon && addressLabel) {
      clearErrors("addressLabel");
    }
  }, [coord.lat, coord.lon, addressLabel]);

  const {mutate, isLoading} = useApplicationRealEstateUpdate({
    onSuccess: () => {
      toastSuccess({text: "Изменения сохранены"});
      if (onClose) {
        onClose()
      }
      refetchRealEstate()
    },
  });

  const onSubmit = (data: any) => {
    if (!disableMap && (!coord.lat || !coord.lon || !addressLabel)) {
      toastError({text: "Отметьте объект на карте и введите адрес"});
      setError("addressLabel", {
        message: "Отметьте объект на карте и введите адрес",
      });

      return;
    }

    const payload = {
      ...data,
      price: Number((String(data.price).replaceAll(" ", ""))),
      pricePerUnit: Number(String(data.pricePerUnit).replaceAll(" ", "")),
      addressId,
      addressLabel,
      photos,
      location: !disableMap
        ? {
          coordinates: [coord.lat, coord.lon],
          type: "Point",
        }
        : undefined,
    };

    delete payload.objectSubType;
    delete payload.id;
    delete payload.schema;
    delete payload.shortId;
    delete payload.catalog;
    delete payload.createdAt;
    delete payload.modifiedAt;
    delete payload.parserPhones;
    delete payload.sourceLinks;
    delete payload.parserData;
    delete payload.creatorId;
    delete payload.companyId;
    delete payload.responsibleUsers;
    delete payload.flatType;
    delete payload.creator;
    delete payload.priceFluctuations;
    delete payload.middlePrice;
    delete payload.metroData;
    delete payload.feedReview;
    delete payload.idsToCompare;
    delete payload.region;
    delete payload.copiedFromId;
    delete payload.permissions;
    delete payload.applications;
    delete payload.modifiedAtList;
    delete payload.source;
    delete payload.similarIds;
    delete payload.image;
    delete payload.point;
    delete payload.activeApplications;
    delete payload.title;
    delete payload.success;
    delete payload.modifiedAt;
    delete payload.category;
    delete payload.applicationId;
    delete payload.isArchived;
    delete payload.responsibleUser;
    delete payload.ownerComment;
    delete payload.actual;
    delete payload.type.label;
    delete payload.type.value;
    delete payload.subways;
    delete payload.priceDynamics;
    delete payload.type.value;
    delete payload.sellerContacts;
    delete payload.iAmResponsibleUser;
    delete payload.shortType;
    delete payload.stage;
    delete payload.stageNo;
    delete payload.icons;
    delete payload.contact;
    delete payload.shows;
    delete payload.preApplicationId
    delete payload.lastActiveCommunicationId

    mutate({id: applicationId || "", data: payload});
  };

  return {
    onSubmit,
    isUpdateLoading: isLoading,
    photos,
    setPhotos
  };
};
