import { useTasksContext } from "../../../../../../service/context/Tasks";
import { DefaultTasksFiltersType } from "../../../../../../types";

export const useFilterNecessityChecker = () => {
	const { roleRelatedFilters } = useTasksContext();

	const isFilterNecessary = (filter: keyof DefaultTasksFiltersType) => roleRelatedFilters.includes(filter);

	return { isFilterNecessary };
};
