import { api } from "../../api";
import { useQuery } from "react-query";
import { DocumentsTabType } from "../../../../types";


const DEFAULT_QN = "sdelka/deal/load-deal-documents/DEFAULT_QN";

type Props = {
  applicationId: string
  tab: DocumentsTabType
}

export const useLoadDocuments = ({applicationId, tab}: Props) => {

  const loadDocuments = () =>
    api.deals.getDocuments(applicationId, tab)

  const { data, isLoading, refetch, isFetching, isError } = useQuery(
    [DEFAULT_QN],
    loadDocuments,
  );

  return {
    documents: data?.data,
    isLoading,
    refetchDocuments: refetch,
    isFetching,
    isError
  }
}