import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import { createNumberMask } from "text-mask-addons";

import { InfoRow, QuestionnaireValue, TransparentInput } from "../../../index";
import { Boolean } from "../Boolean";

type Props = {
	handleOnChange?: (value: QuestionnaireValue) => void;
	register: UseFormRegister<any>;
	setValue: UseFormSetValue<any>;
	getValues: UseFormGetValues<any>;
	control: Control<any>;
	nameGroup: string[];
	disabled?: boolean;
	options?: { label: string; value: number }[];
};

export const BooleanWithNum = ({
	nameGroup,
	register,
	getValues,
	setValue,
	control,
	handleOnChange,
	disabled,
	options,
}: Props): JSX.Element => {
	const [radioName, inputName] = nameGroup;

	const [watcherRadio, watcherInput] = [
		useWatch({
			control,
			name: radioName,
			defaultValue: getValues(radioName),
		}),
		useWatch({
			control,
			name: inputName,
			defaultValue: getValues(inputName),
		}),
	];

	const change = (value: QuestionnaireValue) => {
		if (value === 0) {
			setValue(inputName, "");
		}

		if (handleOnChange) {
			handleOnChange(value);
		}
	};

	return (
		<>
			<Boolean
				name={radioName}
				control={control}
				handleOnChange={change}
				disabled={disabled}
				options={options}
			/>

			{watcherRadio === 1 && (
				<InfoRow label="Введите сумму за услугу">
					<TransparentInput
						register={register}
						placeholder="Указать"
						name={inputName || "1"}
						value={watcherInput}
						onChange={handleOnChange}
						mask={createNumberMask({
							prefix: "",
							thousandsSeparatorSymbol: "",
						})}
						disabled={disabled}
						setValueForMask={setValue}
					/>
				</InfoRow>
			)}
		</>
	);
};
