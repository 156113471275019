import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { useHistory } from "react-router";
import { CompanyCreatePayload, InviteAccept, LoginRequestBody, User } from "../../../../types";
import { cleanUpAccounts, initializeApp, setAsEmployee, setErrors, setUsers } from "./accountsReducer";
import { cleanUpAccount, setCurrentUser, setLoadingCurrentUser } from "./currentAccountReducer";
import { paths } from "../../../../utils/paths";
import { api, appApi } from "../../../api";
import { AllTokens } from "../../../api/services/inner/TokenService";
import { AppState } from "../../store";
import { setRegistrationData, setStep } from "../registration-steps/reg-steps.action";

type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, AnyAction>;

type AppThunkDispatch = ThunkDispatch<AppState, unknown, AnyAction>;

type SignInThunkProps = LoginRequestBody;

const getUsers = async (tokens: string[]): Promise<User[]> => {
	const promises = tokens.map((token) => api.auth.me(token));

	return Promise.all(promises);
};

export const initialize = (jwtFromTG?: string): AppThunk => async (dispatch: AppThunkDispatch) => {
	console.log("start init")
	if (jwtFromTG) {
		appApi.token.setCurrentToken(jwtFromTG)

		dispatch(signIn({token: jwtFromTG}))
	}

	const tokensFromStorage = appApi.token.getTokens();

	const isAsEmployee = !!appApi.token.getParentTokens();

	if (tokensFromStorage) {
		const { allTokens } = tokensFromStorage;

		const currentToken = tokensFromStorage.current;
		const currentUserId = allTokens.find((user) => user.token === currentToken);

		const tokens = allTokens?.map((token) => token.token);

		if (tokens) {
			try {
				const usersInfo = await getUsers(tokens);

				dispatch(setCurrentUser(usersInfo.find((user) => user.id === currentUserId?.userId) || undefined));
				dispatch(setUsers(usersInfo));
				dispatch(setAsEmployee(isAsEmployee));
				dispatch(initializeApp());
			} catch (error) {
				// do logout when some error occur, for now
				dispatch(cleanUpAccount());
				dispatch(cleanUpAccounts());
				api.auth.signOut();
			}
		}
	}

	if (!tokensFromStorage) {
		dispatch(initializeApp());
	}
};

export const changeCurrentUser =
	({ user }: { user: User }): AppThunk =>
	async (dispatch: AppThunkDispatch) => {
		appApi.token.changeCurrentToken(user.id, window.location.pathname);
		dispatch(setCurrentUser(user));
	};

/**
 * Finalize registration via setting tokens and users
 */
export const useFinishRegistration = (): AppThunk => async (dispatch: AppThunkDispatch) => {
	const currentToken = appApi.token.getCurrentToken() ?? "";
	const users = await getUsers([currentToken]);
	const currentUser = users[0];
	const allTokens: AllTokens[] = [{ userId: currentUser.id, token: currentToken }];

	appApi.token.setTokens(
		{
			allTokens,
			current: currentToken,
		},
		{ rememberMe: true }
	);

	dispatch(setUsers(users));
	dispatch(setCurrentUser(currentUser));
};

export const signIn =
	({ phone, password, token }: SignInThunkProps, history?: ReturnType<typeof useHistory>): AppThunk =>
	async (dispatch: AppThunkDispatch) => {

		dispatch(setLoadingCurrentUser(true));
		const authData = { phone, password };
		dispatch(setErrors(undefined));

		if (token) {
			const users = await getUsers([token]);
			dispatch(setUsers(users));
			dispatch(setCurrentUser(users.find((user) => user.type === "estateAgencyUser") || users[0]));
		} else  {
			try {
				const signInData = await api.auth.signIn(authData);
				const tokens = signInData.map((sign) => sign.token);
				const users = await getUsers(tokens);
				dispatch(setUsers(users));
				dispatch(setCurrentUser(users.find((user) => user.type === "estateAgencyUser") || users[0]));

				const url = `${paths.objectsPath.list}?tab=PARSED`;

				appApi.token.changeCurrentToken(
					users.find((user) => user.type === "estateAgencyUser")?.id || users[0].id,
					url
				);
				dispatch(setLoadingCurrentUser(false));
			} catch (error: any) {
				const response = error?.response?.data?.response || error?.response?.data;
				const message = response?.message;

				dispatch(setErrors(message));
				dispatch(setLoadingCurrentUser(false));

				const errorCode = response?.error?.errorCode;
				const shouldRedirectToRoleForm = errorCode === "RE003";

				if (shouldRedirectToRoleForm) {
					appApi.token.setCurrentToken(response?.error?.payload?.token);
					dispatch(setRegistrationData({phone}));
					if (history) {
						history.push("/registration");
					}
					dispatch(setStep("finish-registration"));
				}
			}
		}
	};

export const authAsEmployeeThunk =
	(userId: string): AppThunk =>
	async (dispatch: AppThunkDispatch) => {
		dispatch(setLoadingCurrentUser(true));
		dispatch(setErrors(undefined));

		try {
			const signInAsEmployeeData = await api.auth.signInAsEmployee(userId);

			if (signInAsEmployeeData) {
				const tokens = signInAsEmployeeData.map((sign) => sign.token);
				const users = await getUsers(tokens);
				dispatch(setUsers(users));
				dispatch(setCurrentUser(users.find((user) => user.type === "estateAgencyUser") || users[0]));

				appApi.token.changeCurrentToken(
					users.find((user) => user.type === "estateAgencyUser")?.id || users[0].id
				);
			}
		} catch (error: any) {
			dispatch(setErrors(error?.response?.data?.response?.message || error?.response?.data?.message));
			dispatch(setLoadingCurrentUser(false));
		}
	};

export const logoutAsEmployeeThunk = (): AppThunk => async (dispatch: AppThunkDispatch) => {
	dispatch(setLoadingCurrentUser(true));
	dispatch(setErrors(undefined));

	try {
		const parentTokens = appApi.token.getParentTokens();

		if (parentTokens) {
			const tokens = parentTokens.allTokens.map((token) => token.token);
			const users = await getUsers(tokens);

			dispatch(setUsers(users));
			dispatch(setCurrentUser(users.find((user) => user.type === "estateAgencyUser") || users[0]));
			dispatch(setAsEmployee(false));

			appApi.token.setTokens(parentTokens, { rememberMe: true });
			appApi.token.removeTokens("parent");
			appApi.token.changeCurrentToken(
				users.find((user) => user.type === "estateAgencyUser")?.id || users[0].id
			);
		}
	} catch (error: any) {
		dispatch(setErrors(error?.response?.data?.response?.message || error?.response?.data?.message));
		dispatch(setLoadingCurrentUser(false));
	}
};

export const logoutThunk = (): AppThunk => (dispatch: AppThunkDispatch) => {
	dispatch(cleanUpAccount());
	dispatch(cleanUpAccounts());
	api.auth.signOut();
};

export const registerCompany =
	({ addressLabel, addressId, companyName }: CompanyCreatePayload) =>
	async (dispatch: AppThunkDispatch) => {
		const companyData = { addressLabel, addressId, companyName };

		try {
			const registerCompanyData = await api.auth.createCompany(companyData);

			const users = registerCompanyData.data;
			const currentUserToken = users[0].token;

			appApi.token.setCurrentToken(currentUserToken);

			dispatch(useFinishRegistration());
		} catch (error: any) {
			console.log(error?.response?.data?.response?.message);
		}
	};

export const acceptInvite =
	({ id }: InviteAccept): AppThunk =>
	async (dispatch: AppThunkDispatch) => {
		try {
			const response = await api.invites.acceptInvite(id);
			const users = response.data;

			appApi.token.setCurrentToken(users[0].token);

			dispatch(useFinishRegistration());
		} catch (error: any) {
			console.log(error?.response?.data?.response?.message);
		}
	};

	export const joinToSdelkaPro2 =
	(): AppThunk =>
	async (dispatch: AppThunkDispatch) => {
		try {
			const response = await api.auth.joinTosdelkaPro
			const users = (response as any).data;

			appApi.token.setCurrentToken(users[0].token);

			dispatch(useFinishRegistration());
		} catch (error: any) {
			console.log(error?.response?.data?.response?.message);
		}
	};