import { RealEstateListPayload } from "../../types";

import { getRealEstateFilters, getRealEstateSort } from "../../service/api/helpers";

type Props = Pick<RealEstateListPayload, "search" | "sort" | "join" | "absoluteSearchConditions">;

export const createListFieldsPayload = ({ sort, search, join, absoluteSearchConditions = [] }: Props) => {
	const searchConditions = search
		? getRealEstateFilters({ searchPayload: search, absoluteSearchConditions })
		: undefined;
	const sortConditions = sort ? getRealEstateSort(sort) : undefined;
	const joinConditions = join || ["responsibleUsers", "photos"];

	return {
		searchConditions,
		sortConditions,
		joinConditions,
	};
};
