import { toastInfo, toastError } from "../../../components/UI";

import { ReactComponent as CopyIcon } from "../../../assets/icons/copy.svg";

const SUCCESS_COPY_TEXT = "Скопировано в буфер обмена";
const ERROR_COPY_TEXT = "Не удалось скопировать";

type Props = {
	successToastText?: string;
	errorToastText?: string;
};

type Return = {
	onCopy: (text: string) => void;
};

type UseCopyTextError = (props: Props) => Return;

export const useCopyText: UseCopyTextError = ({
	successToastText = SUCCESS_COPY_TEXT,
	errorToastText = ERROR_COPY_TEXT,
}: Props) => {
	const onToastInfo = () => toastInfo({ text: successToastText, Icon: CopyIcon });

	const onToastError = () => toastError({ text: errorToastText });

	const copyByCreateElement = (text: string) => {
		const tempInput = document.createElement("input");
		tempInput.value = text;
		document.body.appendChild(tempInput);
		tempInput.select();

		try {
			document.execCommand("copy");
			onToastInfo();
		} catch (err) {
			onToastError();
		}

		document.body.removeChild(tempInput);
	};
	const onCopy = (text: string): void => {
		if (!navigator.clipboard) {
			copyByCreateElement(text);
		} else {
			navigator.clipboard
				.writeText(text)
				.then(() => onToastInfo())
				.catch(() => onToastError());
		}
	};

	return {
		onCopy,
	};
};
