import { ApplicationInListV2, KindType } from "../../../types";
import { CardApplication } from "../../../components/UI/Card/card-application";
import { CardApplicationMini } from "../../../components/UI/Card/card-application/card-application-mini";

type Props = {
  dots?: boolean;
  withStage?: boolean;
  contextClick?: string;
  refetchApplications?: () => void;
  kind?: KindType
  isMobile?: boolean
};

export const useTableApplicationColumns = ({withStage, kind, isMobile}: Props) => [
    {
      accessor: "cardApplication",
      render: ({row}: { row: ApplicationInListV2 }) => (
        <>
          {isMobile
            ?  <CardApplicationMini
              application={row}
              isMobile={isMobile}
              withoutStage
            />
            : <CardApplication
            kind={kind}
            application={row}
            withStage={withStage}
            canOpenANewTab
          />
          }
        </>
        ),
    }
  ];
