import { useState } from "react";
import { Info } from "./component";
import { useLoadCardBlocks } from "../../../../../../service/api/queries";
import { ApplicationCardResponse, KindType } from "../../../../../../types";

type Props = {
  application: ApplicationCardResponse;
  applicationId: string;
  refetch: () => void;
  onTab?: (tab: number) => void;
  kind?: KindType;
  isMobile?: boolean
};


export const ApplicationInfo = (
  {
    application,
    applicationId,
    refetch,
    onTab,
    kind,
    isMobile
  }: Props): JSX.Element => {

  const {stages} = application;
  const [stageNum, setStageNum] = useState(0)

  const {data: currentBlocks, isFetching: isFetchingBlocks} = useLoadCardBlocks({
    applicationId,
    stage: stages[ stageNum ].title,
    enabled: !!applicationId,
    kind
  });

  return (
    <Info
      applicationId={applicationId}
      application={application}
      refetch={refetch}
      onTab={onTab}
      stageNum={stageNum}
      setStageNum={setStageNum}
      isFetchingBlocks={isFetchingBlocks}
      currentBlocks={currentBlocks}
      kind={kind}
      isMobile={isMobile}
    />
  );
};
