import { ReactNode } from "react";

export const OPEN_MODAL = "sdelka/modal/OPEN_MODAL";
export const CLOSE_MODAL = "sdelka/modal/CLOSE_MODAL";
export const ACTIVATE_LOADING = "sdelka/modal/ACTIVATE_LOADING";
export const DEACTIVATE_LOADING = "sdelka/modal/DEACTIVATE_LOADING";
export const CHANGE_TITLE = "sdelka/modal/CHANGE_TITLE";
export const DELETE_TITLE = "sdelka/modal/DELETE_TITLE";

export const DEFAULT_MODAL_WIDTH = 500;
export const DEFAULT_ALERT_MODAL_WIDTH = 672;

export type ModalVariants = "default" | "alert";

type SetModal = {
	title?: string | ReactNode;
	variant?: ModalVariants;
	alertIcon?: ReactNode;
	alertButtonSubmit?: {
		label: string;
		onClick: () => void;
	};
	alertButtonDecline?: {
		label: string;
		onClick: () => void;
	};
	body: string | ReactNode;
	width?: number;
	onClose?: () => void;
	classNames?: {
		root?: string;
		contentBody?: string;
		contentWrapper?: string;
		wrapper?: string;
		buttonClose?: string
	};
	contentClass?: string
};

type ChangeTitle = {
	newTitle: string | ReactNode | JSX.Element;
};

export type ModalAction =
	| { type: typeof OPEN_MODAL; payload: SetModal }
	| { type: typeof CLOSE_MODAL }
	| { type: typeof ACTIVATE_LOADING }
	| { type: typeof DEACTIVATE_LOADING }
	| { type: typeof CHANGE_TITLE; payload: ChangeTitle }
	| { type: typeof DELETE_TITLE };

export const openModal = ({
	title,
	body,
	variant = "default",
	alertIcon,
	alertButtonSubmit,
	alertButtonDecline,
	width = DEFAULT_MODAL_WIDTH,
	onClose,
	classNames,
}: SetModal): ModalAction => ({
	type: OPEN_MODAL,
	payload: {
		title,
		variant,
		alertIcon,
		alertButtonSubmit,
		alertButtonDecline,
		body,
		width,
		onClose,
		classNames,
	},
});

export const closeModal = (): ModalAction => ({
	type: CLOSE_MODAL,
});

export const activateLoading = (): ModalAction => ({
	type: ACTIVATE_LOADING,
});

export const deactivateLoading = (): ModalAction => ({
	type: DEACTIVATE_LOADING,
});

export const changeTitle = (payload: ChangeTitle): ModalAction => ({
	type: CHANGE_TITLE,
	payload,
});

export const deleteTitle = (): ModalAction => ({
	type: DELETE_TITLE,
});
