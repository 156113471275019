import { useDispatch } from "react-redux";
import { selectDevice, useSelect } from "../../../../../../service/redux/selectors";
import styles from "./index.module.scss"
import { openModal } from "../../../../../../service/redux/modules/modal/modal.action";
import { CreateViewModal } from "./create-view-modal";

type Props = {
  applicationId: string
  refetchList: () => void
}

export const useOpenCreateViewModal = (
  {
    applicationId,
    refetchList,

  }: Props) => {
  const dispatch = useDispatch()
  const {isMobile} = useSelect(selectDevice)
  const width = isMobile ? 500 : 800

  const openCreateViewModal = () => {
    dispatch(
      openModal({
        width,
        contentClass: styles.root,
        classNames: {
          contentBody: styles.root,
          contentWrapper: isMobile ? styles.modal : styles.wrapper,
          buttonClose: isMobile ? styles.modalClose : ""
        },
        body:
          <CreateViewModal
            applicationId={applicationId}
            refetchList={refetchList}
            isMobile={isMobile}
          />
      })
    )
  }

  return {
    openCreateViewModal
  }
}