import { useMutation } from "react-query";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/shows-views/change-show-lead-source/DEFAULT_MN";


type Props = {
  onSuccess?: () => void;
}

export const useChangeShowLeadSource = ({onSuccess}: Props) => {
  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: ({id, leadSource}: {id: string, leadSource: string}) => api.showsViews.changeShowLeadSource(id, leadSource),
    onSuccess
  })

  return {
    mutate: mutation.mutate,
  }
}