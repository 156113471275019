import styles from "./index.module.scss"
import { ReactComponent as Help } from "../../../../../../assets/icons/help_outline.svg";
import { Tooltip } from "@sdelka_crm/sdelka-crm-component-library";
import { ReactComponent as Info } from "../../../../../../assets/icons/iconInfo.svg";
import { ReactComponent as Plus } from "../../../../../../assets/icons/plus-blue-small.svg";
import { useOpenImportObjectModal } from "./import-object-modal/useOpenImportObjectModal";

export const ImportObject = () => {

  const {handleImportObject} = useOpenImportObjectModal()

  return (
    <div className={styles.root} onClick={handleImportObject}>
      <div className={styles.image}>
        <Help/>
      </div>
      <div className={styles.content}>
        <div className={styles.row}>
          <div className={styles.text}>
            Скопировать объект из другого источника
          </div>
          <Tooltip
            CustomIcon={Info}
            classNames={{
              root: styles.tooltipRoot,
              card: styles.tooltipCard,
            }}
            iconSize="medium"
          >
            Вставьте ссылку на объект из Авито, Циан или Домклик, чтобы автоматически заполнить поля
          </Tooltip>
        </div>
      </div>
      <div className={styles.stage}>
        <Plus/>
      </div>
    </div>
  )
}