import { DashboardTableColumnTypeEnum, DashboardTableResponseColumn } from "../../../../../../types";
import styles from "./index.module.scss"

export const useColumns = (columns?: DashboardTableResponseColumn[]) => {

  const columnNumber = (value) => (
    <div className={styles.template} style={{background: `${value.color}`}}>
      <div id={`${value.fullPrice}${value.mainValue}${value.color}`}>
        <div className={styles.row}>
          <div className={styles.number}>{value.mainValue}</div>
          {value.fullValue && <div className={styles.numberMain}>{`/${value.fullValue}`}</div>}
        </div>
      </div>
    </div>
  )

  const columnText = (value) => (
    <div className={styles.template}>
      <div id={`${value.text}`}>
        <div className={styles.text}>
          {value.text}
        </div>
      </div>
    </div>
  )

  const cols = columns && columns?.map(elem => ({
    Header: elem.header,
    sortable: true,
    id: elem.name,
    accessor: `${elem.name}`,
    width: 285,
    canSort: true,
    sortType: elem.type === DashboardTableColumnTypeEnum.number ? "number" : "string",
    customTemplate: ({value}: any) => (
      <>
        {elem.type === DashboardTableColumnTypeEnum.number
          ? columnNumber(value)
          : columnText(value)
        }
      </>
    )
  }))

  return {cols}
}