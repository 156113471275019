import { DetailedHTMLProps, InputHTMLAttributes } from "react";
import cn, { Argument } from "classnames";

import styles from "../index.module.scss";

interface IIndividualCheckboxProps
	extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	label?: string;
	showBg?: boolean;
	labelTextClassName?: Argument;
	variant?: "default" | "noOpacity";
	weight?: "regular" | "bold";
}

// Individual checkbox not bound to form.
// For right display you need to pass `checked`. For change state `onChange`.
// If you need just show checkbox - don't forget to pass `readOnly`
export const IndividualCheckbox = ({
	className,
	label,
	labelTextClassName,
	variant = "default",
	weight = "regular",
	showBg = true,
	...props
}: IIndividualCheckboxProps): JSX.Element => (
	<label
		className={cn(styles.label, className, styles[variant], styles[weight], {
			[styles.checked]: props.checked,
			[styles.labelDisabled]: props.disabled,
		})}
	>
		<input type="checkbox" className={styles.checkbox} {...props} />
		{showBg && <span className={styles.checkboxBg} />}
		{label && <span className={cn(styles.labelText, labelTextClassName)}>{label}</span>}
	</label>
);
