import { useQuery } from "react-query";
import { AxiosError } from "axios";

import { api } from "../../index";

export const useLoadInviteByLink = (link: string) => {
	const loadInviteByLink = () => api.invites.getAuthenticationLink(link);

	const { data, isLoading, refetch, error, isFetching } = useQuery("loadInviteByLink", loadInviteByLink, {cacheTime: 0});

	return {
		invite: data?.data,
		isLoading,
		isFetching,
		refetch,
		error: error as AxiosError,
	};
};
