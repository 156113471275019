import { useState } from "react";
import cn from "classnames";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { GalleryGrid } from "../GalleryGrid/GalleryGrid";
import { DroppableZone, PhotosPlug, WatermarkSwitcher } from "./components";
import { useGrabScrollX, useLoadPhotos } from "../../../utils/hooks";
import { RealEstate, RealEstatePhoto } from "../../../types";
import { ReactComponent as Arrow } from "../../../assets/icons/arrow.svg";
import styles from "./index.module.scss";
import { selectDevice, useSelect } from "../../../service/redux/selectors";

type Props = {
  realEstate?: Pick<RealEstate, "id" | "photos"> & Partial<Exclude<RealEstate, "id" | "photos">>;
  refetch?: () => void;
  alwaysOpen?: boolean;
  isFromParser?: boolean;
  photos?: RealEstatePhoto[],
  isCreation?: boolean
  setPhotos?: React.Dispatch<React.SetStateAction<RealEstatePhoto[]>>
  isView?: boolean
};

export const ObjectPhotoGallery = (
  {
    realEstate,
    refetch,
    alwaysOpen,
    isFromParser,
    photos,
    setPhotos,
    isCreation,
    isView
  }: Props): JSX.Element => {
  const {loadPhotos} = useLoadPhotos();
  const {isMobile} = useSelect(selectDevice)

  const [isOpen, setIsOpen] = useState(false);
  const [files, setFiles] = useState<(File & { preview: string; photoType?: "photo" | "plan" })[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const {galleryMouseDown, galleryMouseUp, galleryMouseMove, isGrabbing} = useGrabScrollX();

  const onLoadPhotos = (payload: RealEstatePhoto[]) => {
    const lastOrder = photos && photos.length ? photos[ photos.length - 1 ].order + 1 : 1;

    for (let i = 0; i < payload.length; i += 1) {
      // eslint-disable-next-line no-param-reassign
      payload[ i ].order = lastOrder + payload[ i ].order;
    }

    const photosToAdd = photos?.concat(payload) || []

    if (setPhotos) {
      setPhotos(photosToAdd)
    }
  };

  const onSortPhotos = (photosToSort: RealEstatePhoto[]) => {
    const payload = photosToSort.map((photo, index) => ({
      ...photo,
      id: photo.id,
      order: index + 1,
      description: photo.description,
    }));

    if (setPhotos) {
      setPhotos(payload)
    }
  };

  const onDeletePhoto = (photoId: string): boolean => {
    if (!photos) {
      return false
    }
    const payload: RealEstatePhoto[] = photos?.filter(el => el.id !== photoId)
    if (setPhotos) {
      setPhotos(payload)
    }

    return true
  };

  const onLoadImgs = async () => {
    setIsLoading(true);
    const curPhotos = await loadPhotos(files);
    onLoadPhotos(curPhotos);
    setFiles([]);
    setIsLoading(false);
  };

  const thumbs = files.map((file) => (
    <div className={styles.thumb} key={file.name}>
      <div className={styles.thumbInner}>
        <img src={file.preview} className={styles.img} alt=""/>
      </div>
    </div>
  ));

  const shouldRenderWatermarkSwitcher = !isFromParser && realEstate?.applicationId && thumbs.length === 0;

  return (
    <div className={styles.gallery}>
      {(isOpen || alwaysOpen) && photos ? (
        <div className={styles.galleryOpened}>
          <GalleryGrid
            photos={photos}
            onDeletePhoto={onDeletePhoto}
            onSortPhotos={onSortPhotos}
            isFromParser={isFromParser}
            withWatermark={realEstate?.additionalProperties?.watermarkEnabled as boolean}
            isCreation={isCreation}
            isView={isView}
          >
            {!isFromParser && !isView && <DroppableZone files={files} setFiles={setFiles}/>}
          </GalleryGrid>
          {thumbs.length ? (
            <aside className={styles.newImages}>
              <div className={styles.thumbsContainer}>{thumbs}</div>
              {thumbs.length && (
                <div className={styles.buttonContainer}>
                  <Button onClick={onLoadImgs} isLoading={isLoading}>
                    Сохранить изображения
                  </Button>
                </div>
              )}
            </aside>
          ) : null}
          {shouldRenderWatermarkSwitcher && (
            <WatermarkSwitcher
              applicationId={realEstate.applicationId as string} // Typescript bug, it's definitely string
              switcherValue={realEstate?.additionalProperties?.watermarkEnabled as boolean}
              refetch={refetch}
            />
          )}
        </div>
      ) : (
        <div
          onMouseDown={galleryMouseDown}
          onMouseUp={galleryMouseUp}
          onMouseMove={galleryMouseMove}
          className={cn(styles.galleryClosed__scroll, {
            [ styles.grabbing ]: isGrabbing,
          })}
        >
          {photos?.map((photo) => (
            <img
              key={photo.photoOriginal}
              src={photo.photoOriginal}
              alt="img"
              className={styles.galleryClosed__img}
            />
          ))}
          {photos && photos.length === 0 &&
            <PhotosPlug/>
          }
          {photos && photos.length !== 0 &&
            <div className={cn(styles.plug, {
              [ styles.plugOne ]: isMobile
                ? (photos?.length && photos?.length < 2)
                : (photos?.length && photos?.length < 3)
            })} />
        }
        </div>
      )}
      {!alwaysOpen && photos && photos.length !== 0 && (
        <button
          onClick={() => setIsOpen((prev) => !prev)}
          type="button"
          className={cn(styles.toggle, {
            [ styles.toggleReverse ]: isOpen,
            [ styles.toggleNoPhoto ]: photos && photos.length === 0,
          })}
        >
          {
            isOpen ? "Свернуть " : "Все фото"
          }{" "}
          <Arrow/>
        </button>
      )}
    </div>
  );
};
