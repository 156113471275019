import { ReactNode, useState } from "react";
import cn from "classnames";
import { Underlay } from "@sdelka_crm/sdelka-crm-component-library";

import styles from "./index.module.scss";

type Props = {
	percent: number;
	label: string;
	icon?: ReactNode;
	children?: ReactNode;
	percentColor?: "blue" | "red" | "orange";
	// onIconClick?: () => void;
};

export const CardPercent = ({
	percent,
	label,
	icon,
	children,
	percentColor = "blue",
}: // onIconClick,
Props): JSX.Element => {
	const [showChildren] = useState(false);

	// const toggle = () => {
	//   if (children) {
	//     setShowChildren(!showChildren);
	//   }
	//
	//   if (onIconClick) {
	//     onIconClick();
	//   }
	// };

	return (
		<Underlay className={styles.root}>
			<div className={styles.wrapper}>
				<div className={cn(styles.percent, styles[percentColor])}>
					<span>
						{percent > 0 ? "+" : ""}
						{percent}
					</span>

					<span>%</span>
				</div>

				<div className={styles.label}>{label}</div>

				<div
					// onClick={toggle}
					className={cn(styles.icon, { [styles.iconClickable]: !!children })}
				>
					{icon}
				</div>
			</div>

			{showChildren && <div className={styles.children}>{children}</div>}
		</Underlay>
	);
};
