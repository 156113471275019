import { useMutation } from "react-query";

import { AgencyAgrementPayloadType, AgencyAgrementType, ChangeStageResponse, WhoIsClient } from "../../../../types";
import { api } from "../../index";
import { AxiosResponse } from "axios";

const DEFAULT_MN = "sdelka/pre-application/update-application-contacts/DEFAULT_MN";

type UsePostAgencyAgrement = {
	onSuccess?: (data: any) => void;
	data: any
	id: string
};

export const usePutAdditionalProperties = ({ data, id }: UsePostAgencyAgrement) => {

	
	const mutation = useMutation(DEFAULT_MN, {
			mutationFn: () => api.deals.putAdittioanlProperties({ id, data }),
	});

	return {
			mutateAsync: mutation.mutateAsync,
			mutate: mutation.mutate,
			data: mutation.data,
			isLoading: mutation.isLoading,
			isSuccess: mutation.isSuccess,
	};
};
