import { useEffect, useState } from "react";
import ReactCalendar, { Detail, ViewCallbackProperties } from "react-calendar";
import { UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { formatDate } from "@sdelka_crm/sdelka-crm-component-library";

import { addStartEnd, removeOldDates } from "../helpers";

import { ReactComponent as ArrowIcon } from "../../../../assets/icons/chevron.svg";
import "react-calendar/dist/Calendar.css";
import "./index.scss";

export type CalendarProps = {
	className?: string;
	withRange?: boolean;
	form?: {
		name: string | [string, string];
		register: UseFormRegister<any>;
		setValue: UseFormSetValue<any>;
		getValues: UseFormGetValues<any>;
		required?: boolean;
	};
	onDateChange?: (date: Date[] | Date) => void;
	onClickMonth?: (value: Date) => void;
	defaultDate?: Date[] | Date;
	callRerender?: boolean;
	outerInputDates?: Date[] | Date;
	disableDates?: {
		from?: Date;
		to?: Date;
	};
	view?: Detail;
	permanentView?: Detail;
};

const IMPORTANT_FIELD = "Обязательное поле";

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Calendar = ({
	className,
	withRange,
	form,
	onDateChange,
	onClickMonth,
	defaultDate,
	callRerender,
	outerInputDates,
	disableDates,
	view = "month",
	permanentView,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
CalendarProps): JSX.Element => {
	if (withRange && form && typeof form.name === "string") {
		throw Error("For range and form use array of names");
	}

	const getDateFromValue = (name: string) => {
		const gotValue = form?.getValues(name);

		return gotValue ? new Date(gotValue) : undefined;
	};

	const getDefault = (): [Date, Date] | Date | undefined => {
		if (!form) return undefined;

		if (!withRange) return getDateFromValue(form.name as string);

		const from = getDateFromValue(form.name[0]);
		const to = getDateFromValue(form.name[1]);

		if (from && to) {
			return [from, to];
		}

		return undefined;
	};

	const [inputValue, setInputValue] = useState<Date[] | Date | undefined>(defaultDate || getDefault());

	const handleClick = (value: Date[] | Date) => {
		if (!Array.isArray(value)) {
			setInputValue(new Date(value));
		}
		if (value && Array.isArray(value)) {
			setInputValue([new Date(value[0]), new Date(value[1])]);
		}
	};

	const handleClickDay = () => {
		if (withRange) {
			removeOldDates();
		}
	};

	const handleClickMonth = (value: Date) => {
		if (onClickMonth) {
			setInputValue(value);
			onClickMonth(value);
		}
	};

	useEffect(() => {
		if (withRange && inputValue) {
			addStartEnd(inputValue[0], inputValue[1]);
		}

		if (form && !withRange && typeof form.name === "string") {
			form.setValue(form.name, inputValue);
		}

		if (form && withRange && inputValue) {
			form.setValue(form.name[0], inputValue[0]);
			form.setValue(form.name[1], inputValue[1]);
		}

		if (onDateChange && inputValue) {
			onDateChange(inputValue);
		}
	}, [inputValue]);

	useEffect(() => {
		if ((form && withRange && inputValue) || (callRerender && inputValue)) {
			handleClick(inputValue);
		}
	}, []);

	useEffect(() => {
		if (outerInputDates) {
			setInputValue(outerInputDates);
		}
	}, [outerInputDates]);

	const onMonthChange = (info: ViewCallbackProperties) => {
		if (withRange && info.value && info.value[0] && info.value[1]) {
			addStartEnd(info.value[0], info.value[1]);
		}
	};

	return (
		<>
			{form ? (
				<>
					{withRange ? (
						<>
							<input
								{...form.register(form.name[0], {
									required: form.required ? IMPORTANT_FIELD : undefined,
								})}
								className="calendar-input"
							/>
							<input
								{...form.register(form.name[1], {
									required: form.required ? IMPORTANT_FIELD : undefined,
								})}
								className="calendar-input"
							/>
						</>
					) : (
						<input
							{...form.register(form.name as string, {
								required: form.required ? IMPORTANT_FIELD : undefined,
							})}
							className="calendar-input"
						/>
					)}
				</>
			) : null}

			<ReactCalendar
				className={className}
				locale="ru-RU"
				showNeighboringMonth={false}
				nextLabel={<ArrowIcon />}
				prevLabel={<ArrowIcon />}
				selectRange={withRange}
				onChange={handleClick}
				onClickDay={handleClickDay}
				onClickMonth={handleClickMonth}
				value={inputValue as any}
				formatMonthYear={(_, date) => formatDate(date, "month_year")}
				onActiveStartDateChange={onMonthChange}
				maxDate={disableDates?.from}
				minDate={disableDates?.to}
				defaultView={view}
				view={permanentView}
			/>
		</>
	);
};
