import { Dispatch, SetStateAction, useMemo } from "react";
import cn from "classnames";
import { Stage, ApplicationFilterPayload } from "../../../../../../../types";
import { Popover } from "../../../../../../UI";
import { ButtonsFuncReturnType } from "./types";
import { ReactComponent as AllIcon } from "../../../../../../../assets/icons/all-icon.svg";
import styles from "../index.module.scss";

export const requestsButtons = (
	curFilter: { stage?: string[] },
	setCurFilter: Dispatch<SetStateAction<ApplicationFilterPayload>>,
	filterClick: (val: any) => void,
	requestStages: Stage[]
): ButtonsFuncReturnType =>
	// eslint-disable-next-line react-hooks/rules-of-hooks
	useMemo(() => {
		const buttonList = [
			{
				classNames: cn(styles.button, styles.buttonAll, styles.numberButton, {
					[styles.allSelected]: !curFilter.stage || curFilter.stage.length === 0,
				}),
				onClick: () => setCurFilter({ ...curFilter, stage: [] }),
				child: (
					<Popover position="right" overElement={<>Все</>}>
						<AllIcon />
					</Popover>
				),
			},
		];

		requestStages.forEach((stage) =>
			buttonList.push({
				classNames: cn(styles.button, styles.numberButton),
				onClick: () => filterClick(stage.order),
				child: (
					<Popover
						position="right"
						classNames={{ over: styles.popoverOver }}
						overElement={
							<div className={styles.applicationOverContainer}>
								<div className={styles.stageCircle} style={{ background: stage.background }}>
									{stage.order}
								</div>

								<span>{`Этап ${stage.order}. ${stage.name}`}</span>
							</div>
						}
					>
						<div
							className={cn(styles.roundedNumberButton, {
								[styles.selectedNumber]: curFilter?.stage?.includes(stage?.order),
							})}
						>
							{stage.order}
						</div>
					</Popover>
				),
			})
		);

		return buttonList;
	}, [curFilter, requestStages]);
