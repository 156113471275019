import { useDispatch, useSelector } from "react-redux";
import { PersonalCard } from "../../../../../components/pages/PersonalCard";
import { openDrawer, openDrawerInDrawer } from "../../../../redux/modules/drawer/drawer.action";
import { useQueryParams } from "../../../../../utils/hooks";
import { selectCurrentAccount } from "../../../../redux/selectors";
import { FixedNavVerticalMenu } from "../../../../../components/UI";

type Return = {
	openProfileDrawer: (userId?: string) => void;
};

type Props = {
	refetch?: () => void;
	isMobile?: boolean
	id?: string;
	atDrawerInDrawer?: boolean
};

type UseOpenProfileCard = (props: Props) => Return;

export const useOpenProfileCard: UseOpenProfileCard = (
	{
		id,
		refetch ,
		isMobile,
		atDrawerInDrawer
	}) => {
	const dispatch = useDispatch();
	const {removeQueryParam} = useQueryParams<{
		userId: string;
	}>([{name: "userId"}]);

	const {currentAccount} = useSelector(selectCurrentAccount);
	const {id: currentUserId} = currentAccount || {};
	const isCurrentUser = currentUserId === id
	const titleView = isCurrentUser ? "Мой профиль" : "Сотрудник"

	const viewNav = {
		id: "view",
		label: titleView,
		changeTab: () => {
		},
		height: 162,
	}

	const yNavigation: Omit<FixedNavVerticalMenu, "id" | "changeTab">[] = [viewNav]

	const openProfileDrawer = (userId?: string) => {
		if (atDrawerInDrawer) {
			dispatch(openDrawerInDrawer(
				{
					children: 	<PersonalCard
						refetchAll={refetch}
						userId={userId}
					/>,
					width: isMobile ? 500 : 800,
					yNavigation: {
						navigation: yNavigation,
						initialTab: 0
					},
					onClose: () => {
						removeQueryParam("userId")
					},
				}))
		} else {
			dispatch(
				openDrawer({
					children: 	<PersonalCard
						refetchAll={refetch}
						userId={userId}
					/>,
					width: isMobile ? 500 : 800,
					yNavigation: {
						navigation: yNavigation,
						// initialTab: 0,
					},
					onClose: () => {
						removeQueryParam("userId")
					},
				})
			);
		}
	};

	return {
		openProfileDrawer,
	};
};
