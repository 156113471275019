import { ReactNode } from "react";
import cn from "classnames";
import { AdStatusHeaderFooter } from "./AdStatusHeaderFooter";
import styles from "./index.module.scss";

export type ObjectAdStatusHeaderProps = {
  adIsStarted?: boolean;
  date?: string;
  tariff?: string | null;
  status: ReactNode;
  placementsLeft?: string;
  statusClassName?: string;
  isError?: boolean;
  isPosted?: boolean;
};

export const RealEstateAdStatusHeader = (
  {
    placementsLeft = "",
    adIsStarted = false,
    date = "",
    statusClassName = "",
    tariff,
    status,
    isError,
    isPosted,
  }: ObjectAdStatusHeaderProps): JSX.Element => (
  <div className={styles.root}>
    <div
      className={cn(styles.textIcon, statusClassName, {
        [ styles.noAd ]: !adIsStarted,
      })}
    >
      {status}
    </div>
    {adIsStarted && <div className={styles.date}>{date}</div>}
    {!isError && !isPosted && (
      <AdStatusHeaderFooter placementsLeft={placementsLeft} adIsStarted={adIsStarted} tariff={tariff}/>
    )}
  </div>
);
