import styles from "./index.module.scss"
import { useLoadDashboardStatistic } from "../../../service/api/queries";
import { selectDevice, useSelect } from "../../../service/redux/selectors";
import { SidebarLayout } from "../../UI";
import { DashboardStatisticTasks, Reports, Table } from "./components";

export const DashboardStatistic = () => {

  const {isMobile} = useSelect(selectDevice)

  const {statistic} = useLoadDashboardStatistic()

  return (
    <SidebarLayout
      fullSize
      isMobile={isMobile}
      hat={!isMobile ? {
        element: <></>,
        elementHeight: 50,
      } : {element: <></>, elementHeight: 0}}
      withScrollBtn
    >
      <div className={styles.root}>
        {statistic &&
          <div>
            <DashboardStatisticTasks/>
            <Table/>
            <Reports/>
          </div>
        }
      </div>
    </SidebarLayout>
  )
}