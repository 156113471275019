import { useState } from "react";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { PinLeadTab, RadioButton } from "../../../index";
import { LeadInList, RelatedLeadPinType } from "../../../../../types";

import styles from "./index.module.scss";
import { useForm } from "react-hook-form";

type Option = {
	value: RelatedLeadPinType;
	label: string;
};

const radioList: Option[] = [
	{
		value: RelatedLeadPinType.AsNew,
		label: "Как новый",
	},
	{
		value: RelatedLeadPinType.AsDouble,
		label: "Как дублирующий",
	},
];

const defaultPinType = RelatedLeadPinType.AsNew;

type FormValues = {
	pinType: RelatedLeadPinType;
};

type Props = {
	hideThisIds?: string[];
	onPin: (lead: LeadInList, pinType: RelatedLeadPinType) => void;
};

export const LeadsModal = ({ hideThisIds, onPin }: Props): JSX.Element => {
	const [selectedLead, setSelectedLead] = useState<LeadInList | undefined>(undefined);

	const form = useForm<FormValues>({
		defaultValues: {
			pinType: defaultPinType,
		},
	});

	const handleOnPin = () => {
		if (!selectedLead) return;

		const pinType = form.getValues("pinType");
		onPin?.(selectedLead, pinType);
	};

	return (
		<>
			<div className={styles.pinHeader}>
				<h3>
					{"Прикрепить "}
					<span>к лиду</span>
				</h3>

				<RadioButton
					name="pinType"
					control={form.control}
					options={radioList}
					background="blue"
					className={styles.radio}
					size="medium"
				/>
			</div>

			<div className={styles.listContainer}>
				<PinLeadTab
					hideThisIds={hideThisIds}
					selectedElement={selectedLead}
					setSelectedElement={setSelectedLead}
				/>
			</div>

			<div className={styles.buttonContainer}>
				<Button disabled={!selectedLead} onClick={handleOnPin}>
					Прикрепить
				</Button>
			</div>
		</>
	);
};
