import { LeadSourceEnum } from "../../../../types";

export const getSourceDescription = (source?: LeadSourceEnum) => {
  switch (source) {
    case LeadSourceEnum.coldCall:
      return "Лид из холодного звонка по объекту"
    case LeadSourceEnum.call:
      return "Лид из пропущенного звонка от клиента"
    case LeadSourceEnum.external:
      return "Лид с внешнего источника"
    case LeadSourceEnum.lead:
      return "Побочный лид из лида"
    case LeadSourceEnum.publicPage:
      return "Лид с публичной страницы"
    case LeadSourceEnum.show:
      return "Лид из показа"
    case LeadSourceEnum.view:
      return "Лид из просмотра"
    case LeadSourceEnum.archivedEstateObject:
      return "Лид из архивного объекта"
    default:
      return ""
  }
}