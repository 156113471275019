import { RealEstate, RealEstateFilter } from "../../../../../../types";
import { useDispatch } from "react-redux";
import { ObjectsMap } from "../index";
import { useEffect } from "react";
import { addTabInDrawer } from "../../../../../../service/redux/modules/drawer/drawer.action";

type Props = {
  realEstatesList: RealEstate[];
  filter: RealEstateFilter;
  setFilter: (value) => void
  isParser?: boolean;
  isMobile?: boolean
};

export const ObjectsMapWrapper = (
  {
    realEstatesList,
    filter,
    setFilter,
    isParser,
    isMobile
  }: Props) => {
  const dispatch = useDispatch()
  
  const mapNav = {
    id: "map",
    label: "Карта",
    changeTab: () => {
    },
    height: 100,
  }
  
  const mapChildren = (
    <ObjectsMap
      filter={filter}
      setFilter={setFilter}
      isParser={isParser}
      isMobile={isMobile}
    />
  )
  
  useEffect(() => {
    if (realEstatesList) {
      dispatch(addTabInDrawer({
        newChildren: mapChildren,
        newNavigation: mapNav
      }))
    }
  }, [realEstatesList])

  return (
    <></>
  )
}