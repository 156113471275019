import { useState } from "react";
import { useQuery } from "react-query";
import { api } from "../../api";
import { DefaultTasksFiltersType } from "../../../../types";

const DEFAULT_QN = "sdelka/tasks/load-tasks-count-for-left-menu/DEFAULT_QN";

type UseTasksCount = {
  key?: string;
  enabled?: boolean;
  defaultFilter: DefaultTasksFiltersType;
};

export const useTasksCountForLeftMenu = (
  {
    key = DEFAULT_QN,
    enabled = true,
    defaultFilter,
  }: UseTasksCount) => {
  const [filter, setFilter] = useState(defaultFilter);

  const loadTasksCount = () => api.tasks.countForLeftMenu();

  const {data, refetch, remove, isLoading, isFetched, isFetching} = useQuery(
    [key, filter],
    loadTasksCount,
    {
      enabled,
      keepPreviousData: true,
    }
  );

  return {
    data: data?.data,
    isLoading,
    refetch,
    remove,
    filter,
    setFilter,
    isFetched,
    isFetching,
  };
};
