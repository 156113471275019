import { ApplicationsFilter, Conditions } from "../../../../types";

export const DEFAULT_TRAFFIC = {
	addressObject: {
		label: "",
		value: "",
	},
	cadastralCode: "",
	creatorId: "",
	dateCreatingFrom: "",
	dateCreatingTo: "",
	flatNumber: "",
	fullTextSearch: "",
	modifiedAt: "",
	category: [],
	ownerId: "",
	ownerPhone: "",
	priceFrom: "",
	priceMFrom: "",
	priceMTo: "",
	priceTo: "",
	responsibleUsers: [],
	signed: false,
	adSource: [],
	leadSource: [],
	stage: [],
	status: [],
	subways: [],
	typeId: [],
	withTasks: false,
	kind: [],
	agencyAgreementForm: [],
	ropeTemplate: []
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const getRequestFilters = (search: ApplicationsFilter): Conditions => {
	const searchConditions: Conditions = [];

	if (search?.typeId) {
		searchConditions.push({
			field: "typeId",
			operator: "in",
			value: search.typeId,
		});
	}

	if (search?.priceFrom) {
		searchConditions.push({
			field: "price",
			operator: "gte",
			value: search.priceFrom,
		});
	}

	if (search?.priceTo) {
		searchConditions.push({
			field: "price",
			operator: "lte",
			value: search.priceTo,
		});
	}

	if (search?.priceMFrom) {
		searchConditions.push({
			field: "pricePerUnit",
			operator: "gte",
			value: search.priceMFrom,
		});
	}

	if (search?.priceMTo) {
		searchConditions.push({
			field: "pricePerUnit",
			operator: "lte",
			value: search.priceMTo,
		});
	}

	if (search?.priceFrom) {
		searchConditions.push({
			field: "typeId",
			operator: "in",
			value: search.typeId,
		});
	}

	if (search?.status) {
		searchConditions.push({
			field: "status",
			operator: "in",
			value: search.status, // status
		});
	}

	if (search?.stage) {
		searchConditions.push({
			field: "stageNo",
			operator: "in",
			value: search.stage,
		});
	}

	if (search?.fullTextSearch) {
		searchConditions.push({
			field: "fullTextSearch",
			operator: "like",
			value: search.fullTextSearch,
		});
	}

	if (search?.signed) {
		searchConditions.push({
			field: "signed",
			operator: "eq",
			value: search.signed,
		});
	}

	if (search?.withTaskForToday) {
		searchConditions.push({
			field: "withTaskForToday",
			operator: "eq",
			value: search.withTaskForToday,
		});
	}

	if (search?.agencyAgreementForm) {
		searchConditions.push({
			field: "agencyAgreementForm",
			operator: "eq",
			value: search.agencyAgreementForm,
		});
	}

	if (search?.responsibleUsers) {
		const responsibleUsersId = search.responsibleUsers
			?.filter((user) => user.layer === 2)
			.map((user) => user.value);

		searchConditions.push({
			field: "responsibleUsers.id",
			operator: "in",
			value: responsibleUsersId,
		});
	}

	if (search?.creatorId) {
		searchConditions.push({
			field: "creatorId",
			operator: "eq",
			value: search.creatorId,
		});
	}

	if (search?.ropeTemplate) {
		searchConditions.push({
			field: "ropeTemplate",
			operator: "eq",
			value: search.ropeTemplate,
		});
	}

	if (search?.adSource) {
		searchConditions.push({
			field: "adSource",
			operator: "in",
			value: search.adSource,
		});
	}
	if (search?.leadSource) {
		searchConditions.push({
			field: "leadSource",
			operator: "in",
			value: search.leadSource,
		});
	}

	if (search?.withTasks) {
		searchConditions.push({
			field: "withTasks",
			operator: "eq",
			value: search.withTasks,
		});
	}

	if (search?.dateCreatingFrom) {
		searchConditions.push({
			field: "createdAt",
			operator: "gte",
			value: search.dateCreatingFrom,
		});
	}

	if (search?.dateCreatingFrom) {
		searchConditions.push({
			field: "createdAt",
			operator: "lte",
			value: search?.dateCreatingFrom,
		});
	}

	if (search?.modifiedAt) {
		searchConditions.push({
			field: "modifiedAt",
			operator: "gte",
			value: search?.modifiedAt,
		});
	}

	if (search?.category) {
		searchConditions.push({
			field: "clientObject.category",
			operator: "in",
			value: search?.category,
		});
	}

	if (search?.ownerPhone) {
		searchConditions.push({
			field: "phone",
			operator: "like",
			value: search.ownerPhone,
		});
	}

	if (search?.kind) {
		searchConditions.push({
			field: "kind",
			operator: "eq",
			value: search.kind,
		});
	}

	if (search?.ownerId) {
		searchConditions.push({
			field: "ownerId",
			operator: "eq",
			value: search?.ownerId,
		});
	}

	if (search.whoIsClient) {
		searchConditions.push({
			field: "whoIsClient",
			operator: "eq",
			value: search.whoIsClient
		})
	}

	if (search?.addressObject?.value) {
		searchConditions.push({
			field: "address",
			operator: "addressWithChild",
			value: search?.addressObject?.value,
		});
	}

	if (search?.flatNumber) {
		searchConditions.push({
			field: "clientObject.additionalPreperties",
			operator: "jsoneq",
			value: ["flatNumber", search.flatNumber],
		});
	}

	if (search?.subways) {
		const subways = search.subways?.filter((subway) => subway.layer === 1).map((subway) => subway.value);

		searchConditions.push({
			field: "clientObject.subways",
			operator: "in",
			value: subways,
		});
	}

	if (search?.cadastralCode) {
		searchConditions.push({
			field: "clientObject.additionalPreperties",
			operator: "jsoneq",
			value: ["cadastralNum", search?.cadastralCode],
		});
	}

	if (search?.requiresAttention) {
		searchConditions.push({
			field: "requiresAttention",
			operator: "eq",
			value: search?.requiresAttention,
		});
	}

	if (search?.contacts?.contactId) {
		searchConditions.push({
			field: "contacts.contactId",
			operator: "eq",
			value: search.contacts.contactId,
		});
	}

	if (search?.clientObjectId) {
		searchConditions.push({
			field: "clientObjectId",
			operator: "eq",
			value: search.clientObjectId,
		});
	}

	if (search?.myApplicationsForView) {
		searchConditions.push({
			field: "myApplicationsForView",
			operator: "eq",
			value: true,
		});
	}

	if (search?.parentApplicationId) {
		searchConditions.push({
			field: "parentApplicationId",
			operator: "eq",
			value: search.parentApplicationId
		})
	}

	if (search?.viewForObjectApplicationId) {
		searchConditions.push({
			field: "viewForObjectApplicationId",
			operator: "eq",
			value: search.viewForObjectApplicationId
		})
	}

	return searchConditions;
};
