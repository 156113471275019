import { useDispatch } from "react-redux";
import { openModal } from "../../../../../../../../../../../../../../service/redux/modules/modal/modal.action";
import { OrderCreatedModal } from "./OrderCreatedModal";
import styles from "../apply-service-modal/index.module.scss";

type Props = {
  applicationId: string
  isMobile?: boolean
}

type Return = {
  openOrderCreatedModal: (orderId: string) => void;
};

export const useOpenOrderCreatedModal = (
  {
    applicationId,
    isMobile
  }: Props): Return => {
  const dispatch = useDispatch();

  const openOrderCreatedModal = (orderId: string) => {
    dispatch(openModal({
      body: <OrderCreatedModal applicationId={applicationId} orderId={orderId}/>,
      width: isMobile? 500 : 660,
      classNames: {
        contentWrapper: isMobile ? styles.contentWrapperClassNameMobile : "",
        buttonClose: isMobile ? styles.modalClose : ""
      }
    }))
  }

  return {
    openOrderCreatedModal
  }
}