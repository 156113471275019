import { LeadInList } from "../../../../../../../types";
import styles from "./index.module.scss"
import { CardLead } from "../../../../../../UI/Card/card-lead/CardLead";
import { Plug } from "../../../../../../UI/ApplicationComponents/helpers";


type Props = {
  activeLead?: LeadInList
  isMobile?: boolean
}

export const ActiveLead = (
  {
    activeLead,
    isMobile
  }: Props) => (
    <div className={styles.root}>
      <div className={styles.title}>
        Активный лид
      </div>
      {activeLead
        ? <CardLead
          lead={activeLead}
          mini={isMobile}
          background="white"
        />
        : <Plug
          plugText="Активного лида нет"
          mini={isMobile}
          classNames={styles.plug}
        />
      }
    </div>
  )