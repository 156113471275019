import { RealEstateRequiredAdFields, User } from "../../../../types";

export const SET_SHOW_REQUIRED_AD_FIELDS = "sdelka/object-edit/SET_SHOW_REQUIRED_AD_FIELDS";
export const SET_ROOMS_AMOUNT = "sdelka/object-edit/SET_ROOMS_AMOUNT";
export const CLEAN_UP = "sdelka/object-edit/CLEAN_UP";
export const SET_RESPONSIBLES = "sdelka/object-edit/SET_RESPONSIBLES";
export const SET_ADDRESS = "sdelka/object-edit/SET_ADDRESS";
export const SET_NO_HOME_NUMBER = "sdelka/object-edit/SET_NO_HOME_NUMBER";
export const SET_ALLOW_BY_MAP = "sdelka/object-edit/SET_ALLOW_BY_MAP";


export type Coord = {
  lat: number | typeof NaN;
  lon: number | typeof NaN;
};

type SetAddress = {
  coord: Coord;
  dontMarkOnMap?: boolean;
  addressId?: string;
  addressLabel?: string;
};

export type ObjectEditAction =
  | {
  type: typeof SET_SHOW_REQUIRED_AD_FIELDS;
  fields: RealEstateRequiredAdFields;
}
  | { type: typeof SET_ROOMS_AMOUNT; amount: number | string }
  | { type: typeof CLEAN_UP }
  | { type: typeof SET_RESPONSIBLES; users: User[] }
  | { type: typeof SET_ADDRESS; address: SetAddress }
  | { type: typeof SET_NO_HOME_NUMBER; noHomeNumber: boolean }
  | { type: typeof SET_ALLOW_BY_MAP; allowChangeByMap: boolean }


export const setShowRequiredAdFields = (fields: RealEstateRequiredAdFields): ObjectEditAction => ({
  type: SET_SHOW_REQUIRED_AD_FIELDS,
  fields,
});

export const setRoomsAmount = (amount: number | string): ObjectEditAction => ({
  type: SET_ROOMS_AMOUNT,
  amount,
});

export const cleanUp = (): ObjectEditAction => ({
  type: CLEAN_UP,
});

export const setResponsibles = (users: User[]): ObjectEditAction => ({
  type: SET_RESPONSIBLES,
  users,
});

export const setAddress = (address: SetAddress): ObjectEditAction => ({
  type: SET_ADDRESS,
  address,
});

export const setNoHomeNumber = (noHomeNumber: boolean): ObjectEditAction => ({
  type: SET_NO_HOME_NUMBER,
  noHomeNumber,
});

export const setAllowChangeByMap = (allowChangeByMap: boolean): ObjectEditAction => ({
  type: SET_ALLOW_BY_MAP,
  allowChangeByMap
})
