import type {
  AdStatusEnum,
  Application,
  ApplicationBusinessProcessType,
  ApplicationTitle,
  Contact,
  ContactPreview,
  LeadInList,
  LocationType,
  RealEstateTemplates,
  User,
  UserPreview,
  WhoIsClient,
} from "../index";
import { ActualEnum, AdStatusesEnumV2, AgencyAgreementStatusEnum, FeedAd, KindType } from "../index";

export type Catalog = "база собственников" | "база агентств" | "база моего агентства";

export type RealEstateUnit = "м2" | "сотка" | "гектар";

export type SourceLink = {
  id?: string;
  url: string;
  profileUrl?: string;
  site?: ParserSiteEnum
  phone?: string
};


export enum CategoryEnum {
  "Квартира" = "Квартира",
  "Комната" = "Комната",
  "Дом" = "Дом",
  "Участок" = "Участок",
  "Земля" = "Земля",
  "Гараж" = "Гараж",
  "Офис" = "Офис",
  "Торговая площадка" = "Торговая площадка",
  "Склад" = "Склад",
  "Свободное назначение" = "Свободное назначение",
  "Здание" = "Здание",
}

export enum ParserSiteEnum {
  avito = 'avito',
  cian = 'cian',
  domclick = 'domclick',
  yandex = 'yandex',
  youla = 'youla',
  other = 'other'
}

export type RealEstateIcons = {
  signedByOtherAgency?: boolean
  hasRejectedLead?: boolean
  hasActiveLead?: boolean
  hasFreezeLead?: boolean
  signedByMyAgency?: {
    form: AgencyAgreementStatusEnum
    type: 'ADVERTISING' | 'EX' | "FIND" | "LOYAL";
    validUntil?: Date
  }

  hasNotifications?: boolean
}


export type RealEstateAdditionalProperties = Record<string, string | number | boolean>;

// Object deal type
export type RealEstateDealType = "Аренда" | "Продажа";

export type PriceFluctuations = {
  price: number;
  date: Date;
};

export type RealEstateParserData = {
  ownerName: string;
  agencyName?: string;
};

export type RealEstateParserPhones = {
  phone: string;
  name: string;
  isOriginal: boolean;
};

export type MetroData = {
  name: string;
  color: string;
};

export type RealEstatePermissions = {
  edit: boolean;
  copy: boolean;
  compare: boolean;
  moveToArchive: boolean;
};

export enum CurrencyEnum {
  "rub" = "rub",
  "usd" = "usd",
  "eur" = "eur",
}

export type RealEstateAdStatistic = {
  leads: number;
  totalSum: number;
  views: number;
};

export type FeedAdsAdCabinet = {
  status: AdStatusEnum;
  feedId: string;
  feedName: string;
  phoneShows: number;
  totalViews: number;
};

export type ApplicationInRealEstateList = Application & {
  stage: {
    background: string;
    name: string;
    stageNo: number;
  };
  whoIsClient: WhoIsClient;
};

export type RealEstateCopiedInfo = {
  /** @format date-time */
  copiedAt: string;
  user: User;
};

export type ActiveApplications = {
  rentOut?: ApplicationTitle;
  sale?: ApplicationTitle;
};

export type RealEstateTitle = {
  /**
   * Заголовок объекта, который теперь генерируется на беке
   * @example "Продажа, 5-комн. 1231 м² 27/88 эт."
   */
  title: string;

  /**
   * Надпись к адресу, отдается модулем адресации, пример: Россия, Санкт-Петербург, улица Оптиков, 45к2
   * @example "Санкт-Петербург, улица Оптиков, 45к2"
   */
  addressLabel: string;
};

export type RealEstate = RealEstateTitle & {
  applicationId: string;
  preApplicationId: string;
  activeApplications: ActiveApplications;
  applications: ApplicationInRealEstateList[];
  actual: string;
  adTitle: string;
  adDescription?: string;
  adDescriptionSignatureId: string;
  adVideoLink?: string;
  catalog: Catalog;
  id: string;
  fullSquare: string | null;
  floor: number | null;
  floors: number | null;
  objectDescription: string;
  price: string;
  pricePerUnit: string;
  createdAt: string;
  modifiedAt: string;
  saleSquare: string | null;
  unit: RealEstateUnit;
  rooms: string;
  statistic?: RealEstateAdStatistic;
  photos: RealEstatePhoto[];
  additionalProperties?: RealEstateAdditionalProperties;
  source?: string;
  sourceLinks?: SourceLink[];
  addressLabel?: string;
  addressId?: string;
  dealType?: RealEstateDealType;
  feedAds?: FeedAd[];
  location?: LocationType;
  category: CategoryEnum;
  shortId?: string;
  responsibleUser: User;
  middlePrice?: number;
  priceFluctuations?: PriceFluctuations[];
  permissions: RealEstatePermissions;
  currency?: CurrencyEnum;
  needsCallCenterCheck?: boolean;
  callCenterComment?: string;
  otherFeedsCount?: number;
  otherFeedsStatus?: AdStatusEnum;
  // data from parser
  ownerComment?: string;
  parserPhones?: RealEstateParserPhones[];
  parserData?: RealEstateParserData;
  metroData?: MetroData[];
  copiedObjectsInfo?: RealEstateCopiedInfo[];
  modifiedAtList: string[];
  type: ApplicationBusinessProcessType;
  // contacts ids
  sellerContacts: string[];
  shows?: number;
  icons?: RealEstateIcons;
  description?: string
  hasPreApplication?: boolean
  stage?: string
  stageNo?: number
  isAgency?: boolean
  contact?: ContactPreview
  fullContact?: Contact
  shortType: {
    typeName: string
    whoIsClient: 'seller' | 'buyer'
    kind: KindType
  }
  iAmResponsibleUser: boolean
  priceWithDiscount?: string
  point: number[]
  status?: AdStatusesEnumV2
  isArchived?: boolean
  lastActiveCommunicationId?: string
  commission?: string
  hasActiveLead?: boolean
  activeLead?: LeadInList
};

export enum ObjectCollectionLikeStatusEnum {
  'notRated' = 'notRated',
  'likeALot' = 'likeALot',
  'like' = 'like',
  'didNotLike' = 'didNotLike',
}

export enum ObjectViewStatusEnum {
  'notFilled' = 'notFilled',
  'wantToSee' = 'wantToSee',
  'viewAssigned' = 'viewAssigned',
}

export type CollectionItem = {
  clientComment: string;
  collectionStatus?: ObjectCollectionStatusEnum
}

export enum ObjectCollectionStatusEnum {
  'likeALot' = 'likeALot', // Очень понравились
  'like' = 'like', // Понравились
  'didNotLike' = 'didNotLike', // Не понравилось
  'wantToSee' = 'wantToSee', // Хочу посмотреть
  'viewAssigned' = 'viewAssigned', // Просмотр назначен
}

type WithAds = {
  adText: string
  statistic?: RealEstateAdStatistic;
  feedAds?: FeedAdsAdCabinet[];
  otherFeedsCount?: number;
  otherFeedsStatus?: AdStatusEnum;
}

export type ClientObjectInList = {
  id: string;
  applicationId: string;
  image?: string;
  price: number;
  pricePerUnit: number;
  currency: CurrencyEnum;
  title: string;
  addressLabel: string;
  point?: number[];
  contact?: ContactPreview
  responsibleUser?: UserPreview
  iAmResponsibleUser: boolean
  shortType: {
    typeName: string
    whoIsClient: 'seller' | 'buyer'
    kind: 'preApplication' | 'application' | 'deal'
  }
}

export type ParserObjectInList = RealEstate & {
  success?: boolean
  id: string
  shortId: string
  image: string
  photos: RealEstatePhoto[]
  price: number
  pricePerUnit: number
  currency?: CurrencyEnum
  catalog?: Catalog
  priceDynamics?: PriceFluctuations[]
  subways?: Subway[]
  point?: number[]
  statistic?: RealEstateAdStatistic[]
  feedAds?: FeedAdsAdCabinet[]
  modifiedAtList?: Date[]
  actual: ActualEnum
  createdAt: Date
  title: string
  addressLabel: string
  parserData: RealEstateParserData
  type: ApplicationBusinessProcessType
  sourceLinks: SourceLink[];
  icons: RealEstateIcons
  description?: string;
  hasPreApplication: boolean
  contact?: ContactPreview
  responsibleUser?: UserPreview
  iAmResponsibleUser: boolean
}

export type RealEstatePhoto = {
  photoOriginal: string;
  photo1280: string;
  photo1280Watermark: string;
  photo96x96: string;
  order: number;
  description: string;
  id: string;
  width: number;
  height: number;
};

// All object filters available fields type
type RealEstateFilterTypes =
  | "metroFilter"
  | "numberRange"
  | "text"
  | "select"
  | "multiSelect"
  | "groupedSelectWithPreview"
  | "number"
  | "materialMultiple"
  | "customWordsFilter"
  | "phoneFilter"
  | "creator"
  | "responsible"
  | "cadastralNum"
  | "floors"
  | "boolean"
  | "publishDateFilter"
  | "booleanSearch"
  | "subTitle"
  | "communications"
  | "homeBathRoom"
  | "readonly"
  | "objectTypeSelect"
  | "commission"
  | "time"
  | "dealTypeSelect";

export type RealEstateFilterFieldDataType = {
  name: string;
  nameGroup: string[];
  type: RealEstateFilterTypes;
  isReadonlyText?: boolean;
  isMultiSelect?: boolean;
  allowedValues?: any;
  template?: RealEstateTemplates;
  isPhoneFilter?: boolean;
  isCustomWordsFilter?: boolean;
  isPublishDateFilter?: boolean;
  isText?: boolean;
  isBooleanSearch?: boolean;
  isSelect?: boolean;
  isNumberRange?: boolean;
  isNumber?: boolean;
  splitter?: string;
  maxSymbols?: number;
  postfix?: string;
  isFloat?: boolean;
  isMaterialMultiple?: boolean;
  isGroupedSelectWithPreview?: boolean;
  isFloors?: boolean;
  isMetroFilter?: boolean;
  defaultValue: string | string[];
  unit?: {
    k: string;
    name: string;
  }[];
  mask?: "YEAR";
};

// Object filters config field
export type RealEstateFilterField = {
  help: {
    text: string;
    example?: string;
  };
  label: string;
  dataType: RealEstateFilterFieldDataType;
  generalName: string;
  placeholder?: string;
};

// Object filters config block
export type RealEstateFilterBlock = {
  blockName: string;
  blockTitle?: string;
  fields: RealEstateFilterField[];
  isTitle?: boolean;
};

// Object filters config
export type RealEstateFiltersStructure = {
  success: boolean;
  configName: string;
  filters: RealEstateFilterBlock[];
};

export type RealEstateDescriptionFormValues = {
  id: string;
  adDescription: string;
  adTitle: string;
  adDescriptionSignatureId: string;
  additionalProperties?: {
    videoUrl: string;
  };
};

export type Subway = {
  name: string;
  color: string;
  stations: string[];
};

export type RealEstateSignature = {
  id: string;
  label: string;
  text: string;
  companyId: string;
  userId: string;
};
