import { useDispatch } from "react-redux";

import { useDeleteLeadTarget } from "../../../service/api/mutations";
import { closeModal, openModal } from "../../../service/redux/modules/modal/modal.action";
import { closeDrawerInDrawer } from "../../../service/redux/modules/drawer/drawer.action";
import { toastError, toastSuccess } from "../../../components/UI";

type Props = {
	leadId: string;
	refetch: () => void;
};

type Return = {
	handleOpenModal: () => void;
};

type UseUnpinGoal = (props: Props) => Return;

export const useUnpinGoal: UseUnpinGoal = ({ leadId, refetch }) => {
	const dispatch = useDispatch();

	const onError = () => {
		const ERROR_TEXT = "Не удалось удалить цель лида";
		toastError({ text: ERROR_TEXT });
	};

	const onSuccess = () => {
		const SUCCESS_TEXT = "Цель лида откреплена";
		toastSuccess({ text: SUCCESS_TEXT });

		refetch();
	};

	const { mutate } = useDeleteLeadTarget({ leadId, onError, onSuccess });

	const handleUnpin = async () => {
		mutate();
		dispatch(closeModal());
		dispatch(closeDrawerInDrawer());
	};

	const handleOpenModal = () => {
		dispatch(
			openModal({
				title: "Вы точно хотите открепить цель лида?",
				body: "",
				variant: "alert",
				alertIcon: "warning",
				alertButtonSubmit: {
					label: "Открепить",
					onClick: () => handleUnpin(),
				},
				alertButtonDecline: {
					label: "Отменить",
					onClick: () => dispatch(closeModal()),
				},
			})
		);
	};

	return { handleOpenModal };
};
