import { ComponentType, ReactNode, SVGProps } from "react";
import cn from "classnames";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import styles from "./index.module.scss";

type Props = {
	children?: ReactNode;
	className?: string;
	Icon?: ComponentType<SVGProps<SVGSVGElement>>;
	onClick: () => void;
};

export const BigButton = ({ onClick, children, className = "", Icon }: Props): JSX.Element => (
	<Button
		onClick={onClick}
		Icon={Icon}
		classNames={{ root: cn(styles.root, className) }}
	>
		{children}
	</Button>
);
