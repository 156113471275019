import cn from "classnames";
import { ReactComponent as UserIcon } from "../../../../assets/icons/user-icon.svg";
import { ReactComponent as Logo } from "../../../../assets/icons/logoMainMini.svg";
import styles from "./index.module.scss";

type Props = {
  imgSrc?: string;
  name?: string;
  lastName?: string;
  isLoading?: boolean;
  color?: "primary" | "secondary";
  classNames?: {
    root?: string;
    noImgBlock?: string;
    noInitialsIcon?: string;
  };
  isPremium?: boolean
  mini?: boolean
};

export const Avatar = (
  {
    imgSrc,
    name,
    lastName,
    classNames,
    isLoading,
    color = "primary",
    isPremium,
    mini
  }: Props): JSX.Element => {
  const initials = `${name?.slice(0, 1) || ""}${lastName?.slice(0, 1) || ""}`.toUpperCase();

  return (
    <div
      className={cn(styles.root, classNames?.root, {
        [ styles.loading ]: isLoading,
        [ styles.premium ]: isPremium,
        [ styles.mini ]: mini,
      })}
    >
      {imgSrc && !isLoading ? (
        <img src={imgSrc} alt="Avatar"/>
      ) : (
        <div
          className={cn(styles.noImg, classNames?.noImgBlock, styles[ `bg__${color}` ], {[ styles.initialOneLetter ]: initials.trim().length === 1})}>
          {initials || <UserIcon className={cn(styles.noInitialsIcon, classNames?.noInitialsIcon)}/>}
        </div>
      )}
      {isPremium && <Logo className={styles.logo}/>}
    </div>
  );
};
