import { createNumberMask } from "text-mask-addons";

type Options = {
	onlyPositive?: boolean;
	allowDecimal?: boolean;
};

const getDefaultNumberMask = (options: Options) =>
	createNumberMask({
		prefix: "",
		thousandsSeparatorSymbol: "",
		...options,
	});

const getOnlyPositiveNumberMask =
	(options: Options) =>
	(rawValue = "") => {
		const defaultNumberMask = getDefaultNumberMask(options);

		// Prevent typing leading zero as it is not positive.
		// Other logic is encapsulated in defaultNumberMask.
		return rawValue === "0" ? [""] : defaultNumberMask(rawValue);
	};

export const getMask = (options: Options) =>
	options.onlyPositive ? getOnlyPositiveNumberMask(options) : getDefaultNumberMask(options);
