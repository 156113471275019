import { Dispatch, SetStateAction } from "react";

import { BodyInfo, BodyLoading } from "./components";
import { ApplicationCardResponse, KindType, RealEstate } from "../../../../../../../../../../types";

type Props = {
	applicationId: string;
	application: ApplicationCardResponse;
	onTab?: (tab: number) => void;
	refetch: () => void;
	scrollY: number;
	unpinObject: () => void;
	addObject: (object: RealEstate) => void;
	setStageNum: Dispatch<SetStateAction<number>>;
	currentBlocks: any[];
	onNextStage: (stageNumber: number) => void;
	errorsLabel: string[];
	isFetching: boolean;
	isFetchingBlocks: boolean;
	onPinObject: (object: RealEstate) => void;
	kind?: KindType,
	isMobile?: boolean
};
export const Body = ({
	applicationId,
	application,
	onTab,
	refetch,
	scrollY,
	unpinObject,
	addObject,
	setStageNum,
	currentBlocks,
	onNextStage,
	errorsLabel,
	isFetching,
	isFetchingBlocks,
	onPinObject,
	kind,
	isMobile
}: Props): JSX.Element => (
	<>
		{isFetching ? (
			<BodyLoading />
		) : (
			<BodyInfo
				application={application}
				applicationId={applicationId}
				setStageNum={setStageNum}
				currentBlocks={currentBlocks}
				refetch={refetch}
				scrollY={scrollY}
				onNextStage={onNextStage}
				errorsLabel={errorsLabel}
				onTab={onTab}
				unpinObject={unpinObject}
				addObject={addObject}
				isFetchingBlocks={isFetchingBlocks}
				onPinObject={onPinObject}
				kind={kind}
				isMobile={isMobile}
			/>
		)}
	</>
);
