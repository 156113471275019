import { useRef } from "react";
import { H3 } from "@sdelka_crm/sdelka-crm-component-library";
import { Contacts, ObjectLayoutLoading, ObjectPhotoGallery } from "../../../../UI";
import {
  ActiveLead,
  AdDescription,
  BuildingInfo,
  CopiedBy,
  Footer,
  Header,
  MainInfo,
  Responsibles,
} from "./components";
import { useOfficialData } from "../../../../../service/api/queries";
import { RealEstateWithSchema } from "../../../../../types";
import styles from "./index.module.scss";
import { OwnerComment } from "./components/OwnerComment";
import { StickyFooter } from "./components/stickyFooter/StickyFooter";
import { CollectionList } from "./components/collection-list/CollectionList";
import { ObjectDoubles } from "./components/doubles/ObjectDoubles";
import { selectCurrentAccount, selectDevice, selectDrawer, useSelect } from "../../../../../service/redux/selectors";
import { CallCenterMenu } from "./components/StickyMenu/components";
import { useAddEditComponent, useChangeTab, useDeleteTab } from "../../../../../service/servise";
import { changeCurrentTab, reloadDrawer } from "../../../../../service/redux/modules/drawer/drawer.action";
import { useDispatch } from "react-redux";

type Props = {
  id: string;
  blocksIds?: Record<string, string>;
  realEstate?: RealEstateWithSchema;
  refetch: () => void;
  refetchList?: () => void
  inDrawerInDrawer?: boolean;
  enabled?: boolean
  isFromLead?: boolean
  isFromCompilation?: boolean
};

export const RealEstateView = (
  {
    id,
    blocksIds,
    realEstate,
    refetch,
    refetchList,
    inDrawerInDrawer,
    enabled,
    isFromLead,
    isFromCompilation
  }: Props): JSX.Element => {

  const {catalog, applicationId, shortType, parserData} = realEstate || {};
  const {yNavigation} = useSelect(selectDrawer)
  const {changeTab} = useChangeTab({atDrawerInDrawer: inDrawerInDrawer})
  const {currentAccount} = useSelect(selectCurrentAccount);
  const {officialData} = useOfficialData({id: realEstate?.addressId});
  const contentRef = useRef<HTMLDivElement>(null);
  const {isMobile} = useSelect(selectDevice)
  const isFromParser = catalog === "база агентств" || catalog === "база собственников";
  const idForDoubles = parserData ? realEstate?.id : realEstate?.applicationId

  const canViewCallCenterMenu = realEstate?.needsCallCenterCheck && isFromParser && currentAccount?.callCenterAccess;

  const {addEditComponent} = useAddEditComponent({atDrawerInDrawer: inDrawerInDrawer, kind: realEstate?.shortType?.kind})
  const {deleteTab} = useDeleteTab({atDrawerInDrawer: inDrawerInDrawer})

  const openEdit = () => {
    if (yNavigation && !yNavigation.navigation.find(el => el.title === "edit")) {
      addEditComponent({
        id,
        refetch,
        enabled
      })
    }
    if (yNavigation && yNavigation.navigation.find(el => el.title === "edit")) {
      changeTab({title: "edit"})
    }
  }

  if (!realEstate) {
    return <ObjectLayoutLoading/>;
  }

  const handleCallCenterAction = () => {
    if (refetchList) {
      refetchList()
    }
    deleteTab({index: 4})
  }

  return (
    <div className={styles.root}>
      <div id={blocksIds?.common}>
        <Header realEstate={realEstate}/>
      </div>
      <ObjectPhotoGallery
        realEstate={realEstate}
        refetch={refetch}
        isFromParser={isFromParser}
        photos={realEstate.photos}
        isView
      />
      {canViewCallCenterMenu && (
        <div className={styles.callCenterMenu}>
          <CallCenterMenu
            parserObjectId={realEstate.id}
            phone={realEstate.parserPhones && realEstate.parserPhones[ 0 ]?.phone}
            price={+realEstate.price}
            ownerComment={realEstate.ownerComment}
            callCenterComment={realEstate.callCenterComment}
            onSuccess={handleCallCenterAction}
          />
        </div>
      )}
      <div ref={contentRef} className={styles.content}>
        <div className={styles.contentContainer}>
          {isFromParser
            ? <></>
            // ? <Contact realEstate={realEstate}/>
            : <Contacts
              type={shortType?.whoIsClient ?? "seller"}
              applicationId={applicationId}
              label="Продавец"
              classNames={styles.contacts}
              mini={isMobile}
            />
          }
          <Responsibles responsibleUser={realEstate.responsibleUser} isMobile={isMobile}/>
          {isFromParser && <OwnerComment ownerComment={realEstate.ownerComment}/>}
          {isFromParser && <CopiedBy realEstate={realEstate}/>}
          {realEstate.adDescription && <AdDescription adDescription={realEstate.adDescription}/>}
          <div id={blocksIds?.info}>
            <MainInfo realEstate={realEstate}/>
          </div>
          <div>
            <CollectionList
              isFromParser={isFromParser}
              realEstate={realEstate}
              forAdded={false}
              mini={isMobile}
            />
          </div>
          <div>
            <CollectionList
              isFromParser={isFromParser}
              realEstate={realEstate}
              forAdded
              mini={isMobile}
              isFromCompilation={isFromCompilation}
            />
          </div>
          <div>
            <ObjectDoubles id={idForDoubles ?? ""} mini={isMobile} isFromParser={isFromParser}/>
          </div>
          <div id={blocksIds?.official} style={{marginTop: "60px"}}>
            <H3>Сведения с официальных ресурсов</H3>
          </div>
          <BuildingInfo officialData={officialData}/>
          {isFromParser &&
            <ActiveLead
              activeLead={realEstate.activeLead}
              isMobile={isMobile}
            />
          }
        </div>
        <Footer realEstate={realEstate}/>
        <StickyFooter
          realEstate={realEstate}
          realEstateRefetch={refetch}
          isFromParser={isFromParser}
          isMobile={isMobile}
          openEdit={openEdit}
          isFromLead={isFromLead}
          isFromCompilation={isFromCompilation}
        />
      </div>
    </div>
  );
};
