import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Tabs } from "@sdelka_crm/sdelka-crm-component-library";

import { defaultValues } from "../../../utils/clientObjects";
import { useQueryParams } from "../../../utils/hooks";
import { paths } from "../../../utils/paths";
import { RealEstateFilter, RealEstateListTab } from "../../../types";

import styles from "./index.module.scss";

enum TabTypeEnum {
	PARSED = 0,
	AGENCY = 1,
	// SDELKA = 2,
}

type QueryParams = {
	compilation: boolean;
	applicationId: string;
	filter: string;
	tab: RealEstateListTab;
	backPath: string;
	comparedApplicationId: string;
	compilationApplicationId: string;
};

type Props = {
	realEstateListFilters?: RealEstateFilter;
};

export const RealEstateTabs = ({ realEstateListFilters}: Props): JSX.Element => {
	const { push, location } = useHistory();
	const {
		queryParams: {
			tab,
			compilation,
			applicationId,
			backPath,
			comparedApplicationId,
			compilationApplicationId,
		},
	} = useQueryParams<QueryParams>([
		{ name: "compilation" },
		{ name: "applicationId" },
		{ name: "filter" },
		{ name: "tab" },
		{ name: "backPath" },
		{ name: "comparedApplicationId" },
		{ name: "compilationApplicationId" },
	]);

	const defaultTab =
		location.pathname === paths.advertisingPath.list ? 2 : TabTypeEnum[tab] || TabTypeEnum.PARSED;

	const [activeTab, setActiveTab] = useState(defaultTab);

	useEffect(() => {
		setActiveTab(defaultTab);
	}, [defaultTab]);

	const handleChangeTab = (tabNum: number) => {
		const compilationParam = compilation ? `compilation=${compilation}&` : "";
		const comparedApplicationIdParam = comparedApplicationId
			? `comparedApplicationId=${comparedApplicationId}&`
			: "";
		const compilationApplicationIdParam = compilationApplicationId
			? `compilationApplicationId=${compilationApplicationId}&`
			: "";
		const applicationIdParam = applicationId ? `applicationId=${applicationId}&` : "";
		const backPathParam = backPath ? `backPath=${encodeURIComponent(backPath)}&` : "";
		const filtersToSpread = realEstateListFilters || defaultValues;

		switch (tabNum) {
			case 0: {
				const url = encodeURIComponent(JSON.stringify(filtersToSpread));
				push(
					`${paths.objectsPath.list}?tab=PARSED&${compilationParam}${comparedApplicationIdParam}${compilationApplicationIdParam}${applicationIdParam}${backPathParam}filter=${url}`
				);
				break;
			}
			case 1: {
				const url = encodeURIComponent(JSON.stringify(filtersToSpread));
				push(
					`${paths.objectsPath.list}?tab=AGENCY&${compilationParam}${comparedApplicationIdParam}${compilationApplicationIdParam}${applicationIdParam}${backPathParam}filter=${url}`
				);
				break;
			}
			// case 2: {
			// 	const url = encodeURIComponent(JSON.stringify(filtersToSpread));
			// 	push(
			// 		`${paths.objectsPath.list}?tab=SDELKA&${compilationParam}${comparedApplicationIdParam}${compilationApplicationIdParam}${applicationIdParam}${backPathParam}filter=${url}`
			// 	);
			// 	break;
			// }
			case 2: {
				push(paths.advertisingPath.list);
				break;
			}
			default:
				break;
		}
		setActiveTab(tabNum);
	};

	const tabsItems = [
		<div style={{ width: 106 }}>Парсер</div>,
		<div style={{ width: 176  }}>Мои объекты</div>,
		// <div style={{ width: isMobile ? 80 : 176  }}>База Сделки</div>,
	];

	if (!compilation && !comparedApplicationId) {
		tabsItems.push(<div style={{ width: 108 }}>Реклама</div>);
	}

	return (
		<div className={styles.tabs}>
			<Tabs
				tabs={tabsItems}
				size="large"
				active={activeTab}
				onChange={handleChangeTab}
				classNames={{root: styles.root, wrapper: styles.tabsRoot}}
				offset={36}
			/>
		</div>
	);
};
