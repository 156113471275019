import { useState } from "react";
import { PointType } from "../../../../types";

type Return = {
  onSelectPoint: (point: PointType) => void;
  onUnselectPoint: () => void;
  selectedPoint: PointType | null;
};

type Props = {
  setPoint?: React.Dispatch<React.SetStateAction<boolean>>
}

type UseSelectPoint = ({setPoint}: Props) => Return;

export const useSelectPoint: UseSelectPoint = ({setPoint}) => {
  const [selectedPoint, setSelectedPoint] = useState<PointType | null>(null);

  const onSelectPoint = (point: PointType) => {
    setSelectedPoint(point);
    if (setPoint) {
      setPoint(true)
    }
  };

  const onUnselectPoint = () => {
    setSelectedPoint(null);
    if (setPoint) {
      setPoint(false)
    }
  };

  return {
    onSelectPoint,
    onUnselectPoint,
    selectedPoint,
  };
};
