import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo } from "react";

import { DEFAULT_TASKS_FILTERS } from "../../api/helpers/tasks";
import { useLoadTasks, useTasksCount } from "../../api/queries";
import { DefaultTasksFiltersType, TaskUserRole } from "../../../types";
import { useFilterQuery, useFilters, useRole, useTasks } from "./helpers";

type UseLoadTasksReturns = ReturnType<typeof useLoadTasks>;
type TasksQuery = Omit<UseLoadTasksReturns, "setFilter" | "setStage">;

type UseTasksCountReturns = ReturnType<typeof useTasksCount>;
type CountQuery = Omit<UseTasksCountReturns, "setFilter" | "setStage">;

type TasksContextType = {
  tasksQuery: TasksQuery;
  countQuery: CountQuery;
  setStage: (newStage: string[]) => void;
  resetForm: () => void;

  role: TaskUserRole;
  setRole: Dispatch<SetStateAction<TaskUserRole>>;

  filter: DefaultTasksFiltersType;
  setFilter: (newFilter: DefaultTasksFiltersType) => void;
  roleRelatedFilters: Array<keyof DefaultTasksFiltersType>;
};

const TasksContext = createContext<TasksContextType | null>(null);

type Props = {
  children: ReactNode;
  defaultTasksFilter?: DefaultTasksFiltersType;
};

export const TasksContextProvider = ({children, defaultTasksFilter = DEFAULT_TASKS_FILTERS}: Props) => {

  const roleState = useRole()
  const filters = useFilterQuery(defaultTasksFilter)
  const tasksState = useTasks({defaultTasksFilter: filters});
  const filtersState = useFilters({
    defaultTasksFilter: filters,
    role: roleState.role,
    submitFilter: tasksState.submitFilter,
  });

  const value: TasksContextType = useMemo(
    () => ({
      ...tasksState,
      ...roleState,
      ...filtersState,
    }),
    [tasksState, roleState, filtersState]
  );

  return <TasksContext.Provider value={value}>{children}</TasksContext.Provider>;
};

export const useTasksContext = (): TasksContextType => {
  const context = useContext(TasksContext);
  if (context === null) {
    throw new Error("Tasks context must be inside values");
  }

  return context;
};
