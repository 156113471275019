import { KindType, Lead, OpenApplicationInDrawer } from "../../../../../../../types";
import styles from "./index.module.scss";
import { CardApplicationMini } from "../../../../../../UI/Card/card-application/card-application-mini";
import { Plug } from "../plug/Plug";
import { useOpenLeadInDrawer, useQueryParams } from "../../../../../../../utils/hooks";
import { useCloseDrawer, useOpenApplication } from "../../../../../../../service/servise";

type Props = {
  lead: Lead;
  refetch: () => void;
  isMobile?: boolean
};

export const LeadApplications = ({lead, refetch, isMobile}: Props): JSX.Element => {
  const {childApplications} = lead;
  const {
    changeQueryParams,
    removeQueryParam
  } = useQueryParams<{ whoIsClient: string, preApplicationId: string }>([{name: "whoIsClient"}, {name: "preApplicationId"}]);
  const {openApplication} = useOpenApplication()
  const {openLeadInDrawer} = useOpenLeadInDrawer({refetch})
  const {close} = useCloseDrawer({atDrawerInDrawer: !isMobile})

  const openApplicationDrawerInDrawer = ({id, whoIsClient}: OpenApplicationInDrawer) => {
    if (isMobile) {
      changeQueryParams([{name: "whoIsClient", newValue: whoIsClient as string}])
      removeQueryParam("preApplicationId")
      close()
      setTimeout(() => {
        openApplication({
          applicationId: id,
          kind: KindType.APPLICATION,
          atDrawerInDrawer: false,
          whoIsClient,
          onClose: () => {
            openLeadInDrawer({
              id: lead.id
            })
          }
        })
      });
    } else {
      changeQueryParams([{name: "whoIsClient", newValue: whoIsClient as string}])
      removeQueryParam("preApplicationId")
      close()
      setTimeout(() => {
        openApplication({
          applicationId: id,
          kind: KindType.APPLICATION,
          atDrawerInDrawer: true,
          whoIsClient,
        })
      });
    }
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.title}>
          Заявки и сделки
        </div>
      </div>
      <div className={styles.card}>
        {childApplications && childApplications.length > 0
          ? childApplications?.map(
            (application) =>
              application && (
                <CardApplicationMini
                  key={application.id}
                  application={application}
                  onCardClick={() => openApplicationDrawerInDrawer({
                    id: application.id,
                    whoIsClient: application.type.whoIsClient
                  })}
                  options={{
                    canOpenANewTab: true,
                  }}
                  withoutStage
                />
              )
          )
          : <Plug plugText="Нет заявок"/>
        }
      </div>
    </>
  );
};

