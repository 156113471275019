import { useMutation } from "react-query";
import { AxiosResponse } from "axios";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/real-estate/import-object/DEFAULT_MN";

type UseImportObject = {
  onSuccess?: (res: AxiosResponse<{ applicationId: string }>) => void;
  onError?: (e: unknown) => void;
  url?: string
};

export const useImportObject = ({onSuccess, onError, url}: UseImportObject) => {
  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: () => api.realEstate.importObject(url),
    onSuccess,
    onError,
  });

  return {
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
  };
};
