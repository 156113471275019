import { useState } from "react";
import { useDispatch } from "react-redux";
import { Underlay, Button } from "@sdelka_crm/sdelka-crm-component-library";

import { PinObjectTab } from "../../../index";
import { closeModal, openModal } from "../../../../../service/redux/modules/modal/modal.action";
import { RealEstate } from "../../../../../types";

import styles from "./index.module.scss";

const PinObjectModal = ({ pinObject }: { pinObject: (object?: RealEstate) => void }) => {
	const [object, setObject] = useState<RealEstate | undefined>();

	return (
		<div className={styles.pinObject}>
			<Underlay>
				<PinObjectTab setSelectedElement={setObject} />
			</Underlay>

			<Button disabled={!object} onClick={() => pinObject(object)}>
				Прикрепить
			</Button>
		</div>
	);
};

export const usePinObjectModal = ({
	title,
	onChange,
	onClose,
}: {
	title: string;
	onChange: (object: RealEstate) => void;
	onClose?: () => void;
}) => {
	const dispatch = useDispatch();

	const pinObject = (object?: RealEstate) => {
		if (!object) return;

		onChange(object);
		dispatch(closeModal());
	};

	return {
		openPinObjectModal: () =>
			dispatch(
				openModal({
					title,
					body: <PinObjectModal pinObject={pinObject} />,
					onClose,
					width: 788,
				})
			),
	};
};
