import { useDispatch } from "react-redux";
import { Lead } from "../../../../../../../../types";
import { openModal } from "../../../../../../../../service/redux/modules/modal/modal.action";
import { RelatedLeadModal } from "./related-lead-modal/RelatedLeadModal";


type Props = {
  lead: Lead
  refetch: () => void;
}

type Return = {
  openAddRelatedLeadModal: () => void
}

// eslint-disable-next-line @typescript-eslint/naming-convention
type UseOpenRelatedLeadModal = (props: Props) => Return

export const useOpenRelatedLeadModal: UseOpenRelatedLeadModal = (
  {
    lead,
    refetch,
  }) => {

  const dispatch = useDispatch()

  const openAddRelatedLeadModal = () => {
    dispatch(
      openModal({
        width: 660,
        body:
          <RelatedLeadModal
            lead={lead}
            refetch={refetch}
          />,
      })
    )
  }
  return {
    openAddRelatedLeadModal
  }
}