import { RealEstateFilter } from "../../../../../../../../../../../types";

import { useQueryParams } from "../../../../../../../../../../../utils/hooks";
import { parseStringEncodedRealEstateFilter } from "../../../../../../../../../../../utils/url";

type QueryParams = {
	filter: string;
};

export const useFilterAsQueryParam = (): RealEstateFilter => {
	const {
		queryParams: { filter = "{}" },
	} = useQueryParams<QueryParams>([{ name: "filter" }]);

	return parseStringEncodedRealEstateFilter(filter);
};
