import { CallData } from "../../../../../../../../types";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../../../../../service/redux/modules/modal/modal.action";
import { AddCallModal } from "./AddCallModal";
import { selectDevice, useSelect } from "../../../../../../../../service/redux/selectors";
import styles from "../../../../../../ShowsAndViews/components/sticky-footer/create-show-view-modal/index.module.scss";

type Props = {
  onSelect: (callData?: CallData | null, callDataId?: string | null) => void
}

type Return = {
  openAddCallModal: () => void
}

export const useOpenAddCallModal = ({onSelect}: Props): Return => {
  const dispatch = useDispatch()
  const {isMobile} = useSelect(selectDevice)
  const width = isMobile ? 500 : 800

  const openAddCallModal = () => {
    dispatch(
      openModal({
        width,
        body: <AddCallModal onSelect={onSelect}/>,
        classNames:{
          // contentBody: styles.root,
          contentWrapper: isMobile ? styles.modal : styles.wrapper,
          buttonClose: isMobile ? styles.modalClose : ""
        },
      })
    )
  }
  return {
    openAddCallModal
  }
}