import { useDispatch } from "react-redux";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { TermsText } from "./components";
import { openModal } from "../../../../../service/redux/modules/modal/modal.action";

import styles from "./index.module.scss";

const ModalContentMock = () => <TermsText/>;

export const TermsBlock = (): JSX.Element => {
  const dispatch = useDispatch();

  const showOfferModal = () => {
    dispatch(
      openModal({
        title: "Публичная оферта",
        body: <ModalContentMock/>,
        width: 795,
      })
    );
  };

  return (
    <div className={styles.root}>
      {'Нажимая кнопку "Зарегистрироваться", вы соглашаетесь с '}
      <Button type="button" onClick={showOfferModal} variant="text" classNames={{root: styles.btn}}>
        публичной офертой
      </Button>
    </div>
  );
};
