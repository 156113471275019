import { ParserSiteEnum, SourceLink } from "../../../../../../types";
import { JSX, memo } from "react";
import styles from "./index.module.scss";
import LogoCian from "../../../../../../assets/icons/logoCian.svg";
import LogoAvito from "../../../../../../assets/icons/logoAvito.svg";
import LogoYoula from "../../../../../../assets/icons/logoYoula.svg";
import LogoDomClick from "../../../../../../assets/icons/logoDomClick.svg";
import LogoYandexNed from "../../../../../../assets/icons/logoYandexNed.svg";
import IconCian from "../../../../../../assets/icons/iconCian.svg";
import IconYandexNed from "../../../../../../assets/icons/iconYNed.svg";
import IconYoula from "../../../../../../assets/icons/iconYoula.svg";
import IconAvito from "../../../../../../assets/icons/iconAvito.svg";
import IconDomClick from "../../../../../../assets/icons/iconDomClick.svg";
import { useIsTelegram } from "../../../../../../utils/telegram/useIsTelegram";

export type Props = {
  sourceLinks?: SourceLink[]
  isIcon?: boolean
}

export const EstateBaseRow = memo(
  ({sourceLinks, isIcon}: Props): JSX.Element => {
    const otherNumber = sourceLinks?.filter(site => site.site === "other").length

    const {executeUrl} = useIsTelegram()

    const openLink = (link?: SourceLink) => {
      executeUrl(`${link?.url}`)
    }

    return (
      <div className={styles.iconBlock}>
        {sourceLinks?.find(link => link.site === ParserSiteEnum.cian) &&
          <div className={styles.svgIcon}>
            {isIcon
              ? <img src={IconCian} alt="SVG logo"
                     onClick={() => openLink(sourceLinks?.find(link => link.site === ParserSiteEnum.cian))}/>
              : <img src={LogoCian} alt="SVG logo"/>}
          </div>
        }
        {sourceLinks?.find(link => link.site === ParserSiteEnum.avito) &&
          <div className={styles.svgIcon}>
            {isIcon
              ? <img src={IconAvito} alt="SVG logo"
                     onClick={() => openLink(sourceLinks?.find(link => link.site === ParserSiteEnum.avito))}/>
              : <img src={LogoAvito} alt="SVG logo"/>}
          </div>
        }
        {sourceLinks?.find(link => link.site === ParserSiteEnum.youla) &&
          <div className={styles.svgIcon}>
            {isIcon
              ? <img src={IconYoula} alt="SVG logo"
                     onClick={() => openLink(sourceLinks?.find(link => link.site === ParserSiteEnum.youla))}/>
              : <img src={LogoYoula} alt="SVG logo"/>}
          </div>
        }
        {sourceLinks?.find(link => link.site === ParserSiteEnum.domclick) &&
          <div className={styles.svgIcon}>
            {isIcon
              ? <img src={IconDomClick} alt="SVG logo"
                     onClick={() => openLink(sourceLinks?.find(link => link.site === ParserSiteEnum.domclick))}/>
              : <img src={LogoDomClick} alt="SVG logo"/>}
          </div>
        }
        {sourceLinks?.find(link => link.site === ParserSiteEnum.yandex) &&
          <div className={styles.svgIcon}>
            {isIcon
              ? <img src={IconYandexNed} alt="SVG logo"
                     onClick={() => openLink(sourceLinks?.find(link => link.site === ParserSiteEnum.yandex))}/>
              : <img src={LogoYandexNed} alt="SVG logo"/>}
          </div>
        }
        {otherNumber !== 0 &&
          <div className={styles.other}>
                        <span>
                             {`+${otherNumber}`}
                        </span>
          </div>
        }
      </div>
    )
  }
)