import { Dispatch, SetStateAction } from "react";
import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import { createNumberMask } from "text-mask-addons";
import { RealEstateFilter, RealEstateListTab } from "../../../../../../../../../types";
import cn from "classnames";

import { DoubleInput, MultiSelect, Select } from "../../../../../../../../UI";

import styles from "../../index.module.scss";

type Props = {
  type: string[];
  control: Control<any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  setFilter: Dispatch<SetStateAction<RealEstateFilter>>;
  getValues: UseFormGetValues<any>;
  filter: RealEstateFilter;
  globalFilter: RealEstateFilter;
  squareOptions: { value: string; label: string }[];
  materialOptions: { value: string; label: string }[];
  tab: RealEstateListTab;
};

export const HouseCottageOptional = (
  {
    control,
    register,
    setValue,
    setFilter,
    getValues,
    filter,
    globalFilter,
    squareOptions,
    materialOptions,
    type,
    tab,
  }: Props): JSX.Element => {
  const [squareFrom, squareTo, squareAreaFrom, squareAreaTo] = [
    "squareFrom",
    "squareTo",
    "squareAreaFrom",
    "squareAreaTo",
  ];

  const [
    watcherSquareFrom,
    watcherSquareTo,
    watcherSquareValue,
    watcherSquareAreaFrom,
    watcherSquareAreaTo,
    watcherMaterial,
  ] = [
    useWatch({
      control,
      name: squareFrom,
      defaultValue: getValues(squareFrom) || "",
    }),
    useWatch({
      control,
      name: squareTo,
      defaultValue: getValues(squareTo) || "",
    }),
    useWatch({
      control,
      name: "squareValue",
      defaultValue: getValues("squareValue") || squareOptions[ 0 ].value,
    }),
    useWatch({
      control,
      name: squareAreaFrom,
      defaultValue: getValues(squareAreaFrom) || "",
    }),
    useWatch({
      control,
      name: squareAreaTo,
      defaultValue: getValues(squareAreaTo) || "",
    }),
    useWatch({
      control,
      name: "additionalProperties.material_doma",
      defaultValue: getValues("additionalProperties.material_doma") || [],
    }),
  ];

  const onChangeSquareFrom = (square: string) =>
    setFilter({
      ...globalFilter,
      ...filter,
      [ squareFrom ]: Number(String(square)?.replace(/\s/g, "")),
    });

  const onChangeSquareTo = (square: string) =>
    setFilter({
      ...globalFilter,
      ...filter,
      [ squareTo ]: Number(String(square)?.replace(/\s/g, "")),
    });

  const onChangeSquareAreaFrom = (square: string) =>
    setFilter({
      ...globalFilter,
      ...filter,
      [ squareAreaFrom ]: Number(String(square)?.replace(/\s/g, "")),
    });

  const onChangeSquareAreaTo = (square: string) =>
    setFilter({
      ...globalFilter,
      ...filter,
      [ squareAreaTo ]: Number(String(square)?.replace(/\s/g, "")),
    });

  const onChangeMaterial = (value: string[]) =>
    setFilter({
      ...globalFilter,
      ...filter,
      matrialDoma: value,
    });

  const haveHouses = type?.includes("Дом");

  return (
    <>
      {haveHouses && (
        <div className={cn(styles.itemWrapper, styles.squareFull)}>
          <span className={styles.inputTitleBig}>Пл. дома м2:</span>
          <div>
            <DoubleInput
              names={[squareFrom, squareTo]}
              register={register}
              onFirstInputChange={onChangeSquareFrom}
              onSecondInputChange={onChangeSquareTo}
              font="ubuntu"
              placeholders={["от", "до"]}
              textAlign="right"
              setValue={setValue}
              size="unFixed"
              masks={[
                createNumberMask({
                  prefix: "",
                  thousandsSeparatorSymbol: " ",
                }),
                createNumberMask({
                  prefix: "",
                  thousandsSeparatorSymbol: " ",
                }),
              ]}
              value={[watcherSquareFrom, watcherSquareTo]}
            />
          </div>
        </div>
      )}

      <div className={cn(styles.itemWrapper, styles.squareFull)}>
        <div className={styles.squareTrigger}>
          <span className={styles.inputTitlePlace}>Пл. участка</span>
          <div style={{width: 35}}>
            <Select
              name="squareValue"
              defaultValue={watcherSquareValue}
              options={squareOptions}
              size="small"
              register={register}
              setValue={setValue}
            />
          </div>
        </div>
        <div>
          <DoubleInput
            names={[squareAreaFrom, squareAreaTo]}
            register={register}
            font="ubuntu"
            onFirstInputChange={onChangeSquareAreaFrom}
            onSecondInputChange={onChangeSquareAreaTo}
            textAlign="right"
            setValue={setValue}
            size="unFixed"
            placeholders={["от", "до"]}
            masks={[
              createNumberMask({
                prefix: "",
                thousandsSeparatorSymbol: " ",
              }),
              createNumberMask({
                prefix: "",
                thousandsSeparatorSymbol: " ",
              }),
            ]}
            value={[watcherSquareAreaFrom, watcherSquareAreaTo]}
          />
        </div>
      </div>

      {haveHouses && (
        <div className={cn(styles.itemWrapper, styles.material)}>
          <MultiSelect
            name="additionalProperties.material_doma"
            placeholder="Материал дома"
            options={materialOptions}
            register={register}
            setValue={setValue}
            defaultValue={watcherMaterial}
            onChange={onChangeMaterial}
          />
        </div>
      )}
    </>
  );
};
