import { format as DFformat } from "date-fns";
import intervalToDuration from "date-fns/intervalToDuration";

export const formatDateWithTime = (d: Date, timeZone = true, time?: boolean) => {
	if (!d) return "";

	const dt = new Date(d);

	const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);

	if (time === true) {
		return DFformat(timeZone ? dt : dtDateOnly, `HH:mm`);
	}
	if (time === false) {
		return DFformat(timeZone ? dt : dtDateOnly, `dd.MM.yyyy`);
	}

	return DFformat(timeZone ? dt : dtDateOnly, `dd.MM.yyyy HH:mm`);
};

export const dateToNow = (date: Date): Duration =>
	intervalToDuration({
		start: date,
		end: new Date(),
	});

export const yearsToNow = (date: Date): number => dateToNow(date).years || 0;
