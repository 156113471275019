import { AxiosResponse } from "axios";
import { UseMutateFunction, useMutation } from "react-query";
import { ApplicationCreatePayload } from "../../../../types";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/pre-application/application-template/DEFAULT_MN";
type Props = {
	onSuccess?: (data: any) => void;
};

type Return = {
	mutate: UseMutateFunction<AxiosResponse<any>, unknown, ApplicationCreatePayload, unknown>;
	isLoading: boolean;
};

type UseCreatePreApplicationTemplate = (props: Props) => Return;

export const useCreatePreApplicationTemplate: UseCreatePreApplicationTemplate = ({ onSuccess }) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: ({ leadId, businessProcessId, category }: ApplicationCreatePayload) =>
			api.preApplicationTemplate.create({ leadId, businessProcessId, category }),
		onSuccess,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
