import { useQuery } from "react-query";
import { api } from "../../../../../../../../../../../../service/api";

const DEFAULT_QN = "sdelka/lead/settings/business-process/sources/DEFAULT_QN";

type Return = {
  adSources: string[]
  isLoading: boolean;
  isFetching: boolean;
  refetch: () => void;
  remove: () => void;
}

type useGetAdSources = () => Return

export const useGetAdSources: useGetAdSources = () => {
  const getAdSources = () => api.leads.getSource()
  const {data, isLoading, isFetching, refetch, remove} = useQuery(DEFAULT_QN, getAdSources, {
    enabled: true,
  });

  return {
    adSources: data?.data || [],
    isLoading,
    isFetching,
    refetch,
    remove,
  };
}