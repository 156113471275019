import { useQueryParams } from "../../index";
import { KindType } from "../../../../types";
import {
  useOpenApplicationAd,
  useOpenObjectApplication,
  useOpenObjectPreApplication,
  useOpenParserObject
} from "../../../../service/servise";
import { useLoadApplicationRealEstate } from "../../../../service/api/queries";

type Queries = {
  mode: string;
  applicationId: string;
  parserRealEstateId: string;
  preApplicationId: string
  tab: string
};

type Props = {
  onClose?: () => void;
  isParser?: boolean;
  refetch?: () => void
  kind?: KindType
  refetchList?: () => void
  isFromAd?: boolean
};

type Return = {
  handleOpenDrawerRealEstate: (id: string) => void;
};

type UseOpenDrawerRealEstate = (props: Props) => Return;

export const useOpenDrawerRealEstate: UseOpenDrawerRealEstate = (
  {
    isParser,
    kind,
    refetchList,
    isFromAd
  }) => {

  const {queryParams, changeQueryParams, removeQueryParam} = useQueryParams<Queries>([
    {name: "tab"},
    {name: "mode"},
    {name: "applicationId"},
    {name: "preApplicationId"},
    {name: "filter"},
    {name: "parserRealEstateId"},
  ]);

  const {applicationId, preApplicationId, parserRealEstateId} = queryParams

  const {realEstate} = useLoadApplicationRealEstate({
    id: isParser ? parserRealEstateId : (kind === KindType.APPLICATION ? applicationId : preApplicationId),
    kind: KindType.APPLICATION,
  });
  const {openObjectPreApplication} = useOpenObjectPreApplication({
    atDrawerInDrawer: false
  })
  const {openObjectApplication} = useOpenObjectApplication({
    atDrawerInDrawer: false
  })
  const {openApplicationAd} = useOpenApplicationAd({
    atDrawerInDrawer: false
  })

  const {openParserObject} = useOpenParserObject({
    atDrawerInDrawer: false,
    refetchList,
  })

  const openApplicationRealEstate = (id: string) => {
    if (isFromAd) {
      changeQueryParams([{name: "applicationId", newValue: id}])
      openApplicationAd({
        applicationId: id,
        hasMap: true,
        kind: KindType.APPLICATION,
        refetchParent: refetchList,
      })
    } else {
      removeQueryParam("filter")
      changeQueryParams([
        {name: "tab", newValue: "AGENCY"},
        {name: "mode", newValue: queryParams.mode || "view"},
      ]);

      if (kind === KindType.PREAPPLICATION) {
        changeQueryParams([{name: "preApplicationId", newValue: id}])
        openObjectPreApplication({
          applicationId: realEstate?.preApplicationId || "",
          isFromLead: false,
          isFromObjectList: true
        })
      }

      if (kind === KindType.APPLICATION) {
        changeQueryParams([{name: "applicationId", newValue: id}])
        openObjectApplication({
          applicationId: id,
          hasMap: true,
          kind: KindType.APPLICATION,
          refetchParent: refetchList,
        })
      }
    }
  };

  const openParsedRealEstate = (id: string) => {
    changeQueryParams([{name: "tab", newValue: "PARSED"}, {name: "parserRealEstateId", newValue: id}]);
    openParserObject({
      parsedRealEstateId: id,
      hasMap: true,
    })
  };

  const handleOpenDrawerRealEstate = (id: string) =>
    isParser ? openParsedRealEstate(id) : openApplicationRealEstate(id);

  return {handleOpenDrawerRealEstate};
};
