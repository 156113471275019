import { useMutation } from "react-query";
import { CreateGuaranteePayment, CreateGuaranteePaymentWithNewApplication } from "../../../../types";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/application/create-with-new-app-guarantee-payment/DEFAULT_MN";

type UseCreateGuaranteePayment = {
  onSuccess?: () => void;
};

export const useCreateGuaranteePaymentWithNewApplication = (
  {
    onSuccess
  }: UseCreateGuaranteePayment) => {
  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: ({applicationId, payload}: {applicationId: string, payload: CreateGuaranteePaymentWithNewApplication}) =>
    api.application.createGuaranteePaymentWithNewApplication(applicationId, payload),
    onSuccess
  })

  return {
    createGuaranteePaymentWithNewApplication: mutation.mutate,
    data: mutation.data,
    isLoading: mutation.isLoading
  }
}