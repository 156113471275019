import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { Contact, ContactPinDefaultValues } from "../../../../types";
import { Contacts } from "./Contacts";
import { openModal } from "../../../../service/redux/modules/modal/modal.action";
import styles from "./index.module.scss"
import cn from "classnames";

type Props = {
  children: ReactNode;
  onPin: (contact: Contact) => void;
  hideThisIds?: string[];
  defaultValues?: ContactPinDefaultValues;
  disabled?: boolean;
  isMobile?: boolean
  classNames?: {
    contentWrapper?: string;
    root?: string;
  };
};

export const PinContact = (
  {
    children,
    onPin,
    hideThisIds,
    defaultValues,
    disabled,
    classNames,
    isMobile,
  }: Props): JSX.Element => {
  const dispatch = useDispatch();

  const handleClick = () => {
    if (!disabled) {
      dispatch(
        openModal({
          body: <Contacts onPin={onPin} hideThisIds={hideThisIds} defaultValues={defaultValues} isMobile={isMobile}/>,
          width: isMobile ? 500 : 1000,
          classNames: {
            contentWrapper: isMobile ? styles.contentWrapperClassNameMobile : cn(  classNames?.contentWrapper),
            buttonClose: isMobile ? styles.modalClose : "",
            wrapper: styles.wrapper
          },
        })
      );
    }
  };

  return (
    <div onClick={handleClick} className={classNames?.root}>
      {children}
    </div>
  );
};
