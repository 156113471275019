import { useMutation } from "react-query";

import { UploadFile } from "../../../../types";
import { api } from "../../index";

const DEFAULT_MN = "sdelka/files/load-img/DEFAULT_MN";

type UseLoadImg = {
	onSuccess?: () => void;
};

export const useLoadImg = ({ onSuccess }: UseLoadImg) => {
	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: ({ file, params }: { file: FormData; params: UploadFile }) =>
			api.files.uploadImg(file, params),
		onSuccess,
	});

	return {
		mutateAsync: mutate.mutateAsync,
		isLoading: mutate.isLoading,
	};
};
