import { useMutation } from "react-query";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/application/show-create/DEFAULT_MN";

type UseCreateShowOrView = {
  onSuccess?: () => void;
};

export const useCreateShow = ({onSuccess}: UseCreateShowOrView) => {
  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: ({id, relatedApplicationOrPreApplicationId,plannedDate }: {id: string, relatedApplicationOrPreApplicationId: string, plannedDate: Date}) =>
      api.application.createShow(id, relatedApplicationOrPreApplicationId, plannedDate),
    onSuccess,
  });

  return {
    data: mutation.data,
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
  };
};
