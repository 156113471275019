import { AxiosError } from "axios";
import { useMutation } from "react-query";

import { toastError } from "../../../../components/UI";
import { api } from "../../index";
import { axiosError } from "../../../../utils/errors";

const DEFAULT_MN = "sdelka/lead/take-lead/DEFAULT_MN";

type UseTakeLead = {
	onSuccess?: () => void;
	onError?: () => void;
};

export const useTakeLead = ({ onSuccess, onError }: UseTakeLead) => {
	const handleError = (error: AxiosError) => {
		const message = axiosError({ error });

		if (
			message ===
			"lead stage must be 'Нераспределенные' or 'Необработанные' and lead responsibleUsers must be empty or equals me!"
		) {
			toastError({ text: "Вы не можете взять этот лид в работу" });
		}

		if (onError) {
			onError();
		}
	};

	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: (id: string) => api.leads.takeLead(id),
		onSuccess,
		onError: handleError,
	});

	return {
		mutate: mutate.mutate,
		isLoading: mutate.isLoading,
	};
};
