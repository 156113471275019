import { useQuery } from "react-query";
import { api } from "../../api";
import { ProductOrder } from "../../../../types/product";

const DEFAULT_QN = "sdelka/user/load-order/DEFAULT_QN";

type Props = {
  id?: string | null;
  onSuccess?: () => void;
  onError?: () => void;
};

type Return = {
  productOrder?: ProductOrder;
  isFetching: boolean;
  refetch: () => void;
};

type UseLoadProductOrder = (props: Props) => Return;

const makeUseLoadProductQueryKey = (id?: string | null) => [DEFAULT_QN, id];

export const useLoadProductOrder: UseLoadProductOrder = ({id, onSuccess, onError}) => {
  const loadProduct = () => api.services.getProductOrder(id as string);
  const queryKey = makeUseLoadProductQueryKey(id);
  const {data, isFetching, refetch} = useQuery(queryKey, loadProduct, {
    enabled: !!id,
    onSuccess,
    onError,
  });

  return {
    productOrder: data?.data,
    isFetching,
    refetch,
  };
};
