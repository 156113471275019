import { useMutation } from "react-query";
import { ShowData, ShowsViewsType } from "../../../../types";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/shows-views/start-show/DEFAULT_MN";

type UseStartShowOrView = {
	onSuccess?: (data: any) => void;
};

export const useStartShowOrView = ({ onSuccess }: UseStartShowOrView) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (
			data: {
				applicationId: string;
				type: ShowsViewsType;
				clientObjectApplicationId: string;
			} & ShowData
		) => api.showsViews.start(data),
		onSuccess,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
