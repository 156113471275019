import styles from "./index.module.scss"
import { ReactComponent as Success } from "../../../../../assets/icons/Success.svg";
import { useDispatch } from "react-redux";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { closeModal } from "../../../../../service/redux/modules/modal/modal.action";

export const SuccessCreateModal = () => {
  const dispatch = useDispatch()

  return (
    <div>
      <div className={styles.row}>
        <Success/>
        <div className={styles.title}>
          Гарантийный платеж назначен
        </div>
      </div>
      <div className={styles.rowEnd}>
        <Button onClick={() => dispatch(closeModal())}>
          Продолжить
        </Button>
      </div>
    </div>
  )
}