import { ReactNode, useEffect, useRef } from "react";
import cn from "classnames";

import { ParallaxArgs, parallaxEffect } from "../../../../utils/parallax";

import styles from "./index.module.scss";

type Props = {
	children: ReactNode;
	coordinates: (args: ParallaxArgs) => [string, string];
	position?: "start" | "end";
	className?: string;
};

export const ParallaxItem = ({ children, coordinates, position, className }: Props): JSX.Element => {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		let finishParallax;
		if (ref.current) {
			finishParallax = parallaxEffect(ref.current, coordinates);
		}

		return finishParallax;
	}, [coordinates]);

	return (
		<div
			className={cn(styles.root, {
				[styles[`position__${position}`]]: position,
				
			}, className)}
			ref={ref}
		>
			{children}
		</div>
	);
};
