import {
  AddLeadToApplication,
  ChangeLeadStatusPayload,
  Conditions,
  GetLeadListPayload,
  Lead,
  LeadApplicationInfo,
  LeadHistoryResponse,
  LeadListResponse,
  LeadUpdatePayload,
  RefuseLeadPayload,
  TargetObjectToLead,
  TransferLead,
} from "../../../../types";

import { Client } from "../../Client";
import { getLeadFilters } from "../../helpers";

export const LeadsService = {
  list: ({take, skip, search, value, sort}: GetLeadListPayload) => {
    const searchConditions: Conditions = search ? [...getLeadFilters(search)] : [];

    return Client.post<LeadListResponse>(
      `/${value}/search`,
      JSON.stringify({
        search: {
          filterType: "and",
          conditions: searchConditions,
        },
        take,
        skip,
        sort
      })
    );
  },
  createSubLead: (parentId: string) =>
    Client.post<Lead>(`/lead/from/lead/${parentId}`),
  readAndLock: (id: string) => Client.get<Lead>(`/lead/${id}`),
  takeLead: (id: string) => Client.patch<Lead>(`/lead/${id}/take`),
  createRelationLead: (parentLeadId: string, childLeadId: string) =>
    Client.post<unknown>(`/lead/relations/${parentLeadId}/${childLeadId}`),
  createDoubleLead: (currentLeadId: string, relatedLeadId: string) =>
    Client.post<unknown>(`/lead/setDouble/${currentLeadId}/${relatedLeadId}`),
  changeStatus: (leadId: string, payload: ChangeLeadStatusPayload) =>
    Client.patch<Lead>(`/lead/${leadId}/status`, payload),
  updateLead: (payload: LeadUpdatePayload) => Client.put<Lead>(`/lead/${payload.id}`, payload.data),
  createFromLead: (trafficId: string) => Client.post<Lead>(`/lead/from/traffic/${trafficId}`),
  createFromObject: (objectId: string) => Client.post<Lead>(`lead/from/object/${objectId}`),
  createFromArchivedObject: (objectId: string) => Client.post<Lead>(`lead/from/archivedEstateObject/${objectId}`),
  addTargetApplication: ({applicationId, leadId}: AddLeadToApplication) =>
    Client.put(`lead/${leadId}/target/application/${applicationId}`),
  addTargetObject: ({objectId, leadId}: TargetObjectToLead) =>
    Client.put(`lead/${leadId}/target/object/${objectId}`),
  transfer: ({userId, leadId}: TransferLead) => Client.post(`lead/${leadId}/transferTo/${userId}`),
  deleteTarget: (leadId: string) => Client.delete<Lead>(`/lead/${leadId}/target`),
  getAppInfo: (id: string) => Client.get<LeadApplicationInfo[]>(`lead/${id}/applications-info`),
  leadHistory: (leadId: string) => Client.get<LeadHistoryResponse>(`lead/${leadId}/history`),
  refuse: (leadId: string, payload?: RefuseLeadPayload) => Client.patch(`lead/${leadId}/refuse`, payload),
  addLead: (adSource: string, contactId?: string) => Client.post(`lead`, {adSource, contactId}),
  getSource: () => Client.get<string[]>(`/settings/business-process/sources`)
};
