import cn from "classnames";
import { LeadGoal } from "../../../../UI";
import { LeadActions, LeadApplications, LeadTitle, Responsibles, } from "./components";
import { useCopyText, usePinGoal, useUnpinGoal } from "../../../../../utils/hooks";
import { Lead, LeadSourceEnum, LeadStatusEnum } from "../../../../../types";
import styles from "./index.module.scss";
import { LeadContact } from "./components/contact";
import { formatPhone } from "../../../../../utils/formatters";
import { ReactComponent as CopyIcon } from "../../../../../assets/icons/copy-small.svg";
import { Footer } from "./components/LeadFooter";
import { TasksInLead } from "./components/lead-tasks/TasksInLead";
import { selectDevice, useSelect } from "../../../../../service/redux/selectors";

type Props = {
  lead: Lead;
  refetch: () => void;
  num?: number
  isLoading?: boolean
};

export const LeadInfo = ({lead, refetch, num, isLoading}: Props): JSX.Element => {
  const {
    id: leadId,
    status,
    phones
  } = lead;
  const {clientObject} = lead.target
  const isNotNew = lead.status !== LeadStatusEnum.free;

  const {isMobile} = useSelect(selectDevice)

  const {onPinSelected} = usePinGoal({
    leadId,
    refetch,
    makeNotEditMode: () => {
    },
  });

  const {handleOpenModal} = useUnpinGoal({leadId, refetch});
  const canSeeContact = status !== LeadStatusEnum.free && status !== LeadStatusEnum.new
  const freezed = status === LeadStatusEnum.deferred
  const canSeeTasks = status === LeadStatusEnum.in_progress || status === LeadStatusEnum.done

  const {onCopy} = useCopyText({successToastText: "Скопировано успешно"})
  const handleCopyText = (text?: string) => {
    if (!text) {
      return
    }
    onCopy(text)
  }

  return (
    <>
      <div
        className={cn(styles.root, {
          [ styles.notFinished ]: lead.status !== LeadStatusEnum.done,
          [ styles.isNew ]: !isNotNew,
        })}
        id={String(num)}
      >
        <div className={styles.content}>
          <LeadTitle lead={lead}/>
          <div className={styles.contactData}>
            Контактные данные:
          </div>
          {canSeeContact ?
            <>
              {phones && phones.map((ph) => (
                <div
                  className={cn(styles.contactPhone, styles.rowStart)}
                 >
                  {formatPhone(ph)}
                  <CopyIcon  onClick={() => handleCopyText(`+${ph}`)}/>
                </div>
              ))}
            </>
            : <div>
              Скрыто
            </div>
          }
          {isNotNew && lead.source !== LeadSourceEnum.coldCall && (
            <LeadGoal
              clientObject={clientObject}
              onPinSelected={onPinSelected}
              onUnpin={handleOpenModal}
              freezed={freezed}
              responsibleUser={lead.responsibleUser}
            />
          )}
          <LeadContact
            status={lead.status}
            contact={lead.contact}
            leadId={lead.id}
            refetch={refetch}
            isMobile={isMobile}
          />
          {/* <LeadComment */}
          {/*  comment={lead.comment} */}
          {/*  onSaveComment={saveComment} */}
          {/*  isInProgress={isInProgress} */}
          {/*  isLoading={IsLoadingSaveComment} */}
          {/* /> */}
          {canSeeTasks &&
            <TasksInLead leadId={lead.id}/>
          }
          {/* возможно в будущем вернем */}
          {/* {isNotNew && */}
          {/*  <RelatedLeads */}
          {/*    lead={lead} */}
          {/*    refetch={refetch} */}
          {/*  />} */}
          {isNotNew &&
            <Responsibles
              lead={lead}
              refetch={refetch}
            />
          }
          {isNotNew && (
            <LeadApplications
                lead={lead}
                refetch={refetch}
                isMobile={isMobile}
              />
          )}
        </div>
        <div className={styles.footer}>
          <Footer lead={lead}/>
        </div>
      </div>
      <LeadActions
        lead={lead}
        refetch={refetch}
        isLoading={isLoading}
        isMobile={isMobile}
      />
    </>
  );
};
