import { useState, useEffect } from "react";
import { Control, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import { dateMask, datePipe } from "../../../../../../utils/masks";
import { RealEstateFilterField } from "../../../../../../types";

import { DoubleInput, InfoRow, Radio } from "../../../../index";

import styles from "./index.module.scss";

type Props = {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	register: UseFormRegister<any>;
	field: RealEstateFilterField;
	disabled?: boolean;
};

// If value = 'Своя дата' - shows double input with user custom date.
export const Date = ({ control, setValue, register, field, disabled }: Props): JSX.Element => {
	const [selectedDateOption, setSelectedDateOption] = useState<any>();

	const ownLabel = "Своя дата";

	const dateOptions = [
		{ value: "Не важно", label: "Не важно", disabled },
		{ value: "За месяц", label: "За месяц", disabled },
		{ value: "За час", label: "За час", disabled },
		{ value: ownLabel, label: ownLabel, disabled },
		{ value: "За неделю", label: "За неделю", disabled },
	];

	const dateFromWatcher = useWatch({
		control,
		name: "dateFrom",
	});

	const dateToWatcher = useWatch({
		control,
		name: "dateTo",
	});

	const dateWatcher = useWatch({
		control,
		name: "date",
	});

	useEffect(() => {
		if (dateWatcher === ownLabel && selectedDateOption !== ownLabel) {
			setSelectedDateOption(ownLabel);
		}
	}, [dateWatcher]);

	return (
		<InfoRow weight="regular" variant="column" label={field.label}>
			<Radio
				name="date"
				size="medium"
				font="cera"
				options={dateOptions}
				control={control}
				direction="grid"
				handleOnChange={(value) => setSelectedDateOption(value)}
			/>
			{selectedDateOption === ownLabel && (
				<div className={styles.customDate}>
					<DoubleInput
						register={register}
						names={["dateFrom", "dateTo"]}
						separator="по"
						placeholders={["00.00.00", "00.00.00"]}
						setValue={setValue}
						size="large"
						textAlign="right"
						masks={[dateMask, dateMask]}
						pipes={[datePipe, datePipe]}
						value={[dateFromWatcher, dateToWatcher]}
						disabled={disabled}
					/>
				</div>
			)}
		</InfoRow>
	);
};
