import { useMutation } from "react-query";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/files/load-file/DEFAULT_MN";

type UseLoadFile = {
  onSuccess?: () => void;
};

export const useLoadFile = ({onSuccess}: UseLoadFile) => {
  const mutate = useMutation(DEFAULT_MN, {
    mutationFn: ({file}: { file: FormData }) =>
      api.files.uploadFile(file),
    onSuccess,
  });

  return {
    mutateAsync: mutate.mutateAsync,
    isLoading: mutate.isLoading,
  };
};
