import { useDispatch } from "react-redux";
import { deleteTabInDrawer, deleteTabInDrawerInDrawer } from "../../../../redux/modules/drawer/drawer.action";

type Props = {
  atDrawerInDrawer?: boolean
}

type DeleteTab = {
  index: number
}

export const useDeleteTab = (
  {
    atDrawerInDrawer
  }: Props) => {
  const dispatch = useDispatch()

  const deleteTab = (
    {
      index
    }: DeleteTab) => {

    if (atDrawerInDrawer) {
      dispatch(deleteTabInDrawerInDrawer(index))
    } else {
      dispatch(deleteTabInDrawer(index))
    }
  }

  return {
    deleteTab
  }
}