import { useEffect, useState } from "react";
import { RealEstateSchemaConfigField, FormPropsEditRealEstate } from "../../../../../../../../../../types";

import { InfoRow, RadioButton, TransparentInput } from "../../../../../../../../index";
import { ObjectRadio } from "../ObjectRadio";
import { RequiredDots } from "../../RequiredDots";

import styles from "./index.module.scss";

type Props = {
	form: FormPropsEditRealEstate;
	field: RealEstateSchemaConfigField;
};

export const AgentBonus = ({ form, field }: Props): JSX.Element => {
	const {
		required,
		reqiredFor,
		label,
		dataType: { nameGroup = [] },
	} = field;

	type BonusVariant = "" | "no" | "fixed" | "percent";
	const [bonusVariant, setBonusVariant] = useState<BonusVariant>("no");

	const { control, register, watch } = form;

	const bonusVariantWatcher = watch(nameGroup[0]);

	useEffect(() => {
		setBonusVariant(bonusVariantWatcher);
	}, [bonusVariantWatcher]);

	const radioOnChange = (value: string | number) => {
		setBonusVariant(value.toString() as BonusVariant);
	};

	if (!nameGroup) {
		return <></>;
	}

	return (
		<>
			<InfoRow
				label={label}
				labelFont="ubuntu"
				aspect={{ label: 1, content: 1 }}
				classNames={{ root: styles.customInfoRow }}
			>
				<RequiredDots required={reqiredFor} />

				<ObjectRadio
					handleOnChange={radioOnChange}
					control={control}
					name={`${nameGroup[0]}`}
					required={required}
				/>
			</InfoRow>

			{(bonusVariant === "fixed" || bonusVariant === "percent") && (
				<div className={styles.fixed}>
					<div className={styles.empty} />

					<div className={styles.inputContainer}>
						<div className={styles.inputText}>
							<TransparentInput
								placeholder={bonusVariant === "percent" ? "Укажите %" : "Не указано"}
								name={`${nameGroup[1]}`}
								register={register}
								required={required}
							/>
						</div>

						{bonusVariant === "fixed" && (
							<div className={styles.inputRadio}>
								<RadioButton
									name={`${nameGroup[2]}`}
									control={control}
									options={[
										{ value: "рубль", label: "₽" },
										{ value: "доллар", label: "$" },
										{ value: "евро", label: "€" },
									]}
									required={required}
								/>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};
