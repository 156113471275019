import { PlatformData } from "../../../../types";
import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/real-estate-ad/get-platform/DEFAULT_QN";

type Props = {
	applicationId: string;
	feedId: string;
};

type Return = {
	data?: PlatformData;
	isLoading: boolean;
	refetch: () => void;
	remove: () => void;
	isFetched: boolean;
};

type UseGetPlatform = (props: Props) => Return;

export const useGetPlatform: UseGetPlatform = ({ applicationId, feedId }) => {
	const loadPlatform = () => api.realEstateAd.platform(applicationId, feedId);

	const { data, refetch, remove, isLoading, isFetched } = useQuery(DEFAULT_QN, loadPlatform, {
		enabled: false,
	});

	return {
		// @ts-ignore
		data: data?.data?.data,
		isLoading,
		refetch,
		remove,
		isFetched,
	};
};
