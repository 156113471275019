import { ReactComponent as OpenInNew } from "../../../../../../../assets/icons/open_in_new_active.svg";
import styles from "./index.module.scss";
import cn from "classnames";
import { DefaultTasksFiltersType, TaskCountInfo, TaskStatusVariant } from "../../../../../../../types";
import { useLoadTasks } from "../../../../../../../service/api/queries";
import { CardTaskList, TabsRadio } from "../../../../../../UI";
import { Plug } from "../plug/Plug";
import { useIsTelegram } from "../../../../../../../utils/telegram/useIsTelegram";

type Props = {
  leadId: string
};

const activeStages: TaskStatusVariant[] = ["new", "inWork", "done", "failed"];

const getOptions = (countData?: TaskCountInfo) => [
  {label: "Взаимодействие", count: countData?.communications || 0},
];

export const TasksInLead = ({leadId}: Props): JSX.Element => {

  const defaultFilter: DefaultTasksFiltersType = {
    taskType: ["communications"],
    variant: activeStages,
    communicationsForLeadId: leadId
  };

  const {
    data = [],
    tabsCount,
    setFilter,
    refetch: refetchTask,
  } = useLoadTasks({
    defaultFilter,
    defaultStage: ["new", "inWork", "done", "failed"],
    take: 5
  });
  const {executeUrl} = useIsTelegram()

  const handleSeeAll = () => {
   executeUrl(`/tasks?leadId=${leadId}`)
  }

  const refetch = () => {
    refetchTask();
  };

  const onTabChange = (idx: number) => {
    if (idx === 0) {
      setFilter({taskType: ["communications"], communicationsForLeadId: leadId})
    }
    if (idx === 1) {
      setFilter({taskType: ["showOrViews"], communicationsForLeadId: leadId})
    }
    if (idx === 2) {
      setFilter({taskType: ["defaults"], communicationsForLeadId: leadId})
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.rowBetween}>
        <div className={styles.title}>
          Задачи
        </div>
        <div className={styles.row}>
          <div className={cn(styles.row, styles.link)}
               onClick={handleSeeAll}
          >
            <OpenInNew/>
            <div>
              Смотреть все
            </div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.menu}>
          <TabsRadio initialTab={0} titles={getOptions(tabsCount)} onTabChange={onTabChange}>
            <div/>
            <div/>
          </TabsRadio>
        </div>
        {data && data.length ? (
          <CardTaskList
            tasks={data || []}
            refetch={refetch}
            mini
          />
        ) : (
          <Plug plugText="Задачи на выполнение отсутствуют"/>
        )}
      </div>
    </div>
  );
};
