import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./index.module.scss"
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { PriceInput } from "./helpers/PriceInput";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../service/redux/modules/modal/modal.action";
import { TransparentInput } from "../../Form";
import { createNumberMask } from "text-mask-addons";
import { InfoRow } from "../../InfoRow";
import { UseUpdatePriceWithDiscount } from "../../../../service/api/mutations/real-estate/useUpdatePriceWithDiscount";
import { RealEstateUpdatePriceWithDiscount } from "../../../../types";


type Props = {
  price: string | number
  id: string
  realEstateRefetch?: () => void
  priceWithDiscount?: string | number
}

export const Discount = (
  {
    id,
    price,
    realEstateRefetch,
    priceWithDiscount
  }: Props): JSX.Element => {

  const {control, register, getValues, setValue} = useForm<any>({
  });

  useEffect(() => {
    setValue("priceWithDiscount", priceWithDiscount)
  }, [])

  const {mutatePriceWithDiscount} = UseUpdatePriceWithDiscount({onSuccess: realEstateRefetch})

  const onChangeDiscount = () => {
    const pricedWithPercent = getValues("priceWithDiscount")

    const payload: RealEstateUpdatePriceWithDiscount = {
      id,
      priceWithDiscount: +(String(pricedWithPercent).replaceAll(" ", ""))
    };
    mutatePriceWithDiscount(payload);
  };

  const title = "Укажите цену со скидкой"
  const [percent, setPercent] = useState(0)

  const handleOnChangePriceWithDiscount = () => {

    const pricedWithPercent = getValues("priceWithDiscount")

    if (getValues("Discount") !== String(+price - +(String(pricedWithPercent).replaceAll(" ", "")))) {
      setValue("Discount", String(+price - +(String(pricedWithPercent).replaceAll(" ", ""))))
    }
    setPercent(((+price - +(String(pricedWithPercent).replaceAll(" ", ""))) / +price) * 100)
  }

  const handleOnChangeDiscount = () => {
    const disc = getValues("Discount").replaceAll(" ", "")
    if (String(getValues("priceWithDiscount")).replaceAll(" ", "") !== String(+price - +disc)) {
      setValue("priceWithDiscount", String(+price - +disc))
    }
  }

  const dispatch = useDispatch()

  const onApply = () => {
    onChangeDiscount()
    dispatch(closeModal())
  }

  const mask = createNumberMask({
    prefix: "",
    thousandsSeparatorSymbol: " ",
  })

  return (
    <form className={styles.content}>
      <div className={styles.title}>
        {title}
      </div>
      <InfoRow
        label="Цена со скидкой"
        aspect={{label: 8, content: 6}}
        classNames={{
          content: styles.root,
        }}>
        <TransparentInput
          label="Цена со скидкой"
          onChange={handleOnChangePriceWithDiscount}
          register={register}
          name="priceWithDiscount"
          setValueForMask={setValue}
          mask={mask}
          placeholder="Указать ₽"
          value={getValues("priceWithDiscount")}
        />
      </InfoRow>
      <div>
        <PriceInput
          label="Размер скидки"
          control={control}
          handleOnChange={handleOnChangeDiscount}
          register={register}
          getValues={getValues}
          inputName="Discount"
          setValue={setValue}
          percent={percent}
        />
      </div>
      <div className={styles.row}>
        <Button onClick={onApply}>
          Сохранить
        </Button>
      </div>
    </form>
  )
}
