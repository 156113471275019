import { CollectionObject } from "../../../../../../../../types";

import { useCompilation, useCompare, useReturnToBack } from "./helpers";
import { useSampleType } from "../../../../../../../../utils/hooks";
import { SampleType } from "../../../../../../../../types/real-estate";

type Props = {
	isCompilation: boolean;
	isCompare: boolean;
	sampledApplicationId: string;
};

type Return = {
	sampleList: CollectionObject[];
	sampleRefetch: () => void;
	handleRemoveObjectFromActual: () => void;
	isLoadingRemoveObjectFromActual: boolean;
	handleOnReturnToBack: () => void;
	returnBackLabelTitle: string;
	sampleTitle: string;
};

type UseSample = (props: Props) => Return;

type SampleRecord = [CollectionObject[], () => void, () => void, boolean, string, string];

export const useSample: UseSample = ({ isCompilation, isCompare, sampledApplicationId }) => {
	const { sampleType } = useSampleType({ isCompare, isCompilation });

	const {
		compilationObjects,
		refetchCompilation,
		handleCompilationRemoveObjectFromActual,
		isLoadingCompilationRemoveObjectFromActual,
		returnCompilationLabelTitle,
		compilationTitle,
	} = useCompilation({ sampledApplicationId, isCompilation });

	const {
		compareObjects,
		refetchCompare,
		isLoadingCompareRemoveObjectFromActual,
		handleCompareRemoveObjectFromActual,
		returnCompareLabelTitle,
		compareTitle,
	} = useCompare({ applicationId: sampledApplicationId, isCompare });
	const SAMPLE: Record<SampleType, SampleRecord> = {
		compilation: [
			compilationObjects,
			refetchCompilation,
			handleCompilationRemoveObjectFromActual,
			isLoadingCompilationRemoveObjectFromActual,
			returnCompilationLabelTitle,
			compilationTitle,
		],
		compare: [
			compareObjects,
			refetchCompare,
			handleCompareRemoveObjectFromActual,
			isLoadingCompareRemoveObjectFromActual,
			returnCompareLabelTitle,
			compareTitle,
		],
		none: [[], () => {}, () => {}, false, "", ""],
	};

	const sampleList = SAMPLE[sampleType()][0];
	const sampleRefetch = SAMPLE[sampleType()][1];
	const handleRemoveObjectFromActual = SAMPLE[sampleType()][2];
	const isLoadingRemoveObjectFromActual = SAMPLE[sampleType()][3];
	const returnBackLabelTitle = SAMPLE[sampleType()][4];
	const sampleTitle = SAMPLE[sampleType()][5];

	const { handleOnReturnToBack } = useReturnToBack();

	return {
		sampleList,
		sampleRefetch,
		handleRemoveObjectFromActual,
		isLoadingRemoveObjectFromActual,
		handleOnReturnToBack,
		returnBackLabelTitle,
		sampleTitle,
	};
};
