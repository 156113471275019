import { UseMutateFunction, useMutation } from "react-query";
import {
	ComplaintAutoRejectPayload,
	RealEstateUpdateAutoRenewalPayload,
	RealEstateUpdateAutoRenewalResponse,
} from "../../../../types";
import { api } from "../../api";
import { AxiosResponse } from "axios";

const DEFAULT_MN = "sdelka/real-estate-ad/complaint-auto-reject/DEFAULT_MN";

type Props = {
	onSuccess?: (
		data: AxiosResponse<RealEstateUpdateAutoRenewalResponse>,
		variables: ComplaintAutoRejectPayload,
		context: unknown
	) => void | Promise<unknown>;
};

type Return = {
	mutate: UseMutateFunction<
		AxiosResponse<RealEstateUpdateAutoRenewalResponse>,
		unknown,
		ComplaintAutoRejectPayload,
		unknown
	>;
	isLoading: boolean;
};

type UseComplaintAutoReject = (props: Props) => Return;

export const useComplaintAutoReject: UseComplaintAutoReject = ({ onSuccess }) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (data: ComplaintAutoRejectPayload) => api.realEstateAd.updateComplaintAutoReject(data),
		onSuccess,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
