import { useMutation, useQueryClient } from "react-query";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/application/create-task-from-application/DEFAULT_MN";

const RELATED_QN = "sdelka/application/load-application-card/DEFAULT_QN";

type UseCreateTasksFromApplication = {
	applicationId: string;
	onSuccess?: (data: any) => void;
};

export const useCreateTasksFromApplication = ({
	applicationId,
	onSuccess,
}: UseCreateTasksFromApplication) => {
	const queryClient = useQueryClient();

	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (data: { applicationId: string; payload: any }) => api.application.createTasks(data),
		onSuccess: (args) => {
			queryClient.invalidateQueries([], {
				queryKey: [RELATED_QN, applicationId],
			});
			onSuccess?.(args);
		},
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
		isSuccess: mutation.isSuccess,
	};
};
