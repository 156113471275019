import { Dispatch, SetStateAction } from "react";
import { RealEstateFilter, RealEstateListTab } from "../../../../../../../../../types";
import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import { createNumberMask } from "text-mask-addons";
import cn from "classnames";

import { DoubleInput, MultiCheckbox } from "../../../../../../../../UI";

import styles from "../../../index.module.scss";

type Props = {
  control: Control<any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  setFilter: Dispatch<SetStateAction<RealEstateFilter>>;
  filter: RealEstateFilter;
  globalFilter: RealEstateFilter;
  roomsOptions: { value: string; label: string }[];
  tab: RealEstateListTab;
};

export const ApartmentOptional = (
  {
    control,
    register,
    setValue,
    getValues,
    setFilter,
    filter,
    globalFilter,
    roomsOptions,
    tab,
  }: Props): JSX.Element => {
  const onChangeSquareFrom = (square: string) =>
    setFilter({
      ...globalFilter,
      ...filter,
      squareFrom: Number(String(square)?.replace(/\s/g, "")),
    });

  const onChangeSquareTo = (square: string) =>
    setFilter({
      ...globalFilter,
      ...filter,
      squareTo: Number(String(square)?.replace(/\s/g, "")),
    });

  const onChangeRoom = (rooms: string[]) => setFilter({...globalFilter, ...filter, rooms});

  const watchRooms = useWatch({
    control,
    name: "rooms",
    defaultValue: getValues("rooms") || [],
  });

  const watchSquareFrom = useWatch({
    control,
    name: "squareFrom",
    defaultValue: getValues("squareFrom") || "",
  });

  const watchSquareTo = useWatch({
    control,
    name: "squareTo",
    defaultValue: getValues("squareTo") || "",
  });

  return (
    <>
      <div className={cn(styles.itemWrapper, styles.flats)}>
        <MultiCheckbox
          name="rooms"
          register={register}
          setValue={setValue}
          options={roomsOptions}
          variant="radioButton"
          onChange={onChangeRoom}
          defaultValue={watchRooms}
        />
      </div>
      <div className={cn(styles.itemWrapper, styles.square)}>
        <span className={styles.inputTitle}>Пл. м2:</span>
        <div>
          <DoubleInput
            names={["squareFrom", "squareTo"]}
            register={register}
            onFirstInputChange={onChangeSquareFrom}
            onSecondInputChange={onChangeSquareTo}
            font="ubuntu"
            placeholders={["от", "до"]}
            textAlign="right"
            size="unFixed"
            setValue={setValue}
            masks={[
              createNumberMask({
                prefix: "",
                thousandsSeparatorSymbol: " ",
              }),
              createNumberMask({
                prefix: "",
                thousandsSeparatorSymbol: " ",
              }),
            ]}
            value={[watchSquareFrom, watchSquareTo]}
          />
        </div>
      </div>

    </>
  );
};
