import cn from "classnames";

import { CommonListItemWithChild } from "../../../types";

import styles from "./index.module.scss";

type Props = CommonListItemWithChild & {
	type?: "bold" | "default";
	classNames?: {
		root?: string;
	};
};

export const TextInfo = ({ label, children, classNames, type = "default" }: Props): JSX.Element => (
	<div className={cn(styles.root, styles[type], classNames?.root)}>
		<div className={styles.label}>{label}</div>
		{typeof children === "string" ? <div className={styles.value}>{children}</div> : children}
	</div>
);
