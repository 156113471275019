import { useState } from "react";
import { Underlay, Tooltip, Button, H5 } from "@sdelka_crm/sdelka-crm-component-library";

import { RoundInput, ObjectAdDescriptionForm } from "../../../../../../../../../UI";
import { useUpdatePlatformDescription } from "../../../../../../../../../../service/api/mutations";
import { closeModal } from "../../../../../../../../../../service/redux/modules/modal/modal.action";
import { formatNumbers } from "../../../../../../../../../../utils/formatters";

import styles from "./index.module.scss";

type ObjectAdPartnerHeaderModalProps = {
	applicationId: string;
	dispatch: any;
	price?: string;
	pricePerUnit?: string;
	data: any;
	remove: any;
	feedId: string;
	unit?: string;
	square?: string;
	error?: boolean;
	icon?: string;
};

const prepareData = (payload: { data: Record<string, unknown>; id: string }) => ({
	...payload.data,
	applicationId: payload.id,
	additionalProperties: undefined,
});

export const RealEstateAdDescriptionModal = ({
	applicationId,
	feedId,
	dispatch,
	data,
	remove,
	price,
	pricePerUnit,
	unit,
	square = "1",
	error,
	icon,
}: ObjectAdPartnerHeaderModalProps): JSX.Element => {
	const [innerPrice, setPrice] = useState(price);
	const [innerPricePerUnit, setPricePerUnit] = useState(pricePerUnit);

	const numberedSquare: number = square ? +square : 1;

	const setPriceValues = (type: string, val: string): void => {
		const nVal = Number(val.replace(/( |\D)/g, ""));
		if (type === "price") {
			const computed = `${nVal}`;
			if (computed.length > 10) return;
			setPrice(computed);
			setPricePerUnit(`${Math.round(nVal / numberedSquare).toFixed(2)}`);
		} else {
			const computed = `${nVal * numberedSquare}`;
			if (computed.length > 10) return;
			setPricePerUnit(`${nVal}`);
			setPrice(computed);
		}
	};

	return (
		<div>
			<div className={styles.modalTitle}>
				{icon && (
					<div className={styles.modalTitleImage}>
						<img src={icon} alt="Логотип рекламной площадки" />
					</div>
				)}

				<H5 classNames={{ root: styles.h5 }}>Изменить параметры площадки</H5>

				<Underlay className={styles.modalPriceUnderlay}>
					<div className={styles.modalPrice}>
						<div>Цена площадки</div>

						<div className={styles.modalPriceInputs}>
							<RoundInput
								name="price"
								append="₽"
								value={formatNumbers(innerPrice)}
								onChange={(val) => setPriceValues("price", val)}
							/>
							<RoundInput
								name="pricePerUnit"
								append={`₽/${unit || ""}`}
								value={formatNumbers(innerPricePerUnit)}
								onChange={(val) => setPriceValues("pricePerUnit", val)}
							/>
						</div>
					</div>
				</Underlay>
			</div>

			<div className={styles.modalForm}>
				<ObjectAdDescriptionForm
					applicationId={applicationId}
					disableEditMode
					adTitleError={
						error && (
							<Tooltip classNames={{ root: styles.modalError }}>
								<div className={styles.modalErrorCard}>
									<div>Для выгрузки заголовка в рекламу требуется тариф Premium или Top</div>
									<div>
										Ваш тариф:
										<span>Стандарт</span>
									</div>
								</div>
							</Tooltip>
						)
					}
					title="Описание объекта"
					options={{ hideVideoLink: true }}
					realEstate={{
						id: data.id,
						adTitle: data?.adTitle || "",
						adDescription: data?.adDescription || "",
						adDescriptionSignatureId: data?.adDescriptionSignatureId || "",
					}}
					additionalFields={{
						price: Number(innerPrice) || 0,
						pricePerUnit: Number(innerPricePerUnit) || 0,
						feedId,
					}}
					refetch={() => {
						dispatch(closeModal());
						remove();
					}}
					onMutate={{
						func: useUpdatePlatformDescription,
						prepareData,
					}}
				>
					<Button disabled={error} classNames={{ root: styles.modalSubmit }} type="submit">
						Сохранить изменения
					</Button>
				</ObjectAdDescriptionForm>
			</div>
		</div>
	);
};
