import cn from "classnames";

import styles from "./index.module.scss";

export const ObjectEditLoading = (): JSX.Element => (
	<div className={styles.root}>
		<div className={cn(styles.skeleton, styles.header)} />
		<div className={cn(styles.skeleton, styles.baseFields)} />
		<div className={cn(styles.skeleton, styles.address)} />
		<div className={cn(styles.skeleton, styles.ad)} />
		<div className={styles.skeletonsContainer}>
			<div className={styles.leftSkeleton}>
				<div className={cn(styles.skeleton, styles.rooms)} />
				<div className={cn(styles.skeleton, styles.square)} />
			</div>
			<div className={styles.rightSkeleton}>
				<div className={cn(styles.skeleton, styles.contact)} />
				<div className={cn(styles.skeleton, styles.responsible)} />
			</div>
		</div>
	</div>
);
