import { ReactElement, ReactNode } from "react";
import styles from "./index.module.scss";

interface Props {
  children: ReactNode;
  leftColumn: ReactElement;
}

export const SplitScreenLayout = ({children, leftColumn}: Props): JSX.Element => (
  <div className={styles.root}>
    <div className={styles.leftColumn}>
      {leftColumn}
    </div>

    <div className={styles.rightColumn}>{children}</div>
  </div>
);
