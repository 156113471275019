import { ReactNode } from "react";
import cn from "classnames";

import styles from "./index.module.scss";

type Position = "top" | "right" | "bottom" | "left" | "bottomRight" | "bottomLeft" | "topLeft" | "topRight";

type Props = {
	children?: ReactNode;
	overElement: ReactNode;
	position?: Position;
	classNames?: {
		over?: string;
		root?: string;
	};
};

export const Popover = ({ children, overElement, position = "top", classNames }: Props): JSX.Element => (
		<div
			className={cn(styles.root, classNames?.root, {
				[styles.rootWithChildren]: !!children,
			})}
		>
			{children || <div className={styles.emptyOver} />}
			<div className={cn(styles.over, styles[position], classNames?.over)}>{overElement}</div>
		</div>
	);
