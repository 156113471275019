import { Dispatch, SetStateAction } from "react";
import { Creator, TabsStage, TabStage } from "../../../../../../../../../../../UI";
import { Block } from "./components";
import {
  ApplicationCardBlock,
  ApplicationCardResponse,
  KindType,
  RealEstate,
} from "../../../../../../../../../../../../types";

import styles from "./index.module.scss";
import { DealFooter } from "../../../../../../../../../../../UI/ApplicationComponents/footer/DealFooter";

type Props = {
  application: ApplicationCardResponse;
  applicationId: string;
  setStageNum: Dispatch<SetStateAction<number>>;
  refetch: () => void;
  currentBlocks: any[];
  scrollY: number;
  onNextStage: (stageNumber: number) => void;
  errorsLabel: string[];
  onTab?: (tab: number) => void;
  unpinObject: () => void;
  addObject: (object: RealEstate) => void;
  isFetchingBlocks: boolean;
  onPinObject: (object: RealEstate) => void;
  kind?: KindType,
  isMobile?: boolean
};

export const BodyInfo = (
  {
    application,
    applicationId,
    setStageNum,
    currentBlocks,
    refetch,
    scrollY,
    onNextStage,
    errorsLabel,
    onTab,
    unpinObject,
    addObject,
    onPinObject,
    isFetchingBlocks,
    kind,
    isMobile
  }: Props): JSX.Element => {
  const {
    comment,
    creator,
    stages,
    stageNo,
    collectionDescription,
    additionalProperties,
    clientObject,
    createdAt,
    modifiedAt,
    shortId,
    responsibleUser,
    type: {whoIsClient},
  } = application;

  return (
    <div id="stages" className={kind === KindType.PREAPPLICATION ? styles.pre_applications : styles.wrapper}>

      <TabsStage
        onChange={setStageNum}
        stages={stages}
        currentStage={stageNo}
        onNextStage={onNextStage}
        errorsLabel={errorsLabel}
        key={stageNo}
        isFetchingBlocks={isFetchingBlocks}
      >

        {currentBlocks && stages.map((stage, index) => (
          <TabStage key={stage.title}>
            {(currentBlocks || []).map((block: ApplicationCardBlock) => (
              <Block
                application={application}
                block={block}
                index={index}
                stageNo={stageNo}
                scrollY={scrollY}
                comment={comment}
                applicationId={applicationId}
                clientObject={clientObject}
                onTab={onTab}
                onPinObject={onPinObject}
                unpinObject={unpinObject}
                addObject={addObject}
                additionalProperties={additionalProperties}
                collectionDescription={collectionDescription}
                refetch={refetch}
                responsibleUser={responsibleUser}
                key={block.id}
                kind={kind}
                whoIsClient={whoIsClient}
                isMobile={isMobile}
              />
            ))}
          </TabStage>
        ))}

      </TabsStage>

      <div id="aboutApplication" className={styles.creator}>
        {kind === KindType.DEAL
          ? <DealFooter application={application}/>
          : <Creator
            creator={{...creator.account, userId: creator.id}}
            lead={{
              id: application.parentLead?.id ?? applicationId,
              shortId: String(application.parentLead?.shortId ?? shortId),
            }}
            createdDate={(createdAt as string) || ""}
            updatedDate={(modifiedAt as string) || ""}
          />
        }
      </div>
    </div>
  );
};
