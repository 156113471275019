import styles from "./index.module.scss";

const count = 4;

export const Skeleton = (): JSX.Element => (
	<div className={styles.root}>
		{Array(count)
			.fill(0)
			.map((_, idx) => (
				// eslint-disable-next-line react/no-array-index-key
				<div key={idx} className={styles.skeleton} />
			))}
	</div>
);
