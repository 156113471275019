import { FixedNavHorizontal, ObjectLayoutLoading, RealEstateMap, XYNavLayout } from "../../../UI";
import { RealEstateCreate } from "../mode";
import { useMapAdjust } from "../helpers";
import { selectCurrentAccount, selectDevice, selectObjectEdit, useSelect } from "../../../../service/redux/selectors";
import { useQueryParams } from "../../../../utils/hooks";
import { paths } from "../../../../utils/paths";
import { ReactComponent as Edit } from "../../../../assets/icons/edit-page.svg";
import styles from "../../select-office/index.module.scss";
import { ReactComponent as MapIcon } from "../../../../assets/icons/iconMap.svg";
import { useGoBackRealEstate } from "../../../../utils/objects";
import { useState } from "react";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/iconMapClose.svg";

type QueryParams = {
  preCreateForm: string;
};

export const CreateRealEstatePage = (): JSX.Element => {
  const {coord, dontMarkOnMap} = useSelect(selectObjectEdit);
  const {
    queryParams: {preCreateForm},
  } = useQueryParams<QueryParams>([{name: "preCreateForm"}]);
  const {currentAccount} = useSelect(selectCurrentAccount);
  const {isMobile} = useSelect(selectDevice)
  const mapZoom = Number(currentAccount?.company?.mapZoom);
  const mapCenter = [currentAccount?.settings.mapCenter?.lon, currentAccount?.settings?.mapCenter?.lat] as [
    number,
    number
  ];

  const defaultValues = JSON.parse(decodeURIComponent(preCreateForm));

  const navigationY = [
    {
      id: "edit",
      label: <Edit/>,
      changeTab: () => {
      },
      height: 90,
      disabled: true,
    },
  ];

  const {onMapClick} = useMapAdjust({isEditOrCreate: true});
  const defaultBackPath = `${paths.objectsPath.list}`
  const {goBack} = useGoBackRealEstate({defaultBackPath});
  const [isMapOpen, setIsMapOpen] = useState(false)

  return (
    <XYNavLayout
      leftBlock={
        <>
          {isMobile
            ? <></>
            : <RealEstateMap
              onMapClick={onMapClick}
              mark={dontMarkOnMap ? undefined : coord}
              options={{
                center: coord.lat && coord.lon ? [coord.lat as number, coord.lon as number] : mapCenter,
                zoom: mapZoom ?? 16,
                updateCenterIfChanged: true,
                withoutHandlers: true,
              }}
            />}
        </>
      }
      yNavigation={navigationY}
      yNavigationTop={60}
      activeYTab={0}
      withGoBack={{defaultBackPath}}
      navVariant="grey"
    >
      <>
        {isMobile &&
          <div className={styles.nav}>
            <FixedNavHorizontal
              onCloseClick={goBack}
              navigation={[]}
              activeTab={0}
            />
          </div>
        }
        {isMapOpen && isMobile &&
          <div className={styles.mapMobile}>
            <RealEstateMap
              onMapClick={onMapClick}
              mark={dontMarkOnMap ? undefined : coord}
              options={{
                center: coord.lat && coord.lon ? [coord.lat as number, coord.lon as number] : mapCenter,
                zoom: mapZoom ?? 16,
                updateCenterIfChanged: true,
                withoutHandlers: true,
              }}
            />
          </div>
        }
        {defaultValues
          ? <RealEstateCreate defaultValues={defaultValues} isMobile={isMobile}/>
          : <ObjectLayoutLoading/>
        }
        {isMobile &&
          <div className={styles.mapButtonObject} onClick={() => setIsMapOpen(!isMapOpen)}>
            {isMapOpen ? <CloseIcon/> : <MapIcon/>}
          </div>
        }
      </>
    </XYNavLayout>
  );
};
