import { Client } from "../../Client";
import { Position } from "../../../../types";

export const PositionsService = {
	list: () => Client.get<Position[]>("/company/position"),
	getById: (id: string) => Client.get(`/company/position/${id}`),
	delete: (id: string) => Client.delete(`/company/position/${id}`),
	create: (data: Partial<Position>) => Client.post("/company/position", data),
	update: (id: string, data: Partial<Position>) => Client.put(`/company/position/${id}`, data),
};
