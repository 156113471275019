import { useMutation } from "react-query";

import { CompanyAcceptAccession } from "../../../../types";
import { api } from "../../index";

const DEFAULT_MN = "sdelka/company/confirm-accession/DEFAULT_MN";

type UseCompanyConfirmAccession = {
	onSuccess?: () => void;
};

export const useCompanyConfirmAccession = ({ onSuccess }: UseCompanyConfirmAccession) => {
	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: ({ id, data }: { id: string; data: CompanyAcceptAccession }) =>
			api.companies.confirmAccession(id, data),
		onSuccess,
	});

	return {
		mutate: mutate.mutate,
		isLoading: mutate.isLoading,
	};
};
