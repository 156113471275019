import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { KindType } from "../../../../../types";
import {
  ApplicationBusinessProcess,
  ObjectEditLoading,
  ObjectLayoutLoading,
  RealEstateForm,
  RealEstateMap
} from "../../../../UI";
import { useOnSubmit } from "./helpers";
import { setAddress, setRoomsAmount } from "../../../../../service/redux/modules/object-edit/object-edit.action";
import { useLoadApplicationRealEstate, useLoadConfig } from "../../../../../service/api/queries";
import styles from "./index.module.scss";
import { useChangeTab, useDeleteTab } from "../../../../../service/servise";
import { selectCurrentAccount, selectObjectEdit, useSelect } from "../../../../../service/redux/selectors";
import { ReactComponent as MapIcon } from "../../../../../assets/icons/iconMap.svg";
import { useMapAdjust } from "../../helpers";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/iconMapClose.svg";

type Props = {
  id: string;
  refetch: () => void;
  options?: {
    disableMap?: boolean;
    notFullHeight?: boolean;
  };
  kind?: KindType
  enabled?: boolean
  atDrawerInDrawer?: boolean
  isMobile?: boolean
};

export const RealEstateEdit = (
  {
    id,
    options = {},
    refetch,
    kind,
    enabled,
    atDrawerInDrawer,
    isMobile
  }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [isMapOpen, setIsMapOpen] = useState(false)
  const {realEstate, isFetching, remove} = useLoadApplicationRealEstate({
    id,
    kind,
    enabled
  });

  const {disableMap} = options;

  const {
    objectConfig,
    isFetching: isConfigLoading,
    currentCategory,
    setCategory,
    removeData: removeConfig,
  } = useLoadConfig({});

  const {
    control,
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState,
    clearErrors,
    setError,
  } = useForm<any>({
    defaultValues: realEstate,
  });

  useEffect(() => {
    if (realEstate) {
      setCategory({
        category: realEstate.category,
        businessProcessId: realEstate.type.id,
      });

      if (Object.keys(watch()).length === 0) {
        dispatch(setRoomsAmount(realEstate?.rooms || ""));
        reset(realEstate);
      }
      dispatch(
        setAddress({
          coord: {
            lat: realEstate?.location?.coordinates[ 0 ] || 0,
            lon: realEstate?.location?.coordinates[ 1 ] || 0,
          },
          addressId: realEstate?.addressId,
          addressLabel: realEstate?.addressLabel,
        })
      );
    }
  }, [realEstate]);

  const form = {
    control,
    register,
    setValue,
    getValues,
    watch,
    clearErrors,
    setError,
    formState,
    reset,
    handleSubmit,
  };

  const closeButton = document.getElementById("drawer-close-map-button")
  const [closeElement, setCloseElement] = useState<HTMLElement | null>(null)

  useEffect(() => {
    setCloseElement(closeButton)
  }, [closeButton])

  const {coord, dontMarkOnMap} = useSelect(selectObjectEdit);
  const {currentAccount} = useSelect(selectCurrentAccount);
  const {onMapClick, point} = useMapAdjust({isEditOrCreate: true, allowChangeByMap: true});
  const {changeTab} = useChangeTab({atDrawerInDrawer})
  const {deleteTab} = useDeleteTab({atDrawerInDrawer})
  const {onSubmit, isUpdateLoading, photos, setPhotos} = useOnSubmit({
    refetchRealEstate: refetch,
    setError,
    clearErrors,
    applicationId: id,
    disableMap,
    realEstatePhotos: realEstate?.photos,
    onClose: () => {
      changeTab({title: "view"})
      deleteTab({index: kind === KindType.APPLICATION ? 4 : 3})
      if (closeElement !== null) {
        closeElement.click()
      }
    }
  });
  
  useEffect(() => () => {
      remove();
      removeConfig();
    }, []);

  useEffect(() => {
    clearErrors();
  }, [currentCategory]);

  if ((isFetching || isConfigLoading) && !realEstate) {
    return <ObjectEditLoading/>;
  }

  const mapZoom = Number(currentAccount?.company?.mapZoom);
  const mapCenter = [currentAccount?.settings.mapCenter?.lon, currentAccount?.settings?.mapCenter?.lat] as [
    number,
    number
  ];

  return (
    <>
      {isMobile &&
        <div className={styles.buttonMap} onClick={() => setIsMapOpen(!isMapOpen)}>
          {isMapOpen ? <CloseIcon/> : <MapIcon/>}
        </div>
      }
      {objectConfig
        ?
        <>
          {isMapOpen
            && <RealEstateMap
              onMapClick={onMapClick}
              mark={dontMarkOnMap ? undefined : coord}
              options={{
                center: coord.lat && coord.lon ? [coord.lat as number, coord.lon as number] : mapCenter,
                zoom: mapZoom ?? 16,
                updateCenterIfChanged: true,
                withoutHandlers: true,
              }}
            />}
          <div className={styles.root}>
            <h2>Редактировать объект</h2>
            <ApplicationBusinessProcess form={form}/>
            <RealEstateForm
              id={id}
              form={form}
              isMutationLoading={isUpdateLoading}
              objectConfig={objectConfig}
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              isNewMod
              photos={photos}
              setPhotos={setPhotos}
            />
          </div>
        </>
        : <ObjectLayoutLoading/>
      }
    </>
  );
};
