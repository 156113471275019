import { useMutation, useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/pre-application/application-template/arhive/DEFAULT_MN";

type UseLoadPreApplicationTemplate = {
	applicationId: string;
};

export const useArhivePreApplications = ({ applicationId }: UseLoadPreApplicationTemplate) => {

	// const { data, refetch, remove, isLoading, isFetched} = useQuery(DEFAULT_MN, arhivePreApplicationTemplate);
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: () => api.preApplicationTemplate.arhive({ applicationId }),
	});
  
	return {
		data: mutation.data,
    mutate: mutation.mutate,
		// isLoading,
		// refetch,
		// remove,
		// isFetched,

	};
};
