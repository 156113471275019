import styles from "./index.module.scss"
import { RadioButton } from "../../Form";
import { DocumentPayload, DocumentsTabType } from "../../../../types";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useLoadDocuments } from "../../../../service/api/queries/deals";
import { toastError } from "../../Toasts";
import { useLoadFiles } from "../../../../utils/hooks/helpers/useLoadFiles";
import { useDropzone } from "react-dropzone";
import { ReactComponent as IconFolderUp } from "../../../../assets/icons/iconDocumentFolderUp.svg";
import { ReactComponent as IconFolderDown } from "../../../../assets/icons/iconDocumentFolderDown.svg";
import cn from "classnames";
import { ReactComponent as IconPlusSmall } from "../../../../assets/icons/iconAddGray.svg";
import { ReactComponent as IconDownload } from "../../../../assets/icons/iconDownload20.svg";
import { ReactComponent as IconFile } from "../../../../assets/icons/iconFileSmall.svg";
import { useAddDocument } from "../../../../service/api/mutations";
import { useIsTelegram } from "../../../../utils/telegram/useIsTelegram";

type Props = {
  applicationId: string
}

const tabs: { label: string, value: DocumentsTabType }[] = [
  {label: "Продавец", value: "seller"},
  {label: "Покупатель", value: "buyer"},
  {label: "Общие", value: "common"}
]

export const Documents = (
  {
    applicationId,
  }: Props) => {
  const [tab, setTab] = useState<string | number>("seller")
  const [folder, setFolder] = useState<string>()
  const {control} = useForm()
  const {documents, refetchDocuments} = useLoadDocuments({applicationId, tab: tab as DocumentsTabType})

  useEffect(() => {
    refetchDocuments()
  }, [tab])

  const [isLoadingFile, setIsLoading] = useState(false);
  const onError = () => {
    toastError({
      text: `Не удалось загрузить файл`,
    });
    setIsLoading(false);
  };
  const {loadFiles} = useLoadFiles()
  const {addDocumentAsync} = useAddDocument({})

  let curFiles
  const useCreateDropzone = () =>
    useDropzone({
      noDrag: false,
      disabled: isLoadingFile,
      onDropRejected: onError,
      onDrop: async (acceptedFiles: File[]) => {
        setIsLoading(true);

        const acceptedSizes = acceptedFiles.filter((file) => {
          if (file.size > 5000000) {
            toastError({
              text: `Невозможно загрузить ${file.name}. Файл должен весить менее 5мб`,
            });
          }

          return file.size <= 5000000;
        });

        const files = acceptedSizes.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            fileType: "file" as "file" | "doc" | undefined,
          })
        );

        curFiles = await loadFiles(files);

        const addFile = async () => {
          const payload: DocumentPayload = curFiles.map(el => ({
            tab: tab as DocumentsTabType,
            url: el.url,
            folderName: folder
          }))
          setIsLoading(true)
          await addDocumentAsync({id: applicationId, payload})
          setIsLoading(false)
          refetchDocuments()
        }

        await addFile()
        setIsLoading(false)

      },
    });


  useEffect(() => {
    if (curFiles) {
      refetchDocuments()
    }
    setIsLoading(false)

  }, [curFiles])

  const fileDropzone = useCreateDropzone();

  const [foldersArray, setFoldersArray] = useState<Array<string>>([])
  const [open, setOpen] = useState<number>(0)
  let docFoldersArray: string[] = foldersArray

  const handleToggleOpenFolder = (name: string) => {
    if (!docFoldersArray.find(el => el === name)) {
      docFoldersArray = docFoldersArray.concat([name])
      setOpen(Math.random())
    } else {
      const index = docFoldersArray.findIndex(el => el === name)
      docFoldersArray.splice(index, 1)
      setOpen(Math.random())
    }
    setFoldersArray(docFoldersArray as any)
  }

  // const {REACT_APP_API_PROD_URL} = process.env
  //
  // const handleDownloadAll = (name: string) => {
  //   window.open(
  //     `${REACT_APP_API_PROD_URL}/deal/${applicationId}/downloadDocuments/${name}`)
  // }

  const {executeUrl} = useIsTelegram()

  const handleClickDownload = (url: string) => {
    executeUrl(`${url}`)
  }

  return (
    <div className={styles.root} id={`abcd${foldersArray.length}`}>
      <div className={styles.row}>
        <div className={styles.title}>
          Обязательные документы
        </div>
      </div>
      <div className={styles.content}>
        <div>
          <RadioButton
            name="tab"
            control={control}
            options={tabs}
            handleOnChange={(value) => {
              setTab(value)
            }}
            className={styles.button}
            currentValue={tab}
            canUnselect
          />
        </div>
        {documents && documents.folders.map((elem) => (
          <div className={styles.fileContainer}>
            <div className={styles.fileRow}>
              <div className={styles.fileRow} onClick={() => {
                handleToggleOpenFolder(elem.name)
              }}>
                <div className={styles.counterWrapper}>
                  <div className={styles.counter}>{elem.files.length ?? 0}</div>
                  {foldersArray.includes(elem.name) ? <IconFolderUp/> : <IconFolderDown/>}
                </div>
                <div className={styles.fileRowTitle}>{elem.name}</div>
              </div>
              <div className={styles.fileRow}>
                <div
                  id={elem.name}

                  style={{display: "flex"}}
                  {...fileDropzone.getRootProps()}
                  className={cn(styles.filesRoot, {
                    [ styles.isLoading ]: isLoadingFile,
                  })}
                >
                  <input {...fileDropzone.getInputProps()} />
                  <IconPlusSmall style={{marginRight: "8px"}} onClick={() => {
                    setFolder(elem.name)
                  }}/>
                </div>
                {/* <IconDownload onClick={() => handleDownloadAll(elem.name)}/> */}
              </div>
            </div>
            {foldersArray.includes(elem.name) ?
              <div className={styles.filesList}>
                {elem.files.map((item) => (
                  <div className={styles.fileItem} key={item.name}>
                    <div className={styles.fileRow}>
                      <IconFile style={{marginRight: "10px"}}/>
                      <span>
                        {item.name}
                      </span>
                    </div>
                    <IconDownload onClick={() => handleClickDownload(item.url)}/>
                  </div>
                ))}
                {elem.files.length === 0 &&
                  <div className={styles.plug}>
                    Файлов нет
                  </div>
                }
              </div>
              : <></>
            }
          </div>
        ))}
      </div>
    </div>
  )
}