import { useRouteMatch } from "react-router";
import { SidebarLayout } from "../../../UI";
import { RealEstateView } from "../mode";
import { useGetNavigation } from "../helpers";
import { useLoadParsedRealEstate } from "../../../../service/api/queries";

type MatchParams = {
  id: string;
};

type Props = {
  parsedRealEstateId?: string;
  refetchList?: () => void
};

export const ParsedRealEstatePageRender = (
  {
    parsedRealEstateId,
    refetchList,
  }: Props): JSX.Element => {

  const match = useRouteMatch<MatchParams>();
  const {id} = match.params;

  const PARSED_REAL_ESTATE_ID = parsedRealEstateId ?? id;

  const {realEstate, refetch} = useLoadParsedRealEstate({
    id: PARSED_REAL_ESTATE_ID,
  });

  const {blocksIds} = useGetNavigation({
    realEstate,
    isParser: true,
  });

  const parserChildren = (
    <RealEstateView
      id={PARSED_REAL_ESTATE_ID}
      blocksIds={blocksIds}
      realEstate={realEstate}
      refetch={refetch}
      refetchList={refetchList}
    />
  )

  return (
    <>
      {parserChildren}
    </>
  );
};

export const ParsedRealEstatePage = ({parsedRealEstateId, refetchList}: Props): JSX.Element => (
  <>
    {parsedRealEstateId ? (
      <ParsedRealEstatePageRender parsedRealEstateId={parsedRealEstateId} refetchList={refetchList}/>
    ) : (
      <SidebarLayout fullSize>
        <ParsedRealEstatePageRender refetchList={refetchList}/>
      </SidebarLayout>
    )}
  </>
);
