import { useMutation } from "react-query";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/servises/delete-order/DEFAULT_MN";

type Props = {
  onSuccess?: () => void;
};

export const useDeleteProductOrder = (
  {
    onSuccess
  }: Props) => {


  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: (id: string) => api.services.deleteProductOrder(id),
    onSuccess
  })

  return {
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
    isSuccess: mutation.isSuccess,
  }
}