import { useQuery } from "react-query";
import { api } from "../../api";

const DEFAULT_QN = "sdelka/application/load-filter-rope-templates-one-level/DEFAULT_QN";

export const useLoadFilterRopeTemplatesOneLevel = () => {

  const loadRopeTemplates = () => api.application.getRopeTemplatesOneLevel()

  const {data, refetch, remove, isLoading, isFetching} = useQuery([DEFAULT_QN], loadRopeTemplates, {
    keepPreviousData: true,
  })

  return {
    data: data?.data,
    isLoading,
    isFetching,
    refetch,
    remove
  }
}