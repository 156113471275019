import { useDispatch } from "react-redux";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useAddResponsibleModal } from "../../index";
import { UserList } from "../UserList";
import { useUpdateApplication } from "../../../../service/api/mutations";
import { closeModal } from "../../../../service/redux/modules/modal/modal.action";
import { KindType, StageConditions, User, UserListItem } from "../../../../types";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";
import { useLoadOffices, UseLoadUserPositions } from "../../../../service/api/queries";
import { useMemo } from "react";
import { prepareOffices } from "../../../../utils/transformation";

type Props = {
	scrollY?: number;
	applicationId?: string;
	stageConditions?: StageConditions;
	responsibleUser?: User;
	refetch: () => void;
	kind?: KindType
	atDeal?: boolean
	headTitle?: string
	withoutDots?: boolean
	withoutLinks?: boolean
	isMobile?: boolean
	withAvatar?: boolean
};

export const Responsibles = ({
	applicationId = "",
	scrollY,
	stageConditions,
	responsibleUser,
	refetch,
	kind,
	atDeal,
	headTitle,
	withoutDots,
	withoutLinks,
	isMobile,
	withAvatar
}: Props): JSX.Element => {
	const users = responsibleUser ? [responsibleUser] : [];

	const dispatch = useDispatch();

	const { mutate: mutateUpdateApplication } = useUpdateApplication({
		onSuccess: () => {
			refetch();
			dispatch(closeModal());
		},
		kind
	});

	const handleChangeResponsible = (id: string | null ) => {
		if (kind === KindType.DEAL) {
			mutateUpdateApplication({
				applicationId,
				applicationData: { lawyerUserId: id },
			});
		} else {
			mutateUpdateApplication({
				applicationId,
				applicationData: { responsibleUserId: id },
			});
		}
	};

	const handleDeleteResponsible = () => {
		handleChangeResponsible(null);
	};

	const disabled = stageConditions ? stageConditions.isLast : false;

	const { openAddResponsibleModal } = useAddResponsibleModal({
		title: "Выбрать ответственного",
		buttonLabel: "Добавить",
		onPin: ({ responsiblesIds: selectedResponsibles }) => handleChangeResponsible(selectedResponsibles[0]),
		checkedResponsibles: users?.filter((user) => user.id ?? false).map((user) => user.id as string),
		isPinOne: true,
	});

	const userList: UserListItem[] = users.map((respUser) => {
		const {
			account: { name, lastName, secondName, phone, avatar },
			position,
			id,
			type,
		} = respUser;

		return {
			id,
			avatar,
			name,
			lastName,
			secondName,
			position,
			type,
			phone,
		};
	});

	const title = headTitle || (atDeal ? "Агент" : "Ответственный")
	const buttonText = headTitle ? "Назначить юриста" : "Добавить ответственного"
	const { offices } = useLoadOffices({});
	const {getPositionName} = UseLoadUserPositions({});
	const options = useMemo(() => prepareOffices(offices, getPositionName), [offices.length]);

	return (
		<UserList
			title={title}
			applicationId={applicationId}
			users={userList}
			refetch={refetch}
			handleDelete={handleDeleteResponsible}
			scrollY={scrollY}
			isSuccess={false}
			stageConditions={stageConditions}
			atDeal={atDeal}
			withoutDots={withoutDots}
			withoutLinks={withoutLinks}
			mini={isMobile}
			withAvatar={withAvatar}
		>
			{(stageConditions ? !stageConditions.isNext : true) && (
				<>
					{!atDeal && options.length > 2 &&
						<Button Icon={PlusIcon} variant="text" disabled={disabled} onClick={openAddResponsibleModal}>
							{buttonText}
					</Button>
					}
				</>
			)}
		</UserList>
	);
};
