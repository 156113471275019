import { useEffect } from "react";
import { useRouteMatch } from "react-router";
import { ObjectPhotoGallery } from "../../../../../index";
import { useLoadApplicationRealEstate } from "../../../../../../../service/api/queries";
import styles from "./index.module.scss";
import { RealEstatePhoto } from "../../../../../../../types";

type MatchParams = {
  id: string;
};

type Props = {
  applicationId?: string;
  photos?: RealEstatePhoto[],
  setPhotos?: React.Dispatch<React.SetStateAction<RealEstatePhoto[]>>
  isCreation?: boolean
};

export const Gallery = (
  {
    applicationId,
    setPhotos,
    photos,
    isCreation
  }: Props): JSX.Element => {
  const match = useRouteMatch<MatchParams>();
  const {id} = match.params;

  const {realEstate, remove, refetch} = useLoadApplicationRealEstate({
    id: applicationId ?? id,
  });

  useEffect(() => {
    if (id === "new") {
      remove();
    }
  }, [id]);

  return (
    <div className={styles.root}>
        <h3>Фотографии и тех. план</h3>
        <ObjectPhotoGallery
          realEstate={realEstate}
          refetch={refetch}
          setPhotos={setPhotos}
          photos={photos}
          alwaysOpen
          isCreation={isCreation}
        />
      </div>
  );
};
