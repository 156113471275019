import { useReloadPublicLink, useUpdatePublicLink } from "../../../../../../service/api/mutations";
import { useLoadPublicLink } from "../../../../../../service/api/queries";
import { invitationUrlBase } from "../../../../../../utils/constants";
import { toastSuccess } from "../../../../../UI";
import { useCopyText } from "../../../../../../utils/hooks";

import { ReactComponent as UnlockIcon } from "../../../../../../assets/icons/lock-outlined.svg";
import { ReactComponent as LockIcon } from "../../../../../../assets/icons/lock-open.svg";
import { ReactComponent as Copy } from "../../../../../../assets/icons/copy.svg";
import { ReactComponent as ReloadIcon } from "../../../../../../assets/icons/reload.svg";
import styles from "./index.module.scss";

// Invitation params (change state and other manipulations)
export const GeneralInvite = (): JSX.Element => {
	const SUCCESS_COPY_TEXT = "Ссылка скопирована";

	const { onCopy } = useCopyText({ successToastText: SUCCESS_COPY_TEXT });

	const { publicLink, refetch } = useLoadPublicLink();

	const updatePublicLink = useUpdatePublicLink({
		onSuccess: () => refetch(),
	});

	const onSuccessReload = () => {
		refetch();
		toastSuccess({
			text: "Ссылка обновлена",
		});
	};

	const reloadPublicLink = useReloadPublicLink({
		onSuccess: onSuccessReload,
	});

	const changeLinkStatus = () => {
		const payload = {
			departmentId: publicLink?.departmentId,
			positionId: publicLink?.positionId,
			active: !publicLink?.active,
		};

		updatePublicLink.mutate(payload);
	};

	const buttons = [
		{
			key: 1,
			onClick: () => onCopy(`${invitationUrlBase}${publicLink?.link}`),
			children: (
				<>
					<div className={styles.buttonWrapper}>
						<Copy />
					</div>
					Копировать ссылку
				</>
			),
		},
		{
			key: 2,
			onClick: changeLinkStatus,
			children: (
				<>
					<div className={styles.buttonWrapper}>{publicLink?.active ? <LockIcon /> : <UnlockIcon />}</div>
					{publicLink?.active ? "Временно заблокировать" : "Разблокировать"}
				</>
			),
		},
		{
			key: 3,
			onClick: () => reloadPublicLink.mutate(),
			children: (
				<>
					<div className={styles.buttonWrapper}>
						<ReloadIcon />
					</div>
					Обновить ссылку
					<span className={styles.updateSubTitle}>Текущая ссылка станет недействительной</span>
				</>
			),
		},
	];

	return (
		<div className={styles.root}>
			<span className={styles.title}>Общее приглашение</span>

			<input type="text" className={styles.href} readOnly value={`${invitationUrlBase}${publicLink?.link}`} />

			{buttons.map((button) => (
				<button key={button.key} type="button" className={styles.button} onClick={button.onClick}>
					{button.children}
				</button>
			))}
		</div>
	);
};
