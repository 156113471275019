import { useQuery } from "react-query";

import { api } from "../../api";

export const useContact = (id: string) => {
	const loadContact = () => api.contacts.get({ id });

	const { data, isFetching, refetch, remove } = useQuery("loadContact", loadContact, { enabled: !!id });

	return {
		contact: data?.data,
		isFetching,
		refetch,
		remove,
	};
};
