import { useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { H2, Button, LoadByScroll } from "@sdelka_crm/sdelka-crm-component-library";

import { ObjectAdGlobalStatistics } from "../../index";
import { AdPlatformsTable } from "../AdPlatformsTable";
import { HiddenWidget } from "../HiddenWidget";
import { closeDrawer } from "../../../../service/redux/modules/drawer/drawer.action";
import { useBlockAction } from "../helpers";
import { StageConditions } from "../../../../types";

import styles from "./index.module.scss";

type Props = {
	scrollY: number;
	stageConditions?: StageConditions;
	applicationId: string;
};

export const ObjectAd = ({ scrollY, applicationId, stageConditions }: Props): JSX.Element => {
	const [enabled, setEnabled] = useState(false);
	const { checkAllow } = useBlockAction({
		isLast: stageConditions ? stageConditions.isLast : false,
	});
	const dispatch = useDispatch();
	const { push, location } = useHistory();

	const pushToAd = () =>
		checkAllow(() => {
			dispatch(closeDrawer());
			setTimeout(() => {
				const encodedBackPath = encodeURIComponent(`${location.pathname}${location.search}`);

				push(`/real-estate/application/${applicationId}?mode=ad&backPath=${encodedBackPath}`);
			}, 5);
		});

	return (
		<div className={styles.root}>
			<H2>Информация о рекламе</H2>

			{stageConditions && stageConditions.isNext ? (
				<HiddenWidget />
			) : (
				<>
					<LoadByScroll scrollY={scrollY} height={250} onInView={() => setEnabled(true)} isLoading={!enabled}>
						{enabled && (
							<>
								<div>
									<ObjectAdGlobalStatistics small title="Общая статистика" applicationId={applicationId} />
								</div>

								<div className={styles.table}>
									<AdPlatformsTable enabled={enabled} applicationId={applicationId} />
								</div>
							</>
						)}
					</LoadByScroll>

					<div className={styles.buttonContainer}>
						<Button onClick={pushToAd}>Управлять рекламой</Button>
					</div>
				</>
			)}
		</div>
	);
};
