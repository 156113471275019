import cn from "classnames";
import { intervalToDuration } from "date-fns";

import { getDaysBetweenDates } from "../../../utils/date";

import { ReactComponent as ClockTimerIcon } from "../../../assets/icons/clock-timer.svg";
import { ReactComponent as FireIcon } from "../../../assets/icons/fire.svg";
import { ReactComponent as PoopIcon } from "../../../assets/icons/poop.svg";
import styles from "./index.module.scss";

const daysLeftTemplateString = (str: string | number, time = "д") => `Осталось ${str} ${time}.`;

export type AdStatusProps = {
	startDate: Date;
	endDate: Date;
	classNames?: {
		root?: string;
	};
};

const statusObjMap: any = (days: number, interval: Record<string, number>) => {
	let val = days || interval.days;
	let time = "д";

	if (val) {
		let status = "ok";
		if (val < 7 && val > 3) {
			status = "warn";
		}

		return [status, <ClockTimerIcon />, daysLeftTemplateString(Math.floor(val), time)];
	}

	if (!val && (interval.hours || interval.minutes || interval.seconds)) {
		if (interval.hours) {
			time = "ч";
			val = interval.hours;
		} else if (interval.minutes) {
			time = "м";
			val = interval.minutes;
		} else if (interval.seconds) {
			time = "c";
			val = interval.seconds;
		}

		return ["lastDays", <FireIcon />, daysLeftTemplateString(Math.floor(val), time)];
	}

	return ["end", <PoopIcon />, "Реклама закончилась"];
};

export const AdStatus = ({ classNames, startDate, endDate }: AdStatusProps): JSX.Element => {
	const start = new Date(startDate);
	const end = new Date(endDate);

	const interval = intervalToDuration({ start, end });

	const [status, Icon, textFunc] = statusObjMap(getDaysBetweenDates(start, end), interval);

	return (
		<div className={cn(styles.root, styles[status], classNames?.root)}>
			{Icon}

			<span>{textFunc}</span>
		</div>
	);
};
