import ReactDOM from "react-dom";
import { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { ButtonCloseBlack, ButtonMap, FixedNavHorizontal, FixedNavVertical, FixedNavVerticalMenu } from "../../index";
import { DrawerInDrawer } from "../DrawerInDrawer";
import { DrawerWrapper } from "./DrawerWrapper";
import {
  changeCurrentTab,
  changeDrawerScrolled,
  closeDrawer
} from "../../../../service/redux/modules/drawer/drawer.action";
import { selectDevice, selectDrawer, useSelect } from "../../../../service/redux/selectors";
import { useFps, useQueryParams } from "../../../../utils/hooks";
import styles from "./index.module.scss";

const CLOSE_BUTTON_SKIP = 64;
const NO_SCROLL_DRAWER = "drawer-no-scroll";
const LS_PERF_KEY = "drawer-performance";

const useDrawerCss = (width: number, yNavigation?: Omit<FixedNavVerticalMenu, "id" | "changeTab">[]) => {
  const cssStyles: CSSProperties = {};
  const iconStyles: CSSProperties = {};
  const navStyles: CSSProperties = {animationDuration: "1s"};
  let navTop = 0;

  if (width) {
    const offsetForTabs = 55;
    navStyles.left = "auto";
    navStyles.right = `${width}px`;
    cssStyles.width = `${width}px`;
    iconStyles.right = `${width + offsetForTabs - 48}px`;
  }

  if (yNavigation) {
    navTop = CLOSE_BUTTON_SKIP;
  }

  return {navStyles, cssStyles, iconStyles, navTop};
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Drawer = (): JSX.Element => {
  const dispatch = useDispatch();

  const {
    children,
    isOpen,
    width = 800,
    onClose,
    yNavigation,
    classNames,
    drawerInDrawer,
    disableClose,
    hasMap,
    noRemoveQuery,
    changeTab,
  } = useSelect(selectDrawer);

  const [currentYTab, setCurrentYTab] = useState(0);
  const [performance, setPerformance] = useState<"high" | "low">("high");
  const fpsDropCount = useRef(0);
  const {isMobile} = useSelect(selectDevice)

  const mobileWidth = isMobile ? 500 : width

  useFps({
    onTick: (fps) => {
      if (localStorage.getItem(LS_PERF_KEY) === "low") {
        setPerformance("low");
        return;
      }

      const minimalSatisfyingFps = 35;
      const maximumFpsDrops = 5;
      const fpsDropped = fps < minimalSatisfyingFps;
      fpsDropCount.current += fpsDropped ? 1 : 0;
      const fpsDropTooOften = fpsDropCount.current > maximumFpsDrops;

      if (fpsDropTooOften) {
        setPerformance("low");
        localStorage.setItem(LS_PERF_KEY, "low");
      }
    },
    enabled: isOpen,
  });

  const queryNames = yNavigation?.navigation.map(el => ({
    name: el.query?.name || ""
  })).concat({name: "mode"})

  const queries = yNavigation?.navigation.map(el => el.query?.name || "").concat("mode")

  const {removeQueryParam} = useQueryParams((queryNames || []));

  const drawerYNavigation = useMemo(() => {
    if (!yNavigation) {

      if (!drawerInDrawer?.yNavigation) {
        return undefined;
      }
      return drawerInDrawer.yNavigation.navigation.map((nav, index) => ({
        ...nav,
        id: index,
        changeTab: () => {
          setCurrentYTab(index);
        },
      }));
    }
    return yNavigation.navigation.map((nav, index) => ({
      ...nav,
      id: index,
      changeTab: () => {
        setCurrentYTab(index);
        dispatch(changeCurrentTab({newCurrentTab: index}))
      },
    }));
  }, [yNavigation]);

  useEffect(() => {
    setCurrentYTab(yNavigation?.initialTab || 0);
  }, [yNavigation?.initialTab, yNavigation]);

  useEffect(() => {
    setCurrentYTab(changeTab || 0)
  }, [changeTab])

  const {cssStyles, iconStyles, navStyles, navTop} = useDrawerCss(mobileWidth, yNavigation?.navigation);

  useEffect(() => {
    if (isOpen) {
      const innerBody = document.querySelector("body");
      innerBody?.classList.add(NO_SCROLL_DRAWER);
    }

    return () => {
      const innerBody = document.querySelector("body");
      innerBody?.classList.remove(NO_SCROLL_DRAWER);
    };
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen && drawerInDrawer) {
      throw Error("You need parent Drawer to call DrawerInDrawer component");
    }
  }, [isOpen, drawerInDrawer]);

  const handleCloseDrawer = () => {
    if (!disableClose) {
      dispatch(changeCurrentTab({newCurrentTab: 0}))
      dispatch(closeDrawer());
    }

    if (!noRemoveQuery) {
      removeQueryParam(queries || [])
    }

    if (onClose) {
      onClose();
    }
  };

  const scroll = document.getElementById("drawer-right-block")

  setTimeout(() => {
    if (scroll !== undefined && scroll !== null) {
      const listener = () => {
        if (scroll.offsetHeight + scroll.scrollTop >= scroll.scrollHeight - (scroll.scrollHeight / 7)) {
          dispatch(changeDrawerScrolled({scrolled: true}))
        }
      }
      scroll.addEventListener("scroll", listener)

      // eslint-disable-next-line consistent-return
      return () => {
        dispatch(changeDrawerScrolled({scrolled: false}))
        scroll.removeEventListener("scroll", listener)
      }
    }
  }, 100)

  return ReactDOM.createPortal(
    <>
      {isOpen && (
        <div
          className={cn(styles.root, "animate__animated", "animate__faster", "animate__fadeIn", {
            [ styles.highPerf ]: performance === "high",
            [ styles.lowPerf ]: performance === "low",
          })}
        >
          {isMobile &&
            <FixedNavHorizontal
              onCloseClick={handleCloseDrawer}
              navigation={drawerYNavigation}
              activeTab={currentYTab}
              disableClose={disableClose}
            />
          }
          <DrawerWrapper wrapperClassName={cn(classNames?.wrapper)} performance={performance} isMobile={isMobile}>
            {!isMobile && !disableClose &&
              <ButtonCloseBlack
                onCloseClick={handleCloseDrawer}
                classNames={{root: styles.close}}
                jsStyles={iconStyles}
              />
            }
            {hasMap &&
              <div className={styles.buttonMap}>
                <ButtonMap/>
              </div>
            }
            {drawerInDrawer && (
              <DrawerInDrawer
                width={drawerInDrawer.width}
                parentWidth={width}
                onClose={drawerInDrawer.onClose}
                withCloseArrow={drawerInDrawer.withCloseArrow}
                yNavigation={drawerInDrawer.yNavigation}
                changeTab={drawerInDrawer.changeTab}
              >
                {drawerInDrawer.children}
              </DrawerInDrawer>
            )}
            {yNavigation && drawerYNavigation && !isMobile ? (
              <FixedNavVertical
                style={navStyles}
                navigation={drawerYNavigation}
                top={navTop}
                activeTab={currentYTab}
              />
            ) : null}
            <div
              id="drawer-right-block"
              style={cssStyles}
              className={cn(styles.rightBlock, classNames?.rightBlock, {
                [ styles.withShadow ]: drawerInDrawer,
              })}
            >
              {Array.isArray(children) ? children[ currentYTab ] : children}
            </div>
          </DrawerWrapper>
        </div>
      )}
    </>,
    document.body
  );
};
