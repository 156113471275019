import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Office } from "../../../../../../../types";
import { IndividualInput } from "../../../../../../UI";
import { useOfficeUpdate } from "../../../../../../../service/api/mutations";
import { commonErrors } from "../../../../../../../utils/errors";

import { ReactComponent as EmployerIcon } from "../../../../../../../assets/icons/employer.svg";
import styles from "./index.module.scss";

type Props = {
	refetchOffices: () => void;
	office: Office;
};

export const OfficeNameForm = ({ refetchOffices, office }: Props): JSX.Element => {
	const totalEmployers = useMemo(() => {
		const arrayOfUsers = office.departments.map((department) => department.users?.length || 0);

		return arrayOfUsers.reduce((a, b) => a + b, 0);
	}, [office]);

	const schema = yup.object().shape({
		name: yup.string().required(commonErrors.required).max(30, "Слишком длинное название"),
	});

	const { register, handleSubmit, setError, formState } = useForm<Office>({
		resolver: yupResolver(schema),
		defaultValues: {
			name: office.name,
		},
	});

	const { mutate, isLoading } = useOfficeUpdate({
		onSuccess: () => refetchOffices(),
		setError,
	});

	const onSubmit = (values: Office) => {
		const payload = {
			...values,
			id: office.id,
		};

		mutate(payload);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<IndividualInput
				name="name"
				defaultValue={office.name}
				isLoading={isLoading}
				register={register}
				error={formState.errors.name?.message?.toString()}
				options={{
					displayInsteadOfValue: (
						<div key={office.id} className={styles.officeName}>
							<div className={styles.officeNameRow}>
								{office.name}
								<div className={styles.employers}>
									<EmployerIcon />
									{totalEmployers}
								</div>
							</div>
						</div>
					),
				}}
			/>
		</form>
	);
};
