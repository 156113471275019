import { RealEstateSchemaConfigField, FormPropsEditRealEstate } from "../../../../../../../../../../types";

import { InfoRow, Select } from "../../../../../../../../index";
import { RequiredDots } from "../../RequiredDots";
import { commonPlaceholders } from "../../../../../../../../../../utils/placeholders";

import styles from "./index.module.scss";
import { NumberInput } from "../NumberInput";

type Props = {
	form: FormPropsEditRealEstate;
	field: RealEstateSchemaConfigField;
};

export const NumberWithUnit = ({ form, field }: Props): JSX.Element => {
	const {
		label,
		reqiredFor,
		dataType: { nameGroup },
	} = field;
	const { register, setValue, watch } = form;

	if (!nameGroup) {
		return <></>;
	}

	const inputName = nameGroup[0];
	const selectName = nameGroup[1];

	const selectWatcher = watch(selectName);

	const unitOptions = [
		{ value: "сотка", label: "сот" },
		{ value: "гектар", label: "га" },
	];

	return (
		<InfoRow
			label={label}
			labelFont="ubuntu"
			aspect={{ label: 1, content: 1 }}
			classNames={{ root: styles.customInfoRow }}
		>
			<RequiredDots required={reqiredFor} />

			<div className={styles.inputsContainer}>
				<NumberInput
					form={form}
					field={field}
					name={inputName}
					placeholder={commonPlaceholders.notSpecified}
					onlyPositive
				/>

				<div className={styles.selectContainer}>
					<div style={{ width: 100 }}>
						<Select
							name={selectName}
							defaultValue={selectWatcher || unitOptions[0].value}
							options={unitOptions}
							register={register}
							setValue={setValue}
							size="small"
						/>
					</div>
				</div>
			</div>
		</InfoRow>
	);
};
