import { useMutation } from "react-query";
import { AxiosResponse } from "axios";
import { CompilationUpdateFilterPayload } from "../../../../types";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/compilation/update-compilation-filter/DEFAULT_MN";

type UseUpdateCompilationFilter = {
	onSuccess?: (res?: AxiosResponse<any>) => void;
	onError?: () => void;
};

export const useUpdateCompilationFilter = ({ onSuccess, onError }: UseUpdateCompilationFilter) => {
	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: ({ applicationId, filterData }: CompilationUpdateFilterPayload) =>
			api.compilations.updateFilter({ applicationId, filterData }),
		onSuccess,
		onError,
	});

	return {
		mutate: mutate.mutate,
		isLoading: mutate.isLoading,
		isSuccess: mutate.isSuccess,
	};
};
