import { useMutation } from "react-query";
import { api } from "../../api";
import { AxiosResponse } from "axios";
import { ReportsResponse } from "../../../../types";

const DEFAULT_MN = "sdelka/dashboard/download-reports/DEFAULT_MN";

type UseDownloadReports = {
  onSuccess?: (res: AxiosResponse<ReportsResponse>) => void;
};

export const useDownloadReports = ({onSuccess}: UseDownloadReports) => {
  const mutate = useMutation(DEFAULT_MN, {
    mutationFn: ({
                   type,
                   dateTo,
                   dateFrom
                 }: { type: string, dateFrom: Date, dateTo: Date }) => api.dashboard.downloadReports(type, dateFrom, dateTo),
    onSuccess,
  });

  return {
    data: mutate.data?.data,
    mutate: mutate.mutate,
    isLoading: mutate.isLoading,
  };
};
