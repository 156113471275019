import styles from "./index.module.scss";

export const AlertBlock = (): JSX.Element => (
  <div className={styles.root}>
    <h2>Внимание:</h2>
    <p>
      В соглашении используются данные, указанные в Контакте: Основной телефон, ФИО, Паспортные данные
    </p>
    <p>
      В соглашении используются данные, указанные в Объекте: Кадастровый номер, Адрес объекта, Номер квартиры
    </p>

  </div>
);
