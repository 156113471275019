import styles from "./index.module.scss"
import { Popup, toastError } from "../../../../../../../../../../../../../UI";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { MenuItem } from "../../../../../../../../../../../../ShowsAndViews/components/card-actions/CardActions";
import { ReactComponent as Warning } from "../../../../../../../../../../../../../../assets/icons/iconInfoSmall.svg";
import { ReactComponent as IconCancel } from "../../../../../../../../../../../../../../assets/icons/iconClear.svg";
import { ReactComponent as AddInCart } from "../../../../../../../../../../../../../../assets/icons/Shopping_Cart.svg";
import cn from "classnames";
import { GuaranteePaymentEnum, KindType } from "../../../../../../../../../../../../../../types";
import { useOpenCancelApplicationModal } from "./cancel-modal/useOpenCancelApplicationModal";
import {
  useGetFromArchive
} from "../../../../../../../../../../../../RealEstatePage/mode/RealEstateView/components/stickyFooter/helpers/useGetFromArchive";
import { useApplyServiceModal } from "../apply-service-modal";
import { useConfirmCreateDealModal } from "./confirm-create-deal-modal/useConfirmCreateDealModal";
import { useCancelDealModal } from "./cansel-deal-modal/useCancelDealModal";
import { useFinishDealModal } from "./finish-deal-modal/useFinishDealModal";
import {
  ReactComponent as IconDots
} from "../../../../../../../../../../../../../../assets/icons/iconDotsInCircle.svg";
import { selectDrawer, useSelect } from "../../../../../../../../../../../../../../service/redux/selectors";
import { useAddEditComponent, useChangeTab } from "../../../../../../../../../../../../../../service/servise";
import {
  useOpenCreateShowModal
} from "../../../../../../../../../../../../ShowsAndViews/components/sticky-footer/create-show-view-modal";
import { useCompare } from "../../../../../../../../../../../../../../utils/hooks";
import {
  ReactComponent as IconHouseShow
} from "../../../../../../../../../../../../../../assets/icons/iconHouseShow.svg";
import { ReactComponent as IconEdit } from "../../../../../../../../../../../../../../assets/icons/iconEdit.svg";
import {
  ReactComponent as IconCompetitorAnalysis
} from "../../../../../../../../../../../../../../assets/icons/iconCompetitorAnalysis.svg";


type Props = {
  applicationId: string
  kind: KindType | undefined
  whoIsClient?: "seller" | "buyer"
  refetch: () => void
  applicationPublicLink?: string
  isArchive?: boolean
  isMobile?: boolean
  status?: string
  guaranteePaymentStatus?: GuaranteePaymentEnum
  inDrawerInDrawer?: boolean;
}

export const StickyFooterApplication = (
  {
    applicationId,
    kind,
    whoIsClient,
    refetch,
    guaranteePaymentStatus,
    isArchive,
    isMobile,
    status,
    inDrawerInDrawer
  }: Props): JSX.Element => {

  const {openFinishDealModal} = useFinishDealModal({applicationId, refetch})

  const {openCancelDealModal} = useCancelDealModal({applicationId})

  const buttonText = kind === KindType.APPLICATION ? "Создать сделку" : "Завершить сделку"
  const warningText = whoIsClient === "seller"
    ? "Прикрепите заявку на покупку, чтобы начать сделку"
    : "Прикрепите заявку на продажу, чтобы начать сделку"

  const {openApplyServiceModal} = useApplyServiceModal({applicationId, isMobile})

  const {handleCancelApplicationModal} = useOpenCancelApplicationModal({applicationId, whoIsClient, isMobile, refetch})

  const {yNavigation} = useSelect(selectDrawer)
  const {addEditComponent} = useAddEditComponent({atDrawerInDrawer: inDrawerInDrawer})
  const {changeTab} = useChangeTab({atDrawerInDrawer: inDrawerInDrawer})

  const openEdit = () => {
    if (yNavigation && !yNavigation.navigation.find(el => el.title === "edit")) {
      addEditComponent({
        id: applicationId,
        refetch,
        enabled: true
      })
    }
    if (yNavigation && yNavigation.navigation.find(el => el.title === "edit")) {
      changeTab({title: "edit"})
    }
  }

  const {openCreateShowModal} = useOpenCreateShowModal({
    applicationId, refetchList: () => {
    }
  })

  const {openObjectCompare} = useCompare({applicationId});

  const actionsMenu: MenuItem[] = [
    {
      label: "Назначить просмотр",
      icon: <IconHouseShow/>,
      onClick: openCreateShowModal,
      id: 6,
    },
    {
      label: "Редактировать объект",
      icon: <IconEdit/>,
      onClick: () => {
        openEdit()
      },
      id: 5,
    },
    {
      label: "Анализ конкурентов",
      icon: <IconCompetitorAnalysis/>,
      onClick: openObjectCompare,
      id: 3,
    },
    {
      label: "Создать заказ",
      icon: <AddInCart/>,
      onClick: openApplyServiceModal,
      id: 2
    },
    {
      label: "Отказаться от заявки",
      icon: <IconCancel/>,
      onClick: handleCancelApplicationModal,
      id: 5,
      className: "1"
    },
  ]

  const actualMenu = actionsMenu

  const {openConfirmCreateDealModal} = useConfirmCreateDealModal({applicationId, inDrawerInDrawer})

  const mainButtonAction = () => {
    if (kind === KindType.APPLICATION) {
      if (guaranteePaymentStatus === "active") {
        openConfirmCreateDealModal()
      } else {
        toastError({text: "Нельзя начать сделку без противоположной заявки на продажу-покупку"})
      }
    } else {
      openFinishDealModal()
    }
  }
  const {handleGetFromArchive} = useGetFromArchive(applicationId, false, whoIsClient === "seller")

  return (
    <div className={styles.bottoms}>
      {kind === KindType.APPLICATION && !isArchive &&
        <>
          {!isMobile &&
            <div className={styles.row}>
              <div>
                <Warning/>
              </div>
              <div className={styles.warning}>
                {warningText}
              </div>
            </div>
          }
          <div className={styles.actions}>
            <Popup
              openTrigger={isMobile ? <IconDots/> : <div className={styles.openTrigger}>Другие действия</div>}
              options={{withArrow: true}}
              classNames={
                {
                  trigger: styles.clicked,
                  arrow: styles.arrow,
                  card: styles.popupCard
                }
              }
            >
              {actualMenu.map((item) => (
                <div key={item.id} className={cn(
                  styles.actionItem,
                  {[ styles.deleteActionItem ]: item.className !== undefined}
                )} onClick={item.onClick}>
                  {item.icon}
                  <span>{item.label}</span>
                </div>
              ))}
            </Popup>
          </div>
        </>
      }
      {status !== "SUCCESSFULLY" && <>
        {kind === KindType.DEAL && !isArchive &&
          <div className={styles.deleteActionItem} onClick={openCancelDealModal}>
            <IconCancel/>
            <div>Сделка сорвалась</div>
          </div>
        }
        {!isArchive && status !== "onDeal" &&
          <Button onClick={mainButtonAction}>
            {buttonText}
          </Button>
        }
      </>
      }
      {isArchive &&
        <Button onClick={() => handleGetFromArchive(applicationId)}>
          Создать лид
        </Button>
      }
    </div>
  )
}