import { Client } from "../../Client";
import { CallList, GetCallList } from "../../../../types";

export const CallService = {
	getCallsBetweenPhones: (phones: [string, string], { take, skip }: GetCallList) => {
		const [contactPhone, userPhone] = phones;
		const searchConditions = [
			{
				filterType: "and",
				conditions: [
					{
						field: "caller",
						operator: "eq",
						value: contactPhone,
					},
					{
						field: "answer",
						operator: "eq",
						value: userPhone,
					},
				],
			},
			{
				filterType: "and",
				conditions: [
					{
						field: "caller",
						operator: "eq",
						value: userPhone,
					},
					{
						field: "answer",
						operator: "eq",
						value: contactPhone,
					},
				],
			},
		];

		return Client.post<CallList>(
			"/call/filter",
			JSON.stringify({
				search: {
					filterType: "or",
					conditions: searchConditions,
				},
				join: [],
				take,
				skip,
			})
		);
	},

	getCallsWithPhone: (phone: string, { take, skip }: GetCallList) => {
		const searchConditions = [
			{
				field: "caller",
				operator: "eq",
				value: phone,
			},
			{
				field: "answer",
				operator: "eq",
				value: phone,
			},
		];

		return Client.post<CallList>(
			"/call/filter",
			JSON.stringify({
				search: {
					filterType: "or",
					conditions: searchConditions,
				},
				join: [],
				take,
				skip,
			})
		);
	},
};
