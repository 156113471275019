import styles from "./index.module.scss";

export const HiddenWidget = (): JSX.Element => (
	<div className={styles.root}>
		<div className={styles.filter} />
		<div className={styles.wrapper}>
			<div>
				<h2>Виджет скрыт</h2>
				<div>Для просмотра завершите действия на прошлом этапе и перейдите на текущий</div>
			</div>
		</div>

		<div className={styles.skeleton}>
			<div className={styles.firstLine}>
				<div>Xxxxxxxxx</div>
				<div>Xxx</div>
				<div>Xxxxxxxxx</div>
			</div>

			<div className={styles.secondLine}>
				<div>Xxxxxxxxxxxxxxxxx</div>
				<div>Xxxxxxxx</div>
			</div>

			<div className={styles.thirdLine}>
				<div>Xxxxxx</div>
				<div>XXXX</div>
				<div>XXXXXXXXXXX</div>
			</div>
		</div>
	</div>
);
