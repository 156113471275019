import cn from "classnames";

import styles from "./index.module.scss";

type Props = {
	planned: number;
	canceled: number;

};

export const InfoTable = ({ planned, canceled,  }: Props): JSX.Element => {
	const columns: {
		label: string;
		value: number;
		className: string | undefined;
	}[][] = [
		[
			{ label: "Запланированные", value: planned, className: styles.orange },

		],
		[
			{ label: "Отмененные", value: canceled, className: undefined },

		],
	];

	return (
		<div className={styles.columnsWrapper}>
			{columns.map((column, index) => (
				// eslint-disable-next-line react/no-array-index-key
				<div key={`info-table-column-${index}`} className={styles.column}>
					{column.map((info) => (
						<div key={info.label} className={styles.infoContainer}>
							<div className={styles.label}>{info.label}</div>

							<div className={cn(styles.value, info.className)}>{info.value}</div>
						</div>
					))}
				</div>
			))}
		</div>
	);
};
