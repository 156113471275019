import styles from "./index.module.scss"
import { useLoadAppliedServicesHistory } from "../../../../service/api/queries";
import { CardProductForTable } from "../../Card";
import { Plug } from "../helpers";
import { useIsTelegram } from "../../../../utils/telegram/useIsTelegram";

type Props = {
  applicationId: string
  isMobile?: boolean
}

export const AppliedServicesHistory = (
  {
    applicationId,
    isMobile
  }: Props): JSX.Element => {

  const {list, refetch} = useLoadAppliedServicesHistory({applicationId})

  const plugText = (<div className={styles.text}>У вас нет заказов по данной заявке</div>)

  const {executeUrl} = useIsTelegram()

  const openOrder = (id: string) => {
    executeUrl(`/services?tab=ORDERS&orderId=${id}`)
  }

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        Заказы по заявке
      </div>
      <div className={styles.col}>
        {list && list.length > 0 ? list.map((item => (
            <div className={styles.item} key={item.id} onClick={() => openOrder(item.id)}>
              <CardProductForTable
                product={item}
                refetch={refetch}
                type="applied"
                mini={isMobile}
              />
            </div>
          )))
          : <Plug plugText={plugText} mini={isMobile}/>
        }
      </div>
    </div>
  )
}