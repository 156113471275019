import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import { closeDrawer } from "../../../service/redux/modules/drawer/drawer.action";

type Props = {
	applicationId: string;
};

type Return = {
	openObjectCompare: () => void;
};

type UseCompare = (props: Props) => Return;

export const useCompare: UseCompare = ({ applicationId }) => {
	const dispatch = useDispatch();
	const { push, location } = useHistory();

	const openObjectCompare = () => {
		const encodedBackPath = encodeURIComponent(`${location.pathname}${location.search}`);

		dispatch(closeDrawer());
		push(`/real-estate?comparedApplicationId=${applicationId}&backPath=${encodedBackPath}`);
	};

	return {
		openObjectCompare,
	};
};
