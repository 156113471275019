import * as yup from "yup";

import { commonErrors } from "../../../../../../utils/errors";
import { ContactPinDefaultValues } from "../../../../../../types";

type Props = {
  defaultValues?: ContactPinDefaultValues;
};

const text =  'Заполните одно из: Фамилия, Имя, Отчество, Псевдоним'

export const useSchemaResolver = ({defaultValues}: Props) => {
  const schema = yup.object().shape({
    phone: yup
      .string()
      .test("phone", "Невалидный телефон", (phone) => {
        if (defaultValues?.phone) {
          return phone?.replace(/\D/gi, "").length === 10;
        }
        return phone?.replace(/\D/gi, "").length === 11;
      })
      .required(commonErrors.required),
    pseudonym: yup
      .string()
      .test(
        'oneOfRequired',
        text,
        function (item) {
          return (this.parent.pseudonym || this.parent.name || this.parent.lastName || this.parent.secondName)
        }
      ),
    name: yup
      .string()
      .test(
        'oneOfRequired',
        text,
        function (item) {
          return (this.parent.pseudonym || this.parent.name || this.parent.lastName || this.parent.secondName)
        }
      ),
    secondName: yup
      .string()
      .test(
        'oneOfRequired',
        text,
        function (item) {
          return (this.parent.pseudonym || this.parent.name || this.parent.lastName || this.parent.secondName)
        }
      ),
    lastName: yup
      .string()
      .test(
        'oneOfRequired',
        text,
        function (item) {
          return (this.parent.pseudonym || this.parent.name || this.parent.lastName || this.parent.secondName)
        }
      ),
    // name: yup
    // 	.string()
    // 	.required(commonErrors.required)
    // 	.max(30, "Слишком длинное имя")
    // 	.matches(/^[a-zA-Zа-яА-Я]+$/, commonErrors.onlyLetters),
    // lastName: yup
    // 	.string()
    // 	.required(commonErrors.required)
    // 	.max(30, "Слишком длинная фамилия")
    // 	.matches(/^[a-zA-Zа-яА-Я]+$/, commonErrors.onlyLetters),
    // secondName: yup
    // 	.string()
    // 	.max(30, "Слишком длинное отчество")
    // 	.matches(/^[a-zA-Zа-яА-Я]*$/, commonErrors.onlyLetters),
    type: yup.string().test("type", commonErrors.required, (type) => {
      if (defaultValues?.type) {
        return true;
      }
      return !!type;
    }),
  });

  return {
    schema,
  };
};

