import { useQuery } from "react-query";

import { api } from "../../index";

type UseLoadCompanyInvites = {
	enabled?: boolean;
};
export const useLoadCompanyInvites = ({ enabled = true }: UseLoadCompanyInvites) => {
	const loadCompanyInvites = () => api.invites.companyInvites();

	const { data, isLoading, refetch } = useQuery("loadCompanyInvites", loadCompanyInvites, { enabled });

	return {
		invites: data?.data || [],
		isLoading,
		refetch,
	};
};
