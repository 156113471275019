import {
	GroupByDetalizationParams,
	ShowData,
	ShowsOrViews,
	ShowsViewsCommunicate,
	ShowsViewsPositionValue,
	ShowsViewsType,
} from "../index";

export type ShowsViewsUpdataPayload = ShowData & {
	id: string;
	type: ShowsViewsType;
};

export type ShowsViewsCancelPayload = {
	id: string;
	cancelReason: string;
	cancelReasonComment: string;
};

export type ShowsViewsCountPayload = {
	applicationId: string;
	type: ShowsOrViews;
};

export enum ShowsViewsStatisticKind {
	planned = "planned",
	finished = "finished",
	newPreApplications = "newPreApplications",
	maxOffer = "maxOffer"
}

export const ShowsViewsStatisticKindMapper = {
	[ShowsViewsStatisticKind.planned]: "Назначенных показов",
	[ShowsViewsStatisticKind.finished]: "Проведенных показов",
	[ShowsViewsStatisticKind.newPreApplications]: "Новых предзаявок",
	[ShowsViewsStatisticKind.maxOffer]: "Макс. сумма",
}

export type ShowsViewsStatisticPayload = {
	applicationId: string;
	type: ShowsOrViews;
	groupBy?: GroupByDetalizationParams
	kind?: ShowsViewsStatisticKind
	dateFrom?: string
	dateTo?: string
}


export type ShowsViewsStartPayload = ShowData & {
	applicationId: string;
	type: ShowsViewsType;
};

export type ShowsViewsFinishPayload = {
	id: string;
	offer?: number
	buyerNeedsHelpToSellHisObject?: boolean | string;
	sellerNeedsHelpToAnotherNewObject?: boolean | string;
	sellerNeedsHelpToSellHisObject?: boolean | string;
};
