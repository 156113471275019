import { ReactNode, useEffect, useState } from "react";
import {
	closestCenter,
	DndContext,
	DragEndEvent,
	DragOverlay,
	DragStartEvent,
	KeyboardSensor, MouseSensor,
	PointerSensor, TouchSensor,
	useDndContext,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useDispatch } from "react-redux";
import cn from "classnames";

import { GalleryGridItemProps, ImgPosition, RealEstatePhoto } from "../../../types";
import { toastError } from "../index";
import { GalleryGridItem } from "./GalleryGridItem";
import { openGallerySlider } from "../../../service/redux/modules/gallery-slider/gallery-slider.action";
import { choosePhoto } from "./helpers/choosePhoto";

import styles from "./index.module.scss";
import { selectDevice, useSelect } from "../../../service/redux/selectors";
import { useTouchSensor } from "react-beautiful-dnd";

const SortablePage = ({
	id,
	options,
	isView,
	...props
}: GalleryGridItemProps & { activeIndex: number }) => {
	const { attributes, listeners, isDragging, setNodeRef, transform, transition } = useSortable({
		id,
		disabled: options?.disableSort,
	});

	return (
		<GalleryGridItem
			isView={isView}
			ref={setNodeRef}
			id={id}
			active={isDragging}
			style={{
				transition: transition || undefined,
				transform: CSS.Translate.toString(transform),
			}}
			options={options}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...attributes}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...listeners}
		/>
	);
};

const PageOverlay = ({ id, items, isView, ...props }: Omit<GalleryGridItemProps, "index"> & { items: string[] }) => {
	const { activatorEvent, over } = useDndContext();
	const isKeyboardSorting = activatorEvent instanceof KeyboardEvent;
	const activeIndex = items.indexOf(id);
	const overIndex = over?.id ? items.indexOf(over?.id) : -1;

	return (
		<GalleryGridItem
			id={id}
			isView={isView}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
			clone
			insertPosition={
				// eslint-disable-next-line no-nested-ternary
				isKeyboardSorting && overIndex !== activeIndex
					? overIndex > activeIndex
						? ImgPosition.After
						: ImgPosition.Before
					: undefined
			}
		/>
	);
};

type Props = {
	photos: RealEstatePhoto[];
	onDeletePhoto: (photoId: string) => boolean;
	onSortPhotos: (photos: RealEstatePhoto[]) => void;
	children?: ReactNode;
	isFromParser?: boolean;
	withWatermark?: boolean;
	isCreation?: boolean
	isView?: boolean
};

export const GalleryGrid = ({
	photos,
	onDeletePhoto,
	onSortPhotos,
	children,
	isFromParser = false,
	withWatermark,
	isCreation,
	isView,
}: Props): JSX.Element => {
	const dispatch = useDispatch();
	const {isMobile} = useSelect(selectDevice)
	const [activeId, setActiveId] = useState<string | undefined>(undefined);
	const [items, setItems] = useState(photos);
	const activeIndex = activeId ? items.findIndex((photo) => photo.id === activeId) : -1;
	const sensors = useSensors(
		useSensor(TouchSensor, {activationConstraint: {delay: 250, tolerance: 10}}),
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
	);

	useEffect(() => {
		setItems([...photos]);
	}, [photos.length]);

	const handleDragStart = ({ active }: DragStartEvent) => {
		if (!isFromParser || !isView) {
			setActiveId(items.find((item) => item.id === active.id)?.id);
		}
	};

	const handleDragCancel = () => {
		if (!isFromParser || !isView) {
			setActiveId(undefined);
		}
	};

	const handleDragEnd = ({ over }: DragEndEvent) => {
		if (!isFromParser || !isView) {
			if (over) {
				const overIndex = items.findIndex((photo) => photo.id === over.id);

				if (activeIndex !== overIndex) {
					const sortedPhotos = arrayMove(items, activeIndex, overIndex);
					onSortPhotos(sortedPhotos);
					setItems([...sortedPhotos]);
				}
			}

			setActiveId(undefined);
		} else {
			toastError({
				text: "Вы не можете сортировать фотографии объекта из парсера",
			});
		}
	};

	const onRemove = async (imageIdToRemove: string) => {
		if (!isFromParser) {
			const success = onDeletePhoto(imageIdToRemove);

			if (success) {
				setItems((itemsToRemove) => itemsToRemove.filter((photo) => photo.id !== imageIdToRemove));
			}
		} else {
			toastError({
				text: "Вы не можете удалить фотографии объекта из парсера",
			});
		}
	};

	const handlePhotoPreviewClick = (src?: string) => {
		dispatch(
			openGallerySlider({
				photos: photos.map((photo) => choosePhoto(photo, withWatermark)),
				ids: photos.map((photo) => photo.id),
				clickedPhoto: src,
				canUpdatePhotos: !isFromParser,
			})
		);
	};

	const handleChangePhoto = (newPhoto: RealEstatePhoto) => {
		const oldPhotoIdx = items.findIndex((item) => item.id === newPhoto.id);
		const newItems = [items.slice(0, oldPhotoIdx), newPhoto, items.slice(oldPhotoIdx + 1)].flat();

		setItems(newItems);
	};

	return (
		<DndContext
				onDragStart={handleDragStart}
				onDragEnd={handleDragEnd}
				onDragCancel={handleDragCancel}
				sensors={sensors}
				collisionDetection={closestCenter}
			>
				<SortableContext items={items}>
					{isMobile && isView && !isCreation && <div className={styles.mobileWrapper} />
					}
					<ul className={cn(styles.Pages)}>
						{items.map((photo, index) => (
							<SortablePage
								id={photo.id}
								index={index + 1}
								key={photo.id}
								img={{
									src: choosePhoto(photo, withWatermark),
									width: photo.width,
									height: photo.height,
								}}
								activeIndex={activeIndex}
								onRemove={onRemove}
								onPhotoPreviewClick={handlePhotoPreviewClick}
								withRotationButtons={!isView}
								changePhotoItself={handleChangePhoto}
								options={{
									withoutRemove: isFromParser,
									withoutLabels: isFromParser,
									disableSort: isFromParser || isMobile,
								}}
								isView={isView}
							/>
						))}
						{children}
					</ul>
				</SortableContext>

				<DragOverlay>
					{activeId ? (
						<PageOverlay
							id={activeId}
							isView={isView}
							img={{
								src: items.find((item) => item.id === activeId)?.photoOriginal,
								width: items.find((item) => item.id === activeId)?.width || 0,
								height: items.find((item) => item.id === activeId)?.height || 0,
							}}
							items={items.map((photo) => photo.photoOriginal)}
						/>
					) : null}
				</DragOverlay>
			</DndContext>
	);
};
