import { useEffect } from "react";
import { useQuery } from "react-query";

import { api } from "../../index";

type Props = {
	query: string;
	name?: string;
};
export const useLoadBank = ({ query, name = "" }: Props) => {
	const loadBank = () => api.application.getBank(query);

	const queryName = `loadBank${name}`;
	const { data, refetch, remove } = useQuery(queryName, loadBank, {
		enabled: !!query,
	});

	useEffect(() => () => remove(), []);

	return {
		data: data?.data,
		refetch,
	};
};
