import { useDispatch } from "react-redux";

import { toastError } from "../../../../components/UI";
import { useUpdateTask } from "../../../api/mutations";
import { activateLoading, deactivateLoading } from "../../../redux/modules/modal/modal.action";
import { deleteEmptyField } from "../../../../utils/objects";
import { CreateTaskForm, EditTaskPayload } from "../../../../types";
import { getDeadlineByDateAndTime } from "../../../../utils/date";

type Props = {
	onUpdateSuccess: () => void;
	id: string;
};

export const useHandleUpdateTask = ({ onUpdateSuccess, id }: Props) => {
	const dispatch = useDispatch();

	const onError = () => {
		dispatch(deactivateLoading());

		const text = "Не удалось изменить задачу";
		toastError({ text });
	};

	const onSuccess = () => {
		dispatch(deactivateLoading());
		onUpdateSuccess();
	};

	const { mutate } = useUpdateTask({ onError, onSuccess });

	const handleUpdateTask = (form: CreateTaskForm) => {
		dispatch(activateLoading());
		const clearedObject: Partial<CreateTaskForm> = deleteEmptyField(form);

		const deadline = getDeadlineByDateAndTime({
			date: clearedObject?.deadlineDate,
			time: clearedObject?.deadlineTime,
		});

		if (clearedObject?.deadlineTime) {
			delete clearedObject?.deadlineTime;
		}
		if (clearedObject?.deadlineDate) {
			delete clearedObject?.deadlineDate;
		}

		const editPayload = {
			...clearedObject,
			deadline,
			executor: {
				userId: (form.executor.userId as any)[0].value
			}
		};

		const payload: EditTaskPayload = {
			id,
			editPayload,
		};

		mutate(payload);
	};

	return {
		handleUpdateTask,
	};
};
