import { Control } from "react-hook-form";

import { RadioButton } from "../../../../index";

type Props = {
	name: string;
	control: Control<any>;
	currentValue?: string | number;
	disabled?: boolean;
};

export const RadioAvailability = ({ name, control, currentValue, disabled }: Props): JSX.Element => {
	const radioButtonValues = [
		{ value: 1, label: "Есть", disabled },
		{ value: 0, label: "Нет", disabled },
		{ value: "", label: "Неважно", disabled },
	];

	return (
		<RadioButton
			separate
			name={name}
			options={radioButtonValues}
			control={control}
			size="small"
			currentValue={currentValue}
		/>
	);
};
