// Mock positions information
export const PositionsDescription = [
	{
		title: "Контакты",
		description: `Может полностью редактировать объекты своего офиса, менять ответственных, удалять,
    делать все действия итд. по объектам других офисов права те же как у агента`,
	},
	{
		title: "Отделы",
		description: `Может полностью редактировать объекты своего отдела, менять ответственных, удалять, делать
    все действия итд. по объектам других отделов права те же как у агента. Может полностью редактировать объекты
    своего отдела, менять ответственных, удалять, делать все действия итд. по объектам других отделов права те же как
    у агента`,
	},
	{
		title: "Должности",
		description: `Может видеть все объекты, редактировать только текст объявления и отправлять объект в рекламу.
    Может видеть все объекты, редактировать только текст объявления и отправлять объект в рекламу`,
	},
];

export const PositionsPermissions = [
	{
		title: "Объекты",
		subject: "EstateObjectStore",
		available: true,
		headers: [
			{
				name: "Создание",
				accessor: "create",
			},
			{
				name: "Просмотр списка",
				accessor: "read",
			},
			{
				name: "Просмотр приватной информации",
				// eslint-disable-next-line
				accessor: "private-read",
			},
			{
				// eslint-disable-next-line
				name: "Редактирование",
				accessor: "update",
			},
			{
				name: "Удаление",
				accessor: "delete",
			},
		],
		actions: [
			[
				{
					type: "personal",
					name: "Личные",
				},
				{
					type: "personal",
					available: true,
					condition: "create",
				},
				{
					type: "personal",
					available: true,
					condition: "read",
				},
				{
					type: "personal",
					available: true,
					// eslint-disable-next-line
					condition: "private-read",
				},
				{
					type: "personal",
					available: true,
					condition: "update",
				},
				{
					type: "personal",
					available: true,
					condition: "delete",
				},
			],
			[
				{
					type: "departments",
					name: "Отдела",
				},
				{
					type: "departments",
					available: true,
					condition: "create",
				},
				{
					type: "departments",
					available: true,
					condition: "read",
				},
				{
					type: "departments",
					available: true,
					// eslint-disable-next-line
					condition: "private-read",
				},
				{
					type: "departments",
					available: true,
					condition: "update",
				},
				{
					type: "departments",
					available: true,
					condition: "delete",
				},
			],
			[
				{
					type: "offices",
					name: "Офиса",
				},
				{
					type: "offices",
					available: true,
					condition: "create",
				},
				{
					type: "offices",
					available: true,
					condition: "read",
				},
				{
					type: "offices",
					available: true,
					// eslint-disable-next-line
					condition: "private-read",
				},
				{
					type: "offices",
					available: true,
					condition: "update",
				},
				{
					type: "offices",
					available: true,
					condition: "delete",
				},
			],
			[
				{
					type: "companies",
					name: "Компании",
				},
				{
					type: "companies",
					available: true,
					condition: "create",
				},
				{
					type: "companies",
					available: true,
					condition: "read",
				},
				{
					type: "companies",
					available: true,
					// eslint-disable-next-line
					condition: "private-read",
				},
				{
					type: "companies",
					available: true,
					condition: "update",
				},
				{
					type: "companies",
					available: true,
					condition: "delete",
				},
			],
		],
	},
	{
		title: "Реклама",
		subject: "Advertising",
		available: true,
		headers: [
			{
				name: "Просмотр",
				accessor: "read",
			},
			{
				name: "Отправка в рекламу с проверкой",
				accessor: "advertising-sended",
			},
			{
				name: "Визирование",
				accessor: "sighting",
			},
		],
		actions: [
			[
				{
					type: "personal",
					name: "Личные",
				},
				{
					type: "personal",
					available: true,
					condition: "read",
				},
				{
					type: "personal",
					available: true,
					condition: "advertising-send1",
				},
				{
					type: "personal",
					available: true,
					condition: "sighting",
				},
			],
			[
				{
					type: "departments",
					name: "Отдела",
				},
				{
					type: "departments",
					available: true,
					condition: "read",
				},
				{
					type: "departments",
					available: true,
					condition: "advertising-send2",
				},
				{
					type: "departments",
					available: true,
					condition: "sighting",
				},
			],
			[
				{
					type: "offices",
					name: "Офиса",
				},
				{
					type: "offices",
					available: true,
					condition: "read",
				},
				{
					type: "offices",
					available: true,
					condition: "advertising-send",
				},
				{
					type: "offices",
					available: true,
					condition: "sighting",
				},
			],
			[
				{
					type: "companies",
					name: "Компании",
				},
				{
					type: "companies",
					available: true,
					condition: "read",
				},
				{
					type: "companies",
					available: true,
					condition: "advertising-send",
				},
				{
					type: "companies",
					available: true,
					condition: "sighting",
				},
			],
		],
	},
	{
		title: "Задачи",
		subject: "Tasks",
		available: true,
		headers: [
			{
				name: "Создание",
				accessor: "create",
			},
			{
				name: "Просмотр",
				accessor: "read",
			},
			{
				// eslint-disable-next-line
				name: "Редактирование",
				accessor: "update",
			},
			{
				name: "Изменение статуса",
				accessor: "status-updated",
			},
		],
		actions: [
			[
				{
					type: "personal",
					name: "Личные",
				},
				{
					type: "personal",
					available: true,
					condition: "create",
				},
				{
					type: "personal",
					available: true,
					condition: "read",
				},
				{
					type: "personal",
					available: true,
					condition: "update",
				},
				{
					type: "personal",
					available: true,
					condition: "status-update2",
				},
			],
			[
				{
					type: "departments",
					name: "Отдела",
				},
				{
					type: "departments",
					available: true,
					condition: "create",
				},
				{
					type: "departments",
					available: true,
					condition: "read",
				},
				{
					type: "departments",
					available: true,
					condition: "update",
				},
				{
					type: "departments",
					available: true,
					condition: "status-update3",
				},
			],
			[
				{
					type: "offices",
					name: "Офиса",
				},
				{
					type: "offices",
					available: true,
					condition: "create",
				},
				{
					type: "offices",
					available: true,
					condition: "read",
				},
				{
					type: "offices",
					available: true,
					condition: "update",
				},
				{
					type: "offices",
					available: true,
					condition: "status-update",
				},
			],
			[
				{
					type: "companies",
					name: "Компании",
				},
				{
					type: "companies",
					available: true,
					condition: "create",
				},
				{
					type: "companies",
					available: true,
					condition: "read",
				},
				{
					type: "companies",
					available: true,
					condition: "update",
				},
				{
					type: "companies",
					available: true,
					condition: "status-update",
				},
			],
		],
	},
	{
		title: "Контакты",
		subject: "Contacts",
		available: true,
		headers: [
			{
				name: "Просмотр",
				accessor: "read",
			},
			{
				// eslint-disable-next-line
				name: "Редактирование",
				accessor: "update",
			},
			{
				name: "Удаление",
				accessor: "delete",
			},
		],
		actions: [
			[
				{
					type: "personal",
					name: "Личные",
				},
				{
					type: "personal",
					available: true,
					condition: "read",
				},
				{
					type: "personal",
					available: true,
					condition: "update",
				},
				{
					type: "personal",
					available: true,
					condition: "delete",
				},
			],
			[
				{
					type: "departments",
					name: "Отдела",
				},
				{
					type: "departments",
					available: true,
					condition: "read",
				},
				{
					type: "departments",
					available: true,
					condition: "update",
				},
				{
					type: "departments",
					available: true,
					condition: "delete",
				},
			],
			[
				{
					type: "offices",
					name: "Офиса",
				},
				{
					type: "offices",
					available: true,
					condition: "read",
				},
				{
					type: "offices",
					available: true,
					condition: "update",
				},
				{
					type: "offices",
					available: true,
					condition: "delete",
				},
			],
			[
				{
					type: "companies",
					name: "Компании",
				},
				{
					type: "companies",
					available: true,
					condition: "read",
				},
				{
					type: "companies",
					available: true,
					condition: "update",
				},
				{
					type: "companies",
					available: true,
					condition: "delete",
				},
			],
		],
	},
	{
		title: "Заявки",
		subject: "Requests",
		available: true,
		headers: [
			{
				name: "Просмотр",
				accessor: "read",
			},
			{
				name: "Просмотр приватной информации",
				// eslint-disable-next-line
				accessor: "private-read",
			},
			{
				// eslint-disable-next-line
				name: "Редактирование",
				accessor: "update",
			},
			{
				name: "Удаление",
				accessor: "delete",
			},
		],
		actions: [
			[
				{
					type: "personal",
					name: "Личные",
				},
				{
					type: "personal",
					available: true,
					condition: "read",
				},
				{
					type: "personal",
					available: true,
					// eslint-disable-next-line
					condition: "private-read",
				},
				{
					type: "personal",
					available: true,
					condition: "update",
				},
				{
					type: "personal",
					available: true,
					condition: "delete",
				},
			],
			[
				{
					type: "departments",
					name: "Отдела",
				},
				{
					type: "departments",
					available: true,
					condition: "read",
				},
				{
					type: "departments",
					available: true,
					// eslint-disable-next-line
					condition: "private-read",
				},
				{
					type: "departments",
					available: true,
					condition: "update",
				},
				{
					type: "departments",
					available: true,
					condition: "delete",
				},
			],
			[
				{
					type: "offices",
					name: "Офиса",
				},
				{
					type: "offices",
					available: true,
					condition: "read",
				},
				{
					type: "offices",
					available: true,
					// eslint-disable-next-line
					condition: "private-read",
				},
				{
					type: "offices",
					available: true,
					condition: "update",
				},
				{
					type: "offices",
					available: true,
					condition: "delete",
				},
			],
		],
	},
	{
		title: "Звонки",
		subject: "Calls",
		available: true,
		headers: [
			{
				name: "Просмотр",
				accessor: "read",
			},
			{
				name: "Прослушивание",
				accessor: "hear",
			},
		],
		actions: [
			[
				{
					type: "personal",
					name: "Личные",
				},
				{
					type: "personal",
					available: true,
					condition: "read",
				},
				{
					type: "personal",
					available: true,
					condition: "hear",
				},
			],
			[
				{
					type: "departments",
					name: "Отдела",
				},
				{
					type: "departments",
					available: true,
					condition: "read",
				},
				{
					type: "departments",
					available: true,
					condition: "hear",
				},
			],
			[
				{
					type: "offices",
					name: "Офиса",
				},
				{
					type: "offices",
					available: true,
					condition: "read",
				},
				{
					type: "offices",
					available: true,
					condition: "hear",
				},
			],
			[
				{
					type: "companies",
					name: "Компании",
				},
				{
					type: "companies",
					available: true,
					condition: "read",
				},
				{
					type: "companies",
					available: true,
					condition: "hear",
				},
			],
		],
	},
];
