import cn from "classnames";

import styles from "./index.module.scss";

type Props = {
	allText?: string;
	textToHighlight?: string;
	classNames?: {
		root?: string;
		highlight?: string;
	};
};

export const HighlightText = ({ allText, textToHighlight, classNames }: Props): JSX.Element => {
	if (!textToHighlight || !allText) {
		return <>{allText}</>;
	}

	const indexOfStart = allText.indexOf(textToHighlight);
	const IS_FOUND = indexOfStart !== -1;
	const indexOfEnd = IS_FOUND ? indexOfStart + textToHighlight.length : -1;

	const textBefore = IS_FOUND ? allText.slice(0, indexOfStart) : undefined;

	const textHighlight = IS_FOUND ? allText.slice(indexOfStart, indexOfEnd) : undefined;

	const textAfter = IS_FOUND ? allText.slice(indexOfEnd) : undefined;

	if (textBefore === undefined || textHighlight === undefined || textAfter === undefined) {
		return <p className={cn(styles.root, classNames?.root)}>{allText}</p>;
	}

	return (
		<p className={cn(styles.root, classNames?.root)}>
			{textBefore}
			<b className={classNames?.highlight}>{textHighlight}</b>
			{textAfter}
		</p>
	);
};
