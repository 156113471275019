import { useEffect } from "react";

import { useTasksCountForLeftMenu } from "../../../../../../service/api/queries";
import { useTimer } from "../../../../../../utils/hooks";

const refetchDelaySec = 30;

const QUERY_KEY = "sdelka/tasks/load-tasks-count/left-menu";

export const useSidebarTasksCount = () => {
  const {data, refetch: refetchTasksCount} = useTasksCountForLeftMenu({
    key: QUERY_KEY,
    defaultFilter: {requiresAttention: true},
  });

  const [timeRemaining, handleTimer, handleRestart] = useTimer(refetchDelaySec);

  useEffect(() => {
    handleTimer();
  }, []);

  useEffect(() => {
    if (timeRemaining === 0) {
      refetchTasksCount();
      handleRestart();
    }
  }, [timeRemaining]);


  return  data
};
