import { useHistory } from "react-router";
import { selectDevice, useSelect } from "../../service/redux/selectors";

export const useIsTelegram = () => {
  const history = useHistory()
  const {isTelegram} = useSelect(selectDevice)

  const executeUrl = (url: string) => {
    if (isTelegram) {
      history.push(url)
    } else {
      window.open(url)
    }
  }

  return {
    executeUrl
  }
}