import { api } from "../../api";
import { useQuery } from "react-query";

const DEFAULT_QN = "sdelka/dashboard/use-load-dashboard-table-report-types/DEFAULT_QN";

export const useLoadReportTypes = () => {
  const loadTypes = () => api.dashboard.getReportTypes()

  const {data, refetch, isLoading} = useQuery(DEFAULT_QN, loadTypes)

  return {
    types: data?.data,
    refetch,
    isLoading
  }
}