import { useEffect, useState } from "react";
import { Control, UseFormGetValues, UseFormSetValue } from "react-hook-form";

import { IndividualCheckbox, InfoRow, RadioButton } from "../../../../index";
import { RealEstateFilterField } from "../../../../../../types";

type Props = {
	field: RealEstateFilterField;
	nameGroup: [string, string];
	setValue: UseFormSetValue<any>;
	control: Control<any>;
	getValues: UseFormGetValues<any>;
	disabled?: boolean;
};

const canConnectLabel = "Возможно подключить";

// If value = 'Нет', shows checkbox 'Возможно подключить'.
export const Communication = ({
	getValues,
	field,
	nameGroup,
	control,
	setValue,
	disabled,
}: Props): JSX.Element => {
	const [selectedOption, setSelectedOption] = useState<string>(getValues(nameGroup[0]) || "");
	const [canConnect, setCanConnect] = useState<boolean>(getValues(nameGroup[1]) || false);

	const radioButtonValues = [
		{ value: "Есть", label: "Есть", disabled },
		{ value: "На участке", label: "На участке", disabled },
		{ value: "Нет", label: "Нет", disabled },
	];

	useEffect(() => {
		setValue(nameGroup[0], selectedOption);
	}, [selectedOption]);

	const changeSetConnect = () => {
		setCanConnect((prev) => !prev);
		setValue(nameGroup[1], !canConnect);
	};

	return (
		<>
			<InfoRow aspect={{ label: 8, content: 7.3 }} label={field.label}>
				<RadioButton
					separate
					name={nameGroup[0]}
					options={radioButtonValues}
					control={control}
					size="small"
					handleOnChange={(value) => setSelectedOption(selectedOption === String(value) ? "" : String(value))}
					currentValue={selectedOption}
				/>
			</InfoRow>
			{selectedOption?.includes("Нет") && (
				<InfoRow aspect={{ label: 8, content: 7.3 }} variant="noLabel">
					<IndividualCheckbox
						label={canConnectLabel}
						weight="bold"
						onChange={changeSetConnect}
						checked={canConnect}
						disabled={disabled}
					/>
				</InfoRow>
			)}
		</>
	);
};
