import { useMutation } from "react-query";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/application/deal-generate-document/DEFAULT_MN";

type UseFinishDeal = {
  onSuccess?: () => void
}

export const useGetGeneratedDocumentPreview= ({onSuccess}:UseFinishDeal) => {
  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: ({id, template, type}: {id: string, type: string, template: string}) => api.deals.getGeneratedDocumentPreview(id, type, template),
    onSuccess
  })

  return {
    getPreview: mutation.mutate,
    getPreviewAsync: mutation.mutateAsync,
    getPreviewIsLoading: mutation.isLoading,
  }
}