import { RealEstateSchemaConfigField, FormPropsEditRealEstate } from "../../../../../../../types";

import { AddressSelect, TextInput } from "./components";

type Props = {
	form: FormPropsEditRealEstate;
	fields?: RealEstateSchemaConfigField[];
};

export const Address = ({ form, fields }: Props): JSX.Element => {
	const filteredFields = [...(fields || [])];
	filteredFields?.splice(0, 2);
	filteredFields?.splice(filteredFields.length - 1);

	const render = (field: RealEstateSchemaConfigField) => {
		switch (field.dataType.type) {
			case "addressSelect":
				return <AddressSelect form={form} field={field} />;
			case "text":
				return <TextInput form={form} field={field} />;
			default:
				return <></>;
		}
	};

	return (
		<>
			{fields?.map((field) => (
				<div key={field.generalName}>{render(field)}</div>
			))}
		</>
	);
};
