import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Tabs } from "@sdelka_crm/sdelka-crm-component-library";
import { useQueryParams } from "../../../utils/hooks";
import { paths } from "../../../utils/paths";
import styles from "./index.module.scss";

enum TabTypeEnum {
  ORDERS = 0,
  PRODUCTS = 1,
  OFFICES = 2,
  EMPLOYERS = 3
}

type Props = {
  isCoWorking?: boolean
  isBoss?: boolean
  // servicesListFilters?: RealEstateFilter;
};

type QueryParams = {
  employeeId: string;
  filter: string;
  tab: "PRODUCTS" | "OFFICES" | "EMPLOYERS" | "ORDERS";
  productId: string;
  officeId: string;
  orderId: string;
};

export const ServicesTabs = ({isCoWorking, isBoss}: Props): JSX.Element => {
  const {push} = useHistory();
  const {
    queryParams: {
      tab,
    },
  } = useQueryParams<QueryParams>([
    {name: "tab", type: "string"},
  ]);

  const defaultTab = TabTypeEnum[ tab ] ?? 0
  const [activeTab, setActiveTab] = useState(defaultTab);

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab]);

  const handleChangeTab = (tabNum: number) => {
    switch (tabNum) {
      case 0: {
        push(
          `${paths.services.list}?tab=ORDERS`
        );
        break;
      }
      case 1: {
        push(
          `${paths.services.list}?tab=PRODUCTS`
        );
        break;
      }
      case 2: {
        push(
          `${paths.services.list}?tab=OFFICES`
        );
        break;
      }
      case 3: {
        push(
          `${paths.services.list}?tab=EMPLOYERS`
        );
        break;
      }
      default:
        break;
    }
    setActiveTab(tabNum);
  };

  const tabsItems = isCoWorking ? [
    <div className={styles.element} style={{width: 160}}>Мои заказы</div>,
    <div className={styles.element} style={{width: 106}}>Магазин</div>,
  ] : (isBoss ? [
    <div className={styles.element} style={{width: 160}}>Мои заказы</div>,
    <div className={styles.element} style={{width: 106}}>Магазин</div>,
    <div className={styles.element} style={{width: 212}}>Офисы и отделы</div>,
    <div className={styles.element} style={{width: 155}}>Сотрудники</div>,
  ] : [
    <div className={styles.element} style={{width: 160}}>Мои заказы</div>,
    <div className={styles.element} style={{width: 106}}>Магазин</div>,
    <div className={styles.element} style={{width: 212}}>Офисы и отделы</div>,
  ])

  const tabs = ["Мои заказы", "Магазин", "Офисы и отделы", "Сотрудники"].map((tabItem) => (
    <div className={styles.tabLabel}>{tabItem}</div>
  ));

  return (
    <div className={styles.tabs}>
      <Tabs
        tabs={tabsItems}
        size="large"
        active={activeTab}
        onChange={handleChangeTab}
        offset={36}
        classNames={{root: styles.root, wrapper: styles.tabsRoot}}
      />
    </div>
  );
};
