import { Application, KindType } from "../../../../types";
import { AxiosResponse } from "axios";
import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/application/load-application-card/DEFAULT_QN";

type Props = {
  applicationId: string;
  onSuccess?: (data: AxiosResponse<Application>) => void;
  onError?: () => void;
  kind?: KindType
};

type Return = {
  data?: Application;
  isLoading: boolean;
  isFetching: boolean;
  refetch: () => void;
  remove: () => void;
  isFetched: boolean;
};

type UseLoadApplicationCard = (props: Props) => Return;

export const useLoadApplicationCard: UseLoadApplicationCard = ({applicationId, onError, onSuccess, kind}) => {

  const loadApplicationCard = () => {
    switch (kind) {
      case KindType.APPLICATION:
        return api.application.getCard({applicationId})
      case KindType.DEAL:
        return api.deals.getCard({applicationId})
      default:
          console.error("error - useLoadApplicationCard", kind)
    }
  }

  const {data, refetch, remove, isLoading, isFetching, isFetched} = useQuery(
    [`${DEFAULT_QN}${kind}`, applicationId],
    loadApplicationCard,
    {
      enabled: !!applicationId,
      onError,
      onSuccess,
      cacheTime: 0,
    }
  );

  return {
    data: data?.data,
    isLoading,
    isFetching,
    refetch,
    remove,
    isFetched,
  };
};
