import { User } from "../../../../types";

import { AuthTInitialState } from "../../types";

const SET_CURRENT_USER = "sdelka/current-account/SET_CURRENT_USER";
const SET_LOADING_CURRENT_ACCOUNT = "sdelka/current-account/SET_LOADING_CURRENT_ACCOUNT";
const CLEAN_UP = "sdelka/current-account/CLEAN_UP";

type TAction =
	| { type: typeof SET_CURRENT_USER; currentUser?: User }
	| { type: typeof SET_LOADING_CURRENT_ACCOUNT; isLoading: boolean }
	| { type: typeof CLEAN_UP };

const initialState: AuthTInitialState = {
	currentAccount: undefined,
	isLoading: false,
};

export const currentAccountReducer = (
	// eslint-disable-next-line @typescript-eslint/default-param-last
	state = initialState,
	action: TAction,
) => {
	switch (action?.type) {
		case SET_CURRENT_USER:
			return {
				currentAccount: action.currentUser,
			};
		case SET_LOADING_CURRENT_ACCOUNT:
			return {
				isLoading: action.isLoading,
			};
		case CLEAN_UP:
			return {
				currentAccount: undefined,
				isLoading: false,
			};
		default:
			return { ...state };
	}
};

export const setCurrentUser = (currentUser?: User): TAction => ({
	type: SET_CURRENT_USER,
	currentUser,
});

export const setLoadingCurrentUser = (isLoading: boolean): TAction => ({
	type: SET_LOADING_CURRENT_ACCOUNT,
	isLoading,
});

export const cleanUpAccount = (): TAction => ({
	type: CLEAN_UP,
});
