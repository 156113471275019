import { CallData, TrafficWithDate } from "../../../../../../../../types";
import { useLoadTraffic } from "../../../../../../../../service/api/queries";
import { useEffect, useState } from "react";
import { Button, NoData } from "@sdelka_crm/sdelka-crm-component-library";
import { TrafficTable } from "../../../../../../LeadsAndDeals/components";
import styles from "./index.module.scss"
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../../../../service/redux/modules/modal/modal.action";

type Props = {
  onSelect: (callData?: CallData | null, callDataId?: string | null) => void
}

export const AddCallModal = ({onSelect}: Props): JSX.Element => {
  const trafficData = useLoadTraffic({});
  const dispatch = useDispatch()
  const {
    setFilter: setTrafficsFilter,
    isFetching: isFetchingTraffics,
  } = trafficData;
  const [data, setData] = useState<TrafficWithDate | null | undefined>(null)

  const handleSelect = (row: TrafficWithDate | null | undefined) => {
    setData(row)
  }

  const onApply = () => {
    onSelect(data?.callData, data?.callDataId)
    dispatch(closeModal())
  }
// const selectedClass = trafficData.
  useEffect(() => {
    // setTrafficsFilter({contactId: id})
  }, [setTrafficsFilter])

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        Прикрепление звонка
      </div>
      <div className={styles.description}>
        Выберите коммуникацию из списка
      </div>
      <div className={styles.chatCard}>
        <div className={styles.tableWithPagination}>
          {!isFetchingTraffics && trafficData.isError ? (
            <NoData onRefetch={trafficData.refetch}/>
          ) : (
            <TrafficTable
                trafficList={trafficData.list}
                isLoading={isFetchingTraffics}
                setSelect={handleSelect}
                className={{
                  root: styles.callsWrapper,
                  title: styles.callsSeparator,
                  row: styles.tableRow
                }}
              />
          )}
        </div>
      </div>
      <div className={styles.rowEnd}>
        <Button
          onClick={onApply}
          classNames={{root: styles.buttonCreate}}
        >
          Прикрепить
        </Button>
      </div>
    </div>
  )
}