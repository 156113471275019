import { useMutation } from "react-query";
import { TransferLead } from "../../../../types";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/lead/transfer-lead/DEFAULT_MN";

type UseTransferLead = {
	onSuccess?: (data: any) => void;
	onError?: () => void;
};

export const useTransferLead = ({ onSuccess, onError }: UseTransferLead) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (data: TransferLead) => api.leads.transfer(data),
		onSuccess,
		onError,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
