import { useForm, useWatch } from "react-hook-form";
import { Button, H5, phoneMask } from "@sdelka_crm/sdelka-crm-component-library";

import { InfoRow, Textarea, TransparentInput } from "../../../../../../../../../../../UI";
import { MarkSuccessForm } from "../../../../../../../../../../../../types";

import styles from "./index.module.scss";

export type CallCenterMenuSuccessModalProps = Partial<MarkSuccessForm> & {
	onSubmit: (payload: MarkSuccessForm) => void;
};

export const CallCenterMenuSuccessModal = ({
	onSubmit,
	...defaultValues
}: CallCenterMenuSuccessModalProps): JSX.Element => {
	const { control, handleSubmit, setValue, register } = useForm<MarkSuccessForm>({
		defaultValues,
	});

	const phoneWatcher = useWatch({
		name: "phone",
		defaultValue: defaultValues.phone,
		control,
	});

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
			<div>
				<InfoRow label="Телефон">
					<TransparentInput
						register={register}
						mask={phoneMask}
						setValueForMask={setValue}
						value={phoneWatcher}
						name="phone"
						placeholder="Не указано"
						className={styles.oneLineInput}
					/>
				</InfoRow>

				<InfoRow label="Цена">
					<TransparentInput
						register={register}
						name="price"
						placeholder="Не указано"
						className={styles.oneLineInput}
					/>
				</InfoRow>
			</div>

			<div className={styles.description}>
				<H5>Описание объявление</H5>

				<Textarea
					register={register}
					name="ownerComment"
					placeholder="Рекламный текст объявления"
					className={styles.ownerComment}
				/>

				<Textarea
					register={register}
					name="callCenterComment"
					placeholder="Введите комментарий"
					className={styles.callCenterComment}
				/>
			</div>

			<Button type="submit" classNames={{ root: styles.submitBtn }}>
				Сохранить
			</Button>
		</form>
	);
};
