import { Control, UseFormGetValues, useWatch } from "react-hook-form";

type Props = {
	control: Control<any>;
	getValues: UseFormGetValues<any>;
};

export const useFormWatcher = ({ control, getValues }: Props) => {
	const [typeWatcher, addressWatcher, emailWatcher, pseudonymWatcher, seriesWatcher,
		passportNumberWatcher, issuedByWatcher, issuedByCodeWatcher, registerAddressWatcher] = [
		useWatch({
			control,
			name: "type",
			defaultValue: getValues("type") || "",
		}),
		useWatch({
			control,
			name: "addressObject",
			defaultValue: getValues("addressObject") || "",
		}),
		useWatch({
			control,
			name: "email",
			defaultValue: getValues("email") || "",
		}),
		useWatch({
			control,
			name: "pseudonym",
			defaultValue: getValues("pseudonym") || "",
		}),
		useWatch({
			control,
			name: "series",
			defaultValue: getValues("series") || "",
		}),
		useWatch({
			control,
			name: "passportNumber",
			defaultValue: getValues("passportNumber") || "",
		}),
		useWatch({
			control,
			name: "issuedBy",
			defaultValue: getValues("issuedBy") || "",
		}),
		useWatch({
			control,
			name: "issuedByCode",
			defaultValue: getValues("issuedByCode") || "",
		}),
		useWatch({
			control,
			name: "registerAddress",
			defaultValue: getValues("registerAddress") || "",
		}),
	];

	return {
		typeWatcher,
		addressWatcher,
		emailWatcher,
		pseudonymWatcher,
		seriesWatcher,
		passportNumberWatcher,
		issuedByWatcher,
		issuedByCodeWatcher,
		registerAddressWatcher
	};
};
