import { useDispatch } from "react-redux";
import { InviteUserInfo } from "../../../../../../types";
import { closeModal, openModal } from "../../../../../../service/redux/modules/modal/modal.action";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import styles from "./index.module.scss"
import { closeDrawer } from "service/redux/modules/drawer/drawer.action";
import { ReactComponent as CheckMarkSuccess } from "../../../../../../assets/icons/CheckMarkSuccess.svg";
import { setCompanyName } from "service/redux/modules/registration-steps/reg-steps.action";
import { Dispatch, SetStateAction } from "react";
import { acceptInvite } from "service/redux/modules/auth/auth.thunk";
import { useHistory } from "react-router-dom";

type Props = {
  selectedInvite?: InviteUserInfo | undefined;
  setSelectedInvite: Dispatch<SetStateAction<InviteUserInfo | undefined>>;
  invite: InviteUserInfo
};

export const HasInvitesModal = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const onConfirmation = ({invite}: Props) => {

    if (invite) {
      dispatch(acceptInvite({id: invite.inviteId}));
      dispatch(setCompanyName(invite?.companyName || ""));
      dispatch(closeModal())
      dispatch(closeDrawer())
      history.push("/real-estate")
    }
  };

  const openHasInvitesModal = ({setSelectedInvite, invite}: Props) => {
    dispatch(
      openModal({
        width: 660,
        body:
          <div className={styles.wrap}>
            <div className={styles.block}>
              <CheckMarkSuccess/>
              <div className={styles.text}>
                <h1>
                  Внимание!
                </h1>
                <p>
                  Если вы примете приглашение от, то остальные заявки будут отклонены.
                </p>
              </div>
            </div>
            <div className={styles.blockButtons}>
              <h2>Вы уверены?</h2>
              <div className={styles.buttons}>
                <Button onClick={() => onConfirmation({setSelectedInvite, invite})} classNames={{root: styles.button}}>
                  Да
                </Button>
                <Button
                  type="button"
                  variant="text"
                  color="red"
                  onClick={() => {
                    setSelectedInvite(undefined)
                    dispatch(closeModal())
                  }}>
                  Нет
                </Button>
              </div>
            </div>
          </div>
      })
    )
  }
  return {
    openHasInvitesModal
  }
}