import { toastError } from "../../index";

type UseBlockAction = {
	isLast: boolean | undefined;
};

export const useBlockAction = ({ isLast }: UseBlockAction) => {
	const checkAllow = (action: any) =>
		isLast ? toastError({ text: "Для изменения данных перейдите на этот этап" }) : action();

	return {
		checkAllow,
	};
};
