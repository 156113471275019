import { useLoadTasks, useTasksCount } from "../../../api/queries";
import { DefaultTasksFiltersType } from "../../../../types";

type Props = {
  defaultTasksFilter: DefaultTasksFiltersType;
};

export const useTasks = ({defaultTasksFilter}: Props) => {
  const {
    setStage: setLoadStage,
    setFilter: setLoadFilter,
    ...tasksQuery
  } = useLoadTasks({
    defaultFilter: defaultTasksFilter,
  });

  const {
    setFilter: setCountFilter,
    setStage: setCountStage,
    ...countQuery
  } = useTasksCount({
    defaultFilter: defaultTasksFilter,
  });

  const submitFilter = (newFilter: DefaultTasksFiltersType) => {
    setCountFilter(
      {
        ...defaultTasksFilter,
        ...newFilter,
        communicationsForLeadId: defaultTasksFilter.communicationsForLeadId
      }
    );
    setLoadFilter(
      {
        ...defaultTasksFilter,
        ...newFilter,
        communicationsForLeadId: defaultTasksFilter.communicationsForLeadId
      }
    );
    tasksQuery.setPage(1);
  };

  const setStage = (newStage: string[]) => {
    setCountStage(newStage);
    setLoadStage(newStage);
  };

  const resetForm = () => {
    submitFilter({...defaultTasksFilter});
    tasksQuery.setPage(1);
  };

  return {
    tasksQuery,
    countQuery,
    submitFilter,
    setStage,
    resetForm,
  } as const;
};
