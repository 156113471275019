import { useDispatch } from "react-redux";
import { JoinToSdelkaProUserPayload } from "../../../../../../types";
import { closeModal } from "../../../../../../service/redux/modules/modal/modal.action";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import styles from "./index.module.scss"
import { closeDrawer } from "service/redux/modules/drawer/drawer.action";
import { initialize, useFinishRegistration } from "service/redux/modules/auth/auth.thunk";
import { TokenService } from "service/api/services/inner/TokenService";
import { useForm } from "react-hook-form";
import { AddressSelect, Checkbox, InfoRow, toastSuccess } from "../../../../../UI";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { commonErrors } from "../../../../../../utils/errors";
import { useHistory } from "react-router-dom";
import { useTimer } from "../../../../../../utils/hooks";
import { useSelectAddress } from "../../../../../../service/api/mutations";

export const Join = () => {

  const {asyncMutate, error} = useSelectAddress()

  const [disabled, setDisabled] = useState(false)
  const toggle = () => setDisabled((prev) => !prev);
  const [timer, handleTimer] = useTimer(10);

  const schema = yup.object().shape({
    address: yup.object().shape({
      value: yup.string().required(commonErrors.required),
    }),
  });
  const {handleSubmit, setValue, formState, register, watch, control} = useForm<JoinToSdelkaProUserPayload>({
    resolver: yupResolver(schema),
    defaultValues: {
      address: {
        label: "",
        value: ''
      }
    }
  })

  useEffect(() => {
    handleTimer();
  }, []);

  useEffect(() => {
    if ((error as any)?.response?.data?.error.errorCode === "RC001") {
      toastSuccess({text: "Ваша заявка на вступление в компанию на рассмотрении, ожидайте", autoClose: 3000})
    }
  }, [(error as any)?.response?.data?.error.errorCode])

  const [watchAddress] = watch(["address"])

  const onSubmit = async (values) => {
    const payload = {
      addressLabel: values.address.label,
      addressId: values.address.value
    }
    try {
      const response = await asyncMutate(payload.addressId)
      if (response) {
       window.location.reload()
      }
    } catch (error) {
      console.error("Error during mutation:", error);
    }
  };



  return (
    <div className={styles.wrap}>
      <div className={styles.text}>
        <h1>
          Вступить в СДЕЛКА ПРО
        </h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InfoRow label="Город">
          <AddressSelect
            classNames={{selected: `${styles.addressSelect}`, error: styles.error}}
            name="address"
            register={register}
            setValue={setValue}
            error={formState.errors.address?.value?.message?.toString()}
            value={watchAddress}
            withBorder
            autoDetect
            timer={timer}
          />
        </InfoRow>
        <div className={styles.row} style={{marginTop: "32px"}}>
          <Button type="submit" classNames={{root: styles.button}} >
            Сохранить
          </Button>
        </div>
      </form>
    </div>
  )
}