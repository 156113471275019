import { JSX } from "react";
import {
  useAddLeadModal
} from "../../../../../UI/layout/SidebarLayout/Sidebar/components/AddModal/helpers/useAddLeadModal";
import styles from "./index.module.scss"
import { Button } from "@sdelka_crm/sdelka-crm-component-library";


type Props = {
  contactId: string
}

export const ContactStickyFooter = (
  {
    contactId
  }: Props):JSX.Element => {

  const { openLeadModal } = useAddLeadModal(contactId);

  return (
    <div className={styles.root}>
      <Button
        onClick={() => openLeadModal()}
      >
        Создать лид
      </Button>
    </div>
  )
}