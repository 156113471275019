import { useMutation } from "react-query";
import { CompilationDeleteObjectPayload } from "../../../../types";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/compilation/delete-object-from-compilation/DEFAULT_MN";

type UseDeleteObjectFromCompilation = {
	onSuccess?: (data) => void;
};

export const useDeleteObjectFromCompilation = ({ onSuccess }: UseDeleteObjectFromCompilation) => {
	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: ({ applicationId, objectId }: CompilationDeleteObjectPayload) =>
			api.compilations.deleteObject({ applicationId, objectId }),
		onSuccess,
	});

	return {
		mutate: mutate.mutate,
		mutateAsync: mutate.mutateAsync,
		isLoading: mutate.isLoading,
		isSuccess: mutate.isSuccess,
	};
};
