import { Task } from "../../../../types";
import { AxiosResponse } from "axios";
import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/tasks/load-task/DEFAULT_MN";

type Props = {
	id: string;
	onSuccess?: (data: AxiosResponse<Task>) => void;
};

export const useLoadTask = ({ id, onSuccess }: Props) => {
	const loadTask = () => api.tasks.getTask(id);

	const { data, refetch, isFetching } = useQuery([DEFAULT_MN, id], loadTask, {
		onSuccess,
	});

	return {
		data: data?.data,
		isFetching,
		refetch,
	};
};
