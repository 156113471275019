import { useDispatch } from "react-redux";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useRefuseLead } from "../../../../../../../../../../../service/api/mutations";
import { closeModal, openModal, } from "../../../../../../../../../../../service/redux/modules/modal/modal.action";

import styles from "./index.module.scss";
import { ReactComponent as Alert } from "../../../../../../../../../../../assets/icons/iconWarnBig.svg";
import { useEffect } from "react";

type Props = {
  leadId: string;
  refetch: () => void;
};

type Return = {
  openRefuseModal: () => void;
};

type UseRefuseModal = (props: Props) => Return;

const RefuseModal = ({leadId, refetch}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const {mutate, data, isLoading} = useRefuseLead({
    onSuccess: () => {
      refetch();
      dispatch(closeModal());
    },
  });

  useEffect(() => {
    if (data) {
      refetch();
      dispatch(closeModal());
    }
  }, [data])

  const onSubmit = () => {
    const payload = {
      refuseReasonComment: "отказ от лида",
    };
    mutate({leadId, payload});
  };

  const onApply = () => {
    onSubmit()
  }
  const onDeny = () => {
    dispatch(closeModal())
  }

  return (
    <>
      <div className={styles.row}>
        <div>
          <Alert/>
        </div>
        <div className={styles.column}>
          <div className={styles.title}>Ваш лид отправится в архив</div>
          <div className={styles.text}>
            Вы не сможете больше создавать предзаявки и работать с этим лидом
          </div>
        </div>
      </div>
      <div className={styles.rowEnd}>
        <Button onClick={onDeny} variant="outlined">
          Отмена
        </Button>
        <Button
          onClick={onApply}
          classNames={{root: styles.buttonCreate}}
          isLoading={isLoading}
        >
          Продолжить
        </Button>
      </div>
    </>
  );
};

export const useRefuseModal: UseRefuseModal = ({leadId, refetch}) => {
  const dispatch = useDispatch();

  const openRefuseModal = () => {
    dispatch(
      openModal({
        body: <RefuseModal leadId={leadId} refetch={refetch}/>,
        width: 660,
      })
    );
  };

  return {openRefuseModal};
};
