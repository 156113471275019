import { MaskFieldName } from "../../../../../../../types";
import { createNumberMask } from "text-mask-addons";

import { MaskEnum } from "../../../../../../../utils/masks";

type Props = {
	maxSymbols?: number;
	mask?: MaskFieldName;
};

type Return = {
	inputsMask: [any, any];
};

type UseMask = (props: Props) => Return;

export const useMask: UseMask = ({ maxSymbols, mask }) => {
	const defaultMask: any = createNumberMask({
		prefix: "",
		thousandsSeparatorSymbol: " ",
		// includeThousandsSeparator: maxSymbols,
		// integerLimit: Number(maxSymbols) || undefined,
	});

	const inputMask = mask ? MaskEnum[mask] : defaultMask;

	const inputsMask: [any, any] = [inputMask, inputMask];

	return {
		inputsMask,
	};
};
