import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { StagesType } from "../../../../../types";

import { closeModal, openModal } from "../../../../../service/redux/modules/modal/modal.action";

type UseSelectedTab = {
	currentStage: number;
	onNextStage?: (nextStage: number) => void;
	onChange?: (val: number) => void;
	stages: StagesType[];
};

export const useSelectedTab = ({ currentStage, onChange, onNextStage, stages }: UseSelectedTab) => {
	const dispatch = useDispatch();
	const [selectedTab, setSelectedTab] = useState(currentStage - 1);

	useEffect(() => {
		setSelectedTab(currentStage - 1);
	}, [currentStage]);

	useEffect(() => {
		if (onChange) {
			onChange(selectedTab);
		}
	}, [selectedTab]);

	const handleChangeStage = (stage: "next" | "prev", type: "view" | "change" = "view") => {
		
		if (onNextStage && type === "change") {
			const step = stage === "next" ? 1 : 0;
			onNextStage(selectedTab + 1 + step);
		} else {
			const step = stage === "next" ? 1 : -1;
			setSelectedTab((prev) => prev + step);
		}
	};

	const goToCurrentStage = () => setSelectedTab(currentStage - 1);

	const handleStageItemClick = (stage: number) => {
		const stageToGo = stages.find((stageItem) => stageItem?.stageNumber === stage);

		if (!stageToGo?.unknown) {
			setSelectedTab(stage - 1);
		}
	};

	const handleReturnStage = () => {
		dispatch(
			openModal({
				title: "Вернуться к предыдущему этапу?",
				body: "Заполненные данные текущего этапа могут быть потеряны.",
				variant: "alert",
				alertIcon: "warning",
				alertButtonDecline: {
					label: "Вернуться",
					onClick: () => {
						handleChangeStage("prev", "change");
						dispatch(closeModal());
					},
				},
				alertButtonSubmit: {
					label: "Остаться на текущем этапе",
					onClick: () => dispatch(closeModal()),
				},
			})
		);
	};

	return {
		selectedTab,
		goToCurrentStage,
		handleChangeStage,
		handleStageItemClick,
		handleReturnStage,
	};
};
