import { useRouteMatch } from "react-router";
import { H2 } from "@sdelka_crm/sdelka-crm-component-library";

import { PublicLayout } from "../../../../UI";
import { AgentList, DealApplications, ObjectList } from "../../components";

import styles from "./index.module.scss";

export const MainPublic = (): JSX.Element => {
	const match = useRouteMatch<{ contactId: string }>();
	const { contactId } = match.params;

	return (
		<PublicLayout classNames={{ content: styles.root }}>
			<div>
				<H2>Ваши заявки в сделке</H2>

				<DealApplications contactId={contactId} />
			</div>

			<div>
				<H2>Ваш объект</H2>

				<ObjectList contactId={contactId} />
			</div>

			<div>
				<H2>Ваш риэлтор</H2>

				<AgentList contactId={contactId} />
			</div>
		</PublicLayout>
	);
};
