import { useForm } from "react-hook-form";
import { useMemo, useState } from "react";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { MultiLayerSelect } from "../../index";
import { useLoadOffices } from "../../../../service/api/queries";
import { UseLoadUserPositions} from "../../../../service/api/queries/position/useLoadUserPositions";
import { arrValuesToMap, prepareOffices } from "../../../../utils/transformation";
import { OnPinResponsiblePayload } from "../../../../types";

import styles from "./index.module.scss";

type Props = {
	title: string;
	buttonLabel: string;
	onPin: (payload: OnPinResponsiblePayload) => void;
	checkedResponsibles?: string[];
	isPinOne?: boolean;
};

export const AddResponsible = ({
	title,
	buttonLabel,
	onPin,
	checkedResponsibles,
	isPinOne,
}: Props): JSX.Element => {
	const [selectedUser, setSelectedUser] = useState<string[]>([]);
	const { offices } = useLoadOffices({});
	const { getPositionName } = UseLoadUserPositions({});

	const { register, setValue } = useForm();

	const options = useMemo(() => prepareOffices(offices, getPositionName), [offices.length]);

	const handleSetResponsible = () => {
		const selectedMap = arrValuesToMap(selectedUser);

		onPin({
			responsiblesIds: selectedUser.length === 1 ? selectedUser : options
				.filter((user) => selectedMap[user.value] && user.layer === 2)
				.map((op) => op.value)
				.slice(0, 1),
		});
	};

	const getDefaultValues = () => {
		if (!checkedResponsibles) return [];

		const selectedMap = arrValuesToMap(checkedResponsibles);

		return options.filter((op) => selectedMap[op.value]);
	};

	return (
		<div className={styles.root}>
			<h1>{title}</h1>
			<div className={styles.container}>
				<MultiLayerSelect
					name="responsibles"
					alwaysOpen
					isSearchable
					options={options}
					register={register}
					setValue={setValue}
					onChange={setSelectedUser}
					defaultValue={getDefaultValues()}
					selectOne={isPinOne}
					classNames={{
						optionNotSelected: styles.option,
					}}
				/>
			</div>

			<div className={styles.buttonContainer}>
				<Button disabled={!selectedUser.length} onClick={handleSetResponsible}>
					{buttonLabel}
				</Button>
			</div>
		</div>
	);
};
