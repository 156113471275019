import { api } from "../../api";
import { useQuery } from "react-query";


const DEFAULT_QN = "sdelka/deal/load-deal-generated-documents/DEFAULT_QN";

type Props = {
  applicationId: string
}

export const useLoadGeneratedDocuments = ({applicationId}: Props) => {

  const loadDocuments = () =>
    api.deals.getGeneratedDocuments(applicationId)

  const { data, isLoading, refetch, isFetching, isError } = useQuery(
    [DEFAULT_QN],
    loadDocuments,
  );

  return {
    documents: data?.data.data,
    total: data?.data.total,
    isLoading,
    refetchDocuments: refetch,
    isFetching,
    isError
  }
}