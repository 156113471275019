import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { useState } from "react";
import cn from "classnames";

import { TransparentInput } from "../../../index";

import { ReactComponent as CloseIcon } from "../../../../../assets/icons/close.svg";
import styles from "./index.module.scss";

type Props = {
	register: UseFormRegister<any>;
	names: [string, string];
	separator?: string;
	placeholders?: [string, string];
	variant?: "default" | "noPadding";
	size?: "small" | "medium" | "large" | "extraLarge" | "superLarge" | "unFixed";
	minWidth?: number;
	maxWidth?: number;
	font?: "cera" | "ubuntu";
	required?: boolean;
	onFirstInputChange?: (value: any) => void;
	onSecondInputChange?: (value: any) => void;
	masks?: [any, any];
	pipes?: [any, any];
	textAlign?: "left" | "right";
	loading?: boolean;
	withCleanUp?: { setValue: UseFormSetValue<any> };
	// REQUIRED FOR MASKED INPUTS
	setValue?: UseFormSetValue<any>;
	// OPTIONAL FOR UNFIXED SIZE
	multiplyValue?: number;
	error?: string;
	value?: [string, string];
	wrapperClassName?: string;
	disabled?: boolean;
};

// Two inputs (masked or not) separated by your `separator`.
export const DoubleInput = ({
	register,
	names,
	separator = "—",
	placeholders = ["", ""],
	variant = "default",
	font = "cera",
	size = "small",
	minWidth,
	maxWidth,
	required,
	onFirstInputChange,
	onSecondInputChange,
	masks,
	pipes,
	textAlign = "left",
	setValue,
	multiplyValue = 8,
	withCleanUp,
	loading,
	error = "",
	value,
	disabled,
	wrapperClassName,
}: Props): JSX.Element => {
	const [firstWidth, setFirstWidth] = useState(18);
	const [secondWidth, setSecondWidthWidth] = useState(18);
	const [showCleanUp, setShowCleanUp] = useState(false);

	const cleanUp = () => {
		withCleanUp?.setValue(names[0], "");
		withCleanUp?.setValue(names[1], "");
	};

	if (loading) {
		return <div className={styles.loading} />;
	}

	return (
		<div
			className={cn(styles.root, styles[font], styles[size], {
				[styles.withCleanUp]: withCleanUp,
			})}
		>
			<div className={styles.inputsWrapper}>
				<div className={cn(styles.inputWrapper, wrapperClassName)}>
					<div
						style={{
							width: size === "unFixed" ? firstWidth : "auto",
							minWidth: minWidth ?? "auto",
							maxWidth: maxWidth ?? "auto",
						}}
					>
						<TransparentInput
							placeholder={placeholders[0]}
							name={names[0]}
							required={required}
							register={register}
							onChange={(val) => {
								if (val) {
									setShowCleanUp(true);
								}
								setFirstWidth(val.length * multiplyValue || 17);
								if (onFirstInputChange) {
									onFirstInputChange(val);
								}
							}}
							setValueForMask={setValue}
							mask={masks ? masks[0] : ""}
							pipe={pipes ? pipes[0] : undefined}
							textAlign={textAlign}
							variant={variant}
							value={value && value[0]}
							disabled={disabled}
						/>
					</div>
				</div>

				<div className={styles.separator}>{separator}</div>

				<div className={cn(styles.inputWrapper, wrapperClassName)}>
					<div
						style={{
							width: size === "unFixed" ? secondWidth : "auto",
							minWidth: minWidth ?? "auto",
							maxWidth: maxWidth ?? "auto",
						}}
					>
						<TransparentInput
							placeholder={placeholders[1]}
							name={names[1]}
							required={required}
							register={register}
							onChange={(val) => {
								if (val) {
									setShowCleanUp(true);
								}
								setSecondWidthWidth(val.length * multiplyValue || 17);
								if (onSecondInputChange) {
									onSecondInputChange(val);
								}
							}}
							setValueForMask={setValue}
							mask={masks ? masks[1] : ""}
							pipe={pipes ? pipes[1] : undefined}
							variant={variant}
							value={value && value[1]}
							disabled={disabled}
						/>
					</div>
				</div>

				{withCleanUp && showCleanUp && (
					<div onClick={cleanUp} className={styles.close}>
						<CloseIcon />
					</div>
				)}
			</div>
			{error && <span className={styles.error}>{error}</span>}
		</div>
	);
};
