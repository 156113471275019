import { AxiosError, AxiosResponse } from "axios";
import { UseFormSetError } from "react-hook-form";
import { useMutation } from "react-query";

import { api } from "../../index";
import { axiosError } from "../../../../utils/errors";

const DEFAULT_MN = "sdelka/auth/require-phone/DEFAULT_MN";

type UseRequirePhone = {
	onSuccess?: (
		data: AxiosResponse<{ token: string }>,
		variables: string,
		context: unknown
	) => void | Promise<unknown>;
	setError?: UseFormSetError<any>;
};

export const useRequirePhone = ({ onSuccess, setError }: UseRequirePhone) => {
	const onError = (error: AxiosError) => {
		const message = axiosError({ error });

		if (setError) {
			if (message.includes("account with this phone does not exists!")) {
				setError("phone", {
					message: "Аккаунт с таким телефоном не существует",
				});
			} else if (message.includes("phone must be a valid phone number")) {
				setError("phone", { message: "Невалидный телефон" });
			} else {
				setError("phone", { message });
			}
		}
	};

	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (phone: string) => api.auth.phoneVerification({ phone }),
		onSuccess,
		onError,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
