import { Office } from "../../../../../types";
import styles from "./index.module.scss"
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { MenuItem } from "../../../ShowsAndViews/components/card-actions/CardActions";
import { ReactComponent as Arrows } from "../../../../../assets/icons/DoubleChevron_Right.svg";
import { Popup } from "../../../../UI";
import cn from "classnames";
import { useSetCurrentOffice } from "../../../../../service/api/mutations/user/useSetCurrentOffice";
import { useCreateDepartmentModal } from "../create-department-modal";
import { ReactComponent as IconDots } from "../../../../../assets/icons/iconDotsInCircle.svg";

type Props = {
  office?: Office
  refetch: () => void;
  isMobile?: boolean
}

export const OfficeCardStickyFooter = (
  {
    office,
    refetch,
    isMobile
  }: Props): JSX.Element => {
  const {mutate} = useSetCurrentOffice({onSuccess: () => refetch(), officeId: office?.id ?? ""})
  const {openCreateDepartmentModal} = useCreateDepartmentModal({id: office?.id ?? "", refetch})

  const actionsMenu: MenuItem[] = [
    {
      label: "Создать отдел",
      icon: <Arrows/>,
      onClick: openCreateDepartmentModal,
      id: 1
    },
  ]

  return (
    <div className={styles.root}>
      {office?.isCurrentOffice ? <div className={styles.actions}>
          <Popup
            openTrigger={isMobile ? <IconDots/> : <div className={styles.openTrigger}>Другие действия</div>}
            options={{withArrow: true}}
            classNames={
              {
                trigger: styles.trigger,
                arrow: styles.arrow,
                card: styles.popupCard
              }
            }
          >
            {actionsMenu.map((item) => (
              <div key={item.id} className={cn(
                styles.actionItem,
                {[ styles.deleteActionItem ]: item.className !== undefined}
              )} onClick={item.onClick}>
                {item.icon}
                <span>{item.label}</span>
              </div>
            ))}
          </Popup>
        </div>
        : <Button
          onClick={() => mutate()}
        >
          Переключиться на офис
        </Button>
      }
    </div>
  )
}