export const deleteEmptyField = <T>(myObject: T): T => {
	const clearedObject = { ...myObject };
	// @ts-ignore
	Object.keys(clearedObject).forEach((key) => {
		const type = typeof clearedObject[key];

		if (type === "undefined") {
			delete clearedObject[key];

			return;
		}
		if (clearedObject[key]?.length === 0) {
			delete clearedObject[key];
		} else if (type === "object") {
			const clearedField = deleteEmptyField(clearedObject[key]);

			if (Object.keys(clearedField)?.length > 0) {
				clearedObject[key] = clearedField;
			} else if (!(clearedObject[key] instanceof Date)) {
				delete clearedObject[key];
			}
		}
	});

	return clearedObject;
};
