import { useMutation } from "react-query";
import { api } from "../../api";
import { CartPayload } from "../../../../types";

const DEFAULT_MN = "sdelka/compilation/use-update-cart/DEFAULT_MN";

type UseUpdateCart = {
  onSuccess?: () => void;
};

export const useUpdateCart = ({ onSuccess }: UseUpdateCart) => {
  const mutate = useMutation(DEFAULT_MN, {
    mutationFn: (payload: CartPayload) => api.cart.update(payload),
    onSuccess,
  });

  return {
    data: mutate.data?.data,
    mutate: mutate.mutate,
    isLoading: mutate.isLoading,
  };
};
