import { Office } from "../../../../types";
import styles from "./index.module.scss";
import { useColumns } from "./columns";
import { useCallback } from "react";
import cn from "classnames";
import { useBlinkRow, useQueryParams } from "../../../../utils/hooks";
import { useOpenSelectedOfficeCard } from "../../../../service/servise";
import { Table } from "../../../UI";

type Props = {
  officesList: Office[];
  isLoading: boolean;
  isMobile?: boolean
  isSelect?: boolean
};

export const OfficesTable = (
  {
    officesList,
    isLoading,
    isMobile,
    isSelect
  }: Props): JSX.Element => {
  const {changeQueryParams} = useQueryParams<{
    userId: string;
  }>([{name: "officeId"}]);
  const columns = useColumns(isMobile);
  const {rowsToBlink} = useBlinkRow();

  const {openOfficeDrawer} = useOpenSelectedOfficeCard({});

  const renderTitle: (item: boolean | string) => JSX.Element = useCallback(
    (isCurrentOffice: boolean | string) =>
      // const ids = usersList.filter(elem => elem.status === status).map(elem => elem.id)
      (
        <div>
          <div className={styles.title}>
            {isCurrentOffice ? "Вы здесь" : "Офисы"}
          </div>
        </div>
      )
    ,
    []
  );

  const handleRowClick = (row: Office) => {
    changeQueryParams([
      {name: "officeId", newValue: row.id},
    ]);
    openOfficeDrawer(row.id)
  };

  return (
    <div className={cn(styles.wrapper, {[ styles.mini ]: isMobile})}>
      {officesList?.length > 0 || !isLoading ? (
        <Table<Office>
          idKey="id"
          data={officesList}
          columns={columns}
          groupKey="isCurrentOffice"
          rowClass={styles.row}
          transformGroupTitle={renderTitle}
          onRowClick={handleRowClick}
          loading={{isLoading, rowsCount: 5}}
          activeRow={{
            accessor: "",
            activeId: 1,
          }}
          blinkRows={rowsToBlink}
        />
      ) : (
        <div className={styles.empty}>Похожих офисов не найдено</div>
      )}
    </div>
  )
}