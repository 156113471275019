import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RealEstateWithSchema } from "../../../../types";
import { Coord, setAddress } from "../../../../service/redux/modules/object-edit/object-edit.action";
import { useFindPointDetailed } from "../../../../service/api/mutations";
import { selectObjectEdit, useSelect } from "../../../../service/redux/selectors";
import styles from "./index.module.scss"

type Props = {
  realEstate?: RealEstateWithSchema;
  isEditOrCreate?: boolean;
  allowChangeByMap?: boolean
};

type Return = {
  onMapClick: (coordinates: [number, number]) => Promise<void>;
  point: Coord
};

type UseMapAdjust = (props: Props) => Return;

export const useMapAdjust: UseMapAdjust = ({isEditOrCreate, realEstate, allowChangeByMap}: Props) => {
  const dispatch = useDispatch();
  const {addressId, addressLabel, noHomeNumber, coord} = useSelect(selectObjectEdit);

  const findAddress = useFindPointDetailed({});

  useEffect(() => {
    if (allowChangeByMap === false) {
      return
    }
    if (realEstate?.location?.coordinates) {
      dispatch(
        setAddress({
          coord: {
            lat: realEstate?.location?.coordinates[ 0 ],
            lon: realEstate?.location?.coordinates[ 1 ],
          },
          addressId: realEstate?.addressId,
          addressLabel: realEstate?.addressLabel,
        })
      );
    }
  }, [allowChangeByMap, dispatch, realEstate, realEstate?.location, realEstate?.location?.coordinates]);

  const [point, setPoint] = useState(coord)

  const [isMapBusy, setIsMapBusy] = useState(false)

  useEffect(() => {
    if (findAddress.status === "loading") {
      setIsMapBusy(true)
    }
    if (findAddress.status === "success") {
      (window as any).mapStatus = false;
      setIsMapBusy( false)
    }
  }, [findAddress.status])


  const onMapClick = async (coordinates: [number, number]) => {
    if ((window as any).mapStatus) {
    } else  if (isEditOrCreate) {
      (window as any).mapStatus = true
      setPoint({lat: coordinates[ 0 ], lon: coordinates[ 1 ]})
      const address = await findAddress.mutateAsync(`${coordinates[ 1 ]},${coordinates[ 0 ]}`);

      if (allowChangeByMap === false) {
        dispatch(
          setAddress({
            coord: {
              lat: coordinates[ 0 ],
              lon: coordinates[ 1 ],
            },
            addressId,
            addressLabel,
          })
        );
      } else {
        dispatch(
          setAddress({
            coord: {
              lat: coordinates[ 0 ],
              lon: coordinates[ 1 ],
            },
            addressId: address.data[ 0 ]?.id && !noHomeNumber ? address.data[ 0 ]?.id : addressId,
            addressLabel: address.data[ 0 ]?.label && !noHomeNumber ? `${address.data[ 0 ].label}` : addressLabel,
          })
        );
      }
    }
  };

  return {onMapClick, point};
};
