import { H3 } from "@sdelka_crm/sdelka-crm-component-library";
import { User } from "../../../../../../../types";
import styles from "./index.module.scss";
import { CardContact } from "../../../../../../UI/Card/card-contact/CardContact";

type Props = {
  responsibleUser: User;
  isMobile?: boolean
};

export const Responsibles = ({responsibleUser, isMobile}: Props): JSX.Element => {
  if (!responsibleUser) {
    return <></>;
  }

  return (
    <div style={{marginTop: "60px"}}>
      <H3>Ответственные по объекту</H3>

      <div className={styles.card}>
        <CardContact
          contact={responsibleUser.account}
          withAvatar
          withoutLinks
          withoutDots
          mini={isMobile}
        />
      </div>
    </div>
  );
};
