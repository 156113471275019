import { useQuery } from "react-query";

import { api } from "../../index";

type UseLoadYourAgents = {
	contactId: string;
};

export const useLoadYourAgents = ({ contactId }: UseLoadYourAgents) => {
	const loadYourAgents = () => api.publicPage.agents(contactId);

	const { data, isLoading, refetch } = useQuery("loadYourAgents", loadYourAgents);

	return {
		data: data?.data || [],
		isLoading,
		refetch,
	};
};
