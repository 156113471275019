import { InfoRow, Textarea, TransparentInput } from "../../../index";
import { Deadline, Executor } from "./components";
import { useSelector } from "../../../../../service/redux/useSelector";
import { prepareUserData } from "../../../../../utils/user/prepareUserData";
import { prepareShortUserOutput } from "../../../../../utils/transformation";
import { useTaskFormContext } from "../../../../../service/context/TaskForm";
import styles from "./index.module.scss";

export const Form = (): JSX.Element => {
  const {register, errors} = useTaskFormContext();

  const {currentAccount} = useSelector((state) => state.currentAccount);

  const {name, secondName, lastName} = prepareUserData(currentAccount);

  return (
    <>
      <div className={styles.rows}>
        <Deadline/>
        <Executor classnames={styles.executor}/>
        <InfoRow classNames={{root: styles.infoRow}} labelFont="ubuntu" label="Создатель">
					<span>
						{prepareShortUserOutput({
              name,
              lastName,
              secondName,
            })}
					</span>
        </InfoRow>
      </div>
      <div className={styles.inputs}>
        <div className={styles.inputWrapper}>
          <TransparentInput
            name="name"
            className={styles.name}
            placeholder="Введите имя задачи"
            register={register}
            error={errors?.name ? "Обязательное поле" : ""}
          />
        </div>
        <Textarea
          name="description"
          className={styles.textarea}
          required
          placeholder="Введите описание задачи."
          register={register}
          error={errors?.description ? "Обязательное поле" : ""}
          rows={10}
        />
      </div>
    </>
  );
};
