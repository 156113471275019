import { RealEstate } from "../../../../types";
import styles from "./index.module.scss";
import { CardObject } from "../CardObject";
import { CardObjectMini } from "../CardObjectMini";
import { useIsTelegram } from "../../../../utils/telegram/useIsTelegram";

type Props = {
  clientObject: RealEstate;
  isMobile?: boolean
};

export const CardObjectHeader = ({clientObject, isMobile}: Props): JSX.Element => {
  const {applicationId} = clientObject;

  const {executeUrl} = useIsTelegram()

  const openObject = () => {
    executeUrl(`/real-estate?tab=AGENCY&applicationId=${applicationId}`)
  }

  return (
    <div className={styles.root} onClick={openObject}>
      {isMobile
        ?  <CardObjectMini
          id={clientObject.id}
          objectData={clientObject}
          canOpenANewTab
          isHovered
          withPrice
          onCardClick={openObject}
        />
        : <CardObject
        clientObject={clientObject}
        id={clientObject.id}
        variant="atApp"
        options={{
          canOpenANewTab: true
        }}
        classNames={{
          content: styles.cardContent,
          owner: styles.responsible
        }}
        cardBackground="white"
        onCardClick={openObject}
      />}
    </div>
  );
};
