import { useDispatch } from "react-redux";
import { openModal } from "../../../../../../../../../../../../../../../service/redux/modules/modal/modal.action";
import { CancelApplicationModal } from "./CancelApplicationModal";
import styles from "../../apply-service-modal/index.module.scss";

type Props = {
  applicationId: string
  whoIsClient: "seller" | "buyer" | undefined
  isMobile?: boolean
  refetch?: () => void
}

export const useOpenCancelApplicationModal = (
  {
    applicationId,
    whoIsClient,
    isMobile,
    refetch
  }: Props) => {
  const dispatch = useDispatch()

  const handleCancelApplicationModal = () => {
    dispatch(
      openModal({
        body: <CancelApplicationModal
          whoIsClient={whoIsClient}
          applicationId={applicationId}
          refetch={refetch}
        />,
        width: isMobile ? 500 : 660,
        classNames: {
          contentWrapper: isMobile ? styles.contentWrapperClassNameMobile : "",
          buttonClose: isMobile ? styles.modalClose : ""
        }
      })
    )
  }
  return {handleCancelApplicationModal}
}