import { useState, useEffect } from "react";
import { Control } from "react-hook-form";

import { RadioButton } from "../../../../../../../../index";

import styles from "./index.module.scss";

type Props = {
	control: Control<any>;
	name: string;
	handleOnChange?: (value: string | number) => void;
	required?: boolean;
};

export const ObjectRadio = ({ control, name, handleOnChange, required }: Props): JSX.Element => {
	const [currentButtons, setCurrentButtons] = useState<
		{ value: string | number; label: string }[] | undefined
	>(undefined);

	const radioOptions = [
		{ value: 0, label: "Нет" },
		{ value: 1, label: "1" },
		{ value: 2, label: "2" },
		{ value: 3, label: "3" },
		{ value: 4, label: "4" },
	];

	const agentBonusOptions = [
		{ value: "no", label: "Нет" },
		{ value: "fixed", label: "Фикс." },
		{ value: "percent", label: "%" },
	];

	useEffect(() => {
		if (["additionalProperties.agentBonusType", "additionalProperties.clientFeeType"].includes(name)) {
			setCurrentButtons(agentBonusOptions);
		} else {
			setCurrentButtons(radioOptions);
		}
	}, [name]);

	return (
		<div className={styles.root}>
			<div className={styles.radioWrapper}>
				{currentButtons ? (
					<RadioButton
						name={name}
						control={control}
						options={currentButtons}
						separate
						required={required}
						handleOnChange={handleOnChange}
					/>
				) : (
					<div className={styles.loading} />
				)}
			</div>

			<div className={styles.hideLine} />
		</div>
	);
};
