import isEqual from "lodash.isequal";
import { memo, useMemo } from "react";
import { RealEstate } from "../../../../../../types";
import cn from "classnames";
import styles from "../../index.module.scss";
import { Popover } from "../../../../Popover";
import { ReactComponent as OpenInNew } from "../../../../../../assets/icons/open-in-new.svg";

export type Props = {
  object: RealEstate
  canOpenANewTab?: boolean
  isCompilation?: boolean
  classNames?: string
  selected?: boolean
  isMini?: boolean
}

export const CardObjectStage = memo((
  {
    object,
    isCompilation,
    canOpenANewTab,
    classNames,
    selected,
    isMini
  }: Props): JSX.Element => {
  const {
    hasPreApplication,
    stageNo,
    stage,
    shortType,
    parserData,
  } = object

  const getColor = useMemo(() => {
    switch (shortType?.kind) {
      case 'preApplication':
        return "#D5D3CE";
      case  'application':
        return "#0FB092"
      case 'deal':
        return "#336BEB"
      case "successDeal":
        return `${styles.linear}`
      default:
        return "white"
    }
  }, [shortType]);

  const isParser = !isEqual(parserData, undefined);
  // eslint-disable-next-line no-nested-ternary
  const preAppStyle = isCompilation
    ? cn(styles.atCompilation, classNames, (hasPreApplication ? styles.hasPreApplication : cn(styles.atCompilation)))
    : (hasPreApplication ? cn(styles.hasPreApplication, classNames) : "")
  return (
    <>
      {isParser &&
        <div className={preAppStyle} style={{borderColor: "#D5D3CE"}}>
          {canOpenANewTab && <OpenInNew/>}
          <Popover
            classNames={{over: styles.stagePopup}}
            overElement={
              <>
								<div className={styles.stageCircle} style={{background: "#D5D3CE"}}>
									{stageNo}
								</div>
                <div>
                {`Предзаявка: Этап ${stageNo}: ${stage}`}
                </div>
              </>
            }
          />
        </div>}
      {!isParser &&
        <div
          style={{background: getColor, borderColor: getColor}}
          className={cn(styles.atCompilation, classNames,
            {
              [ styles.selected ]: selected,
              [ styles.isMini ]: isMini,
            }
          )}
        >
          {canOpenANewTab && <OpenInNew/>}
          <Popover
            classNames={{over: styles.stagePopup}}
            overElement={
              <>
								<div className={styles.stageCircle} style={{background: getColor}}>
									{stageNo}
								</div>
                <div>
                  {`Этап ${stageNo}: ${stage}`}
                </div>

              </>
            }
          />
        </div>
      }
    </>
  )
})