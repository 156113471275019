import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../../../../service/redux/modules/modal/modal.action";
import { useCreateDepartment } from "../../../../../service/api/mutations";
import { CreateDepartmentModal } from "./CreateDepartmentModal";

type Props = {
  id: string
  refetch: () => void
}

type Return = {
  openCreateDepartmentModal: () => void;
}

export const useCreateDepartmentModal = (
  {
    id,
    refetch
  }: Props): Return => {
  const dispatch = useDispatch();

  const {mutate} = useCreateDepartment({
    onSuccess: () => {
      dispatch(closeModal())
      refetch()
    }
  })

  const handleCreate = (name: string | undefined) => {
    if (name) {
      mutate({
        name,
        officeId: id
      })
    }
  }

  const openCreateDepartmentModal = () => {
    dispatch(
      openModal({
        body: (
          <CreateDepartmentModal onCreate={handleCreate}/>
        ),
        width: 800,
      })
    );
  };

  return {
    openCreateDepartmentModal
  }
}