import { Application, ApplicationInListV2, Contact, RealEstate } from "../index";

export type ShowsViewsConditions = {
  isNo: boolean;
  isThinking: boolean;
  isYes: boolean;
  isSuccess: boolean;
  isPreSuccess: boolean;
};

export type ShowData = {
  clientObjectId: string;
  sellerContactId?: string;
  sellerAgentContactId?: string;
  buyerContactId?: string;
  buyerAgentContactId?: string;
  plannedDate: Date;
};

export type ShowsViewsType = "show" | "view";

export type ShowsViewsStatus =
  | "success"
  | "waiting"
  | "successFinished"
  | "canceled"
  | "finished"


export type ShowsViewsCommunicate = "client" | "agent";

export type ShowsViewsPositionValue = "yes" | "no" ;

export type ShowsOrViews = "shows" | "views";

export type ShowsViewsStats = {
  waiting: number;
  finished: number;
  requests: number
  total: number;
  hasNewRequest?: boolean,
  hasNewPlnned?: boolean,
};

export enum ShowAndViewsTabs {
  finished = "finished",
  requests = "requests",
  waiting = "waiting"
}

export type ShowsViewsApplicationCardCount = ShowsViewsStats & {
  convertedToLead: number;
};

export type ShowsViews = {
  id: string;
  showShortId: string
  viewShortId: string
  showId?: string
  viewId?: string
  showApplicationId?: string
  viewApplicationId?: string
  applicationShortId: string
  applicationCreatedAt?: Date
  dealId: string;
  type: ShowsViewsType;
  status: ShowsViewsStatus;
  isConfirmed?: boolean
  actIsSigned?: boolean
  isCreatedByOtherAgent?: boolean
  offer?: number
  isMaxOffer?: boolean
  finalPrice?: number
  realDate?: Date
  plannedDate: Date;
  cancelDate?: Date
  // Обратная связь, показ
  buyerNeedsHelpToSellHisObjectPreApplication?: ApplicationInListV2
  buyerNeedsHelpToSellHisObjectPreApplicationTemplate?: ApplicationInListV2
  // Обратная связь, просмотр
  sellerNeedsHelpToSellHisObjectPreApplication?: ApplicationInListV2
  sellerNeedsHelpToSellHisObjectPreApplicationTemplate?: ApplicationInListV2
  sellerNeedsHelpToAnotherNewObjectPreApplication?: ApplicationInListV2
  sellerNeedsHelpToAnotherNewObjectPreApplicationTemplate?: ApplicationInListV2
  agentComment?: string;
  clientAnswerComment?: string;
  result?: ShowsAndViewResultEnum;
  presentationLink?: string;
  publicPageLink?: string;
  actLink?: string;
  realEstateObject?: RealEstate
  clientObjectApplication?: RealEstate
  clientObject: RealEstate;
  sellerContact?: Contact
  sellerContactId?: string
  sellerAgentContact?: Contact
  sellerAgentContactId?: string
  buyerContact?: Contact
  buyerContactId?: string
  buyerAgentContact?: Contact
  buyerAgentContactId?: string
  createdAt: Date;
  modifiedAt: Date;
  cancelReason: string | null;
  cancelReasonComment: string | null;
  communicateWith: ShowsViewsCommunicate;
  dislikeReason: string | null;
  dislikeComment: string | null;
  buyerNeedsHelpToAnotherNewObject: boolean;
  buyerSellsHisObject: boolean;
  buyerNeedsHelpToSellHisObject: boolean;
  sellerNeedsHelpToSellHisObject: boolean;
  sellerBuyAnotherObject: boolean;
  sellerNeedsHelpToAnotherNewObject: boolean;
  selectedApplication?: ApplicationInListV2
};

export enum ShowsAndViewResultEnum {
  yes = 'yes',
  no = 'no',
  thinking = 'thinking',
}

