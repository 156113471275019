import { ReactNode } from "react";
import cn from "classnames";
import { Loader } from "@sdelka_crm/sdelka-crm-component-library";

import { DefaultSelectProps } from "../../../../../../types";

import { ReactComponent as ArrowIconSmall } from "../../../../../../assets/icons/arrow.svg";
import { ReactComponent as ArrowIconDefault } from "../../../../../../assets/icons/arrow-down.svg";
import selectStyles from "../selectStyles.module.scss";

type Props = {
	children: ReactNode;
	isListOpen: boolean;
	onSelectClick: () => void;

} & Pick<
	DefaultSelectProps,
	"append" | "prepend" | "hideArrow" | "disabled" | "isLoading" | "size" | "classNames"
>;

export const SelectedContainer = ({
	children,
	isListOpen,
	onSelectClick,
	append,
	prepend,
	hideArrow,
	disabled,
	isLoading,
	size,
	classNames,
}: Props): JSX.Element => (
	<div className={cn(selectStyles.selectedVariant, classNames?.selected)} onClick={onSelectClick}>
		{prepend && <div className={selectStyles.prepend}>{prepend}</div>}

		{children}

		{isLoading && (
			<div className={selectStyles.selectLoader}>
				<Loader />
			</div>
		)}

		{append && <div className={cn(selectStyles.append)}>{append}</div>}

		{!hideArrow && (
			<div
				className={cn(selectStyles.arrowContainer, classNames?.arrowContainer, {
					[selectStyles.arrowContainerDisabled]: disabled,
					[selectStyles.arrowContainerSelectOpen]: isListOpen,
				})}
			>
				{size === "normal" && <ArrowIconDefault className={selectStyles.iconDefault} />}
				{size === "small" && <ArrowIconSmall className={selectStyles.iconSmall} />}
			</div>
		)}
	</div>
);
