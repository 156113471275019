import { useWatch } from "react-hook-form";
import { RealEstateSchemaConfigField, FormPropsEditRealEstate } from "../../../../../../../../../../types";

import { Time } from "../../../../../../../../index";

import styles from "./index.module.scss";

type Props = {
	form: FormPropsEditRealEstate;
	field: RealEstateSchemaConfigField;
};

export const TimeTemplate = ({ form, field }: Props): JSX.Element => {
	const { control, register, getValues } = form;
	const {
		dataType: { name = "" },
	} = field;

	const timeWatcher = useWatch({
		control,
		name,
		defaultValue: getValues(name) || undefined,
	});

	if (!name) {
		return <></>;
	}

	return (
		<Time
			register={register}
			name={name}
			hideLabel
			classNames={{
				wrapper: styles.timeWrapper,
			}}
			value={timeWatcher}
		/>
	);
};
