import { useMemo } from "react";
import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import { User } from "../../../../../../types";

import { InfoRow, Select } from "../../../../index";
import { prepareShortUserOutput } from "../../../../../../utils/transformation";

type Props = {
	control: Control<any>;
	name: string;
	register: UseFormRegister<any>;
	setValue: UseFormSetValue<any>;
	label: string;
	getValues: UseFormGetValues<any>;
	users: User[];
	disabled?: boolean;
};

export const Creator = ({
	control,
	name,
	register,
	setValue,
	label,
	getValues,
	users,
	disabled,
}: Props): JSX.Element => {
	const usersOption = useMemo(
		() =>
			users.map((user) => ({
				label: prepareShortUserOutput(user.account),
				value: user.id,
			})),
		[users]
	);

	const watchCreator = useWatch({
		control,
		name,
		defaultValue: getValues(name) || "",
	});

	return (
		<InfoRow aspect={{ label: 8, content: 7.3 }} label={label}>
			<Select
				name={name}
				setValue={setValue}
				options={usersOption}
				register={register}
				placeholder="Выбрать"
				defaultValue={watchCreator}
				disabled={disabled}
			/>
		</InfoRow>
	);
};
