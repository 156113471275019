import { useState } from "react";
import { Control, UseFormRegister, UseFormSetValue } from "react-hook-form";

import { InfoRow, MultiCheckbox, RadioButton } from "../../../../index";
import { RealEstateFilterField } from "../../../../../../types";

type Props = {
	field: RealEstateFilterField;
	setValue: UseFormSetValue<any>;
	register: UseFormRegister<any>;
	control: Control<any>;
	disabled?: boolean;
};

// If first value = 'В доме', shows two more options.
export const Bathroom = ({ field, register, setValue, control, disabled }: Props): JSX.Element => {
	const [selectedOption, setSelectedOption] = useState<any>();

	const radioButtonValues = [
		{ value: "В доме", label: "В доме", disabled },
		{ value: "На улице", label: "На улице", disabled },
	];

	const countValues = [
		{ value: "1", label: "1", disabled },
		{ value: "2", label: "2", disabled },
		{ value: "3", label: "3", disabled },
		{ value: "4", label: "4", disabled },
	];

	return (
		<>
			<InfoRow variant="singleBorder" aspect={{ label: 8, content: 8.2 }} label="Санузел">
				<RadioButton
					separate
					name={field.dataType.nameGroup[0]}
					options={radioButtonValues}
					control={control}
					size="small"
					handleOnChange={(value) => setSelectedOption(selectedOption === value ? undefined : value)}
				/>
			</InfoRow>
			{selectedOption === "В доме" && (
				<>
					<InfoRow variant="singleBorder" aspect={{ label: 8, content: 8.2 }} label="Раздельные санузлы">
						<MultiCheckbox
							name={field.dataType.nameGroup[1]}
							options={countValues}
							register={register}
							setValue={setValue}
							variant="radioButtonWhite"
							margin="small"
						/>
					</InfoRow>
					<InfoRow variant="singleBorder" aspect={{ label: 8, content: 8.2 }} label="Совмещенные санузлы">
						<MultiCheckbox
							name={field.dataType.nameGroup[2]}
							options={countValues}
							register={register}
							setValue={setValue}
							variant="radioButtonWhite"
							margin="small"
						/>
					</InfoRow>
				</>
			)}
		</>
	);
};
