import { useRef } from "react";
import { UseFormSetValue } from "react-hook-form";

type Props = {
	datetimeFieldName: string;
	onChangeGlobal: (datetime: string) => void;
	setValue: UseFormSetValue<any>;
};

export const useDatetime = ({ onChangeGlobal, datetimeFieldName, setValue }: Props) => {
	// date as string
	const date = useRef("");

	// time as '00:00'-alike string
	const time = useRef("");

	// combine date and time and represent as a string
	const getDatetime = () =>
		time.current ? date.current.replace(/\s\d\d:\d\d/, ` ${time.current}`) : date.current;

	const onDateChange = (newDate: Date | Date[]) => {
		const wantedNewDate = Array.isArray(newDate) ? newDate[0] : newDate;

		date.current = wantedNewDate.toString();

		const datetime = getDatetime();

		setValue(datetimeFieldName, datetime);
		onChangeGlobal(datetime);
	};

	const onTimeChange = (newTime: string) => {
		time.current = newTime;

		const datetime = getDatetime();

		setValue(datetimeFieldName, datetime);
		onChangeGlobal(datetime);
	};

	return {
		onDateChange,
		onTimeChange,
	};
};
