import { memo, useEffect, useState } from "react";
import cn from "classnames";
import { formatDate, FNSFormatType } from "@sdelka_crm/sdelka-crm-component-library";

import { Popup, Calendar, CalendarProps } from "../../index";
import { UseClickOutside } from "../../../../utils/hooks";

import { ReactComponent as CalendarIcon } from "../../../../assets/icons/calendar.svg";
import styles from "./index.module.scss";

type Props = Pick<
	CalendarProps,
	"form" | "view" | "permanentView" | "onDateChange" | "onClickMonth" | "disableDates"
> &
	Pick<UseClickOutside, "withClickListener"> & {
		defaultDate?: Date;
		disabled?: boolean
		formatDateType?: FNSFormatType;
		error?: string;
		canNotSelectPast?: boolean
		classNames?: {
			wrapper?: string;
			label?: string;
			trigger?: string;
			error?: string;
		};
	};

export const CalendarInPopup = memo(
	({
		form,
		view,
		permanentView,
		onDateChange,
		onClickMonth,
		defaultDate,
		error,
		classNames,
		disableDates,
		disabled,
		formatDateType = "full",
		withClickListener,
		canNotSelectPast
	}: Props): JSX.Element => {
		const DATE_NOT_SELECTED = "00.00.00";

		const [value, setValue] = useState<Date | undefined>(defaultDate ? new Date(defaultDate) : undefined);
		const isTouched = value !== defaultDate;
		const [dateError, setDateError] = useState<string | undefined>(error)

		useEffect(() => {
			setDateError(error)
		}, [error])

		useEffect(() => {
			if (defaultDate) {
				setValue(defaultDate)
			}
			if (defaultDate === undefined) {
				setValue(defaultDate)
			}
		}, [defaultDate])

		const label = value ? formatDate(value, formatDateType) : DATE_NOT_SELECTED;

		const handleDateChange = (date: Date[] | Date) => {
			// This component does not support ranges
			// Use first element of array as fallback
			if (Array.isArray(date)) {
				handleDateChange(date[0] ?? "");
				return;
			}

			if (canNotSelectPast && (date.getTime() < Date.now())) {
				setDateError("Невалидная дата")
			} else {
				setDateError(undefined)
				setValue(date);
				onDateChange?.(date);
			}
		};

		const handleMonthClick = (clickedDate: Date) => {
			setValue(clickedDate);
			onClickMonth?.(clickedDate);
		};

		return (
			<div className={cn(styles.wrapper, classNames?.wrapper)}>
				<Popup
					classNames={{
						trigger: classNames?.trigger,
						wrapper: styles.popupWrapper,
						card: styles.card
					}}
					disabled={disabled}
					openTrigger={
						<div
							className={cn(styles.label, classNames?.label, {
								[styles.notSelectedDate]: !value,
							})}
						>
							<CalendarIcon />
							{label}
						</div>
					}
					options={{ withClickListener }}
				>
					<div className={styles.popupContent}>
						<Calendar
							form={form}
							onDateChange={handleDateChange}
							onClickMonth={handleMonthClick}
							callRerender
							defaultDate={isTouched ? value : defaultDate}
							disableDates={disableDates}
							view={view}
							permanentView={permanentView}
						/>
					</div>
				</Popup>
				{dateError && <div className={cn(styles.error, classNames?.error)}>{dateError}</div>}
			</div>
		);
	}
);
