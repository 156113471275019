import {
  Control,
  FormState,
  useFieldArray,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { InfoRow } from "@sdelka_crm/sdelka-crm-component-library";

import { AddressSelect, CalendarInPopup, InputList, Select, TransparentInput, } from "../../../../../../../../../UI";
import { ExistedContact } from "./components";
import { useFormWatcher } from "./helpers";
import { parsePhone } from "../../../../../../../../../../utils/parsers";
import {
  ContactTypeMap,
  FindContactByPhoneResponse,
  FindContactByPhoneResponseExist,
  Phone,
} from "../../../../../../../../../../types";
import styles from "./index.module.scss";

type Props = {
  control: Control<any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  formState: FormState<any>;
  getValues: UseFormGetValues<any>;
  foundContact?: FindContactByPhoneResponse;
};

const phoneInputsNameBuilder = (baseName: string, index: number, objectKey: string) =>
  index === 0 ? "mainPhone" : `${baseName}.${index}.${objectKey}`;

export const EditForm = (
  {
    control,
    register,
    setValue,
    formState,
    getValues,
    foundContact,
  }: Props): JSX.Element => {
  const {contact: existsContact} = foundContact || {};

  const {fields, append, remove} = useFieldArray({control, name: "phones"});

  const {
    typeWatcher,
    addressWatcher,
    emailWatcher,
    pseudonymWatcher,
    seriesWatcher,
    passportNumberWatcher,
    issuedByWatcher,
    issuedByCodeWatcher,
    registerAddressWatcher
  } = useFormWatcher({
    control,
    getValues,
  });

  const typeOptions = Object.keys(ContactTypeMap).map((key) => ({
    value: key,
    label: ContactTypeMap[ key ],
  }));

  const haveFirstLabel = (): boolean => {
    const phones: Phone[] = fields as Phone[];

    if (phones.length === 0) {
      return true;
    }

    return phones[ 0 ] && phones[ 0 ].type ? phones[ 0 ].type === "main" : false;
  };

  const showOtherType = typeWatcher === "other";

  return (
    <div className={styles.formWrapper}>
      <InfoRow label="Фамилия" classNames={{root: styles.row}}>
        <TransparentInput
          className={styles.input}
          placeholder="Укажите фамилию"
          name="lastName"
          error={formState?.errors?.lastName?.message?.toString()}
          withCleanUp={{setValue}}
          register={register}
        />
      </InfoRow>
      <InfoRow label="Имя" classNames={{root: styles.row}}>
        <TransparentInput
          className={styles.input}
          register={register}
          placeholder="Укажите имя"
          name="name"
          withCleanUp={{setValue}}
          error={formState?.errors?.name?.message?.toString()}
        />
      </InfoRow>
      <InfoRow label="Отчество" classNames={{root: styles.row}}>
        <TransparentInput
          className={styles.input}
          register={register}
          placeholder="Укажите отчество"
          name="secondName"
          withCleanUp={{setValue}}
          error={formState?.errors?.secondName?.message?.toString()}
        />
      </InfoRow>
      <InfoRow label="Псевдоним" classNames={{root: styles.row}}>
        <TransparentInput
          className={styles.input}
          placeholder="Укажите псевдоним"
          name="pseudonym"
          withCleanUp={{setValue}}
          register={register}
          error={formState?.errors?.pseudonym?.message?.toString()}
          value={pseudonymWatcher}
        />
      </InfoRow>
      <InputList
        fieldName="phones"
        fields={fields}
        control={control}
        nameBuilder={phoneInputsNameBuilder}
        append={append}
        remove={remove}
        firstLabel="Основной"
        otherLabels="Доп."
        objectKey="phone"
        parser={parsePhone}
        haveFirstLabel={haveFirstLabel()}
        placeholder="Укажите телефон"
      >
        {foundContact && existsContact && (
          <ExistedContact foundContact={foundContact as FindContactByPhoneResponseExist}/>
        )}
      </InputList>
      <InfoRow label="Тип" classNames={{root: styles.row}}>
        <Select
          name="type"
          options={typeOptions}
          register={register}
          error={formState?.errors?.type?.message?.toString()}
          setValue={setValue}
          defaultValue={typeWatcher}
          placeholder="Выберите тип"
          label="Выберите тип"
        />
      </InfoRow>
      {showOtherType && (
        <InfoRow label=" " classNames={{root: styles.row}} labelFont="ubuntu">
          <TransparentInput
            placeholder="Тип"
            name="otherType"
            withCleanUp={{setValue}}
            register={register}
            error={formState?.errors?.otherType?.message?.toString()}
          />
        </InfoRow>
      )}
      {/* Нужно в будущем */}
      {/* <InfoRow label="Теги" classNames={{root: styles.row}}/> */}
      <InfoRow label="E-mail" classNames={{root: styles.row}}>
        <TransparentInput
          placeholder="Укажите E-mail"
          name="email"
          withCleanUp={{setValue}}
          register={register}
          error={formState?.errors?.email?.message?.toString()}
          value={emailWatcher}
        />
      </InfoRow>
      <InfoRow label="Серия паспорта" classNames={{root: styles.row}}>
        <TransparentInput
          placeholder="Укажите серию паспорта"
          name="series"
          withCleanUp={{setValue}}
          maxLenght={4}
          register={register}
          error={formState?.errors?.series?.message?.toString()}
          value={seriesWatcher}
          numeric
        />
      </InfoRow>
      <InfoRow label="Номер паспорта" classNames={{root: styles.row}}>
        <TransparentInput
          placeholder="Укажите номер паспорта"
          name="passportNumber"
          withCleanUp={{setValue}}
          register={register}
          maxLenght={6}
          error={formState?.errors?.passportNumber?.message?.toString()}
          value={passportNumberWatcher}
          numeric
        />
      </InfoRow>
      <InfoRow label="Дата выдачи" classNames={{root: styles.row}}>
        <div className={styles.calendar}>
          <CalendarInPopup
            form={{
              name: "dateOfIssue",
              register,
              setValue,
              getValues,
            }}
            error={formState?.errors?.dateOfIssue?.message?.toString()}
          />
        </div>
      </InfoRow>
      <InfoRow label="Кем выдан" classNames={{root: styles.row}}>
        <TransparentInput
          placeholder="Укажите кем выдан паспорт"
          name="issuedBy"
          withCleanUp={{setValue}}
          register={register}
          error={formState?.errors?.issuedBy?.message?.toString()}
          value={issuedByWatcher}
        />
      </InfoRow>
      <InfoRow label="Код подразделения" classNames={{root: styles.row}}>
        <TransparentInput
          placeholder="Укажите код подразделения"
          name="issuedByCode"
          withCleanUp={{setValue}}
          maxLenght={7}
          register={register}
          error={formState?.errors?.issuedByCode?.message?.toString()}
          value={issuedByCodeWatcher}
        />
      </InfoRow>
      <InfoRow label="Адрес регистрации" classNames={{root: styles.row}}>
        <TransparentInput
          placeholder="Укажите адрес регистрации"
          name="registerAddress"
          withCleanUp={{setValue}}
          register={register}
          error={formState?.errors?.registerAddress?.message?.toString()}
          value={registerAddressWatcher}
        />
      </InfoRow>
      <InfoRow label="Адрес" classNames={{root: styles.row}}>
        <AddressSelect
          value={addressWatcher}
          name="addressObject"
          register={register}
          setValue={setValue}
          placeholder="Укажите адрес проживания"
          error={formState.errors.addressObject?.message?.toString()}
          classNames={{
            input: styles.selectInput,
          }}
        />
      </InfoRow>
      <InfoRow label="Дата рождения" classNames={{root: styles.row}}>
        <div className={styles.calendar}>
          <CalendarInPopup
            form={{
              name: "dob",
              register,
              setValue,
              getValues,
            }}
            error={formState?.errors?.dob?.message?.toString()}
          />
        </div>
      </InfoRow>
    </div>
  );
};
