import { useMutation } from "react-query";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/application/terminate-deal/DEFAULT_MN";

type UseTerminateDeal = {
  onSuccess?: () => void
}

export const useTerminateDeal= ({onSuccess}:UseTerminateDeal) => {
  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: (id: string) => api.deals.terminateDeal(id),
    onSuccess
  })

  return {
    mutate: mutation.mutate,
    mutateAsync: mutation.mutateAsync,
    isLoading: mutation.isLoading,
  }
}