export const OPEN_GALLERY_SLIDER = "sdelka/gallery-slider/OPEN_GALLERY_SLIDER";
export const CLOSE_GALLERY_SLIDER = "sdelka/gallery-slider/CLOSE_GALLERY_SLIDER";

type SetGallerySlider = {
	photos: string[];
	clickedPhoto?: string;
	canUpdatePhotos?: boolean;
	ids?: string[];
};

export type GallerySliderAction =
	| { type: typeof OPEN_GALLERY_SLIDER; payload: SetGallerySlider }
	| { type: typeof CLOSE_GALLERY_SLIDER };

export const openGallerySlider = ({
	photos,
	clickedPhoto,
	canUpdatePhotos,
	ids,
}: SetGallerySlider): GallerySliderAction => ({
	type: OPEN_GALLERY_SLIDER,
	payload: { photos, clickedPhoto, canUpdatePhotos, ids },
});

export const closeGallerySlider = (): GallerySliderAction => ({
	type: CLOSE_GALLERY_SLIDER,
});
