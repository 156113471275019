import styles from "./index.module.scss"
import { Contact, ContactHistoryTabs } from "../../../../../../types";
import { useEffect, useState } from "react";
import { ReactComponent as IconNotification } from "../../../../../../assets/icons/iconHasNotifications.svg";
import { useForm } from "react-hook-form";
import { RadioButton } from "../../../../../UI";
import { Chat } from "./chat";
import { NoData } from "@sdelka_crm/sdelka-crm-component-library";
import { TrafficTable } from "../../../../LeadsAndDeals/components";
import { useLoadTraffic } from "../../../../../../service/api/queries";

type Props = {
  contact: Contact
}

type Tabs = {
  cian?: boolean,
  avito?: boolean,
  companySite?: boolean
};

const historyTypeOptions = ({avito,}: Tabs, avitoChatId?: string) => {
  const tabs: ({ label: string, value: string, icon?: undefined } | { label: string, value: string, icon: false | JSX.Element | undefined })[]
    = [{label: `Звонки`, value: "calls"}]

  if (avitoChatId) {
    tabs.push({label: `Чат Авито`, value: "chatAvito", icon: avito && <IconNotification/>},)
  }

  return tabs
};

export const CommunicationHistory = ({contact}: Props): JSX.Element => {
  const {
    tabsNewEvents,
    id
  } = contact

  const trafficData = useLoadTraffic({});
  const {
    setFilter: setTrafficsFilter,
    isFetching: isFetchingTraffics,
  } = trafficData;

  useEffect(() => {
    setTrafficsFilter({contactId: id})
  }, [id, setTrafficsFilter])

  const historyOptions = historyTypeOptions((tabsNewEvents || {}) as Tabs, contact.avitoChatId);
  const [tab, setTab] = useState<ContactHistoryTabs>(ContactHistoryTabs.calls)

  const {control} = useForm({
    defaultValues: {
      historyType: "calls",
    },
  });

  const onTypeChange = (value: string | number) => {
    switch (value) {
      case "calls":
        return setTab(ContactHistoryTabs.calls)
      case "chatCian":
        return setTab(ContactHistoryTabs.chatCian)
      case "chatAvito":
        return setTab(ContactHistoryTabs.chatAvito)
      case "chatCompany":
        return setTab(ContactHistoryTabs.chatCompany)
      default:
        return setTab(ContactHistoryTabs.calls)
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        История коммуникаций
      </div>
      <div className={styles.body}>
        <RadioButton
          name="historyType"
          control={control}
          options={historyOptions}
          handleOnChange={onTypeChange}
          background="blue"
          backgroundButtons="blue"
        />
      </div>
      <div className={styles.chatCard}>
        {tab === ContactHistoryTabs.calls &&
          <div className={styles.tabContent}>
            <div className={styles.tableWithPagination}>
              {!isFetchingTraffics && trafficData.isError ? (
                <NoData onRefetch={trafficData.refetch}/>
              ) : (
                <TrafficTable
                  trafficList={trafficData.list}
                  isLoading={isFetchingTraffics}
                  className={{
                    root: styles.callsWrapper,
                    title: styles.callsSeparator
                  }}
                />
              )}
            </div>
          </div>
        }
        {tab !== ContactHistoryTabs.calls &&
          <Chat tab={tab} contactId={id} contact={contact}/>
        }
      </div>
    </div>
  )
}