import { KindType, WhoIsClient } from "../../../../../types";
import { useDispatch } from "react-redux";
import { api } from "../../../../api";
import {
  LayoutLoading,
  PreApplicationTemplate
} from "../../../../../components/pages/Application/PreApplicationTemplate";
import { RealEstateViewWithTabs } from "../../../../../components/pages/RealEstatePage/mode";
import { ReactComponent as Shows } from "../../../../../assets/icons/shows_and_views.svg";
import { ShowsAndViews } from "../../../../../components/pages/ShowsAndViews";
import { openDrawer, openDrawerInDrawer } from "../../../../redux/modules/drawer/drawer.action";
import { FixedNavVerticalMenu } from "../../../../../components/UI";
import { useQueryParams } from "../../../../../utils/hooks";

type OpenPreApplication = {
  applicationId: string;
  refetchLead?: () => void;
  isFromLead?: boolean
  refetchList?: () => void
  onClose?: () => void
  disableClose?: boolean;
  atDrawerInDrawer?: boolean
  hasMap?: boolean
  isMobile?: boolean
  whoIsClient?: WhoIsClient
  classNames?: {
    wrapper?: string;
    rightBlock?: string;
  };
}

export const useOpenPreApplication = (
  ) => {
  const {removeQueryParam} = useQueryParams<{ preApplicationId: string, whoIsClient: string }>([{name: "preApplicationId"}, {name: "whoIsClient"}])
  const dispatch = useDispatch()

  const openPreApplication = async (
    {
      applicationId,
      refetchLead,
      isFromLead,
      refetchList,
      atDrawerInDrawer,
      onClose,
      isMobile,
      hasMap,
      classNames,
      disableClose,
      whoIsClient
    }: OpenPreApplication) => {

    const onCloseDrawer = () => {
      removeQueryParam(["preApplicationId", "whoIsClient"])
    }

    const loadPreApplicationTemplate = () => api.preApplicationTemplate.load({applicationId});
    let applicationTemplateId

    openEmptyDrawer()

    // eslint-disable-next-line no-return-assign
    loadPreApplicationTemplate().then(res => {
      applicationTemplateId = res.data.applicationTemplateId
      openFullDrawer()
    })

    function openFullDrawer() {
      const preAppNav = {
        label: "Предзаявка",
        height: 160,
        title: "preApp",
        query: {
          name: "preApplicationId",
          value: applicationId
        }
      }

      const preAppChildren = (
        <PreApplicationTemplate
          applicationId={applicationId}
          isFromLead={isFromLead}
          refetchList={refetchList}
          refetchLead={refetchLead}
        />
      )

      const viewNav = {
        label: "Объект",
        height: 160,
        title: "view",
        query: {
          name: "preApplicationId",
          value: applicationId
        }
      };

      const viewChildren = (
        <RealEstateViewWithTabs
          id={applicationTemplateId ?? ""}
          kind={KindType.PREAPPLICATION}
          inDrawerInDrawer={atDrawerInDrawer}
        />
      )

      const showNav = {
        label: <Shows/>,
        title: "shows",
        height: 90,
      };

      const showChildren = (
        <ShowsAndViews
          applicationId={applicationId}
          type={whoIsClient === "buyer" ? "views" : "shows"}
        />
      )


      const yNavigation: Omit<FixedNavVerticalMenu, "id" | "changeTab">[] = whoIsClient === "seller" ? [preAppNav, viewNav, showNav] : [preAppNav, showNav]
      if (atDrawerInDrawer) {
        dispatch(openDrawerInDrawer({
          children: whoIsClient === "seller" ? [preAppChildren, viewChildren, showChildren] : [preAppChildren, showChildren],
          width: 800,
          yNavigation: {
            navigation: yNavigation,
            initialTab: 0,
          },
          onClose: () => {
            if (onClose) {
              onClose()
            }
            onCloseDrawer()
          },
        }))
      } else {
        dispatch(openDrawer({
          children: whoIsClient === "seller" ? [preAppChildren, viewChildren, showChildren] : [preAppChildren, showChildren],
          width: 800,
          yNavigation: {
            navigation: yNavigation,
            initialTab: 0,
          },
          onClose: () => {
            if (onClose) {
              onClose()
            }
            onCloseDrawer()
          },
          disableClose,
          hasMap,
          isMobile,
          classNames,
        }))
      }
    }

    function openEmptyDrawer() {
      if (atDrawerInDrawer) {
        dispatch(openDrawerInDrawer({
          children: <LayoutLoading/>,
          width: 800,
          onClose,
        }))
      } else {
        dispatch(openDrawer({
          children: <LayoutLoading/>,
          width: 800,
          onClose,
          disableClose,
          hasMap,
          isMobile,
          classNames,
        }))
      }
    }
  }

  return {
    openPreApplication
  }
}