import { Button, ButtonProps } from "@sdelka_crm/sdelka-crm-component-library";
import cn from "classnames";

import styles from "./index.module.scss";

type Props = Omit<ButtonProps, "variant">;

export const ButtonSquare = ({
	children,
	color,
	disabled,
	isLoading,
	Icon,
	fullWidth,
	classNames,
	type,
	onClick,
}: Props): JSX.Element => (
	<Button
		fullWidth={fullWidth}
		Icon={Icon}
		color={color}
		disabled={disabled}
		isLoading={isLoading}
		type={type}
		onClick={onClick}
		classNames={{
			root: cn(styles.root, classNames?.root),
			icon: classNames?.icon,
		}}
	>
		{children}
	</Button>
);
