import { useMutation } from "react-query";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/shows-views/make-show-view-final/DEFAULT_MN";

type Props = {
  onSuccess?: () => void;
  isViews?: boolean
}

export const useMakeShowOrViewFinal = ({onSuccess, isViews}: Props) => {
  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: (id: string) => isViews ? api.showsViews.makeFinalView(id) : api.showsViews.makeFinalShow(id),
    onSuccess
  });

  return {
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
  };
};
