import { useLoadPaidServices } from "../../../../../../../../../../service/api/queries";
import { useUpdatePaidService } from "../../../../../../../../../../service/api/mutations";
import { PlatformPaidServicesData } from "../../../../../../../../../../types";

export type PaidServiceOnClick<T> = (paidData: T) => void;

export const usePaidServices = <T>(data: PlatformPaidServicesData, format?: string) => {
	const { applicationId, feedId, feedAdId } = data;
	const {
		data: paidServices,
		isLoading,
		refetch,
		remove,
	} = useLoadPaidServices({ applicationId, feedId, format });

	const handleRefetch = () => refetch();

	const { mutate } = useUpdatePaidService({ onSuccess: handleRefetch });

	const fetchPaidServices: (val: boolean) => void = (val) => (val ? refetch() : remove());

	const onPaidServiceClick: PaidServiceOnClick<T> = (paidData) =>
		mutate({ ...paidData, feedAdId, applicationId, adPlatformAccountId: feedId });

	return {
		paidServices,
		isLoading,
		refetch,
		remove,
		fetchPaidServices,
		onPaidServiceClick,
	};
};
