import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Tabs } from "@sdelka_crm/sdelka-crm-component-library";
import styles from "./index.module.scss";
import { useQueryParams } from "../../../../utils/hooks";
import { paths } from "../../../../utils/paths";

enum TabTypeEnum {
  SELECT_OFFICE = 0,
  BECOME_A_PARTNER = 1,
}

type QueryParams = {
  tab: "SELECT_OFFICE" | "BECOME_A_PARTNER"
};

export const SelectOfficeTabs = (): JSX.Element => {
  const { push } = useHistory();
  const {
    queryParams: {
      tab,
    },
  } = useQueryParams<QueryParams>([
    { name: "tab", type: "string" },
  ]);

  const defaultTab = TabTypeEnum[tab] ?? 0


  const [activeTab, setActiveTab] = useState(defaultTab);

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab]);

  const handleChangeTab = (tabNum: number) => {
    switch (tabNum) {
      case 0: {
        push(
          `${paths.selectOffice.list}?tab=SELECT_OFFICE`
        );
        break;
      }
      case 1: {
        push(
          `${paths.selectOffice.list}?tab=BECOME_A_PARTNER`
        );
        break;
      }
      default:
        break;
    }
    setActiveTab(tabNum);
  };

  const tabsItems =  [
    <div className={styles.element} style={{ width: 250 }}>Выбрать компанию</div>,
    <div className={styles.element} style={{ width: 212 }}>Стать партнером</div>,
  ]

  return (
    <div className={styles.tabs}>
      <Tabs
        tabs={tabsItems}
        size="large"
        active={activeTab}
        onChange={handleChangeTab}
        offset={36}
        classNames={{root: styles.root, wrapper: styles.tabsRoot}}
      />
    </div>
  );
};
