import styles from "./index.module.scss"
import { ShowsViews, ShowsViewsFinishPayload } from "../../../../../types";
import { Popup } from "../../../../UI";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { ReactComponent as IconCancel } from "../../../../../assets/icons/iconClear.svg";
import { ReactComponent as IconEdit } from "../../../../../assets/icons/iconChange.svg";
import { ReactComponent as IconDownload } from "../../../../../assets/icons/iconDownload.svg";
import { ReactComponent as IconCopy } from "../../../../../assets/icons/copy-small.svg";
import { ReactNode } from "react";
import cn from "classnames";
import { useCopyText } from "../../../../../utils/hooks";
import { useCancelShowViewModal } from "./modal/cancel-modal/delete-contact-modal/useCancelShowViewModal";
import { UseOpenChangeDateModal } from "./modal/change-date-modal/useChangeDateModal";
import { useMakeShowOrViewFinal } from "../../../../../service/api/mutations/shows-views/useMakeShowViewFinal";
import { useFinishShowOrView } from "../../../../../service/api/mutations";
import { ReactComponent as IconDots } from "../../../../../assets/icons/iconDotsInCircle.svg";

type Props = {
  isViews: boolean;
  item: ShowsViews
  onSuccess: () => void
  payload: ShowsViewsFinishPayload
  isMobile?: boolean
  hasSuccessShow?: boolean
  hasSuccessView?: boolean
}

export type MenuItem = {
  label: string;
  icon: ReactNode;
  onClick: () => void;
  hide?: boolean;
  disabled?: boolean;
  id?: number
  className?: string
};

export const CardActions = (
  {
    isViews,
    item,
    onSuccess,
    payload,
    isMobile,
    hasSuccessShow,
    hasSuccessView
  }: Props): JSX.Element => {
  const {
    status,
    publicPageLink,
    actLink,
    presentationLink,
    id,
    plannedDate
  } = item

  const buttonText = status === "finished" ? `Выбрать ${isViews ? "объект" : "покупателем"}` : `Завершить ${isViews ? "просмотр" : "показ"}`
  const {onCopy} = useCopyText({successToastText: "Скопировано успешно"})
  const handleCopyText = (text?: string) => {
    if (!text) {
      return
    }
    onCopy(text)
  }

  const {openCancelShowViewModal} = useCancelShowViewModal(isViews, id, onSuccess)
  const {openOpenChangeDateModal} = UseOpenChangeDateModal({id, onSuccess, isViews, plannedDate})

  const handleOpenPresentation = () => {
    window.open(`/${presentationLink}`, "_blank");
  };

  const handleOpenAct = () => {
    window.open(`/${actLink}`, "_blank");
  };

  const actionsMenu: MenuItem[] = [
    {
      label: "Скачать презентацию",
      icon: <IconDownload/>,
      onClick: () => {
        handleOpenPresentation()
      },
      id: 1
    },
    {
      label: "Скачать акт",
      icon: <IconDownload/>,
      onClick: () => {
        handleOpenAct()
      },
      id: 2
    },
    {
      label: "Скопировать ссылку",
      icon: <IconCopy/>,
      onClick: () => {
        handleCopyText(publicPageLink)
      },
      id: 3,
    },
    {
      label: "Изменить дату",
      icon: <IconEdit/>,
      onClick: openOpenChangeDateModal,
      id: 4,
    },
    {
      label: "Отменить",
      icon: <IconCancel/>,
      onClick: openCancelShowViewModal,
      id: 5,
      className: "1"
    },
  ]

  const actualMenu = () => {
    const menu = actionsMenu
    if (status === "canceled") {
      menu.splice(3, 2)
    }
    if (status === "finished") {
      menu.splice(3, 2)
    }
    if (status === "successFinished") {
      menu.splice(3, 2)
    }
    if (!publicPageLink) {
      menu.splice(2, 1)
    }
    if (!actLink) {
      menu.splice(1, 1)
    }
    if (!presentationLink) {
      menu.splice(0, 1)
    }
    if (menu.length === 0) {
      menu.push({label: "Нет доступных действий", id: 6, icon: <IconCancel/>, onClick: () => {}})
    }

    return menu
  }

  const {mutate: mutateFinish, isLoading: finishLoading} = useFinishShowOrView({
    onSuccess: () => onSuccess(),
    isViews
  });
  const {mutate: makeFinal, isLoading: finalLoading} = useMakeShowOrViewFinal({onSuccess, isViews})

  const handleButtonClick = () => {
    if (status === "finished") {
      makeFinal(id)
    } else {
      mutateFinish(payload)
    }
  }

  return (
    <div className={styles.root}>
      {status !== "finished" && status !== "successFinished" &&
        <div className={styles.actions}>
        <Popup
          openTrigger={isMobile ? <IconDots/> : <div className={styles.openTrigger}>Другие действия</div>}
          options={{
            withArrow: true,
            primaryOpen: "left",
            openUp: true
          }}
          classNames={
            {
              trigger: styles.clicked,
              arrow: styles.arrow,
              card: styles.popupCard
            }}
        >
          {actualMenu().map((menuItem) => (
            <div
              key={item.id}
              className={cn(
                styles.actionItem,
                {[ styles.deleteActionItem ]: menuItem.className !== undefined}
              )}
              onClick={menuItem.onClick}
            >
              {menuItem.icon}
              <span className={menuItem.className && styles.deleteActionItem}>
                  {menuItem.label}
                </span>
            </div>
          ))}
        </Popup>
      </div>
      }
      {status !== "successFinished" && status !== "finished" && status !== "canceled" &&
        <div className={styles.buttonContainer}>
          <Button
            onClick={handleButtonClick}
            isLoading={finishLoading}
          >
            {buttonText}
          </Button>
        </div>
      }
      {status === "finished" && !hasSuccessShow && !hasSuccessView &&
        <div className={styles.buttonContainer}>
          <Button
            onClick={handleButtonClick}
            isLoading={finalLoading}
          >
            {buttonText}
          </Button>
        </div>
      }
    </div>
  )
}