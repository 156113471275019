import {
	RealEstateSchemaConfigField,
	RealEstateAllowedValuesDecorationsOrRefurbishment,
	FormPropsEditRealEstate,
} from "../../../../../../../../../../types";

import { RadioButton, SelectWithPreview } from "../../../../../../../../index";
import { getDefaultForSelect } from "../../../../../helpers";

import styles from "./index.module.scss";

type Props = {
	field: RealEstateSchemaConfigField;
	form: FormPropsEditRealEstate;
};

export const FlatCondition = ({ field, form }: Props): JSX.Element => {
	const { dataType, label } = field;
	const { nameGroup, allowedValuesDecorations, allowedValuesRefurbishment } = dataType;

	if (!nameGroup || !allowedValuesDecorations || !allowedValuesRefurbishment) {
		return <></>;
	}

	const formatAllowedValues = (fields: RealEstateAllowedValuesDecorationsOrRefurbishment[]) =>
		fields.map((item) => ({
			group: item.name,
			values: item.allowedValues.map((allowedValue) => ({
				label: allowedValue.name,
				value: allowedValue.name,
				src: allowedValue.image,
			})),
		}));

	const formatedAllowedValuesDecorations = formatAllowedValues(allowedValuesDecorations);
	const formatedAllowedValuesRefurbishment = formatAllowedValues(allowedValuesRefurbishment);

	const radioButtonName = nameGroup[0];
	const conditionSelectName = nameGroup[1];

	const { control, register, setValue, getValues, watch } = form;

	const radioButtonWatcher = watch(radioButtonName);

	const conditionOptions = [
		{ label: "Отделка", value: "Отделка" },
		{ label: "Ремонт", value: "Ремонт" },
	];

	return (
		<div className={styles.root}>
			<div className={styles.radio}>
				<div className={styles.radioLabel}>{label}</div>

				<div className={styles.radioButton}>
					<RadioButton name={radioButtonName} options={conditionOptions} control={control} separate />
				</div>
			</div>

			{radioButtonWatcher && (
				<div className={styles.select}>
					<div className={styles.selectLabel}>
						{radioButtonWatcher === "Отделка" ? "Вид отделки" : "Вид ремонта"}
					</div>

					<div className={styles.selectButtons}>
						<SelectWithPreview
							register={register}
							setValue={setValue}
							name={conditionSelectName}
							options={
								radioButtonWatcher === "Отделка"
									? formatedAllowedValuesDecorations
									: formatedAllowedValuesRefurbishment
							}
							defaultValue={getDefaultForSelect({
								fieldName: conditionSelectName,
								getValues,
							})}
						/>
					</div>
				</div>
			)}
		</div>
	);
};
