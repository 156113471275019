import { AxiosResponse, AxiosError } from "axios";
import { useQuery } from "react-query";
import { CollectionObject } from "../../../../types";

import { api } from "../../api";

const DEFAULT_QN = "sdelka/compare/use-load-compare/DEFAULT_QN";
export const COMPARE_PAGE_LOAD_COMP_QN = "sdelka/compare/use-load-compare/COMPARE_PAGE_LOAD_COMP_QN";

type Props = {
	id: string;
	queryName?: string;
	enabled?: boolean;
	retry?: boolean | number;
	onSuccess?: (res: AxiosResponse<CollectionObject[]>) => void;
};

type Return = {
	data?: CollectionObject[];
	isLoading: boolean;
	isFetching: boolean;
	refetch: () => void;
	remove: () => void;
	error: AxiosError;
	isFetched: boolean;
};

type UseGetCompare = (props: Props) => Return;

export const useGetCompare: UseGetCompare = ({
	id,
	queryName = DEFAULT_QN,
	enabled = true,
	retry = false,
	onSuccess,
}) => {
	const getComparedObjects = () => api.objectCompare.getObjects(id);

	const { data, isLoading, refetch, remove, error, isFetching, isFetched } = useQuery(
		queryName,
		getComparedObjects,
		{
			enabled,
			retry,
			onSuccess,
		}
	);

	return {
		data: data?.data,
		isLoading,
		isFetching,
		refetch,
		remove,
		error: error as AxiosError,
		isFetched,
	};
};
