import { useQuery } from "react-query";
import { Dispatch, SetStateAction, useState } from "react";
import { Conditions, RealEstate, RealEstateFilter, RealEstateSort } from "../../../../types";

import { api } from "../../index";

export const REAL_ESTATE_LIST_DEFAULT_QN = "sdelka/real-estate/load-real-estates/DEFAULT_QN";
export const REAL_ESTATE_LIST_FOR_MAP_QN = "sdelka/real-estate/load-real-estates/REAL_ESTATE_LIST_FOR_MAP_QN";

type Props = {
  defaultTake?: number;
  defaultFilter?: RealEstateFilter;
  absoluteSearchConditions?: Conditions;
  defaultPage?: number;
  join?: string[];
  queryName?: string;
  options?: {
    keepPreviousData?: boolean;
  };
  enabled?: boolean;
};

type Return = {
  realEstatesList: RealEstate[];
  isLoading: boolean;
  isFetching: boolean;
  total: number;
  take: number;
  setTake: Dispatch<SetStateAction<number>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  sort?: RealEstateSort;
  setSort: Dispatch<SetStateAction<RealEstateSort | undefined>>;
  filter: RealEstateFilter;
  setFilter: Dispatch<SetStateAction<RealEstateFilter>>;
  refetch: () => void
};

type UseLoadApplicationRealEstates = (props: Props) => Return;

export const useLoadApplicationRealEstates: UseLoadApplicationRealEstates = (
  {
    defaultTake = 50,
    defaultFilter = {},
    defaultPage = 1,
    join,
    queryName = REAL_ESTATE_LIST_DEFAULT_QN,
    options = {},
    absoluteSearchConditions = [],
    enabled = true,
  }) => {
  const {keepPreviousData} = options;
  const [filter, setFilter] = useState<RealEstateFilter>(defaultFilter);
  const [take, setTake] = useState(defaultTake);
  const [page, setPage] = useState(defaultPage);
  const [sort, setSort] = useState<RealEstateSort | undefined>({
    field: "createdAt",
    sort: "DESC",
  });

  const onSuccess = (data) => {
    const {
      data: {total},
    } = data;

    if (total && total < page) {
      setPage(1);
    }
  };

  const currentFilter = {...filter};
  delete currentFilter.sourceSelect;

  const loadRealEstates = () =>
    api.applicationRealEstate.list({
      take,
      skip: take * (page - 1),
      sort,
      search: currentFilter,
      join,
      absoluteSearchConditions,
    });

  const {data, isLoading, isFetching, refetch} = useQuery(
    [queryName, take, page, sort, currentFilter, absoluteSearchConditions],
    loadRealEstates,
    {
      enabled,
      keepPreviousData,
      onSuccess,
    }
  );

  return {
    realEstatesList: data?.data?.data || [],
    isLoading,
    isFetching,
    total: data?.data?.total || 0,
    take,
    setTake,
    page,
    setPage,
    sort,
    setSort,
    filter,
    setFilter,
    refetch
  };
};
