import { useState } from "react";
import styles from "./index.module.scss"
import { productNounHelper } from "../../../../utils/string";
import { CartHeader, Footer } from "./helpers";
import { CardProduct } from "../../../UI";
import { CartResponse } from "../../../../types";
import cn from "classnames";

type Props = {
  refetchTable: () => void
  cart?: CartResponse
  refetch: () => void
  isCoWorking?: boolean
  isMobile?: boolean
}

export const Cart = (
  {
    refetchTable,
    refetch,
    cart,
    isCoWorking,
    isMobile
  }: Props) => {
  const [, setBlindOpen] = useState(!!isMobile);
  const [hovered, setHovered] = useState(!!isMobile);

  const handleRefetch = () => {
    refetch()
    refetchTable()
  }

  return (
    <div className={styles.underlayer}>

      <div
        className={cn(styles.root, {[ styles.rootDrawer ]: isMobile})}
        onMouseEnter={() => {
          if (!isMobile) {
            setBlindOpen(true)
            setHovered(true)
          }
        }}
        onMouseLeave={() => {
          if (!isMobile) {
            setBlindOpen(false)
            setHovered(false)
          }

        }}
      >
        <div className={styles.header}>
          <div className={styles.title}>
            Корзина
          </div>
          <div className={styles.subTitle}>
            {cart?.products && cart?.products.length} {productNounHelper(cart?.products.length || 0)}
          </div>
          {hovered && <CartHeader/>}
        </div>
        <div className={styles.products}>
          {cart?.products && cart.products.map((item) => (
              <div key={item.id}>
                <CardProduct
                  product={item}
                  onSuccess={handleRefetch}
                  isHovered={hovered}
                />
              </div>
            )
          )}
        </div>
        <Footer
          cart={cart}
          refetch={handleRefetch}
          isCoWorking={isCoWorking}
          hovered={hovered}
        />
      </div>
    </div>
  )
}