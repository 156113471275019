import { StagesType } from "../../../../../types";

type UseConditions = {
	currentStage: number;
	selectedTab: number;
	stages: StagesType[];
};

export const useConditions = ({ currentStage, selectedTab, stages }: UseConditions) => {
	const isGrey = currentStage !== selectedTab + 1;

	const showPrevArrow = selectedTab !== 0;

	const showNextArrow = selectedTab !== stages?.length - 1 && !stages[selectedTab + 1]?.unknown;

	const showNextStageBtn =
		stages[selectedTab]?.stageNumber === currentStage &&
		stages.length !== currentStage &&
		!stages[selectedTab + 1]?.unknown;

	const showLastStageBtn = stages[selectedTab]?.stageNumber === currentStage;

	const selectedStage = stages.find((stageItem) => stageItem?.stageNumber === selectedTab + 1);

	const showReturnBtn = selectedStage?.stageNumber ? selectedStage?.stageNumber + 1 === currentStage : false;

	return {
		isGrey,
		showPrevArrow,
		showNextArrow,
		showNextStageBtn,
		showLastStageBtn,
		showReturnBtn,
	};
};
