import { CollectionObject, ObjectCompareAdd } from "../../../../types";
import { AxiosResponse } from "axios";
import { UseMutateFunction, useMutation } from "react-query";

import { api } from "../../index";

type Props = {
	onSuccess?: () => void;
	onError?: () => void;
};

type Return = {
	mutate: UseMutateFunction<AxiosResponse<CollectionObject>, unknown, ObjectCompareAdd, unknown>;
	isLoading: boolean;
};

type UseAddObject = (props: Props) => Return;

export const useAddObject: UseAddObject = ({ onSuccess, onError }) => {
	const mutate = useMutation("addObjectToCompilation", {
		mutationFn: (payload: ObjectCompareAdd) => api.objectCompare.addObject(payload),
		onSuccess,
		onError,
	});

	return {
		mutate: mutate.mutate,
		isLoading: mutate.isLoading,
	};
};
