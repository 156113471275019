import { useMemo } from "react";
import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import cn from "classnames";

import { MultiLayerSelectOption } from "../../../../../../../types";
import { MultiLayerSelect } from "../../../../../../UI";
import { prepareResponsibleUsers } from "../../../../../../../utils/transformation";
import { useLoadUsers } from "../../../../../../../service/api/queries";

import styles from "./index.module.scss";

type Props = {
	name: string;
	setValue: UseFormSetValue<any>;
	getValues: UseFormGetValues<any>;
	register: UseFormRegister<any>;
	placeholder: string;
	control: Control<any>;
	withSystemAsUser?: boolean;
};

/**
 * Returns users multi-select input
 *
 * Can be polymorphed to pick responsibles, creators, participants, etc.
 *
 * Define concrete purpose by passing "name" prop which can actually be any string,
 *  but logically you want it to be something like "responsible", "creator", etc.,
 *  depending on your form structure
 */
export const Users = ({
	name,
	getValues,
	setValue,
	register,
	placeholder,
	control,
	withSystemAsUser,
}: Props): JSX.Element => {
	const { users } = useLoadUsers({});

	const responsibleUsers = useMemo((): MultiLayerSelectOption[] => {
		// TODO: this function could prepare all users, not just "responsible"?
		const prepared = prepareResponsibleUsers(users);

		// prepend prepared users list with "system" user
		if (withSystemAsUser) {
			prepared.unshift({
				value: "1",
				label: {
					name: "Система",
					children: "Система",
				},
				parents: [],
				layer: 0,
			});
		}

		return prepared;
	}, [users, withSystemAsUser]);

	const responsibleUsersWatcher = useWatch({
		control,
		name,
		defaultValue: getValues(name),
	});

	return (
		<div className={cn(styles.filterWrapper, styles.responsibleWrapper)}>
			<MultiLayerSelect
				name={name}
				options={responsibleUsers || []}
				register={register}
				placeholder={placeholder}
				setValue={setValue}
				isSearchable
				defaultValue={responsibleUsersWatcher || []}
			/>
		</div>
	);
};
