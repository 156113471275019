import cn from "classnames";

import styles from "./index.module.scss";

// if radius is 41 then circumference is 258
const circumference = 258;

type Props = {
	percent?: number;
};

export const ProgressCircular = ({ percent = 0 }: Props): JSX.Element => {
	const getFullness = circumference - (circumference / 100) * percent;
	const getSign = () => {
		if (percent < 0) {
			return "-";
		}

		if (percent > 0) {
			return "+";
		}

		return "";
	};

	return (
		<div className={styles.root}>
			<div
				className={cn(styles.circle, {
					[styles.negative]: percent < 0,
					[styles.middle]: percent >= 0 && percent < 50,
				})}
			>
				<svg>
					<defs>
						<linearGradient id="progress-circular-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
							<stop offset="0%" stopColor="#A7B0ED" />
							<stop offset="100%" stopColor="#7F8DEF" />
						</linearGradient>
					</defs>

					<circle r="41" cx="46" cy="46" className={styles.backCircle} />

					<circle
						r="41"
						cx="46"
						cy="46"
						style={{ strokeDashoffset: getFullness }}
						className={styles.frontCircle}
						stroke="url(#progress-circular-gradient)"
					/>
				</svg>
			</div>

			<div
				className={cn(styles.text, {
					[styles.negativePercent]: percent < 0,
					[styles.middlePercent]: percent > 0 && percent < 50,
					[styles.neutralPercent]: percent === 0,
				})}
			>
				{getSign()}
				<span>{Math.abs(percent)}</span>%
			</div>
		</div>
	);
};
