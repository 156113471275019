import cn from "classnames";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { formatNumbers } from "../../../../../utils/formatters";

import { ReactComponent as Arrow } from "../../../../../assets/icons/arrow-down-filled.svg";
import styles from "./index.module.scss";

type Props = {
	price: number;
	averagePrice: number;
};

export const AveragePriceBanner = ({ price, averagePrice }: Props): JSX.Element => {
	const diffPrice = price - averagePrice;
	const more = diffPrice < 0;

	return (
		<div className={cn(styles.root, { [styles.up]: more })}>
			<div className={styles.title}>Средняя стоимость вашего объекта в норме</div>

			<div className={styles.prices}>
				<div>{`${formatNumbers(`${averagePrice}`, 3)} ₽`}</div>

				<div>
					<span className={cn(styles.arrow, { [styles.up]: more })}>
						<Arrow />
					</span>

					{`${formatNumbers(`${Math.abs(diffPrice)}`, 3)} ₽ ${more ? "выше" : "ниже"} чем у вас`}
				</div>
			</div>

			<Button color="blue-disabled">Смотреть конкурентов</Button>
		</div>
	);
};
