import { RealEstateSchemaConfigField, FormPropsEditRealEstate } from "../../../../../../../../../../types";

import styles from "./index.module.scss";
import { NumberInput } from "../NumberInput";

type Props = {
	form: FormPropsEditRealEstate;
	field: RealEstateSchemaConfigField;
};

export const Floors = ({ form, field }: Props): JSX.Element => {
	const {
		dataType: { nameGroup },
	} = field;

	if (!nameGroup) {
		return <></>;
	}

	return (
		<div className={styles.root}>
			<div className={styles.inputWrapper}>
				<NumberInput form={form} field={field} name={nameGroup[0]} placeholder="000" onlyPositive maxLength={3} />
			</div>

			<div className={styles.separator}>из</div>

			<div className={styles.inputWrapper}>
				<NumberInput form={form} field={field} name={nameGroup[1]} placeholder="000" onlyPositive maxLength={3}/>
			</div>
		</div>
	);
};
