import type { Company, Department, PointCoordinate, Position } from "../index";
import { Phone } from "../index";

// User roles: separated account or company
export type UserTypes = "singleUser" | "estateAgencyUser";

export type UserData = {
	name: string;
	lastName: string;
	secondName?: string;
};

export type UserPosition = {
	id: string;
	name: string;
};

export type UserSettings = {
	mapCenter: PointCoordinate | null;
};

export type User = {
	id: string;
	shortId?: string;
	active: boolean;
	account: UserAccountData;
	company?: Company;
	companyId: string;
	officeId?: string;
	token: string;
	address: string;
	type: UserTypes;
	updatedAt: string;
	createdAt: string;
	lastActionDate: string;
	department?: Department;
	position?: UserPosition;
	callCenterAccess: boolean;
	settings: UserSettings;
	tariff?: {
		expirationDate: Date;
		name: string;
	};
	// FOR WHAT
	permissions?: string;
	paidUntil?: string
	specialisations?: string
	status?: UserStatusEnum
	aboutMe?: string
	canEdit?: boolean
	isInCart?: boolean
	employerSubscribtionFinished?: boolean
	canRemoveFromDepartment?: boolean
	// officePreview: Office
	isPremium?: boolean
	premiumDaysLeftWarning?: number
	isVerified?: boolean
	isOfficeSelected?: boolean
	isAddressSelected?: boolean
};

export type ApplicationResponsible = {
	id: string;
	applicationId: string;
	userId: string;
	// clarify later
	stage: unknown;
	main: boolean;
	date: string;
	user: User;
};

export enum UserStatusEnum {
	Waiting = 'Waiting', // Ожидает подтверждения
	Expired = 'Expired', // Истек
	ExpiresSoon = 'ExpiresSoon', // Скоро истечет
	Active = 'Active'  // Активный
}


export type ConnectUserInfo = {
	id: string;
	accountId: string;
	workPhone: string;
	instagram?: string;
	vk?: string;
	email?: string;
};

export type UserPreview = {
	name: string;
	lastName: string;
	secondName?: string;
	avatar?: string;
};

export type UserAccountData = UserPreview & {
	id: string;
	shortId: string;
	phone: string;
	dob?: string;
	connectInfo: ConnectUserInfo;
	deleted: boolean;
	createdAt: string;
	updatedAt: string;
	position?: Position;
	aboutMe?: string;

};

export type IUserEditFormRequest = {
	id: string
	name?: string;
	lastName?: string;
	secondName?: string;
	avatar?: string;
	departmentId?: string;
	positionId?: string;
	dob?: string;
	extraPhones?: string[];
	vk?: string
	email?: string
	aboutMe?: string
	specialisations?: string
}

export type IUserEditFormResponse = {
	id: string
	name?: string;
	lastName?: string;
	secondName?: string;
	avatar?: string;
	departmentId?: string;
	positionId?: string;
	department?: Department;
	position?: UserPosition;
	officeName?: string
	dob?: string;
	phone?: string;
	extraPhones?: string[];
	vk?: string
	email?: string
	aboutMe?: string
	specialisations?: string
	shortId?: string
	accountId?: string
	status: UserStatusEnum
	paidUntill?: string
	publicPageLink?: string
	isInCart?: boolean
	canEdit?: boolean
	isPremium?: boolean
	isVerified?: boolean
}
