import { CollectionItem } from "../../../../../types";
import { JSX, memo } from "react";
import styles from "../index.module.scss";
import { ReactComponent as IconLike } from "../../../../../assets/icons/iconLike.svg";
import { ReactComponent as IconDidNotLike } from "../../../../../assets/icons/iconDidNotLike.svg";
import { ReactComponent as IconLikeALot } from "../../../../../assets/icons/iconLikeALot.svg";
import { ReactComponent as IconWantToSee } from "../../../../../assets/icons/iconWantToSee.svg";
import { Tooltip } from "@sdelka_crm/sdelka-crm-component-library";
import cn from "classnames";

type Props = {
  collectionItem?: CollectionItem
}

export const ImageIcons = memo(({collectionItem}: Props): JSX.Element => {
  const status = collectionItem?.collectionStatus

  const iconTop = () => {
    switch (status) {
      case "likeALot":
        return IconLikeALot
      case "like":
        return IconLike
      case "didNotLike":
        return IconDidNotLike
      case "wantToSee":
        return IconWantToSee
      default:
        return undefined
    }
  }

  const iconTooltipText = () => {
    switch (status) {
      case "likeALot":
        return "Клиенту понравился объект"
      case "like":
        return "Клиент сомневается"
      case "didNotLike":
        return "Клиенту не понравился объект"
      case "wantToSee":
        return "Клиент хочет просмотр"
      default:
        return undefined
    }
  }


  return (
    <Tooltip
      classNames={{
        root: styles.icon,
        card: cn(styles.tooltipCard, styles.tooltipComment),
      }}
      iconSize="big"
      CustomIcon={iconTop()}
    >
      {iconTooltipText()}
    </Tooltip>
  )
})




