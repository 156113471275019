import { useEffect, useState } from "react";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { Info as InfoCard } from "../ApplicationCard/components/ApplicationInfo/component";
import { useArhivePreApplications, useLoadPreApplicationTemplate } from "../../../../service/api/queries";
import { useCompare, useOpenLeadInDrawer, useOpenTaskModal, useQueryParams } from "../../../../utils/hooks";
import { ReactComponent as DoubleChevronRight } from "../../../../assets/icons/DoubleChevron_Right.svg";
import styles from "./index.module.scss";
import {
  useLoadCardBlocksPreApplications
} from "service/api/queries/pre-application-template/useLoadCardBlocksPreApplications";
import { KindType } from "types";
import {
  useFinishSuccessPreApplications
} from "service/api/queries/pre-application-template/useFinishSuccessPreApplications";
import { Popup } from "components/UI";
import { closeDrawerInDrawer } from "service/redux/modules/drawer/drawer.action";
import { useLocation, useRouteMatch } from "react-router";
import { selectDevice, selectDrawer, useSelect } from "service/redux/selectors";
import { useDispatch } from "react-redux";
import { MenuItem } from "components/pages/ShowsAndViews/components/card-actions/CardActions";
import { ReactComponent as IconDots } from "../../../../assets/icons/iconDotsInCircle.svg";
import {
  useAddEditComponent,
  useChangeTab,
  useCloseDrawer,
  useOpenApplication,
  useOpenPreApplication
} from "../../../../service/servise";
import {
  useGetFromArchive
} from "../../RealEstatePage/mode/RealEstateView/components/stickyFooter/helpers/useGetFromArchive";
import { LayoutLoading } from "./LayoutLoading";
import { ReactComponent as IconHouseShow } from "../../../../assets/icons/iconHouseShow.svg";
import { ReactComponent as IconEdit } from "../../../../assets/icons/iconEdit.svg";
import { ReactComponent as IconCompetitorAnalysis } from "../../../../assets/icons/iconCompetitorAnalysis.svg";
import { useOpenCreateShowModal } from "../../ShowsAndViews/components/sticky-footer/create-show-view-modal";
import { ReactComponent as IconCancel } from "../../../../assets/icons/iconClear.svg";
import cn from "classnames";

type Props = {
  applicationId: string;
  refetchLead?: () => void;
  isFromLead?: boolean
  refetchList?: () => void
};
type MatchParams = {
  id: string;
};

export const PreApplicationTemplate = (
  {
    applicationId,
    refetchLead,
    isFromLead,
    refetchList
  }: Props): JSX.Element => {

  const {
    removeQueryParam,
    changeQueryParams
  } = useQueryParams<{ preApplicationId: string, whoIsClient: string, tab: string, leadId: string }>([{name: "preApplicationId"}, {name: "whoIsClient"}, {name: "tab"}, {name: "leadId"}]);
  const dispatch = useDispatch();
  const {isMobile} = useSelect(selectDevice)
  const match = useRouteMatch<MatchParams>();
  const {id} = match.params;
  const APPLICATION_ID = applicationId ?? id;

  const {data, isLoading, refetch} = useLoadPreApplicationTemplate({
    applicationId,
  });

  const location = useLocation()
  const {yNavigation} = useSelect(selectDrawer)
  const {changeTab} = useChangeTab({atDrawerInDrawer: isMobile ? true : isFromLead})
  const {addEditComponent} = useAddEditComponent({
    atDrawerInDrawer: isMobile ? true : isFromLead,
    kind: KindType.PREAPPLICATION
  })

  const openEdit = () => {
    if (yNavigation && !yNavigation.navigation.find(el => el.title === "edit")) {
      addEditComponent({
        id: data?.applicationTemplateId || "",
        refetch,
        enabled: true
      })
    }
    if (yNavigation && yNavigation.navigation.find(el => el.title === "edit")) {
      changeTab({title: "edit"})
    }
  }

  const {openCreateShowModal} = useOpenCreateShowModal({
    applicationId, refetchList: () => {
    }
  })
  const {openObjectCompare} = useCompare({applicationId});

  const arhivePreApplication = () => {
    arhiveMutate()
  }

  const {handleOpenTaskModal} = useOpenTaskModal({
    onUpdatedTask: arhivePreApplication,
    refetchParent: refetchLead || refetch,
    isLast: true
  });

  const actionsMenu: MenuItem[] = [
    {
      label: "Назначить просмотр",
      icon: <IconHouseShow/>,
      onClick: openCreateShowModal,
      id: 6,
    },
    {
      label: "Редактировать объект",
      icon: <IconEdit/>,
      onClick: () => {
        openEdit()
      },
      id: 5,
    },
    {
      label: "Анализ конкурентов",
      icon: <IconCompetitorAnalysis/>,
      onClick: openObjectCompare,
      id: 3,
    },
    {
      label: "Открыть лид",
      icon: <DoubleChevronRight/>,
      onClick: () => openLeadCard(),
      id: 1
    },
    // {
    //   label: "Открыть чат",
    //   icon: <DoubleChevron_Right/>,
    //   onClick: () => 1,
    //   id: 2
    // },
    {
      label: "Отказаться от предзаявки",
      icon: <IconCancel/>,
      onClick: () => handleOpenTaskModal(data?.lastActiveCommunicationId ?? ""),
      id: 5,
      className: styles.deleteActionItem
    },
  ]

  const actualMenu = (location.search.includes("tab=applications"))
    ? actionsMenu
    : actionsMenu.slice(1, 3)

  const [stageNum, setStageNum] = useState(0);
  const stages = data ? data.stages : []
  const stageTitle: string | null = stages && stages[ stageNum ] ? stages[ stageNum ].title : null;

  const {
    data: currentBlocks,
    isFetching: isFetchingBlocks,
    refetch: refeatchBlocks
  } = useLoadCardBlocksPreApplications({
    applicationId,
    stage: stageTitle as string,
    enabled: !!applicationId && !!stageTitle,
  });

  useEffect(() => {
    refetch()
    refeatchBlocks()
  }, [applicationId])

  const {
    mutate: arhiveMutate,
    data: arhivePreApplicationsData
  } = useArhivePreApplications({applicationId})

  const {
    mutate: finishSuccessMutate,
    data: finishSuccessData,
    isLoading: finishSuccessLoading,
  } = useFinishSuccessPreApplications({applicationId})


  useEffect(() => {
    // todo
    if (arhivePreApplicationsData) {
      dispatch(closeDrawerInDrawer())
    }
  }, [arhivePreApplicationsData])

  const finishSuccesPreApplication = () => {
    finishSuccessMutate()
  }

  const {close} = useCloseDrawer({atDrawerInDrawer: isMobile === true ? isMobile : isFromLead})
  const {openApplication} = useOpenApplication()
  const {openPreApplication} = useOpenPreApplication()
  const {openLeadInDrawer} = useOpenLeadInDrawer({refetch})
  useEffect(() => {
    if (finishSuccessData) {
      changeQueryParams([{name: "whoIsClient", newValue: data?.type.whoIsClient as string}])
      removeQueryParam("preApplicationId")
      const currentApplicationId = (finishSuccessData?.data as any)?.applicationId
      close()
      setTimeout(() => {
        openApplication({
          applicationId: currentApplicationId,
          kind: KindType.APPLICATION,
          atDrawerInDrawer: isMobile === false ? isFromLead : !isMobile,
          whoIsClient: data?.type.whoIsClient,
          onClose: () => {
            window.location.reload()
          }
        })
      }, 200)
    }
    if (isFromLead === false) {
      if (refetchList) {
        refetchList()
      }
    } else if (refetchLead) {
      refetchLead()
    }
  }, [finishSuccessData]);

  const applicationTemplateId = data?.applicationTemplateId

  const openLeadCard = () => {
    close()
    if (data?.parentLead.id) {
      changeQueryParams([{name: "tab", newValue: "leads"}, {name: "leadId", newValue: data?.parentLead.id}])
    }
  }

  const {handleGetFromArchive} = useGetFromArchive(applicationTemplateId, true)

  return (
    <>
      {APPLICATION_ID && (
        <div className={styles.wrap}>
          {data &&
            <InfoCard
              applicationId={applicationId}
              kind={KindType.PREAPPLICATION}
              application={data}
              onTab={() => 0}
              refetch={refetch}
              stageNum={stageNum}
              setStageNum={setStageNum}
              isFetchingBlocks={isFetchingBlocks}
              currentBlocks={currentBlocks}
              isMobile={isMobile}
            />
          }
        </div>
      )}
      {data &&
        <>
          {data && !data.isArchived &&
            <div className={styles.bottoms}>
              <div className={styles.actions}>
                <Popup
                  openTrigger={isMobile ? <IconDots/> : <div className={styles.openTrigger}>Другие действия</div>}
                  options={{withArrow: true}}
                  classNames={
                    {
                      trigger: styles.clicked,
                      arrow: styles.arrow,
                      card: styles.popupCard
                    }
                  }
                >
                  {actualMenu.map((item) => (
                    <div key={item.id} className={cn(
                      styles.actionItem,
                      {[ styles.deleteActionItem ]: item.className !== undefined}
                    )} onClick={item.onClick}>
                      {item.icon}
                      <span>{item.label}</span>
                    </div>
                  ))}
                </Popup>
              </div>
              <Button onClick={finishSuccesPreApplication} isLoading={finishSuccessLoading}>
                Активировать заявку
              </Button>
            </div>
          }
          {data.isArchived &&
            <div className={styles.bottoms}>
              <Button onClick={() => handleGetFromArchive(data.applicationTemplateId ?? "")}>
                Создать лид
              </Button>
            </div>
          }
        </>
      }
      {isLoading &&
        <LayoutLoading/>}
      {/*  пока оставлено */}
      {/* {data && <Info refetch={refetch} refetchLead={refetchLead} application={data as unknown as ApplicationTemplate} />} */}
      {/* {data && <InfoCard
					applicationId={applicationId}
					kind="pre-applications"
					application={data}
					onTab={() => 0}
					refetch={refetch}
					stageNum={stageNum}
					setStageNum={setStageNum}
					isFetchingBlocks={isFetchingBlocks}
					currentBlocks={currentBlocks}
				/>} */}
    </>
  );
};
