import { useMemo, useState } from "react";
import { useQuery } from "react-query";

import { api } from "../../api";
import { DefaultTasksFiltersType } from "../../../../types";

const DEFAULT_QN = "sdelka/tasks/use-load-tasks/DEFAULT_QN";

const DEFAULT_STAGE = ["all"];

type UseLoadTasks = {
  defaultStage?: string[];
  defaultFilter: DefaultTasksFiltersType;
  queryName?: string;
  enabled?: boolean;
  take?: number
};

export const useLoadTasks = (
  {
    defaultStage = DEFAULT_STAGE,
    defaultFilter,
    queryName = DEFAULT_QN,
    enabled = true,
  }: UseLoadTasks) => {
  const [stage, setStage] = useState(defaultStage);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [filter, setFilter] = useState(defaultFilter);
  const skip = useMemo(() => (page - 1) * take, [page]);
  const loadTasks = () =>
    api.tasks.list({
      skip,
      take,
      search: {...filter, stage},
    });

  const {data, refetch, remove, isLoading, isFetched, isFetching} = useQuery(
    [queryName, stage, take, filter, page],
    loadTasks,
    {enabled, keepPreviousData: false}
  );

  const pageCount = data ? Math.ceil(data.data.total / take) : 0;

  return {
    data: data?.data?.data,
    tabsCount: data?.data.tabsCount,
    total: data?.data?.total,
    pageCount,
    isLoading,
    refetch,
    remove,
    isFetched,
    take,
    setTake,
    isFetching,
    page,
    setPage,
    filter,
    setFilter,
    stage,
    setStage,
  };
};
