import styles from "./index.module.scss";
import { Table } from "../../../../UI";
import { useBlinkRow, useQueryParams } from "../../../../../utils/hooks";
import { useColumns } from "./columns";
import { useCallback } from "react";
import { AppliedProduct, appliedProductStatus } from "../../../../../types/product";
import cn from "classnames";
import { useOpenProductOrder } from "../../../../../service/servise";

type Props = {
  productsList: AppliedProduct[];
  isLoading: boolean;
  refetch: () => void;
  isMobile?: boolean
};

export const OrdersTable = (
  {
    productsList,
    isLoading,
    refetch,
    isMobile
  }: Props): JSX.Element => {
  const {changeQueryParams} = useQueryParams<{
    userId: string;
  }>([{name: "orderId"}]);
  const columns = useColumns(refetch, isMobile);
  const {rowsToBlink} = useBlinkRow();

  const {openProductOrderDrawer} = useOpenProductOrder({
    refetch,
  });

  const renderTitle: (item: string) => JSX.Element = useCallback(
    (status: string) =>
      (
        <div>
          <div className={styles.title}>
            {appliedProductStatus[ status ]}
          </div>
        </div>
      ),
    []
  );

  const handleRowClick = (row: AppliedProduct) => {
    changeQueryParams([
      {name: "orderId", newValue: row.id},
    ]);
    openProductOrderDrawer(row.id)
  };

  return (
    <div className={cn(styles.wrapper, {[ styles.mini ]: isMobile})}>
      {productsList?.length > 0 || isLoading ? (
        <Table<AppliedProduct>
          idKey="id"
          data={productsList}
          columns={columns}
          groupKey="status"
          rowClass={styles.row}
          transformGroupTitle={renderTitle}
          onRowClick={handleRowClick}
          loading={{isLoading, rowsCount: 5}}
          activeRow={{
            accessor: "",
            activeId: 1,
          }}
          blinkRows={rowsToBlink}
        />
      ) : (
        <div className={styles.empty}>Похожих продуктов не найдено</div>
      )}
    </div>
  )
}