import { useDispatch } from "react-redux";
import { openModal } from "../../../../../../../../../../../../../../service/redux/modules/modal/modal.action";
import { ConfirmPaymentModal } from "./ConfirmPaymentModal";
import { SelectOption } from "../../../../../../../../../../../../../../types";
import styles from "../apply-service-modal/index.module.scss";

type Props = {
  applicationId: string
  serviceId: string
  isMobile?: boolean
}

type Return = {
  openConfirmPaymentModal: (tariffLabel?: string,
                            tariffOptions?: SelectOption[]) => void;
};

export const useOpenConfirmPaymentModal = (
  {
    applicationId,
    serviceId,
    isMobile,
  }: Props): Return => {
  const dispatch = useDispatch();

  const openConfirmPaymentModal = (tariffLabel?: string,
                                   tariffOptions?: SelectOption[]) => {
    dispatch(openModal({
      body: <ConfirmPaymentModal
        applicationId={applicationId}
        serviceId={serviceId}
        tariffOptions={tariffOptions}
        tariffLabel={tariffLabel}
        isMobile={isMobile}
      />,
      width: isMobile ? 500 : 660,
      classNames: {
        contentWrapper: isMobile ? styles.contentWrapperClassNameMobile : "",
        buttonClose: isMobile ? styles.modalClose : ""
      }
    }))
  }

  return {
    openConfirmPaymentModal
  }
}