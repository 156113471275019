import styles from "./index.module.scss"
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useOpenCreateShowModal, useOpenCreateViewModal } from "./create-show-view-modal";

type Props = {
  applicationId: string
  isViews: boolean;
  refetch: () => void
  hasSuccessShow?: boolean
  hasSuccessView?: boolean
}

export const StickyFooter = (
  {
    isViews,
    refetch,
    applicationId,
    hasSuccessShow,
    hasSuccessView
  }: Props): JSX.Element => {

  const buttonText = `Назначить ${!isViews ? "показ" : "просмотр"}`

  const {openCreateShowModal} = useOpenCreateShowModal({applicationId, refetchList: refetch})
  const {openCreateViewModal} = useOpenCreateViewModal({applicationId, refetchList: refetch})

  return (
    <div className={styles.root}>
      {!hasSuccessShow && !hasSuccessView &&
        <Button
          classNames={{root: styles.button}}
          onClick={isViews ? openCreateViewModal : openCreateShowModal}
        >
          {buttonText}
        </Button>
      }
    </div>
  )
}