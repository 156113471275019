import { useMutation } from "react-query";
import { api } from "../../index";

const DEFAULT_MN = "sdelka/pre-application/delete-application-contacts/DEFAULT_MN";

export const useDeleteAC = (applicationId:string) => {
	const mutation = useMutation(DEFAULT_MN, {
    mutationFn: () => api.PreApplicationService.deleteAgencyAgrement(applicationId),
  });

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
		status: mutation.status
	};
};

