import styles from "./index.module.scss";

type Props = {
	label: string;
	count?: number;
	needComma?: boolean;
};

export const СountLabel = ({ label, count, needComma }: Props): JSX.Element => {
	if (count) {
		return (
			<>
				{label} <span className={styles.count}>({count})</span>
				{needComma ? ", " : ""}
			</>
		);
	}

	return <></>;
};
