import { H4, Tooltip } from "@sdelka_crm/sdelka-crm-component-library";

import { RealEstateFilterField } from "../../../../../../types";
import styles from "./index.module.scss";

type Props = {
	field: RealEstateFilterField;
};

export const Subtitle = ({ field }: Props): JSX.Element => {
	const { text, example } = field.help;

	return (
		<H4 classNames={{ root: styles.subTitle }}>
			{field.label}

			<span>
				{text && text !== "" && (
					<Tooltip iconSize="medium">
						{`${field?.help.text}`}

						{example && example !== "" && (
							<>
								<div className={styles.example}>Пример:</div>
								<div>{example}</div>
							</>
						)}
					</Tooltip>
				)}
			</span>
		</H4>
	);
};
