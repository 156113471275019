import { Client } from "../../Client";
import { DashboardTableResponse, DashbordStatistic, ReportsResponse, SelectOption } from "../../../../types";

export const DashboardService = {
  get: () => Client.get<DashbordStatistic>(`/dashboard/statistic`),

  getTable: (preset: string, orderBy: string, direction: "ASC" | "DESC", dateFrom: Date, dateTo: Date) =>
    Client.get<DashboardTableResponse>
    (`/dashboard/table?preset=${preset}&dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}&orderBy=${orderBy}&orderDirection=${direction}`),

  downloadExel: (preset: string, dateFrom: Date, dateTo: Date) =>
    Client.get<{ url: string }>(`/dashboard/table/downloadExel?preset=${preset}&dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}`),

  downloadReports: (type: string, dateFrom: Date, dateTo: Date) =>
    Client.get<ReportsResponse>(`/dashboard/table/downloadReport?type=${type}&dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}`),

  getReportTypes: () => Client.get<SelectOption[]>(`/dashboard/table/reportTypes`)
}