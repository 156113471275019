import { useQuery } from "react-query";

import { api } from "../../index";

type UseLoadApplicationPublic = {
	id: string;
};

export const useLoadApplicationPublic = ({ id }: UseLoadApplicationPublic) => {
	const loadApplicationPublic = () => api.applicationPublic.getOne(id);

	const { data, isLoading, refetch } = useQuery("loadApplicationPublic", loadApplicationPublic);

	return {
		application: data?.data,
		isLoading,
		refetch,
	};
};
