import { useMutation } from "react-query";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/application/view-create/DEFAULT_MN";

type UseCreateShowOrView = {
  onSuccess?: () => void;
};

export const useCreateViewWithoutApplication = ({onSuccess}: UseCreateShowOrView) => {
  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: ({applicationId, contactId, plannedDate, leadSource }: {applicationId: string, contactId: string, plannedDate: Date, leadSource?: string}) =>
      api.application.createViewWithoutApp(applicationId, plannedDate, contactId, leadSource),
    onSuccess,
  });

  return {
    data: mutation.data,
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
  };
};
