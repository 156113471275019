import { useForm, useWatch } from "react-hook-form";

import { RealEstateFilter } from "../../../../../../../types";
import { selectCurrentAccount, useSelect } from "../../../../../../../service/redux/selectors";

type UseFormFilter = {
	filter: RealEstateFilter;
};
export const useFormFilter = ({ filter }: UseFormFilter) => {
	const { control, register, setValue, handleSubmit, reset, getValues, watch } = useForm<any>({
		defaultValues: { ...filter },
	});

	const formWatcher = watch();
	const {currentAccount} = useSelect(selectCurrentAccount)

	const [watchCategory, watcherUnit, watcherPriceFrom, watcherPriceTo, watcherAddress, watcherAddressParser, watcherResponsible] = [
		useWatch({
			control,
			name: "category",
			defaultValue: filter?.category || [],
		}),
		useWatch({
			control,
			name: "unit",
		}),
		useWatch({
			control,
			name: "priceFrom",
			defaultValue: getValues("priceFrom") || "",
		}),
		useWatch({
			control,
			name: "priceTo",
			defaultValue: getValues("priceTo") || "",
		}),
		useWatch({
			control,
			name: "address",
			defaultValue: getValues("address") || "",
		}),
		useWatch({
			control,
			name: "addressParser",
			defaultValue: getValues("addressParser") || "",
		}),
		useWatch({
			control,
			name: "responsible",
			defaultValue:  [currentAccount?.id],
		}),
	];

	return {
		register,
		setValue,
		handleSubmit,
		reset,
		getValues,
		control,
		formWatcher,
		watchCategory,
		watcherUnit,
		watcherPriceFrom,
		watcherPriceTo,
		watcherAddress,
		watcherAddressParser,
		watcherResponsible
	};
};
