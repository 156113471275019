import { ActiveApplications } from "../../../../../../../types";
import { Tab } from "./component";

import styles from "./index.module.scss";

type Props = {
	activeApplications: ActiveApplications;
	currentApplicationId: string;
};

export const Tabs = ({ activeApplications, currentApplicationId }: Props): JSX.Element => {
	const { rentOut, sale } = activeApplications;

	return (
		<div className={styles.root}>
			<Tab application={sale} currentApplicationId={currentApplicationId} />

			<Tab application={rentOut} currentApplicationId={currentApplicationId} />
		</div>
	);
};
