import { RealEstateWithSchema } from "../../../../types";
import { useQuery } from "react-query";

import { api } from "../../index";
import { setAddress } from "../../../redux/modules/object-edit/object-edit.action";
import { useDispatch } from "react-redux";

const DEFAULT_QN = "sdelka/real-estate/load-parser-real-estate/DEFAULT_QN";

type Props = {
	id: string;
	enabled?: boolean;
	queryName?: string;
	onSuccess?: () => void;
	onError?: () => void;
};

type Return = {
	realEstate: RealEstateWithSchema | undefined;
	isLoading: boolean;
	isFetching: boolean;
	refetch: () => void;
	remove: () => void;
};

type UseLoadParsedRealEstate = (props: Props) => Return;

export const useLoadParsedRealEstate: UseLoadParsedRealEstate = ({
	id,
	enabled = true,
	queryName,
	onError,
	onSuccess,
}) => {
	const loadRealEstate = () => api.realEstate.get({ id });
	const dispatch = useDispatch();
	const defaultQueryName = `${DEFAULT_QN}/${id}`;

	const { data, refetch, remove, isLoading, isFetching } = useQuery(
		queryName || defaultQueryName,
		loadRealEstate,
		{
			enabled: !!enabled && Boolean(id) && id !== "new",
			onError,
			onSuccess,
			cacheTime: 0,
		}
	);

	// sort photos by order field
	data?.data.photos.sort((a, b) => {
		if (a.order < b.order) {
			return -1;
		}
		if (a.order > b.order) {
			return 1;
		}

		return 0;
	});

	const coords = data?.data?.point ?? data?.data.location?.coordinates;

	if (coords) {
		dispatch(
			setAddress({
				coord:
					{
						lat: Number(coords[ 0 ]),
						lon: Number(coords[ 1 ]),
					},
				addressId: data?.data.addressId,
				addressLabel: data?.data.addressLabel,
				dontMarkOnMap: data?.data.category !== "Дом",
			})
		);
	}

	return {
		realEstate: data?.data,
		isLoading,
		isFetching,
		refetch,
		remove,
	};
};
