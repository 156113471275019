type CompanyRequestStatus = "deleted" | "default" | "warning" | "hot" | "accepted";

// Get request/invite status
export const getStatus = (difference: number): CompanyRequestStatus => {
	if (difference >= 1440 && difference < 2880) {
		return "warning";
	}

	if (difference >= 2880) {
		return "hot";
	}

	return "default";
};
