import { IUserEditFormRequest, IUserEditFormResponse } from "../../../../../types";
import { useMemo } from "react";
import styles from "./index.module.scss"
import cn from "classnames";
import { InfoRow } from "@sdelka_crm/sdelka-crm-component-library";
import { Control, FormState, useFieldArray } from "react-hook-form";
import { InputList, Select } from "../../../../UI";
import { parsePhone } from "../../../../../utils/parsers";
import {
  useLoadDepartments,
} from "../../../../../service/api/queries";
import { UseLoadUserPositions } from "../../../../../service/api/queries/position/useLoadUserPositions";
import { LOAD_POSITIONS_REQUESTS_QN } from "../../../../../service/api/queries/position/useLoadUserPositions";
import { UseFormRegister, UseFormSetValue } from "react-hook-form/dist/types/form";

type Props = {
  user?: IUserEditFormResponse;
  register: UseFormRegister<IUserEditFormRequest>;
  control: Control<IUserEditFormRequest, any>
  formState: FormState<IUserEditFormRequest>
  setValue: UseFormSetValue<IUserEditFormRequest>;
  editMode: boolean
};

export const Characteristics = (
  {
    user,
    register,
    control,
    formState,
    setValue,
    editMode
  }: Props) => {

  const phoneInputsNameBuilder = (baseName: string, index: number, objectKey: string) =>
    index === 0 ? "mainPhone" : `${baseName}.${index}.${objectKey}`;

  const {
    data: departments
  } = useLoadDepartments({});
  const {
    positions,
  } = UseLoadUserPositions({queryName: LOAD_POSITIONS_REQUESTS_QN});

  const officeOptions = useMemo(
    () =>
      (departments ?? []).map((department) => ({
        label: department.name,
        value: department.id,
      })),
    [departments, departments?.length]
  );

  const positionOptions = useMemo(
    () =>
      positions?.map((position) => ({
        label: position.name,
        value: position.id,
      })),
    [positions, positions.length]
  );

  const {fields, append, remove} = useFieldArray({control: control as any, name: "extraPhones" as any});

  return (
    <div className={styles.root}>
      <div className={cn(styles.row, styles.header)}>
        <div className={styles.title}>Характеристики</div>
      </div>
      {!editMode
        ? <>
          <InfoRow label="Офис">
            {user?.officeName || "Не указан"}
          </InfoRow>
          <InfoRow label="Отдел">
            {user?.department?.name || "Не указан"}
          </InfoRow>
          <InfoRow label="Должность">
            {user?.position?.name || "Не указан"}
          </InfoRow>
          <InfoRow label="Корпоративный телефон ">
            {user?.extraPhones?.join(", ") || "Не указан"}
          </InfoRow>
        </>
        : <>
          <InfoRow label="Офис">
            {user?.officeName || "Не указан"}
          </InfoRow>
          <InfoRow label="Отдел">
            <Select
              classNames={{
                selected: styles.selected
              }}
              name="departmentId"
              withBorder
              options={officeOptions}
              placeholder="Отдел"
              register={register}
              setValue={setValue}
              defaultValue={user?.departmentId}
              error={formState.errors?.departmentId?.message?.toString()}
            />
          </InfoRow>
          <InfoRow label="Должность">
            <Select
              classNames={{
                selected: styles.selected
              }}
              name="positionId"
              withBorder
              options={positionOptions}
              placeholder="Должность"
              register={register}
              setValue={setValue}
              defaultValue={user?.positionId}
              error={formState.errors?.positionId?.message?.toString()}
            />
          </InfoRow>
          <InputList
            fieldName="extraPhones"
            fields={fields}
            control={control}
            nameBuilder={phoneInputsNameBuilder}
            append={append}
            remove={remove}
            firstLabel="Корпоративный телефон"
            otherLabels="Корпоративный телефон"
            objectKey="extraPhone"
            parser={parsePhone}
            haveFirstLabel={false}
            placeholder="Укажите телефон"
          />
        </>
      }
    </div>
  )
}