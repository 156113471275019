import { AxiosResponse } from "axios";

import {
	CreateObjectToApplicationPayload,
	GetClusterPayload,
	RealEstate,
	RealEstateCreatePayload,
	RealEstateListPayload,
	RealEstateListResponse,
	RealEstateUpdatePayload,
	RealEstateWithSchema,
} from "../../../../types";
import { Client } from "../../Client";
import { createListFieldsPayload } from "../../../../utils/clientObjects";
import { getRealEstateFilters } from "../../helpers";

export const DealRealEstateService = {
	get: ({ id }: { id: string }): Promise<AxiosResponse<RealEstateWithSchema>> =>
		Client.get<RealEstateWithSchema>(`/deal/${id}/object`),
	create: (payload: RealEstateCreatePayload) =>
		Client.post<{ applicationId: string }>("/application/object", payload),
	list: ({
		take,
		skip,
		sort,
		search,
		join,
		absoluteSearchConditions = [],
	}: Omit<RealEstateListPayload, "onlyCount">) => {

		const { searchConditions, sortConditions, joinConditions } = createListFieldsPayload({
			sort,
			search,
			join,
			absoluteSearchConditions,
		});
		return Client.post<RealEstateListResponse>(
			"/application/object/search",
			JSON.stringify({
				search: {
					filterType: "and",
					conditions: searchConditions,
				},
				join: joinConditions,
				take,
				skip,
				sort: sortConditions,
			})
		);
	},
	count: ({
		take,
		skip,
		sort,
		search,
		join,
		absoluteSearchConditions = [],
	}: Omit<RealEstateListPayload, "onlyCount">) => {
		const { searchConditions, sortConditions, joinConditions } = createListFieldsPayload({
			sort,
			search,
			join,
			absoluteSearchConditions,
		});

		return Client.post<number>(
			"/application/object/count",
			JSON.stringify({
				search: {
					filterType: "and",
					conditions: searchConditions,
				},
				join: joinConditions,
				take,
				skip,
				sort: sortConditions,
			})
		);
	},
	getClusters: ({ payload, search }: GetClusterPayload) => {
		const searchConditions = getRealEstateFilters({ searchPayload: search });

		return Client.post("/application/object/searchForMap", {
			...payload,
			search: {
				filterType: "and",
				conditions: searchConditions,
			},
		});
	},
	update: ({ id, data }: RealEstateUpdatePayload) => Client.put(`/application/${id}/object`, data),
	createObjectToApplication: ({ applicationId, data }: CreateObjectToApplicationPayload) =>
		Client.post<RealEstate>(`/application/${applicationId}/object`, data),
};
