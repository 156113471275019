import { ReactNode, useEffect, useState } from "react";
import { H3, LoadByScroll } from "@sdelka_crm/sdelka-crm-component-library";
import styles from "./index.module.scss"
import { UserListCards } from "./components";
import { useBlockAction } from "../helpers";
import { StageConditions, UserListItem } from "../../../../types";

type Props = {
  type?: any;
  title: string;
  applicationId?: string;
  scrollY?: number;
  children?: ReactNode;
  isSuccess: boolean;
  enabled?: boolean;
  stageConditions?: StageConditions;
  users: UserListItem[];
  refetch: () => void;
  handleDelete?: ({applicationId, id, type}) => void;
  mini?: boolean;
  classNames?: string
  atModal?: boolean
  atDeal?: boolean
  withoutDots?: boolean
  withoutLinks?: boolean
  withAvatar?: boolean
  allowOpenInNewTab?: boolean
};

export const UserList = (
  {
    type,
    isSuccess,
    children,
    title,
    enabled = true,
    applicationId = "",
    scrollY,
    stageConditions,
    users,
    refetch,
    handleDelete,
    mini,
    classNames,
    atModal,
    atDeal,
    withoutDots,
    withoutLinks,
    withAvatar,
    allowOpenInNewTab,
  }: // eslint-disable-next-line sonarjs/cognitive-complexity
    Props): JSX.Element => {
  const [startFetching, setStartFetching] = useState(false);

  const haveStageCondition = stageConditions && (stageConditions.isLast || stageConditions?.isNext);

  const {checkAllow} = useBlockAction({
    isLast: stageConditions ? haveStageCondition : false,
  });

  const handleInView = () => enabled && setStartFetching(true);
  const onDelete = (id: any) => {
    checkAllow(() => (handleDelete ? handleDelete({applicationId, id, type}) : {}));
  };

  useEffect(() => {
    if (isSuccess) refetch();
  }, [isSuccess]);

  useEffect(() => {
    if (startFetching) refetch();
  }, [startFetching]);

  useEffect(() => {
    handleInView()
  }, [enabled])


  return (
    <>
      <H3 classNames={{root: atDeal ? styles.title : ""}}
          button={(stageConditions ? !haveStageCondition : true) && children}>{title}</H3>

      <LoadByScroll
        isLoading={enabled && !startFetching}
        height={300}
        scrollY={scrollY}
        onInView={handleInView}
      >
        <UserListCards
          data={users}
          mini={mini}
          classNames={classNames}
          atModal={atModal}
          refetch={refetch}
          onDelete={onDelete}
          withoutDots={withoutDots}
          withoutLinks={withoutLinks}
          withAvatar={withAvatar}
          allowOpenInNewTab={allowOpenInNewTab}
        />
      </LoadByScroll>
    </>
  );
};
