import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useCallCenterActions, useFilterAsQueryParam, useSuccessModal } from "./helpers";
import { toastError } from "../../../../../../../../../UI";
import { DefaultRequestArgs, MarkSuccessForm, } from "../../../../../../../../../../types";
import styles from "./index.module.scss";
import { ReactComponent as AccountCircle } from "../../../../../../../../../../assets/icons/account-circle.svg";
import { ReactComponent as CashRemove } from "../../../../../../../../../../assets/icons/cash-remove.svg";
import { ReactComponent as ThumbUp } from "../../../../../../../../../../assets/icons/thumb-up-outline.svg";
import { ReactComponent as TransferRight } from "../../../../../../../../../../assets/icons/transfer-right.svg";
import { ReactComponent as PhoneRemove } from "../../../../../../../../../../assets/icons/phone-remove.svg";

type Props = {
  parserObjectId: string;
  onSuccess?: () => void
} & Partial<MarkSuccessForm>;

export const CallCenterMenu = ({parserObjectId, onSuccess, ...successFormDefaultValues}: Props): JSX.Element => {
  const {open: openSuccesModal, close: closeSuccessModal} = useSuccessModal();
  const filter = useFilterAsQueryParam();

  const handleSuccess = () => {
    if (onSuccess) {
      onSuccess()
    }
  }

  const onActionError = () => toastError({text: "Что-то пошло не так."});

  const {actions, isAnyActionLoading} = useCallCenterActions({
    onSuccess: handleSuccess,
    onError: onActionError,
  });

  // Args that must be in any action
  const defaultActionArgs = {parserObjectId, filter};

  const createSuccessHandler = () => (formData: MarkSuccessForm) => {
    closeSuccessModal();
    actions.markSuccess.mutate({...defaultActionArgs, ...formData});
  };

  const createDefaultHandler = (mutateFn: (args: DefaultRequestArgs) => unknown) => () =>
    mutateFn(defaultActionArgs);

  return (
    <div className={styles.menuItems}>
      <Button
        variant="outlined"
        Icon={AccountCircle}
        color="yellow"
        isLoading={actions.markAgent.isLoading}
        disabled={isAnyActionLoading}
        onClick={createDefaultHandler(actions.markAgent.mutate)}
      >
        Агент
      </Button>

      <Button
        Icon={CashRemove}
        variant="outlined"
        color="red"
        isLoading={actions.markArchive.isLoading}
        disabled={isAnyActionLoading}
        onClick={createDefaultHandler(actions.markArchive.mutate)}
      >
        Продан
      </Button>

      <Button
        Icon={ThumbUp}
        variant="outlined"
        color="green"
        isLoading={actions.markSuccess.isLoading}
        disabled={isAnyActionLoading}
        onClick={() =>
          openSuccesModal({
            onSubmit: createSuccessHandler(),
            ...successFormDefaultValues,
          })
        }
      >
        Норм
      </Button>
      <Button
        variant="outlined"
        Icon={TransferRight}
        isLoading={actions.markSkip.isLoading}
        disabled={isAnyActionLoading}
        onClick={createDefaultHandler(actions.markSkip.mutate)}
      >
        Пропустить
      </Button>

      <Button
        variant="outlined"
        Icon={PhoneRemove}
        isLoading={actions.markNotAvailable.isLoading}
        disabled={isAnyActionLoading}
        onClick={createDefaultHandler(actions.markNotAvailable.mutate)}
      >
        Недоступен
      </Button>
    </div>
  );
};
