import styles from "./index.module.scss";
import { CardProductForTable } from "../../../../../UI";
import { AppliedProduct } from "../../../../../../types/product";

export const useColumns = (refetch: () => void, isMobile?: boolean) => [
  {
    accessor: "product",
    render: ({row}: { row: AppliedProduct }) => (
        <div className={styles.root}>
          <CardProductForTable
            product={row}
            refetch={refetch}
            type="applied"
            mini={isMobile}
          />
        </div>
      ),
  },
];
