import { useQuery } from "react-query";

import { api } from "../../index";

type UseLoadCompare = {
	contactId: string;
	objectId: string;
};

export const useLoadCompare = ({ contactId, objectId }: UseLoadCompare) => {
	const loadCompare = () => api.publicPage.compare(contactId, objectId);

	const { data, isLoading, refetch } = useQuery("loadCompare", loadCompare);

	return {
		data: data?.data,
		isLoading,
		refetch,
	};
};
