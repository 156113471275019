import { ReactElement, useEffect, useState } from "react";
import cn from "classnames";

import styles from "./index.module.scss";

type TabRadioProps = {
	children: ReactElement;
};

export const TabRadio = ({ children }: TabRadioProps): JSX.Element => <div>{children}</div>;

type TabsRadioProps = {
	children: ReactElement[];
	titles: {
		label: string;
		count?: number;
	}[];
	onTabChange?: (index: number) => void;
	initialTab?: number;
	background?: string
};

export const TabsRadio = ({ children, titles, onTabChange, initialTab, background }: TabsRadioProps): JSX.Element => {
	const [selectedTab, setSelectedTab] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (initialTab !== undefined) {
			setSelectedTab(initialTab);
		}
	}, [initialTab]);

	const handleTabChange = (tab: number) => {
		if (onTabChange) {
			onTabChange(tab);
		}

		setSelectedTab(tab);
	};

	return (
		<>
			<div className={cn(styles.columnsWrapper, background)}>
				{titles.map((title, index) => (
					<div
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						className={cn(styles.titleItem, {
							[styles.titleItemActive]: index === selectedTab,
						})}
						onClick={() => handleTabChange(index)}
					>
						{`${title.label} (${title.count || 0})`}
					</div>
				))}
			</div>

			<div>{selectedTab !== undefined ? children[selectedTab] : null}</div>
		</>
	);
};
