import { LeadInList } from "../../../../../../types";

import styles from "./index.module.scss";
import { CardLead } from "../../../../../UI/Card/card-lead/CardLead";

export const useColumns = (isMobile?: boolean) => [
  {
    accessor: "numberAndCreatedAt",
    render: ({row}: { row: LeadInList }) => (
      <div className={styles.root}>
        <CardLead lead={row} background="white" mini={isMobile}/>
      </div>
    ),
  },

];
