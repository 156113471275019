import { Control, useFieldArray } from "react-hook-form";
import { RealEstateFilterField } from "../../../../../../types";

import { InputList } from "../../../../index";

import styles from "./index.module.scss";

type Props = {
	control: Control<any>;
	field: RealEstateFilterField;
	name: string;
	disabled?: boolean;
};

// Component with Input list for include and exclude words.
export const CustomWords = ({ field, control, name, disabled }: Props): JSX.Element => {
	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	return (
		<InputList
			fieldName={name}
			fields={fields}
			control={control}
			append={append}
			remove={remove}
			otherLabels={field.label}
			firstLabel={field.label}
			objectKey="word"
			placeholder={field?.placeholder || "Введите текст"}
			className={styles.inputWords}
			aspect={{ label: 9.5, content: 6 }}
			disabled={disabled}
		/>
	);
};
