import { useMutation } from "react-query";

import { EditTaskPayload } from "../../../../types";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/tasks/update-task/DEFAULT_MN";

type UseUpdateTask = {
	onSuccess?: (data) => void;
	onError?: () => void;
};

export const useUpdateTask = ({ onSuccess, onError }: UseUpdateTask) => {
	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: (payload: EditTaskPayload) => api.tasks.updateTask(payload),
		onSuccess,
		onError,
	});

	return {
		mutate: mutate.mutate,
		isLoading: mutate.isLoading,
		mutateAsync: mutate.mutateAsync,
	};
};
