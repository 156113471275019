import { useCallback, useEffect, useRef, useState } from "react";
import { Pagination } from "@sdelka_crm/sdelka-crm-component-library";
import { RealEstateTabs, SidebarLayout } from "../../UI";
import { MobileFiltersMainButtons, ObjectListFastFilters, ObjectListHeader, ObjectsMap } from "./components";
import { ObjectsListRegular, RealEstateListCompilation, SampleSelected } from "./views";
import { selectCurrentAccount, selectDevice, useSelect } from "../../../service/redux/selectors";
import { useSelector } from "../../../service/redux/useSelector";
import { defaultValues } from "../../../utils/clientObjects";
import { useQueryParams } from "../../../utils/hooks";
import { useDispatch } from "react-redux";
import { openDrawer } from "../../../service/redux/modules/drawer/drawer.action";
import { useAutoScroll, useLoadData, useSample, useSyncCompilationFilters } from "./helpers";
import { useUrlWatcher } from "../../../service/servise";
import { ObjectsMapWrapper } from "./components/ObjectsMap/wrapper/ObjectsMapWrapper";
import { RealEstateFilter, RealEstateListTab } from "../../../types";
import { ReactComponent as Arrow } from "../../../assets/icons/arrow-down.svg";
import cn from "classnames";
import styles from "./index.module.scss";

type TabType = "PARSED" | "AGENCY"

type QueryParams = {
  compilation: boolean;
  applicationId: string;
  compilationApplicationId: string;
  comparedApplicationId: string;
  page: number;
  filter: RealEstateListTab;
  tab: TabType;
};

export const RealEstateList = (): JSX.Element => {
  const {objectsGroupIds} = useSelector((state) => state.objectList);
  const dispath = useDispatch()
  const [objectsFilterTypes, setObjectsFilterTypes] = useState<string[]>(defaultValues.category ?? []);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {currentAccount} = useSelect(selectCurrentAccount)

  const {queryParams, changeQueryParams} = useQueryParams<QueryParams>([
    {name: "compilation", type: "boolean"},
    {name: "applicationId"},
    {name: "compilationApplicationId"},
    {name: "preApplicationId"},
    {name: "page", type: "number"},
    {name: "filter"},
    {name: "comparedApplicationId", type: "string"},
    {name: "tab", type: "string"},
  ]);

  const {isMobile, isTelegram} = useSelect(selectDevice)
  const currentTab = queryParams.tab ?? "PARSED";

  const {
    list: realEstatesList,
    isFetching,
    total,
    take,
    setTake,
    page,
    setPage,
    setSort,
    filter,
    setFilter,
    refetch: refetchList
  } = useLoadData({
    defaultPage: queryParams.page,
    tab: currentTab,
  });

  useUrlWatcher({
    refetch: refetchList,
  })

  const countFilters = useCallback((arr: Array<any>) => {
    let res = []
    res =  res.concat(...arr.filter(el => el !== undefined)
      .filter(el => el !== "")
      .filter(el => el.label !== "" && el.value !== "")
    )
    res.forEach(el => {
      if (Array.isArray(el) && (el as Array<any>).length === 0) {
        const index = res.findIndex(el)

        res.splice(index, 1)
      }
    })
    return res.length
  }, [filter])

  useAutoScroll({wrapperRef, isFetching, page});
  const [actualFilter, setActualFilter] = useState(filter)
  const [filtersCount, setFiltersCount] = useState(countFilters(Object.values(filter)))

  useEffect(() => {
    setFiltersCount(countFilters(Object.values(filter)))
  }, [filter])

  const {handleOnSaveCompilationFilter} = useSyncCompilationFilters({
    applicationId: queryParams.compilationApplicationId,
    isCompilationPage: queryParams.compilation,
    filter,
    setFilter,
  });

  useEffect(() => {
    setTimeout(() => {
      if (!queryParams?.filter) return;
      const parsedFilters = queryParams?.filter && JSON.parse(queryParams?.filter);
      setFilter(parsedFilters);
    }, 1000);

    return () => {
      setObjectsFilterTypes([]);
    };
  }, []);

  useEffect(() => {
    if (queryParams?.filter) {
      const parsedFilters = JSON.parse(queryParams?.filter);
      setFilter(parsedFilters);
    }
  }, [queryParams?.filter, queryParams?.tab]);

  const handlePageChange = (nextPage: number) => {
    setPage(nextPage);

    changeQueryParams([{name: "page", newValue: nextPage}]);
  };

  const changeActualFilter = (payload: RealEstateFilter) => {
    handlePageChange(1);
    setActualFilter(payload);
  };

  const changeFilter = (payload: RealEstateFilter) => {
    handlePageChange(1);
    setFilter(payload);
  };

  const resetFilter = () => {
    handlePageChange(1);
    setFilter(defaultValues);

    const encodedBackPath = encodeURIComponent(JSON.stringify(defaultValues));
    changeQueryParams([{name: "filter", newValue: encodedBackPath, isEncode: true}]);
  };

  const setMapFilter = (value) => {
    setActualFilter(value)
    setFilter(value)
  }

  const openMapDrawer = () => {
    dispath(openDrawer({
      children:
        <ObjectsMapWrapper
          realEstatesList={realEstatesList}
          filter={actualFilter}
          setFilter={setMapFilter}
          isParser={isParser}
          isMobile={isMobile}
        />,
      width: 500
    }))
  }

  const {compilation, compilationApplicationId, comparedApplicationId} = queryParams;
  const sampledApplicationId = compilationApplicationId || comparedApplicationId;
  const {isSample, isCompilation, isCompare} = useSample({
    compilation,
    comparedApplicationId,
    applicationId: compilationApplicationId,
  });

  const isParser = currentTab !== "AGENCY";

  const [filtered, setFiltered] = useState(false)
  const [selectedPoint, setSelectedPoint] = useState(false)
  const filterByMapClick = (ids?: string[]) => {
    setFiltered(true)
    setFilter({...filter, id: ids})
  }

  const RealEstateFiltered = (
    <>
      <div className={styles.return} onClick={() => {
        setFiltered(false)
        setFilter({...filter, id: []})
        setSelectedPoint(false)
      }}>
        <Arrow/>
        <div>
          Вернуться к списку
        </div>
      </div>
      <div className={styles.total}>
        {`${total} объектов`}
      </div>
      <ObjectsListRegular
        realEstatesList={realEstatesList}
        isLoading={isFetching}
        isParser={isParser}
        refetchList={refetchList}
        isMobile={isMobile}
      />
    </>
  )

  return (
    <>
      <SidebarLayout
        fullSize
        isMobile={isMobile}
        isTelegram={isTelegram}
        hat={!isMobile && !filtered ? {
          element: (
            <ObjectListFastFilters
              globalFilter={filter}
              changeGlobalFilter={changeFilter}
              isCompilation={queryParams.compilation}
              onSaveCompilationFilters={handleOnSaveCompilationFilter}
              className={queryParams.compilation ? styles.filtersShorter : ""}
              objectsFilterTypes={objectsFilterTypes}
              setObjectsFilterTypes={setObjectsFilterTypes}
              resetFilter={resetFilter}
              tab={currentTab}
              changeActualFilter={changeActualFilter}
              isParser={isParser}
              isMobile={isMobile}
            />
          ),
          elementHeight: 50,
        } : {
          element: <>
          </>, elementHeight: 0
        }}
        rightColumn={
          isSample ? (
            <SampleSelected
              isCompilation={isCompilation}
              isCompare={isCompare}
              sampledApplicationId={sampledApplicationId}
            />
          ) : undefined
        }
      >
        <div className={cn(styles.root,
          {[ styles.rootFull ]: filtered || isTelegram,
            [styles.rootAlert]: !currentAccount?.isPremium})
        }>
          <div ref={wrapperRef} className={cn(styles.listWrapper, {[ styles.isMobileWrapper ]: isMobile})}>
            <div
              className={cn(styles.list, {
                [ styles.listWithNoFilters ]: !objectsFilterTypes?.length,
                [ styles.isMobile ]: isMobile,
                [ styles.listAtCompilation ]: objectsFilterTypes?.length > 0,
              })}
            >
              {filtered ?
                <>
                  {RealEstateFiltered}
                </>
                : <>
                  {isMobile &&
                    <MobileFiltersMainButtons
                      filtersCount={filtersCount}
                      clearFilters={resetFilter}
                      openMap={openMapDrawer}
                      filter={filter}
                      setObjectsFilterTypes={setObjectsFilterTypes}
                      tab={currentTab}
                      setFilter={setFilter}
                    />
                  }
                  <RealEstateTabs realEstateListFilters={filter}/>
                  {!objectsGroupIds.length && (
                    <ObjectListHeader
                      total={total}
                      take={take}
                      setTake={setTake}
                      setSort={setSort}
                      isLoading={isFetching}
                      isMobile={isMobile}
                    />
                  )}
                  {isSample ? (
                    <RealEstateListCompilation
                      realEstatesList={realEstatesList}
                      isLoading={isFetching}
                      sampledApplicationId={sampledApplicationId}
                      isCompare={isCompare}
                      isCompilation={isCompilation}
                      isParser={isParser}
                    />
                  ) : (
                    <ObjectsListRegular
                      realEstatesList={realEstatesList}
                      isLoading={isFetching}
                      isParser={isParser}
                      refetchList={refetchList}
                      isMobile={isMobile}
                    />
                  )}
                  {!objectsGroupIds.length && (
                    <Pagination
                      totalCount={total}
                      isLoading={isFetching}
                      pageSize={take}
                      currentPage={page}
                      onPageChange={(nextPage: number) => handlePageChange(nextPage)}
                      classNames={{root: styles.pagination}}
                    />
                  )}
                </>
              }
            </div>
          </div>
          {!isMobile ? <div className={styles.map}>
              <ObjectsMap
                filter={actualFilter}
                setFilter={setMapFilter}
                isParser={isParser}
                isMobile={isMobile}
                filterByMapClick={filterByMapClick}
                selectedPoint={selectedPoint}
                setSelectedPoint={setSelectedPoint}
              />
            </div>
            : <></>
          }
        </div>
      </SidebarLayout>
    </>
  );
};
