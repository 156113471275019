import { useHistory } from "react-router";
import { UseMutateFunction, useMutation } from "react-query";
import { AxiosResponse } from "axios";
import { KindType, RealEstateCreatePayload } from "../../../../types";
import { api } from "../../api";
import { useDispatch } from "react-redux";
import { changeCurrentTab, closeDrawer, openDrawer } from "../../../redux/modules/drawer/drawer.action";
import { ApplicationCard } from "../../../../components/pages/Application";
import { useOpenObjectPreApplication } from "../../../servise";

const DEFAULT_MN = "sdelka/real-estate/create-real-estate/DEFAULT_MN";

type Props = {
  onSuccess?: (data) => void;
  disabledPush?: boolean;
};

type Return = {
  mutate: UseMutateFunction<AxiosResponse<{
    preApplicationId: string;
  }>,
    unknown,
    RealEstateCreatePayload,
    unknown>;
  isLoading: boolean;
};

type UseCreateApplicationObject = (props: Props) => Return;

export const useCreateApplicationObject: UseCreateApplicationObject = (
  {
    onSuccess,
    disabledPush = false,
  }) => {
  const {push} = useHistory();
  const dispatch = useDispatch()

  const {openObjectPreApplication} = useOpenObjectPreApplication({atDrawerInDrawer: false})
  const onCardClick = (id: string) => {
    dispatch(
      openDrawer({
        width: 800,
        children:
          <ApplicationCard
            applicationId={id}
            kind={KindType.APPLICATION}
          />,
        onClose: () => {
          window.location.href = "/real-estate?tab=AGENCY"
        }
      })
    );

    openObjectPreApplication({
      applicationId: id,
      hasMap: true,
      isFromLead: false,
      isFromObjectList: false,
      onClose: () => {
        window.location.href = "/real-estate?tab=AGENCY"
      }
    })
  };

  const onCreateSuccess = (response: AxiosResponse<{ preApplicationId: string }>) => {
    if (onSuccess) {
      onSuccess(response.data);
    }

    if (!disabledPush && response?.data?.preApplicationId) {
      dispatch(closeDrawer())
      push(`/real-estate?tab=AGENCY`);
      setTimeout(() => {
        onCardClick(response?.data?.preApplicationId)
      }, 1000)
    }
  };

  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: (payload: RealEstateCreatePayload) => api.applicationRealEstate.create(payload),
    onSuccess: (response) => onCreateSuccess(response),
  });

  return {
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
  };
};
