import { useDispatch } from "react-redux";
import styles from "./index.module.scss"
import { useNewDate } from "../../../../../../../service/api/mutations";
import { ChangeDateModal } from "./ChangeDateModal";
import { openModal } from "../../../../../../../service/redux/modules/modal/modal.action";
import { selectDevice, useSelect } from "../../../../../../../service/redux/selectors";

type Props = {
  id: string
  onSuccess: () => void;
  isViews?: boolean
  plannedDate: Date
}

type Return = {
  openOpenChangeDateModal: () => void
}

// eslint-disable-next-line @typescript-eslint/naming-convention
type UseOpenOpenChangeDateModal = (props: Props) => Return

export const UseOpenChangeDateModal: UseOpenOpenChangeDateModal = (
  {
    id,
    onSuccess,
    isViews,
    plannedDate
  }) => {

  const {mutate} = useNewDate({isViews})
  const {isMobile} = useSelect(selectDevice)
  const width = isMobile ? 500 : 660
  const dispatch = useDispatch()

  const openOpenChangeDateModal = () => {
    dispatch(
      openModal({
        width,
        contentClass: styles.root,
        classNames:{
          contentBody: styles.root,
          contentWrapper: isMobile ? styles.modal : "",
          buttonClose: isMobile ? styles.modalClose : ""
        },
        body:
          <ChangeDateModal
            onChange={mutate}
            id={id}
            onSuccess={onSuccess}
            isViews={isViews}
            plannedDate={plannedDate}
          />
      })
    )
  }

  return {
    openOpenChangeDateModal
  }
}