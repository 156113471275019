import { useState } from "react";
import { useQuery } from "react-query";

import { api } from "../../index";

export const useLoadCallsWithPhone = (phone: string) => {
	const [take, setTake] = useState(10);
	const [page, setPage] = useState(1);

	const loadCalls = () => api.call.getCallsWithPhone(phone, { take, skip: take * (page - 1) });

	const { data, isLoading } = useQuery(["loadCallsWithPhone", take, page], loadCalls);

	return {
		data: data?.data?.data || [],
		isLoading,
		take,
		setTake,
		page,
		setPage,
		canViewAllCompany: data?.data?.canViewAllCompany || false,
	};
};
