import { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { RealEstateSchemaConfigField, FormPropsEditRealEstate } from "../../../../../../../../../../types";

import { MultiSelect } from "../../../../../../../../index";

type Props = {
	form: FormPropsEditRealEstate;
	field: RealEstateSchemaConfigField;
};

export const MultiSelectTemplate = ({ form, field }: Props): JSX.Element => {
	const {
		dataType: { name = "", allowedValues },
	} = field;
	const { register, setValue, control, getValues } = form;

	const options = useMemo(
		() =>
			allowedValues?.map((allowed) => ({
				value: allowed,
				label: allowed,
			})) || [],
		[]
	);

	const multiSelectDefaultValues = useWatch({
		control,
		name,
		defaultValue: getValues(name) || [],
	});

	if (!name) {
		return <></>;
	}

	return (
		<MultiSelect
			name={name}
			defaultValue={multiSelectDefaultValues}
			options={options}
			register={register}
			placeholder="Выбрать"
			setValue={setValue}
			isSearchable
			isSortSelected
		/>
	);
};
