import { useMutation } from "react-query";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/shows-views/cancel-show-view/DEFAULT_MN";

type UseCancelShowOrView = {
	onSuccess?: (id: any) => void;
	isViews?: boolean
};

export const useCancelShowOrView = ({ onSuccess, isViews }: UseCancelShowOrView) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (id: string) => isViews ? api.showsViews.cancelView(id) : api.showsViews.cancelShow(id),
		onSuccess,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
