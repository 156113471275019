import cn from "classnames";
import { Tooltip } from "@sdelka_crm/sdelka-crm-component-library";

import { RealEstate } from "../../../../../../../types";
import { formatThousand } from "../../../../../../../utils/formatters";
import { ReactComponent as M2 } from "../../../../../../../assets/icons/iconM2.svg";
import styles from "./index.module.scss";

type Props = {
  realEstate: RealEstate;
};

export const Header = ({realEstate}: Props): JSX.Element => (
  <div className={styles.root}>
    <div className={cn(styles.row, styles.title)}>
      <div className={styles.rowLeft}>{realEstate.title}</div>
      <div className={cn(styles.rowRight, styles.price)}>
        {`${formatThousand(realEstate.price)} ₽`}
        <Tooltip
          CustomIcon={M2}
          iconSize="big"
          classNames={{
            root: styles.tooltipDescription,
            card: styles.tooltipCard,
          }}
        >
          <div className={styles.middlePrice}>
            <span>{realEstate.pricePerUnit && `${formatThousand(Number(realEstate?.pricePerUnit))} ₽/м2`}</span>
          </div>
        </Tooltip>
      </div>
    </div>
    <div className={styles.row}>
      <div className={styles.rowLeft}>
				<span className={styles.address}>
					{realEstate?.addressLabel}
					{/* Pano hidden while back is not ready */}
					{/* <Button variant="text">
            панорама дома
          </Button> */}
				</span>
      </div>
      <div className={cn(styles.rowRight, styles.priceWith)}>
        <div className={styles.priceUnit}>
					<span>
						{realEstate?.priceWithDiscount && `${formatThousand(Number(realEstate?.priceWithDiscount))} ₽ со скидкой`}
					</span>
        </div>
      </div>
    </div>
  </div>
);
