import { mapComponentsByBlockType } from "./helpers";
import {
  ApplicationCardBlock,
  ApplicationCardResponse,
  KindType,
  RealEstate,
  User,
  WhoIsClientType
} from "../../../../../../../../../../../../../../types";

type Props = {
  block: ApplicationCardBlock;
  index: number;
  stageNo: number;
  scrollY: number;
  comment: string;
  applicationId: string;
  clientObject: RealEstate | null;
  onTab?: (tab: number) => void;
  unpinObject: () => void;
  addObject: (object: RealEstate) => void;
  additionalProperties: any;
  collectionDescription: string;
  refetch: () => void;
  selectedTab?: number;
  onPinObject: (object: RealEstate) => void;
  responsibleUser?: User;
  kind?: KindType,
  application?: ApplicationCardResponse;
  whoIsClient: WhoIsClientType
  buyApplicationId?: string
  saleApplicationId?: string
  isMobile?: boolean
};

export const Block = (
  {
    block,
    index,
    stageNo,
    scrollY,
    comment,
    applicationId,
    clientObject,
    onTab,
    unpinObject,
    addObject,
    additionalProperties,
    collectionDescription,
    refetch,
    selectedTab,
    onPinObject,
    responsibleUser,
    kind,
    application,
    whoIsClient,
    buyApplicationId,
    saleApplicationId,
    isMobile
  }: Props): JSX.Element => {
  const {type, title, props, questions} = block;

  if (mapComponentsByBlockType[ type ] === undefined) {

    return <></>;
  }

  const isNext = stageNo < index + 1;
  const isLast = stageNo > index + 1;
  const stageConditions = {
    isLast,
    isNext,
  };

  const {id: clientObjectId} = clientObject || {};

  const resultComponent: JSX.Element = mapComponentsByBlockType[ type ]({
    onTab,
    scrollY,
    clientObjectId,
    comment,
    applicationId,
    unpinObject,
    addObject,
    clientObject,
    additionalProperties,
    title,
    props,
    questions,
    collectionDescription,
    refetch,
    stageConditions,
    selectedTab,
    onPinObject,
    responsibleUser,
    kind,
    block,
    application,
    whoIsClient,
    buyApplicationId,
    saleApplicationId,
    isMobile,
  });

  return <div>{resultComponent}</div>;
};
