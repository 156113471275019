import { useMutation, useQueryClient } from "react-query";

import { api } from "../../api";
import { AxiosResponse } from "axios";
import { FindAddressDetailed } from "../../../../types";

const DEFAULT_MN = "sdelka/address/load-points-detailed/DEFAULT_MN";

type Props =  {
  onSuccess?: (res: AxiosResponse<FindAddressDetailed[]>) => void
}

export const useFindPointDetailed = ({onSuccess}: Props) => {
  const queryClient = useQueryClient();

  const mutate = useMutation(DEFAULT_MN, {
    mutationFn: (addressOrPoint: string) => api.address.findAddressPoint(addressOrPoint),
    onSuccess
  });

  const getLastMutationCache = () => {
    const allMutations = queryClient.getMutationCache().findAll({ mutationKey: DEFAULT_MN });

    return allMutations[allMutations.length - 1];
  };

  return {
    mutate: mutate.mutate,
    mutateAsync: mutate.mutateAsync,
    isLoading: mutate.isLoading,
    data: mutate.data,
    status: mutate.status,
    getLastMutationCache,
  };
};
