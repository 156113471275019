import { useDispatch } from "react-redux";
import { UseFormGetValues, UseFormSetValue, Control, useWatch } from "react-hook-form";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { InfoRow, PinContact, QuestionnaireValue } from "../../../index";
import { Boolean } from "../Boolean";
import { closeModal } from "../../../../../service/redux/modules/modal/modal.action";
import { useContact } from "../../../../../service/api/queries";
import { prepareShortUserOutput } from "../../../../../utils/transformation";
import { UserData } from "../../../../../types";

import styles from "./index.module.scss";

type Props = {
	handleOnChange: (value: QuestionnaireValue) => void;
	getValues: UseFormGetValues<any>;
	setValue: UseFormSetValue<any>;
	control: Control<any>;
	nameGroup: string[];
	disabled?: boolean;
};
export const BooleanWithContact = ({
	nameGroup,
	getValues,
	setValue,
	control,
	handleOnChange,
	disabled,
}: Props): JSX.Element => {
	const dispatch = useDispatch();

	const [radioName, inputName] = nameGroup;

	const change = (value: QuestionnaireValue) => {
		if (value === 0) {
			setValue(inputName, "");
		}

		if (handleOnChange) {
			handleOnChange(value);
		}
	};

	const [radioWatcher, contactWatcher] = [
		useWatch({
			control,
			name: radioName,
			defaultValue: getValues(radioName),
		}),
		useWatch({
			name: inputName,
			control,
			defaultValue: getValues(inputName),
		}),
	];

	const { contact } = useContact(contactWatcher);

	const addContact = ({ id }: { id: string }) => {
		setValue(inputName, id);
		handleOnChange(id);
		dispatch(closeModal());
	};

	const haveContact = contactWatcher && contact && (contactWatcher as string)?.length > 1;

	const btnLabel = haveContact ? "Изменить" : "Выбрать";

	const label = haveContact ? prepareShortUserOutput(contact as UserData) : "Укажите контакт агента";

	return (
		<div>
			<Boolean control={control} name={radioName} handleOnChange={change} disabled={disabled} />

			{radioWatcher === 1 && (
				<InfoRow label={label} aspect={{ label: 2, content: 3 }}>
					<div className={styles.row}>
						{!disabled && (
							<PinContact onPin={addContact} hideThisIds={contactWatcher}>
								<Button variant="text">{btnLabel}</Button>
							</PinContact>
						)}
					</div>
				</InfoRow>
			)}
		</div>
	);
};
