import { ReactNode } from "react";
import cn from "classnames";

import { AdStatusEnum } from "../../../types";

import styles from "./index.module.scss";
import { ReactComponent as AlertIcon } from "../../../assets/icons/alert.svg";
import { ReactComponent as ClockFilledIcon } from "../../../assets/icons/clock-outline-wrapped-2.svg";
import { ReactComponent as ClockTimerIcon } from "../../../assets/icons/clock-timer.svg";

type AdStatusIconMap = {
	[K in AdStatusEnum]?: ReactNode;
};

export const adStatusIconMap: AdStatusIconMap = {
	[AdStatusEnum.NotPublished]: null,
	[AdStatusEnum.NotRequested]: <ClockFilledIcon className={cn(styles.icon, styles.clockIcon)} />,
	[AdStatusEnum.Published]: <ClockTimerIcon className={cn(styles.icon, styles.timerIcon)} />,
	[AdStatusEnum.Error]: <AlertIcon className={cn(styles.icon, styles.alertIcon)} />,
	[AdStatusEnum.Removed]: null,
};
