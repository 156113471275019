import {
  ADD_NEW_TAB,
  ADD_NEW_TAB_IN_DRAWER,
  CHANGE_CURRENT_TAB,
  CHANGE_CURRENT_TAB_BY_LABEL,
  CHANGE_CURRENT_TAB_IN_DRAWER,
  CHANGE_CURRENT_TAB_IN_DRAWER_BY_LABEL,
  CLOSE_DRAWER,
  CLOSE_DRAWER_IN_DRAWER,
  DELETE_TAB,
  DRAWER_IN_DRAWER_SCROLLED,
  DRAWER_SCROLLED,
  DrawerAction,
  OPEN_DRAWER,
  OPEN_DRAWER_IN_DRAWER,
  RELOAD_DRAWER,
  RELOAD_DRAWER_IN_DRAWER,
  SHOW_MAP
} from "./drawer.action";
import { DrawerTInitialState } from "../../types";

const initialState: DrawerTInitialState = {
  isOpen: false,
};

export const drawerReducer = (state = initialState, action: DrawerAction): DrawerTInitialState => {
  switch (action.type) {
    case OPEN_DRAWER: {
      return {
        ...state,
        isOpen: true,
        children: action.payload.children,
        width: action.payload.width,
        onClose: action.payload.onClose,
        classNames: action.payload.classNames,
        yNavigation: action.payload.yNavigation,
        disableClose: action.payload.disableClose,
        hasMap: action.payload.hasMap,
        isMobile: action.payload.isMobile,
        scrolled: action.payload.scrolled,
        noRemoveQuery: action.payload.noRemoveQuery,
      };
    }
    case SHOW_MAP: {
      return {
        ...state,
        hasMap: action.payload.hasMap
      }
    }
    case OPEN_DRAWER_IN_DRAWER: {
      return {
        ...state,
        drawerInDrawer: {
          ...state.drawerInDrawer,
          children: action.payload.children,
          width: action.payload.width,
          onClose: action.payload.onClose,
          withCloseArrow: action.payload.withCloseArrow,
          yNavigation: action.payload.yNavigation,
          scrolled: action.payload.scrolled
        },
      };
    }
    case RELOAD_DRAWER_IN_DRAWER:
      return {
        ...state,
        drawerInDrawer: {
          ...state.drawerInDrawer,
          children: action.payload.children,
          width: action.payload.width,
        },
      }
    case RELOAD_DRAWER:
      return {
        ...state,
        children: action.payload.children,
        width: action.payload.width,
      }
    case CLOSE_DRAWER: {
      return {
        ...state,
        isOpen: false,
        children: undefined,
        width: undefined,
        onClose: undefined,
        classNames: undefined,
        yNavigation: undefined,
        drawerInDrawer: undefined,
        disableClose: undefined,
      };
    }
    case CLOSE_DRAWER_IN_DRAWER: {
      return {
        ...state,
        drawerInDrawer: undefined,
      };
    }

    case ADD_NEW_TAB: {
      const stateNav = state.yNavigation?.navigation || [];

      if (stateNav.find(el => el.title === action.payload.newNavigation.title)) {
        return state
      }

      return {
        ...state,
        yNavigation: {
          navigation: [...stateNav, action.payload.newNavigation],
          initialTab: state.yNavigation?.initialTab,
        },
        children: [...(Array.isArray(state?.children) ? state.children : []), action.payload.newChildren],
      };
    }

    case ADD_NEW_TAB_IN_DRAWER: {
      const stateNav = state.drawerInDrawer?.yNavigation?.navigation || [];

      return {
        ...state,
        drawerInDrawer: {
          ...state.drawerInDrawer,
          width: state.drawerInDrawer?.width ?? 800,
          yNavigation: {
            navigation: [...stateNav, action.payload.newNavigation],
            initialTab: state.yNavigation?.initialTab,
          },
          children: [...(state.drawerInDrawer && Array.isArray(state.drawerInDrawer.children)
            ? state.drawerInDrawer.children : []), action.payload.newChildren],
        },
      };
    }

    case DELETE_TAB: {
      const stateNav = state.yNavigation?.navigation || [];
      const indexToRemove = action.payload.index; // Замените tabIndex на index

      if (indexToRemove >= 0 && indexToRemove < stateNav.length) {
        const newNavigation = stateNav.filter((_, index) => index !== indexToRemove);

        return {
          ...state,
          yNavigation: {
            navigation: newNavigation,
            initialTab: state.yNavigation?.initialTab,
          },
          children: [...(Array.isArray(state?.children) ? state.children : [])],
        };
      }
      return state;
    }


    case CHANGE_CURRENT_TAB: {
      const stateNav = state.yNavigation?.navigation || [];

      return {
        ...state,
        changeTab: action.payload.newCurrentTab,
        yNavigation: {
          navigation: stateNav,
          initialTab: action.payload.newCurrentTab,
        },
      };
    }

    case CHANGE_CURRENT_TAB_BY_LABEL: {
      const stateNav = state.yNavigation?.navigation || [];
      const index = stateNav.findIndex(el => el.title === action.payload.title)

      if (index !== -1) {
        return {
          ...state,
          changeTab: index,
          yNavigation: {
            navigation: stateNav,
            initialTab: index,
          },
        }
      }
      return {
        ...state
      }

    }

    case CHANGE_CURRENT_TAB_IN_DRAWER: {
      const stateNav = state.drawerInDrawer?.yNavigation?.navigation || [];

      return {
        ...state,
        drawerInDrawer: {
          ...state.drawerInDrawer,
          width: state.drawerInDrawer?.width ?? 800,
          changeTab: action.payload.newCurrentTab,
          yNavigation: {
            navigation: stateNav,
            initialTab: action.payload.newCurrentTab,
          },
        },
      };
    }

    case CHANGE_CURRENT_TAB_IN_DRAWER_BY_LABEL: {
      const stateNav = state.drawerInDrawer?.yNavigation?.navigation || [];
      const index = stateNav.findIndex(el => el.title === action.payload.title)

      if (index !== -1) {
        return {
          ...state,
          drawerInDrawer: {
            ...state.drawerInDrawer,
            width: state.drawerInDrawer?.width ?? 800,
            changeTab: index,
            yNavigation: {
              navigation: stateNav,
              initialTab: index,
            },
          },
        }
      }
      return {
        ...state,
        drawerInDrawer: {
          ...state.drawerInDrawer,
          width: state.drawerInDrawer?.width ?? 800,
        }
      }

    }

    case DRAWER_SCROLLED: {
      return {
        ...state,
        scrolled: action.payload.scrolled
      }
    }

    case DRAWER_IN_DRAWER_SCROLLED: {
      return {
        ...state,
        drawerInDrawer: {
          ...state.drawerInDrawer,
          width: state.drawerInDrawer?.width ?? 800,
          scrolled: action.payload.scrolled
        }
      }
    }

    default:
      return state;
  }
};
