import { IUserEditFormResponse } from "../../../../../types";
import styles from "./index.module.scss"
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { MenuItem } from "../../../ShowsAndViews/components/card-actions/CardActions";
import { ReactComponent as OpenInNew } from "../../../../../assets/icons/openInNewBlue.svg";
import { ReactComponent as Arrows } from "../../../../../assets/icons/DoubleChevron_Right.svg";
import { ReactComponent as PenIcon } from "../../../../../assets/icons/penIconNormal.svg";
import { Popup } from "../../../../UI";
import cn from "classnames";
import { useFireModal } from "../fireModal";
import { useChangeOfficeModal } from "../change-office-modal";
import { ReactComponent as IconDots } from "../../../../../assets/icons/iconDotsInCircle.svg";
import { selectCurrentAccount, useSelect } from "../../../../../service/redux/selectors";
import { useDispatch } from "react-redux";
import { ReactComponent as LogoutIcon } from "../../../../../assets/icons/logout.svg";
import { logoutThunk } from "../../../../../service/redux/modules/auth/auth.thunk";
import { useCloseDrawer } from "../../../../../service/servise";
import { useIsTelegram } from "../../../../../utils/telegram/useIsTelegram";

type Props = {
  user?: IUserEditFormResponse
  canEdit?: boolean;
  refetch?: () => void;
  editMode: boolean
  isLoading: boolean
  toggle: () => void
  isMobile?: boolean
}

export const PersonalCardStickyFooter = (
  {
    user,
    canEdit,
    refetch,
    editMode,
    isLoading,
    toggle,
    isMobile
  }: Props): JSX.Element => {
  const dispatch = useDispatch()
  const {close} = useCloseDrawer({})
  const {executeUrl} = useIsTelegram()

  const openOnPublic = () => {
    executeUrl(`${user?.publicPageLink}`)
  }

  const onRefetch = () => {
    if (refetch) {
      refetch()
    }
  }
  const {openFireModal} = useFireModal({id: user?.id ?? "", refetch: onRefetch})
  const {openChangeModal} = useChangeOfficeModal({id: user?.id ?? "", refetch: onRefetch})

  const {currentAccount} = useSelect(selectCurrentAccount)
  // const {mutate} = useAddToCart({
  //   onSuccess: () => {
  //     if (refetch) {
  //       refetch()
  //     }
  //   }
  // })

  const isCurrent = currentAccount?.id === user?.id

  // const {openAddInCartModal} = useAddInCartModal({
  //   type: ProductTypeEnum.employerSubscribtion,
  //   title: "Выберите срок продления",
  //   action: mutate,
  //   isMobile
  // })

  const handleExit = () => {
    dispatch(logoutThunk())
    close()
  }

  const actionsMenu: MenuItem[] = [
    {
      label: "Редактировать",
      icon: <PenIcon/>,
      onClick: toggle,
      id: 1
    },
    {
      label: "Сменить офис",
      icon: <Arrows/>,
      onClick: openChangeModal,
      id: 2
    },
    {
      label: "Уволить",
      icon: <Arrows/>,
      onClick: openFireModal,
      id: 4
    },
  ]

  const exitMenu = [{
    label: "Выйти",
    icon: <LogoutIcon/>,
    onClick: () => handleExit(),
    id: 4,
  }]

  const filteredMenu = isCurrent ? actionsMenu.filter(el => el.id !== 2 && el.id !== 4) : actionsMenu
  const actualMenu = canEdit ? filteredMenu : actionsMenu.slice(2)

  return (
    <div className={styles.root}>
      {!editMode ?
        <div className={styles.actions}>
          <Popup
            openTrigger={isMobile ? <IconDots/> : <div className={styles.openTrigger}>Другие действия</div>}
            options={{withArrow: true}}
            classNames={
              {
                trigger: styles.trigger,
                arrow: styles.arrow,
                card: styles.popupCard
              }
            }
          >
            {(isMobile ? actualMenu.concat(exitMenu) : actualMenu).map((item) => (
              <div key={item.id} className={cn(
                styles.actionItem,
                {[ styles.deleteActionItem ]: item.className !== undefined}
              )} onClick={item.onClick}>
                {item.icon}
                <span>{item.label}</span>
              </div>
            ))}
          </Popup>
        </div>
        : <Button type="submit" isLoading={isLoading}>
          Сохранить
        </Button>
      }
    </div>
  )
}