import { CSSProperties } from "react";
import cn from "classnames";

import styles from "./index.module.scss";

type Dot = {
	label: string;
	disabled?: boolean;
	percent?: number;
};

type Props = {
	dots: Dot[];
};

const showProgress = (percent = 0): Pick<CSSProperties, "background"> => {
	const blue = `var(--main-blue) ${percent}%`;
	const grey = `var(--main-blue-inactive) ${percent}% ${100 - percent}%`;

	return {
		background: percent !== undefined ? `linear-gradient(to right, ${blue}, ${grey})` : "var(--main-blue)",
	};
};

export const DotProgress = ({ dots }: Props): JSX.Element => (
	<div className={styles.root}>
		{dots.map((dot) => (
			<div key={dot.label} className={styles.dotWrapper}>
				<div className={styles.label}>{dot.label}</div>

				<div style={showProgress(dot.percent)} className={styles.line}>
					<div className={cn(styles.dot, { [styles.disabled]: dot.disabled })} />
				</div>
			</div>
		))}
	</div>
);
