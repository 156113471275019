import styles from "./index.module.scss";

export const MainPhotoLabels = (): JSX.Element => (
	<>
		<div className={styles.label}>Главное фото</div>

		<div className={styles.shadow} />

		<div className={styles.info}>Перетяните нужное фото вперед, чтобы сделать его главным</div>
	</>
);
