import { useForm } from "react-hook-form";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { ContentBlock, InfoRow, MultiLayerSelect, MultiSelect } from "../../../../UI";
import { MainInfo } from "../components";
import { DEFAULT_TRAFFIC } from "../../../../../service/api/helpers";
import { ApplicationsFilter, KindType, SelectOption, User } from "../../../../../types";
import styles from "./index.module.scss";
import { ReactComponent as CancelIcon } from "../../../../../assets/icons/cancel.svg";
import { useResponsibleUsers, useRopeTemplatesOneLevel } from "../../../../../utils/hooks";
import { useWatchers } from "../RequestFastFilters/helpers";
import { useState } from "react";

type Props = {
  defaultValues: ApplicationsFilter;
  users: User[];
  submitFilter: (filter: ApplicationsFilter) => void;
  sourcesOptions: SelectOption[];
  isMobile?: boolean
  formWatcher?: ApplicationsFilter
};

const requestAgencyAgreementFormOptions = [
  {value: "ORAL", label: "Устная"},
  {value: "ONLINE", label: "Онлайн"},
  {value: "OFFLINE", label: "Оффлайн"},
  {value: "UNSIGNED", label: "Без договора"},
];

const requestStatusOptions = [
  {value: "inWork", label: "В работе"},
  {value: "success", label: "Успешная"},
  {value: "refusal", label: "Отказ"},
  {value: "onDeal", label: "На сделке"},
]

const requestKindOptions = [
  {value: KindType.PREAPPLICATION, label: "Предзаявки"},
  {value: KindType.APPLICATION, label: "Заявки"},
]

export const RequestFiltersModal = (
  {
    defaultValues,
    users,
    submitFilter,
    sourcesOptions,
    isMobile,
    formWatcher
  }: Props): JSX.Element => {
  const {handleSubmit, control, register, setValue, watch, reset, getValues} = useForm({
    defaultValues,
  });

  const [watcher, setWatcher] = useState(formWatcher)
  const onSubmit = (values: ApplicationsFilter) => submitFilter(values);

  const resetForm = () => {
    const updateForm = {
      ...DEFAULT_TRAFFIC,
      fullTextSearch: defaultValues.fullTextSearch,
      typeId: defaultValues.typeId,
      responsibleUsers: defaultValues.responsibleUsers,
      withTaskForToday: defaultValues.withTaskForToday,
    };
    setWatcher(DEFAULT_TRAFFIC)
    reset(updateForm);
  };

  const {preparedTemplates} = useRopeTemplatesOneLevel()
  const {responsibleUsers} = useResponsibleUsers();

  const {watcherResponsibleUsers} = useWatchers({
    control,
    getValues,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
      <h3 className={styles.header}>{isMobile ? "Фильтры" : "Еще фильтры"}</h3>
      {isMobile && watcher &&
        <>
          <InfoRow label="Тип" labelFont="ubuntu">
            <MultiSelect
            name="kind"
            setValue={setValue}
            options={requestKindOptions}
            register={register}
            defaultValue={watcher.kind}
            />
            </InfoRow>
            <InfoRow label="Шаблон воронки" labelFont="ubuntu">
            <MultiSelect
            name="ropeTemplate"
            options={preparedTemplates}
            register={register}
            setValue={setValue}
            />
            </InfoRow>
            <InfoRow label="Форма соглашения" labelFont="ubuntu">
            <MultiSelect
            name="agencyAgreementForm"
            setValue={setValue}
            options={requestAgencyAgreementFormOptions}
            register={register}
            defaultValue={watcher.agencyAgreementForm}
            />
            </InfoRow>
            <InfoRow label="Статус" labelFont="ubuntu">
            <MultiSelect
            name="status"
            setValue={setValue}
            options={requestStatusOptions}
            register={register}
            defaultValue={watcher.status}
            />
            </InfoRow>
            <InfoRow label="Ответственный" labelFont="ubuntu">
            <MultiLayerSelect
            name="responsibleUsers"
            options={responsibleUsers || []}
            register={register}
            setValue={setValue}
            isSearchable
            selectOne={false}
            defaultValue={watcherResponsibleUsers}
            showLastCheckbox
            />
            </InfoRow>
        </>}
      <div className={styles.content}>
        <div className={styles.leftBlock}>
          <ContentBlock>
            <MainInfo
              register={register}
              users={users}
              sourcesOptions={sourcesOptions}
              setValue={setValue}
              watch={watch}
            />
          </ContentBlock>
        </div>

      </div>
      <div className={styles.actions}>
        <div onClick={resetForm} className={styles.cancelBtn}>
          <CancelIcon/>
          <div>
            Очистить фильтры
          </div>
        </div>
        <Button type="submit">
          Показать
        </Button>
      </div>
    </form>
  );
};
