import { useEffect } from "react";
import { DRAWER_IN_DRAWER_ID } from "../../../UI";
import { Applications, ContactLoading, ContactRealEstates, ContactStickyFooter, General } from "./components";
import { useContact } from "../../../../service/api/queries";
import { RoleGuard } from "../../../../service/router/RoleGuard";
import { Contact } from "../../../../types";

import styles from "./index.module.scss";
import { CommunicationHistory } from "./components/communication-history";
import { selectDevice, useSelect } from "../../../../service/redux/selectors";
import { LayoutLoading } from "../../RealEstatePage/mode/RealEstateViewWithTabs/LayoutLoading";

type Props = {
  contactId: string;
  inDrawer?: boolean;
  refetchList?: () => void;
};

export const ContactInfo = (
  {
    contactId,
    inDrawer,
    refetchList
  }: Props): JSX.Element => {
  const {contact, isFetching, refetch, remove} = useContact(contactId);
  const {isMobile} = useSelect(selectDevice)

  useEffect(() => () => {
    remove();
  }, []);

  const blocksIds = {
    wrapper: inDrawer ? DRAWER_IN_DRAWER_ID : "drawer-right-block",
    general: "general-block-contact-view",
    requests: "requests-block-contact-view",
    contacts: "contact-block-contact-view",
    realEstates: "real-estates-block-contact-view",
  };


  if (isFetching) {
    return <ContactLoading/>;
  }

  const refetchAll = () => {
    refetch();
    if (refetchList) {
      refetchList();
    }
  };

  return (
    <RoleGuard only="estateAgencyUser">
      {contact
        ? <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <General contact={contact as Contact} sectionId={blocksIds.general} refetch={refetchAll}/>
            <CommunicationHistory contact={contact as Contact}/>
            {contact && <Applications id={blocksIds.requests} contact={contact} isMobile={isMobile}/>}
            <ContactRealEstates id={blocksIds.realEstates} contactId={contactId} isMobile={isMobile}/>
          </div>
          <ContactStickyFooter contactId={contactId}/>
        </div>
      </div>
      : <LayoutLoading/>
      }
    </RoleGuard>
  );
};
