import { ApplicationTitle } from "../../../../../../../../../types";

import { Info, Empty } from "./components";

import styles from "./index.module.scss";

type Props = {
	application?: ApplicationTitle;
	currentApplicationId: string;
};

export const Tab = ({ application, currentApplicationId }: Props): JSX.Element => {
	const { id } = application || {};
	const isActive = id === currentApplicationId;

	return (
		<div className={styles.root}>
			{application ? <Info application={application} isActive={isActive} /> : <Empty />}
		</div>
	);
};
