import { useMemo } from "react";
import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import { MultiLayerSelectOption, User } from "../../../../../../types";
import { InfoRow, MultiLayerSelect } from "../../../../index";
import { prepareResponsibleUsers } from "../../../../../../utils/transformation";

import styles from "./index.module.scss";

type Props = {
	register: UseFormRegister<any>;
	setValue: UseFormSetValue<any>;
	getValues: UseFormGetValues<any>;
	control: Control<any>;
	name: string;
	label: string;
	users: User[];
	disabled?: boolean;
};

export const Responsible = ({
	register,
	setValue,
	control,
	name,
	getValues,
	label,
	users,
	disabled,
}: Props): JSX.Element => {
	const responsibleUsers = useMemo((): MultiLayerSelectOption[] => prepareResponsibleUsers(users), [users]);

	const watcher = useWatch({
		control,
		name,
		defaultValue: getValues(name) || [],
	});

	return (
		<InfoRow aspect={{ label: 8, content: 7.3 }} label={label}>
			<MultiLayerSelect
				name={name}
				options={responsibleUsers || []}
				register={register}
				placeholder="Ответственный"
				setValue={setValue}
				isSearchable
				defaultValue={watcher}
				classNames={{ root: styles.select }}
				disabled={disabled}
			/>
		</InfoRow>
	);
};
