import { Parallax, ParallaxItem } from "../../../../../UI";
import styles from "../TitleImage/styles.module.scss";
import title_image from "../../../../../../assets/images/sdelka.png";

export const HandWithKey = (): JSX.Element => (
    <Parallax>
      <ParallaxItem coordinates={() => ["", ""]} className={styles.wrap}>
        <img alt="Hand" src={title_image}/>
      </ParallaxItem>
    </Parallax>
  );
