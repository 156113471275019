import { ReactComponent as EyeIcon } from "../../../../assets/icons/iconEyeClosed.svg";
import { ReactComponent as EyeIconOpen } from "../../../../assets/icons/iconEyeOpen.svg";
import styles from "./index.module.scss";

type Props = {
	onClick: () => void;
	type: "password" | "text";
};

export const TogglePass = ({ onClick, type }: Props): JSX.Element => (
	<button className={styles.button} type="button" onClick={onClick}>
		{type === "text" && <EyeIconOpen/>}
		{type === "password" && <EyeIcon/>}
	</button>
);
