import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LoadingWindow } from "@sdelka_crm/sdelka-crm-component-library";
import { Drawer, GallerySlider, Modal, ToastContainer } from "./components/UI";
import { initialize } from "./service/redux/modules/auth/auth.thunk";
import { Router } from "./service/router/Router";
import { selectAccounts, selectCurrentAccount, useSelect } from "./service/redux/selectors";
import FullLogo from "./assets/icons/fullMainLogo.png";
import "@sdelka_crm/sdelka-crm-component-library/dist/esm/index.css";
import "./styles/global.scss";
import styles from "./index.module.scss"
import { setIsMobileDevice } from "./service/redux/modules/device/device.action";
import { useQueryParams } from "./utils/hooks";
import { LokiLogger } from "./logger/logger";

export const App = (): React.ReactElement => {
  const APP_VERSION = "1.0.0"
  const {initialized} = useSelect(selectAccounts);
  const dispatch = useDispatch();
  const {queryParams} = useQueryParams<{ jwtFromTG: string }>([{name: "jwtFromTG"}])

  useEffect(() => {
    dispatch(initialize(queryParams.jwtFromTG));
  }, [dispatch]);

  const {currentAccount} = useSelect(selectCurrentAccount)
  const oldConsole = window.console
  const {REACT_APP_LOGS_API_PROD_URL} = process.env

  // @ts-ignore
  // window.console = new LokiLogger(
  //   REACT_APP_LOGS_API_PROD_URL,
  //   30000,
  //   oldConsole,
  //   currentAccount?.id
  // )

  let isTelegram

  // @ts-ignore
  if (Telegram.WebApp.isEmbedded) {
    console.log("The app is running inside Telegram as a Mini App.");
    isTelegram = true
  } else {
    console.log("The app is running outside of the Telegram environment.");
    isTelegram = false
  }


  useEffect(() => {
    console.log(window.location.href)
  })

  console.log(APP_VERSION)

  const details = navigator.userAgent;
  const regexp = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  const isMobileDevice = regexp.test(details)
    || queryParams.jwtFromTG !== undefined;

  if (isMobileDevice) {
    console.log("You are using a Mobile DeviceY");

  } else {
    console.log("You are using Desktop");
  }

  dispatch(setIsMobileDevice(isMobileDevice,  isTelegram))

  return (
    <>
      <ToastContainer/>
      <Modal/>
      <div className={styles.root}>
        <LoadingWindow initialized={initialized} imgSrc={FullLogo}/>
      </div>
      {initialized && <Router/>}
      <Drawer/>
      <GallerySlider/>
    </>
  );
};
