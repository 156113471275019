import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/real-estate-ad/load-ad-recomendations/DEFAULT_QN";

type UseLoadAdRecommendations = {
	applicationId: string;
	enabled?: boolean;
};

export const useLoadAdRecommendations = ({ applicationId, enabled = true }: UseLoadAdRecommendations) => {
	const adRec = () => api.realEstateAd.adRecommendations(applicationId);

	const { data, refetch, remove, isLoading, isFetched } = useQuery(DEFAULT_QN, adRec, { enabled });

	return {
		data: data?.data,
		isLoading,
		refetch,
		remove,
		isFetched,
	};
};
