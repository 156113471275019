import { RealEstateSchemaConfigField, FormPropsEditRealEstate } from "../../../../../../../../../../types";

import { Checkbox, InfoRow, Textarea } from "../../../../../../../../index";
import { RequiredDots } from "../../RequiredDots";

import styles from "./index.module.scss";

type Props = {
	form: FormPropsEditRealEstate;
	field: RealEstateSchemaConfigField;
};

export const CheckboxWithText = ({ form, field }: Props): JSX.Element => {
	const {
		label,
		dataType: { nameGroup },
	} = field;
	const { register, control, watch } = form;

	if (!nameGroup) {
		return <></>;
	}

	const checkboxName = nameGroup[0];
	const textName = nameGroup[1];

	if (!checkboxName || !textName) {
		return <></>;
	}

	const checkboxValue = watch(checkboxName);

	return (
		<>
			<InfoRow
				label={label}
				labelFont="ubuntu"
				aspect={{ label: 1, content: 1 }}
				classNames={{ root: styles.customInfoRow }}
			>
				<RequiredDots required={field.reqiredFor} />

				<Checkbox name={checkboxName} control={control} defaultValue={checkboxValue} />
			</InfoRow>

			<Textarea
				register={register}
				name={textName}
				className={styles.textarea}
				placeholder="Указать обременения"
				rows={4}
			/>
		</>
	);
};
