import { ErrorsAndWarningsType } from "../../../../../../../../../../../types";
import React from "react";
import styles from "./index.module.scss";

type MessagesBlockProps = {
	title: string;
	items: ErrorsAndWarningsType[];
};

export const StartAdMessagesBlock: React.FC<MessagesBlockProps> = ({ title, items }) => (
	<div className={styles.container}>
		<p>{title}:</p>
		<div className={styles.messagesContainer}>
			{items.map((item) => (
				<div className={styles.messageTitle} key={item.text}>
					{/* eslint-disable-next-line react/no-danger */}
					<p dangerouslySetInnerHTML={{ __html: item.text }} />
					{item.recommendation && (
						<div className={styles.recommendation}>
							<p>Что с этим делать?</p>
							{/* eslint-disable-next-line react/no-danger */}
							<p dangerouslySetInnerHTML={{ __html: item.recommendation }} />
						</div>
					)}
				</div>
			))}
		</div>
	</div>
);
