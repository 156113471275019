import * as Yup from "yup";

import { commonErrors } from "../../../../utils/errors";
import { RegistrationPayload } from "../../../../types";

export const validationFirstStepSchema: Yup.SchemaOf<RegistrationPayload> = Yup.object().shape({
	name: Yup.string()
		.required(commonErrors.required)
		.test("name", "В имени не может быть пробелов", (name) => !name?.includes(" "))
		.max(19, "Слишком длинное имя")
		.matches(/^[a-zA-Zа-яА-Я]+$/, "Только буквы"),
	lastName: Yup.string()
		.required(commonErrors.required)
		.test("lastName", "В фамилии не может быть пробелов", (lastName) => !lastName?.includes(" "))
		.max(19, "Слишком длинная фамилия")
		.matches(/^[a-zA-Zа-яА-Я]+$/, "Только буквы"),
	phone: Yup.string()
		.test("phone", commonErrors.required, (phone) => phone?.replace(/\D/gi, "").length === 11)
		.required(),
	password: Yup.string()
		.ensure()
		.min(6, "Длина пароля должна быть не меньше 6 символов")
		.test("password", "В пароле должны содержаться заглавные и строчные буквы", (password) =>
			/[A-ZА-Я].*[a-zа-я]|[a-zа-я].*[A-ZА-Я]/.test(password)
		)
		.test("password", "В пароле должны содержаться цифры", (password) => /\d/.test(password))
		.test("password", "В пароле должны содержаться спецсимволы", (password) =>
			/[!@#$%^&()\\|/"№;:?~,.]/.test(password)
		)
		.test("password", "В пароле не может быть пробелов", (password) => !password?.includes(" "))
		.max(19, "Слишком длинный пароль")
		.required(commonErrors.required),
	promoCode: Yup.string().max(19, "Слишком длинный код"),
});
