import classNames from "classnames";
import { ReactComponent as AdminPanel } from "../../../../../../../../assets/icons/admin_panel_settings.svg";
import { ReactComponent as IconError } from "../../../../../../../../assets/icons/iconWarn.svg"
import { ReactComponent as Waiting } from "../../../../../../../../assets/icons/iconClock.svg"
import { ReactComponent as Ok } from "../../../../../../../../assets/icons/ok.svg"
import { ReactComponent as Flash } from "../../../../../../../../assets/icons/flash.svg"
import { ReactComponent as IconCancel } from "../../../../../../../../assets/icons/iconClear.svg";
import { ReactComponent as IconEdit } from "../../../../../../../../assets/icons/iconChange.svg";
import { ReactComponent as IconDownload } from "../../../../../../../../assets/icons/iconDownload.svg";
import { ReactComponent as IconCopy } from "../../../../../../../../assets/icons/copy-small.svg";
import { PopupMenuItems } from "components/UI";
import styles from "./index.module.scss";
import { AgencyAgreementStatusEnum, AgencyAgrementType } from "types";
import { useDeleteAC } from "service/api/mutations/agency-agrement/useDeleteAgencyAgrement";
import { useEffect, useState } from "react";

type Props = {
  data?: AgencyAgrementType,
  formatDate: (value: Date) => void,
  errorCode?: string
  applicationId: string
  edit?: boolean
  refetch?: () => void
  isMobile?: boolean
};

export const Header = (
  {
    data,
    formatDate,
    errorCode,
    applicationId,
    edit,
    refetch,
    isMobile
  }: Props): JSX.Element => {

  const [, setActual] = useState(data)

  useEffect(() => {
    if (data) {
      setActual(data)
    }
    setActual(data)
  }, [data, edit, errorCode])

  const {mutate: deletaACMutate} = useDeleteAC(applicationId)

  const getForm = () => {
    if (!data) {
      return undefined
    }
    if (data.form === "ONLINE") {
      return "Он-лайн"
    }
    return "Офф-лайн"

  }

  const getType = () => {
    if (!data) {
      return undefined
    }
    switch (data.type) {
      case "EX":
        return "Эксклюзивный"
      case "ADVERTISING":
        return "Рекламный"
      case "FIND":
        return "Поисковый"
      case "LOYAL":
        return "Юр. сопрвождения"
      default:
        return undefined
    }
  }

  const getStatusMes = (status: AgencyAgreementStatusEnum): {
    text: string;
    color: string;
    classname: string;
    icon?: any,
    form?: string,
    type?: string,
    currentPopupMenu: PopupMenuItems[]
  } => {
    switch (status) {
      case AgencyAgreementStatusEnum.unsigned:
        return {
          text: 'Не заключено', color: "#BFC4DF", classname: styles.gray,
          currentPopupMenu: []
        }
      case AgencyAgreementStatusEnum.oral:
        return {
          text: 'Устное соглашение', color: "#BFC4DF", classname: styles.gray, icon: <IconError/>,
          currentPopupMenu: [{
            label: "Редактировать",
            icon: <IconEdit/>,
            className: styles.link,
            action: () => {

            },
          },]
        }
      case AgencyAgreementStatusEnum.waitOnline:
        return {
          text: 'Ожидает подписания', color: "#BFC4DF", classname: styles.gray, icon: <Waiting/>,
          currentPopupMenu: [{
            label: "Редактировать",
            className: styles.link,
            icon: <IconEdit/>,
            action: () => {

            },
          },
            {
              label: "Скопировать ссылку ",
              className: styles.link,
              icon: <IconCopy/>,
              action: () => {
                if (data?.agreementLink) {
                  navigator.clipboard.writeText(data.agreementLink);
                }
              },
            },
            {
              label: "Отменить соглашение ",
              className: styles.red,
              icon: <IconCancel/>,
              action: () => {
                deletaACMutate()
                if (refetch) {
                  setTimeout(() => {
                    refetch()
                  }, 1000)
                }
              },
            },
          ]
        }
      case AgencyAgreementStatusEnum.signedOnline:
      case AgencyAgreementStatusEnum.offline:
        return {
          text: 'Подписано',
          color: "#21BC5F",
          classname: styles.green,
          form: getForm(),
          type: getType(),
          icon: <Ok/>,
          currentPopupMenu: [
            {
              label: "Редактировать",
              className: styles.link,
              icon: <IconEdit/>,
              action: () => {

              },
            },
            {
              label: "Скопировать ссылку ",
              className: styles.link,
              icon: <IconCopy/>,
              action: () => {
                if (data?.agreementLink) {
                  navigator.clipboard.writeText(data.agreementLink);
                }
              },
            },
            {
              label: "Скачать соглашение ",
              className: styles.link,
              icon: <IconDownload/>,
              action: () => {
                if (data?.downloadAgreementLink) {
                  window.open(data.downloadAgreementLink)
                }
              },
            },
            {
              label: "Отменить соглашение ",
              className: styles.red,
              icon: <IconCancel/>,
              action: () => {
                deletaACMutate()
                if (refetch) {
                  setTimeout(() => {
                    refetch()
                  }, 1000)
                }
              },
            },
          ]
        }

      case AgencyAgreementStatusEnum.done:
        return {
          text: 'Выполнено', color: "#7F8DEF", classname: styles.violet, icon: <Flash/>,
          currentPopupMenu: [
            {
              label: "Скопировать ссылку ",
              className: styles.link,
              icon: <IconCopy/>,
              action: () => {
                if (data?.agreementLink) {
                  navigator.clipboard.writeText(data.agreementLink);
                }
              },
            },
            {
              label: "Скачать соглашение ",
              className: styles.link,
              icon: <IconDownload/>,
              action: () => {
                if (data?.downloadAgreementLink) {
                  window.open(data.downloadAgreementLink)
                }
              },
            },
            {
              label: "Скачать акт ",
              className: styles.link,
              icon: <IconDownload/>,
              action: () => {
                if (data?.downloadActLink) {
                  window.open(data.downloadActLink)
                }
              },
            },
          ]
        }
      default:
        return {text: 'Не заключено', color: "#BFC4DF", classname: styles.gray, currentPopupMenu: []}
    }
  }

  function useErrorCode(code: string): { text: string; color: string; classname: string } {
    switch (code) {
      case 'AP005':
        return {text: 'Не заключено', color: "#BFC4DF", classname: styles.gray}
      case 'oral':
        return {text: 'Устное', color: "#BFC4DF", classname: styles.gray}
      default:
        return {text: '', color: "", classname: ""}
    }
  }

  return (
    <div className={styles.root}>
      <h1>Агентское соглашение</h1>
      <div className={styles.wrap}>
        <div className={styles.header}>
          {!isMobile && <div className={styles.headerIcon}>
            <AdminPanel
              className={classNames(
                data?.status ? getStatusMes(data?.status).classname : "",
                errorCode ? useErrorCode(errorCode).classname : ""
              )}
            />
          </div>}
          <div className={styles.statusBlock}>
            {/* {data?.status && getStatusMes(data.status) && */}
            {/*  <div className={styles.popup}> */}
            {/*    <PopupMenu */}
            {/*      menuItems={getStatusMes(data.status).currentPopupMenu} */}
            {/*      options={{primaryOpen: "left"}} */}
            {/*      classNames={{ */}
            {/*        card: styles.popupCard */}
            {/*      }} */}
            {/*    /> */}
            {/*  </div>} */}
            <div className={styles.icon}>
              {data && getStatusMes(data.status).icon && getStatusMes(data.status).icon}
            </div>
            <div className={styles.rightBlock}>
              <div className={styles.text}>
                <p
                  className={styles.statusText}>{data ? (getStatusMes(data.status) as { text: string; color: string; }).text
                  : "Не заключено"}
                </p>
                {data?.sentSMS &&
                  <p className={styles.sentSMS}>
                    Отправлено по СМС
                  </p>}
                {data?.sentAct &&
                  <p className={styles.sentSMS}>
                    Акт отправлен
                  </p>}
                {data?.terminationRequested &&
                  <p className={styles.terminationRequested}>
                    Запрос на расторжение
                  </p>}
              </div>
              {data && (data.status === AgencyAgreementStatusEnum.signedOnline
                  || data.status === AgencyAgreementStatusEnum.offline
                  || data.status === AgencyAgreementStatusEnum.done) &&
                <div className={styles.row}>
                  <div className={styles.row}>
                    <div className={styles.description}>
                      Форма:
                    </div>
                    <div className={styles.descLabel}>
                      {data && getStatusMes(data.status).form}
                    </div>
                  </div>
                  <div className={styles.row} style={{marginLeft: "16px"}}>
                    <div className={styles.description}>
                      Тип:
                    </div>
                    <div className={styles.descLabel}>
                      {data && getStatusMes(data.status).type}
                    </div>
                  </div>
                </div>}
              {data?.status === "done" && data.validUntil && data?.validFrom ? <p className={styles.date}>
                Действовало с {data?.validFrom && formatDate(data.validFrom)} по {formatDate(data.validUntil)}
              </p> : <></>}
              {data?.validFrom && data?.status !== "done" ?
                <p className={styles.date}>
                  Действует до {data?.validUntil && formatDate(data.validUntil)}
                </p> : <></>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
