import { Control, UseFormGetValues, useWatch } from "react-hook-form";

type Props = {
	control: Control<any>;
	getValues: UseFormGetValues<any>;
};

export const useFormWatcher = ({ control, getValues }: Props) => {
	const [typeWatcher, sourceWatcher, pseudonymWatcher, phoneWatcher] = [
		useWatch({
			control,
			name: "type",
			defaultValue: getValues("type") || "customer",
		}),
		useWatch({
			control,
			name: "source",
			defaultValue: getValues("source") || "",
		}),
		useWatch({
			control,
			name: "pseudonym",
			defaultValue: getValues("pseudonym") || "",
		}),
		useWatch({
			control,
			name: "phone",
			defaultValue: getValues("phone") || "",
		})
	];

	return {
		typeWatcher,
		sourceWatcher,
		pseudonymWatcher,
		phoneWatcher,
	};
};
