import { ReactNode } from "react";
import { H2 } from "@sdelka_crm/sdelka-crm-component-library";
import { UserAccountData } from "../../../../types";

import { UserListCardsLocal } from "./components/UserListCards/UserListCardsLocal";

type Props = {
  title: string;
  data: UserAccountData[];
  children?: ReactNode;
  classNames?: string
  isMobile?: boolean
  withOutDots?: boolean
};

export const UserListLocal = (
  {
    children,
    data,
    title,
    classNames,
    isMobile,
    withOutDots
  }: Props): JSX.Element => (
    <>
      <H2 button={children}>{title}</H2>
      <UserListCardsLocal
        data={data}
        classNames={classNames}
        isMobile={isMobile}
        withOutDots={withOutDots}
      />
    </>
  );
