import { SidebarLayout } from "../../UI";
import { TasksContent, TasksTopFilters } from "./components";
import { TasksContextProvider } from "../../../service/context/Tasks";
import { selectDevice, useSelect } from "../../../service/redux/selectors";

const hat = {
	element: <TasksTopFilters />,
	elementHeight: 50,
};

export const Tasks = (): JSX.Element => {

	const {isMobile} = useSelect(selectDevice)

	return (
			<TasksContextProvider>
				<SidebarLayout
					fullSize
					withScrollBtn
					hat={isMobile ? {element: <></>, elementHeight: 0} : hat}
					isMobile={isMobile}
				>
					<TasksContent isMobile={isMobile}/>
				</SidebarLayout>
			</TasksContextProvider>
		)
};
