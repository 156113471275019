import { useLoadApplicationCard } from "../../../../../service/api/queries";
import { ApplicationCardResponse, KindType } from "../../../../../types";

type Props = {
  applicationId: string;
  inDrawer?: boolean;
  inDrawerInDrawer?: boolean;
  onFirstLoad?: () => void;
  kind?: KindType
  isFromObjectList?: boolean
  isFromAdList?: boolean
};

type Return = {
  application?: ApplicationCardResponse;
  isLoadingApplicationCard: boolean;
  refetch: () => void;
};

type UseLoadApplication = (props: Props) => Return;

export const useLoadApplication: UseLoadApplication = (
  {
    applicationId,
    kind,
  }) => {

  const {
    data: application,
    isLoading: isLoadingApplicationCard,
    refetch,
  } = useLoadApplicationCard({applicationId, kind}) as unknown as {
    data: ApplicationCardResponse;
    isLoading: boolean;
    refetch: () => void;
  };

  return {
    application,
    isLoadingApplicationCard,
    refetch,
  };

};