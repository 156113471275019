import { useMutation } from "react-query";

import { api } from "../../index";

type UseDeleteUnactual = {
	onSuccess?: () => void;
};

export const useDeleteUnactualCompareObject = ({ onSuccess }: UseDeleteUnactual) => {
	const mutate = useMutation("addCompilation", {
		mutationFn: (comparedApplicationId: string) => api.objectCompare.deleteUnactual(comparedApplicationId),
		onSuccess,
	});

	return {
		mutate: mutate.mutate,
		isLoading: mutate.isLoading,
	};
};
