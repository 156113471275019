import { memo } from "react";
import styles from "../Owner/index.module.scss"
import cn from "classnames";
import { ContactPreview } from "../../../../../../types";
import { ReactComponent as ContactIcon } from "../../../../../../assets/icons/iconContact.svg";

type Props = {
  contact?: ContactPreview
  classNames?: string
}

export const CardObjectContact = memo(({contact, classNames}: Props): JSX.Element => (
  <div className={cn(styles.contact, classNames)}>
    <ContactIcon/>
    <span className={styles.contactText}>
       {`${contact?.pseudonym ?? ""} ${contact?.name || ""} ${contact?.secondName || ""} ${contact?.lastName || ""}`}
    </span>
  </div>
))