import { UserPreview } from "../../../../../types";
import { UserBlock } from "./renderUserBlock";
import { selectCurrentAccount, useSelect } from "../../../../../service/redux/selectors";

type Props = {
  creator?: UserPreview
  creatorId?: string
}

export 	const RenderCreator = ({creator, creatorId}: Props): JSX.Element => {
  const {
    name,
    lastName,
    secondName
  } = creator || {name: "", secondName: "", lastName: ""}

  const {currentAccount} = useSelect(selectCurrentAccount)
  const isCoWorking = currentAccount && currentAccount.position?.name === "Агент в коворкинге"

  if (creator) {
    const href = `employees?userId=${creatorId}`;

    return UserBlock({name, lastName, secondName,  href, isCoWorking});
  }

  return <div>Система</div>;
};