import { UseFormRegister, UseFormSetValue, Control, useWatch, UseFormGetValues } from "react-hook-form";
import { RealEstateFilterField } from "../../../../../../types";
import cn from "classnames";

import { InfoRow, MultiCheckbox, MultiSelect } from "../../../../index";

import styles from "./index.module.scss";

type Props = {
	field: RealEstateFilterField;
	name: string;
	options: { label: string; value: string }[];
	setValue: UseFormSetValue<any>;
	getValues: UseFormGetValues<any>;
	register: UseFormRegister<any>;
	control: Control<any>;
	disabled?: boolean;
};

// All possible selects variants from backend.
export const SelectTemplate = ({
	field,
	register,
	setValue,
	name,
	control,
	options,
	getValues,
	disabled,
}: Props): JSX.Element => {
	const watcher = useWatch({
		control,
		name,
		defaultValue: getValues(name) || field?.dataType?.defaultValue || [],
	});

	const optionWithDisable = options.map(({ label, value }) => ({
		label,
		value,
		disabled,
	}));

	if (field.dataType.template === "roundChekbox") {
		return (
			<InfoRow aspect={{ label: 8, content: 7.3 }} label={field.label}>
				<MultiCheckbox
					name={name}
					register={register}
					setValue={setValue}
					variant="radioButtonWhite"
					options={optionWithDisable}
					margin="small"
					defaultValue={watcher || []}
				/>
			</InfoRow>
		);
	}

	if (field.dataType.template === "checkbox") {
		const direction = optionWithDisable.find((i) => i.label.length > 20) ? "column" : "grid";

		return (
			<InfoRow aspect={{ label: 8, content: 7.3 }} variant="column" label={field.label}>
				<MultiCheckbox
					name={name}
					register={register}
					setValue={setValue}
					direction={direction}
					options={optionWithDisable}
					className={cn({ [styles.columnCheckboxes]: direction === "column" })}
					defaultValue={watcher || []}
				/>
			</InfoRow>
		);
	}

	return (
		<InfoRow aspect={{ label: 8, content: 7.3 }} label={field.label}>
			<MultiSelect
				options={optionWithDisable}
				placeholder="Выбрать"
				name={name}
				register={register}
				setValue={setValue}
				defaultValue={watcher || []}
				disabled={disabled}
			/>
		</InfoRow>
	);
};
