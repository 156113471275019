import styles from "./index.module.scss"
import { ReactComponent as Icon } from "../../../../../../../../../../../../../../assets/icons/iconWarnYellowBig.svg";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import {
  useAddInCartModal
} from "../../../../../../../../../../../../Servises/cart/helpers/addInCartModal/useAddInCartModal";
import { CartResponse, ProductTypeEnum, SelectOption } from "../../../../../../../../../../../../../../types";
import { useBuyInOneClick } from "../../../../../../../../../../../../../../service/api/mutations";
import { AxiosResponse } from "axios";
import classNames from "classnames";

type Props = {
  applicationId: string
  serviceId: string
  tariffLabel?: string
  tariffOptions?: SelectOption[]
  isMobile?: boolean
}

export const ConfirmPaymentModal = (
  {
    applicationId,
    serviceId,
    tariffLabel,
    tariffOptions,
    isMobile
  }: Props) => {

  const {mutate: buyInOneClick} = useBuyInOneClick({
    onSuccess: (data: AxiosResponse<CartResponse>) => {
      document.location.href = data?.data.paymentUrl ?? ""
    }
  })

  const {openAddInCartModal} = useAddInCartModal({
    type: ProductTypeEnum.service,
    title: "Выберите тариф",
    action: buyInOneClick,
    tariffLabel,
    tariffOptions,
    applicationId,
    isMobile
  })

  return (
    <div>
      <div className={styles.row}>
        <Icon/>
        <div>
          <div className={styles.title}>
            Заказ создан и ожидает оплаты
          </div>
          <div className={styles.description}>
            Исполнитель будет назначен после оплаты заказа
          </div>
        </div>
      </div>
      <div className={classNames({[styles.row]: !isMobile}, {[styles.col]: isMobile})} style={{marginTop: "32px"}}>
        <Button variant="outlined" onClick={() => openAddInCartModal([serviceId ?? ""], false)}>
          Добавить в корзину и продолжить
        </Button>
        <Button onClick={() => openAddInCartModal([serviceId ?? ""], true)}>
          Оплатить в один клик
        </Button>
      </div>
    </div>
  )
}