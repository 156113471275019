import { ReactNode } from "react";
import { CollapseDown, Loader } from "@sdelka_crm/sdelka-crm-component-library";

import { FeedPaidData, PlatformPaidServicesData } from "../../../../../../../../../../types";
import { TableSimple } from "../../../../../../../../../UI";
import { usePaidServices, usePaidServicesTableData } from "../helpers";
import styles from "./index.module.scss";

type Props = {
	data: PlatformPaidServicesData;
};

export const PaidServices = ({ data }: Props): JSX.Element => {
	const { paidServices, isLoading, fetchPaidServices, onPaidServiceClick } =
		usePaidServices<FeedPaidData>(data);

	const tableData = usePaidServicesTableData(paidServices || [], onPaidServiceClick);

	// if (isLoading) return <></>;

	return (
		<CollapseDown
			onClick={() => {
				// Запросы кидаются и при закрытии дропдауна, но зато без подлагивания компонента
				fetchPaidServices(true);
			}}
			label="Подключение платных услуг"
			classNames={{ root: styles.root }}
		>
			{isLoading ? (
				<div style={{ padding: "24px" }}>
					<Loader />
				</div>
			) : (
				<>
					{paidServices && tableData.length > 0
						?  		<TableSimple<{
							tariff: ReactNode;
							status: ReactNode;
							action: ReactNode;
						}>
							thead={["Тариф", "Стоимость", "Состояние", "Действие с услугой"]}
							data={tableData}
							cols={[
								{ render: (row) => row.tariff },
								{ render: (row) => row.price },
								{ render: (row) => row.status },
								{ render: (row) => row.action },
							]}
						/>
						: <div className={styles.noPaidServices}>
							Нет платных услуг
						</div>
					}

				</>
			)}
		</CollapseDown>
	);
};
