import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { add } from "date-fns";

import { toastError } from "../../../../../../UI";
import { setShowRequiredAdFields } from "../../../../../../../service/redux/modules/object-edit/object-edit.action";
import { useStartAd, useStopAd } from "../../../../../../../service/api/mutations";
import { CianTariff, PaymentMethods } from "../../../../../../../types";
import { useRestartAd } from "../../../../../../../service/api/mutations/real-estate-ad/useRestartAd";
import { useChangeTariff } from "../../../../../../../service/api/mutations/real-estate-ad/useChangeTariff";
import {
  useApplyServiceModal
} from "../../../../../Application/ApplicationCard/components/ApplicationInfo/component/Info/components/Body/components/BodyInfo/components";
import { selectDevice, useSelect } from "../../../../../../../service/redux/selectors";

type Props = {
  applicationId: string;
  feedId?: string;
  refetch: () => void;
};

const startAdFailureMessage = "Ошибка размещения рекламы";

export const useStartAdActions = ({applicationId, feedId, refetch}: Props) => {
  const {push, location} = useHistory();
  const dispatch = useDispatch();
  const {isMobile} = useSelect(selectDevice)

  const [currentlyUpdating, setCurrentlyUpdating] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [tariffJustChangedArray, setTariffJustChangedArray] = useState<string[]>([]);
  // const [innerFeedId, setFeedId] = useState(feedId);
  const innerFeedId = useRef(feedId);

  const {openApplyServiceModal} = useApplyServiceModal({applicationId, isMobile, adPlatformAccountId: feedId})

  const fillFields = (format: string) => {
    const newSearch = location.search.replace("mode=ad", "mode=edit");

    dispatch(
      setShowRequiredAdFields({
        [ format ]: true,
      })
    );

    push(location.pathname + newSearch);
  };

  /* const testForErrors = (data) => {
    setIsLoading(false);
    if (data.type === "limitOver") {
      toastError({
        Icon: Alert,
        text: "Вы исчерпали лимит на размещение объектов",
      });
    } else if (data.type === "feedNotFound") {
      toastError({
        Icon: Alert,
        text: "Реклама не была найдена или отсутствуют полномочия на размещение объектов",
      });
    }
  }; */

  const {mutate: mutateStartAd} = useStartAd({
    onSuccess: async ({data}) => {
      if (innerFeedId.current) setCurrentlyUpdating(innerFeedId.current);
      else setCurrentlyUpdating(data.result.feedId);
      await refetch();
      setCurrentlyUpdating("");

      if (!data.success) toastError({text: startAdFailureMessage});
      // testForErrors(data);
    },
    onError: async (error) => {
      if (error.response?.data.error.errorCode === "AD052") {
        openApplyServiceModal()
      } else {
        toastError({text: startAdFailureMessage});
        if (innerFeedId.current) setCurrentlyUpdating(innerFeedId.current);
        await refetch();
        setCurrentlyUpdating("");
      }
    },
  });

  const {mutate: mutateStopAd} = useStopAd({
    onSuccess: async ({data}) => {
      if (innerFeedId.current) setCurrentlyUpdating(innerFeedId.current);
      else setCurrentlyUpdating(data.feedId);
      await refetch();
      setCurrentlyUpdating("");
      if (!(data as any)?.success) toastError({text: startAdFailureMessage});
    },
    onError: async () => {
      toastError({text: "Ошибка остановки рекламы"});
      if (innerFeedId.current) setCurrentlyUpdating(innerFeedId.current);
      await refetch();
      setCurrentlyUpdating("");
    },
  });

  const {mutate: mutateRestartAd} = useRestartAd({
    onSuccess: ({data}) => {
      if (innerFeedId.current) setCurrentlyUpdating(innerFeedId.current);
      else setCurrentlyUpdating(data.feedId);
      refetch();
    },
    onError: async (error) => {
      if (error.response?.data.error.errorCode === "AD052") {
        openApplyServiceModal()
      } else {
        if (innerFeedId.current) setCurrentlyUpdating(innerFeedId.current);
        refetch();
        toastError({text: startAdFailureMessage});
      }
    },
  });

  // { months: getValues().startAdDuration }
  const startAd = (
    providedFeedId: string,
    duration: Duration,
    payMethod: number,
    selectedTariffOption?: string
  ) => {
    const startDate = new Date();
    innerFeedId.current = providedFeedId;
    setIsLoading(true);
    mutateStartAd({
      applicationId,
      feedId: providedFeedId,
      startDate,
      duration: duration.months as number,
      endDate: add(startDate, duration),
      // FIXME: Types error
      payMethod: Object.values(PaymentMethods)[ payMethod - 1 ] as any,
      tariff: selectedTariffOption as CianTariff,
    });
  };
  const stopAd = (providedFeedId: string): void => {
    innerFeedId.current = providedFeedId;
    setIsLoading(true);
    mutateStopAd({applicationId, feedId: providedFeedId});
  };

  const restartAd = (providedFeedId: string): void => {
    innerFeedId.current = providedFeedId;
    setIsLoading(true);
    mutateRestartAd({applicationId, feedId: providedFeedId});
  };

  const {mutate: mutateTariff} = useChangeTariff({
    onSuccess: () => {
      if (innerFeedId.current) {
        setCurrentlyUpdating(innerFeedId.current);
        setTariffJustChangedArray((prev) => [...prev, innerFeedId.current as string]);
      }
      refetch();
    },
    onError: () => {
      if (innerFeedId.current) setCurrentlyUpdating(innerFeedId.current);
      refetch();
      toastError({text: "Ошибка изменения тарифа"});
    },
  });

  const changeTariff = (
    {
      applicationId: innerApplicationId,
      feedId: paramFeedId,
      newTariffValue,
    }: any): void => {
    innerFeedId.current = paramFeedId;
    setIsLoading(true);
    mutateTariff({applicationId: innerApplicationId, feedId: paramFeedId, newTariffValue});
  };

  return {
    currentlyUpdating,
    setCurrentlyUpdating,
    loading,
    setIsLoading,
    fillFields,
    startAd,
    stopAd,
    restartAd,
    changeTariff,
    tariffJustChangedArray,
  };
};
