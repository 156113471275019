import { SET_BUSINESS_PROCESSES, CLEAN_UP, BusinessProcessAction } from "./business-process.action";
import { BusinessProcessTInitialState } from "../../types";

const initialState: BusinessProcessTInitialState = {
	businessProcesses: [],
};

export const businessProcessReducer = (
	state = initialState,
	action: BusinessProcessAction
): BusinessProcessTInitialState => {
	switch (action.type) {
		case SET_BUSINESS_PROCESSES: {
			return {
				...state,
				businessProcesses: action.businessProcesses,
			};
		}

		case CLEAN_UP: {
			return {
				...initialState,
			};
		}

		default:
			return state;
	}
};
