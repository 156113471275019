import { toastSuccess } from "../../../../../../../../UI";
import { useContactUpdate } from "../../../../../../../../../service/api/mutations";
import { ContactUpdate, UpdateContactPayload, Contact, LocationType } from "../../../../../../../../../types";

type Props = {
	contactId: string;
	refetch: () => void;
};

export const useSubmitForm = ({ refetch, contactId }: Props) => {
	const onSuccess = (): void => {
		refetch();
		toastSuccess({
			text: "Контакт обновлен",
		});
	};

	const { mutate: mutateContact, isLoading } = useContactUpdate({ onSuccess });

	const onSubmit = (form: Contact & { mainPhone: string }) => {
		const dob = form?.dob ? new Date(form.dob).toISOString() : "";
		const {
			name,
			secondName,
			lastName,
			email,
			type,
			otherType,
			pseudonym,
			addressObject: { location, value, label },
		} = form as unknown as UpdateContactPayload;

		let payload: ContactUpdate = {
			name,
			secondName,
			lastName,
			phones: [{ phone: form.mainPhone, type: "main" }],
			email,
			type,
			otherType,
			dob,
			pseudonym,
			passportInfo: {
				series: (form as any).series,
				passportNumber: (form as any).passportNumber,
				dateOfIssue: (form as any).dateOfIssue,
				issuedBy: (form as any).issuedBy,
				issuedByCode: (form as any).issuedByCode,
				registerAddress: (form as any).registerAddress
			}
		};
		if (location && Object.keys(location).length > 0) {
			payload = {
				...payload,
				...{
					location: {
						...(location as LocationType),
						type: "point",
					},
					addressId: value,
					addressLabel: label,
				},
			};
		}

		mutateContact({ id: contactId, payload });
	};

	return {
		onSubmit,
		isLoading,
	};
};
