import { useDispatch } from "react-redux";
import { CancelShowWiewModal } from "./CancelShowViewModal";
import { openModal } from "../../../../../../../../service/redux/modules/modal/modal.action";
import styles from "../../change-date-modal/index.module.scss";
import { selectDevice, useSelect } from "../../../../../../../../service/redux/selectors";


type Return = {
  openCancelShowViewModal: () => void
}

// eslint-disable-next-line @typescript-eslint/naming-convention
type useCancelShowViewModal = (isViews: boolean, id: string, onSuccess: () => void) => Return

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const useCancelShowViewModal: useCancelShowViewModal = (isViews, id, onSuccess) => {
  const dispatch = useDispatch()
  const {isMobile} = useSelect(selectDevice)
  const width = isMobile ? 500 : 660

  const openCancelShowViewModal = () => {
    dispatch(
      openModal(
        {
          width,
          contentClass: styles.root,
          classNames:{
            contentBody: styles.root,
            contentWrapper: isMobile ? styles.modal : "",
            buttonClose: isMobile ? styles.modalClose : ""
          },
          body: <CancelShowWiewModal
            isViews={isViews}
            showViewId={id}
            onSuccess={onSuccess}
          />
        }
      ))
  }

  return {
    openCancelShowViewModal
  }
}

