import { ApplicationCardResponse } from "../../../../types";
import styles from "./index.module.scss"
import { formatDateWithTime } from "../../../../utils/date";
import { useIsTelegram } from "../../../../utils/telegram/useIsTelegram";

type Props = {
  application: ApplicationCardResponse
}
export const DealFooter = ({application}: Props) => {

  const {executeUrl} = useIsTelegram()

  const openApplication = (id: string) => {
    executeUrl(`/leadsanddeals?tab=applications&applicationId=${id}`)
  }

  return (
    <div className={styles.root}>
      <div className={styles.col}>
        <div className={styles.title}>
          ID сделки:
        </div>
        <div className={styles.text}>
          {application.shortId}
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.title}>
          ID заявки продавца:
        </div>
        <div className={styles.link} onClick={() => openApplication(application.saleApplicationId ?? "")}>
          {application.saleApplication?.shortId}
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.title}>
          ID заявки покупателя:
        </div>
        <div className={styles.link} onClick={() => openApplication(application.buyApplicationId ?? "")}>
          {application.buyApplication?.shortId}
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.title}>
          Дата создания сделки:
        </div>
        <div className={styles.text}>
          {formatDateWithTime(new Date(application.createdAt ?? ""), false)}
        </div>
      </div>
    </div>
  )
}