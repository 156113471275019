import { Client } from "../../Client";

import {
	Invite,
	InvitePublicLink,
	InviteSend,
	InviteUpdatePublicLink,
	InviteUserInfo,
} from "../../../../types";

export const InvitesService = {
	companyInvites: () => Client.get<Invite[]>("/company/invites"),
	userInvites: () => Client.get<InviteUserInfo[]>("/authentication/invites"),
	getAuthenticationLink: (link: string) => Client.get<Invite>(`/authentication/invites/${link}`),
	acceptInvite: (id: string) => Client.post(`/authentication/invites/${id}`),
	deleteAuthentication: (id: string) => Client.delete(`/authentication/invites/${id}`),
	update: (id: string, data: Partial<Invite>) => Client.put(`/company/invites/${id}`, data),
	getPublicLink: () => Client.get<InvitePublicLink>("/company/invites/getPublicLinkData"),
	createPersonalLink: (data: InviteSend) => Client.post<Invite>("/company/invites", data),
	generatePublicLink: () => Client.post("/company/invites/generateNewPublicLink"),
	updatePublicLink: (data: InviteUpdatePublicLink) =>
		Client.put<boolean>("/company/invites/setPublicLinkData", data),
};
