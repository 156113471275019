import styles from "./index.module.scss"
import { ReactComponent as Warning } from "../../../../../../../assets/icons/iconWarnYellow.svg";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useOpenConfirmationCodeModal } from "../confirmation-code-modal";
import { selectDrawer, useSelect } from "../../../../../../../service/redux/selectors";
import { useEffect } from "react";

export const PublicOffer = () => {
  const {openConfirmationCodeModal} = useOpenConfirmationCodeModal()
  const {scrolled} = useSelect(selectDrawer);

  useEffect(() => {

  }, [scrolled])

  return (
    <div className={styles.root} id="publicOfferRoot">
      <div className={styles.content} id="publicOfferContent">
        <div className={styles.title}>Публичная оферта</div>
        <div className={styles.text}>
          <p className={styles.subtitle}>
            Пользовательское Соглашение
          </p>
          <br/>
          Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между ООО "Сделка" (далее
          СДЕЛКА или Администрация) с одной стороны и пользователем сайта с другой.
          <br/> Сайт СДЕЛКА не является средством массовой информации.
          <br/>Используя сайт, Вы соглашаетесь с условиями данного соглашения.
          <br/>
          <br/>
          <p className={styles.subtitle}>
            Предмет соглашения
          </p>
          <br/>
          <b>
            Администрация предоставляет пользователю право на размещение на сайте следующей информации:
          </b>
          <br/>- Кадастровая информация об объектах недвижимости
          <br/>- Текстовое описание объекта недвижимости
          <br/>- Фотоматериалов
          <br/>- Ссылок на материалы, размещенные на других сайтах
          <br/>- Персональные данные собственников недвижимости и клиентов пользователя
          <br/>
          <br/>
          <p className={styles.subtitle}>Права и обязанности сторон</p>
          <br/>
          <b>
            Пользователь имеет право:
          </b>
          <br/>- осуществлять поиск информации на сайте
          <br/>- получать информацию на сайте
          <br/>- создавать информацию для сайта
          <br/>- распространять информацию на сайте
          <br/>- копировать информацию на другие сайты с указанием источника
          <br/>- требовать от администрации скрытия любой информации о пользователе
          <br/>- требовать от администрации скрытия любой информации переданной пользователем сайту
          <br/>- использовать информацию сайта в коммерческих целях без специального разрешения
          <br/>
          <br/>
          <b>
            Администрация имеет право:
          </b>
          <br/>- по своему усмотрению и необходимости создавать, изменять, отменять правила
          <br/>- ограничивать доступ к любой информации на сайте
          <br/>- создавать, изменять, удалять информацию
          <br/>
          <br/>
          <b>
            Пользователь обязуется:
          </b>
          <br/> - не нарушать работоспособность сайта
          <br/>- не использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с
          Сайтом и его Сервисами
          <br/>
          <br/>
          <b>
            Администрация обязуется:
          </b>
          <br/>- поддерживать работоспособность сайта за исключением случаев, когда это невозможно по независящим от
          Администрации причинам.
          <br/>- осуществлять разностороннюю защиту учетной записи Пользователя
          <br/>
          <br/>
          <p className={styles.subtitle}>
            Ответственность сторон
          </p>
          <br/>- пользователь лично несет полную ответственность за распространяемую им информацию
          <br/>- администрация не несет никакой ответственности за достоверность информации, скопированной из других
          источников
          <br/>- администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами
          <br/> - в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение, стихийное
          бедствие и т. д.) Администрация не гарантирует сохранность информации, размещенной Пользователем, а также
          бесперебойную работу информационного ресурса
          <br/>
          <br/>
          <p className={styles.subtitle}>
            Условия действия Соглашения
          </p>
          <br/> Данное Соглашение вступает в силу при регистрации на сайте.
          <br/> Соглашение перестает действовать при появлении его новой версии.
          <br/> Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по своему
          усмотрению.
          <br/> При любом изменении данного соглашения, администрация будет оповещать пользователей удобным для нее
          способом.
        </div>
      </div>
      <div id="footer"/>
      <div className={styles.footer}>
        <div className={styles.row}>
          <Warning/>
          <div className={styles.warningText}>
            Дочитайте до конца
          </div>
        </div>
          <Button
            onClick={openConfirmationCodeModal}
            disabled={!scrolled}
          >
            Согласен
          </Button>
      </div>
    </div>
  )
}