import { Client } from "../../Client";
import {
	CianData,
	FeedPaidResponse,
	FeedSettings,
	GetFeedSeparatedPayload,
	PaidServicesType,
	PercentCardList,
	Platform,
	PlatformAdDescription,
	PlatformData,
	PlatformDescriptionIdData,
	PlatformsTableAll,
	RealEstateUpdateAutoRenewalPayload,
	RealEstateUpdateAutoRenewalResponse,
	SendObjectToFeedResponse,
	Statistics,
	DateBorders,
	PayMethod,
	PlatformDetailsData,
	DetalizationStatsPayload,
	ComplaintAutoRejectPayload,
	ChangeTariffPayload,
	StopAdPayload,
	CianTariff,
} from "../../../../types";

export const RealEstateAdService = {
	statistics: (applicationId: string) =>
		Client.get<Statistics>(`/advertising/${applicationId}/details`, {
			headers: { "X-API-VERSION": 2 },
		}),

	updateFeedId: (applicationId: string, feedId: string) =>
		Client.post<PlatformDescriptionIdData>("/advertesting/separatedData/setFeedNewId", {
			applicationId,
			feedId,
		}),

	platforms: (applicationId: string, params?: string) =>
		Client.get<Platform>(`advertising/${applicationId}/platforms?${params || ""}`, {
			headers: { "X-API-VERSION": 2 },
		}),

	platform: (applicationId: string, feedId: string) =>
		Client.get<PlatformData>(`advertising/${applicationId}/platform/${feedId}`),

	platformDetails: (applicationId: string, feedId: string) =>
		Client.get<PlatformDetailsData>(`advertising/${applicationId}/platform/${feedId}/details`, {
			headers: { "X-API-VERSION": 2 },
		}),

	allPlatformDetails: (applicationId: string) =>
		Client.get<PlatformDetailsData>(`advertising/${applicationId}/details`, {
			headers: { "X-API-VERSION": 2 },
		}),

	platformPaidServices: (applicationId: string, feedId: string) =>
		Client.get<PaidServicesType[]>(`advertising/${applicationId}/platform/${feedId}/paidServices`, {
			headers: { "X-API-VERSION": 2 },
		}),

	platformDescription: ({ applicationId, feedId }: GetFeedSeparatedPayload) =>
		Client.post<PlatformAdDescription>("/advertesting/separatedData/getFeedSeparatedData", {
			applicationId,
			feedId,
		}),

	startOrRemoveAllAds: ({ applicationId, tableName, action }: PlatformsTableAll) =>
		Client.post<{ feedId?: string; success: boolean }[]>("/advertising/sendObjectToFeedOrStopMultiple", {
			applicationId,
			tableName,
			action,
		}),

	startAd: (data: PlatformDescriptionIdData & DateBorders & PayMethod & { tariff?: CianTariff }) => {
		let defaultPayloadData: any = {
			period: `MONTH_${data.duration}`,
			payMethod: data.payMethod,
		};

		if (data.tariff) defaultPayloadData = { ...defaultPayloadData, tariff: data.tariff };

		return Client.patch<SendObjectToFeedResponse>(
			`/advertising/${data.applicationId}/platform/${data.feedId}/start`,
			defaultPayloadData,
			{ headers: { "X-API-VERSION": 2 } }
		);
	},

	stopAd: ({ applicationId, feedId }: StopAdPayload) =>
		Client.patch(
			`/advertising/${applicationId}/platform/${feedId}/stop`,
			{},
			{
				headers: { "X-API-VERSION": 2 },
			}
		),

	updatePlatformDescription: (data: PlatformDescriptionIdData & PlatformAdDescription) =>
		Client.post<string>("/advertesting/separatedData/setFeedSeparatedData", {
			...data,
		}),

	updatePaidService: (
		data: Record<string, unknown> & { applicationId: string; adPlatformAccountId: string }
	) => Client.post<FeedPaidResponse>("/advertesting/feed-paid", { ...data }),

	updateCianData: (data: CianData) => Client.post("advertesting/updateCianData", { ...data }),

	adRecommendations: (applicationId: string) =>
		Client.get<PercentCardList>(`application/${applicationId}/blockData/adsRecommendations`),

	getFeedList: () => Client.get<FeedSettings[]>("/advertising/feed-settings/getFeedList"),

	updateAutoRenewal: (data: RealEstateUpdateAutoRenewalPayload) =>
		Client.post<RealEstateUpdateAutoRenewalResponse>("/advertesting/updateAutoRenewal", { ...data }),

	updateComplaintAutoReject: ({ applicationId, feedId, isEnabled }: ComplaintAutoRejectPayload) =>
		Client.put(
			`/advertising/${applicationId}/platform/${feedId}/complaintAutoReject`,
			{ isEnabled },
			{ headers: { "X-API-VERSION": 2 } }
		),

	changeTariff: ({ applicationId, feedId, newTariffValue }: ChangeTariffPayload) =>
		Client.put(
			`/advertising/${applicationId}/platform/${feedId}/tariff`,
			{ tariff: newTariffValue },
			{ headers: { "X-API-VERSION": 2 } }
		),

	restartAd: ({ applicationId, feedId }: StopAdPayload) =>
		Client.patch(
			`/advertising/${applicationId}/platform/${feedId}/restart`,
			{},
			{
				headers: { "X-API-VERSION": 2 },
			}
		),

	stats: ({ applicationId, feedId, groupBy, kind, periodDates }: DetalizationStatsPayload) =>
		Client.get(
			`/advertising/${applicationId}/platform/${feedId}/stats?&groupBy=${groupBy}&kind=${kind}&dateFrom=${periodDates.startDate}&dateTo=${periodDates.endDate}`,
			{ headers: { "X-API-VERSION": 2 } }
		),
};
