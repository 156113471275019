import DOMPurify from "dompurify";
import { H3 } from "@sdelka_crm/sdelka-crm-component-library";

import styles from "./index.module.scss";

type Props = {
	adDescription: string;
};

export const AdDescription = ({ adDescription }: Props): JSX.Element => (
	<div style={{marginTop: "60px"}}>
		<H3>Описание объявления</H3>

		<div className={styles.description}>
			<div
				dangerouslySetInnerHTML={{
					__html: DOMPurify.sanitize(adDescription || "Описание отсутствует"),
				}}
			/>
		</div>
	</div>
);
