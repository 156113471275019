import { FeedPaidResponse, SuccessResponse } from "../../../../types";
import { AxiosResponse } from "axios";
import { UseMutateFunction, useMutation } from "react-query";

import { api } from "../../index";
import { toastError } from "../../../../components/UI";

const DEFAULT_MN = "sdelka/real-estate-ad/update-paid-service/DEFAULT_MN";

type Props = {
	onSuccess?: () => void | Promise<unknown>;
};

type Return = {
	mutate: UseMutateFunction<
		AxiosResponse<FeedPaidResponse | SuccessResponse>,
		unknown,
		Record<string, unknown>,
		unknown
	>;
	isLoading: boolean;
};

type UseUpdatePaidService = (props: Props) => Return;

enum ToastErrorTexts {
	"default" = "Невозможно применить данную платную услугу",
	"delete" = "Не удалось остановить платную услугу",
}

// @ts-ignore
export const useUpdatePaidService: UseUpdatePaidService = ({ onSuccess }) => {
	let mutationFnData: Record<string, unknown> & { applicationId: string; adPlatformAccountId: string };
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (data: Record<string, unknown> & { applicationId: string; adPlatformAccountId: string }) => {
			mutationFnData = data;
			return api.realEstateAd.updatePaidService(data);
		},
		onSuccess: (data) => {
			if (!(data.data as any)?.success)
				toastError({
					text: mutationFnData.status === "delete" ? ToastErrorTexts.delete : ToastErrorTexts.default,
				});
			else if (onSuccess) onSuccess();
		},
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
