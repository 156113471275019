import { LeadInList, LeadStatusEnum } from "../../../../types";
import { memo } from "react";
import { getSourceDescription, getStatusDescription } from "../../../pages/LeadCard/get-description";
import cn from "classnames";
import styles from "./index.module.scss"
import { ReactComponent as IconContact } from "../../../../assets/icons/iconContact.svg";
import { LeadStrap } from "./lead-strap/LeadStrap";
import { Avatar } from "../../Avatar";

type Props = {
  lead: LeadInList
  mini?: boolean
  background?: "white" | "gray"
  className?: string
}

export const CardLead = memo(({lead, mini, background, className}: Props): JSX.Element => {
  const {
    contact,
    iAmResponsibleUser,
    status,
    source,
    adSource,
    relatedKinds,
    responsibleUser
  } = lead

  const {color, label} = getStatusDescription(status)
  const isFree = status === LeadStatusEnum.free

  return (
    <div className={cn(styles.root, className,
      {[ styles.inTable ]: background === "white"},
      {[ styles.rootMini ]: mini}
    )}>
      <div className={styles.content}>
        <div>
          <div className={styles.row}>
            <div className={styles.title}>
              {getSourceDescription(source)}
            </div>
            <div style={{color}} className={styles.status}>
              {label}
            </div>
          </div>
          <div className={cn(styles.row, styles.adSource)}>
            {adSource ?? ""}
          </div>
        </div>
        <div className={cn(styles.row, {[ styles.rowEnd ]: !contact})}>
          {contact &&
            <div className={cn(styles.contact, {[ styles.contactMini ]: mini})}>
              <IconContact/>
              {`${contact?.pseudonym ?? ""} ${contact?.lastName || ""} ${contact?.name || ""} ${contact?.secondName || ""}`}
            </div>}
          {!isFree &&
            <div className={cn(styles.responsible)}>
              {iAmResponsibleUser
                ?
                <>
                  <Avatar
                    imgSrc={responsibleUser?.account.avatar}
                    name={responsibleUser?.account.name}
                    lastName={responsibleUser?.account?.lastName}
                    color="primary"
                    mini
                  />
                  <div className={styles.contactText}>
                    {` ${responsibleUser?.account?.lastName || ""} ${responsibleUser?.account?.name || ""} ${responsibleUser?.account?.secondName || ""}`}
                  </div>

                </>
                : <div className={styles.responsible}>
                  <Avatar
                    imgSrc={responsibleUser?.account.avatar}
                    name={responsibleUser?.account.name}
                    lastName={responsibleUser?.account?.lastName}
                    color="primary"
                    mini
                  />
                  <div className={styles.contactText}>
                    {`${responsibleUser?.account.lastName ?? ""} ${responsibleUser?.account.name ?? ""}`}
                  </div>
                </div>
              }
            </div>
          }
        </div>
      </div>
      {relatedKinds && <LeadStrap relatedKinds={relatedKinds} mini={mini}/>}
    </div>
  )
})