export type TaskCountInfoVariant = "hot" | "missed" | "success" | "falied" | "total";

export const TaskCountInfoVariantLabel: Record<TaskCountInfoVariant, string> = Object.freeze({
	hot: "Горячие",
	missed: "Просроченные",
	success: "Успешные",
	falied: "Неуспешные",
	total: "Все",
});

export type TaskCountInfo = {
	communications: number,
	defaults: number,
	showOrViews: number

	/** Count of tasks in total */
	total: number;
};

export type TaskCountVariant = "communications" | "defaults" | "showOrViews"

export const TaskCountVariantLabel: Record<TaskCountVariant, string> = Object.freeze({
	communications: "Взаимодействие",
	showOrViews: "По показам",
	defaults: "Обычные",
});

export type TaskCount = Record<TaskCountVariant, TaskCountInfo>;
