import { memo } from "react";
import { useDispatch } from "react-redux";
import { CollectionItem, RealEstate } from "../../../../../../../../types";

import { CardObjectSample } from "../../../../../../../UI";
import { useCardClick } from "./helpers";
import {
  NO_IMG_VALUE_COMP,
  setHoveredRealEstateImg,
} from "../../../../../../../../service/redux/modules/compilation/compilation.action";

type Props = {
  realEstate: RealEstate;
  isInSample: boolean;
  refetch: () => void;
  isParser?: boolean;
  sampledApplicationId: string;
  isCompare: boolean;
  isCompilation: boolean;
  collectionItem?: CollectionItem
};

export const ObjectListItem = memo(
  ({
     realEstate,
     isInSample,
     refetch,
     isParser,
     sampledApplicationId,
     isCompare,
     isCompilation,
     collectionItem,
   }: Props): JSX.Element => {
    const dispatch = useDispatch();
    const handleEnterBar = (imgUrl?: string) => {
      if (!isInSample) {
        dispatch(setHoveredRealEstateImg(imgUrl || NO_IMG_VALUE_COMP));
      }
    };
    const handleLeaveBar = () => dispatch(setHoveredRealEstateImg(undefined));

    const {handleAuxClick, handleCardClick} = useCardClick({
      realEstate,
      isParser,
      refetch,
    });

    return (
      <CardObjectSample
        key={realEstate.id}
        objectData={realEstate}
        isInSample={isInSample}
        onEnterBar={() => handleEnterBar(realEstate?.photos?.[ 0 ]?.photo1280)}
        onLeaveBar={handleLeaveBar}
        onAuxClick={handleAuxClick}
        onCardClick={handleCardClick}
        isCompilation={isCompilation}
        isCompare={isCompare}
        sampledApplicationId={sampledApplicationId}
        collectionItem={collectionItem}
      />
    );
  }
);
