import { useDispatch } from "react-redux";
import { openDrawer } from "../../../../redux/modules/drawer/drawer.action";
import { UnknownContact } from "../../../../../components/pages/UnknownContact";
import { useQueryParams } from "../../../../../utils/hooks";

type OpenUnknownContact = {
  phone: string
}

export const useOpenUnknownContact = () => {
  const dispatch = useDispatch()

  const {removeQueryParam} = useQueryParams<{
    phone: string;
  }>([ {name: "phone"}]);

  const openUnknownContact = (
    {
     phone,
    }: OpenUnknownContact) => {

    const contactNav = {
      label: "Контакт",
      height: 160,
      title: "contact",
      query: {
        name: "phone",
        value: phone
      }
    }

    dispatch(
      openDrawer({
        children: [<UnknownContact phone={phone}/>],
        width: 800,
        yNavigation: {
          initialTab: 0,
          navigation: [contactNav],
        },
        onClose: () => {
          removeQueryParam("phone")
        }
      })
    );
  }

  return {
    openUnknownContact
  }
}