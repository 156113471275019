import { useEffect } from "react";
import { Loader } from "@sdelka_crm/sdelka-crm-component-library";
import { useLoadPlatformsTable } from "../../../../service/api/queries";
import { getCurrentStatusLayout } from "../../../../utils/ads";
import { PlatformData } from "../../../../types";

import styles from "./index.module.scss";

type Props = {
	enabled: boolean;
	applicationId: string;
};

export const AdPlatformsTable = ({ applicationId, enabled }: Props): JSX.Element => {
	const { data, isLoading, refetch } = useLoadPlatformsTable({
		applicationId,
		enabled: false,
		params: "type=important&limit=10&offset=0",
	});

	useEffect(() => {
		if (enabled && applicationId) {
			refetch();
		}
	}, [enabled, applicationId]);

	return (
		<div>
			<Loader height={250} style={{ display: isLoading ? "block" : "none" }} />
			<div style={{ display: isLoading ? "none" : "block" }}>
				<table className={styles.root}>
					<tbody>
						{(data?.data || []).map((item: PlatformData) => {
							const [, StatusLayout] = getCurrentStatusLayout({
								adStatus: item.adStatus,
								options: {
									noData: true,
									noTooltip: true,
								},
							});

							return (
								<tr key={item.id}>
									<td>
										<img src={item.icon || ""} alt={item.name} />
									</td>

									<td>{StatusLayout}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
};
