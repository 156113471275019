import { useDispatch } from "react-redux";
import { useWatch } from "react-hook-form";
import cn from "classnames";
import { Underlay } from "@sdelka_crm/sdelka-crm-component-library";

import { RadioButton, TRadio } from "../../../../../../../../index";
import { RequiredDots } from "../../RequiredDots";
import { setRoomsAmount } from "../../../../../../../../../../service/redux/modules/object-edit/object-edit.action";
import { RealEstateSchemaConfigField, FormPropsEditRealEstate } from "../../../../../../../../../../types";

import styles from "./index.module.scss";

type Props = {
	form: FormPropsEditRealEstate;
	field: RealEstateSchemaConfigField;
};

const generateRadioOption = (curOptions: string[]) =>
	curOptions.map((item) => ({ label: item, value: item })) as TRadio[];

export const RoundCheckbox = ({ form, field }: Props): JSX.Element => {
	const {
		label,
		reqiredFor,
		dataType: { name = "", allowedValues },
	} = field;
	const dispatch = useDispatch();
	const { control, setValue, formState } = form;

	const error =
		formState?.errors?.rooms?.type === "required" ? "Обязательное поле" : formState?.errors?.rooms?.message;

	let brIndex = allowedValues?.findIndex((option) => option === "<BR>");
	// eslint-disable-next-line no-nested-ternary
	brIndex = !brIndex || brIndex === -1 ? (allowedValues ? allowedValues.length : 0) : brIndex;

	const letterOptions = ((allowedValues as string[]) || []).slice(0, brIndex);
	const numberOptions = ((allowedValues as string[]) || []).slice(brIndex + 1);

	const value = useWatch({
		control,
		name,
	});

	const onChange = (curValue) => {
		setValue(name, curValue);
		dispatch(setRoomsAmount(curValue));
	};

	if (!name || (!letterOptions.length && !numberOptions.length)) return <></>;

	return (
		<Underlay
			className={cn(styles.root, {
				[styles.rootWithLabel]: label,
			})}
		>
			<RequiredDots required={reqiredFor} />

			{label && <div className={styles.label}>{label}</div>}

			<div
				className={cn(styles.radioWrapper, {
					[styles.radioWrapperWithLabel]: label,
				})}
			>
				<RadioButton
					name={name}
					options={generateRadioOption(letterOptions)}
					control={control}
					separate
					required
					className={styles.radio}
					handleOnChange={onChange}
					currentValue={value}
				/>

				<RadioButton
					name={name}
					options={generateRadioOption(numberOptions)}
					control={control}
					separate
					required
					className={styles.radio}
					handleOnChange={onChange}
					currentValue={value}
				/>
			</div>

			{error && <div className={styles.error}>{error}</div>}
		</Underlay>
	);
};
