import { Lead } from "../../../../../../../../types";
import { AxiosResponse } from "axios";
import { toastError, toastSuccess } from "../../../../../../../UI";
import { useCreateLeadFromObject } from "../../../../../../../../service/api/mutations";
import { useQueryParams } from "../../../../../../../../utils/hooks";
import { useDispatch } from "react-redux";
import { closeDrawer } from "../../../../../../../../service/redux/modules/drawer/drawer.action";
import { useOpenLead } from "../../../../../../LeadsAndDeals/helpers";
import { useOpenParserObject } from "../../../../../../../../service/servise";

type Return = {
  handleCreateColdCall: (object: string) => void;
  isLoading: boolean;
};

type UseColdCall = (parserId?: string) => Return;

export const useColdCall: UseColdCall = (parserId?: string) => {
  const dispatch = useDispatch()
  const {handleOpenLead} = useOpenLead({
    refetch: () => {
    }
  })

  const {changeQueryParams} = useQueryParams([
    {name: "tab"},
    {name: "id"},
    {name: "parserObjectId"},
  ]);

  const onError = () => {
    toastError({text: "Не удалось совершить Холодный звонок"});
  };

  const {openParserObject} = useOpenParserObject({
    atDrawerInDrawer: true,
  })

  const onSuccess = (res: AxiosResponse<Lead>) => {
    const {
      data: {id},
    } = res;

    dispatch(closeDrawer())
    toastSuccess({text: "Холодный звонок совершен"});
    changeQueryParams([{name: "id", newValue: id}, {name: "parserObjectId", newValue: parserId ?? ""}])
    handleOpenLead(id);
    setTimeout(() => {
      openParserObject({
        parsedRealEstateId: parserId ?? "",
      })
    }, 100)
  };

  const {mutate, isLoading} = useCreateLeadFromObject({
    onSuccess,
    onError,
  });

  const handleCreateColdCall = (objectId: string) => {
    if (!isLoading) {
      mutate(objectId);
    }
  };

  return {
    handleCreateColdCall,
    isLoading,
  };
};
