import { CreateTaskForm } from "../../../../types";
import { FieldErrorsImpl, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { commonErrors } from "../../../../utils/errors";

const DEFAULT_TASK_CREATE_VALUES: CreateTaskForm = {
  deadlineDate: undefined,
  executor: {
    userId: [""],
  },
  deadlineTime: "",
  remindFor: 3600,
  name: "",
  description: "",
};

export const useCreateForm = () => {
  const schema = yup.object().shape(
    {
      name: yup.string().required(commonErrors.required).max(30, "Слишком длинное название"),
      description: yup.string().required(commonErrors.required).min(1, "Введите описание задачи"),
      deadlineDate: yup.string().required(commonErrors.required),
      executor: yup.object().required(commonErrors.required).nullable(),
    },
    [
      ["executor", "type"],
    ]
  );

  const {
    register,
    setValue,
    getValues,
    control,
    handleSubmit,
    formState: {errors},
  } = useForm<CreateTaskForm>({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_TASK_CREATE_VALUES,
  });

  const formErrors = errors as FieldErrorsImpl<CreateTaskForm>;

  return {
    register,
    setValue,
    getValues,
    control,
    handleSubmit,
    errors: formErrors,
  };
};
