import { ReactNode } from "react";
import { useLocation } from "react-router";

import { SplitScreenLayout } from "../../../../UI";
import { CompanyInformation, HandWithKey } from "../index";
import {
  selectDevice,
  selectRegisterSteps,
  selectResetPassword,
  useSelect
} from "../../../../../service/redux/selectors";
import { paths } from "../../../../../utils/paths";

import styles from "./index.module.scss";
import { TitleImage } from "../ParallaxBlock/TitleImage";

type Props = {
  children: ReactNode;
};

export const AuthLayout = ({children}: Props): JSX.Element => {
  const {currentStep, showCompanyData, companyData} = useSelect(selectRegisterSteps);
  const resetPass = useSelect(selectResetPassword);
  const {pathname} = useLocation();
  const {isMobile} = useSelect(selectDevice)

  return (
    <>
      {isMobile ?
        <div className={styles.content}>{children}</div>
        : <SplitScreenLayout
          leftColumn={
            <>
              {/* For login pages */}
              {pathname.includes(paths.loginPath) && (
                <HandWithKey/>
              )}
              {pathname.includes(paths.indexPath) && (
                <HandWithKey/>
              )}
              {/* For registration pages */}
              {pathname.includes(paths.registrationPath.default) && (
                <>
                  {showCompanyData && <CompanyInformation company={companyData}/>}
                  {!showCompanyData && currentStep === "sms-code" && <TitleImage/>}
                  {/* {!showCompanyData && currentStep !== "sms-code" && <Hands />} */}
                  {!showCompanyData && currentStep !== "sms-code" && <TitleImage/>}
                </>
              )}
              {/* For reset-password pages */}
              {pathname.includes(paths.resetPasswordPath) && (
                <>
                  {resetPass.currentStep === "sms-code" && <TitleImage/>}
                  {resetPass.currentStep !== "sms-code" && <TitleImage/>}
                  {resetPass.currentStep !== "sms-code" && <TitleImage/>}
                </>
              )}
            </>
          }
        >
          <div className={styles.content}>{children}</div>
        </SplitScreenLayout>
      }
    </>
  );
};
