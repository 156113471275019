import { useQuery } from "react-query";
import { MyShowsViewsResponse } from "../../../../types";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/shows-views/load-my-shows-views/DEFAULT_QN";

type Props = {
	date: Date;
};

type Return = {
	data: MyShowsViewsResponse[];
	isLoading: boolean;
	isFetching: boolean;
	refetch: () => void;
	remove: () => void;
};

type UseLoadMyShowsViews = (props: Props) => Return;

export const useLoadMyShowsViews: UseLoadMyShowsViews = ({ date }) => {
	const loadMyShowsViews = () => api.showsViews.myList(date);

	const { data, isLoading, isFetching, refetch, remove } = useQuery([DEFAULT_QN, date], loadMyShowsViews);

	return {
		data: data?.data || [],
		isLoading,
		isFetching,
		refetch,
		remove,
	};
};
