import { useState } from "react";
import { Placemark, withYMaps, YMapsApi } from "react-yandex-maps";

import { iconTemplate } from "../../../map-utils/utils";
import { usePropsVariables } from "./helpers";
import { getPriceLabel } from "../../../../../../utils/clientObjects";
import { PointType } from "../../../../../../types";

import "./PointWithCluster.scss";

type Props = {
  point: PointType;
  priceType: string;
  onUnselectPoint: () => void;
  ymaps?: YMapsApi;
  filterByMapClick?: (ids?: string[]) => void
};

export const PointWithBalloon = withYMaps(
  ({
     point,
     priceType,
     onUnselectPoint,
     ymaps,
     filterByMapClick,
   }: Props): JSX.Element => {
    const {lat, lon, pricePerUnit, price, ids} = usePropsVariables({point});

    const currentPrice = priceType === "price" ? getPriceLabel({price})
      : getPriceLabel({pricePerUnit});
    const iconContent = `${priceType === "office" ? "" : currentPrice}`;
    const [wasOpen, setWasOpen] = useState(false);
    const [id, setId] = useState<string | undefined>(undefined)

    const handleSetPlaceMark = (ref) => {
      if (!wasOpen && ref?.balloon) {
        setWasOpen(true);
        if (filterByMapClick) {
          filterByMapClick(ids)
        }
      }
      if (ids && ids[0] !== id) {
        setId(ids[0])
        setWasOpen(false)
        if (filterByMapClick) {
          filterByMapClick(ids)
        }
      }
    };

    return (
      <Placemark
        properties={{iconContent}}
        geometry={[lat, lon]}
        onClick={() => {
        }}
        onBalloonClose={onUnselectPoint}
        options={{
          iconLayout: ymaps?.templateLayoutFactory.createClass(iconTemplate),
          iconShape: {
            type: "Rectangle",
            coordinates: [
              [-78, -34],
              [0, 0],
            ],
          },
        }}
        instanceRef={(ref) => handleSetPlaceMark(ref)}
      />
    );
  }
);
