import { useMutation } from "react-query";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/compilation/use-delete-unactual-from-compilation/DEFAULT_MN";

type UseDeleteUnactual = {
	onSuccess?: () => void;
};

export const useDeleteUnactual = ({ onSuccess }: UseDeleteUnactual) => {
	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: (applicationId: string) => api.compilations.deleteUnactual(applicationId),
		onSuccess,
	});

	return {
		mutate: mutate.mutate,
		isLoading: mutate.isLoading,
	};
};
