import cn from "classnames";

import { SideContainer } from "../SideContainer";
import { InnerMenuItem } from "../../../../../types";
import { SecondLevel } from "./components";

import styles from "./index.module.scss";

type Props = {
	isOpen: boolean;
	list: InnerMenuItem[];
	paddingTop?: number;
};

export const InnerMenu = ({ isOpen, list, paddingTop = 0 }: Props): JSX.Element => {
	const renderSubMenu = (item: InnerMenuItem) =>
		item.submenu && item.submenu?.length > 0 ? <SecondLevel subMenu={item.submenu} /> : <></>;

	return (
		<SideContainer isOpen={isOpen} className={cn(styles.root, { [styles.rootIsOpen]: isOpen })}>
			<div style={{ paddingTop }} className={styles.list}>
				{list.map((listItem) => (
					<div key={listItem.id} className={styles.item}>
						<span onClick={listItem.onClick}>{listItem.text}</span>

						{renderSubMenu(listItem)}
					</div>
				))}
			</div>
		</SideContainer>
	);
};
