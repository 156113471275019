import { memo, useRef, useState } from "react";
import cn from "classnames";
import { Link } from "../../Link";
import { formatDateWithTime } from "../../../../utils/date";
import { prepareShortUserOutput } from "../../../../utils/transformation";
import { useClickOutside } from "../../../../utils/hooks";
import { UserData } from "../../../../types";
import styles from "./index.module.scss";
import { useIsTelegram } from "../../../../utils/telegram/useIsTelegram";

type Props = {
  creator: UserData & { id: string; userId: string };
  lead: {
    shortId: string;
    id: string;
  };
  createdDate: string;
  updatedDate: string;
};

export const Creator = memo(
  ({
     creator,
     lead: {shortId, id},
     createdDate,
     updatedDate,
   }: Props): JSX.Element => {

    const triggerRef = useRef<HTMLDivElement>(null);
    const [active, setActive] = useState(false);

    useClickOutside({
      ref: triggerRef,
      onClick: () => {
        if (active) {
          setActive(false);
        }
      },
    });

    const {executeUrl} = useIsTelegram()

    const pushToLead = () => {
      executeUrl(`/leadsanddeals?tab=leads&id=${id}`)
    }
    const pushToAccount = () => {
      executeUrl(`/employees?userId=${creator.userId}&accountId=${creator.id}`)
    }

    return (
      <div className={styles.root}>
        <div>
          <div className={styles.block}>
            <div className={styles.block}>
              <div className={styles.title}>Заявку создал:</div>
              <Link onClick={pushToAccount} classNames={{root: styles.info}}>
                {prepareShortUserOutput(creator)}
              </Link>
            </div>
          </div>
          {shortId && (
            <div className={styles.block}>
              <div className={styles.title}>Заявка создана из лида:</div>
              <Link onClick={pushToLead} classNames={{root: styles.info}}>{`№ ${shortId}`}</Link>
            </div>
          )}
          {createdDate && (
            <div className={styles.block}>
              <div className={styles.title}>Дата создания:</div>
              <div className={cn(styles.info, styles.date)}>
                {formatDateWithTime(new Date(createdDate), false)}
              </div>
            </div>
          )}
          {updatedDate && (
            <div className={styles.block}>
              <div className={styles.title}>Дата изменения:</div>
              <div className={cn(styles.info, styles.date)}>
                {formatDateWithTime(new Date(updatedDate), false)}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);
