import { useMutation } from "react-query";
import { CreateGuaranteePayment } from "../../../../types";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/application/create-guarantee-payment/DEFAULT_MN";

type UseCreateGuaranteePayment = {
  onSuccess?: () => void;
};

export const useCreateGuaranteePayment = (
  {
    onSuccess
  }: UseCreateGuaranteePayment) => {
  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: ({applicationId, payload}: {applicationId: string, payload: CreateGuaranteePayment}) =>
    api.application.createGuaranteePayment(applicationId, payload),
    onSuccess
  })

  return {
    createGuaranteePayment: mutation.mutate,
    data: mutation.data,
    isLoading: mutation.isLoading
  }
}