import { PreCreateApplicationPayload } from "../../../../types";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../service/redux/modules/modal/modal.action";
import { useCreatePreApplicationTemplate } from "service/api/mutations/pre-application-template";
import { usePreCreatePreApplication } from "../usePreCreatePreApplication";
import { useQueryParams } from "../../helpers";

type Props = {
  leadId: string;
  objectCategory?: string
  onSuccess?: (data) => void;
};

type Return = {
  handleCreateApplication: () => void;
  isLoading: boolean;
};

type UseCreatePreApplicationModal = (props: Props) => Return;

export const useCreatePreApplicationModal: UseCreatePreApplicationModal = (
  {
    leadId,
    onSuccess,
    objectCategory,
  }) => {
  const dispatch = useDispatch();
  const {queryParams, removeQueryParam} = useQueryParams<{
    parserObjectId: string
  }>([
    {name: "parserObjectId"},
  ])

  const {mutate, isLoading} = useCreatePreApplicationTemplate({
    onSuccess: (data) => {
      dispatch(closeModal());
      if (queryParams.parserObjectId !== undefined) {
        removeQueryParam("parserObjectId")
      }

      if (onSuccess) {
        onSuccess(data.data);
      }
    },
  });

  const onApplicationCreate = ({type, category}: PreCreateApplicationPayload) =>
    mutate({
      leadId,
      category,
      businessProcessId: type.id,
    });

  const CREATE_APPLICATION_TITLE = "Создать предзаявку";

  const {handleCreateObject} = usePreCreatePreApplication({
    onCreated: onApplicationCreate,
    title: CREATE_APPLICATION_TITLE,
    isFull: true,
    isLoading,
    objectCategory,
  });

  return {
    handleCreateApplication: handleCreateObject,
    isLoading,
  };
};
