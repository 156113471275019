import styles from "./index.module.scss"
import { ReactComponent as Alert } from "../../../../../assets/icons/iconWarnBig.svg";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../service/redux/modules/modal/modal.action";

type Props = {
  onApply: () => void
}

export const DeleteFromDepartmentModal = (
  {
    onApply,
  }: Props): JSX.Element => {
  const dispatch = useDispatch()
  const onDeny = () => {
    dispatch(closeModal())
  }

  return (
    <>
      <div className={styles.row}>
        <div>
          <Alert/>
        </div>
        <div className={styles.column}>
          <div className={styles.title}>Вы уверены, что хотите убрать сотрудника из отдела?</div>
        </div>
      </div>
      <div className={styles.rowEnd}>
        <Button onClick={onDeny} variant="outlined">
          Отмена
        </Button>
        <Button onClick={onApply} classNames={{root: styles.buttonCreate}}>
          Продолжить
        </Button>
      </div>
    </>
  )
}