import { CallData } from "../../../../../types";
import {ReactComponent as CallIn} from "../../../../../assets/icons/callIn.svg";
import {ReactComponent as CallOut} from "../../../../../assets/icons/callOut.svg";
import {ReactComponent as CallMissed} from "../../../../../assets/icons/callMissed.svg";
import {ReactComponent as CallBusy} from "../../../../../assets/icons/callBusy.svg";

export const getIconAndLabel = (call:CallData):JSX.Element => {
  const {
    type,
    status
  } = call

  if ( status === "success") {
    if (type === "in") {
      return <CallIn/>
    }
    return <CallOut/>
  }

  if (status === "missed") {
    return <CallMissed/>
  }

  return <CallBusy/>
}

export const getLabel = (call:CallData) => {
  const {
    type,
    status
  } = call

  if ( status === "success") {
    if (type === "in") {
      return {
        label: "Входящий"
      }
    }
    return {
      label: "Исходящий"
    }
  }

  if (status === "missed") {
    return {
      label: "Пропущенный"
    }
  }

  return {
    label: "Недозвон"
  }
}