import { useState } from "react";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import cn from "classnames";

import { Textarea } from "../Form";
import { DialogShape } from "../DialogShape";

import { ReactComponent as Send } from "../../../assets/icons/send.svg";
import { ReactComponent as Pencil } from "../../../assets/icons/pencil.svg";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import styles from "./index.module.scss";

type Props = {
	isActive?: boolean;
	editable?: boolean;
	image?: string;
	title: string;
	text?: string;
	placeholder?: string;
	form?: {
		name: string;
		register: UseFormRegister<any>;
		setValue: UseFormSetValue<any>;
	};
};

export const Comment = ({
	editable = false,
	image = "",
	title,
	text = "",
	placeholder = "",
	isActive = false,
	form,
}: Props): JSX.Element => {
	const [active, setActive] = useState(isActive);

	return (
		<div className={styles.form}>
			<div className={styles.header}>
				<div className={styles.headerMain}>
					{image && !active && (
						<div className={styles.image}>
							<img src={image} alt={title} />
						</div>
					)}

					<h2>{title}</h2>
				</div>

				{editable && (
					<div onClick={() => setActive(!active)} className={styles.editable}>
						{!active ? <Pencil /> : <Close />}
					</div>
				)}
			</div>

			{active && form && (
				<div className={styles.area}>
					<Textarea placeholder={placeholder} name={form.name} register={form.register} />
					<Send />
				</div>
			)}

			{!active && (
				<div className={cn(styles.text, image && styles.withImage)}>
					<DialogShape classNames={{ root: styles.dialogRoot, dialog: styles.dialog }} side="left">
						{text}
					</DialogShape>
				</div>
			)}
		</div>
	);
};
