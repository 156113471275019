import { UserAccountData } from "../../../../../../types";
import styles from "./index.module.scss";
import cn from "classnames";
import { CardPerson } from "../../../../Card";
import { CardContact } from "../../../../Card/card-contact/CardContact";

type Props = {
  data: UserAccountData[];
  classNames?: string
  isMobile?: boolean
  withOutDots?: boolean
};

export const UserListCardsLocal = (
  {
    data,
    classNames,
    isMobile,
    withOutDots
  }: Props): JSX.Element => {
  const isThereData = data && data.length > 0;

  return (
    <>
      <ul className={cn(styles.body, classNames)}>
        {isThereData &&
          data.map((user) => (
            <li
              key={user.id}
            >
              <CardContact
           contact={user}
           mini={isMobile}
           withoutDots={withOutDots}
              /></li>
          ))}
      </ul>
      {!isThereData && <div className={styles.noData}>Не назначено</div>}
    </>
  );
};
