import { ShowsViewsStatus } from "../../../../../../types";
import { ReactComponent as IconCanceled } from "../../../../../../assets/icons/iconHouseBigRed.svg";
import { ReactComponent as IconSuccess } from "../../../../../../assets/icons/iconHouseBigGreen.svg";
import { ReactComponent as IconWaiting } from "../../../../../../assets/icons/iconHouseBigGray.svg";
import { ReactComponent as IconFinalSuccess } from "../../../../../../assets/icons/iconHouseBigBlue.svg";


export const getMainIcon = (status: ShowsViewsStatus) => {
  switch (status) {
    case "canceled":
      return <IconCanceled/>
    case "finished":
      return <IconSuccess/>
    case "successFinished":
      return <IconFinalSuccess/>
    case "waiting":
      return <IconWaiting/>
    default:
      return <IconSuccess/>
  }
}