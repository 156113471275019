import { useMutation } from "react-query";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/user/account-update-photo/DEFAULT_MN";

type UseAccountUpdatePhoto = {
	onSuccess?: () => void;
	onError?: () => void;
};

export const useAccountUpdatePhoto = ({ onSuccess, onError }: UseAccountUpdatePhoto) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: ({ accountId, data }: { accountId: string; data: { avatar: string } }) =>
			api.users.updatePhoto(accountId, data),
		onSuccess,
		onError,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
