// Get how mush time left for request/access.
export const requestDateCalculator = (date: Date): string => {
	const difference = 259200000 - (new Date().getTime() - date.getTime());
	if (difference > 172800000) {
		return "3 дн";
	}
	if (difference > 86400000 && difference <= 172800000) {
		return "2 дн";
	}
	if (difference > 82800000 && difference <= 86400000) {
		return "1 дн.";
	}

	if (difference > 3600000 && difference <= 82800000) {
		return `${Math.round(difference / 3600000).toString()} ч.`;
	}

	return "меньше часа";
};
