import { KindType, RealEstate } from "../../../../../../../../../types";

import { useOpenRealEstate } from "../../../../../../../../../utils/objects";
import { useOpenDrawerRealEstate, useQueryParams } from "../../../../../../../../../utils/hooks";
import {
  useOpenObjectApplication,
  useOpenObjectPreApplication,
  useOpenParserObject
} from "../../../../../../../../../service/servise";
import { selectDevice, useSelect } from "../../../../../../../../../service/redux/selectors";

type Props = {
  isParser?: boolean;
  refetch: () => void;
  realEstate: RealEstate;
};

type Return = {
  handleAuxClick: () => void;
  handleCardClick: () => void;
};

type UseCardClick = (props: Props) => Return;

type QueryParams = {
  applicationId: string;
  parserRealEstateId: string;
  preApplicationId: string
};

export const useCardClick: UseCardClick = ({isParser, refetch, realEstate}) => {
  const {removeQueryParam} = useQueryParams<QueryParams>([
    {name: "applicationId"},
    {name: "parserRealEstateId"},
    {name: "preApplicationId"},
  ]);

  const {isMobile} = useSelect(selectDevice)
  const {shortType} = realEstate;

  const onClose = () => {
    refetch();
    if (isParser) {
      removeQueryParam("parserRealEstateId");
    } else if (shortType.kind === KindType.APPLICATION) {
      removeQueryParam("applicationId");
    } else {
      removeQueryParam("preApplicationId")
    }
  };

  const {openObjectApplication} = useOpenObjectApplication({atDrawerInDrawer: false})
  const {openObjectPreApplication} = useOpenObjectPreApplication({atDrawerInDrawer: false})
  const {openParserObject} = useOpenParserObject({atDrawerInDrawer: false, refetchList: refetch})

  const openCompilationObject = () => {
    if (isParser) {
      openParserObject({
        parsedRealEstateId: realEstate.id,
        onClose,
        hasMap: true,
      })
    } else if (shortType.kind === KindType.APPLICATION) {
      openObjectApplication({
        applicationId: realEstate.applicationId,
        kind: KindType.APPLICATION,
        onClose,
        hasMap: true,
        whoIsClient: shortType.whoIsClient,
        isMobile,
        isFromCompilation: true
      })
    } else {
      openObjectPreApplication({
        applicationId: realEstate.preApplicationId,
        hasMap: true,
        isMobile,
        whoIsClient: shortType.whoIsClient,
        isFromObjectList: true,
        isFromLead: false,
        isFromCompilation: true
      })
    }

  }

  const handleAuxClick = () => {};

  const handleCardClick = () => openCompilationObject();

  return {
    handleAuxClick,
    handleCardClick,
  };
};
