import { GroupByDetalizationParams } from "../../../../../../../../../../../types";

const GROUP_BY_MULTIPLIERS = {
	[GroupByDetalizationParams.WEEK]: 7 * 24 * 60 * 60 * 1000,
	[GroupByDetalizationParams.MONTH]: 30 * 24 * 60 * 60 * 1000,
	[GroupByDetalizationParams.NINE_MONTH]: 9 * 30 * 24 * 60 * 60 * 1000,
};

export enum DIRECTIONS {
	PREVIOUS = "prev",
	NEXT = "next",
}

export enum DATETYPES {
	START = "start",
	END = "end",
}

export const formatDate = (date: Date, isForBackend?: boolean) => {
	const day = date.getDate().toString().padStart(2, "0");
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	const year = date.getFullYear().toString();
	if (isForBackend) return `${year}-${month}-${day}`;
	return `${day}.${month}.${year}`;
};

export const getStartDate = (nowDate: Date, groupBy: GroupByDetalizationParams): Date => {
	if (groupBy === GroupByDetalizationParams.WEEK)
		return new Date(nowDate.getTime() - GROUP_BY_MULTIPLIERS[GroupByDetalizationParams.WEEK]);
	if (groupBy === GroupByDetalizationParams.MONTH) {
		const startDate = new Date(nowDate);
		startDate.setMonth(nowDate.getMonth() - 1);
		return startDate;
	}
	if (groupBy === GroupByDetalizationParams.NINE_MONTH) {
		const start = new Date(nowDate);
		start.setMonth(nowDate.getMonth() - 9);
		return start;
	}
	return nowDate;
};

export const getDefaultDate = (groupBy: GroupByDetalizationParams, type: DATETYPES): Date => {
	const nowDate = new Date();
	const startDate = getStartDate(nowDate, groupBy);

	if (type === DATETYPES.START) return startDate;
	if (type === DATETYPES.END) return nowDate;
	return nowDate;
};

export const checkDatesEquality = (firstDate: Date, secondDate: Date) =>
	firstDate.getFullYear() === secondDate.getFullYear() &&
	firstDate.getMonth() === secondDate.getMonth() &&
	firstDate.getDate() === secondDate.getDate();

export const updateDate = (date: Date, groupBy: GroupByDetalizationParams, direction: DIRECTIONS) => {
	const directionMultiplier = direction === DIRECTIONS.PREVIOUS ? -1 : 1;
	const groupByMultiplier = GROUP_BY_MULTIPLIERS[groupBy];
	return new Date(date.getTime() + directionMultiplier * groupByMultiplier);
};
