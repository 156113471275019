import { CartPayload, ProductTypeEnum, SelectOption, SubscriptionRenewalPeriod } from "../../../../../../types";
import styles from "./index.module.scss"
import { InfoRow, RadioButton, Select, toastSuccess, TRadio } from "../../../../../UI";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../../service/redux/modules/modal/modal.action";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useAddToCart, useRequestPrice } from "../../../../../../service/api/mutations";
import { employeesNounHelper } from "../../../../../../utils/string";
import { selectCurrentAccount, useSelect } from "../../../../../../service/redux/selectors";
import { formatThousand } from "../../../../../../utils/formatters";

type Props = {
  ids: string[]
  title: string;
  type: ProductTypeEnum
  action: (payload: CartPayload[]) => void
  tariffLabel?: string
  tariffOptions?: SelectOption[]
  buyInOneClick?: boolean
  applicationId?: string
  refetchCart?: () => void
  adPlatformAccountId?: string
}

const radioOptions: TRadio[] = [
  {label: "год", value: SubscriptionRenewalPeriod[ "365days" ]},
  {label: "месяц", value: SubscriptionRenewalPeriod[ "30days" ]},
]

export const AddInCartModal = (
  {
    ids,
    title,
    type,
    action,
    tariffLabel,
    tariffOptions,
    buyInOneClick,
    applicationId,
    refetchCart,
    adPlatformAccountId
  }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const {control, register, setValue} = useForm<CartPayload>()
  const [reneval, setReneval] = useState<SubscriptionRenewalPeriod | undefined>(SubscriptionRenewalPeriod[ "365days" ])
  const [selectedTariff, setSelectedTariff] = useState<undefined | string>(tariffOptions?.[ 0 ].value)
  const {currentAccount} = useSelect(selectCurrentAccount);
  const isCoWorking = currentAccount && currentAccount.position?.name === "Агент в коворкинге"

  const {data: prices, mutate} = useRequestPrice({
    payload:
      {
        productType: type,
        userIds: type === ProductTypeEnum.service ? undefined : ids,
        subscriptionRenewalPeriod: type === ProductTypeEnum.service ? undefined : reneval,
        serviceId: type === ProductTypeEnum.service ? ids[ 0 ] : undefined,
        tariff: type === ProductTypeEnum.service ? selectedTariff : undefined,
        adPlatformAccountId,
      }
  })

  useEffect(() => {
    mutate()
  }, [reneval, selectedTariff])

  const handleChangeRadio = (value: string | number) => {
    setReneval(value as SubscriptionRenewalPeriod)
  }

  const {mutate: addToCart} = useAddToCart({
    onSuccess: () => {
      toastSuccess({text: "Заказ добавлен в корзину"})
      if (refetchCart) {
        refetchCart()
      }
      dispatch(closeModal())
    }
  })

  const onDeny = () => {
    dispatch(closeModal())
  }
  const onApply = () => {
    const payload: CartPayload[] = ids.map(id => JSON.parse(JSON.stringify({
      id,
      applicationId,
      productType: type,
      amount: 1,
      tariff: type === ProductTypeEnum.service ? selectedTariff : reneval,
      adPlatformAccountId,
    })))

    if (buyInOneClick) {
      action(payload)
    } else {
      addToCart(payload)
    }

    dispatch(closeModal())
  }

  const handleChangeTariff = (value) => {
    setSelectedTariff(value)
  }

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {title}
      </div>
      <form>
        {type === ProductTypeEnum.employerSubscribtion &&
          <InfoRow label="Продлить на">
            <RadioButton
              name="tariff"
              backgroundButtons="blue"
              className={styles.RadioButton}
              options={radioOptions}
              control={control}
              handleOnChange={handleChangeRadio}
              currentValue={reneval}
            />
          </InfoRow>}
        {type === ProductTypeEnum.service &&
          <InfoRow label={tariffLabel ?? "Выберите услугу"} classNames={{content: styles.infoRow}}
                   aspect={{label: 6, content: 5}}>
            <Select
              classNames={{
                root: styles.select,
                wrapper: styles.select,
                selectListWrapper: styles.selectWrapper,
              }}
              options={tariffOptions ?? []}
              name="tariff"
              register={register}
              setValue={setValue}
              onChange={(value) => handleChangeTariff(value)}
              defaultValue={tariffOptions?.[ 0 ].value ?? ""}
            />
          </InfoRow>}
      </form>
      {!isCoWorking &&
        <InfoRow label="Цена за тариф" aspect={{label: 6, content: 5}}>
          <div className={styles.tariff}>
            <div style={{minWidth: "50px"}}>{`${formatThousand(prices?.price) ?? ""} ₽`}</div>
            {type === ProductTypeEnum.employerSubscribtion &&
              <>
                <div>|</div>
                <div>{`${ids.length} ${employeesNounHelper(ids.length)}`}</div>
              </>
            }
          </div>
        </InfoRow>
      }
      <div className={styles.row}>
        <div className={styles.totalPrice}>
          {`Итого: ${formatThousand(prices?.totalPrice ?? 0)} ₽`}
        </div>
        <div className={styles.row}>
          <Button onClick={onDeny} variant="outlined">
            Отмена
          </Button>
          <Button onClick={onApply} classNames={{root: styles.buttonCreate}}>
            {buyInOneClick ? "Оплатить" : "В корзину"}
          </Button>
        </div>
      </div>
    </div>
  )
}