import { useLoadFile } from "../../../service/api/mutations";

export const useLoadFiles = () => {
  const {mutateAsync} = useLoadFile({});

  const loadFiles = async (files: Array<File>) => {

    const filesUrls = await Promise.all(
      files.map((file) => {
        const formData = new FormData();

        formData.append("file", file);

        const payload = {
          file: formData,
        };

        return mutateAsync(payload);
      })
    );

    return filesUrls.map((file) => file.data)
  };

  return {loadFiles};
};
