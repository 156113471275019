import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { AddressSelect, Col, Container, Row, TextField } from "../../../../../UI";
import { registerCompany } from "../../../../../../service/redux/modules/auth/auth.thunk";
import { setCompanyName, } from "../../../../../../service/redux/modules/registration-steps/reg-steps.action";
import { commonErrors } from "../../../../../../utils/errors";
import { CompanyCreatePayload } from "../../../../../../types";
import styles from "./index.module.scss";
import { newCompanyFormModal } from "../../FinishRegistration/newCompanyFormModal";

const maxCompanyNameLength = 100;

export const NewCompanyForm = (): JSX.Element => {
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    companyName: yup
      .string()
      .max(maxCompanyNameLength, `Масимум ${maxCompanyNameLength} символов`)
      .required(commonErrors.required),
    address: yup.object().shape({
      value: yup.string().required(commonErrors.required),
    }),
  });

  const {handleSubmit, control, formState, register, setValue, watch} = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      companyName: "",
      address: {
        value: "",
        label: "",
      },
    },
  });
  const {openNewCompanyFormModal} = newCompanyFormModal()
  const [watchAddress] = watch(["address"]);

  const onSubmit = (values: any) => {
    const data: CompanyCreatePayload = {
      addressId: values?.address.value,
      addressLabel: values?.address.label,
      companyName: values?.companyName,
    };

    dispatch(registerCompany(data));
    dispatch(setCompanyName(values?.companyName));
    openNewCompanyFormModal()
  };

  return (
    <form className={styles.root} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.fields}>
        <Container>
          <Row marginBottom={20}>
            <Col xs={12}>
              <TextField
                control={control}
                label="Название компании"
                placeholder="Указать"
                name="companyName"
                error={formState.errors.companyName?.message?.toString()}
                className={styles.companyName}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <AddressSelect
                classNames={{selected: `${styles.addressSelect}`}}
                name="address"
                label="Адрес офиса"
                placeholder="Указать"
                register={register}
                setValue={setValue}
                error={formState.errors.address?.value?.message?.toString()}
                value={watchAddress}
                withBorder
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Button type="submit" classNames={{root: styles.buttonNewCompany}}>
        Создать
      </Button>
    </form>
  );
};
