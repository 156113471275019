import styles from "./index.module.scss"
import { SelectOfficeTabs } from "./select-office-tabs/SelectOfficeTabs";
import { RealEstateMap, SidebarLayout } from "../../UI";
import { selectCurrentAccount, selectDevice, useSelect } from "../../../service/redux/selectors";
import { NoData, Pagination } from "@sdelka_crm/sdelka-crm-component-library";
import { useQueryParams } from "../../../utils/hooks";
import { useLoadSelectOffices } from "../../../service/api/queries";
import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { OfficesTable } from "./offices-table";
import { useOpenSelectedOfficeCard } from "../../../service/servise";
import { SelectOfficeAddress } from "./address/SelectOfficeAddress";
import { ReactComponent as MapIcon } from "../../../assets/icons/iconMap.svg";
import { useDispatch } from "react-redux";
import { openDrawer } from "../../../service/redux/modules/drawer/drawer.action";

enum TabTypeEnum {
  SELECT_OFFICE = 0,
  BECOME_A_PARTNER = 1,
}

type QueryParams = {
  tab: "SELECT_OFFICE" | "BECOME_A_PARTNER"
  officeId: string
};

export const SelectOffice = () => {
  const {currentAccount} = useSelect(selectCurrentAccount);
  const {isMobile} = useSelect(selectDevice)
  const {openOfficeDrawer} = useOpenSelectedOfficeCard({});
  const dispath = useDispatch()

  const {
    queryParams,
    changeQueryParams
  } = useQueryParams<QueryParams>([
    {name: "tab", type: "string"},
    {name: "officeId", type: "string"}
  ]);
  let currentTab = queryParams.tab ?? TabTypeEnum.SELECT_OFFICE;
  useEffect(() => {
    if (queryParams.tab === "SELECT_OFFICE") {
      if (queryParams.officeId) {
        openOfficeDrawer(queryParams.officeId)
      }
    }
  }, [queryParams.officeId, queryParams.tab])

  useEffect(() => {
    if (!queryParams.tab) {
      changeQueryParams([{name: "tab", newValue: "SELECT_OFFICE"}])
    }
  }, [])

  const [addressId, setAddressId] = useState<string | undefined>(undefined)
  const [point, setPoint] = useState<[number, number] | undefined>(undefined)

  const {
    offices,
    take: officesTake,
    page: officesPage,
    setPage: officesSetPage,
    total: officesTotal,
    refetch: refetchOffices,
    isFetching: isFetchingOffices
  } = useLoadSelectOffices({
    enabled: currentAccount !== undefined,
    addressId
  })

  const wrapperRef = useRef<HTMLDivElement>(null);


  const [centerXY] = useState<[number | typeof NaN, number | typeof NaN]>([NaN, NaN]);

  // const isCoWorking = currentAccount && currentAccount.position?.name === "Агент в коворкинге"
  const mapZoom = Number(currentAccount?.company?.mapZoom);
  const mapCenter = point || currentAccount?.department?.office?.mapCenter as [
    number,
    number
  ];

  const getDefaultCenter = (initialCenter?: [number, number]): [number, number] => {
    if (!initialCenter || !initialCenter[ 0 ] || !initialCenter[ 1 ]) {
      return [59.94506601286178, 30.31705281156423];
    }
    return [initialCenter[ 0 ], initialCenter[ 1 ]];
  };

  const pointsForMap = offices ? offices.map(elem => JSON.parse(JSON.stringify({
    objects: [
      {
        id: "",
        price: 0,
        pricePreUnit: 0,
        unut: elem.addressLabel,
      }
    ],
    lat: elem.location?.coordinates?.[ 0 ] ?? 59.94506601286178,
    lon: elem.location?.coordinates?.[ 1 ] ?? 30.31705281156423,
  }))) : []

  const handleChangeAddress = (value: string) => {
    setAddressId(value)

    setTimeout(() => {
      refetchOffices()
    }, 50)
  }

  const handleChangePoint = (val?: [number, number]) => {
    setPoint(val)
  }

  useEffect(() => {
    refetchOffices()
  }, [])

  const openMapDrawer = () => {
    dispath(openDrawer({
      children:
        <RealEstateMap
          points={pointsForMap}
          priceTypeAlternative="office"
          isSelectOffice
          options={{
            order: "map-first",
            center: mapCenter ?? getDefaultCenter(centerXY),
            zoom: mapZoom,
            updateCenterIfChanged: true,
            withoutHandlers: true,
          }}
        />,
      width: 500
    }))
  }

  return (
    <>
      <SidebarLayout
        fullSize
        isMobile={isMobile}
        hat={!isMobile ? {
          element: <></>,
          elementHeight: 0,
        } : {element: <></>, elementHeight: 0}}
        withScrollBtn
      >
        <div className={cn(styles.root, {[styles.rootNotPremium]: !currentAccount?.isPremium})}>
          <div ref={wrapperRef} className={styles.listWrapper}>
            <SelectOfficeTabs/>
            <div
              className={cn(styles.list, {})}
            >
              {(currentTab === "SELECT_OFFICE" || currentTab === undefined) &&
                <div className={styles.tabContent}>
                  <div className={styles.tableWithPagination}>
                    {!isFetchingOffices && offices.length === 0
                      ? <NoData onRefetch={refetchOffices}/>
                      : <>
                        <OfficesTable
                          officesList={offices}
                          isLoading={isFetchingOffices}
                          isMobile={isMobile}
                          isSelect
                        />
                        {!isFetchingOffices && officesTotal !== 0 && (
                          <Pagination
                            classNames={{root: styles.pagination}}
                            totalCount={officesTotal || 0}
                            pageSize={officesTake}
                            currentPage={officesPage || 0}
                            onPageChange={(val: number) => officesSetPage ? officesSetPage(val) : () => {
                            }}
                            isLoading={isFetchingOffices}
                          />
                        )}
                      </>
                    }
                  </div>
                </div>
              }
              {currentTab === "BECOME_A_PARTNER" && <></>}
            </div>
          </div>
          <SelectOfficeAddress
            onChangeAddress={handleChangeAddress}
            onChangePoint={handleChangePoint}
          />
          {isMobile &&
            <div className={styles.mapButton} onClick={openMapDrawer}>
              <MapIcon/>
            </div>
          }
          {currentTab === "SELECT_OFFICE" && !isMobile &&
            <div className={styles.map}>
              <RealEstateMap
                points={pointsForMap}
                isSelectOffice
                priceTypeAlternative="office"
                options={{
                  order: "map-first",
                  center: mapCenter ?? getDefaultCenter(centerXY),
                  zoom: mapZoom,
                  updateCenterIfChanged: true,
                }}
              />
            </div>}
        </div>
      </SidebarLayout>

    </>

  )
}