import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { Button, H2, LoadByScroll } from "@sdelka_crm/sdelka-crm-component-library";
import { BigButton, CardObject, CardObjectMini, usePinObjectModal } from "../../index";
import { CreateObject } from "./components";
import { HiddenWidget } from "../HiddenWidget";
import { useBlockAction } from "../helpers";
import { closeModal, openModal } from "../../../../service/redux/modules/modal/modal.action";
import { PreCreateObjectPayload, RealEstate, StageConditions } from "../../../../types";
import { ReactComponent as Plus } from "../../../../assets/icons/plus.svg";
import { ReactComponent as Clip } from "../../../../assets/icons/clip.svg";
import { ReactComponent as Pencil } from "../../../../assets/icons/pencil.svg";
import { ReactComponent as Close } from "../../../../assets/icons/close.svg";
import styles from "./index.module.scss";
import { useQueryParams } from "utils/hooks";
import { useIsTelegram } from "../../../../utils/telegram/useIsTelegram";

type Props = {
  scrollY?: number;
  defaultValues?: PreCreateObjectPayload;
  object: RealEstate | undefined;
  onObjectChange: (object: RealEstate) => void;
  onPinObject: (object: RealEstate) => void;
  onRemoveObject: () => void;
  stageConditions?: StageConditions;
  apiEnabled?: boolean;
  withCreate?: boolean;
  applicationId: string;
  canUpdateOrRemoveObject?: boolean;
  kind?: "pre-applications" | "applications",
  isMobile?: boolean
};

export const ObjectCreation = ({
                                 object,
                                 scrollY,
                                 onObjectChange,
                                 onRemoveObject,
                                 onPinObject,
                                 stageConditions,
                                 apiEnabled = true,
                                 withCreate,
                                 defaultValues,
                                 applicationId,
                                 canUpdateOrRemoveObject,
                                 kind,
                                 isMobile
                               }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [enabled, setEnabled] = useState(false);
  const [activeEdit, setActiveEdit] = useState(false);
  const {
    changeQueryParams
  } = useQueryParams<{ mode: string }>([{name: "mode"}]);

  const handleChangeTab = (newTab: string) => changeQueryParams([{name: "mode", newValue: newTab}]);
  const {checkAllow} = useBlockAction({
    isLast: stageConditions ? stageConditions.isLast : false,
  });

  const deleteObject = () => {
    dispatch(
      openModal({
        title: "Объект будет откреплен",
        body: "Продолжить?",
        variant: "alert",
        alertIcon: "warning",
        alertButtonSubmit: {
          label: "Удалить заявку",
          onClick: () => {
            if (apiEnabled) {
              checkAllow(onRemoveObject);
            } else {
              onRemoveObject();
            }
            dispatch(closeModal());
          },
        },
        alertButtonDecline: {
          label: "Отменить",
          onClick: () => dispatch(closeModal()),
        },
      })
    );
  };

  const wrapRef = useRef<HTMLDivElement>(null);

  const toggle = () => setActiveEdit(!activeEdit);

  const {openPinObjectModal} = usePinObjectModal({
    title: "Прикрепить объект",
    onChange: onPinObject,
  });

  const pinObject = () => {
    setActiveEdit(false);
    openPinObjectModal();
  };
  useEffect(() => {
    setEnabled(true)
  }, [])
  const onObjectCreate = (data: RealEstate) => {
    onObjectChange(data);
    wrapRef?.current?.scrollIntoView({block: "center", behavior: "smooth"});
    setActiveEdit(false);
  };

  const {executeUrl} = useIsTelegram()

  const handleCardClick = () => {
    if (kind === "pre-applications") {
      handleChangeTab("view")
    } else {
      executeUrl(`/real-estate?tab=AGENCY&preApplicationId=${object?.applicationId}`)
    }
  };

  return (
    <div
      ref={wrapRef}
      className={cn(styles.root, {
        [ styles.rootWithMarginBottom ]: !activeEdit,
      })}
    >
      <H2
        button={
          object &&
          canUpdateOrRemoveObject && (
            <Button
              Icon={Pencil}
              variant="text"
              onClick={openPinObjectModal}
              disabled={stageConditions ? stageConditions.isLast : false}
            >
              Редактировать
            </Button>
          )
        }
      >
        Объект продажи
      </H2>
      {(stageConditions && stageConditions.isNext && <HiddenWidget/>) || (
        <LoadByScroll scrollY={scrollY} isLoading={!enabled} onInView={() => setEnabled(true)} height={250}>
          {!object && (stageConditions ? !stageConditions.isLast : true) && (
            <div className={styles.buttons}>
              {withCreate && defaultValues && (
                <BigButton Icon={Plus} onClick={toggle}>
                  Создать
                </BigButton>
              )}
              <BigButton Icon={Clip} onClick={pinObject}>
                Прикрепить
              </BigButton>
            </div>
          )}
          {activeEdit && defaultValues && !object && (
            <CreateObject
              onSubmit={onObjectCreate}
              defaultValues={defaultValues}
              applicationId={applicationId}
            />
          )}
          {!activeEdit && object && (
            <div className={styles.object}>

              {isMobile
                ? <CardObjectMini
                  id={object.id}
                  objectData={object}
                  isHovered
                  withPrice
                />

                : <CardObject
                  id={object.id}
                  clientObject={object}
                  onCardClick={handleCardClick}
                  variant="standard"
                  classNames={{
                    owner: styles.owner
                  }}
                />}
              {canUpdateOrRemoveObject && (
                <div onClick={deleteObject} className={styles.close}>
                  <Close/>
                </div>
              )}
            </div>
          )}
        </LoadByScroll>
      )}
    </div>
  );
};
