import { useMutation } from "react-query";
import { AxiosError } from "axios";

import { api } from "../../index";
import { axiosError } from "../../../../utils/errors";

const DEFAULT_MN = "sdelka/office/delete-office/DEFAULT_MN";

type UseOfficeDelete = {
	onSuccess?: () => void;
	setError: (error: string) => void;
};

export const useOfficeDelete = ({ onSuccess, setError }: UseOfficeDelete) => {
	const onError = (error: AxiosError) => setError(axiosError({ error }));

	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (id: string) => api.offices.delete(id),
		onSuccess,
		onError,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
