import axios from "axios";
import Cookies from "js-cookie";

import { ErrorCodeEnum } from "../../types";
import { toastError } from "../../components/UI";
import { config } from "./config";

export const AccessTokenAllUsers = "sdelka_access_token_all_users";
export const AccessTokenCurrentUser = "sdelka_access_token_current_user";
export const TokensParentUser = "sdelka_token_parent_user";
export type UserType = "estateAgencyUser" | "singleUser";

const toastApiError = (errorObject: {
  errorCode: string;
  payload?: Record<string, string | number>;
  customMessage?: string;
}): void => {
  const {errorCode, customMessage, payload = {}} = errorObject;
  if (!errorCode) return;

  const errorIsCustom = errorCode === "000";
  const canShowCustomError = errorIsCustom && customMessage;

  if (canShowCustomError) {
    toastError({text: customMessage, autoClose: false});

    return;
  }

  let errorToShow: string = ErrorCodeEnum[ errorCode ] ?? "";

  // format error message
  Object.entries(payload).forEach(([key, value]) => {
    errorToShow = errorToShow.replaceAll(`{${key}}`, value.toString());
  });

  if (errorToShow) {
    toastError({text: errorToShow, autoClose: false});
  }
};

const Client = axios.create({
  baseURL: config.API_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${Cookies.get(AccessTokenCurrentUser)}`,
  },
});

Client.interceptors.response.use(
  (response) => {
		console.log(`status: ${response.status}`, `url: ${response.config.url}`)
    return response
  },
  (err) => {
    const {
      response: {
        data: {error},
      },
    } = err;
    console.log(err)
    if (Array.isArray(error)) {
      error.forEach(toastApiError);
    } else {
      toastApiError(error);
    }
    console.error(`${err} ${err.stack}`)
    return Promise.reject(err);
  }
);

export { Client };
