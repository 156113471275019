import { useQueryParams } from "../../../../../utils/hooks";
import { useDispatch } from "react-redux";
import { FixedNavVerticalMenu } from "../../../../../components/UI";
import { openDrawer, openDrawerInDrawer } from "../../../../redux/modules/drawer/drawer.action";
import { selectDevice, useSelect } from "../../../../redux/selectors";
import { ParsedRealEstatePageRender } from "../../../../../components/pages/RealEstatePage";

type Props = {
  atDrawerInDrawer?: boolean
  refetchList?: () => void
}

type OpenParserObject = {
  parsedRealEstateId: string
  onClose?: () => void
  disableClose?: boolean;
  hasMap?: boolean
  classNames?: {
    wrapper?: string;
    rightBlock?: string;
  };
}

export const useOpenParserObject = (
  {
    atDrawerInDrawer,
    refetchList
  }: Props) => {
  const {removeQueryParam} = useQueryParams<{ parserObjectId: string }>([{name: "parserObjectId"}])
  const dispatch = useDispatch()
  const {isMobile} = useSelect(selectDevice)

  const openParserObject = (
    {
      parsedRealEstateId,
      onClose,
      disableClose,
      hasMap,
      classNames
    }: OpenParserObject) => {

    const parserNav = {
      label: "Объект",
      height: 160,
      title: "parser"
    }

    const parserChildren = (
      <ParsedRealEstatePageRender parsedRealEstateId={parsedRealEstateId} refetchList={refetchList}/>
    )

    const onCloseDrawer = () => {
      removeQueryParam("parserObjectId")
    }

    const yNavigation: Omit<FixedNavVerticalMenu, "id" | "changeTab">[] = [parserNav]

    if (atDrawerInDrawer) {
      dispatch(openDrawerInDrawer(
        {
          children: [parserChildren],
          width: 800,
          yNavigation: {
            navigation: yNavigation,
            initialTab: 0
          },
          onClose: () => {
            if (onClose) {
              onClose()
            }
            onCloseDrawer()
          },
        }))
    } else {
      dispatch(openDrawer(
        {
          children: [parserChildren],
          width: 800,
          yNavigation: {
            navigation: yNavigation,
            // initialTab: 0,
          },
          onClose: () => {
            if (onClose) {
              onClose()
            }
            onCloseDrawer()
          },
          disableClose,
          hasMap,
          isMobile,
          classNames,
        }))
    }
  }

  return {
    openParserObject
  }
}