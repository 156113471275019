import styles from "./index.module.scss"
import { ReactComponent as Arrow } from "../../../../../../assets/icons/keyboard_arrow_down.svg";
import { useAddInCartModal } from "../../../../../pages/Servises/cart/helpers/addInCartModal/useAddInCartModal";
import { selectCurrentAccount, selectDevice, useSelect } from "../../../../../../service/redux/selectors";
import { useBuyInOneClick } from "../../../../../../service/api/mutations";
import { CartResponse, ProductTypeEnum } from "../../../../../../types";
import { AxiosResponse } from "axios";

export const AlertPremium = () => {
  const {currentAccount} = useSelect(selectCurrentAccount);
  const {isMobile} = useSelect(selectDevice)

  const {mutate} = useBuyInOneClick({
    onSuccess: (data: AxiosResponse<CartResponse>) => {
      document.location.href = data?.data.paymentUrl ?? ""
    }
  })

  const {openAddInCartModal} = useAddInCartModal({
    type: ProductTypeEnum.employerSubscribtion,
    title: "Выберите срок продления",
    action: mutate,
    buyInOneClick: true,
    isMobile
  })

  return (
    <div className={styles.root}>
      <div>
        {`${!isMobile ? "Вы используете бесплатную версию СДЕЛКА." : "Бесплатная версия СДЕЛКА."}
         ${!isMobile ? "Для получения доступа к дополнительным функциям приобретите Premium аккаунт." : ""}`}
      </div>
      <div className={styles.button} onClick={() => openAddInCartModal([currentAccount?.id ?? ""])}>
        <div>
          Стать Premium пользователем
        </div>
        <Arrow/>
      </div>
    </div>
  )
}