import { useWatch, UseFormSetValue, UseFormRegister, Control } from "react-hook-form";

import { InfoRow, TransparentInput } from "../../../../index";

type Props = {
	name: string;
	label: string;
	register: UseFormRegister<any>;
	setValue: UseFormSetValue<any>;
	control: Control<any>;
	disabled?: boolean;
};

export const TextTemplate = ({ name, label, register, setValue, control, disabled }: Props): JSX.Element => {
	const textWatcher = useWatch({
		control,
		name,
	});

	return (
		<InfoRow aspect={{ label: 8, content: 7.3 }} label={label}>
			<TransparentInput
				placeholder="Не указано"
				register={register}
				name={name}
				withCleanUp={{ setValue }}
				variant="noPadding"
				value={textWatcher || ""}
				disabled={disabled}
			/>
		</InfoRow>
	);
};
