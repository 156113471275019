import { useState, ReactNode, ChangeEvent, ClipboardEvent } from "react";
import { Controller, Control } from "react-hook-form";
import cn from "classnames";
import MaskedInput from "react-text-mask";

import { formatPhoneWithSpace } from "../../../../utils/formatters";
import { AnyInputProps } from "../../../../types";

import { ReactComponent as Error } from "../../../../assets/icons/error.svg";
import styles from "./index.module.scss";

type Props = {
	control: Control<any>;
	type?: "text" | "password";
	hintText?: string;
	prepend?: ReactNode;
	append?: ReactNode;
	autoComplete?: boolean;
	autoSuggest?: boolean;
	mask?: any;
	inputClassName?: string;
	variant?: "small" | "standard" | "smallNoBorder";
	onChange?: (value: string) => void;
	onBlur?: () => void;
	className?: string;
	options?: {
		hideErrorAppend?: boolean;
	};
} & AnyInputProps;

export const TextField = ({
	control,
	name,
	hintText,
	type = "text",
	label,
	placeholder,
	prepend,
	append,
	error,
	autoComplete = true,
	autoSuggest = true,
	mask,
	inputClassName,
	variant = "standard",
	disabled,
	onChange,
	className,
	onBlur,
	options,
}: Props): JSX.Element => {
	const [isFocused, setIsFocused] = useState(false);

	const inputClasses = {
		[styles.inputSmall]: variant === "small",
		[styles.inputSmallNoBorder]: variant === "smallNoBorder",
		[styles.inputWithPrepend]: prepend,
		[styles.inputWithApend]: append || error,
		[styles.inputWithApendAndError]: append && error,
		[styles.inputFocused]: isFocused,
		[styles.inputWithError]: error,
	};

	// eslint-disable-next-line no-nested-ternary
	const autoCompleteType =
		autoComplete && type === "password" ? "on" : autoSuggest === false ? "off" : "new-password";

	return (
		<div className={cn(styles.root, className)}>
			<Controller
				name={name}
				control={control}
				// eslint-disable-next-line sonarjs/cognitive-complexity
				render={({ field }) => {
					const inputProps = {
						autoComplete: autoCompleteType,
						className: cn(styles.input, inputClasses, inputClassName),
						type,
						...field,
						onChange: (event: ChangeEvent<HTMLInputElement>) => {
							field.onChange(event.target.value);

							if (onChange) {
								onChange(event.target.value);
							}
						},
						onPaste: (event: ClipboardEvent) => {
							if (mask && mask()[0] === "+" && mask()[1] === 7) {
								let text: string = event.clipboardData.getData("Text");

								text = text.replace(/\D+/g, "");

								if (text.length > 10) {
									text = text.substring(text.length - 10);
								}

								text = `+${formatPhoneWithSpace(text)}`;

								field.onChange(text);

								if (onChange) {
									onChange(text);
								}
								event.preventDefault();
							}
						},
						onBlur: () => {
							field.onBlur();
							setIsFocused(false);
							if (onBlur) {
								onBlur();
							}
						},
						onFocus: () => setIsFocused(true),
						mask,
						placeholder,
						disabled,
					};

					return (
						<>
							{label && (
								<label
									className={cn([styles.label], {
										[styles.label__paddingLeft]: prepend,
										[styles.label__noValueNoActive]: !field.value && !isFocused,
										[styles.label__withSmallInput]: variant === "small" || variant === "smallNoBorder",
									})}
								>
									{label}
								</label>
							)}

							<div className={styles.textField}>
								{prepend && <div className={styles.prepend}>{prepend}</div>}

								{inputProps.mask ? (
									// eslint-disable-next-line react/jsx-props-no-spreading
									<MaskedInput {...inputProps} />
								) : (
									// eslint-disable-next-line react/jsx-props-no-spreading
									<input {...inputProps} />
								)}

								{((error && !options?.hideErrorAppend) || append) && (
									<div className={styles.append}>
										{/* eslint-disable-next-line sonarjs/no-identical-expressions */}
										{append && append}
										{error && <Error />}
									</div>
								)}

								{hintText && !error && (
									<div className={styles.hintContainer}>
										<div className={styles.hint}>{hintText}</div>
									</div>
								)}

								{error && (
									<div className={styles.errorContainer}>
										<div className={styles.error}>{error}</div>
									</div>
								)}
							</div>
						</>
					);
				}}
			/>
		</div>
	);
};
