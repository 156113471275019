import Cookies from "js-cookie";

import { AccessTokenAllUsers, AccessTokenCurrentUser, Client, TokensParentUser } from "../../Client";
import { useIsTelegram } from "../../../../utils/telegram/useIsTelegram";

const COOKIES_EXPIRES = 180;

export type AllTokens = {
	userId: string;
	token: string;
};

type Tokens = {
	allTokens: AllTokens[];
	current: string;
};

type SetTokensOptions = {
	rememberMe?: boolean;
};

export const TokenService = {
	setTokens: (tokens: Tokens, options?: SetTokensOptions): void => {
		if (options?.rememberMe) {
			// @ts-ignore
			Cookies.set(AccessTokenAllUsers, tokens.allTokens, {
				expires: COOKIES_EXPIRES,
			});
			Cookies.set(AccessTokenCurrentUser, tokens.current, {
				expires: COOKIES_EXPIRES,
			});
			Client.defaults.headers.Authorization = `Bearer ${tokens.current}`;
		}
	},

	setParentTokens: (tokens: Tokens): void => {
		// @ts-ignore
		Cookies.set(TokensParentUser, tokens, { expires: COOKIES_EXPIRES });
	},

	setCurrentToken: (token: string): void => {
		if(!token) {
			return
		}
		Cookies.set(AccessTokenCurrentUser, token, { expires: COOKIES_EXPIRES });
		Client.defaults.headers.Authorization = `Bearer ${token}`;
	},

	getCurrentToken: (): string | undefined => Cookies.get(AccessTokenCurrentUser),

	getTokens: (): Tokens | undefined => {
		const tokens = Cookies.get(AccessTokenAllUsers);
		const currentToken = Cookies.get(AccessTokenCurrentUser);

		if (!tokens || !currentToken) {
			return undefined;
		}

		const parsedTokens: AllTokens[] | undefined = JSON.parse(tokens);

		if (!parsedTokens || !currentToken) {
			return undefined;
		}

		return { allTokens: parsedTokens, current: currentToken };
	},

	getParentTokens: (): Tokens | undefined => {
		const tokens = Cookies.get(TokensParentUser);

		if (!tokens) {
			return undefined;
		}

		const parsedTokens: Tokens | undefined = JSON.parse(tokens);

		if (!parsedTokens) {
			return undefined;
		}

		return { ...parsedTokens };
	},

	changeCurrentToken: (userId: string, urlToOpen?: string): void => {
		const tokens = TokenService.getTokens();
		if (tokens) {
			const { allTokens } = tokens;

			const newCurrentToken = allTokens?.find((token) => token.userId === userId);
			if (newCurrentToken) {
				Cookies.set(AccessTokenCurrentUser, newCurrentToken.token, {
					expires: COOKIES_EXPIRES,
				});
				Client.defaults.headers.Authorization = `Bearer ${newCurrentToken.token}`;

				window.open(urlToOpen || "/", "_self");
			}
		}
	},

	removeTokens: (tokensToRemove: "all" | "parent" = "all"): void => {
		Cookies.remove(TokensParentUser);

		if (tokensToRemove === "all") {
			Cookies.remove(AccessTokenAllUsers);
			Cookies.remove(AccessTokenCurrentUser);
		}
	},
};
