import { ApplicationTemplate, ApplicationCreatePayload, ApplicationCardResponse } from "../../../../types";
import { Client } from "../../Client";

export const PreApplicationTemplateService = {
	create: ({ leadId, businessProcessId, category }: ApplicationCreatePayload) =>
		Client.post("/pre-application/", {
			leadId,
			businessProcessId,
			category,
		}),

	load: ({ applicationId }: { applicationId: string }) =>
		Client.get<ApplicationCardResponse>(`/pre-application/${applicationId}`),
	arhive: ({ applicationId }: { applicationId: string }) =>
	Client.post<ApplicationCardResponse>(`/pre-application/${applicationId}/unsuccess`),
	finishSuccess: ({ applicationId }: { applicationId: string }) =>
	Client.post<ApplicationCardResponse>(`/pre-application/${applicationId}/success`),
	delete: ({ applicationId }: { applicationId: string }) =>
		Client.delete(`/application-template/${applicationId}`),

	getQuiz: ({ typeId }: { typeId: string }) =>
		Client.get(`application-template/type/${typeId}/blockData/quiz`),
};