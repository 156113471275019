import { LeadStatusEnum } from "../../../../types";

export const getStatusDescription = (status: LeadStatusEnum): {color: string, label: string} => {
  switch (status) {
    case LeadStatusEnum.new:
      return {color: "#FF575F", label: "Необработанный"}
    case LeadStatusEnum.free:
      return {color: "#21BC5F", label: "Свободный"}
    case LeadStatusEnum.in_progress:
      return {color: "#124BF6", label: "В работе"}
    case LeadStatusEnum.deferred:
      return {color: "#7F8DEF", label: "Замороженный"}
    case LeadStatusEnum.done:
      return {color: "#ADB4D6", label: "Отработанный"}
    default:
      return {color: "", label: ""}
  }
}