import { useEffect, useMemo, useState } from "react";
import { Column, ColumnInstance, useColumnOrder, useSortBy, useTable } from "react-table";
import cn from "classnames";
import RSC from "react-scrollbars-custom"
import { ColumnsSetting } from "./ColumnsSetting";
import { ReactComponent as ArrowIcon } from "../../../../assets/icons/arrow.svg";
import { ReactComponent as Pin } from "../../../../assets/icons/iconPin.svg";
import { ReactComponent as Left } from "../../../../assets/icons/shevron-left.svg";
import { ReactComponent as Right } from "../../../../assets/icons/shevron-right.svg";
import styles from "./index.module.scss";

type Props = {
  columns: Column[];
  data: any;
  isFirstColumnFixed: boolean;
  heighBodyRow?: number;
  isLoading?: boolean
  setOrderBy?: React.Dispatch<React.SetStateAction<string>>
  setDirection?: React.Dispatch<React.SetStateAction<"ASC" | "DESC">>
  direction?: "ASC" | "DESC"
  orderBy?: string
};

export const TableCustomizable = (
  {
    columns,
    data,
    isFirstColumnFixed,
    isLoading,
    setDirection,
    setOrderBy,
    direction,
    orderBy
  }: Props): JSX.Element => {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    setColumnOrder,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    useColumnOrder,
  );

  const heighRow = useMemo(() => data.length * 7, [data.length]);

  const [selected, setSelected] = useState("")
  const handleSelectRow = (id: string) => {
    if (selected === id) {
      setSelected("")
    } else {
      setSelected(id)
    }
  }

  const [fixedColumns, setFixedColumns] = useState<ColumnInstance<object>[]>([])
  const [actualCols, setActualCols] = useState<ColumnInstance<object>[]>(allColumns)

  const handleFixColumn = () => {
    const col = allColumns.shift()

    if (fixedColumns.length > 0) {
      setFixedColumns([])
      allColumns.unshift(col as any)
      setActualCols(allColumns)
    } else {
      setFixedColumns([col as any])
      const filteredCols = allColumns.slice(0, allColumns.length)
      setActualCols(filteredCols)
    }
  }

  const [xCoords, setXCoords] = useState(0)
  const [showVerticalPlug, setShowVerticalPlug] = useState(true)
  const [elem, setElem] = useState<HTMLElement | null>(null)
  const plug = document.getElementById("plugVertical")

  useEffect(() => {
    const table = document.getElementById("tableCustomizable")
    setElem(table)
  }, [])

  const handleScrollHorizontal = (to: number) => {
    setXCoords(to)
  }

  return (
    <>
      <ColumnsSetting data={data} allColumns={actualCols} changeColumnOrder={setColumnOrder}/>
      <div className={styles.wrapper} id="tableCustomizableWrapper">
        <RSC trackXProps={{className: styles.ScrollbarsCustomTrackX}} scrollLeft={xCoords}
             onScrollStop={(scrollValues) => {
               if (scrollValues.scrollLeft >= 200) {
                 plug?.classList.add(styles.displayNone)
               }
               if (scrollValues.scrollLeft < 100) {
                 plug?.classList.remove(styles.displayNone)
               }

             }}>
          <div className={styles.tableWrapper}>
            <div>
              <table
                id="tableCustomizable"
                className={cn(styles.table, {
                  [ styles.fixedFirstColumn ]: isFirstColumnFixed,
                  [ styles.tablePlug ]: isLoading
                })}
                {...getTableProps()}
              >
                {!isLoading &&
                  <thead className={cn(styles.header,)}>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}
                        className={cn({[ styles.fixedHeader ]: fixedColumns.length > 0})}>
                      {headerGroup.headers.map((column) => (
                        <th
                          className={styles.headerTh}
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          onClick={() => {
                            if (setOrderBy) {
                              setOrderBy(column.id)
                            }
                            if (setDirection) {
                              setDirection(direction === "ASC" ? "DESC" : "ASC")
                            }
                          }}
                        >
                          <div className={styles.headerThContent}>
                            {column.render("Header")}
                            <span>
												{column.sortable ? (
                          <ArrowIcon
                            className={cn(styles.arrowIcon, {
                              [ styles.arrowIconAsc ]: orderBy === column.id && direction === "ASC",
                              [ styles.arrowIconDesc ]: orderBy === column.id && direction !== "ASC",
                            })}
                          />
                        ) : (
                          ""
                        )}
											</span>
                            {column.id === columns[ 0 ].id && <Pin onClick={(event) => {
                              event.preventDefault()
                              handleFixColumn()
                            }} className={styles.svg}/>}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                  </thead>
                }
                {isLoading &&
                  <thead>
                  <tr>
                    <th className={styles.plugThContent}/>
                  </tr>
                  </thead>
                }
                {!isLoading &&
                  <>
                    <tbody {...getTableBodyProps()} style={{height: `${heighRow}px`}}>
                    {rows.slice(0, rows.length - 1).map((row) => {
                      prepareRow(row);

                      return (
                        <tr
                          className={cn(styles.row, {[ styles.fixed ]: fixedColumns.length > 0})} {...row.getRowProps()}
                          onClick={() => handleSelectRow(row.id)}>
                          {row.cells.map((cell) => (
                            <td className={styles.td} {...cell.getCellProps()}>
                              <div className={styles.tdContent}>
                                {cell.column.customTemplate ? cell.column.customTemplate(cell) : cell.render("Cell")}
                              </div>
                            </td>
                          ))}
                          {selected === row.id && <div className={styles.rowSelected}/>}
                        </tr>
                      );
                    })
                    }
                    </tbody>
                    {rows.slice(rows.length - 1).map((row) => {
                      prepareRow(row);
                      return (
                        <tfoot className={styles.footer}>
                        <tr
                          className={cn(styles.row, {[ styles.fixed ]: fixedColumns.length > 0})} {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td className={styles.td} {...cell.getCellProps()}>
                              <div className={styles.tdContent}>
                                {cell.column.customTemplate ? cell.column.customTemplate(cell) : cell.render("Cell")}
                              </div>
                            </td>
                          ))}
                        </tr>
                        </tfoot>
                      );
                    })}
                  </>
                }
                {isLoading &&
                  <tbody>
                  <tr>
                    <td className={styles.plugTdContent}>Идет загрузка</td>
                  </tr>
                  </tbody>
                }
              </table>
            </div>
          </div>
        </RSC>
        <div className={styles.buttonscroll}>
          <div onClick={() => {
            handleScrollHorizontal(0)
            setShowVerticalPlug(true)
          }}>
            <Left/>
          </div>
          <div onClick={() => {
            handleScrollHorizontal(elem?.scrollWidth || 0)
            setShowVerticalPlug(false)
          }}>
            <Right/>
          </div>
        </div>
        {showVerticalPlug && <div id="plugVertical" className={styles.plugVertical}/>}
        {fixedColumns.length > 0 && <div className={styles.fixedPlugVertical}/>}
      </div>
    </>
  );
};
