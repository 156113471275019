import styles from "./index.module.scss"
import { useLoadOffices } from "../../../../../service/api/queries";
import { useEffect, useState } from "react";
import { SearchInput } from "../../../../UI/collections-modal/add-to-collection/helpers";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { CardOffice } from "../../../../UI";

type Props = {
  onSelect: (id: string | undefined) => void
  title: string
}

export const SelectOfficeModal = ({onSelect, title}: Props): JSX.Element => {
  const {offices} = useLoadOffices({});
  const [selectedOfficeId, setSelectedOfficeId] = useState<string | undefined>(undefined)
  const [searchValue, setSearchValue] = useState("")
  const [filteredOffices, setFilteredOffices] = useState(offices)

  const handleSearchChange = (value: string) => {
    setSearchValue(value)
  }

  const handleSelect = (id: string | undefined) => {
    setSelectedOfficeId(id)
  }

  useEffect(() => {
    const filter = offices.filter((office) => (office.name.includes(searchValue) || office.addressLabel.includes(searchValue)))
    setFilteredOffices(filter)
  }, [offices, searchValue])


  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.search}>
        <SearchInput
          name="Поиск"
          placeholder="Поиск"
          prepend="search"
          onChange={handleSearchChange}
        />
      </div>
      <div className={styles.col}>
        {filteredOffices.map((office) => (

            <CardOffice
              office={office}
              onCardClick={handleSelect}
              selected={selectedOfficeId === office.id}
            />

        ))}
      </div>


      <div className={styles.footer}>
        <Button onClick={() => onSelect(selectedOfficeId)}>
          Подтвердить
        </Button>
      </div>
    </div>
  )
}