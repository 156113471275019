import styles from "./index.module.scss"
import {
  useLoadGeneratedDocuments,
  useLoadGeneratedDocumentsTypes,
  useLoadGeneratedDocumentsWarnings
} from "../../../../service/api/queries/deals";
import { useEffect, useState } from "react";
import { InfoRow } from "../../InfoRow";
import { useForm } from "react-hook-form";
import { Select } from "../../Form";
import { SelectOption } from "../../../../types";
import { useGenerateDocument, useGetGeneratedDocumentPreview } from "../../../../service/api/mutations";
import { ReactComponent as IconFolder } from "../../../../assets/icons/documentFolder.svg";

import { ReactComponent as IconDownload } from "../../../../assets/icons/iconDownload20.svg";
import { ReactComponent as IconFile } from "../../../../assets/icons/iconFileSmall.svg";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useIsTelegram } from "../../../../utils/telegram/useIsTelegram";

type Props = {
  applicationId: string
}

export const DocumentGeneration = (
  {
    applicationId,
  }: Props) => {

  const {types, templates} = useLoadGeneratedDocumentsTypes({applicationId})
  const selectableTypes: SelectOption[] = types?.map(el => JSON.parse(JSON.stringify({value: el, label: el}))) ?? []
  const selectableTemplates: SelectOption[] = templates?.map(el => JSON.parse(JSON.stringify({
    value: el,
    label: el
  }))) ?? []

  const {register, setValue} = useForm()
  const [type, setType] = useState()
  const [template, setTemplate] = useState()
  const {warnings, refetch: refetchWarnings} = useLoadGeneratedDocumentsWarnings({applicationId, type, template})

  const {documents, refetchDocuments} = useLoadGeneratedDocuments({applicationId})

  const {getPreviewAsync} = useGetGeneratedDocumentPreview({})

  const onSuccessGenerate = () => {
    refetchDocuments()
  }

  const {generateDocument} = useGenerateDocument({onSuccess: onSuccessGenerate})

  const onPreview = async () => {
    const file = await getPreviewAsync({id: applicationId, type: type ?? "", template: template ?? ""})

    const pdfWindow = window.open("")
    // @ts-ignore
    pdfWindow.document.write(
      `<iframe width='100%' height='100%' src='data:application/pdf;base64, ${
        encodeURI(file.data)}'></iframe>`
    )
  }
  const {executeUrl} = useIsTelegram()
  const handleDownload = (url: string) => {
    executeUrl(`${url}`)
  }

  const handleGenerateDocument = () => {
    generateDocument({id: applicationId, type: type ?? "", template: template ?? ""})
  }

  const handleChangeType = (value) => {
    setType(value)
  }
  const handleChangeTemplate = (value) => {
    setTemplate(value)
  }

  useEffect(() => {
    refetchWarnings()
  }, [type, template])

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        Генерация документов
      </div>
      <div className={styles.content}>
        {warnings && warnings.length > 0 &&
          <div className={styles.alert}>
            <div className={styles.alertTitle}>
              Предупреждение:
            </div>
            <div className={styles.alertText}>
              {`Не заполнены поля: ${warnings.join(", ")}`}
            </div>
          </div>
        }
        <InfoRow label="Тип документа">
          <Select
            options={selectableTypes}
            name="type"
            register={register}
            setValue={setValue}
            onChange={(value) => handleChangeType(value)}
            classNames={{
              selectListWrapper: styles.selectWrapper
            }}
          />
        </InfoRow>
        <InfoRow label="Шаблон">
          <Select
            options={selectableTemplates}
            name="template"
            register={register}
            setValue={setValue}
            onChange={(value) => handleChangeTemplate(value)}
            classNames={{
              selectListWrapper: styles.selectWrapper
            }}
          />
        </InfoRow>
        <div>
          <div className={styles.fileContainer}>
            <div className={styles.fileRow}>
              <div className={styles.fileRow}>
                <div className={styles.counterWrapper}>
                  <div className={styles.counter}>{documents?.length ?? 0}</div>
                  <IconFolder/>
                </div>
                <div className={styles.fileRowTitle}>Сгенерированные документы</div>
              </div>
            </div>
            <div className={styles.filesList}>
              {documents && documents.map((item) => (
                <div className={styles.fileItem} key={item.url}>
                  <div className={styles.fileRow}>
                    <IconFile style={{marginRight: "10px"}}/>
                    <span>
                        {item.name}
                      </span>
                    {item.isNew &&
                      <div className={styles.fileItemNew}>
                        Новый
                      </div>
                    }
                  </div>
                  <IconDownload onClick={() => handleDownload(item.url)}/>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.rowEnd}>
          {warnings && warnings.length === 0 &&
            <div className={styles.preview} onClick={onPreview}>
              Предпросмотр
            </div>}
          <Button onClick={handleGenerateDocument} disabled={warnings && warnings.length > 0}>
            Сгенерировать
          </Button>
        </div>
      </div>
    </div>
  )
}