import { useState } from "react";
import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/contact/findContactByPhone/DEFAULT_QN";

type Props = {
	defaultPhone?: string;
	enabled?: boolean;
};

export const useFindContactByPhone = ({ defaultPhone = "", enabled = true }: Props) => {
	const [phone, setPhone] = useState(defaultPhone);

	const fetch = () => api.contacts.findByPhone(phone);

	const { data, isFetching, remove } = useQuery([DEFAULT_QN, phone], fetch, {
		enabled,
	});

	return {
		data: data?.data,
		isFetching,
		setPhone,
		remove,
	};
};
