import { RealEstateListPayload, RealEstateListResponse } from "../../../../types";

import { Client } from "../../Client";
import { createListFieldsPayload } from "../../../../utils/clientObjects";

export const ParsedRealEstateService = {
	list: ({
		take,
		skip,
		sort,
		search,
		join,
		absoluteSearchConditions = [],
	}: Omit<RealEstateListPayload, "onlyCount">) => {
		const { searchConditions, sortConditions, joinConditions } = createListFieldsPayload({
			sort,
			search,
			join,
			absoluteSearchConditions,
		});

		return Client.post<RealEstateListResponse>(
			"/estateObjectStore/search",
			JSON.stringify({
				search: {
					filterType: "and",
					conditions: searchConditions,
				},
				join: joinConditions,
				take,
				skip,
				sort: sortConditions,
			})
		);
	},

	count: ({
		take,
		skip,
		sort,
		search,
		join,
		absoluteSearchConditions = [],
	}: Omit<RealEstateListPayload, "onlyCount">) => {
		const { searchConditions, sortConditions, joinConditions } = createListFieldsPayload({
			sort,
			search,
			join,
			absoluteSearchConditions,
		});

		return Client.post<number>(
			"/estateObjectStore/count",
			JSON.stringify({
				search: {
					filterType: "and",
					conditions: searchConditions,
				},
				join: joinConditions,
				take,
				skip,
				sort: sortConditions,
			})
		);
	},

	similarList: ({ id, take }: { id: string } & Pick<RealEstateListPayload, "take">) =>
		Client.post<RealEstateListResponse>(
			`/estateObjectStore/${id}/similar`,
			JSON.stringify({
				take,
			})
		),

	duplicatesList: ({ id, take }: { id: string } & Pick<RealEstateListPayload, "take">) =>
		Client.post<RealEstateListResponse>(
			`/estateObjectStore/${id}/duplicates`,
			JSON.stringify({
				take,
			})
		),
};
