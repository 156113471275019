import { useMutation } from "react-query";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/pre-application//agent-agreement/get/DEFAULT_MN";

export const useGetAgencyAgrement = (applicationId: string) => {
  const mutation = useMutation(`${DEFAULT_MN}${applicationId}`, {
    mutationFn: () => api.PreApplicationService.getAgencyAgrement({applicationId}),
  });

  return {
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
    data: mutation.data,
    asyncData: mutation.mutateAsync,
    error: mutation.error,
    mutation
  };
};

