import { UseFormHandleSubmit } from "react-hook-form";
import cn from "classnames";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { ObjectEditLayout } from "./ObjectEditLayout";
import { FormPropsEditRealEstate, RealEstatePhoto, RealEstateSchema } from "../../../../types";
import styles from "./index.module.scss";

type Props = {
  id: string;
  objectConfig?: RealEstateSchema;
  isMutationLoading: boolean;
  form: FormPropsEditRealEstate;
  onSubmit: (data: any) => void;
  isNewMod?: boolean;
  handleSubmit: UseFormHandleSubmit<any>;
  photos?: RealEstatePhoto[],
  setPhotos?: React.Dispatch<React.SetStateAction<RealEstatePhoto[]>>
};

export const RealEstateForm = (
  {
    id,
    isMutationLoading,
    form,
    objectConfig,
    onSubmit,
    isNewMod,
    handleSubmit,
    photos,
    setPhotos
  }: Props): JSX.Element => {
  const {
    formState: {errors},
  } = form;
  const showFields = objectConfig;

  const DISABLE_SUBMIT = isMutationLoading || !!Object.keys(errors).length;

  return (
    <div>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        {showFields && (
          <div className={cn({"animate__animated animate__fadeInUp": isNewMod})}>
            <ObjectEditLayout
              id={id}
              schema={objectConfig as RealEstateSchema}
              form={form}
              photos={photos}
              setPhotos={setPhotos}
              isCreation={isNewMod}
            />
          </div>
        )}
        {showFields && (
          <div className={styles.save}>
            <Button type="submit" disabled={DISABLE_SUBMIT}>
              Сохранить объект
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};
