import { useMutation } from "react-query";
import { api } from "../../index";

const DEFAULT_MN = "registration/sdelkaPro-select-address/DEFAULT_MN";

export const useSelectAddress = () => {
  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: (id: string) =>
      api.offices.setAddressId(id),
  });

  return {
    data: mutation.data,
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
    mutation,
    asyncMutate: mutation.mutateAsync,
    error: mutation.error,
  };
};
