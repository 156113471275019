import { UseFormSetError } from "react-hook-form";
import { AxiosError } from "axios";
import { useMutation } from "react-query";

import { CompanyCreateAccession } from "../../../../types";
import { api } from "../../index";
import { axiosError } from "../../../../utils/errors";

const DEFAULT_MN = "sdelka/auth/auth-accession/DEFAULT_MN";

type UseAuthAccession = {
	setError?: UseFormSetError<any>;
	onSuccess?: () => void;
};

export const useAuthAccession = ({ setError, onSuccess }: UseAuthAccession) => {
	const onError = (error: AxiosError) => {
		const message = axiosError({ error });

		if (message) {
			if (message.includes("Company not found!") && setError) {
				setError("shortCompanyId", { message: "Компания не найдена!" });
			} else if (setError) {
				setError("shortCompanyId", {
					message: `Неизвестная ошибка ${message?.toString()}`,
				});
			}
		}
	};

	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (data: CompanyCreateAccession) => api.company.authAccession(data),
		onError,
		onSuccess,
	});

	return {
		mutateAsync: mutation.mutateAsync,
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
