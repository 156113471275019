import { useState } from "react";

import { TaskUserRole } from "../../../../types";

export const useRole = () => {
	const [role, setRole] = useState<TaskUserRole>("executor");

	return {
		role,
		setRole,
	} as const;
};
