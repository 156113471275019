import { MouseEventHandler, useMemo, useState } from "react";
import { ApplicationInListV2, KindType } from "../../../../types";
import cn from "classnames";
import styles from "./index.module.scss";
import { ReactComponent as BuildingsIcon } from "../../../../assets/icons/buildings.svg";
import { InfoIconBlock } from "./infoIconBlock/InfoIconBlock";
import { getPriceLabel } from "../../../../utils/clientObjects";
import { CardApplicationContact } from "./card-application-contact/CardApplicationContact";
import { CardApplicationResponsible } from "./card-application-responsible/CardApplicationResponsible";
import { Popover } from "../../Popover";
import { ReactComponent as OpenInNew } from "../../../../assets/icons/open-in-new.svg";

type Props = {
  withStage?: boolean;
  application: ApplicationInListV2
  inCard?: boolean
  kind?: KindType
  canOpenANewTab?: boolean
};

export function CardApplication({withStage, application, inCard, canOpenANewTab, kind}: Props): JSX.Element {

  const {
    addressLabel,
    contact,
    icons,
    mainPhoto,
    title,
    price,
    priceRange,
    stage,
    stageNo,
    responsibleUser,
  } = application
  const [isImageError, setIsImageError] = useState(false);
  const priceRangeFrom = priceRange?.from
  const priceRangeTo = priceRange?.to

  const getColor = useMemo(() => {
    switch (kind ?? application.shortType.kind) {
      case 'preApplication':
        return "#D5D3CE";
      case  'application':
        return "#30CFB1"
      case 'deal':
        return "#336BEB"
      case "successDeal":
        return `${styles.linear}`
      default:
        return "#000"
    }
  }, [application]);

  const getColorTitle = useMemo(() => {
    switch (kind ?? application.shortType.kind) {
      case 'preApplication':
        return "#9A9589";
      case  'application':
        return "#0FB092"
      case 'deal':
        return "#336BEB"
      case "successDeal":
        return `${styles.linear}`
      default:
        return "#000"
    }
  }, [application]);

  const onImageError = () => setIsImageError(true);

  const handleOpenInNewWindow: MouseEventHandler = (e) => {
    e.stopPropagation();

    // когда будет сделка - добавить сюда открытие
    // handleOpenApplicationNewTab(id);
  };

  return (
    <div className={cn(styles.flexRow, styles.applicationFlexRow, {[ styles.rootGray ]: inCard})}>
      <div className={cn(styles.img)}>
        {mainPhoto && !isImageError ? (
          <img onError={onImageError} alt="object" src={mainPhoto}/>
        ) : (
          <div className={styles.noImg}>
            <BuildingsIcon/>
          </div>
        )}
      </div>
      <div className={cn(styles.content)}>
        <div className={cn(styles.titleAndPrice, styles.flexRow)}>
          <div>
            <div className={cn(styles.flexRow)}>
              <div className={styles.title}>
                <span>{title}</span>
              </div>
              <div>
                <InfoIconBlock icons={icons}/>
              </div>
            </div>
            <div className={cn(styles.flexRow, styles.flexRowAlignStart, styles.address)}>
              <div className={styles.addressLabel}>{addressLabel}</div>
            </div>
          </div>
          <div className={styles.flexRow}>
            <div className={styles.price}>
              {price && getPriceLabel({price})}
              {priceRange &&
                <span>
                    {getPriceLabel({priceRangeFrom})} <br/>
                  {getPriceLabel({priceRangeTo})}
              </span>
              }
            </div>
            {/* <PopupMenu */}
            {/*  contextClick={contextClick === id} */}
            {/*  classNames={{ */}
            {/*    wrapper: styles.popupWrapper, */}
            {/*  }} */}
            {/*  options={{primaryOpen: "left"}} */}
            {/*  menuItems={menu} */}
            {/*  source="application" */}
            {/* /> */}
          </div>
        </div>
        <div className={cn(styles.flexBetween, styles.flexRow)}>
          <CardApplicationContact contact={contact}/>
          <CardApplicationResponsible responsible={responsibleUser}/>
        </div>
      </div>
      <div style={{background: getColor}} className={styles.stage}>
          {canOpenANewTab &&
            <div className={styles.openInNew} onClick={handleOpenInNewWindow}>
              <OpenInNew/>
            </div>
          }
          <Popover
            classNames={{over: styles.stagePopup}}
            overElement={
              <>
								<span className={styles.stageCircle} style={{background: getColor}}>
									{stageNo}
								</span>
                {`Этап ${stageNo}: ${stage}`}
              </>
            }
          />
        </div>
    </div>
  )
}
