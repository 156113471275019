import { Button, Tooltip } from "@sdelka_crm/sdelka-crm-component-library";

import { Applied, FeedPaidData, Limits, PaidServicesType } from "../../../../../../../../../../types";
import { AdStatus } from "../../../../../../../../../UI";
import {
	conditionHelper,
	ConditionHelperType,
} from "../../../../../../../../../../utils/ads/conditionHelper";

import styles from "./index.module.scss";

const buttonStatusMap = {
	delete: "Отключить",
	apply: "Применить",
	unavailable: "Недоступна",
};

type ButtonConditionColors = "blue" | "red" | "grey";

const checkTime: (applied: Applied) => JSX.Element = (applied) => (
	<AdStatus
		classNames={{ root: styles.servicesStatus }}
		startDate={applied?.startDate}
		endDate={applied?.endDate}
	/>
);

const showLimits: (limits: Limits) => JSX.Element = (limits) => (
	<>
		<span>
			{limits?.remainder ? `Осталось применений ${limits?.remainder}/${limits?.limit}` : "Лимит исчерпан"}
		</span>
		<Tooltip classNames={{ root: styles.statusTooltipText }} iconType="info">
			<div>Ваш руководитель поставил ограничение в размещении рекламы. Пакет размещений обновится: </div>
			<div>22.04.21</div>
		</Tooltip>
	</>
);

export const usePaidServicesTableData = (
	data: PaidServicesType[],
	onClick: (paidData: FeedPaidData) => void
) =>
	data
		? data.map((item, index) => {
				const limits = item?.limits as Limits;
				const buttonCondition: ConditionHelperType = conditionHelper(
					item,
					"red",
					!limits?.remainder ? "grey" : "blue",
					"blue"
				);
				const buttonTextCondition: ConditionHelperType = conditionHelper(
					item,
					"delete",
					!limits?.remainder ? "unavailable" : "apply",
					"apply"
				);

				const onButtonClick = () =>
					onClick({
						tariff: item.name,
						status: buttonTextCondition as "apply" | "delete",
					});

				return {
					tariff: (
						<div className={styles.tariff}>
							<div className={styles.tariffContainer}>
								<Tooltip classNames={{ root: styles.tooltip }}>{item.tooltip}</Tooltip>

								<div className={styles.info} dangerouslySetInnerHTML={{ __html: item.label }} />
							</div>
						</div>
					),
					status: (
						<div className={styles.adEditLimit}>
							{conditionHelper(
								item,
								checkTime(item?.applied as Applied),
								showLimits(limits),
								<span>Не применено</span>
							)}
						</div>
					),
					price: (
						<div className={styles.priceContainer}>
							<p>{item.price ? `${item.price} ₽` : "Неизвестно"}</p>
						</div>
					),
					action: (
						<Button
							disabled={buttonCondition === "grey"}
							color={buttonCondition as ButtonConditionColors}
							variant="text"
							onClick={onButtonClick}
						>
							{buttonStatusMap[buttonTextCondition as string]}
						</Button>
					),
					name: item.name,
					index,
				};
		  })
		: [];
