import { useDispatch } from "react-redux";
import { openModal } from "../../../../../service/redux/modules/modal/modal.action";
import { AddToDepartmentModal } from "./AddToDepartmentModal";
import styles from "./index.module.scss"

export const useAddToDepartmentModal = () => {
  const dispatch = useDispatch()

  const handleAddToDepartmentModal = (departmentId: string, isMobile?: boolean) => {
    dispatch(
      openModal({
        body: <AddToDepartmentModal departmentId={departmentId}/>,
        width: isMobile ? 500 : 660,
        classNames: {
          contentWrapper: isMobile ? styles.modal : "",
          buttonClose: isMobile ? styles.modalClose : ""
        }
      })
    )
  }
  return {handleAddToDepartmentModal}
}