import { useMutation } from "react-query";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/department/delete-department/DEFAULT_MN";

type UseDepartmentDelete = {
	onSuccess?: () => void;
	onError?: (id: string) => void;
};

export const useDepartmentDelete = ({ onSuccess, onError }: UseDepartmentDelete) => {
	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: (id: string) => api.departments.delete(id),
		onSuccess,
		onError,
	});

	return {
		mutate: mutate.mutate,
		isLoading: mutate.isLoading,
	};
};
