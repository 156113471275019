import { Client } from "../../Client";
import {
  MyShowsViewsResponse,
  PlatformDescriptionIdData,
  ShowAndViewsTabs,
  ShowsApplicationCardResponse,
  ShowsOrViews, ShowsViews,
  ShowsViewsCancelPayload,
  ShowsViewsCountPayload,
  ShowsViewsFinishPayload,
  ShowsViewsListResponse,
  ShowsViewsStartPayload,
  ShowsViewsStatisticPayload, ShowsViewsStatisticResponse,
  ShowsViewsUpdataPayload,
} from "../../../../types";

export const ShowsViewsService = {
  listShows: (applicationId: string, tab: ShowAndViewsTabs) =>
    Client.get<ShowsViewsListResponse>(`/applications/${applicationId}/shows?tab=${tab}`),

  listViews: (applicationId: string, tab: ShowAndViewsTabs) =>
    Client.get<ShowsViewsListResponse>(`/applications/${applicationId}/views?tab=${tab}`),

  myList: (date: Date) => Client.get<MyShowsViewsResponse[]>(`/show-and-views/my?day=${date}`),

  newDateShow: (payload: { id: string; plannedDate: Date }) => {
    const {id, plannedDate} = payload;

    return Client.patch(`/show/${id}/date`, {plannedDate});
  },

  newDateView: (payload: { id: string; plannedDate: Date }) => {
    const {id, plannedDate} = payload;

    return Client.patch(`/view/${id}/date`, {plannedDate});
  },

  update: (payload: ShowsViewsUpdataPayload) => {
    const {id, type} = payload;
    const requestPayload = {
      clientObjectId: payload.clientObjectId,
      sellerContactId: payload.sellerContactId,
      sellerAgentContactId: payload.sellerAgentContactId,
      buyerContactId: payload.buyerContactId,
      buyerAgentContactId: payload.buyerAgentContactId,
      plannedDate: payload.plannedDate,
    };

    return Client.put(`/show-and-views/${type}/${id}`, requestPayload);
  },

  cancelShow: (id: string) => Client.post(`/show/${id}/cancel`),

  cancelView: (id: string) => Client.post(`/view/${id}/cancel`),

  start: (payload: ShowsViewsStartPayload & { clientObjectApplicationId: string }) => {
    const {type, applicationId, clientObjectApplicationId} = payload;
    const requestPayload = {
      clientObjectApplicationId,
      sellerContactId: payload.sellerContactId,
      sellerAgentContactId: payload.sellerAgentContactId,
      buyerContactId: payload.buyerContactId,
      buyerAgentContactId: payload.buyerAgentContactId,
      plannedDate: payload.plannedDate,
    };

    return Client.post<PlatformDescriptionIdData>(`/show-and-views/${type}/${applicationId}`, requestPayload);
  },

  finishShow: (payload: ShowsViewsFinishPayload) => {
    const {id} = payload;
    const requestPayload = {
      offer: payload.offer,
      sellerNeedsHelpToAnotherNewObject: payload.sellerNeedsHelpToAnotherNewObject,
      buyerNeedsHelpToSellHisObject: payload.buyerNeedsHelpToSellHisObject,
      sellerNeedsHelpToSellHisObject: payload.sellerNeedsHelpToSellHisObject,
    };

    return Client.post(`/show/${id}/finish`, requestPayload);
  },

  finishView: (payload: ShowsViewsFinishPayload) => {
    const {id} = payload;
    const requestPayload = {
      offer: payload.offer,
      sellerNeedsHelpToAnotherNewObject: payload.sellerNeedsHelpToAnotherNewObject,
      buyerNeedsHelpToSellHisObject: payload.buyerNeedsHelpToSellHisObject,
      sellerNeedsHelpToSellHisObject: payload.sellerNeedsHelpToSellHisObject,
    };

    return Client.post(`/view/${id}/finish`, requestPayload);
  },

  count: (payload: ShowsViewsCountPayload) => {
    const {applicationId, type} = payload;

    return Client.get<ShowsApplicationCardResponse>(`/show-and-views/${type}/${applicationId}/count`);
  },

  createShow: (applicationId, plannedDate,  selectedApplicationId?: string,
               selectedPreApplicationId?: string) => {
    const payload = {
      plannedDate,
      selectedApplicationId,
      selectedPreApplicationId,
    }
    return Client.post<ShowsViews>(`/show-and-views/show/${applicationId}`, payload)
  },
  createView: (applicationId, plannedDate, clientObjectId: string | undefined, parserId: string | undefined) => {
    const payload = {
      plannedDate,
      parserObjectId: parserId,
      clientObjectApplicationId: clientObjectId,
    }
    return Client.post(`/show-and-views/view/${applicationId}`, payload)
  },

  pinSellerContact: (id: string, contactId: string) => Client.put(`show-and-views/view/${id}/contact/${contactId}`),
  pinSellerAgentContact: (id: string, contactId: string) => Client.put(`show-and-views/view/${id}/agentContact/${contactId}`),
  pinBuyerContact: (id: string, contactId: string) => Client.put(`show-and-views/show/${id}/contact/${contactId}`),
  pinBuyerAgentContact: (id: string, contactId: string) => Client.put(`show-and-views/show/${id}/agentContact/${contactId}`),

  makeFinalShow: (id: string) => Client.put(`show/${id}/makeFinal`),

  makeFinalView: (id: string) => Client.put(`view/${id}/makeFinal`),

  changeShowLeadSource: (id: string, leadSource: string) => Client.put(`/show-and-views/show/${id}/leadSource/${leadSource}`),

  getStatistic: ({
                   applicationId,
                   groupBy,
                   kind,
                   dateFrom,
                   dateTo,
                   type
                 }: ShowsViewsStatisticPayload) => Client.get<ShowsViewsStatisticResponse>(
    `/show-and-views/${type}/${applicationId}/count?&groupBy=${groupBy}&kind=${kind}&dateFrom=${dateFrom}&dateTo=${dateTo}`
  ),
};
