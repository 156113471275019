import { AxiosResponse } from "axios";
import { useMutation } from "react-query";

import { ErrorCodeEnum } from "../../../../types";
import { api } from "../../api";

const mutationKey = "sdelka/otp/verify";

type SmsCodeConfirmationResponsePayload = {
  error: {
    errorCode: ErrorCodeEnum;
    payload: {
      token: string;
    };
  };
};

type SmsCodeConfirmationResponse = AxiosResponse<SmsCodeConfirmationResponsePayload>;

type Props = {
  onSuccess?: (response: SmsCodeConfirmationResponse) => void;
};
// todo
export const usePublicOfferSmsConfirm = ({onSuccess}: Props) => {
  const mutation = useMutation({
    mutationKey,
    mutationFn: ({phone, verificationToken, code}: { phone: string, verificationToken: string, code: string }) =>
      api.auth.publicOfferSmsConfirm(phone, verificationToken, code),
    onError: (error, variables, context) => {
      console.error("Mutation error:", error, variables, context);
    },
    onSuccess,

  },);

  return {
    mutate: mutation.mutate,
    isSuccess: mutation.isSuccess,
    data: mutation.data?.data,
    error: mutation.error
  };
}
