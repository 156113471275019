import { User } from "../../../../../../types";
import styles from "./index.module.scss";
import { CardPerson } from "../../../../../UI";
import { UseLoadUserPositions } from "../../../../../../service/api/queries/position/useLoadUserPositions";

export const useColumns = (refetch: () => void, isMobile?: boolean,   refetchCart?: () => void) => [
  {
    accessor: "user",
    render: ({row}: { row: User }) => {
      const {getPositionName} = UseLoadUserPositions({});
      const positionId = row.position?.id;

      return (
        <div className={styles.root}>
          <CardPerson
            id={row.id}
            phone={row.account.phone}
            position={getPositionName(positionId)}
            avatar={row.account.avatar}
            specialisation={row.specialisations}
            status={row.status}
            fio={{
              firstName: row.account.name,
              lastName: row.account.lastName,
              secondName: row.account.secondName
            }}
            hasCart
            isInCart={row.isInCart}
            refetch={refetch}
            mini={isMobile}
            refetchCart={refetchCart}
            // omitAvatar
          />
        </div>
      )
    },
  },

];
