import { useMutation } from "react-query";
import { api } from "../../api";
import { CartPayload } from "../../../../types";

const DEFAULT_MN = "sdelka/compilation/use-add-to-cart/DEFAULT_MN";

type UseAddToCart = {
  onSuccess?: () => void;
};

export const useAddToCart = ({ onSuccess }: UseAddToCart) => {
  const mutate = useMutation(DEFAULT_MN, {
    mutationFn: (payload: CartPayload[]) => api.cart.add(payload),
    onSuccess,
  });

  return {
    data: mutate.data?.data,
    mutate: mutate.mutate,
    isLoading: mutate.isLoading,
  };
};
