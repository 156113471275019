import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { toastSuccess } from "../../../index";
import { ContactForm } from "./ContactForm";
import { ContactList } from "./ContactList";
import { useContacts } from "../../../../../service/api/queries";
import { useContactCreate } from "../../../../../service/api/mutations";
import { useSchemaResolver } from "./helpers";
import { Contact, ContactCreate, ContactPinDefaultValues } from "../../../../../types";

import styles from "./index.module.scss";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../service/redux/modules/modal/modal.action";
import cn from "classnames";
import { useOpenContact } from "../../../../../service/servise";

type Props = {
  onPin: (contact: Contact) => void;
  hideThisIds?: string[];
  defaultValues?: ContactPinDefaultValues;
  isCreation?: boolean
  isMobile?: boolean
};

type FormType = Omit<ContactCreate, "phones"> & { phone: string };

export const Contacts = (
  {
    onPin,
    hideThisIds,
    defaultValues,
    isCreation,
    isMobile
  }: Props): JSX.Element => {
  const [selectedContact, setSelectedContact] = useState<Contact | undefined>(undefined);
  const dispatch = useDispatch()
  const {schema} = useSchemaResolver({defaultValues});
  const {openContact} = useOpenContact()
  const {register, clearErrors, setValue, getValues, handleSubmit, formState, control} = useForm<FormType>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues || {},
  });

  const {contactsList, total, isFetching, filter, setFilter, refetch} = useContacts({
    defaultTake: 10,
    options: {
      keepPreviousData: true,
    },
  });

  const onSuccess = (data: Contact) => {
    onPin(data);

    let toastText = "Новый контакт создан";

    if (isFetching || total) {
      toastText = "Контакт успешно прикреплен";
    } else {
      toastText = "Контакт успешно создан";
    }

    if (isCreation) {
      openContact({id: data.id, refetchList: refetch})
    }

    toastSuccess({
      text: toastText,
    });
  };

  const contactCreate = useContactCreate({
    onSuccess,
  });

  useEffect(() => {
    if (
      !isFetching &&
      selectedContact &&
      !contactsList.find((contact) => contact.id === selectedContact.id)
    ) {
      setSelectedContact(undefined);
    }
  }, [contactsList, total]);

  useEffect(() => {
    if (hideThisIds && hideThisIds.length) {
      setFilter({
        ...filter,
        hideThisIds,
      });
    }
  }, [hideThisIds]);

  const onSubmit = async (values: FormType) => {
    const {name, lastName, secondName, email, type, otherType, comment, pseudonym} = values;

    contactCreate.mutate({
      name,
      lastName,
      secondName,
      pseudonym,
      email,
      type,
      otherType,
      comment,
      phones: [{phone: values.phone.replaceAll(/\D/g, ""), type: "main"}],
    });

    dispatch(closeModal())
  };

  const onPinClick = () => {
    if (selectedContact) {
      onSuccess(selectedContact);
    }
  };

  const btnSubmitText = (): string => {
    if (isFetching || total) {
      return "Прикрепить";
    }
    return "Создать и прикрепить";
  };

  const buttonText = isCreation ? "Создать" : btnSubmitText()

  const onSearch = (data) => setFilter(data);

  const debouncedSearch = useDebouncedCallback(onSearch, 200);

  useEffect(() => {
    const {phone} = defaultValues || {};
    let parsePhone = (phone || "").replace(/[^\d]/g, "");

    if (parsePhone.length > 10) {
      parsePhone = parsePhone.substring(parsePhone.length - 10);
    }

    const filterValue = {
      ...defaultValues,
      phone,
    };
    debouncedSearch(filterValue);
  }, [defaultValues]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={cn(styles.container, {[styles.containerMobile]: isMobile})}>
        <div className={styles.form}>
          <h3>Данные контакта</h3>

          <ContactForm
            filter={filter}
            setFilter={debouncedSearch}
            register={register}
            setValue={setValue}
            clearErrors={clearErrors}
            formState={formState}
            getValues={getValues}
            control={control}
            isMobile={isMobile}
          />
        </div>

        <div className={styles.list}>
          <h3>Совпадение по номеру телефона</h3>

          <div className={styles.listContainer}>
            {total > 0 || isFetching ? (
              <ContactList
                contacts={contactsList}
                isLoading={isFetching}
                selectedContact={selectedContact}
                setSelectedContact={setSelectedContact}
                isCreation={isCreation}
              />
            ) : (
              <div className={styles.noContacts}>
                <p>Похожих контактов не найдено.</p>
                <p>Вы можете заполнить данные и создать новый контакт</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={cn(styles.btnContainer, {[styles.btnContainerMobile]: isMobile})}>
        <Button
          type={isFetching || total ? "button" : "submit"}
          disabled={!!((isFetching || contactCreate.isLoading || total) && !selectedContact)}
          onClick={isFetching || total ? onPinClick : undefined}
        >
          {buttonText}
        </Button>
      </div>
    </form>
  );
};
