import { Conditions, VideoLessonsFiltersType } from "../../../../types";

export const getVideoLessonsFilters = (search: VideoLessonsFiltersType): Conditions => {
  const searchConditions: Conditions = [];

  if (search?.fullTextSearch) {
    searchConditions.push({
      field: "fullTextSearch",
      operator: "like",
      value: search.fullTextSearch,
    });
  }
  return searchConditions;
}