import { useGetOffice } from "../../../service/api/mutations";
import { MainInfo } from "./components/main";
import { Departments } from "./components/departments/Departments";
import { OfficeCardStickyFooter } from "./components/office-card-sticky-footer/OfficeCardStickyFooter";
import { selectDevice, useSelect } from "../../../service/redux/selectors";
import { LayoutLoading } from "../RealEstatePage/mode/RealEstateViewWithTabs/LayoutLoading";

type Props = {
  officeId: string;
  refetch?: () => void;
};

export const OfficeCard = ({officeId, refetch}: Props): JSX.Element => {
  const {office, refetch: RefetchOffice} = useGetOffice({officeId})
  const {isMobile} = useSelect(selectDevice)
  const refetchAll = () => {
    refetch?.();
    RefetchOffice?.();
  };

  return (
    <>
      <div style={{padding: `16px ${isMobile ? "10px" : "40px"}`, minHeight: "100%"}}>
        {office
          ? <>
            <MainInfo
              office={office}
              refetch={refetchAll}
              canEdit
            />
            <Departments
              office={office}
              isMobile={isMobile}
            />
          </>
          : <LayoutLoading/>
        }
      </div>
      <OfficeCardStickyFooter
        office={office}
        refetch={refetchAll}
        isMobile={isMobile}
      />
    </>
  )
}