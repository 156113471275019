import { MaskFieldName } from "../../../../../../../types";
import { createNumberMask } from "text-mask-addons";

import { MaskEnum } from "../../../../../../../utils/masks";

type Props = {
	maxSymbols?: number;
	mask?: MaskFieldName;
};

type Return = {
	inputMask: any;
};

type UseMask = (props: Props) => Return;

export const useMask: UseMask = ({ maxSymbols, mask }) => {
	const defaultMask: any = createNumberMask({
		prefix: "",
		includeThousandsSeparator: false,
		integerLimit: maxSymbols,
	});

	const inputMask = mask ? MaskEnum[mask] : defaultMask;

	return {
		inputMask,
	};
};
