export const getImageWidthHeight = async (file: File): Promise<{ height: number; width: number }> =>
	new Promise((resolve) => {
		const reader = new FileReader();

		reader.onload = async (e: any) => {
			const image = new Image();
			image.src = e.target.result;

			image.onload = () => {
				resolve({ width: image.width, height: image.height });
			};
		};

		reader.readAsDataURL(file);
	});
