import cn from "classnames";
import styles from "./index.module.scss";
import { RealEstate } from "../../../../types";

import { formatNumbers } from "../../../../utils/formatters";
import { ReactComponent as BuildingsIcon } from "../../../../assets/icons/buildings.svg";

type Props = {
	id: string;
	clientObject: RealEstate;
	cardBackground?: "white" | "blue";
	onCardClick?: (id: string) => void;
	withPrice?: boolean;
};

export const CardObjectLessInfo = ({
	id,
	clientObject,
	cardBackground = "blue",
	onCardClick,
	withPrice = true,
}: Props): JSX.Element => {
	const { addressLabel, price, pricePerUnit, photos } = clientObject;

	const { title } = clientObject;

	const handleCardClick = () => {
		if (onCardClick) {
			onCardClick(id);
		}
	};

	return (
		<div onClick={handleCardClick} className={cn(styles.root, styles[`background_${cardBackground}`])}>
			<div
				className={cn(styles.img, {
					[styles.noImg]: !photos.length,
				})}
			>
				{photos.length ? <img alt="object" src={photos[0].photo1280} /> : <BuildingsIcon />}
			</div>
			<div className={styles.info}>
				<div>
					<div className={styles.title}>{title}</div>
					<div className={styles.address}>{addressLabel}</div>
				</div>
				{withPrice && (
					<div>
						<div className={styles.currentPrice}>{`${formatNumbers(price || "")} ₽`}</div>
						<div className={styles.pricePerUnit}>{`${formatNumbers(pricePerUnit || "")} ₽/м²`}</div>
					</div>
				)}
			</div>
		</div>
	);
};
