import { Control, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";

import { TransparentInput, InfoRow } from "../../../../index";
import { createNumberMask } from "text-mask-addons";
import { RealEstateFilterField } from "../../../../../../types";

import styles from "./index.module.scss";

type Props = {
	control: Control;
	register: UseFormRegister<any>;
	setValue: UseFormSetValue<any>;
	field: RealEstateFilterField;
	disabled?: boolean;
};

export const Commission = ({ field, control, register, setValue, disabled }: Props): JSX.Element => {
	const watchFields = useWatch({
		name: field.dataType.nameGroup,
		control,
	});

	return (
		<InfoRow aspect={{ label: 4, content: 7 }} label={field.label}>
			<div className={styles.root}>
				<div className={styles.input}>
					<TransparentInput
						disabled={disabled}
						textAlign="right"
						register={register}
						name={field.dataType.nameGroup[0]}
						setValueForMask={setValue}
						variant="noPadding"
						value={watchFields[0]}
						mask={createNumberMask({
							prefix: "",
							includeThousandsSeparator: false,
							allowDecimal: true,
							integerLimit: 2,
						})}
					/>

					<span>%</span>
				</div>

				<span className={styles.separator}>|</span>

				<div className={styles.input}>
					<TransparentInput
						disabled={disabled}
						register={register}
						name={field.dataType.nameGroup[1]}
						setValueForMask={setValue}
						variant="noPadding"
						value={watchFields[1]}
						mask={createNumberMask({
							prefix: "",
							thousandsSeparatorSymbol: " ",
							allowDecimal: true,
						})}
					/>

					<span>₽</span>
				</div>
			</div>
		</InfoRow>
	);
};
