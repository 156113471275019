import { RadioButton } from "../../../../../../../../UI";
import cn from "classnames";
import { ReactNode } from "react";
import { Tooltip } from "@sdelka_crm/sdelka-crm-component-library";
import styles from "./index.module.scss";
import { CianTariffNames } from "../../../../../../../../../types";
import { UseFormWatch } from "react-hook-form";

const StartAdTariffLabel = ({children}: { children: ReactNode }): JSX.Element => (
  <div className={styles.tariffOption}>
    <Tooltip>{children}</Tooltip>
    <span>{children}</span>
  </div>
);

const startAdTariffOptions = [
  {value: 1, label: <StartAdTariffLabel>Стандарт</StartAdTariffLabel>},
  {value: 2, label: <StartAdTariffLabel>Premium</StartAdTariffLabel>},
  {value: 3, label: <StartAdTariffLabel>Top</StartAdTariffLabel>},
];

type Props = {
  format: string;
  control: any;
  onChangeTariff: (value: number | string) => void;
  watch: UseFormWatch<any>;
  isPostedAndWaitingForStop?: boolean;
  isTariffJustChanged?: boolean;
};

export const CianSelectTariff = (
  {
    format,
    control,
    onChangeTariff,
    watch,
    isPostedAndWaitingForStop,
    isTariffJustChanged,
  }: Props) => {
  const selectedValue = watch("startAdTariff");

  const handleChangeTariff = (value) => {
    onChangeTariff(value);
  };

  return (
    <>
      <div className={styles.options}>
        <div className={styles.fixedWidthTitle}>Выбрать тариф:</div>
        <div className={styles.radio}>
          <RadioButton
            separate
            className={cn(styles.radioButton, styles[ format ])}
            name="startAdTariff"
            options={startAdTariffOptions}
            control={control}
            handleOnChange={handleChangeTariff}
            disabled={isPostedAndWaitingForStop}
          />
        </div>
      </div>
      {isTariffJustChanged && (
        <div className={styles.info}>
          <p>
            Тариф изменен на <span>{Object.values(CianTariffNames)[ (selectedValue as any) - 1 ]}</span>.
            Ожидайте применения изменений.
          </p>
        </div>
      )}
    </>
  );
};
