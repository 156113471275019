import { PointType } from "../../../../../../../types";

type Props = {
  point: PointType;
};

type Return = {
  lat: number;
  lon: number;
  realEstateId: string;
  pricePerUnit: number;
  price: number;
  applicationId?: string;
  ids?: string[]
};

type UsePropsVariables = (props: Props) => Return;

export const usePropsVariables: UsePropsVariables = ({point}) => {
  const {lat, lon, objects} = point;
  const [object] = objects;
  const {id: realEstateId, pricePerUnit, price, applicationId} = object;

  return {
    lat,
    lon,
    realEstateId,
    pricePerUnit,
    price,
    applicationId,
    ids: [objects[ 0 ].id],
  };
};
