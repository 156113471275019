import { useDispatch } from "react-redux";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { PinContact } from "../../Pin";
import { Contact, WhoIsClient } from "../../../../types";
import { UserListLocal } from "../UserList";
import { closeModal } from "../../../../service/redux/modules/modal/modal.action";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";

type Props = {
  type: WhoIsClient;
  data: any[];
  onChange: (contact: Contact) => void;
  isMobile?: boolean
  withOutDots?: boolean
};

export const ContactsLocal = (
  {
    type,
    data,
    onChange,
    isMobile,
    withOutDots,
  }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const title = type === "seller" ? "Продавец" : "Покупатель";

  const onPin = (contact: Contact) => {
    onChange(contact);
    dispatch(closeModal());
  };

  return (
    <div style={{marginTop: isMobile ? 40 : 80}}>
      <UserListLocal title={title} data={data} withOutDots={withOutDots}>
        {data.length === 0 && <PinContact onPin={onPin} isMobile={isMobile}>
          <Button Icon={PlusIcon} variant="text">
            Добавить контакт
          </Button>
        </PinContact>
        }
      </UserListLocal>
    </div>
  );
};
