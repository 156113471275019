import { ReactNode } from "react";

import { DefaultSelectProps } from "../../../../../../../types";
import { SelectedContainer, SelectedWrapper } from "../../../components";

type Props = {
	thereIsSelected: boolean;
	selectedLabels: Array<{ name: string; children: ReactNode }>;
	toggleList: () => void;
	isListOpen: boolean;
} & Pick<
	DefaultSelectProps,
	"placeholder" | "classNames" | "size" | "append" | "prepend" | "hideArrow" | "isLoading" | "disabled"
>;

export const Selected = ({
	placeholder,
	thereIsSelected,
	selectedLabels,
	classNames,
	size,
	append,
	hideArrow,
	isLoading,
	disabled,
	prepend,
	toggleList,
	isListOpen,
}: Props): JSX.Element => {
	const showLabels = () => (
		<>
			{selectedLabels.map((l, index) => (
				// eslint-disable-next-line react/no-array-index-key
				<span key={index}>
					{l && l.children}
					{selectedLabels.length > 1 && "/"}
				</span>
			))}
		</>
	);

	return (
		<SelectedContainer
			isListOpen={isListOpen}
			onSelectClick={toggleList}
			append={append}
			prepend={prepend}
			hideArrow={hideArrow}
			disabled={disabled}
			isLoading={isLoading}
			size={size}
			classNames={classNames}
		>
			<SelectedWrapper placeholder={placeholder} thereIsSelected={thereIsSelected}>
				{showLabels()}
			</SelectedWrapper>
		</SelectedContainer>
	);
};
