import styles from "./index.module.scss";
import { prepareShortUserOutput } from "../../../../../utils/transformation";
import { openInNewWindow } from "../../../../../utils/openInNewWindow";
import cn from "classnames";

type Props = {
  name: string;
  lastName: string;
  secondName?: string | undefined;
  href: string;
  isCoWorking?: boolean
};

export const UserBlock = ({name, lastName, secondName, href, isCoWorking}: Props): JSX.Element => {

  const onClick = () => {
    if (!isCoWorking) {
      openInNewWindow(href, isCoWorking);
    }
  };

  const FIO = prepareShortUserOutput({lastName, name, secondName});

  return (
    <div onClick={onClick} className={cn(styles.root, {[ styles.coworking ]: isCoWorking})}>
      {FIO}
    </div>
  );
};
