import {
	BusinessProcessSettings,
	CategoryEnum,
	SelectOption,
	BusinessProcess,
} from "../../../../../../../types";
import { useMemo } from "react";

import { useLoadBusinessProcess } from "../../../../../../../service/api/queries";

type Props = {
	businessProcessType: BusinessProcess | "";
	category?: CategoryEnum[];
};

type Return = {
	list?: BusinessProcessSettings[];
	businessProcessOptions: SelectOption[];
	isFetching: boolean;
};

type UseBusinessProcessOptions = (props: Props) => Return;

export const useBusinessProcessOptions: UseBusinessProcessOptions = ({ businessProcessType, category }) => {
	const { list, isFetching } = useLoadBusinessProcess({
		businessProcessType,
		category: category ? category[0] : undefined,
	});

	const businessProcessOptions: SelectOption[] = useMemo(
		() =>
			(list || [])?.map((item) => ({
				value: item.id,
				label: item.typeName,
			})),
		[list, isFetching]
	);

	return {
		list,
		businessProcessOptions,
		isFetching,
	};
};
