import cn from "classnames";
import styles from "./index.module.scss";
import { Loader } from "@sdelka_crm/sdelka-crm-component-library";
import {
  UseSetObjectDoubles
} from "../../../../../../../service/api/mutations/real-estate/useGetObjectDoublesFromOwners";
import { CardObject, CardObjectMini } from "../../../../../../UI";
import { Plug } from "../plug/Plug";
import { KindType } from "../../../../../../../types";
import { useIsTelegram } from "../../../../../../../utils/telegram/useIsTelegram";

type Props = {
  id: string
  isFromParser?: boolean
  mini?: boolean
}

export const ObjectDoubles = (
  {
    id,
    isFromParser,
    mini
  }: Props): JSX.Element => {

  const useHook = UseSetObjectDoubles({id})
  const {doubles, isLoading, isFetching, total} = useHook
  const title = "Дубли объекта"
  const count = doubles ? `(${total})` : "(0)"

  const {executeUrl} = useIsTelegram()

  const openObject = (realEstateId: string, kind?: KindType) => {
    if (!kind) {
      executeUrl(`/real-estate?tab=PARSED&parserObjectId=${realEstateId}`)
    } else {
      executeUrl(`/real-estate?tab=AGENCY&applicationId=${realEstateId}`)
    }
  }

  return (
    <div className={styles.root}>
      <div className={cn(styles.row, styles.header)}>
        <div className={styles.row}>
          <div className={styles.title}>
            {title}
          </div>
          <div className={styles.counter}>
            {count}
          </div>
        </div>
      </div>
      {(doubles && doubles.length > 0)
        ? <>
          {isLoading || isFetching
            ? <div
              className={cn(styles.loading, "animate__animated", "animate__faster", "animate__fadeIn", {
                [ styles.loadingHide ]: !isFetching,
              })}
            >
              <Loader/>
            </div>
            : <div className={styles.collections}>
              {doubles && doubles.length > 0 && doubles.slice(0, 3).map((double) => (
                <div key={double.applicationId}>
                  {mini
                    ? <CardObjectMini
                      id={double.id}
                      objectData={double}
                      isHovered
                      canOpenANewTab
                      onCardClick={() => openObject(isFromParser ? double.id
                        : (double.shortType?.kind !== undefined
                            ? (double.shortType.kind === KindType.APPLICATION ? double.applicationId : double.preApplicationId)
                            : double.id
                        ), double.shortType?.kind)}
                      withPrice
                    />
                    : <CardObject
                      id={double.id}
                      clientObject={double}
                      variant="standard"
                      onCardClick={() => openObject(isFromParser ? double.id
                        : (double.shortType?.kind !== undefined
                            ? (double.shortType.kind === KindType.APPLICATION ? double.applicationId : double.preApplicationId)
                            : double.id
                        ), double.shortType?.kind)}
                    />}
                </div>
              ))
              }
            </div>
          }
        </>
        : <Plug plugText="Других обьявлений не найдено"/>
      }
    </div>
  )
}


