import { InfoRow, RadioButton, toastError, TransparentInput } from "components/UI";
import styles from "./index.module.scss";
import { AgencyAgreementStatusEnum, AgencyAgrementType, CallData } from "types";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { Tooltip } from "@sdelka_crm/sdelka-crm-component-library";
import { usePostAgencyAgrement } from "service/api/mutations/agency-agrement";
import { useEffect, useState } from "react";
import { ReactComponent as InfoIcon } from "../../../../../../../../assets/icons/iconInfo.svg"
import { ReactComponent as IconFile } from "../../../../../../../../assets/icons/iconFileSmall.svg"
import { ReactComponent as IconFolderDown } from "../../../../../../../../assets/icons/iconDocumentFolderDown.svg"
import { ReactComponent as IconFolderUp } from "../../../../../../../../assets/icons/iconDocumentFolderUp.svg"
import { ReactComponent as IconPlusSmall } from "../../../../../../../../assets/icons/iconAddGray.svg"
import { ReactComponent as IconDownload } from "../../../../../../../../assets/icons/iconDownload20.svg"
// eslint-disable-next-line import/no-duplicates
import classNames from "classnames";
// eslint-disable-next-line import/no-duplicates
import cn from "classnames";
import { useDropzone } from "react-dropzone";
import { useLoadFiles } from "../../../../../../../../utils/hooks/helpers/useLoadFiles";
import { AlertBlock } from "../AlerBlock/AlertBlock";
import { useOpenAddCallModal } from "../../helpers/useAddCall/useOpenAddCallModal";
import { ReactComponent as CloseIcon } from "../../../../../../../../assets/icons/close.svg";
import { ReactComponent as PlusIcon } from "../../../../../../../../assets/icons/plus-small.svg";
import { CardTraffic } from "../../../../../../../UI/Card/card-traffic/CardTraffic";
import { createNumberMask } from "text-mask-addons";

type Props = {
  data?: AgencyAgrementType
  isEdit: boolean
  setIsEdit: (value: boolean) => void,
  applicationId: any
  errorCode?: string
  status?: AgencyAgreementStatusEnum
  refetch?: () => void
  err: any
  call?: any
};

export const Form = (
  {
    data,
    isEdit,
    setIsEdit,
    applicationId,
    errorCode,
    refetch,
    err,
    call,
  }: Props): JSX.Element => {
  const [, setHasChanges] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [priceError, setPriceError] = useState<string | null>(null)
  const [commError, setCommError] = useState<string | null>(null)
  const [filesOpen, setFilesOpen] = useState(false)

  const {control, register, setValue, getValues, handleSubmit} = useForm()

  let whoIsClient = data?.whoIsClient
  if (!whoIsClient) {
    whoIsClient = (err as any)?.response?.data?.error?.payload.whoIsClient
  }

  const [
    formWatcher,
    typeWatcher,
    durationWatcher,
    prolongationWatcher,
    objectPriceWatcher,
    yourComissionWatcher,
    comissionPercentWatcher,
    counterCommissionWatcher,
  ] = [
    useWatch({
      name: "form",
      control,
      defaultValue: getValues("form") || data?.form || "ONLINE",
    }),
    useWatch({
      name: "type",
      control,
      defaultValue: getValues("type") || data?.type || whoIsClient === "seller" ? "ADVERTISING" : "FIND",
    }),
    useWatch({
      name: "duration",
      control,
      defaultValue: getValues("duration") || data?.duration || "1_MONTH",
    }),
    useWatch({
      name: "prolongation",
      control,
      defaultValue: getValues("prolongation") || data?.prolongation || "NO",
    }),
    useWatch({
      name: "objectPrice",
      control,
      defaultValue: data?.objectPrice, // getValues("objectPrice") ||
    }),
    useWatch({
      name: "comissionSum",
      control,
      defaultValue: data?.comissionSum,
    }),
    useWatch({
      name: "comissionPercent",
      control,
      defaultValue: data?.comissionPercent,
    }),
    useWatch({
      name: "counterCommission",
      control,
      defaultValue: data?.counterCommission, // getValues("counterCommission") ||
    }),
  ];


  const mask = createNumberMask({
    prefix: "",
    thousandsSeparatorSymbol: " ",
  })

  const [files, setFiles] = useState<Array<string>>(data?.files ?? [])
  const [selectedCall, setSelectedCall] = useState<CallData | null | undefined>(data?.callData)
  const [selectedCallId, setSelectedCallId] = useState<string | null | undefined>(data?.callDataId)

  const formDataPayload: any = {
    form: formWatcher,
    type: typeWatcher,
    duration: durationWatcher,
    prolongation: prolongationWatcher,
    objectPrice: +(String(objectPriceWatcher).replaceAll(" ", "")),
    comissionSum: +(String(yourComissionWatcher).replaceAll(" ", "")),
    comissionPercent: +(String(comissionPercentWatcher).replaceAll(" ", "")),
    counterCommission: +(String(counterCommissionWatcher).replaceAll(" ", "")),
    files,
    callDataId: selectedCallId,
  }

  useEffect(() => {
    const originalData = {
      form: data?.form,
      type: data?.type,
      duration: data?.duration,
      prolongation: data?.prolongation,
      objectPrice: data?.objectPrice,
      counterCommission: data?.counterCommission,
      comissionPercent: data?.comissionPercent,
      comissionSum: data?.comissionSum,
      files: data?.files
    };

    const currentData = {
      form: formWatcher,
      type: typeWatcher,
      duration: durationWatcher,
      prolongation: prolongationWatcher,
      objectPrice: objectPriceWatcher,
      comissionSum: yourComissionWatcher,
      counterCommission: counterCommissionWatcher,
      comissionPercent: comissionPercentWatcher,
      files
    };

    const isDifferent = JSON.stringify(originalData) !== JSON.stringify(currentData);
    setHasChanges(isDifferent);
  }, [formWatcher, typeWatcher, durationWatcher, prolongationWatcher, objectPriceWatcher, yourComissionWatcher, counterCommissionWatcher, data?.form, data?.type, data?.duration, data?.prolongation, data?.objectPrice, data?.counterCommission, data?.comissionPercent, data?.comissionSum, data?.files, comissionPercentWatcher, files]);

  const {mutate, isLoading, data: postData} = usePostAgencyAgrement({
    data: formDataPayload,
    errorCode,
    applicationID: applicationId,
    onSuccess: () => {
      if (refetch) {
        refetch()
      }
      setIsEdit(false)
    }
  });

  const onSubmit: SubmitHandler<any> = () => {
    mutate()
  };

  const handleSelectCall = (callDataSelected: CallData | null | undefined, callDataSelectedId: string | null | undefined) => {
    setSelectedCallId(callDataSelectedId)
    setSelectedCall(callDataSelected)
  }

  const handleDeleteCall = () => {
    setSelectedCallId(null)
    setSelectedCall(null)
  }

  const {openAddCallModal} = useOpenAddCallModal({onSelect: handleSelectCall})

  const [showUnfilled, setUnfilled] = useState(false)
  const [isButtonActive, setIsButtonActive] = useState(true)
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  const checkErrors = () => {
    let buttonActive = true
    if (+counterCommissionWatcher > +yourComissionWatcher) {
      setError("Не может превышать сумму вашей комиссии");
      buttonActive = false
    } else {
      setError(null);
    }
    if (whoIsClient === "seller" && (yourComissionWatcher === undefined || +yourComissionWatcher < 1 || +yourComissionWatcher >= +objectPriceWatcher)) {
      if (showUnfilled) {
        setCommError("Недопустимое значение")
      }
      buttonActive = false
    } else {
      setCommError(null)
    }
    if (whoIsClient === "seller" && (objectPriceWatcher === undefined || +objectPriceWatcher < 2 || +yourComissionWatcher >= +objectPriceWatcher)) {
      if (showUnfilled) {
        setPriceError("Недопустимое значение")
      }
      buttonActive = false
    } else {
      setPriceError(null)
    }
    setIsButtonActive(buttonActive)
  };

  useEffect(() => {
    setIsButtonLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    checkErrors();
  }, [checkErrors, counterCommissionWatcher, objectPriceWatcher]);


  const updateFormValues = () => {
    if (postData && postData.data && !isLoading) {
      setValue("form", postData.data.form);
      setValue("type", postData.data.type);
      setValue("duration", postData.data.duration);
      setValue("prolongation", postData.data.prolongation);
      setValue("objectPrice", postData.data.objectPrice);
      setValue("yourComission", postData.data.yourComission);
      setValue("comissionSum", postData.data.comissionSum);
      setValue("comissionPercent", postData.data.comissionPercent);
      setValue("counterCommission", postData.data.counterCommission);
    }
  };
  useEffect(() => {
    updateFormValues();
    if (postData) {
      setIsEdit(false)
    }
  }, [postData]);

  const resetFormValues = () => {
    setValue("form", data?.form);
    setValue("type", data?.type);
    setValue("duration", data?.duration);
    setValue("prolongation", data?.prolongation);
    setValue("objectPrice", data?.objectPrice);
    setValue("comissionSum", data?.comissionSum);
    setValue("comissionPercent", data?.comissionPercent);
    setValue("counterCommission", data?.counterCommission);
    setIsEdit(false);
  };

  const initialSaveText = getValues("form") === "OFFLINE" ? "Сохранить" : "Сформировать"

  call(onSubmit.bind(this), initialSaveText, resetFormValues.bind(this), isButtonActive, isButtonLoading)

  const [isLoadingFile, setIsLoading] = useState(false);
  const onError = () => {
    toastError({
      text: `Не удалось загрузить файл`,
    });
    setIsLoading(false);
  };
  let curFiles
  const {loadFiles} = useLoadFiles()
  const useCreateDropzone = () =>
    useDropzone({
      noDrag: false,
      disabled: isLoadingFile,
      onDropRejected: onError,
      onDrop: async (acceptedFiles: File[]) => {
        setIsLoading(true);

        const acceptedSizes = acceptedFiles.filter((file) => {
          if (file.size > 5000000) {
            toastError({
              text: `Невозможно загрузить ${file.name}. Файл должен весить менее 5мб`,
            });
          }

          return file.size <= 5000000;
        });

        const filesAccepted = acceptedSizes.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            fileType: "file" as "file" | "doc" | undefined,
          })
        );

        curFiles = await loadFiles(filesAccepted);
        const newFiles = (curFiles as Array<{ url: string }>).map(el =>
          el.url)
        newFiles.forEach(el => formDataPayload.files.push(el))
        setIsLoading(false)
      },
    });

  useEffect(() => {
    if (curFiles) {
      setFiles(curFiles)
    }

    setIsLoading(false)

  }, [curFiles])

  const fileDropzone = useCreateDropzone();

  let dataForContact = data?.contact
  if (!dataForContact) {
    dataForContact = (err as any)?.response?.data?.error?.payload.contact
  }

  const handleClickDownload = (url: string) => {
    window.open(`${url}`)
  }

  const {REACT_APP_API_PROD_URL} = process.env

  const handleDownloadAll = () => {
    window.open(
      `${REACT_APP_API_PROD_URL}/agent-agreement/${applicationId}/downloadAllFiles`)
  }

  return (
    <div>
      <form className={styles.root} onSubmit={handleSubmit(onSubmit)}>
        {errorCode === "AP005" && <AlertBlock/>}
        <div className={styles.radioButtons}>
          <div className={styles.block}>
            <p>Форма</p>
            <RadioButton
              background="gray"
              backgroundButtons="white"
              name="form"
              control={control}
              className={styles.RadioButton}
              options={[
                {
                  value: "ORAL", label: "Устная", disabled: !isEdit || (data && data?.form !== "ORAL"), icon: <Tooltip
                    classNames={{
                      root: styles.tooltipDescription,
                      card: styles.tooltipCard,
                    }}
                    CustomIcon={InfoIcon}
                  >
                    Если у вас устная договоренность с клиентом. Дает возможность создать Заявку без подписания
                    договора.
                  </Tooltip>
                },
                {
                  value: "ONLINE", label: "Он-лайн", disabled: !isEdit || (data && data?.form !== "ORAL"), icon: <Tooltip
                    classNames={{
                      root: styles.tooltipDescription,
                      card: styles.tooltipCard,
                    }}
                    CustomIcon={InfoIcon}
                  >
                    Если вы хотите заключить договор с клиентом с помощью онлайн-подписания по СМС.
                  </Tooltip>
                },
                {
                  value: "OFFLINE", label: "Офф-лайн", disabled: !isEdit || (data && data?.form !== "ORAL"), icon: <Tooltip
                    classNames={{
                      root: styles.tooltipDescription,
                      card: styles.tooltipCard,
                    }}
                    CustomIcon={InfoIcon}
                  >
                    Если вы заключили договор с клиентом на бумажном носителе. Необходимо загрузить документ в в
                    папку &quot;Агентское соглашение&quot;.
                  </Tooltip>
                },
              ]}
              disabled={!isEdit || (data && data?.form !== "ORAL")}

              settings={{preventOnChange: !isEdit, disabledOptional: !isEdit || (data && data?.form !== "ORAL")}}
              currentValue={formWatcher}
            />
          </div>
          {getValues("form") !== "ORAL" && formWatcher !== "ORAL" && <div className={styles.block}>
            <p>Тип</p>
            <RadioButton
              background="gray"
              backgroundButtons="white"
              name="type"
              control={control}
              className={styles.RadioButton}
              options={[
                {
                  icon: <Tooltip
                    classNames={{
                      root: styles.tooltipDescription,
                      card: styles.tooltipCard,
                    }}
                    CustomIcon={InfoIcon}
                  >
                    {whoIsClient === "seller"
                      ? "Соглашение по которому у вас есть преимущественное право продавать недвижимость клиента.\n" +
                      "                      Собственник жилья обязуется не обращаться к другим риелторам."
                      : "Договор по которому, вы выполняете услуги поиска подходящего под требования клиента объекта недвижимости," +
                      " проверку объекта на юридическую чистоту с целью приобретения права собственности"}
                  </Tooltip>,
                  value: whoIsClient === "seller" ? "EX" : "FIND",
                  label: whoIsClient === "seller" ? "Эксклюзивный" : "Поисковый"
                },
                {
                  icon: <Tooltip
                    classNames={{
                      root: styles.tooltipDescription,
                      card: styles.tooltipCard,
                    }}
                    CustomIcon={InfoIcon}
                  >
                    {whoIsClient === "seller"
                      ? "Соглашение по которому у вас есть право рекламировать недвижимость клиента и проводить показы для заинтересованных покупателей."
                      : "Договор оказания услуг, по которому вы выполняете составление договоров, сбор документов, подачу документов на гос. регистрацию права собственности"}
                  </Tooltip>,
                  value: whoIsClient === "seller" ? "ADVERTISING" : "LOYAL",
                  label: whoIsClient === "seller" ? "Рекламный" : "Юр. сопровождения"
                },
              ]}
              disabled={!isEdit}
              settings={{preventOnChange: !isEdit, disabledOptional: !isEdit}}
              currentValue={typeWatcher}
            />
          </div>}
          <div className={styles.block}>
            <p>Сроки договора:</p>
            <RadioButton
              background="gray"
              backgroundButtons="white"
              name="duration"
              control={control}
              className={styles.RadioButton}
              options={[
                {value: "2_WEEKS", label: "2 недели"},
                {value: "1_MONTH", label: "1 месяц"},
                {value: "2_MONTH", label: "2 месяца"},
                {value: "3_MONTH", label: "3 месяца"},
              ]}
              disabled={!isEdit}
              currentValue={durationWatcher}
              settings={{preventOnChange: !isEdit, disabledOptional: !isEdit}}
            />
          </div>
          {getValues("form") !== "ORAL" && formWatcher !== "ORAL" &&
            <div className={styles.block}>
              <p>Пролонгация:</p>
              <RadioButton
                background="gray"
                backgroundButtons="white"
                name="prolongation"
                control={control}
                className={styles.RadioButton}
                options={[
                  {value: "YES", label: "Есть"},
                  {value: "NO", label: "Нет"},
                ]}
                disabled={!isEdit}
                currentValue={prolongationWatcher}
                settings={{preventOnChange: !isEdit, disabledOptional: !isEdit}}
              />
            </div>}
          <div className={styles.stripe}/>
        </div>
        <div className={styles.financialConditions}>
          <h2>
            Предварительные финансовые условия
          </h2>
          {whoIsClient === "seller" &&
            <InfoRow
              label="Стоимость объекта"
              classNames={{
                content: styles.root,
              }}>
              <TransparentInput
                className={classNames(styles.input, isEdit ? "" : styles.readOnly)}
                placeholder="Указать ₽"
                name="objectPrice"
                mask={mask}
                maxLenght={11}
                setValueForMask={setValue}
                onChange={(value) => {
                  if (getValues("objectPrice") !== value) {
                    setValue("objectPrice", value)
                    setUnfilled(true)
                  }
                  ;
                }}
                register={register}
                disabled={!isEdit}
                required
                value={objectPriceWatcher}
              />
            </InfoRow>}
          {priceError && <p className={styles.error}>{priceError}</p>}
          <InfoRow
            label="Ваша комиссия"
            classNames={{
              content: styles.rootInput,
            }}>
            <TransparentInput
              className={classNames(styles.input, isEdit ? "" : styles.readOnly)}
              mask={mask}
              register={register}
              placeholder="Указать ₽"
              name="comissionSum"
              maxLenght={11}
              value={yourComissionWatcher}
              setValueForMask={setValue}
              onChange={(value) => {
                if (getValues("comissionSum") !== value) {
                  setValue("comissionSum", value)
                  setValue("comissionPercent", String((parseFloat(value) / parseFloat(getValues("objectPrice") ?? data?.objectPrice)) * 100))
                  setUnfilled(true)
                }
              }}
              disabled={!isEdit}
              textAlign="right"
              required
            />
          </InfoRow>
          {commError && <p className={styles.error}>{commError}</p>}
          {whoIsClient === "seller" && getValues("form") !== "ORAL" && formWatcher !== "ORAL" &&
            <InfoRow
              label={<>
                Встречная комиссия
                <Tooltip
                  classNames={{
                    root: styles.tooltipDescription,
                    card: styles.tooltipCard,
                  }}
                  CustomIcon={InfoIcon}
                >
                  Сумма, которую вы предлагаете риэлтору
                  с покупателем (Не участвует в агентском договоре)
                  Введя ее в поле, вы даете согласие на публикацию вашего объекта в базе СДЕЛКИ
                </Tooltip>
              </>}
              classNames={{
                content: styles.infoRow,
              }}>
              <TransparentInput
                className={classNames(styles.input, isEdit ? "" : styles.readOnly)}
                placeholder="Указать ₽"
                name="counterCommission"
                mask={mask}
                maxLenght={11}
                required
                setValueForMask={setValue}
                register={register}
                onChange={(value) => {
                  if (getValues("counterCommission") !== value) setValue("counterCommission", value);
                }}
                disabled={!isEdit}
                value={counterCommissionWatcher}
              />
            </InfoRow>
          }
          {error && <p className={styles.error}>{error}</p>}
        </div>
        {formDataPayload.files && formDataPayload.files.length > 0 &&
          <div>
            <div className={styles.docTitle}>Контракт</div>
          </div>
        }
        {formDataPayload.form === "OFFLINE" &&
          <div className={styles.fileContainer}>
            {formDataPayload.files && formDataPayload.files.length > 0 ? <>
                <div className={styles.fileRow}>
                  <div className={styles.fileRow} onClick={() => setFilesOpen(!filesOpen)}>
                    <div className={styles.counterWrapper}>
                      <div className={styles.counter}>{files.length ?? 0}</div>
                      {filesOpen ? <IconFolderUp/> : <IconFolderDown/>}
                    </div>
                    <div className={styles.fileRowTitle}>Агентское соглашение</div>
                  </div>
                  <div className={styles.fileRow}>
                    {isEdit &&
                      <div
                        style={{display: "flex"}}
                        {...fileDropzone.getRootProps()}
                        className={cn(styles.filesRoot, {
                          [ styles.isLoading ]: isLoading,
                        })}
                      >
                        <input {...fileDropzone.getInputProps()} />
                        <IconPlusSmall style={{marginRight: "8px"}}/>
                      </div>}
                    <IconDownload onClick={() => handleDownloadAll()}/>
                  </div>
                </div>
                {filesOpen &&
                  <div className={styles.filesList}>
                    {formDataPayload.files.map((item) => (
                      <div className={styles.fileItem} key={item}>
                        <div className={styles.fileRow}>
                          <IconFile style={{marginRight: "10px"}}/>
                          <span className={styles.fileRowText}>
                              {item.replace("https://crm.sdelka-it.ru/files/", "")}
                            </span>
                        </div>
                        <IconDownload onClick={() => handleClickDownload(item)}/>
                      </div>
                    ))}
                  </div>
                }
              </>
              : <>
                {isEdit &&
                  <div
                    style={{display: "flex"}}
                    {...fileDropzone.getRootProps()}
                    className={cn(styles.filesRoot, styles.plugFiles, {
                      [ styles.isLoading ]: isLoading,
                    })}
                  >
                    <input {...fileDropzone.getInputProps()} />
                    Документы не загружены
                  </div>}

              </>
            }
          </div>
        }
        {formDataPayload.form === "ORAL" &&
          <div className={styles.callContainer}>
            <div className={styles.callRow}>
              <div className={styles.docTitle}>
                Запись звонка
              </div>
              {isEdit &&
                <>
                  {selectedCall
                    ? <div className={cn(styles.callRow, styles.delete)} onClick={() => handleDeleteCall()}>
                      <CloseIcon/>
                      <div>Открепить</div>
                    </div>
                    : <div className={cn(styles.callRow, styles.add)} onClick={() => openAddCallModal()}>
                      <PlusIcon/>
                      <div className={styles.add}>Прикрепить запись</div>
                    </div>
                  }
                </>
              }
            </div>
            {selectedCall
              ? <div className={styles.selectedCall}>
                <CardTraffic callData={selectedCall}/>
              </div>
              : <div className={styles.plug}>
                Запись не прикреплена
              </div>
            }

          </div>
        }
      </form>
    </div>
  );
}
