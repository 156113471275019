import { AxiosResponse } from "axios";

import {
	CompanyCreatePayload,
	PersonalDataFormType,
	LoginRequestBody,
	LoginResponse,
	RegistrationConfirm,
	RegistrationPayload,
	RegistrationPayloadV2,
	RegistrationResponse,
	ResetPasswordCodeConfirm,
	ResetPasswordPayload,
	User, JoinToSdelkaProUserPayload, RegistrationResponse2,
} from "../../../../types";
import { UsersService } from "./UsersService";
import { Client } from "../../Client";
import { appApi } from "../../appApi";

export const AuthService = {
	me: async (token: string): Promise<User> => {
		const me = await UsersService.me(token);
		return me?.data;
	},

	signIn: async (data: LoginRequestBody) => {
		const signInResult = await Client.post<LoginResponse[]>("/auth/login", data);
		const allTokens = signInResult.data.map((user) => ({
			userId: user.id,
			token: user.token,
		}));
		const currentToken = signInResult.data[0].token;
		appApi.token.setTokens({ allTokens, current: currentToken }, { rememberMe: true });
		return signInResult.data;
	},

	signOut: (): void => {
		appApi.token.removeTokens();
	},

	registration: async (data: RegistrationPayload): Promise<AxiosResponse<RegistrationResponse>> => {
		const signUpResult = await Client.post("/registration", data);
		appApi.token.setCurrentToken(signUpResult.data.token);

		return signUpResult;
	},
	registrationV2 : async (data: RegistrationPayloadV2): Promise<AxiosResponse<RegistrationResponse>> => {
		const signUpResult = await Client.post("/auth/requsetConfirmForRegister", data);
	
		appApi.token.setCurrentToken(signUpResult.data.token);
		return signUpResult;
	},
	checkConfirm: async (data: RegistrationConfirm) => {
		const response = await Client.post("/auth/checkConfirm", data);
		const { token }: { token: string } = response.data.error.payload;

		appApi.token.setCurrentToken(token);
		return response;
	},
	checkPersonalDataForm : async (data: PersonalDataFormType): Promise<AxiosResponse<RegistrationResponse>> => {
		
		const signUpResult = await Client.post("/registration/v2", data);
		appApi.token.setCurrentToken(signUpResult.data.token);

		return signUpResult;
	},
	confirmSmsCode: async (data: RegistrationConfirm) =>
		
		// const { token }: { token: string } = response.data.error.payload;
		// appApi.token.setCurrentToken(token);
		 Client.post("/auth/checkConfirm", data)
	,
	// registration/sdelkaPro
	joinTosdelkaPro: async (payload?) => Client.post("registration/sdelkaPro", payload),
	phoneVerification: (data: { phone: string }): Promise<AxiosResponse<{ token: string }>> =>
		Client.post("/auth/requireConfirm", data),

	resetPassword: (
		data: Omit<ResetPasswordPayload, "phone"> & { token: string }
	): Promise<AxiosResponse<User>> => Client.put("/auth/resetPassword", data),

	resetPasswordCodeConfirm: (
		data: Pick<ResetPasswordCodeConfirm, "code"> & { token: string }
	): Promise<AxiosResponse<boolean>> => Client.post("/auth/checkConfirm", data),

	createCompany: async (data: CompanyCreatePayload) => {
		const companyData = await Client.post("/company", data);

		const allTokens = companyData.data.map((user) => ({
			userId: user.id,
			token: user.token,
		}));
		const currentToken = companyData.data.find((user) => user.type === "estateAgencyUser").token;
		appApi.token.setTokens({ allTokens, current: currentToken }, { rememberMe: true });

		return companyData;
	},

	signInAsEmployee: async (userId: string) => {
		try {
			const signInResult = await Client.post<LoginResponse[]>(`/auth/loginBy/${userId}`);
			const parentTokens = appApi.token.getTokens();

			if (parentTokens) {
				appApi.token.setParentTokens(parentTokens);

				const allTokens = signInResult.data.map((user) => ({
					userId: user.id,
					token: user.token,
				}));
				const currentToken = signInResult.data[0].token;

				appApi.token.setTokens({ allTokens, current: currentToken }, { rememberMe: true });

				return signInResult.data;
			}

			return undefined;
		} catch {
			console.log("Не удалось зайти под сотрудником");

			return undefined;
		}
	},

	publicOfferRequest: (data: RegistrationPayloadV2) => Client.post<RegistrationResponse2>(`/otp/sms`, data),

	publicOfferSmsConfirm :  (phone: string, verificationToken: string, code: string) => Client.post(`/otp/verify`, {
			phone,
			verificationToken,
			code,
		}),

}
