import cn from "classnames";

import styles from "./index.module.scss";

export const TrafficLeadSkeleton = (): JSX.Element => (
	<div className={styles.root}>

		<div className={styles.item}>
			<div className={cn(styles.skeleton, styles.body)} />
		</div>
		<div className={styles.item}>
			<div className={cn(styles.skeleton, styles.body)} />
		</div>
		<div className={styles.item}>
			<div className={cn(styles.skeleton, styles.body)} />
		</div>
		<div className={styles.item}>
			<div className={cn(styles.skeleton, styles.body)} />
		</div>
		<div className={styles.item}>
			<div className={cn(styles.skeleton, styles.body)} />
		</div>
		<div className={styles.item}>
			<div className={cn(styles.skeleton, styles.body)} />
		</div>
		<div className={styles.item}>
			<div className={cn(styles.skeleton, styles.body)} />
		</div>
		<div className={styles.item}>
			<div className={cn(styles.skeleton, styles.body)} />
		</div>
		<div className={styles.item}>
			<div className={cn(styles.skeleton, styles.body)} />
		</div>
		<div className={styles.item}>
			<div className={cn(styles.skeleton, styles.body)} />
		</div>
		<div className={styles.item}>
			<div className={cn(styles.skeleton, styles.body)} />
		</div>
		<div className={styles.item}>
			<div className={cn(styles.skeleton, styles.body)} />
		</div>
		<div className={styles.item}>
			<div className={cn(styles.skeleton, styles.body)} />
		</div>
		<div className={styles.item}>
			<div className={cn(styles.skeleton, styles.body)} />
		</div>
	</div>
);
