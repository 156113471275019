import { useState } from "react";
import styles from "./index.module.scss";
import { BuildingInfo } from "components/UI/RealEstate/RealEstateForm/ObjectEditLayout/components";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { ReactComponent as Edit } from "assets/icons/iconEdit.svg";
import { usePutAdditionalProperties } from "service/api/queries/deals/usePutAdditionalProperties";

type Props = {
  form: any;
  editFields: any;
  viewFields: any;
  blockName: string
  id: string
};

export const Block = (
  {
    form,
    editFields,
    viewFields,
    blockName,
    id,
  }: Props): JSX.Element => {

  const [isEdit, setIsEdit] = useState(false)

  const {mutate} = usePutAdditionalProperties({
    data: {additionalProperties: form.getValues().additionalProperties},
    id
  });

  const putAD = () => {
    mutate();
  };

  const saveHandler = () => {
    putAD()
    setIsEdit(false)
  }
  const discardChangesHandler = () => {
    setIsEdit(false)
  }

  return (
    <form className={styles.root} onSubmit={form.handleSubmit(saveHandler)}>
      <div className={styles.header}>
        <h2>{blockName}</h2>
        {!isEdit &&
          <Button Icon={Edit} variant="text" onClick={() => setIsEdit(true)}>
            Редактировать
          </Button>
        }
      </div>
      {isEdit ?
        <BuildingInfo form={form} fields={editFields}/> :
        <BuildingInfo form={form} fields={viewFields}/>}
      {isEdit &&
        <div className={styles.buttons}>
          <Button variant="outlined" onClick={discardChangesHandler}>
            Отменить
          </Button>
          <Button type="submit">
            Сохранить
          </Button>
        </div>}
    </form>
  );
};
