import { useQuery } from "react-query";
import { api } from "../../index";

const DEFAULT_QN = "sdelka/user/get-office/DEFAULT_QN";

type Props = {
  officeId: string;
  onSuccess?: () => void;
  onError?: () => void;
};

export const useGetOffice = ({officeId, onError, onSuccess}: Props) => {

  const loadOffice = () => api.offices.get(officeId)
  const { data, isFetching, refetch } = useQuery(DEFAULT_QN, loadOffice, {
    enabled: !!officeId,
    onSuccess,
    onError,
    cacheTime:0
  });

  return {
    office: data?.data,
    isFetching,
    refetch
  }
}
