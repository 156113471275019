import { Loader } from "@sdelka_crm/sdelka-crm-component-library";
import { InfinityWick, Table } from "../../../../UI";
import { useOpenLead } from "../../helpers";
import { useColumns } from "./columns";
import { useLoadLeads } from "../../../../../service/api/queries";
import { LeadInList, LeadStatusEnum } from "../../../../../types";
import styles from "./index.module.scss";

type Props = {
  refetch: () => void;
};

export const FreeLeads = ({refetch}: Props): JSX.Element => {
  const columns = useColumns();

  const defaultFilter = {
    status: [LeadStatusEnum.new],
  };

  const {
    list,
    isLoading,
    isFetching,
    isFetched,
    total,
    refetch: refetchFreeLeads,
  } = useLoadLeads({name: "loadFreeLeads", defaultFilter});

  const {handleOpenLead} = useOpenLead({refetch});

  const handleRowClick = (row: LeadInList) => {
    handleOpenLead(row.id);
  };

  return (
    <div className={styles.root}>
      <h3>
        Необработанные лиды
        <span>{` (${total})`}</span>
        <span>{isFetching && <Loader height={0} className={styles.loader}/>}</span>
      </h3>

      {isFetched && <InfinityWick onRefetch={refetchFreeLeads}/>}

      {!list.length && !isLoading && (
        <div className={styles.noFreeLeads}>Нераспределенные лиды отсутствуют</div>
      )}

      {(isLoading && <Loader height={250}/>) || (
        <Table<LeadInList>
          idKey="id"
          data={list}
          columns={columns}
          onRowClick={handleRowClick}
          rowClass={styles.row}
          colClass={styles.col}
        />
      )}
    </div>
  );
};
