import styles from "./index.module.scss"
import { useDispatch } from "react-redux";
import { selectDevice, useSelect } from "../../../../../../../service/redux/selectors";
import { openModal } from "../../../../../../../service/redux/modules/modal/modal.action";
import { ImportObjectModal } from "./ImportObjectModal";

export const useOpenImportObjectModal = () => {
  const dispatch = useDispatch();
  const {isMobile} = useSelect(selectDevice)

  const handleImportObject = () => {
    dispatch(
      openModal({
        body: <ImportObjectModal/>,
        width: isMobile ? 500 : 660,
        classNames: {
          contentWrapper: isMobile ? styles.contentWrapperMobile : styles.contentWrapper,
          buttonClose: isMobile ? styles.modalClose : ""
        },
      })
    );
  };

  return {
    handleImportObject,
  };
}