import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/company/load-company-accessions/DEFAULT_QN";

type UseLoadCompanyAccessions = {
	enabled?: boolean;
};

export const useLoadCompanyAccessions = ({ enabled = true }: UseLoadCompanyAccessions) => {
	const loadCompanyAccessions = () => api.companies.getAccessions();

	const { data, isLoading, refetch } = useQuery(DEFAULT_QN, loadCompanyAccessions, { enabled });

	return {
		accessions: data?.data || [],
		isLoading,
		refetch,
	};
};
