import {
	Contact,
	ContactFilter,
	ContactList,
	ContactToObject,
	ContactPinToObject,
	ContactCreate,
	ContactSort,
	ContactUpdate,
	FindContactByPhoneResponse, ChatAvitoItem,
} from "../../../../types";

import { Client } from "../../Client";
import { getContactSort, getContactCondition } from "../../helpers";

type Get = {
	id: string;
};

type Update = {
	id: string;
	data: ContactUpdate;
};

type List = {
	take: number;
	skip: number;
	join?: string[];
	sort?: ContactSort;
	search?: ContactFilter;
};

export const ContactsService = {
	get: ({ id }: Get) => Client.get<Contact>(`/contact/${id}`),
	update: ({ id, data }: Update) => Client.put<Contact>(`/contact/${id}`, data),
	list: ({ take, skip, sort, join, search = {} }: List) => {
		const sortConditions = sort ? getContactSort(sort) : undefined;

		const searchConditions = [...getContactCondition(search)];

		return Client.post<ContactList>(
			"/contact/filter",
			JSON.stringify({
				search: {
					filterType: "and",
					conditions: [...searchConditions],
				},
				join,
				take,
				skip,
				sort: sortConditions,
			})
		);
	},

	relatedContacts: ({ take, skip, search = {} }: List) => {
		const searchConditions = getContactCondition(search);

		return Client.post<ContactList>(
			"/contact/filter",
			JSON.stringify({
				search: {
					filterType: "or",
					conditions: searchConditions,
				},
				join: ["parents", "children", "contact.phones"],
				sort: [
					{
						field: "id",
						order: "ASC",
					},
				],
				take,
				skip,
			})
		);
	},

	contactPinToObject: (payload: ContactPinToObject) =>
		Client.post<Omit<ContactToObject, "contact">>("/contact-to-object", payload),
	contactUnpinToObject: (id: string) =>
		Client.delete<Omit<ContactToObject, "contact">>(`/contact-to-object/${id}`),
	create: (data: ContactCreate) => Client.post<Contact>("/contact", data),
	archive: (id: string) => Client.delete<Contact>(`/contact/${id}/archive`),
	findByPhone: (phone: string) =>
		Client.get<FindContactByPhoneResponse>(`/contact/findByPhone?phone=${phone}`),
	addAccessToUser: (contactId: string) => Client.post<{ success: boolean }>(`contact/access/${contactId}`),

	contactsChatAvito: (contactId: string) =>
		Client.get<ChatAvitoItem[]>(`/contacts-chat-avito/readContactChat/${contactId}`),

	sendToChatAvito: ({contactId, text}: {contactId: string, text: string}) =>
		Client.post(`/contacts-chat-avito/send/${contactId}`, {text})

};
