import { ReactNode, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { add } from "date-fns";
import cn from "classnames";
import { Button, CollapseDown, Loader, Tooltip } from "@sdelka_crm/sdelka-crm-component-library";

import { CianTariff, PlatformPaidServicesData } from "../../../../../../../../../../types";
import { CounterInput, DotProgress, Select, TableSimple } from "../../../../../../../../../UI";
import { usePaidServices, usePaidServicesTableData } from "../helpers";
import { useUpdateCian } from "../../../../../../../../../../service/api/mutations";
import { prepareStats } from "./helpers";

import styles from "./index.module.scss";

type Props = {
	data: PlatformPaidServicesData;
	onTariffChange: (tariff: CianTariff) => void;
};

const dateOptions = [
	{ value: "1", label: "1 день" },
	{ value: "7", label: "7 дней" },
	{ value: "14", label: "14 дней" },
	{ value: "28", label: "28 дней" },
];

const dotsTemplate = {
	standart: "Стандарт",
	premium: "Premium",
	top: "Top",
};

const cianTariffMap = {
	...dotsTemplate,
	"": "",
};

export const CianPaidServices = ({ data, onTariffChange }: Props): JSX.Element => {
	const { paidServices, isLoading, fetchPaidServices, onPaidServiceClick } = usePaidServices(data, "cian");

	const { mutate } = useUpdateCian({
		onSuccess: (successData) => {
			fetchPaidServices(false);
			fetchPaidServices(true);
			onTariffChange(successData.data.cianTariff);
		},
	});

	const displaySelect = (index) => (paidServices?.length && paidServices[index].applied ? "none" : "block");

	const { cianData } = (paidServices || [{}])[0];

	const { register, setValue, watch } = useForm({
		defaultValues: {
			bet: 0,
			date: 1,
		},
	});

	const formWatch = watch();

	const paidServiceClick = (paidData) => {
		const value = formWatch[`date${paidData.tariff}`];

		if (value !== "") {
			const startDate = new Date();
			onPaidServiceClick({
				...paidData,
				startDate,
				endDate: add(startDate, { days: value }),
			});
		}
	};

	const [pricePos, setPricePos] = useState(cianData ? parseInt(cianData.auctionValue, 10) : 0);

	const generateDots = (): {
		label: string;
		disabled: boolean;
		percent?: number;
	}[] => {
		const isStandart = pricePos <= (cianData?.premiumPrice || 0);
		const isPremium = pricePos <= (cianData?.topPrice || 0);

		const standart = cianData?.premiumPrice || 0;
		const premium = cianData?.topPrice || 0;

		const standardPercent = Math.floor((pricePos * 100) / standart);
		const premiumPercent = Math.floor(((pricePos - standart) * 100) / (premium - standart));

		return [
			{
				percent: standardPercent,
				label: dotsTemplate.standart,
				disabled: false,
			},
			{
				percent: isStandart ? 0 : premiumPercent,
				label: `${dotsTemplate.premium} (${standart})`,
				disabled: isStandart,
			},
			{
				label: `${dotsTemplate.top} (${premium})`,
				disabled: isPremium,
			},
		];
	};

	const auctionValue = parseInt(cianData?.auctionValue || "0", 10);
	useEffect(() => setPricePos(auctionValue), [auctionValue]);

	const onPriceChange = (price: number): void => {
		if (price < 1) return;
		setPricePos(price);
	};

	const computeNewTariff = () => {
		if (pricePos > Number(cianData?.topPrice)) {
			return "top";
		}

		if (pricePos > Number(cianData?.premiumPrice)) {
			return "premium";
		}

		return "standart";
	};

	const updateData = () =>
		mutate({
			feedAdId: data.feedAdId,
			cianAuction: pricePos,
			cianTariff: computeNewTariff(),
		});

	const paidServicesTableData = usePaidServicesTableData(paidServices ?? [], paidServiceClick);
	const tariff = cianTariffMap[String(cianData?.cianTariff)];

	return (
		<CollapseDown onClick={fetchPaidServices} label="Подключение платных услуг">
			<Loader style={{ display: isLoading ? "block" : "none" }} height={250} />
			<>
				<div style={{ display: isLoading ? "none" : "block" }} className={styles.root}>
					<div className={styles.body}>
						<div className={styles.tariff}>
							<div className={cn(styles.tooltipContainer, styles.tariffContainer)}>
								<Tooltip classNames={{ root: styles.tooltip }}>{tariff}</Tooltip>
								<div className={styles.info}>{tariff}</div>
							</div>
						</div>

						<div className={styles.formWrapper}>
							<div className={cn(styles.tooltipContainer, styles.auction)}>
								<Tooltip classNames={{ root: styles.tooltip }}>Аукцион</Tooltip>
								<div className={styles.info}>Аукцион</div>
							</div>

							<form className={styles.form}>
								<CounterInput
									name="bet"
									register={register}
									setValue={setValue}
									max={5}
									value={pricePos}
									onChange={onPriceChange}
									className={styles.counterInput}
									label="Ставка:"
									postfix={<span className={styles.rubble}>₽</span>}
								/>

								<div className={styles.progress}>
									<DotProgress dots={generateDots()} />
								</div>

								<Button onClick={updateData} classNames={{ root: styles.button }} variant="text">
									Сделать ставку
								</Button>
							</form>

							<div className={styles.stats}>{prepareStats(cianData)}</div>
						</div>
					</div>

					<div className={styles.footer}>
						{paidServicesTableData && (
							<TableSimple<{
								tariff: ReactNode;
								status: ReactNode;
								action: ReactNode;
							}>
								className={styles.table}
								data={paidServicesTableData}
								cols={[
									{ render: (row) => row.tariff },
									{ render: (row) => row.status },
									{
										render: (row) => {
											const { name, index } = row;

											return (
												<div className={styles.select}>
													<div style={{ display: displaySelect(index) }}>
														<div style={{ width: 100 }}>
															<Select
																classNames={{
																	root: styles.selectWrapper,
																	wrapper: styles.customSelectWrapper,
																}}
																name={`date${name}`}
																register={register}
																setValue={setValue}
																options={dateOptions}
																placeholder="Выбрать срок"
																size="small"
															/>
														</div>
													</div>
												</div>
											);
										},
									},
									{ render: (row) => row.action },
								]}
							/>
						)}
					</div>
				</div>
			</>
		</CollapseDown>
	);
};
