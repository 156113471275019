import { Control, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import { RealEstateFilterField, SelectWithPreviewOption } from "../../../../../../types";

import { InfoRow, SelectWithPreview } from "../../../../index";

import styles from "./index.module.scss";

type Props = {
	field: RealEstateFilterField;
	name: string;
	setValue: UseFormSetValue<any>;
	register: UseFormRegister<any>;
	control: Control<any>;
	disabled?: boolean;
};

export const Repair = ({ field, register, setValue, name, control, disabled }: Props): JSX.Element => {
	const formatRepairFields = (fields): SelectWithPreviewOption[] =>
		fields.map((item) => ({
			group: item.name,
			values: item.allowedValues.map((allowedValue) => ({
				label: allowedValue.name,
				value: allowedValue.name,
				src: allowedValue.image,
			})),
			disabled,
		}));

	const watcher = useWatch({
		control,
		name,
	});

	return (
		<InfoRow
			aspect={{ label: 6, content: 8.1 }}
			label={field.label || "Вид ремонта"}
			variant="noBorder"
			classNames={{ root: styles.repairRow }}
		>
			<SelectWithPreview
				register={register}
				setValue={setValue}
				name={name}
				options={formatRepairFields(field.dataType.allowedValues)}
				defaultValue={watcher}
				multiselect
			/>
		</InfoRow>
	);
};
