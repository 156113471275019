import { api } from "../../api";
import { useQuery } from "react-query";

const DEFAULT_QN = "sdelka/deal/load-deal-generated-document-warnings/DEFAULT_QN";

type Props = {
  applicationId: string
  type?: string
  template?: string
}

export const useLoadGeneratedDocumentsWarnings = ({applicationId, type, template}: Props) => {

  const loadDocumentWarnings = () =>
    api.deals.getGeneratedDocumentWarnings(applicationId, type, template)

  const { data, isLoading, refetch, isFetching, isError } = useQuery(
    [DEFAULT_QN],
    loadDocumentWarnings,
  );

  return {
    warnings: data?.data.warnings,
    isLoading,
    refetch,
    isFetching,
    isError
  }
}