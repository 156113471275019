import { useState } from "react";
import cn from "classnames";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { EditMode, ShowMode, translateContactType } from "./components";
import { capitalizeFirstLetter } from "../../../../../../utils/formatters";
import { Contact } from "../../../../../../types";
import { ReactComponent as PenIcon } from "../../../../../../assets/icons/pencil.svg";
import styles from "./index.module.scss";

type Props = {
  contact: Contact;
  sectionId: string;
  refetch: () => void;
};

export const General = ({contact, sectionId, refetch}: Props): JSX.Element => {
  const [showMode, setShowMode] = useState<boolean>(true);
  const toggleMode = () => setShowMode((prev) => !prev);

  const secondName = capitalizeFirstLetter(contact?.secondName || "");
  const name = capitalizeFirstLetter(contact?.name || "");
  const lastName = capitalizeFirstLetter(contact?.lastName || "");
  const pseudonym = capitalizeFirstLetter(contact?.pseudonym || "");
  const type = capitalizeFirstLetter(translateContactType(contact?.type) || "")
  const FIO = `${pseudonym} ${lastName} ${name} ${secondName}`;

  return (
    <div id={sectionId}>
      {showMode &&
        <div className={cn(styles.row, styles.header)}>
          <div className={styles.row}>
            <div className={styles.title}>
              {FIO}
            </div>
            <div className={styles.type}>
              {type}
            </div>
          </div>
          <Button variant="text" Icon={PenIcon} onClick={toggleMode}>
            Редактировать
          </Button>
        </div>}
      {showMode ? (
        <ShowMode contact={contact}/>
      ) : (
        <EditMode contactId={contact.id} refetch={refetch} toggleMode={toggleMode} contact={contact}/>
      )}
    </div>
  );
};
