import { TaskStatusEnum, TaskStatusVariant } from "../../../../../../types";

import { getAppropriateDiff, mapDiffToLabel } from "./utils";

import styles from "./index.module.scss";

type Props = {
	status: TaskStatusVariant;
	isRework?: boolean;
	isSuccessFinished?: boolean;
	deadline?: Date | string;
};

const getDiffLabel = (deadline: Date | string) => {
	const isDeadlineCrossed = new Date() > new Date(deadline);
	const diff = getAppropriateDiff(deadline);
	diff.value = Math.abs(diff.value);

	return mapDiffToLabel(diff, isDeadlineCrossed);
};

export const StatusBadge = ({ status, isRework, isSuccessFinished, deadline }: Props): JSX.Element => {
	const getLabel = () => {
		if (isRework) return "Возвращенная";

		switch (status) {
			case TaskStatusEnum.inWork:
				return deadline ? getDiffLabel(deadline) : "В работе"; // fallback label

			case TaskStatusEnum.done:
				return isSuccessFinished ? "Завершена успешно" : "Завершена неуспешно";

			case TaskStatusEnum.failed:
				return "Провалена";

			default:
				return "";
		}
	};

	const label = getLabel();

	return <span className={styles.root}>{label}</span>;
};
