import { useDispatch } from "react-redux";
import { openModal } from "../../../../../../../../../../../../../../../service/redux/modules/modal/modal.action";
import { CancelDealModal } from "./CancelDealModal";
import styles
  from "../../../../../../../../../../../../../../UI/ApplicationComponents/guarantee-payment/success-modal/index.module.scss";
import { selectDevice, useSelect } from "../../../../../../../../../../../../../../../service/redux/selectors";

type Props = {
  applicationId: string

}
export const useCancelDealModal = (
  {
    applicationId,

  }: Props) => {
  const dispatch = useDispatch()
  const {isMobile} = useSelect(selectDevice)

  const openCancelDealModal = () => {
    dispatch(
      openModal({
        body: <CancelDealModal applicationId={applicationId}/>,
        width: 660,
        classNames: {
          contentWrapper: isMobile ? styles.modal : "",
          buttonClose: isMobile ? styles.modalClose : ""
        },
      })
    )
  }
  return {openCancelDealModal}
}