import { useHistory } from "react-router";
import { RealEstate } from "../../types";

import { openInNewWindow } from "../openInNewWindow";

type ReturnType = (realEstate: RealEstate) => void;

type UseOpenRealEstate = {
	locationFrom?: "regular-list" | "public-vote-list" | "parser-list";
	options?: {
		inNewTab?: boolean;
		mode?: "edit" | "view";
	};
};

export const useOpenRealEstate = ({
	locationFrom = "regular-list",
	options = {},
}: UseOpenRealEstate): ReturnType => {
	const { push, location } = useHistory();
	const { inNewTab, mode } = options;

	const encodedBackPath = encodeURIComponent(`${location.pathname}${location.search}`);
	const open = (url: string) => {
		if (inNewTab) {
			openInNewWindow(url);
		} else {
			push(url);
		}
	};

	switch (locationFrom) {
		case "public-vote-list": {
			return (realEstate: RealEstate) => {
				const url = `public-real-estate/${realEstate.id}?backPath=${encodedBackPath}&withVote=true`;
				open(url);
			};
		}

		case "parser-list": {
			return (realEstate: RealEstate) => {
				const url = `/real-estate/parser/${realEstate.id}?&backPath=${encodedBackPath}`;
				open(url);
			};
		}

		case "regular-list":
		default: {
			return (realEstate: RealEstate) => {
				const url = `/real-estate/application/${realEstate.applicationId}?mode=${
					mode || "view"
				}&backPath=${encodedBackPath}`;
				open(url);
			};
		}
	}
};
