import { CartTInitialState } from "../../types";
import { CartAction, CLEAN_UP, SET_HOVERED_RE_IMG } from "./cart.action";

const initialState: CartTInitialState = {
	hoveredCartItemImg: undefined,
};

export const cartReducer = (
	state = initialState,
	action: CartAction
): CartTInitialState => {
	switch (action.type) {
		case SET_HOVERED_RE_IMG: {
			return {
				...state,
				hoveredCartItemImg: action.hoveredCartItemImg,
			};
		}
		case CLEAN_UP: {
			return {
				...state,
				hoveredCartItemImg: undefined,
			};
		}
		default:
			return state;
	}
};
