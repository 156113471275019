import { Task, TaskStatusEnum, TaskVirtualStatus } from "../../../../../../../../../../types";

/** https://stackoverflow.com/questions/42136098/array-groupby-in-typescript */
const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
	list.reduce((previous, currentItem) => {
		const group = getKey(currentItem);
		// eslint-disable-next-line no-param-reassign
		if (!previous[group]) previous[group] = [];
		previous[group].push(currentItem);

		return previous;
	}, {} as Record<K, T[]>);

export const groupTasks = (tasks: Task[] = []): Record<TaskStatusEnum, Task[]> =>
	groupBy(tasks, (task) => task.status);
