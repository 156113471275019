import { useState, useEffect } from "react";
import { RealEstateSchemaConfigField, FormPropsEditRealEstate } from "../../../../../../../../../../types";

import { InfoRow } from "../../../../../../../../index";
import { useSelect, selectObjectEdit } from "../../../../../../../../../../service/redux/selectors";

import styles from "./index.module.scss";
import { NumberInput } from "../NumberInput";

const CALCULATION = ["Первая", "Вторая", "Третья", "Четвертая", "Пятая", "Шестая", "Седьмая", "Восьмая"];

type Props = {
	form: FormPropsEditRealEstate;
	field: RealEstateSchemaConfigField;
};

export const RoomsSquareList = ({ form, field }: Props): JSX.Element => {
	const { setValue } = form;
	const {
		label,
		dataType: { name },
	} = field;
	const [inputsCount, setInputsCount] = useState(0);
	const { roomsAmount } = useSelect(selectObjectEdit);

	useEffect(() => {
		if (!Number.isNaN(Number(roomsAmount))) {
			setInputsCount(Number(roomsAmount));
		} else {
			setInputsCount(0);
		}

		for (let i = Number(roomsAmount) || 0; i < 6; i += 1) {
			setValue(`${name}.${i}` || "", "");
		}
	}, [roomsAmount]);

	return (
		<>
			{new Array(inputsCount).fill(1).map((_, index) => (
				// eslint-disable-next-line react/no-array-index-key
				<div key={`${name}-${index}`}>
					{index === 0 && (
						<InfoRow
							label={label}
							aspect={{ label: 1, content: 1 }}
							labelFont="ubuntu"
							classNames={{ root: styles.customInfoRow }}
						>
							<NumberInput
								form={form}
								field={field}
								name={`${name}.${index}`}
								placeholder={CALCULATION[index]}
								onlyPositive
							/>
						</InfoRow>
					)}

					{index !== 0 && (
						<InfoRow
							label="hide it"
							aspect={{ label: 1, content: 1 }}
							labelFont="ubuntu"
							classNames={{ root: styles.customInfoRow }}
						>
							<NumberInput
								form={form}
								field={field}
								name={`${name}.${index}`}
								placeholder={CALCULATION[index]}
								onlyPositive
							/>

							<div className={styles.hideLabel} />
						</InfoRow>
					)}
				</div>
			))}
		</>
	);
};
