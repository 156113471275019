import { KindType, StageConditions, User } from "../../../../types";
import { selectCurrentAccount, useSelect } from "../../../../service/redux/selectors";
import { Responsibles } from "../Responsibles";
import React from "react";
import { Plug } from "../helpers";
import { useIsTelegram } from "../../../../utils/telegram/useIsTelegram";

type Props = {
  scrollY?: number;
  applicationId?: string;
  stageConditions?: StageConditions;
  lawyer?: User;
  refetch: () => void;
  kind?: KindType
  isMobile?: boolean
};

export const DealLawyer = (
  {
    applicationId = "",
    scrollY,
    stageConditions,
    lawyer,
    refetch,
    kind,
    isMobile
  }: Props) => {

  const {currentAccount} = useSelect(selectCurrentAccount)
  const isCoWorking = currentAccount && currentAccount.position?.name === "Агент в коворкинге"

  const {executeUrl} = useIsTelegram()

  const onClickOnPlug = () => {
    executeUrl(`/services`)
  }

  const plugText = <div style={{display: "flex", gap: "4px"}}>
    <div>Вы можете</div>
    <div style={{cursor: "pointer", textDecoration: "underline"}} onClick={onClickOnPlug}>
      заказать юридическое сопровождение
    </div>
    <div>этой сделки.</div>
  </div>


  return (
    <div style={{marginTop: isMobile ? "40px" : "60px"}}>
      {!isCoWorking
        ? <>
          {lawyer
            ? <div>
              <Responsibles
                scrollY={scrollY}
                applicationId={applicationId}
                stageConditions={stageConditions}
                responsibleUser={lawyer}
                refetch={refetch}
                kind={kind}
                atDeal
                withoutDots
                headTitle="Юрист по сделке"
                isMobile={isMobile}
              />
            </div>
            : <div>
              <Plug plugText={plugText} mini={isMobile}/>
            </div>
          }
        </>
        : <div>
          <Responsibles
            scrollY={scrollY}
            applicationId={applicationId}
            stageConditions={stageConditions}
            responsibleUser={lawyer}
            refetch={refetch}
            kind={kind}
            withoutDots
            headTitle="Юрист по сделке"
            isMobile={isMobile}
          />
        </div>
      }
    </div>
  )
}