import { useMutation } from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import { ContactCreate, CreateContactForm, Contact } from "../../../../types";
import { toastError } from "../../../../components/UI";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/contact/create-contact/DEFAULT_MN";

type UseContactCreate = {
	onSuccess?: (data: Contact) => void;
};

export const useContactCreate = ({ onSuccess }: UseContactCreate) => {
	const onError = (error: AxiosError) => {
		toastError({
			text: "Произошла ошибка при создании контакта",
		});
	};

	const onSuccessContactCreate = (data: AxiosResponse<Contact>) => {
		if (onSuccess) {
			onSuccess(data.data);
		}
	};

	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: (payload: ContactCreate | CreateContactForm) => api.contacts.create(payload),
		onSuccess: (data: AxiosResponse<Contact>) => onSuccessContactCreate(data),
		onError,
	});

	return {
		mutate: mutate.mutate,
		mutateAsync: mutate.mutateAsync,
		isLoading: mutate.isLoading,
	};
};
