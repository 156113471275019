import { useDispatch } from "react-redux";
import { Contact as ContactType, LeadUpdatePayload } from "../../../../../../types";

import { useUpdateLead } from "../../../../../../service/api/mutations";
import { closeModal, deactivateLoading } from "../../../../../../service/redux/modules/modal/modal.action";

type UseOnPinContact = {
	leadId: string;
	refetch: () => void;
};

export const useOnPinContact = ({ leadId, refetch }: UseOnPinContact) => {
	const dispatch = useDispatch();

	const { mutateAsync } = useUpdateLead({
		onSuccess: () => {
			refetch();
			dispatch(deactivateLoading());
			dispatch(closeModal());
		},
		onError: () => {
			dispatch(deactivateLoading());
		},
	});

	const onPinContact = async (contact: ContactType | null | undefined, onPinSuccess?: () => void) => {
		const payload: LeadUpdatePayload = {
			id: leadId,
			data: {
				contactId: contact?.id ?? null,
			},
		};

		await mutateAsync(payload);

		if (onPinSuccess) {
			onPinSuccess();
		}
	};

	return { onPinContact };
};
