import styles from "./index.module.scss"
import { ReactComponent as Filters } from "../../../../../../assets/icons/filters.svg";
import { ReactComponent as CancelIcon } from "../../../../../../assets/icons/cancel.svg";
import { ReactComponent as MapIcon } from "../../../../../../assets/icons/iconMap.svg";
import { RealEstateFilter, RealEstateListTab } from "../../../../../../types";
import { useDrawFilter, useFormFilter } from "../../ObjectListFilters/ObjectListFastFilters/helpers";
import { Dispatch, SetStateAction } from "react";

type Props = {
  filtersCount: number
  clearFilters: () => void
  openMap: () => void
  filter: RealEstateFilter;
  tab: RealEstateListTab;
  isParser?: boolean
  setFilter: Dispatch<SetStateAction<RealEstateFilter>>;
  setObjectsFilterTypes: Dispatch<SetStateAction<string[]>>;
}

export const MobileFiltersMainButtons = (
  {
    filtersCount,
    clearFilters,
    openMap,
    filter,
    tab,
    isParser,
    setFilter,
    setObjectsFilterTypes
  }: Props) => {

  const {
    reset,
    getValues,
  } = useFormFilter({filter});

  const {openFiltersDrawer} = useDrawFilter({
    setFilter,
    reset,
    getValues,
    setObjectsFilterTypes,
    tab,
    isParser,
    isMobile: true
  });

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <div className={styles.button} onClick={openFiltersDrawer}>
          <Filters/>
          {filtersCount !== 0 &&
            <div className={styles.selectedFilters}>{filtersCount}</div>
          }
        </div>
        <div className={styles.buttonClear} onClick={clearFilters}>
          <span>Сбросить</span>
          <CancelIcon/>
        </div>
      </div>
      <div className={styles.button} onClick={openMap}>
        <MapIcon/>
      </div>
    </div>
  )
}