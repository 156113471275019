import { ReactNode } from "react";
import cn from "classnames";
import { Contact, ContactPinDefaultValues } from "../../../../../../types";

import { CardContact, PinContact } from "../../../../../UI";

import styles from "../index.module.scss";

type Props = {
	addContact: (contact: Contact, contactType: string) => void;
	contact: {
		contactType: string;
		contactTitle: string;
		contactButtonTitle: string;
		contactData?: Contact;
		setContact: (data: undefined) => void;
	};
	children?: ReactNode;
	className?: string;
	defaultValues?: ContactPinDefaultValues;
};

export const ContactSelect = ({
	className,
	contact: { contactData, contactTitle, contactType, setContact, contactButtonTitle },
	addContact,
	children,
	defaultValues,
}: Props): JSX.Element => (
	<>
		{!contactData ? (
			<div className={cn(styles.wrapper, className)}>
				<PinContact
					classNames={{ contentWrapper: styles.contactContent }}
					onPin={(contact: Contact) => addContact(contact, contactType)}
					defaultValues={defaultValues}
				>
					<div className={styles.button}>{contactButtonTitle}</div>
				</PinContact>

				{children}
			</div>
		) : (
			<CardContact title={contactTitle} contact={contactData} onClose={() => setContact(undefined)} />
		)}
	</>
);
