import { useDispatch } from "react-redux";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { logoutAsEmployeeThunk } from "../../../../../../service/redux/modules/auth/auth.thunk";
import { ReactComponent as AlertInfoIcon } from "../../../../../../assets/icons/alert-info.svg";
import { ReactComponent as CloseIcon } from "../../../../../../assets/icons/close.svg";
import styles from "./index.module.scss";

type Props = {
  employerSubscribtionFinished?: boolean
}

export const AlertRedLine = (
  {
    employerSubscribtionFinished
  }: Props): JSX.Element => {
  const dispatch = useDispatch();

  const handleLogoutAsEmployee = () => {
    dispatch(logoutAsEmployeeThunk());
  };

  const text = employerSubscribtionFinished ? "Оплаченный срок доступа к СДЕЛКА закончен. Оплатите доступ." : "Вы вошли с аккаунта сотрудника!"

  return (
    <div className={styles.redLine}>
      <AlertInfoIcon/>

      <span>{text}</span>

      <div className={styles.buttonContainer}>
        <Button variant="text" onClick={handleLogoutAsEmployee} classNames={{root: styles.button}}>
          Выйти
          <CloseIcon className={styles.closeSvg}/>
        </Button>
      </div>
    </div>
  );
};
