import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../../../../../../../../../../../service/redux/modules/modal/modal.action";
import styles from "./index.module.scss";
import { ReactComponent as Alert } from "../../../../../../../../../../../../../../../assets/icons/iconWarnBig.svg";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useTerminateDeal } from "../../../../../../../../../../../../../../../service/api/mutations";
import { closeDrawer } from "../../../../../../../../../../../../../../../service/redux/modules/drawer/drawer.action";

type Props = {
  applicationId: string
}

export const CancelDealModal = ({applicationId}: Props) => {

  const dispatch = useDispatch()

  const onSuccessCancel = () => {
    dispatch(closeDrawer())
    dispatch(closeModal())
  }

  const {mutate: mutateTerminateDeal} = useTerminateDeal({onSuccess: onSuccessCancel})

  const onApply = () => {
    mutateTerminateDeal(applicationId)
  }

  const onDeny = () => {
    dispatch(closeModal())
  }

  return (
    <>
      <div className={styles.row}>
        <div>
          <Alert/>
        </div>
        <div className={styles.column}>
          <div className={styles.title}>Вы уверены, что сделка сорвалась?</div>
        </div>
      </div>
      <div className={styles.rowEnd}>
        <Button onClick={onDeny} variant="outlined">
          Отмена
        </Button>
        <Button onClick={onApply} classNames={{root: styles.buttonCreate}}>
          Согласен
        </Button>
      </div>
    </>
  )
}