import { useDispatch } from "react-redux";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { setObjectsGroupIds } from "../../../../../service/redux/modules/object-list/object-list.action";
import { useSelector } from "../../../../../service/redux/useSelector";

import { ReactComponent as ArrowIcon } from "../../../../../assets/icons/arrow-down.svg";
import styles from "./index.module.scss";

export const ObjectsGroupHeader = (): JSX.Element => {
	const { objectsGroupIds } = useSelector((state) => state.objectList);
	const dispatch = useDispatch();

	return (
		<>
			<Button
				variant="text"
				Icon={ArrowIcon}
				classNames={{ root: styles.button }}
				onClick={() => dispatch(setObjectsGroupIds([]))}
			>
				Вернуться к списку
			</Button>

			<div className={styles.header}>{`Количество объектов в группе - ${objectsGroupIds.length}`}</div>
		</>
	);
};
