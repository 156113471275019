import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { IndividualInput } from "../../../../../../UI";
import { PositionsPermissions } from "../../../../helpers";
import { usePositionCreate } from "../../../../../../../service/api/mutations";
import { commonErrors } from "../../../../../../../utils/errors";

import { ReactComponent as PlusIcon } from "../../../../../../../assets/icons/plus.svg";
import styles from "./index.module.scss";

type Props = {
	refetch: () => void;
};

export const CreatePositionForm = ({ refetch }: Props): JSX.Element => {
	const [isPositionInCreate, setIsPositionInCreate] = useState(false);

	const schema = yup.object().shape({
		name: yup.string().required(commonErrors.required).max(30, "Слишком длинное название"),
	});

	const { register, handleSubmit, reset, formState } = useForm({
		resolver: yupResolver(schema),
		defaultValues: { name: "" },
	});

	const { mutate, isLoading } = usePositionCreate({
		onSuccess: () => {
			setIsPositionInCreate(false);
			reset({ name: "" });
			refetch();
		},
	});

	const createPosition = (values: { name: string }) => {
		mutate({ name: values.name, permission: PositionsPermissions });
	};

	return (
		<>
			{isPositionInCreate && (
				<form onSubmit={handleSubmit(createPosition)} className={styles.create}>
					<IndividualInput
						name="name"
						placeholder="Должность"
						defaultValue=""
						isLoading={isLoading}
						register={register}
						error={formState.errors.name?.message?.toString()}
						options={{
							initialEditMode: true,
							stopCloseOnClickOutside: true,
						}}
					/>
				</form>
			)}

			<Button
				variant="text"
				Icon={!isPositionInCreate ? PlusIcon : undefined}
				onClick={() => setIsPositionInCreate((prev) => !prev)}
				classNames={{ root: styles.buttonCreate }}
			>
				{isPositionInCreate ? "Отменить создание" : "Создать должность"}
			</Button>
		</>
	);
};
