import { useDispatch } from "react-redux";
import { openModal } from "../../../../../../../../../../../../../../../service/redux/modules/modal/modal.action";
import { ConfirmCreateDealModal } from "./ConfirmCreateDealModal";
import styles
  from "../../../../../../../../../../../../../../UI/ApplicationComponents/guarantee-payment/success-modal/index.module.scss";
import { selectDevice, useSelect } from "../../../../../../../../../../../../../../../service/redux/selectors";

type Props = {
  applicationId: string
  inDrawerInDrawer?: boolean;
}
export const useConfirmCreateDealModal = (
  {
    applicationId,
    inDrawerInDrawer
  }: Props) => {
  const dispatch = useDispatch()
  const {isMobile} = useSelect(selectDevice)

  const openConfirmCreateDealModal = () => {
    dispatch(
      openModal({
        body: <ConfirmCreateDealModal applicationId={applicationId} inDrawerInDrawer={inDrawerInDrawer}/>,
        width: 660,
        classNames: {
          contentWrapper: isMobile ? styles.modal : "",
          buttonClose: isMobile ? styles.modalClose : ""
        },
      })
    )
  }
  return {openConfirmCreateDealModal}
}