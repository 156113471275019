import { useQuery } from "react-query";

import { api } from "../../index";

type UseLoadYourObjects = {
	contactId: string;
};

export const useLoadYourObjects = ({ contactId }: UseLoadYourObjects) => {
	const loadYourObjects = () => api.publicPage.objects(contactId);

	const { data, isLoading, refetch } = useQuery("loadYourObjects", loadYourObjects);

	return {
		data: data?.data || [],
		isLoading,
		refetch,
	};
};
