import styles from "./index.module.scss"
import { CodeInput } from "../../../../../../UI/CodeInput/CodeInput";
import {
  setStep,
  setVerificationCode
} from "../../../../../../../service/redux/modules/registration-steps/reg-steps.action";
import { useEffect, useState } from "react";
import { RegistrationPayloadV2, RegistrationResponse, RegistrationResponse2 } from "../../../../../../../types";
import { selectRegisterSteps, useSelect } from "../../../../../../../service/redux/selectors";
import { useDispatch } from "react-redux";
import { useTimer } from "../../../../../../../utils/hooks";
import {
  SmsCodeConfirmationResponse,
  usePublicOfferRequest,
  usePublicOfferSmsConfirm,
  useRegistration
} from "../../../../../../../service/api/mutations";
import { digitOnly } from "../../../../../../../utils/string";
import { AxiosResponse } from "axios";
import { useJoinToSdelkaPro } from "../../../../../../../service/api/mutations/auth/useJoinToSdelkaPro";
import { useHistory } from "react-router-dom";
import { TokenService } from "../../../../../../../service/api/services/inner/TokenService";
import { initialize, useFinishRegistration } from "../../../../../../../service/redux/modules/auth/auth.thunk";
import { closeModal } from "../../../../../../../service/redux/modules/modal/modal.action";
import { closeDrawer } from "../../../../../../../service/redux/modules/drawer/drawer.action";
import { JoinSdelkaProModal } from "../../openJoinSdelkaProModal";
import { toastError } from "../../../../../../UI";

export const ConfirmationCodeModal = () => {
  const [sessionData, setSessionData] = useState<RegistrationResponse | null>(null);
  const {registrationData} = useSelect(selectRegisterSteps);
  const dispatch = useDispatch();
  const [verToken, setVerToken] = useState("")
  const [isErrorSms, setIsErrorSms] = useState(true)
  const [timer, handleTimer] = useTimer(3);
  const [initialValue, setInitialValue] = useState("")
  const {getLastMutationCacheSms, getLastMutationCache} = useRegistration({});

  const onSuccessLoadToken = (response: AxiosResponse<RegistrationResponse2>) => {
    setVerToken(response.data.verificationToken)
    setIsErrorSms(false)
  }

  const onErrorLoadToken =  () => {
    toastError({text: `Произошла внутренняя ошибка, обновите страницу и повторите отправку кода повторно!`})
  }

  const {asyncMutate: joinSdelkaPro} = useJoinToSdelkaPro()
  const history = useHistory()

  const {openJoinSdelkaProModal} = JoinSdelkaProModal();

  const {mutate: loadToken} = usePublicOfferRequest({onSuccess: onSuccessLoadToken, onError: onErrorLoadToken})

  const onSuccess = async (res: SmsCodeConfirmationResponse) => {
    if ((res as any).data.success === true) {
      try {
        const response = await joinSdelkaPro({})
        if (response) {
          if (response.data[ 0 ].isAddressSelected !== true) {
            TokenService.setCurrentToken(response.data[ 0 ].token)
            // eslint-disable-next-line react-hooks/rules-of-hooks
            await dispatch(useFinishRegistration());
            dispatch(initialize())
            dispatch(closeModal());
            dispatch(closeDrawer());
            history.push("/real-estate?tab=PARSED")
            openJoinSdelkaProModal()
          } else {
            if (response.data[ 0 ].isOfficeSelected === true) {
              TokenService.setCurrentToken(response.data[ 0 ].token)
              // eslint-disable-next-line react-hooks/rules-of-hooks
              await dispatch(useFinishRegistration());
              dispatch(initialize())
              dispatch(closeModal());
              dispatch(closeDrawer());
              history.push("/real-estate?tab=PARSED")
            } else {
              TokenService.setCurrentToken(response.data[ 0 ].token)
              // eslint-disable-next-line react-hooks/rules-of-hooks
              await dispatch(useFinishRegistration());
              dispatch(initialize())
              dispatch(closeModal());
              dispatch(closeDrawer());
              history.push("/select-office/list?tab=SELECT_OFFICE")
            }
          }
        }
      } catch (error) {
        console.error("Error during mutation:", error);
      }
    } else {
      toastError({text: "Неверный код!"})
    }
  }

  const {mutate: confirmCode, isSuccess, error} = usePublicOfferSmsConfirm({onSuccess})

  useEffect(() => {
    if (registrationData) {
      loadToken(registrationData as RegistrationPayloadV2 || {phone: ""})
    }
  }, [registrationData])

  const errorMessage = (error as any)?.response?.data?.error.errorCode && "Неверный код"

  useEffect(() => {
    handleTimer();
  }, []);

  useEffect(() => {
    const lastRegisterMutation = getLastMutationCache();
    if (lastRegisterMutation?.state?.status === "success") {
      const response = lastRegisterMutation.state?.data as any;
      setSessionData(response.data);
    }
  }, []);

  const onSubmit = (values: { code: string }) => {

    dispatch(setVerificationCode(values.code))
    confirmCode({
      phone: registrationData?.phone || "",
      verificationToken: verToken,
      code: digitOnly(values.code),
    });
    if (!isSuccess) {
      setInitialValue("")
    }
  };

  const resetCode = async () => {
    try {
      const lastRegisterMutation = getLastMutationCacheSms();
      const {data}: any = await lastRegisterMutation.execute();
      setSessionData(data as RegistrationResponse);
      handleTimer();
    } catch (err) {
      console.error("Error resetting code:", err);
    }
  };
  if (!sessionData) {
    <>Произошла ошибка, попробуйте снова</>;
  }

  return (
    <div className={styles.wrap}>
      <p className={styles.message}>
        {registrationData?.phone && `Код отправлен на номер ${registrationData.phone.replace(/[()]/g, '')}`} {` `}
      </p>
      <CodeInput
        onSubmit={onSubmit}
        resetCode={resetCode}
        setBackStep={() => dispatch(setStep("first-step"))}
        timer={timer}
        initialValue={initialValue}
        errorMessage={errorMessage}
        isError={isErrorSms}
      />
    </div>
  );
}