import { api } from "../../api";
import { useQuery } from "react-query";

const DEFAULT_QN = "sdelka/dashboard/use-load-dashboard-statistic/DEFAULT_QN";

export const useLoadDashboardStatistic = () => {
  const loadDashboardStatistic = () => api.dashboard.get()

  const {data, isLoading, refetch, remove, error, isFetching} = useQuery(
    DEFAULT_QN,
    loadDashboardStatistic
  )

  return {
    statistic: data?.data,
    isLoading,
    isFetching,
    refetch,
    remove,
    error
  }
}