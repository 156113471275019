import { TasksMain } from "./components";

import styles from "./index.module.scss";
import { Tabs } from "@sdelka_crm/sdelka-crm-component-library";

type Props = {
  isMobile?: boolean
}

const tabsItems = [
  <div className={styles.element} style={{width: 100}}>Задачи</div>,
]

export const TasksContent = ({isMobile}: Props): JSX.Element => (
  <div className={styles.root}>
    <div className={styles.tabs}>
      <Tabs
        tabs={tabsItems}
        size="large"
        active={0}
        offset={36}
        classNames={{ wrapper: styles.tabsRoot}}
      />
    </div>
    <TasksMain isMobile={isMobile}/>
    {/* {!isMobile && <TasksAttention/>} */}
  </div>
);
