import styles from "./index.module.scss"
import { useLoadDeals } from "../../../../service/api/queries/deals/useLoadDeals";
import { Plug } from "../helpers";
import { CardApplication } from "../../Card/card-application";
import { KindType } from "../../../../types";
import cn from "classnames";
import { CardApplicationMini } from "../../Card/card-application/card-application-mini";

type Props = {
  applicationId: string
  isArchived?: boolean
  isMobile?: boolean
}

export const ChildDeals = (
  {
    applicationId,
    isArchived,
    isMobile
  }: Props): JSX.Element => {
  const {list: dealsList} = useLoadDeals({
    enabled: true, defaultFilter: {
      parentApplicationId: applicationId,
    }
  });

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        Сделки
      </div>
      {dealsList && dealsList.length > 0
        ? <div className={styles.list}>
          {dealsList.map((app) => (
              <div key={app.id} className={cn(styles.card, {[ styles.archive ]: isArchived})}>
                {isMobile ?
                  <CardApplicationMini
                    application={app}
                    isMobile={isMobile}
                  />
                  :
                  <CardApplication
                    withStage
                    canOpenANewTab
                    application={app}
                    kind={KindType.DEAL}
                  />}
              </div>
            )
          )}
        </div>
        : <Plug plugText="У вас пока нет существующей сделки" mini={isMobile}/>
      }
    </div>
  )
}