import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { ContactPreviewCardHOCFromContact } from "../../../../../../../../../../../UI";
import { RelationsInfo } from "./components";
import { useAddToMyContacts } from "./helpers";
import { FindContactByPhoneResponseExist, ExistContact } from "../../../../../../../../../../../../types";

import styles from "./index.module.scss";

type Props = {
	foundContact: FindContactByPhoneResponseExist;
};

export const ExistedContact = ({ foundContact }: Props): JSX.Element => {
	const { contact, result, relations } = foundContact;
	const { id } = contact;

	const { isLoading, handleMutate } = useAddToMyContacts({ contactId: id });

	const showAddBtn = result === ExistContact.existInCompany;

	return (
		<div className={styles.root}>
			<RelationsInfo relations={relations} />

			<div className={styles.secondLine}>
				<ContactPreviewCardHOCFromContact contact={contact} />

				{showAddBtn && (
					<Button isLoading={isLoading} onClick={handleMutate} variant="text">
						{" "}
						Добавить в контакты
					</Button>
				)}
			</div>
		</div>
	);
};
