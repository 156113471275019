import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { PinModal } from "./PinModal";
import { openModal } from "../../../../service/redux/modules/modal/modal.action";
import { LeadTargetPinPayload } from "../../../../types";

type Props = {
  children: ReactNode;
  onPinSelected: (pinPayload: LeadTargetPinPayload) => void;
  hasObjectWithoutApplication?: boolean;
};

export const PinObject = (
  {
    children,
    onPinSelected,
    hasObjectWithoutApplication,
  }: Props): JSX.Element => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      openModal({
        body: (
          <PinModal
            onPinSelected={onPinSelected}
            hasObjectWithoutApplication={hasObjectWithoutApplication}
          />
        ),
        width: 840,
      })
    );
  };

  return <div onClick={handleClick}>{children}</div>;
};
