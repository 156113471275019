export interface ParallaxArgs {
	windowWidth: number;
	windowHeight: number;
	cursorX: number;
	cursorY: number;
}

export function scaleDownFromCenter({
	windowWidth,
	windowHeight,
	cursorX,
	cursorY,
}: ParallaxArgs): [string, string] {
	const x = `${(cursorX - windowWidth) * 0.01}%`;
	const y = `${(cursorY - windowHeight) * 0.01}%`;

	return [x, y];
}

export function scaleDownBottomTop({ cursorY }: ParallaxArgs): [string, string] {
	const x = "0%";
	let topCord = cursorY * 0.01;

	if (topCord < 0) {
		topCord = 0;
	}

	const y = `${topCord}%`;

	return [x, y];
}

export function scaleDownTopBottom({ cursorY }: ParallaxArgs): [string, string] {
	const x = "0%";
	const y = `-${cursorY * 0.01}%`;

	return [x, y];
}

export function parallaxEffect(ref: HTMLDivElement, translate: (args: ParallaxArgs) => [string, string]) {
	function parallax(e: MouseEvent) {
		const windowWidth = window.innerWidth / 2;
		const windowHeight = window.innerHeight / 2;
		const cursorX = e.clientX;
		const cursorY = e.clientY;
		const [x, y] = translate({
			windowWidth,
			windowHeight,
			cursorX,
			cursorY,
		});
		// eslint-disable-next-line no-param-reassign
		ref.style.top = y;
		// eslint-disable-next-line no-param-reassign
		ref.style.left = x;
	}
	document.addEventListener("mousemove", parallax);

	return () => document.removeEventListener("mousemove", parallax);
}
