import { useForm } from "react-hook-form";
import { RealEstate, CategoryEnum } from "../../../../../types";

import { RadioButton } from "../../../../UI";
import { CardObjectVote } from "./CardObjectVote";
import { useOpenRealEstate } from "../../../../../utils/objects";

import fakeImgSrc from "../../../../../assets/images/fake-object-img.png";
import styles from "./index.module.scss";

const objectExample = {
	actual: "Актуальный",
	adDescription: "<br><br>Номер лота: 4102940",
	addressLabel: "Ленинский, Фестивальная улица, 39",
	category: CategoryEnum["Квартиры"],
	createdAt: "2021-09-02T06:57:17.828Z",
	dealType: "Продажа",
	floor: 3,
	floors: 9,
	id: "a2697a68-da41-443f-a515-19544d97732b",
	modifiedAt: "2021-09-02T16:31:43.216Z",
	objectDescription: "https://www.avito.ru/ulyanovsk/kvartiry/2-k._kvartira_53m_39et._1734970107\n",
	photos: [{ photo1280: fakeImgSrc }],
	responsibleUser: {},
	price: "200000",
	pricePerUnit: "40000",
	rooms: "2",
	shortId: "4102940",
	saleSquare: "53.00",
	fullSquare: "53.00",
	unit: "м2",
	metroData: [
		{
			name: "Парнас",
			color: "#0645ad",
		},
		{
			name: "Невский проспект",
			color: "#0645ad",
		},
		{
			name: "Площадь Восстания",
			color: "#d6083b",
		},
	],
} as RealEstate;

const compilationOptions = [
	{ label: "Не оцененные (445)", value: "no_rating" },
	{ label: "Понравились (43)", value: "liked" },
	{ label: "Не понравилось (435)", value: "not_liked" },
	{ label: "Сомневаюсь (2)", value: "doubt" },
];

export const CompilationList = (): JSX.Element => {
	const { control } = useForm();

	const openRealEstate = useOpenRealEstate({
		locationFrom: "public-vote-list",
	});

	const handleCardClick = () => {
		// Replace console log with filter object list to fined real estate object
		openRealEstate(objectExample);
	};

	return (
		<div className={styles.root}>
			<div className={styles.tabs}>
				<RadioButton control={control} name="types" options={compilationOptions} />
			</div>

			<div className={styles.list}>
				{/* Mock array of real estate objects */}
				{[1, 2].map((realEstateItem) => (
					<CardObjectVote key={realEstateItem} clientObject={objectExample} onCardClick={handleCardClick} />
				))}
			</div>
		</div>
	);
};
