import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { ObjectsFilterFromConfig } from "../../../../../UI";
import { useLoadFilters } from "../../../../../../service/api/queries";
import { useDrawerForm, useDrawerFormActions } from "./helpers";
import { BusinessProcess, RealEstateFilter, RealEstateListTab, User } from "../../../../../../types";
import { ReactComponent as Spinner } from "../../../../../../assets/icons/spinner.svg";
import { ReactComponent as Cancel } from "../../../../../../assets/icons/cancel.svg";
import styles from "./index.module.scss";
import cn from "classnames";

const rootId = "object-filters-modal";

type Props = {
  prepareSubmitForm: (values: RealEstateFilter) => void;
  filter: RealEstateFilter;
  users: User[];
  tab: RealEstateListTab;
  isParser?: boolean
  isMobile?: boolean
};

export const ObjectListFiltersModal = (
  {
    prepareSubmitForm,
    filter,
    users,
    tab,
    isParser,
    isMobile
  }: Props): JSX.Element => {
  const {
    objectsList,
    isFetching,
    setFilter: setCountFilter,
    filter: countFilter,
  } = useLoadFilters({
    defaultFilter: {
      category: filter?.category || [],
      type: {
        value: filter.type?.value as BusinessProcess,
      },
    },
    rootId,
    tab,
  });

  const {control, register, setValue, handleSubmit, getValues, reset} = useDrawerForm({filter});

  const {onSubmit, resetForm, setCategory, onChangeTypeValue} = useDrawerFormActions({
    setCountFilter,
    getValues,
    prepareSubmitForm,
    countFilter,
    reset,
  });

  return (
    <div className={styles.root}>
      <form id={rootId} className={styles.content}>
        {isFetching ? (
          <div id={rootId} className={styles.loadingWrapper}>
            <Spinner className={styles.spinner}/>
          </div>
        ) : (
          <>
            <ObjectsFilterFromConfig
              filterBlocks={objectsList}
              control={control}
              setValue={setValue}
              getValues={getValues}
              register={register}
              setCategory={setCategory}
              onChangeTypeValue={onChangeTypeValue}
              users={users}
              isParser={isParser}
            />
            <div className={cn(styles.actions, {[ styles.actionsMobile ]: isMobile})}>
              <Button
                variant="text"
                color="red"
                onClick={resetForm}
                classNames={{root: styles.cancelBtn}}
                Icon={Cancel}
              >
                Очистить
              </Button>
              <Button onClick={handleSubmit(onSubmit)}>
                Показать
              </Button>
            </div>
          </>
        )}
      </form>
    </div>
  );
};
