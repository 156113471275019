import cn from "classnames";
import styles from "./index.module.scss";

// type Tab = {
//   label: string;
//   role: TaskUserRole;
// };
//
// const tabs: Tab[] = [
//   {
//     label: "Завершенные",
//     role: "responsible",
//   },
// ];

// Оставлено до раскоммента
// const tabItems = tabs.map(({label}) => <div>{label}</div>);

type Props = {
  className?: string;
};

export const TasksMainFilters = ({className}: Props): JSX.Element => 
  // const {setRole} = useTasksContext();
  //
  // const onTabChange = (idx: number) => setRole(tabs[ idx ].role);

   (
    <div className={cn(styles.root, className)}>
      {/* <div className={cn(styles.tabs, {[styles.tabsUnset]: tabItems.length === 1})}> */}
      {/*	<Tabs */}
      {/*		onChange={onTabChange} */}
      {/*		tabs={tabItems} */}
      {/*		size="large" */}
      {/*		offset={36} */}
      {/*		classNames={{root: styles.bottomLine, wrapper: styles.tabsRoot}} */}
      {/*	/> */}
      {/* </div> */}
    </div>
  )
;
