import { useEffect, useMemo, useState } from "react";
import { Map as YMapContainer, YMaps, YMapsApi } from "react-yandex-maps";
import { MapContextProvider } from "./MapContext/MapContext";
import { ClusterType, MapProps, OnChangeArgs, PointType } from "../../../types";

/**
 * Компонент карты. Занимает 100% ширины и высоты от родителя.
 */
export const Map = (
  {
    onMapChange,
    onMapClick,
    children,
    getMapInstances,
    options,
  }: MapProps): JSX.Element => {
  const [ymaps, setYmaps] = useState<YMapsApi>();
  const [mapInstance, setMapInstance] = useState<any>();

  useEffect(() => {
    const updateValues = ({newBounds, newCenter, newZoom}: OnChangeArgs) => {
      if (typeof onMapChange === "function") {
        onMapChange({newBounds, newCenter, newZoom});
      }
    };

    const handleInitialState = (instance: any) => {
      const newBounds = instance.getBounds();
      const newZoom = instance.getZoom();
      const newCenter = instance.getCenter("newCenter");
      updateValues({newBounds, newCenter, newZoom});
    };

    if (mapInstance) {
      handleInitialState(mapInstance);
      mapInstance.events.add("boundschange", (e: any) => {
        const newBounds = e.get("newBounds");
        const newZoom = e.get("newZoom");
        const newCenter = e.get("newCenter");
        updateValues({newBounds, newCenter, newZoom});
      });
    }
  }, [mapInstance, onMapChange]);

  const handleOnMapClick = (event: any) => {
    if (onMapClick) {
      onMapClick(event.get("coords"));
    }
  };

  useEffect(() => {
    if (typeof getMapInstances === "function") {
      getMapInstances({ymaps, mapInstance});
    }
  }, [ymaps, mapInstance]);

  const getCenter: [number, number] = useMemo(() => {
    if (options?.center && options?.center[ 0 ] && options?.center[ 1 ]) {
      return [options?.center[ 0 ], options?.center[ 1 ]];
    }

    return [59.94506601286178, 30.31705281156423];
  }, [options?.center, options?.center?.[ 0 ], options?.center?.[ 1 ]]);

  return (
    <MapContextProvider value={{ymaps, mapInstance}}>
      <YMaps>
        <YMapContainer
          width="100%"
          height="100%"
          defaultState={{
            center: getCenter,
            zoom: options?.zoom || 10,
          }}
          onLoad={setYmaps}
          instanceRef={setMapInstance}
          onClick={handleOnMapClick}
          modules={["templateLayoutFactory", "geoObject.addon.balloon", "geoObject.addon.hint"]}
          options={{suppressMapOpenBlock: true, autoFitToViewport: "always"}}
        >
          {children}
        </YMapContainer>
      </YMaps>
    </MapContextProvider>
  );
};

export type { MapProps, ClusterType, PointType };
