import { api } from "../../api";
import { useQuery } from "react-query";

const DEFAULT_QN = "sdelka/dashboard/use-load-dashboard-table/DEFAULT_QN";

type UseLoadDashboardTable = {
  preset: string,
  orderBy: string,
  direction: "ASC" | "DESC",
  dateFrom: Date,
  dateTo: Date
}

export const useLoadDashboardTable = (
  {
    preset,
    orderBy,
    dateTo,
    dateFrom,
    direction
  }: UseLoadDashboardTable) => {
  const loadTable = () => api.dashboard.getTable(preset, orderBy, direction, dateFrom, dateTo)

  const {data, isLoading, refetch, remove, error, isFetching} = useQuery(
    DEFAULT_QN,
    loadTable,
  )

  return {
    table: data?.data,
    isLoading,
    isFetching,
    refetch,
    remove,
    error
  }
}