import { InfoRow } from "../../../../../../../../../../UI";

type Props = {
	partsData: {
		isPossible?: boolean;
		partsAmount?: string;
		partsSquares?: string[];
	};
};

export const CommercialPartsSquare = ({ partsData }: Props): JSX.Element => {
	const { isPossible, partsSquares } = partsData;

	return (
		<>
			<InfoRow
				label="Возможна продажа частями"
				aspect={{
					label: 6,
					content: 7,
				}}
			>
				{isPossible ? "Да" : "Нет"}
			</InfoRow>

			{partsSquares
				?.filter((part) => !!part)
				.map((part, index) => (
					<InfoRow
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						label={`${index + 1} часть — площадь м2`}
						aspect={{
							label: 6,
							content: 7,
						}}
					>
						{`${part} м2`}
					</InfoRow>
				))}
		</>
	);
};
