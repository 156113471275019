import { ReactNode } from "react";
import cn from "classnames";
import { selectDrawer, selectModal, useSelect } from "../../../../../service/redux/selectors";

import styles from "./index.module.scss";
import { useQueryParams } from "../../../../../utils/hooks";
import { useCloseDrawer } from "../../../../../service/servise";

type Props = {
  children: ReactNode;
  wrapperClassName?: string;
  performance?: "high" | "low";
  isMobile?: boolean
};

export const DrawerWrapper = ({children, wrapperClassName, performance, isMobile}: Props): JSX.Element => {

  const {isOpen: isModalOpen} = useSelect(selectModal);
  const {drawerInDrawer, onClose, disableClose, yNavigation} = useSelect(selectDrawer);

  const {close} = useCloseDrawer({atDrawerInDrawer: drawerInDrawer !== undefined})

  const queryNames = yNavigation?.navigation.map(el => ({
    name: el.query?.name || ""
  }))
  const queryNamesDrawerInDrawer = drawerInDrawer?.yNavigation?.navigation.map(el => ({
    name: el.query?.name || ""
  }))
  const queries = yNavigation?.navigation.map(el => el.query?.name || "").concat("mode")
  const queriesDrawerInDrawer = drawerInDrawer?.yNavigation?.navigation.map(el => el.query?.name || "").concat("mode")

  const {removeQueryParam} = useQueryParams(drawerInDrawer !== undefined ? queryNamesDrawerInDrawer || [] : queryNames || []);

  const handleCloseDrawer = () => {
    if (!isModalOpen) {
      if (drawerInDrawer) {
        close()
        removeQueryParam(queriesDrawerInDrawer || [])
      } else {
        if (onClose) {
          onClose();
        }
        if (!disableClose) {
          removeQueryParam(queries || [])
          close()
        }
      }
    }
  };

  return (
    <>
      <div
        onClick={handleCloseDrawer}
        className={cn(styles.drawerBackground, {
          [ styles.lowPerf ]: performance === "low",
        })}
      />

      <div
        className={cn(
          styles.wrapper,
          wrapperClassName,
          {[ styles.mobile ]: isMobile},
          "animate__animated",
          "animate__faster",
          "animate__fadeInRightBig"
        )}
      >
        {children}
      </div>
    </>
  );
};
