import {
  ApplicationInListV2,
  Lead,
  LeadStatusEnum,
  OpenApplicationInDrawer,
  WhoIsClient
} from "../../../../../../../../../types";
import styles from "./index.module.scss"
import { ReactComponent as PlusIcon } from "../../../../../../../../../assets/icons/plus-small.svg";
import { CardApplicationMini } from "../../../../../../../../UI/Card/card-application/card-application-mini";
import { Plug } from "../../../plug/Plug";
import cn from "classnames";
import {
  useCreatePreApplicationModal,
  useOpenLeadInDrawer,
  useQueryParams
} from "../../../../../../../../../utils/hooks";
import { toastError } from "../../../../../../../../UI";
import { useCloseDrawer, useOpenPreApplication } from "../../../../../../../../../service/servise";

type Props = {
  preApplications?: ApplicationInListV2[]
  status: LeadStatusEnum
  lead: Lead
  refetch: () => void
  isMobile?: boolean
}

export const PreApplications = ({preApplications, status, lead, refetch, isMobile}: Props): JSX.Element => {
  const {contact} = lead;
  const isFreezed = status === LeadStatusEnum.deferred
  const canAddPreApp = status !== (LeadStatusEnum.deferred || LeadStatusEnum.done)
  const count = preApplications ? preApplications.length : ""
  const plugText = isFreezed ? "Разморозьте лид, чтобы создать" : "Нет предзаявок"

  const {openPreApplication} = useOpenPreApplication()
  const {close} = useCloseDrawer({atDrawerInDrawer: !isMobile})
  const {openLeadInDrawer} = useOpenLeadInDrawer({refetch})

  const {
    removeQueryParam
  } = useQueryParams<{ whoIsClient: string, applicationId: string }>([{name: "whoIsClient"}, {name: "applicationId"}]);

  const handleOpenPreApplication = (id: string, whoIsClient?: WhoIsClient) => {
    if (isMobile) {
      close()
      setTimeout(() => {
        removeQueryParam("applicationId")
        openPreApplication({
          refetchLead: refetch,
          applicationId: id,
          isFromLead: true,
          whoIsClient,
          atDrawerInDrawer: false,
          onClose: () => {
            close()
            openLeadInDrawer({
              id: lead.id,
            })
          }
        })
      }, 100)
    } else {
      close()
      setTimeout(() => {
        removeQueryParam("applicationId")
        openPreApplication({
          refetchLead: refetch,
          atDrawerInDrawer: true,
          applicationId: id,
          whoIsClient,
          isFromLead: true,
        })
      }, 100)
    }
  };

  const openApplicationDrawerInDrawer = ({id, whoIsClient}: OpenApplicationInDrawer) => {

    if (isMobile) {
      close()
      setTimeout(() => {
        openPreApplication({
          refetchLead: refetch,
          atDrawerInDrawer: false,
          applicationId: id,
          isFromLead: true,
          whoIsClient,
          onClose: () => {
            close()
            openLeadInDrawer({
              id: lead.id,
            })
          }
        })
      }, 100)
    } else {
      close()
      setTimeout(() => {
        openPreApplication({
          refetchLead: refetch,
          isFromLead: true,
          atDrawerInDrawer: true,
          applicationId: id,
          whoIsClient
        })
      }, 100)
    }
  };

  const {handleCreateApplication} = useCreatePreApplicationModal({
    leadId: lead.id,
    objectCategory: lead.parserObject?.category,
    onSuccess: (data) => {
      refetch();
      openApplicationDrawerInDrawer({
        id: data.id,
        isTemplate: true,
        whoIsClient: data.type.whoIsClient
      });
    },
  });

  const checkBeforeCreate = () => {
    if (contact) {
      handleCreateApplication();
    } else {
      toastError({
        text: "Необходимо добавить контакт лиду, чтобы создать заявку",
      });
    }
  };

  return (
    <div>
      <div className={cn(styles.row, styles.label)}>
        <div className={styles.row}>
          <span className={styles.title}>
            Предзаявки
          </span>
          {!isFreezed && <span className={styles.count}>
            {count}
          </span>}
        </div>
        {canAddPreApp &&
          <div className={styles.row}>
            <PlusIcon/>
            <span className={styles.actionText} onClick={checkBeforeCreate}>
              Добавить
            </span>
          </div>
        }
      </div>
      {preApplications && preApplications.length > 0
        ? <div className={styles.preAppTable}>
          {preApplications.map(app =>
            <div key={app.id} onClick={() => {
              handleOpenPreApplication(app.id, app.shortType.whoIsClient)
            }}>
              <CardApplicationMini
                application={app}
                classNames={{
                  root: styles.preApp
                }}
                options={{
                  canOpenANewTab: true,
                  success: app.shortType.kind === "successDeal"
                }
                }
              />
            </div>
          )}
        </div>
        : <div className={styles.plug}>
          <Plug plugText={plugText}/>
        </div>
      }
    </div>
  )
}