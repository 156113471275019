import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import { QuizQuestion } from "../../../../../../types";

import { Questionnaire, QuestionnaireValue } from "../../../../index";

import styles from "./index.module.scss";

type Props = {
	applicationId: string;
	quest: QuizQuestion;
	onChange: (value: QuestionnaireValue) => void;
	register: UseFormRegister<any>;
	getValues: UseFormGetValues<any>;
	setValue: UseFormSetValue<any>;
	control: Control<any>;
	refetch: () => void;
	disabled?: boolean;
};

export const CreateQuest = ({
	applicationId,
	quest,
	onChange,
	register,
	getValues,
	setValue,
	control,
	refetch,
	disabled,
}: Props): JSX.Element => {
	const {
		dataType: { name, type, nameGroup, allowedValues, yesLabel, noLabel, values },
		visibleCondition,
		questionText,
	} = quest;

	const condition = JSON.parse(visibleCondition || "{}");
	const nameCondition = Object.keys(condition)[0];

	const isShow = useWatch({
		control,
		name: nameCondition,
	});

	const conditionValue = condition[nameCondition] ? 1 : 0;

	const options =
		yesLabel && noLabel
			? [
					{ value: 1, label: yesLabel },
					{ value: 0, label: noLabel },
			  ]
			: undefined;

	if (conditionValue && isShow !== conditionValue) return <></>;

	return (
		<div className={styles.quest} key={questionText}>
			<Questionnaire
				applicationId={applicationId}
				name={name}
				nameGroup={nameGroup}
				question={questionText}
				getValues={getValues}
				setValue={setValue}
				control={control}
				register={register}
				type={type}
				onChange={onChange}
				allowedValues={allowedValues}
				refetch={refetch}
				options={options}
				disabled={disabled}
				values={values}
			/>
		</div>
	);
};
