import styles from "./index.module.scss"
import { ReactComponent as Alert } from "../../../../../assets/icons/iconWarnBig.svg";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../service/redux/modules/modal/modal.action";

type Props = {
  onDelete: () => void
}

export const DeleteContactModal = ({onDelete}: Props) => {
  const dispatch = useDispatch()

  const onApply = () => {
    dispatch(closeModal())
    onDelete()
  }
  const onDeny = () => {
    dispatch(closeModal())
  }

  return (
    <>
      <div className={styles.row}>
        <div>
          <Alert/>
        </div>
        <div className={styles.column}>
          <div className={styles.title}>Вы уверены, что хотите открепить контакт?</div>
        </div>
      </div>
      <div className={styles.rowEnd}>
        <Button onClick={onDeny} variant="outlined">
          Отмена
        </Button>
        <Button onClick={onApply} classNames={{root: styles.buttonCreate}}>
          Открепить
        </Button>
      </div>
    </>

  )
}