import { Task } from "../../../../../../../../../../../types";
import { useTasksContext } from "../../../../../../../../../../../service/context/Tasks";
import { useOpenTaskModal } from "../../../../../../../../../../../utils/hooks";
import styles from "./index.module.scss";
import { CardTask } from "../../../../../../../../../../UI/Card/card-task/CardTask";
import cn from "classnames";

type Props = {
  groupName: string;
  tasks: Task[];
  isMobile?: boolean
};

export const TasksGroupedList = ({groupName, tasks, isMobile}: Props): JSX.Element => {
  const {
    tasksQuery: {refetch},
  } = useTasksContext();

  const {handleOpenTaskModal} = useOpenTaskModal({onUpdatedTask: refetch, mini: isMobile});

  const onCardClick = (id?: string) => {
    if (id) handleOpenTaskModal(id);
  };

  return (
    <div>
      <h3 className={styles.groupName}>{groupName}</h3>
      <div className={cn(styles.list, {[styles.mobile]: isMobile})}>
        {tasks.map((task) => (
          <div className={styles.card}
               onClick={() => onCardClick(task.id)}>
            <CardTask task={task} inTable mini={isMobile}/>
          </div>
        ))}
      </div>
    </div>
  );
};
