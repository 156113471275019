import { H3, Loader, Button } from "@sdelka_crm/sdelka-crm-component-library";
import { ReactNode } from "react";

import { RealEstate } from "../../../types";

import styles from "./index.module.scss";
import { CardObjectWithPopupMenu } from "../../pages/RealEstateList/views/regular/ObjectsListRegular/components";

const DEFAULT_TAKE = 3;

type Props = {
	list: RealEstate[];
	setTake: (newTake: number) => void;
	handleCardClick: (object: RealEstate) => void;
	isFetching: boolean;
	isLoading: boolean;
	title: string;
	blockId: string;
	total?: number;
	children?: ReactNode;
	classNames?: {
		root: string
	}
	isMobile?: boolean
};

export const RealEstateList = ({
	list,
	setTake,
	isFetching,
	isLoading,
	handleCardClick,
	blockId,
	title,
	total = 0,
	children,
	classNames,
	isMobile
}: Props): JSX.Element => {
	const take = list.length;

	const ClickMore = () => {
		setTake(take + DEFAULT_TAKE);
	};

	const SetTakeDefault = () => {
		setTake(DEFAULT_TAKE);
	};

	const isShowHiding = take > DEFAULT_TAKE;

	const renderKey = (realEstate: RealEstate) => `${realEstate?.applicationId || ""} ${realEstate?.id || ""}`;

	const showTotal = total > 0;

	return (
		<div className={styles.root} id={blockId}>
			<H3 classNames={{ root: styles.title }}>
				{title}
				&nbsp;
				{showTotal && <span className={styles.titleCount}>{`(${total})`}</span>}
			</H3>

			{(isLoading && <Loader height={30} />) ||
				(list.length > 0 && (
					<>
						<div className={styles.objectWrapper}>
							{list.map((realEstate) => (
								<CardObjectWithPopupMenu
									key={renderKey(realEstate)}
									object={realEstate}
									onCardClick={() => handleCardClick(realEstate)}
									isParser={Boolean(realEstate.sourceLinks)}
									classNames={{
										root: classNames?.root
									}}
									isMobile={isMobile}
								/>
							))}
						</div>

						{(isFetching && take !== 3 && <Loader height={30} style={{ marginTop: 12 }} />) || (
							<div className={styles.buttons}>
								{isShowHiding && (
									<Button variant="text" onClick={SetTakeDefault} isLoading={isFetching}>
										Свернуть
									</Button>
								)}

								{total > list.length && (
									<Button variant="text" onClick={ClickMore} isLoading={isFetching}>
										Еще &nbsp;
										{total - take}
									</Button>
								)}
							</div>
						)}
					</>
				)) ||
				(<div>{children}</div> ?? <div className={styles.notObject}>Связанных объектов нет</div>)}
		</div>
	);
};
