import { ReactNode } from "react";
import cn from "classnames";
import { Loader, Underlay } from "@sdelka_crm/sdelka-crm-component-library";
import { RealEstateAdStatistics, SupportData } from "./components";
import { RealEstateAdErrors, RealEstateAdErrorsProps } from "../RealEstateAdErrors";
import { PlatformSupportData } from "../../../../../../../../../types";
import styles from "./index.module.scss";

type Props = {
  header?: ReactNode;
  footer?: ReactNode;
  disableStatistics?: boolean;
  supportData?: PlatformSupportData & { id: string };
  className?: string;
  headerClassName?: string;
  footerClassName?: string;
  isLoading?: boolean;
  small?: boolean;
  editError?: RealEstateAdErrorsProps;
  applicationId: string;
  feedId?: string;
  disableDetalization?: boolean;
  isYandexOrDomclick?: boolean;
  headerLabel?: string;
};

export const RealEstateAdPartnersInfo = (
  {
    className,
    header,
    footer = "",
    supportData,
    disableStatistics = false,
    headerClassName = "",
    footerClassName = "",
    isLoading = true,
    small = false,
    editError,
    applicationId,
    feedId,
    disableDetalization,
    isYandexOrDomclick,
    headerLabel,
  }: Props): JSX.Element => (
    <div className={styles.root}>
      <h1>{headerLabel}</h1>
      <Underlay className={className}>
        {isLoading ? (
          <Loader height={450}/>
        ) : (
          <>
            <div
              className={cn(headerClassName, {
                [ styles.disableStatistics ]: disableStatistics && !footer,
              })}
            >
              {header}
            </div>
            {editError && (
              <div className={styles.editError}>
                <RealEstateAdErrors
                  errors={editError.errors}
                  data={editError.data}
                  isEditError
                  applicationId={applicationId}
                />
              </div>
            )}
            {!disableStatistics && (
              <div className={styles.statistics}>
                <RealEstateAdStatistics
                  small={small}
                  applicationId={applicationId}
                  feedId={feedId}
                  disableDetalization={disableDetalization}
                  isYandexOrDomclick={isYandexOrDomclick}
                />
              </div>
            )}
            {footer && <div className={footerClassName}>{footer}</div>}
          </>
        )}
      </Underlay>
      {supportData && <SupportData supportData={supportData}/>}
      <div/>
    </div>
  );
