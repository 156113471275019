import { useMutation } from "react-query";
import { api } from "../../api";
import { AxiosResponse } from "axios";

const DEFAULT_MN = "sdelka/dashboard/download-exel/DEFAULT_MN";

type UseDownloadExel = {
  onSuccess?: (res: AxiosResponse<{url: string}>) => void;
};

export const useDownloadExel = ({ onSuccess }: UseDownloadExel) => {
  const mutate = useMutation(DEFAULT_MN, {
    mutationFn: ({preset, dateTo, dateFrom}:{preset: string, dateFrom: Date, dateTo: Date}) => api.dashboard.downloadExel(preset, dateFrom, dateTo),
    onSuccess,
  });

  return {
    data: mutate.data?.data,
    mutate: mutate.mutate,
    isLoading: mutate.isLoading,
  };
};
