import styles from "./index.module.scss";
import { AgencyAgreementStatusEnum, AgencyAgrementType } from "../../../../../../../../types";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { ReactComponent as Close } from "../../../../../../../../assets/icons/close.svg";
import { PopupMenu, PopupMenuItems } from "../../../../../../../UI";
import { ReactComponent as IconEdit } from "../../../../../../../../assets/icons/iconChange.svg";
import { ReactComponent as IconCopy } from "../../../../../../../../assets/icons/copy-small.svg";
import { ReactComponent as IconCancel } from "../../../../../../../../assets/icons/iconClear.svg";
import { ReactComponent as IconDownload } from "../../../../../../../../assets/icons/iconDownload.svg";
import { useDeleteAC } from "../../../../../../../../service/api/mutations";
import { ReactComponent as IconDots } from "../../../../../../../../assets/icons/iconDotsInCircle.svg";

type Props = {
  initialSaveText: string
  resetFormValues: () => void
  isEdit: boolean
  errorCode: string
  status?: AgencyAgreementStatusEnum
  data?: AgencyAgrementType
  onClick: () => void
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>
  isButtonActive?: boolean
  appliactionId: string
  refetch?: () => void
  isMobile?: boolean
  isLoading?: boolean
  isApplication?: boolean
}

export const ACStickyFooter = (
  {
    initialSaveText,
    resetFormValues,
    isEdit,
    status,
    errorCode,
    data,
    onClick,
    setIsEdit,
    isButtonActive,
    refetch,
    appliactionId,
    isMobile,
    isLoading,
    isApplication
  }: Props): JSX.Element => {

  const {mutate: deletaACMutate} = useDeleteAC(appliactionId)

  const getStatusMes = (statusMes: AgencyAgreementStatusEnum, isApplication?: boolean): {
    currentPopupMenu: PopupMenuItems[]
  } => {
    switch (statusMes) {
      case AgencyAgreementStatusEnum.unsigned:
        return {
          currentPopupMenu: []
        }
      case AgencyAgreementStatusEnum.oral:
        if (isApplication) {
          return {
            currentPopupMenu: [
              {
                label: "Скопировать ссылку ",
                className: styles.actionItem,
                icon: <IconCopy/>,
                action: () => {
                  if (data?.agreementLink) {
                    navigator.clipboard.writeText(data.agreementLink);
                  }
                },
              },
            ]
          }
        } else {
          return {
            currentPopupMenu: [{
              label: "Редактировать",
              icon: <IconEdit/>,
              className: styles.actionItem,
              action: () => {
                setIsEdit(true);
              },
            },]
          }
        }

      case AgencyAgreementStatusEnum.waitOnline:
        if (isApplication) {
         return  {
            currentPopupMenu: [
              {
                label: "Скопировать ссылку ",
                className: styles.actionItem,
                icon: <IconCopy/>,
                action: () => {
                  if (data?.agreementLink) {
                    navigator.clipboard.writeText(data.agreementLink);
                  }
                },
              },
            ]
          }
        } else {
          return {
            currentPopupMenu: [{
              label: "Редактировать",
              className: styles.actionItem,
              icon: <IconEdit/>,
              action: () => {
                setIsEdit(true);
              },
            },
              {
                label: "Скопировать ссылку ",
                className: styles.actionItem,
                icon: <IconCopy/>,
                action: () => {
                  if (data?.agreementLink) {
                    navigator.clipboard.writeText(data.agreementLink);
                  }
                },
              },
              {
                label: "Отменить соглашение ",
                className: styles.deleteActionItem,
                icon: <IconCancel/>,
                action: () => {
                  deletaACMutate()
                  if (refetch) {
                    setTimeout(() => {
                      refetch()
                    }, 1000)
                  }
                },
              },
            ]
          }
        }

      case AgencyAgreementStatusEnum.signedOnline:
      case AgencyAgreementStatusEnum.offline:
        if (isApplication) {
          return  {
            currentPopupMenu: [
              {
                label: "Скопировать ссылку ",
                className: styles.actionItem,
                icon: <IconCopy/>,
                action: () => {
                  if (data?.agreementLink) {
                    navigator.clipboard.writeText(data.agreementLink);
                  }
                },
              },
              {
                label: "Скачать соглашение ",
                className: styles.actionItem,
                icon: <IconDownload/>,
                action: () => {
                  if (data?.downloadAgreementLink) {
                    window.open(data.downloadAgreementLink)
                  }
                },
              },
            ]
          }
        } else {
          return {
            currentPopupMenu: [
              {
                label: "Редактировать",
                className: styles.actionItem,
                icon: <IconEdit/>,
                action: () => {
                  setIsEdit(true);
                },
              },
              {
                label: "Скопировать ссылку ",
                className: styles.actionItem,
                icon: <IconCopy/>,
                action: () => {
                  if (data?.agreementLink) {
                    navigator.clipboard.writeText(data.agreementLink);
                  }
                },
              },
              {
                label: "Скачать соглашение ",
                className: styles.actionItem,
                icon: <IconDownload/>,
                action: () => {
                  if (data?.downloadAgreementLink) {
                    window.open(data.downloadAgreementLink)
                  }
                },
              },
              {
                label: "Отменить соглашение ",
                className: styles.deleteActionItem,
                icon: <IconCancel/>,
                action: () => {
                  deletaACMutate()
                  if (refetch) {
                    setTimeout(() => {
                      refetch()
                    }, 1000)
                  }
                },
              },
            ]
          }
        }


      case AgencyAgreementStatusEnum.done:
        return {
          currentPopupMenu: [
            {
              label: "Скопировать ссылку ",
              className: styles.actionItem,
              icon: <IconCopy/>,
              action: () => {
                if (data?.agreementLink) {
                  navigator.clipboard.writeText(data.agreementLink);
                }
              },
            },
            {
              label: "Скачать соглашение ",
              className: styles.actionItem,
              icon: <IconDownload/>,
              action: () => {
                if (data?.downloadAgreementLink) {
                  window.open(data.downloadAgreementLink)
                }
              },
            },
            {
              label: "Скачать акт ",
              className: styles.actionItem,
              icon: <IconDownload/>,
              action: () => {
                if (data?.downloadActLink) {
                  window.open(data.downloadActLink)
                }
              },
            },
          ]
        }
      default:
        return {currentPopupMenu: []}
    }
  }

  return (
    <div className={styles.buttonBlock}>
        {data?.status && getStatusMes(data.status, isApplication) && !isEdit &&
          <div className={styles.actions}>
            <div className={styles.popup}>
              <PopupMenu
                openTrigger={isMobile ? <IconDots/> : <div className={styles.openTrigger}>Другие действия</div>}
                menuItems={getStatusMes(data.status, isApplication).currentPopupMenu}
                options={{
                  withArrow: true,
                  primaryOpen: "left",
                  openUp: true
                }}
                classNames={
                  {
                    trigger: styles.clicked,
                    arrow: styles.arrow,
                    card: styles.popupCard
                  }}
              />
            </div>
          </div>
        }
        {isEdit && status !== AgencyAgreementStatusEnum.unsigned && errorCode !== "AP005" &&
          <Button onClick={() => {
            resetFormValues()
            setIsEdit(false)
          }} color="red" variant="text" Icon={Close}>
            Отменить редактирование
          </Button>
        }
        {errorCode === "AP005" && isEdit &&
          <Button onClick={onClick} disabled={!isButtonActive} isLoading={isLoading}>
            {initialSaveText}
          </Button>
        }
        {status !== AgencyAgreementStatusEnum.unsigned && isEdit && errorCode !== "AP005" &&
          <Button onClick={onClick} disabled={!isButtonActive}>
            Внести изменения
          </Button>
        }
        {data?.terminationRequested &&
          <Button color="red">
            Подтвердить расторжение
          </Button>
        }
      </div>
  )
}