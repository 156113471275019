import styles from "./index.module.scss"
import { ReactComponent as Success } from "../../../../../../../../../../../../../../assets/icons/Success.svg";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../../../../../../../../../../service/redux/modules/modal/modal.action";

type Props = {
  applicationId: string
  orderId: string
}

export const OrderCreatedModal = (
  {
    applicationId,
    orderId
  }: Props) => {
  const {push} = useHistory()
  const dispatch = useDispatch()

  const openApplication = () => {
    push(`/leadsanddeals?tab=applications&applicationId=${applicationId}`)
    dispatch((closeModal()))
  }

  const openOrder = () => {
    push(`/services?orderId=${orderId}`)
    dispatch((closeModal()))
  }

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <Success/>
        <div>
          <div className={styles.title}>
            Заказ создан
          </div>
          <div className={styles.description}>
            Перейдите к заказу, чтобы увидеть исполнителя
          </div>
        </div>
      </div>
      <div className={styles.rowEnd}>
        <Button variant="outlined" onClick={openApplication}>
          К заявке
        </Button>
        <Button onClick={openOrder}>
          К заказу
        </Button>
      </div>
    </div>
  )
}