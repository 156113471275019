import { Placemark, withYMaps } from "react-yandex-maps";

import useYMaps from "../map-utils/useYMaps";
import { markTemplate } from "../map-utils/utils";
import { MarkType } from "../../../../types";

type Props = {
	mark: MarkType;
};

export const Mark = withYMaps(({ mark }: Props): JSX.Element => {
	const markError = "mark error"
	if (typeof mark !== "object") {
		console.log(markError)
		return <></>
	}

	if (!mark || !mark.lat || !mark.lon) {
		console.log(markError)
		return <></>
	}

	if (!mark.lon || !mark.lat) {
		console.log(markError)
		return <></>
	}

	const { ymaps, mapInstance } = useYMaps();

	const clickOnMark = () => {
		const zoom = mapInstance.getZoom();
		mapInstance.setCenter([mark.lat, mark.lon], zoom + 2, {
			checkZoomRange: true,
		});
	};

	return (
		<Placemark
			geometry={[mark.lat, mark.lon]}
			onClick={clickOnMark}
			options={{
				iconLayout: ymaps?.templateLayoutFactory.createClass(markTemplate),
				iconShape: {
					type: "Rectangle",
					coordinates: [
						[-22, -62],
						[22, 0],
					],
					radius: 16,
				},
			}}
		/>
	);
});
