import { KindType, RealEstate } from "../../../../../../../types";
import { CardObject, CardObjectMini } from "../../../../../../UI";
import styles from "./index.module.scss";
import { useOpenDrawerRealEstate } from "../../../../../../../utils/hooks";

type Props = {
  realEstate: RealEstate;
  isMobile?: boolean
};

export const Item = ({realEstate, isMobile}: Props): JSX.Element => {
  const {applicationId, shortType, preApplicationId} = realEstate;
  const {handleOpenDrawerRealEstate: openDrawer} = useOpenDrawerRealEstate({
    kind: shortType.kind,
    isFromAd: true,
  });

  const onCardClick = () => {
    openDrawer(shortType.kind === KindType.APPLICATION ? applicationId : preApplicationId)
  };
  const object = realEstate

  return (
    <>
      {isMobile
        ? <CardObjectMini
          id={object.id}
          objectData={object}
          isHovered
          withPrice
          onCardClick={onCardClick}
        />
        : <CardObject
          clientObject={realEstate}
          id={applicationId}
          cardBackground="white"
          classNames={{root: styles.card}}
          onCardClick={onCardClick}
          variant="ad-list"
        />}
    </>

  );
};
