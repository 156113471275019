import { useMemo } from "react";

import { Office, User } from "../../../../../../../types";
import { selectCurrentAccount, useSelect } from "../../../../../../../service/redux/selectors";

type Props = {
	searchOffices: Office[];
};

export const useOfficesWithoutMe = ({ searchOffices }: Props) => {
	const { currentAccount } = useSelect(selectCurrentAccount);

	const notMe = (user: User) => user.id !== currentAccount?.id;

	return useMemo(
		() =>
			searchOffices.map((office) => ({
				...office,
				departments: office.departments.map((department) => ({
					...department,
					users: department.users?.filter(notMe),
				})),
			})),
		[searchOffices, currentAccount]
	);
};
