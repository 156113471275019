import { Client } from "../../Client";

import { ImageUploadResponse, UploadFile } from "../../../../types";

export const FileStorageService = {
	uploadImg: (file: FormData, params: UploadFile) =>
		Client.post<ImageUploadResponse>("/file-storage/uploadImage", file, {
			params,
		}),
	uploadFile: (file: FormData) =>
		Client.post<string>("/file-storage/uploadFile", file, {
		}),
};
