import { Control } from "react-hook-form";
import { RadioButton, TRadio } from "../../../../UI";
import styles from "./index.module.scss";

type Props = {
  control: Control<any>;
  options: TRadio[];
  name: string;
  onChange: (value: string | number) => void;
};

export const Header = (
  {
    onChange,
    name,
    control,
    options,
  }: Props): JSX.Element => (
    <div className={styles.root}>
      <div className={styles.body}>
        <RadioButton
          name={name}
          control={control}
          options={options}
          handleOnChange={onChange}
          background="blue"
          backgroundButtons="blue"
        />
      </div>
    </div>
  );
