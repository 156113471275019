import { ApplicationsFilter, ContactFilter, LeadFiltersType, MultiLayerSelectOption } from "../../../../../types";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { CONTACT_DEFAULT_FILTERS } from "../../../../../service/api/helpers";
import { mapFilterInputValues } from "../utils";
import { useLoadUsers } from "../../../../../service/api/queries";
import { prepareResponsibleUsers } from "../../../../../utils/transformation";
import { Button, InfoRow } from "@sdelka_crm/sdelka-crm-component-library";
import { Checkbox, MultiLayerSelect, MultiSelect, RoundInput } from "../../../../UI";
import cn from "classnames";
import styles from "./index.module.scss";
import { useCloseDrawer } from "../../../../../service/servise";

type Props = {
  filter: ContactFilter;
  formSubmit: (values: ContactFilter) => void;
  setPage?: (page: number) => void;
};

export const ContactsFiltersMobile = (
  {
    filter,
    formSubmit,
    setPage,
  }: Props) => {
  const { register, handleSubmit, setValue, getValues, reset, control, watch } = useForm({
    defaultValues: CONTACT_DEFAULT_FILTERS,
  });

  const formWatcher = watch();
  const [typeWatcher, responsiblesWatcher] = [
    useWatch({
      name: "type",
      control,
      defaultValue: getValues("type") || [],
    }),
    useWatch({
      name: "responsibles",
      control,
      defaultValue: getValues("responsibles") || [],
    }),
  ];

  const {close} = useCloseDrawer({atDrawerInDrawer: false})

  const prepareSubmitForm = (values) => {
    reset(values);

    const mappedValues = mapFilterInputValues(values);
    formSubmit({
      ...filter,
      ...mappedValues,
    });
    if (setPage) {
      setPage(1);
    }
    close()
  };

  const { users } = useLoadUsers({});
  const responsibleUsers = useMemo((): MultiLayerSelectOption[] => prepareResponsibleUsers(users), [users]);

  const contactTypeOptions = [
    { label: "Агент", value: "agency" },
    { label: "Клиент", value: "customer" },
    { label: "Другое", value: "other" },
  ];


  return (
    <form className={cn(styles.root)} onSubmit={handleSubmit(prepareSubmitForm)}>
      <div className={styles.title}>
        Фильтры
      </div>
      <InfoRow aspect={{label: 8, content: 7.3}} label={""}>
        <RoundInput register={register} name="fullTextSearch" prepend="search" placeholder="Поиск" />
      </InfoRow>
      <InfoRow aspect={{label: 8, content: 7.3}} label={"Тип контакта"}>
        <MultiSelect
          name="type"
          setValue={setValue}
          options={contactTypeOptions}
          register={register}
          placeholder="Тип контакта"
          defaultValue={typeWatcher}
        />
      </InfoRow>
      <InfoRow aspect={{label: 8, content: 7.3}} label={"Ответственный"}>
        <MultiLayerSelect
          name="responsibles"
          options={responsibleUsers || []}
          register={register}
          placeholder="Ответственный"
          setValue={setValue}
          isSearchable
          defaultValue={responsiblesWatcher}
        />
      </InfoRow>
      <InfoRow aspect={{label: 8, content: 7.3}} label={"С активной"}>
        <Checkbox
          control={control}
          name="withApplication"
          label="Заявкой"
          variant="noOpacity"
          weight="bold"
        />
      </InfoRow>
      <InfoRow aspect={{label: 8, content: 7.3}} label={"С активной"}>
        <Checkbox control={control} name="withObject" label="Объектом" variant="noOpacity" weight="bold" />
      </InfoRow>
      <div className={styles.submitWrapper}>
        <Button type="submit">Показать</Button>
      </div>
    </form>
  )
}