// Test function
/* function getDateAfterDays(days) {
    const today = new Date();
    return new Date(today.getTime() + days * 24 * 60 * 60 * 1000);
} */

export function getDayDifference(date) {
	// Convert provided date to UTC midnight time
	const providedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

	// Convert current date to UTC midnight time
	const today = new Date();
	const currentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

	// Calculate difference in seconds between two dates
	const differenceInSeconds = (currentDate.getTime() - providedDate.getTime()) / 1000;

	if (differenceInSeconds > 0) return 0;

	// Calculate difference in days and return the absolute value
	const differenceInDays = Math.floor(differenceInSeconds / (24 * 60 * 60));
	return Math.abs(differenceInDays);
}
