import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { activateLoading, deactivateLoading } from "../../../../../service/redux/modules/modal/modal.action";

type Props = {
	isFetching: boolean;
};

export const useDisplayFetching = ({ isFetching }: Props) => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (isFetching) {
			dispatch(activateLoading());
		} else {
			dispatch(deactivateLoading());
		}
	}, [isFetching]);
};
