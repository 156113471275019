import { useState } from "react";

import { CianTariff, PlatformData } from "../../../../../../../../types";

type Props = {
	platform: PlatformData;
};

export const useCian = ({ platform }: Props) => {
	const isCian = platform.format === "cian";

	const checkCianError = (tariff: string) => isCian && Boolean(tariff) && tariff === "standart";
	const [cianError, setCianError] = useState(checkCianError(String(platform.adStatus?.cianTariff)));

	const onCianChange = (tariff: CianTariff) => {
		setCianError(checkCianError(String(tariff)));
	};

	return {
		isCian,
		cianError,
		onCianChange,
	};
};
