import { useMemo } from "react";
import { UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { LeadSourceEnum, LeadSourceEnumTranslate, SelectOption, User } from "../../../../../../types";
import { DoubleInput, InfoRow, MultiSelect, PopupObjectType, Select, TransparentInput } from "../../../../../UI";
import { dateMask, datePipe, phoneMask } from "../../../../../../utils/masks";
import { prepareShortUserOutput } from "../../../../../../utils/transformation";
import { ReactComponent as Calendar } from "../../../../../../assets/icons/calendar.svg";
import styles from "./index.module.scss";
import { commonPlaceholders } from "../../../../../../utils/placeholders";

type Props = {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  users: User[];
  sourcesOptions: SelectOption[];
};

export const MainInfo = (
  {
    register,
    users,
    setValue,
    watch,
    sourcesOptions: adSourceOptions,
  }: Props): JSX.Element => {
  const [
    watchCategory,
    watchPriceFrom,
    watchPriceTo,
    watchCreator,
    watchAdSource,
    watchLeadSource,
    watchDateCreatingFrom,
    watchDateCreatingTo,
    watchOwnerPhone,
  ] = watch(["category", "priceFrom", "priceTo", "creatorId", "adSource", "status", "leadSource", "dateCreatingFrom", "dateCreatingTo", "modifiedAt", "ownerPhone"]);

  function makeEnum() {
    const all = [];
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in LeadSourceEnum) {
      all.push(LeadSourceEnum[ key ] as never);
    }
    return all
  }

  const sourcesList = makeEnum()
  const sourcesOptions = useMemo(
    () =>
      sourcesList.map((source) => ({
        value: source,
        label: LeadSourceEnumTranslate[ source ],
      })),
    [sourcesList]
  );

  const usersOption = useMemo(
    () =>
      users.map((user) => ({
        label: prepareShortUserOutput(user.account),
        value: user.id,
      })),
    [users]
  );

  return (
    <>
      <div className={styles.title}>Информация о заявке</div>
      <InfoRow label="Создатель" labelFont="ubuntu">
        <Select
          name="creatorId"
          setValue={setValue}
          options={usersOption}
          register={register}
          placeholder="Выбрать"
          defaultValue={watchCreator}
        />
      </InfoRow>
      {/*<InfoRow label="Дата создания" labelFont="ubuntu">*/}
      {/*  <div className={styles.dateWrapper}>*/}
      {/*    <Calendar className={styles.dateIcon}/>*/}
      {/*    <DoubleInput*/}
      {/*      separator="-"*/}
      {/*      size="large"*/}
      {/*      register={register}*/}
      {/*      placeholders={["00.00.00", "00.00.00"]}*/}
      {/*      masks={[dateMask, dateMask]}*/}
      {/*      pipes={[datePipe, datePipe]}*/}
      {/*      setValue={setValue}*/}
      {/*      names={["dateCreatingFrom", "dateCreatingTo"]}*/}
      {/*      value={[watchDateCreatingFrom, watchDateCreatingTo]}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</InfoRow>*/}
      <InfoRow label="Номер собственника" labelFont="ubuntu">
        <TransparentInput
          placeholder="+7 ХХХ ХХХ ХХ-ХХ"
          name="ownerPhone"
          register={register}
          withCleanUp={{setValue}}
          mask={phoneMask}
          setValueForMask={setValue}
          variant="noPadding"
          value={watchOwnerPhone}
        />
      </InfoRow>
      <div className={styles.title}>Информация о лиде</div>
      <InfoRow label="Источник" labelFont="ubuntu">
        <MultiSelect
          name="leadSource"
          setValue={setValue}
          options={sourcesOptions}
          register={register}
          placeholder="Выбрать"
          defaultValue={watchLeadSource}
        />
      </InfoRow>
      <InfoRow label="Рекламный канал" labelFont="ubuntu">
        <MultiSelect
          name="adSource"
          setValue={setValue}
          options={adSourceOptions}
          register={register}
          placeholder="Выбрать"
          defaultValue={watchAdSource}
        />
      </InfoRow>
      <div className={styles.title}>Информация об объекте</div>
      <InfoRow label="Тип объекта" labelFont="ubuntu">
        <PopupObjectType
          variant="light"
          register={register}
          setValue={setValue}
          defaultValues={watchCategory}
        />
      </InfoRow>
      <InfoRow label="Цена ₽" labelFont="ubuntu">
        <DoubleInput
          size="extraLarge"
          register={register}
          placeholders={[commonPlaceholders.notSpecified, commonPlaceholders.notSpecified]}
          setValue={setValue}
          names={["priceFrom", "priceTo"]}
          value={[watchPriceFrom, watchPriceTo]}
        />
      </InfoRow>
    </>
  );
};
