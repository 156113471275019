// Function for getting greeting by time
export function getDayTimeGreeting(): string {
	const date = new Date();
	const currentHour = date.getHours();

	if (currentHour > 2 && currentHour <= 5) {
		return "Доброй ночи";
	}
	if (currentHour >= 5 && currentHour < 12) {
		return "Доброе утро";
	}
	if (currentHour >= 12 && currentHour <= 16) {
		return "Добрый день";
	}

	return "Добрый вечер";
}
