import { InfoRow } from "../../../../../../../../UI";
import { CommercialPartsSquare, RoomsSquareList, SelectList } from "./components";
import { getObjectValue, getObjectComplexValue } from "./helpers";
import { RealEstate } from "../../../../../../../../../types";

import styles from "./index.module.scss";

type Props = {
	object: RealEstate;
	type: string;
	template: string;
	label: string;
	name: string;
	nameGroup: string[];
	postfix: string;
};

const withCatchingError = (renderFunction: (props) => JSX.Element) => (props) => {
	const isProduction = process.env.NODE_ENV === "production";

	try {
		return renderFunction(props);
	} catch (err) {
		console.log(err);

		if (isProduction) {
			return <></>;
		}

		throw new Error();
	}
};

export const ViewCategories = withCatchingError(
	({ object, type, template, label, name, nameGroup, postfix }: Props): JSX.Element => {
		const rowValue = nameGroup
			? getObjectComplexValue({ object, nameGroup, type }) || ""
			: getObjectValue({ object, name, postfix, type, template });

		if (!rowValue || rowValue === "-") {
			return <></>;
		}

		if (type === "roomsSquare") {
			return <RoomsSquareList squares={rowValue} postfix={postfix} />;
		}

		if (type === "multiSelect" && template === "viewList") {
			return <SelectList list={rowValue} />;
		}

		if (type === "commercialPartsSquareView") {
			return <CommercialPartsSquare partsData={rowValue} />;
		}

		return (
			<InfoRow
				label={label}
				aspect={{
					label: 6,
					content: 7,
				}}
				classNames={{ content: styles.content }}
			>
				{rowValue}
			</InfoRow>
		);
	}
);
