import { Underlay, formatDate } from "@sdelka_crm/sdelka-crm-component-library";

import { UseLoadUserPositions } from "../../../../../../../service/api/queries/position/useLoadUserPositions";
import { prepareShortUserOutput } from "../../../../../../../utils/transformation";
import { RealEstate } from "../../../../../../../types";

import { ReactComponent as CopyIcon } from "../../../../../../../assets/icons/copy-icon.svg";
import styles from "./index.module.scss";

type Props = {
	realEstate: RealEstate;
};

export const CopiedBy = ({ realEstate }: Props): JSX.Element => {
	const { copiedObjectsInfo } = realEstate;
	const copiedInfo = copiedObjectsInfo || [];

	const { getPositionName } = UseLoadUserPositions({});

	if (!copiedInfo.length) {
		return <></>;
	}

	return (
		<Underlay className={styles.root}>
			<CopyIcon className={styles.copyIcon} />

			<p>Объект из парсера скопирован:</p>

			{copiedInfo.map((copied, index) => {
				const { user } = copied || {};
				const { account, position } = user || {};
				const { id: positionId } = position || {};

				const { name, lastName, secondName } = account || {};

				return (
					// eslint-disable-next-line react/no-array-index-key
					<div key={index} className={styles.userBlock}>
						<span className={styles.createdAt}>{formatDate(copied.copiedAt, "month_short")}</span>

						<div className={styles.user}>
							<span className={styles.position}>{getPositionName(positionId)}</span>

							<span>{prepareShortUserOutput({ lastName, name, secondName })}</span>
						</div>
					</div>
				);
			})}
		</Underlay>
	);
};
