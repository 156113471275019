import { useState } from "react";
import { useHistory } from "react-router";
import { useLoadApplications, useLoadLeads, useLoadTraffic } from "../../../../service/api/queries";
import { useLoadDeals } from "service/api/queries/deals/useLoadDeals";
import {
  useGetVideoLessonsList
} from "../../../UI/layout/SidebarLayout/Sidebar/components/video-lessons-modal/helpers";
import { useUrlWatcher } from "../../../../service/servise";

type TabType = "contacts" | "leads" | "applications" | "deals" | "contracts";

export const useLeadsAndDealsTabs = () => {
  const {push} = useHistory();
  const [curTab, setCurTab] = useState(0);

  const trafficData = useLoadTraffic({enabled: curTab === 0});
  const {
    filter: trafficsFilter,
    setFilter: setTrafficsFilter,
    refetch: refetchTraffics,
    isFetching: isFetchingTraffics,
  } = trafficData;

  const leadsData = useLoadLeads({enabled: curTab === 1});
  const {
    filter: leadFilter,
    setFilter: setLeadFilter,
    refetch: refetchLeads,
    isFetching: isFetchingLeads,
  } = leadsData;

  const applicationsData = useLoadApplications({enabled: curTab === 2});
  const {
    filter: applicationsFilter,
    setFilter: setApplicationsFilter,
    refetch: refetchApplications,
    isFetching: isFetchingApplications,
  } = applicationsData;

  const dealsData = useLoadDeals({enabled: curTab === 3});
  const {
    filter: dealsFilter,
    setFilter: setDealsFilter,
    refetch: refetchDeals,
    isFetching: isFetchingDeals,
  } = dealsData;

  const contractsFilter = {};

  const TABS: Record<TabType, unknown> = {
    contacts: [trafficsFilter, setTrafficsFilter, refetchTraffics, isFetchingTraffics],
    leads: [leadFilter, setLeadFilter, refetchLeads, isFetchingLeads],
    applications: [applicationsFilter, setApplicationsFilter, refetchApplications, isFetchingApplications],
    deals: [dealsFilter, setDealsFilter, refetchDeals, isFetchingDeals],
    contracts: [contractsFilter, () => false],
  };

  const TAB_KEYS = Object.keys(TABS);
  const filterType = TAB_KEYS[ curTab ];

  const filter = TABS[ filterType ][ 0 ];
  const setFilter = TABS[ filterType ][ 1 ];
  const refetch = TABS[ filterType ][ 2 ];
  const isFetching = TABS[ filterType ][ 3 ];

  const {list: lessonsList, refetch: refetchLessons} = useGetVideoLessonsList({
    defaultFilter: {fullTextSearch: ""},
    tab: filterType
  })

  useUrlWatcher({
    refetch,
    setCurTab,
    curTab
  })

  const onChangeCurTab = (newTab: number) => {
    setCurTab(newTab);
    push(`/leadsanddeals?tab=${TAB_KEYS[ newTab ]}`);
    setTimeout(() => {
      refetchLessons()
    }, 100)
  };

  return {
    curTab,
    onChangeCurTab,
    filter,
    setFilter,
    refetch,
    isFetching,
    // leads
    leadsData,
    // applications
    applicationsData,
    // traffic
    trafficData,
    //
    dealsData,
    lessonsList,
    refetchLessons
  };
};
