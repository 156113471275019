import styles from "./index.module.scss"
import { useLoadDashboardTable } from "../../../../../service/api/queries";
import { useEffect, useState } from "react";
import { useColumns } from "./helpers/useColumns";
import { CalendarRangeInPopup, Popup, TableCustomizable } from "../../../../UI";
import cn from "classnames";
import { useForm } from "react-hook-form";
import { ReactComponent as Download } from "../../../../../assets/icons/iconDownload20.svg";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useDownloadExel } from "../../../../../service/api/mutations";
import { AxiosResponse } from "axios";
import { useIsTelegram } from "../../../../../utils/telegram/useIsTelegram";

export const Table = () => {
  const [dateFrom, setDateFrom] = useState<Date>(new Date(Date.now() - 30 * 60 * 60 * 24 * 1000))
  const [dateTo, setDateTo] = useState<Date>(new Date())
  const [preset, setPreset] = useState<string>("testPreset")
  const [orderBy, setOrderBy] = useState<string>("leads")
  const [direction, setDirection] = useState<"ASC" | "DESC">("DESC")

  const {register, getValues, setValue} = useForm();

  const handleChangePreset = (value: string) => {
    setPreset(value)
    setTimeout(() => {
      refetch()
    }, 50)
  }

  const actualMenu = [
    {
      label: "Preset",
      onClick: () => handleChangePreset("Preset"),
      id: 1
    },
  ]

  const {table, refetch, isLoading} = useLoadDashboardTable({
    dateFrom,
    dateTo,
    direction,
    orderBy,
    preset
  })

  const handleDateChange = (date: Date[] | Date | null | undefined) => {
    if (date) {
      setDateFrom(date[ 0 ])
      setDateTo(date[ 1 ])
      setTimeout(() => {
        refetch()
      }, 50)
    }
  };

  const {cols} = useColumns(table?.structure.columns)

  const extendedData = table?.data.concat([table.totals] ?? [])

  const datePlaceholder = `${dateFrom.toLocaleDateString()} - ${dateTo.toLocaleDateString()}`
  const {executeUrl} = useIsTelegram()
  const onSuccessDownloadExel = (res: AxiosResponse<{ url: string }>) => {
    executeUrl(`${res.data.url}`)
  }

  const {mutate} = useDownloadExel({onSuccess: onSuccessDownloadExel})

  const handleDownload = () => {
    mutate({preset, dateTo, dateFrom})
  }

  useEffect(() => {
    refetch()
  }, [orderBy, direction])

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <div className={styles.title}>
          <Popup
            openTrigger={<div className={styles.openTrigger}>План</div>}
            options={{withArrow: true}}
            classNames={
              {
                trigger: styles.clicked,
                arrow: styles.arrow,
                card: styles.popupCard
              }
            }
          >
            {actualMenu.map((item) => (
              <div key={item.id} className={cn(
                styles.actionItem,
              )} onClick={item.onClick}>
                <span>{item.label}</span>
              </div>
            ))}
          </Popup>
        </div>
        <CalendarRangeInPopup
          form={{name: ["dateFrom", "dateTo"], register, setValue, getValues, required: true}}
          options={{withArrow: true, primaryOpen: "left", arrowClass: styles.arrow}}
          classNames={{
            wrapper: styles.calendar,
            label: styles.openTrigger,
          }}
          openTrigger={<div className={styles.openTrigger}>Другие действия</div>}
          onDateChange={handleDateChange}
          placeholder={datePlaceholder}
        />
      </div>
      {table && cols
        ? <div className={styles.table}>
          <TableCustomizable
            columns={cols}
            data={extendedData}
            isFirstColumnFixed
            isLoading={isLoading}
            setDirection={setDirection}
            direction={direction}
            setOrderBy={setOrderBy}
            orderBy={orderBy}
          />
        </div>
        : <div className={cn(styles.skeleton, styles.bodySkeleton)}/>
      }
      <div className={styles.buttonContainer}>
        <Button Icon={Download} onClick={handleDownload}>
          Скачать Excel
        </Button>
      </div>
    </div>
  )
}