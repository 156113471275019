import { Dispatch, SetStateAction } from "react";
import {
  Control,
  FormState,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import cn from "classnames";

import { InfoRow, Select, TransparentInput } from "../../../../index";
import { phoneMask } from "../../../../../../utils/masks";
import { ContactFilter, ContactTypeMap } from "../../../../../../types";
import { commonPlaceholders } from "../../../../../../utils/placeholders";

import styles from "./index.module.scss";

type Props = {
  filter: ContactFilter;
  setFilter: Dispatch<SetStateAction<ContactFilter>>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  clearErrors: UseFormClearErrors<any>;
  formState: FormState<any>;
  control: Control<any>;
  isMobile?: boolean
};

export const ContactForm = (
  {
    filter,
    setFilter,
    register,
    setValue,
    getValues,
    clearErrors,
    formState,
    isMobile,
    control,
  }: Props): JSX.Element => {
  const {errors} = formState;
  const typeOptions = Object.keys(ContactTypeMap).map((key) => ({
    value: key,
    label: ContactTypeMap[ key ],
  }));

  const onChangePhone = (phone: string) => setFilter({...filter, phone: phone.replaceAll(/\D/g, "")});
  const onChangeLastName = (lastName: string) => setFilter({...filter, lastName});
  const onChangeName = (name: string) => setFilter({...filter, name});
  const onChangePseudonym = (pseudonym: string) => setFilter({...filter, pseudonym});
  const onChangeSecondName = (secondName: string) => setFilter({...filter, secondName});
  const onChangeType = (type: string) => {
    clearErrors("type");
    setFilter({...filter, type});
  };
  const onChangeEmail = (email: string) => {
    clearErrors("email");
    setFilter({...filter, email});
  };
  const onChangeOtherType = (otherType: string) => setFilter({...filter, otherType});

  const [phoneWatcher, typeWatcher] = [
    useWatch({
      control,
      name: "phone",
      defaultValue: getValues("phone") || "",
    }),
    useWatch({
      control,
      name: "type",
      defaultValue: typeOptions[ 1 ].value,
    }),
  ];

  const showOtherType = typeWatcher === "other";

  return (
    <div className={cn(styles.root, {[ styles.rootMobile ]: isMobile})}>
      <InfoRow label="Телефон" classNames={{root: styles.infoRow}}>
        <TransparentInput
          name="phone"
          register={register}
          onChange={onChangePhone}
          placeholder={commonPlaceholders.notSpecified}
          mask={phoneMask}
          setValueForMask={setValue}
          value={phoneWatcher}
        />
      </InfoRow>
      {errors.phone && <div className={styles.error}>{errors.phone.message?.toString()}</div>}

      <InfoRow label="Псевдоним" classNames={{root: styles.infoRow}}>
        <TransparentInput
          name="pseudonym"
          register={register}
          onChange={onChangePseudonym}
          placeholder={commonPlaceholders.notSpecified}
        />
      </InfoRow>
      {errors.pseudonym && <div className={styles.error}>{errors?.pseudonym?.message?.toString()}</div>}

      <InfoRow label="Фамилия" classNames={{root: styles.infoRow}}>
        <TransparentInput
          name="lastName"
          register={register}
          onChange={onChangeLastName}
          placeholder={commonPlaceholders.notSpecified}
        />
      </InfoRow>
      {errors.lastName && <div className={styles.error}>{errors.lastName.message?.toString()}</div>}

      <InfoRow label="Имя" classNames={{root: styles.infoRow}}>
        <TransparentInput
          name="name"
          register={register}
          onChange={onChangeName}
          placeholder={commonPlaceholders.notSpecified}
        />
      </InfoRow>
      {errors.name && <div className={styles.error}>{errors.name.message?.toString()}</div>}

      <InfoRow label="Отчество" classNames={{root: styles.infoRow}}>
        <TransparentInput
          name="secondName"
          register={register}
          onChange={onChangeSecondName}
          placeholder={commonPlaceholders.notSpecified}
        />
      </InfoRow>
      {errors.secondName && <div className={styles.error}>{errors.secondName.message?.toString()}</div>}


      <InfoRow label="Тип" classNames={{root: styles.infoRow}}>
        <Select
          name="type"
          defaultValue={typeWatcher}
          register={register}
          setValue={setValue}
          options={typeOptions}
          onChange={onChangeType}
          placeholder="Выбрать"
        />
      </InfoRow>

      {showOtherType && (
        <InfoRow label=" " classNames={{root: styles.infoRow}} labelFont="ubuntu">
          <TransparentInput
            placeholder="Тип"
            name="otherType"
            withCleanUp={{setValue}}
            register={register}
            onChange={onChangeOtherType}
            error={formState?.errors?.otherType?.message?.toString()}
          />
        </InfoRow>
      )}

      {errors.type && <div className={styles.error}>{errors.type.message?.toString()}</div>}

      <InfoRow label="E-mail" classNames={{root: styles.infoRow}}>
        <TransparentInput
          name="email"
          register={register}
          onChange={onChangeEmail}
          placeholder={commonPlaceholders.notSpecified}
        />
      </InfoRow>
      {errors.email && <div className={styles.error}>{errors.email.message?.toString()}</div>}
    </div>
  );
};
