import type { RefuseReason } from "./lead-history";
import { LeadStatusEnum, LeadSourceEnum } from "./lead";

export const leadType: Record<LeadSourceEnum, string> = {
	[LeadSourceEnum.call]: "Звонок",
	[LeadSourceEnum.coldCall]: "Холодный звонок",
	// [LeadSourceEnum.advertisingPlatform]: "С площадки",
	// [LeadSourceEnum.estateObjectShowView]: "Просмотр/показ объекта",
	// [LeadSourceEnum.relatedLead]: "Связанный лид",
	[LeadSourceEnum.lead]: "Из лида",
	// [LeadSourceEnum.companyWebsite]: "С сайта компании",
	// [LeadSourceEnum.viewOrShow]: "С просмотра/показа",
	[LeadSourceEnum.publicPage]: "Из публичной страницы",
	// [LeadSourceEnum.cameToTheOffice]: "Посещение офиса",
	// [LeadSourceEnum.offlineMeeting]: "Оффлайн встреча",
	// [LeadSourceEnum.unknown]: "Неизвестно",
	[LeadSourceEnum.show]: "Из показа",
	[LeadSourceEnum.view]: "Из просмотра",
	[LeadSourceEnum.external]: "Из внешнего источника",
	[LeadSourceEnum.archivedEstateObject]: "Из архивного объекта",
};

export const leadStatuses: Record<LeadStatusEnum, string> = {
	new: "Необработанный",
	deferred: "Замороженный",
	in_progress: "В работе",
	done: "Отработанный",
	free: "Свободный"
};

export const leadRefuseReasons: Record<RefuseReason, string> = {
	byMyself: "Пока сам",
	definitelyMyself: "Категорически сам",
	ignore: "Игнор",
};
