import { useMemo } from "react";
import { Call, UserAccountData, User } from "../../../../../types";

import { CallHistory } from "../CallHistory";

type Props = {
	call: Call;
	users: User[];
};

export const ChooserCompanyUser = ({ call, users }: Props): JSX.Element => {
	const companyUser = useMemo((): UserAccountData | undefined => {
		if (call.type === "in") {
			const answerUser = users.find((account) => account.account.phone === call.answer);

			return answerUser?.account;
		}

		const callerUser = users.find((account) => account.account.phone === call.caller);

		return callerUser?.account;
	}, [call, users]);

	return <>{companyUser && <CallHistory call={call} user={companyUser} />}</>;
};
