import { AxiosResponse } from "axios";
import { useQuery } from "react-query";

import { CompanyPublicData } from "../../../../types";
import { api } from "../../index";

const DEFAULT_QN = "sdelka/company/load-company-public/DEFAULT_QN";

type UseLoadCompanyPublic = {
	id: string;
	onSuccess?: (data: AxiosResponse<CompanyPublicData>) => void;
};

export const useLoadCompanyPublic = ({ id, onSuccess }: UseLoadCompanyPublic) => {
	const loadCompanyPublic = () => api.companies.getCompanyPublic(id);

	const { data, isLoading, refetch } = useQuery([DEFAULT_QN, id], loadCompanyPublic, {
		enabled: !!id,
		onSuccess,
	});

	return {
		companyPublic: data?.data,
		isLoading,
		refetch,
	};
};
