import { useState } from "react";
import { Tabs } from "@sdelka_crm/sdelka-crm-component-library";

import { ViewCategories } from "./components";
import { RealEstate, RealEstateViewBlocks, RealEstateWithSchema } from "../../../../../../../types";

import styles from "./index.module.scss";

const MainInfoViewConfigBlock = ({
	realEstate,
	viewBlock,
}: {
	realEstate: RealEstate;
	viewBlock: RealEstateViewBlocks;
}): JSX.Element => (
	<div key={viewBlock.blockName} className={styles.rowsBlock}>
		<h3>{viewBlock.blockName}</h3>

		{viewBlock.fields.map((field, index) => {
			const { dataType, label } = field;
			const { postfix, type, template, name, nameGroup } = dataType;

			return (
				<ViewCategories
					// eslint-disable-next-line react/no-array-index-key
					key={`${name}-${index}`}
					type={type}
					template={template}
					label={label}
					name={name}
					nameGroup={nameGroup}
					postfix={postfix}
					object={realEstate}
				/>
			);
		})}
	</div>
);

type Props = {
	realEstate: RealEstateWithSchema;
};

export const MainInfo = ({ realEstate }: Props): JSX.Element => {
	const [active, setActive] = useState(0);
	const { catalog } = realEstate;
	const { objectViewConfig, objectParserConfig } = realEstate.schema;

	const mainList = catalog === "база моего агентства" ? objectViewConfig : objectParserConfig;

	const tabs = mainList.map((viewConfig) => viewConfig.tabName);

	return (
		<div className={styles.root}>
			<Tabs size="small" active={active} onChange={setActive} tabs={tabs} offset={20} maxWidth={490} />

			{mainList[active]?.blocks?.map((viewBlock) => (
				<MainInfoViewConfigBlock key={viewBlock.blockName} viewBlock={viewBlock} realEstate={realEstate} />
			))}
		</div>
	);
};
