import { AxiosResponse } from "axios";
import { UseMutationResult, useMutation } from "react-query";

import { api } from "../../index";
import { CallCenterResponseData, DefaultRequestArgs } from "../../../../types";

const mutationKey = "/sdelka/callCenterChecking/isAgent";

type Response = AxiosResponse<CallCenterResponseData>;

type Props = {
	onSuccess?: (data: Response) => void;
	onError?: (error: unknown) => void;
};

type Return = UseMutationResult<AxiosResponse<CallCenterResponseData>, unknown, DefaultRequestArgs, unknown>;

export const useMarkAgent = ({ onSuccess, onError }: Props): Return =>
	useMutation({
		mutationFn: api.callCenter.markAgent,
		mutationKey,
		onSuccess,
		onError,
	});
