import { ClipboardEvent, useState } from "react";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import MaskedInput from "react-text-mask";
import cn from "classnames";
import { AnyInputProps } from "../../../../../types";

import { formatPhoneWithSpace } from "../../../../../utils/formatters";

import { ReactComponent as CloseIcon } from "../../../../../assets/icons/close.svg";
import styles from "./index.module.scss";

type Props = {
  withCleanUp?: { setValue: UseFormSetValue<any> };
  register: UseFormRegister<any>;
  setValueAs?: (value: string) => void;
  onChange?: (value: string) => void;
  textAlign?: "left" | "right";
  variant?: "default" | "noPadding";
  // !For mask input required!
  setValueForMask?: UseFormSetValue<any>;
  autoComplete?: string;
  guide?: boolean;
  type?:string
  numeric?: boolean
  maxLenght?: number
  parser?: (value: string) => string;
  formatNumber?: boolean
} & AnyInputProps;

export const TransparentInput = (
  {
    withCleanUp,
    register,
    name,
    placeholder,
    loading,
    disabled,
    className,
    required,
    setValueAs,
    onChange,
    readOnly,
    mask,
    type,
    pipe,
    preNode = "",
    error = "",
    textAlign = "left",
    setValueForMask,
    variant = "default",
    value,
    autoComplete = "off",
    guide = true,
    maxLenght,
    parser,
    formatNumber
  }: Props): JSX.Element => {
  const [showCleanUp, setShowCleanUp] = useState(false);
  const [focused, setFocused] = useState(false);
  const cleanUp = () => withCleanUp?.setValue(name, "");

  if (loading) {
    return <div className={styles.loading}/>;
  }

  const inputProps = {
    disabled,
    placeholder: focused ? "" : placeholder,
    readOnly,
    className: cn(styles.input, styles[ textAlign ], {
      [ styles.withClose ]: withCleanUp,
    }),
    mask,
    pipe,
    value,
    autoComplete,
    type,
  };

  const registerProp = register(name, {
    required,
    setValueAs: (v) => {
      if (withCleanUp) {
        setShowCleanUp(!!v);
      }

      if (onChange) {
        onChange(v);
      }

      if (v) {
        setShowCleanUp(true);
      } else {
        setShowCleanUp(false);
      }

      if (setValueAs) {
        return setValueAs(v);
      }

      return v;
    },
  });

  return (
    <>
      <div className={cn(styles.root, className, styles[ variant ])}>
        {preNode}
        {mask ? (
          <MaskedInput
            maxLength={maxLenght}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...inputProps}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...registerProp}
            guide={guide}
            onChange={(e) => {
               
              if (withCleanUp) {
                setShowCleanUp(!!e.target.value);
              }

              if (onChange) {
                if (parser) {
                  onChange(parser(e.target.value));
                } else {
                  onChange(e.target.value);
                }
              }

              if (setValueForMask) {
                if (formatNumber) {
                  setValueForMask(name, Number(e.target.value.replaceAll(" ", "")));
                }
                setValueForMask(name, e.target.value);
              }

              if (e.target.value) {
                setShowCleanUp(true);
              } else {
                setShowCleanUp(false);
              }
            }}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            // eslint-disable-next-line sonarjs/cognitive-complexity
            onPaste={(event: ClipboardEvent) => {
              if (mask && mask()[ 0 ] === "+" && mask()[ 1 ] === 7) {
                let text: string = event.clipboardData.getData("Text");

                text = text.replace(/\D+/g, "");

                if (text.length > 10) {
                  text = text.substring(text.length - 10);
                }

                text = `+${formatPhoneWithSpace(text)}`;

                if (onChange) {
                  onChange(text);
                }
                if (setValueForMask) {
                  setValueForMask(name, text);
                }

                if (text) {
                  setShowCleanUp(true);
                } else {
                  setShowCleanUp(false);
                }
                event.preventDefault();
              }
            }}
          />
        ) : (
          <input
            maxLength={maxLenght}
            {...inputProps}
            {...registerProp}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            autoComplete={autoComplete}
          />
        )}

        {withCleanUp && showCleanUp && (
          <div onClick={cleanUp} className={styles.close}>
            <CloseIcon/>
          </div>
        )}
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </>
  );
};
