import { useMemo, useState } from "react";
import { isToday } from "date-fns";
import { formatDate } from "@sdelka_crm/sdelka-crm-component-library";
import { useSample } from "./helpers";
import { ObjectColumnItem, Stub, UnactualDelete } from "./components";
import { objectNounHelper } from "../../../../../../utils/string";
import styles from "./index.module.scss";
import { CollectionItem } from "../../../../../../types";
import { ReactComponent as Arrow } from "../../../../../../assets/icons/keyboard_arrow_down.svg";
import { useLocation } from "react-router";
import classNames from "classnames";
import cn from "classnames";
import { closeModal, openModal } from "../../../../../../service/redux/modules/modal/modal.action";
import { useDispatch } from "react-redux";
import { selectDevice, selectObjectList, useSelect } from "../../../../../../service/redux/selectors";
import { ReactComponent as Add } from "../../../../../../assets/icons/bar.svg";

type Props = {
  isCompilation: boolean;
  isCompare: boolean;
  sampledApplicationId: string;
};

export const SampleSelected = ({isCompilation, isCompare, sampledApplicationId}: Props): JSX.Element => {
  const {
    sampleList,
    sampleRefetch,
    isLoadingRemoveObjectFromActual,
    handleOnReturnToBack,
    sampleTitle,
  } = useSample({isCompilation, isCompare, sampledApplicationId});
  const handleRemoveObjectFromActual = null;
  const location = useLocation()
  const {filtersShowButton} = useSelect(selectObjectList);
  const {isMobile, isTelegram} = useSelect(selectDevice)

  const returnText = location.search.includes("fromObject=true") ? "объекту" : "заявке"
  const [isBlindOpen, setBlindOpen] = useState(false);
  const [hovered, setHovered] = useState(false);

  const unactualCount = useMemo(() => {
    const compilationArray = sampleList || [];

    const filteredAmount = compilationArray.filter((realEstate) => {
      const {
        object: {actual},
      } = realEstate;
      const notActual = actual ? actual !== "Актуальный" : false;

      const {isDeleted} = realEstate;

      return notActual && !isDeleted;
    });

    return filteredAmount.length;
  }, [sampleList]);

  const dispatch = useDispatch();

  const openSaveModal = () => {
    if (sampleTitle === "Конкурентный анализ") {
      handleOnReturnToBack()
    } else if (filtersShowButton) {
      dispatch(
        openModal({
          title: "Изменения в фильтрах не сохранены",
          body: "Сохранить внесенные вами изменения в фильтре?",
          variant: "alert",
          alertIcon: "warning",
          alertButtonSubmit: {
            label: "Остаться в подборке",
            onClick: async () => {
              dispatch(closeModal());
            },
          },
          alertButtonDecline: {
            label: "Вернуться",
            onClick: async () => {
              handleOnReturnToBack()
              dispatch(closeModal());
            },
          },
        })
      );
    } else {
      handleOnReturnToBack()
    }
  };

  return (
    <div className={styles.underlayer}>
      {isMobile && <div className={styles.buttonAdd} onClick={() => {
        setBlindOpen(!isBlindOpen)
        setHovered(!hovered)
      }}>
        <Add/>
      </div>}
      <div
        className={cn(isMobile ? (hovered ? styles.rootOpen : styles.rootClosed) : styles.root,
          isTelegram && isMobile ? (hovered ? styles.rootOpen : styles.rootClosed) : styles.root,
          )}
        onMouseEnter={() => {
          setBlindOpen(true)
          setHovered(true)
        }}
        onMouseLeave={() => {
          setBlindOpen(false)
          setHovered(false)
        }}
      >
        <div className={styles.left}>
          <div className={styles.headerContainer}>
            <div className={styles.header}>
              <div className={styles.headerPart}>
                <div className={classNames(styles.title, {[ styles.unhoveredTitle ]: !hovered})}>{sampleTitle}</div>
                {hovered && <div className={styles.subTitle}>
                  {sampleList?.length} {objectNounHelper(sampleList?.length || 0)}
                </div>}
              </div>
              {hovered && <div className={styles.backButton} onClick={openSaveModal}>
                <div>
                  {`Вернуться к ${returnText}`}
                </div>
                <Arrow/>
              </div>}
            </div>
            {isBlindOpen && handleRemoveObjectFromActual && (
              <UnactualDelete
                handleRemoveObjectFromActual={handleRemoveObjectFromActual}
                isLoadingRemoveObjectFromActual={isLoadingRemoveObjectFromActual}
                unactualCount={unactualCount}
              />
            )}
          </div>
          <div className={styles.childrenContainer}>
            <Stub isBlindOpen={isBlindOpen}/>
            {sampleList?.map((realEstate, index) => {
              const prevDate = formatDate(sampleList[ index - 1 ]?.createdAt, "month_short");
              const currDate = formatDate(realEstate.createdAt, "month_short");
              const printDate = index === 0 || prevDate !== currDate;
              const isTodayDate = isToday(new Date(realEstate.createdAt));
              const {isDeleted} = realEstate;
              const isPrevDeleted = sampleList[ index - 1 ]?.isDeleted;
              const showDeleted = isDeleted && !isPrevDeleted;
              const showDate = printDate && !isDeleted;
              const showDivider = showDeleted || showDate;
              const id = realEstate.objectApplicationId || realEstate.parserObjectId;
              const object = sampleList.find(item => item.objectApplicationId === id || item.parserObjectId === id)
              let collectionItem: CollectionItem | undefined
              if (object) {
                const {collectionStatus, clientComment} = object
                collectionItem = {
                  collectionStatus,
                  clientComment
                }
              } else {
                collectionItem = undefined
              }
              return (
                <div key={realEstate.id}>
                  {showDivider && (
                    <div className={styles.dateBlock}>
                      {!isBlindOpen && <div className={styles.divider}/>}
                      {isBlindOpen && (
                        <span>
													{showDeleted && "Удаленные"}
                          {showDate && `${isTodayDate ? "новые " : ""}от ${currDate}`}
												</span>
                      )}
                    </div>
                  )}
                  <ObjectColumnItem
                    isCompare={isCompare}
                    isCompilation={isCompilation}
                    realEstate={realEstate.object}
                    sampledApplicationId={sampledApplicationId}
                    refetch={sampleRefetch}
                    isBlindOpen={isBlindOpen}
                    disableDelete={isDeleted}
                    isHovered={hovered}
                    collectionItem={collectionItem}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
