import { useDispatch } from "react-redux";
import styles from "./index.module.scss"
import { SuccessCreateModal } from "./SuccessCreateModal";
import { openModal } from "../../../../../service/redux/modules/modal/modal.action";
import { selectDevice, useSelect } from "../../../../../service/redux/selectors";

export const useOpenSuccessCreateModal = (

) => {
  const dispatch = useDispatch()
  const {isMobile} = useSelect(selectDevice)
  const width = isMobile ? 500 : 660

  const openSuccessCreateModal = () => {
    dispatch(
      openModal({
        width,
        contentClass: styles.root,
        classNames: {
          contentBody: styles.root,
          contentWrapper: isMobile ? styles.modal : "",
          buttonClose: isMobile ? styles.modalClose : ""
        },
        body:
          <SuccessCreateModal/>
      })
    )
  }

  return {
    openSuccessCreateModal
  }
}