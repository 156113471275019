import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { AddressSelect, TextField } from "../../../../UI";
import { useOfficeCreate } from "../../../../../service/api/mutations";
import { commonErrors } from "../../../../../utils/errors";
import { LocationType, OfficePayload } from "../../../../../types";

import styles from "./index.module.scss";

type Props = {
	refetchOffices: () => void;
};

type OfficeForm = {
	name: string;
	address: {
		value: string;
		label: string;
		location?: LocationType & { type: string };
	};
	departmentName: string;
};

export const CreateOfficeForm = ({ refetchOffices }: Props): JSX.Element => {
	const schema = yup.object().shape({
		name: yup.string().required(commonErrors.required).max(30, "Слишком длинное название"),
		address: yup.object().shape({
			value: yup.string().required(commonErrors.required),
		}),
		departmentName: yup.string().required(commonErrors.required).max(30, "Слишком длинное название"),
	});

	const {
		control,
		register,
		setValue,
		handleSubmit,
		reset,
		formState: { errors },
		watch,
	} = useForm<OfficeForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			name: "",
			address: {
				value: "",
				label: "",
			},
			departmentName: "",
		},
	});

	const [watchAddress] = watch(["address"]);

	const { mutate, isLoading } = useOfficeCreate({
		onSuccess: () => {
			reset();
			refetchOffices();
		},
	});

	const createOffice = (values: OfficeForm) => {
		const payload: OfficePayload = {
			name: values.name,
			addressId: values.address.value,
			addressLabel: values.address.label,
			departmentName: values.departmentName,
			location: {
				coordinates: [
					values.address.location?.coordinates[0] || 0,
					values.address.location?.coordinates[1] || 0,
				],
				type: "Point",
			},
		};

		mutate(payload);
	};

	return (
		<form className={styles.form} onSubmit={handleSubmit(createOffice)}>
			<TextField
				name="name"
				control={control}
				label="Название офиса"
				variant="small"
				error={errors?.name?.message?.toString()}
				className={styles.field}
				options={{ hideErrorAppend: true }}
			/>

			<AddressSelect
				name="address"
				value={watchAddress}
				register={register}
				setValue={setValue}
				placeholder="Адрес офиса"
				withBorder
				hideArrow
				classNames={{
					root: styles.field,
					input: styles.inputSelect,
					selected: styles.selectSelected,
				}}
				error={errors?.address?.value?.message?.toString()}
			/>

			<TextField
				name="departmentName"
				control={control}
				label="Название отдела"
				variant="small"
				className={styles.field}
				error={errors?.departmentName?.message?.toString()}
				options={{ hideErrorAppend: true }}
			/>

			<div className={styles.btnContainer}>
				<Button isLoading={isLoading} color="green" type="submit">
					Создать
				</Button>
			</div>
		</form>
	);
};
