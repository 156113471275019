import { useDispatch } from "react-redux";

import { CallCenterMenuSuccessModal, CallCenterMenuSuccessModalProps } from "../components";
import {
	closeModal,
	openModal,
} from "../../../../../../../../../../../service/redux/modules/modal/modal.action";

export const useSuccessModal = () => {
	const dispatch = useDispatch();

	const open = ({
		onSubmit,
		phone,
		price,
		ownerComment,
		callCenterComment,
	}: CallCenterMenuSuccessModalProps) =>
		dispatch(
			openModal({
				title: "Обновление данных",
				body: (
					<CallCenterMenuSuccessModal
						onSubmit={onSubmit}
						phone={phone}
						price={price}
						ownerComment={ownerComment}
						callCenterComment={callCenterComment}
					/>
				),
			})
		);

	const close = () => dispatch(closeModal());

	return { open, close };
};
