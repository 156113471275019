import { PlatformData, PlatformDetailsData } from "../../../../types";
import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/real-estate-ad/get-platform-details/DEFAULT_QN";

type Props = {
	applicationId: string;
	feedId: string;
	disableDetalization?: boolean;
};

type Return = {
	data?: PlatformDetailsData;
	isLoading: boolean;
	refetch: () => void;
	remove: () => void;
	isFetched: boolean;
};

type UseGetPlatformDetails = (props: Props) => Return;

export const useGetPlatformDetails: UseGetPlatformDetails = ({
	applicationId,
	feedId,
	disableDetalization,
}) => {
	const loadPlatformDetails = () => api.realEstateAd.platformDetails(applicationId, feedId);
	const loadAllPlatformDetails = () => api.realEstateAd.allPlatformDetails(applicationId);

	const { data, refetch, remove, isLoading, isFetched } = useQuery(
		DEFAULT_QN,
		disableDetalization ? loadAllPlatformDetails : loadPlatformDetails,
		{
			enabled: false,
		}
	);

	return {
		data: data?.data,
		isLoading,
		refetch,
		remove,
		isFetched,
	};
};
