import { memo, MouseEvent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import cn from "classnames";
import { Badge, Button } from "@sdelka_crm/sdelka-crm-component-library";
import { AddModal, VideoLessonsModal } from "./components";
import { AccountSwitcher } from "../AccountSwitcher";
import { InnerMenu } from "../InnerMenu";
import { setExpanded } from "../../../../../service/redux/modules/sidebar/sidebar.action";
import { openModal } from "../../../../../service/redux/modules/modal/modal.action";
import { setFiltersShowButton } from "../../../../../service/redux/modules/object-list/object-list.action";
import { selectCurrentAccount, selectDevice, selectSideBar, useSelect } from "../../../../../service/redux/selectors";
import { useQueryParams, useWindowWidth } from "../../../../../utils/hooks";
import { paths } from "../../../../../utils/paths";
import { useSidebarTasksCount } from "./helpers";
import { InnerMenu as InnerMenuType, SideBarMenu } from "../../../../../types";
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/plus.svg";
import { ReactComponent as IconVideoPlay } from "../../../../../assets/icons/iconVideoPlay.svg";
import { ReactComponent as IconTG } from "../../../../../assets/icons/iconTelegram.svg";
import { ReactComponent as IconTGReversed } from "../../../../../assets/icons/iconTelegramReversedColors.svg";
import { ReactComponent as DocumentIcon } from "../../../../../assets/icons/document.svg";
import { ReactComponent as SecondaryHousingIcon } from "../../../../../assets/icons/secondary-housing.svg";
import { ReactComponent as Service } from "../../../../../assets/icons/ShopSidebar.svg";
import { ReactComponent as MainLogo } from "../../../../../assets/icons/mainLogo.svg";
import { ReactComponent as MainLogoMini } from "../../../../../assets/icons/mainLogoMini.svg";
import { ReactComponent as Roll } from "../../../../../assets/icons/roll.svg";
import { ReactComponent as LogoutIcon } from "../../../../../assets/icons/logout.svg";
import { ReactComponent as Sdelka } from "../../../../../assets/icons/sdelkaIcon.svg";
import { ReactComponent as PersonGroup } from "../../../../../assets/icons/personGroupIcon.svg";
import styles from "./index.module.scss";
import { logoutThunk } from "../../../../../service/redux/modules/auth/auth.thunk";
import { useOpenSelectedOfficeCard } from "../../../../../service/servise";
import { useIsTelegram } from "../../../../../utils/telegram/useIsTelegram";

const menuItemIdPrefix = "sidebar-menu-item-";

export const Sidebar = memo((): JSX.Element => {
  const dispatch = useDispatch();
  const {pathname, search} = useLocation();
  const width = useWindowWidth();
  const {push} = useHistory();
  const {isMobile} = useSelect(selectDevice)
  const {isAccountsOpen, isOfficesOpen} = useSelect(selectSideBar);
  const {currentAccount} = useSelect(selectCurrentAccount);

  const isCoWorking = currentAccount && currentAccount.position?.name === "Агент в коворкинге"
  const isBoss = currentAccount && currentAccount.position?.name === "Руководитель компании"

  const userSubscriptionFinished = currentAccount?.employerSubscribtionFinished ?? false

  const registrationIsFinished = currentAccount?.isOfficeSelected

  const data = useSidebarTasksCount() ?? 0;

  const {queryParams} = useQueryParams<{
    officeId: string;
  }>([{name: "officeId"}]);

  const {openOfficeDrawer} = useOpenSelectedOfficeCard({
    disableClose: isMobile && queryParams.officeId !== undefined,
    noRemoveQuery: true
  });

  const isDashboardLink = pathname.includes("/dashboard") && queryParams.officeId !== undefined

  const dashboardOfficeLink = queryParams.officeId

  const totalTasksCountLabel =
    data.toString().length > 7
      ? `${data.toString().slice(0, 7)}...`
      : data.toString();

  const noInnerMenu = {height: 0, url: ""};
  const [openInnerMenu, setOpenInnerMenu] = useState<{
    height: number;
    url: string;
  }>(noInnerMenu);
  const innerMenuRef = useRef<HTMLDivElement>(null);

  const handleOpenInnerMenu = (queries: [string, string][]) => {
    let queriesString = "";

    queries.forEach((query) => {
      const [key, value] = query;

      const firstSymbol = queriesString.length === 0 ? "" : "&";
      queriesString += `${firstSymbol}${key}=${value}`;
    });

    push(`${openInnerMenu.url}?${queriesString}`);
    setOpenInnerMenu(noInnerMenu);
  };

  const servicesInnerMenu = isCoWorking
    ? [
      {
        id: "8",
        text: "Мои заказы",
        onClick: () => handleOpenInnerMenu([["tab", "ORDERS"]]),
      },
      {
        id: "1",
        text: "Магазин",
        onClick: () => handleOpenInnerMenu([["tab", "PRODUCTS"]]),
      },
    ]
    : (isBoss ? [
      {
        id: "8",
        text: "Мои заказы",
        onClick: () => handleOpenInnerMenu([["tab", "ORDERS"]]),
      },
      {
        id: "1",
        text: "Магазин",
        onClick: () => handleOpenInnerMenu([["tab", "PRODUCTS"]]),
      },
      {
        id: "2",
        text: "Офис",
        onClick: () => handleOpenInnerMenu([["tab", "OFFICES"]]),
      },
      {
        id: "3",
        text: "Сотрудники",
        onClick: () => handleOpenInnerMenu([["tab", "EMPLOYERS"]]),
      },
    ] : [
      {
        id: "8",
        text: "Мои заказы",
        onClick: () => handleOpenInnerMenu([["tab", "ORDERS"]]),
      },
      {
        id: "1",
        text: "Магазин",
        onClick: () => handleOpenInnerMenu([["tab", "PRODUCTS"]]),
      },
      {
        id: "2",
        text: "Офис",
        onClick: () => handleOpenInnerMenu([["tab", "OFFICES"]]),
      },
    ])


  const innerMenu: InnerMenuType = {
    [ paths.services.list ]: servicesInnerMenu,
    [ paths.leadsAndDealsPath.list ]: [
      {
        id: "1",
        text: "Контакты",
        onClick: () => handleOpenInnerMenu([["tab", "contacts"]]),
      },
      {
        id: "2",
        text: "Лиды",
        onClick: () => handleOpenInnerMenu([["tab", "leads"]]),
      },
      {
        id: "3",
        text: "Заявки",
        onClick: () => handleOpenInnerMenu([["tab", "applications"]]),
      },
      {
        id: "4",
        text: "Сделки",
        onClick: () => handleOpenInnerMenu([["tab", "deals"]]),
      },
    ],
    [ paths.objectsPath.list ]: [
      {
        id: "1",
        text: "Парсер",
        // eslint-disable-next-line sonarjs/no-duplicate-string
        onClick: () => handleOpenInnerMenu([["tab", "PARSED"]]),
      },
      {
        id: "4",
        text: "Мои объекты",
        onClick: () => handleOpenInnerMenu([["tab", "AGENCY"]]),
      },
      {
        id: "6",
        text: "Реклама",
        onClick: () => {
          push(paths.advertisingPath.list)
        }
      },
      // {
      //   id: "7",
      //   text: "База СДЕЛКА ПРО",
      //   onClick: () => {},
      // },
    ],
  };

  const sidebarMenu: SideBarMenu = [
    {
      Icon: <SecondaryHousingIcon/>,
      text: "Объекты",
      url: paths.objectsPath.list,
      isInnerMenu: true,
    },
    {
      Icon: <DocumentIcon/>,
      text: "CRM",
      url: paths.leadsAndDealsPath.list,
      isInnerMenu: true,
    },
    {
      Icon: <Roll/>,
      text: "Задачи",
      url: paths.tasks.list,
      children: !totalTasksCountLabel ? undefined : (
        <Badge
          className={styles.sidebarBadge}
          number={totalTasksCountLabel as unknown as number}
          hideZero={false}
        />
      ),
    },
    {
      Icon: <Service style={{opacity: "0.7"}}/>,
      text: "Магазин",
      url: paths.services.list,
      isInnerMenu: true,
    },
    // {
    //   Icon: <DocumentIcon/>,
    //   text: "Дашборд",
    //   url: paths.dashboardStatisticPath.list,
    // },
  ];
  const sidebarMenuActual = userSubscriptionFinished ? sidebarMenu.slice(3) : sidebarMenu

  const sidebarMenuUnAuthorized: SideBarMenu = [
    {
      Icon: <Sdelka/>,
      text: "О платформе",
      url: isDashboardLink ? `${paths.dashboardPath}?officeId=${dashboardOfficeLink}` : `${paths.dashboardPath}`,
      isInnerMenu: false,
    },
    {
      Icon: <PersonGroup/>,
      text: dashboardOfficeLink ? "О клубе" : "Клубы",
      url: isDashboardLink ? `${paths.dashboardPath}?officeId=${dashboardOfficeLink}` : `${paths.selectOffice.list}?tab=SELECT_OFFICE`,
      isInnerMenu: false,
    },
  ]

  const authorizedMenu = currentAccount !== undefined ? sidebarMenuActual : sidebarMenuUnAuthorized

  useEffect(() => {
    if (width > 1600) {
      dispatch(setExpanded(true));
    }
    if (width < 1600) {
      dispatch(setExpanded(false));
    }
  }, [width]);

  const handleOpenCreateModal = () => {
    dispatch(
      openModal({
        title: "Выберите, что хотите создать",
        body: <AddModal/>,
        alertIcon: "plus",
        variant: "alert",
      })
    );
  };

  const handleOpenVideoLessonsModal = () => {
    dispatch(
      openModal({
        width: 800,
        body: <VideoLessonsModal/>,
      })
    );
  }

  const {executeUrl} = useIsTelegram()


  const openTG = () => {
    executeUrl(`https://t.me/sdelka_Ai`)
  }

  const handleLinkClick = (url: string): void => {
    if (url === paths.objectsPath.list) {
      dispatch(setFiltersShowButton(true));
    }
    if (url === `${paths.dashboardPath}?officeId=${dashboardOfficeLink}`) {
      openOfficeDrawer(dashboardOfficeLink)
    }
  };

  const handleHoverMenuItem = (event: MouseEvent<HTMLDivElement>, url: string) => {
    const element = document.getElementById(`${menuItemIdPrefix}${url}`);

    setOpenInnerMenu({
      height: url && element ? element.offsetTop : 0,
      url,
    });
  };


  const [isOpen, setIsOpen] = useState(false)

  const tgIcon = (isAccountsOpen || width > 1600 || !isOpen) ? IconTG : IconTGReversed

  return (
    <div
      ref={innerMenuRef}
      className={cn(styles.sidebar, {
        [ styles.open ]: isAccountsOpen || openInnerMenu.url || width > 1600,
        [ styles.sidebar__inner ]: openInnerMenu.url,
      })}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => {
        setIsOpen(false)
        setOpenInnerMenu(noInnerMenu)
      }}
    >
      <div
        className={cn(styles.sidebarBlur, {
          [ styles.sidebarBlur__open ]: isOfficesOpen,
        })}
      />
      <div
        className={cn(styles.sidebarColumn, {
          [ styles.sidebar__wrapperHide ]: isAccountsOpen,
        })}
      >
        <div className={styles.logo} onClick={() => push("/dashboard")}>
          <MainLogoMini/>
          <MainLogo/>
        </div>
        <div className={styles.sidebarColumn__content}>
          {!userSubscriptionFinished && registrationIsFinished &&
            <div className={styles.buttonContainer}>
              <Button
                variant="outlined"
                Icon={PlusIcon}
                onClick={handleOpenCreateModal}
                classNames={{
                  root: cn(styles.button, {
                    [ styles.buttonShort ]: isAccountsOpen,
                  }),
                }}
              >
                <span className={styles.buttonText} style={{marginLeft: "9px"}}>Создать</span>
              </Button>
            </div>}
          <div className={styles.sidebarMenu}>
            {authorizedMenu.map((it) => (
              <div
                id={`${menuItemIdPrefix}${it.url}`}
                key={it.url}
                onMouseEnter={(event: MouseEvent<HTMLDivElement>) =>
                  handleHoverMenuItem(event, it.isInnerMenu ? it.url : "")
                }
                className={cn(styles.sidebarMenuItem, {
                  [ styles.sidebarMenuItemHide ]: it.hide,
                })}
              >
                <Link
                  to={it.url}
                  onClick={() => handleLinkClick(it.url)}
                  className={cn(styles.sidebarMenuItem__link, {
                    [ styles.sidebarMenuItem__link_active ]: pathname.includes(it.url),
                    [ styles.sidebarMenuItem__link_active ]: search.includes(it.url.slice(1)),
                  })}
                >
                  <div className={styles.aniBorder}/>
                  <div className={styles.sidebarMenuItem__icon}>{it.Icon}</div>
                  <span className={styles.sidebarMenuItem__text}>{it.text}</span>
                  {it.children}
                </Link>
              </div>
            ))}
            {currentAccount &&
              <div className={styles.sidebarMenuItem}>
                <button
                  type="button"
                  onClick={() => dispatch(logoutThunk())}
                  className={cn(styles.sidebarMenuItem__link, styles.sidebarMenuItem__button)}
                >
                  <div className={styles.aniBorder}/>
                  <div className={styles.sidebarMenuItem__icon}>
                    <LogoutIcon/>
                  </div>
                  <span className={styles.sidebarMenuItem__text}>Выйти</span>
                </button>
              </div>
            }
          </div>
          {!userSubscriptionFinished && currentAccount &&
            <div className={styles.buttonContainerVideo}>
              <Button
                variant="outlined"
                Icon={IconVideoPlay}
                onClick={handleOpenVideoLessonsModal}
                classNames={{
                  root: cn(styles.buttonVideo, {
                    [ styles.buttonShort ]: isAccountsOpen,
                  }),
                }}
              >
                <span className={styles.buttonText}>Видео-уроки</span>
              </Button>
            </div>}
          <div className={styles.buttonContainerTG}>
            <Button
              variant="outlined"
              Icon={tgIcon}
              onClick={openTG}
              classNames={{
                root: cn(styles.buttonTG, {
                  [ styles.buttonShort ]: isAccountsOpen,
                }),
              }}
            >
              <span className={styles.buttonText}>@sdelka_crm_bot</span>
            </Button>
          </div>
          {currentAccount && <AccountSwitcher isOpen={isAccountsOpen || width > 1600 || isOpen}/>}
          {!currentAccount &&
            <div className={styles.unAuthorized}>
              <Link
                to={paths.registrationPath.default}
                className={cn(styles.sidebarMenuItem__text)}
              >
                <span className={styles.sidebarMenuItem__text}>Зарегистрироваться</span>
              </Link>
              <Link
                to={paths.loginPath}
                className={cn(styles.sidebarMenuItem__text)}
              >
                <span className={styles.sidebarMenuItem__text}>Войти</span>
              </Link>
            </div>
          }

        </div>
      </div>
      <InnerMenu
        isOpen={Boolean(openInnerMenu.url)}
        list={openInnerMenu.url ? innerMenu[ openInnerMenu.url ] : []}
        paddingTop={openInnerMenu.height}
      />
    </div>
  );
});
