import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";

import { api } from "../../index";

type Props = {
	source: "cian" | "avito" | "yandex";
};

export const useFindJK = ({ source }: Props) => {
	const queryClient = useQueryClient();

	const name = `findJK${source}`;

	const mutate = useMutation(name, {
		mutationFn: (query: string) => api.realEstate.findJK({ source, query }),
	});

	useEffect(() => () => mutate.reset(), []);
	const getLastMutationCache = () => {
		const allMutations = queryClient.getMutationCache().findAll({ mutationKey: name });

		return allMutations[allMutations.length - 1];
	};

	return {
		mutate: mutate.mutate,
		mutateAsync: mutate.mutateAsync,
		isLoading: mutate.isLoading,
		getLastMutationCache,
	};
};
