import { formatMillion, formatThousand } from "../formatters";


type Props = {
  // as number
  price?: number | string | undefined;
  // as number
  pricePerUnit?: number | string;
  priceRangeFrom?: number | string | undefined;
  priceRangeTo?: number | string | undefined;
};

export const getPriceLabel = ({price, pricePerUnit, priceRangeFrom, priceRangeTo}: Props): string => {
  const priceNotDefined = "Цена не указана"

  if (pricePerUnit) {
    return pricePerUnit ? `${formatThousand(pricePerUnit)} ₽/м²` : priceNotDefined;
  }

  if (priceRangeFrom) {
    return priceRangeFrom ? ` от ${formatMillion(priceRangeFrom)}` : priceNotDefined;
  }

  if (priceRangeTo) {
    return priceRangeTo ? ` до ${formatMillion(priceRangeTo)} ₽` : priceNotDefined;
  }

  return price ? `${formatMillion(price)} ₽` : priceNotDefined;
}


