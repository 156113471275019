import {
	RegStepsAction,
	SET_CURRENT_STEP,
	SET_VERIFICATION_CODE,
	SET_INVITE_CODE,
	SET_SAW_INVITES,
	SET_SHOW_COMPANY_DATA,
	SET_COMPANY_DATA,
	SET_COMPANY_NAME,
	SET_REGISTRATION_DATA,
	CLEAN_UP,
	SET_JWT_TOKEN,
} from "./reg-steps.action";
import { RegStepsTInitialState } from "../../types";

const initialState: RegStepsTInitialState = {
	currentStep: "first-step",
};

export const regStepsReducer = (state = initialState, action: RegStepsAction): RegStepsTInitialState => {
	switch (action.type) {
		case SET_CURRENT_STEP:
			return {
				...state,
				currentStep: action.step,
			};
		case SET_VERIFICATION_CODE:
			return {
				...state,
				verificationCode: action.code,
			};
		case SET_INVITE_CODE:
			return {
				...state,
				inviteCode: action.code,
			};
		case SET_SAW_INVITES:
			return {
				...state,
				sawInvites: action.saw,
			};
		case SET_SHOW_COMPANY_DATA:
			return {
				...state,
				showCompanyData: action.showData,
			};
		case SET_COMPANY_DATA:
			return {
				...state,
				companyData: action.company,
			};
		case SET_COMPANY_NAME:
			return {
				...state,
				companyName: action.name,
			};
		case SET_REGISTRATION_DATA:
			return {
				...state,
				registrationData: action.data,
			};
		case SET_JWT_TOKEN:
			return {
				...state,
				jwtToken: action.token,
			};
		case CLEAN_UP:
			return {
				...state,
				currentStep: "personal-data",
				verificationCode: undefined,
				inviteCode: undefined,
				showCompanyData: undefined,
				companyData: undefined,
				companyName: undefined,
				registrationData: undefined,
			};
		default:
			return state;
	}
};
