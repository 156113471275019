import { useEffect, useState, useMemo } from "react";
import { RealEstateSchemaConfigField, FormPropsEditRealEstate } from "../../../../../../../../../../types";

import { InfoRow, Select } from "../../../../../../../../index";
import { RequiredDots } from "../../RequiredDots";
import { getDefaultForSelect } from "../../../../../helpers";

import styles from "./index.module.scss";

type Props = {
	form: FormPropsEditRealEstate;
	field: RealEstateSchemaConfigField;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Material = ({ form, field }: Props): JSX.Element => {
	const { register, watch, setValue, getValues } = form;
	const {
		label,
		reqiredFor,
		dataType: { nameGroup = [], allowedValues = [] },
	} = field;

	const names = {
		default: nameGroup[0],
		avito: nameGroup[1],
		cian: nameGroup[2],
		yandex: nameGroup[3],
	};

	const [displayErrors, setDisplayErrors] = useState({
		cian: false,
		avito: false,
		yandex: false,
	});

	const selectedMaterial = watch(names.default);

	// All allowed options for select
	const options = useMemo(
		() =>
			allowedValues.map((allowed) => ({
				label: allowed.value as string,
				value: allowed.value as string,
			})),
		[allowedValues]
	);

	// Get allowed for concrete platform
	const getAllowed = (
		allowedOptions: Record<string, unknown>[],
		platform: "feedExistsAvito" | "feedExistsCian" | "feedExistsYandex"
	) =>
		allowedOptions
			.filter((allowed) => allowed[platform])
			.map((allowed) => ({
				label: allowed.value as string,
				value: allowed.value as string,
			}));

	const allowedOptionsAvito = getAllowed(allowedValues as Record<string, unknown>[], "feedExistsAvito");
	const allowedOptionsCian = getAllowed(allowedValues as Record<string, unknown>[], "feedExistsCian");
	const allowedOptionsYandex = getAllowed(allowedValues as Record<string, unknown>[], "feedExistsYandex");

	useEffect(() => {
		const isCurrendAllowedAtAvito = allowedOptionsAvito.find((allowed) => allowed.value === selectedMaterial);
		const isCurrendAllowedAtCian = allowedOptionsCian.find((allowed) => allowed.value === selectedMaterial);
		const isCurrendAllowedAtYandex = allowedOptionsYandex.find(
			(allowed) => allowed.value === selectedMaterial
		);

		if (selectedMaterial) {
			setDisplayErrors((prev) => ({
				...prev,
				avito: !isCurrendAllowedAtAvito,
				cian: !isCurrendAllowedAtCian,
				yandex: !isCurrendAllowedAtYandex,
			}));
		}

		// Clean up additional fields if value is allowed
		if (isCurrendAllowedAtAvito) {
			setValue(names.avito, undefined);
		}
		if (isCurrendAllowedAtCian) {
			setValue(names.cian, undefined);
		}
		if (isCurrendAllowedAtYandex) {
			setValue(names.yandex, undefined);
		}
	}, [selectedMaterial]);

	if (!nameGroup.length || !allowedValues.length) {
		return <></>;
	}

	return (
		<>
			<InfoRow
				label={label}
				labelFont="ubuntu"
				aspect={{ label: 1, content: 1 }}
				classNames={{ root: styles.customInfoRow }}
			>
				<RequiredDots required={reqiredFor} />

				<Select
					register={register}
					setValue={setValue}
					name={names.default}
					defaultValue={
						getDefaultForSelect({
							fieldName: names.default,
							getValues,
						}) as string
					}
					options={options}
					placeholder="Выбрать"
				/>
			</InfoRow>

			{displayErrors.avito && <div className={styles.error}>Такой опции не существует на Avito</div>}

			{displayErrors.cian && <div className={styles.error}>Такой опции не существует на Циан</div>}

			{displayErrors.yandex && <div className={styles.error}>Такой опции не существует на Yandex</div>}

			{displayErrors.avito && (
				<InfoRow
					label={`${label} (Avito)`}
					labelFont="ubuntu"
					aspect={{ label: 1, content: 1 }}
					classNames={{ root: styles.customInfoRow }}
				>
					<RequiredDots required={{ avito: reqiredFor?.avito }} />

					<Select
						register={register}
						setValue={setValue}
						name={names.avito}
						defaultValue={
							getDefaultForSelect({
								fieldName: names.avito,
								getValues,
							}) as string
						}
						options={allowedOptionsAvito}
						placeholder="Выбрать"
					/>
				</InfoRow>
			)}

			{displayErrors.cian && (
				<InfoRow
					label={`${label} (Циан)`}
					labelFont="ubuntu"
					aspect={{ label: 1, content: 1 }}
					classNames={{ root: styles.customInfoRow }}
				>
					<RequiredDots required={{ cian: reqiredFor?.cian }} />

					<Select
						register={register}
						setValue={setValue}
						name={names.cian}
						defaultValue={
							getDefaultForSelect({
								fieldName: names.cian,
								getValues,
							}) as string
						}
						options={allowedOptionsCian}
						placeholder="Выбрать"
					/>
				</InfoRow>
			)}

			{displayErrors.yandex && (
				<InfoRow
					label={`${label} (Yandex)`}
					labelFont="ubuntu"
					aspect={{ label: 1, content: 1 }}
					classNames={{ root: styles.customInfoRow }}
				>
					<RequiredDots required={{ yandex: reqiredFor?.yandex }} />

					<Select
						register={register}
						setValue={setValue}
						name={names.yandex}
						defaultValue={
							getDefaultForSelect({
								fieldName: names.yandex,
								getValues,
							}) as string
						}
						options={allowedOptionsYandex}
						placeholder="Выбрать"
					/>
				</InfoRow>
			)}
		</>
	);
};
