import { RealEstateList } from "../../../../../UI";
import { useLoadApplicationRealEstates } from "../../../../../../service/api/queries";
import { useOpenRealEstate } from "./helpers";
import { RealEstate } from "../../../../../../types";
import styles from "./index.module.scss";
import { Plug } from "../../../../RealEstatePage/mode/RealEstateView/components/plug/Plug";

type Props = {
  id: string;
  contactId: string;
  isMobile?: boolean
};

const DEFAULT_TAKE = 3;

export const ContactRealEstates = (
  {
    id,
    contactId,
    isMobile
  }: Props): JSX.Element => {
  const join = ["responsibleUsers", "contacts", "contacts.contact", "photos"];

  const {realEstatesList, total, isFetching, isLoading, setTake} = useLoadApplicationRealEstates({
    defaultFilter: {contactId},
    defaultTake: DEFAULT_TAKE,
    join,
    options: {
      keepPreviousData: true,
    },
  });

  const {handleOpenClientEstate} = useOpenRealEstate();

  const handleCardClick = (object: RealEstate) => handleOpenClientEstate(object.applicationId);

  return (
    <div className={styles.root} id={id}>
      {realEstatesList && realEstatesList.length > 0
        ? <RealEstateList
          list={realEstatesList}
          setTake={setTake}
          isFetching={isFetching}
          isLoading={isLoading}
          title="Объекты"
          handleCardClick={handleCardClick}
          blockId={id}
          total={total}
          classNames={{
            root: styles.objectCard
          }}
          isMobile={isMobile}
        />
        : <div>
          <div className={styles.titleObject}>
            Объекты
          </div>
          <Plug plugText="Объектов нет"/>
        </div>
      }
    </div>
  );
};
