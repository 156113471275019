import { CLEAN_UP, DeviceAction, SET_DEVICE } from "./device.action";

const initialState = {
  isMobile: undefined,
  isTelegram: undefined
}

export const deviceReducer = (
  state = initialState,
    action: DeviceAction
) => {
  switch (action.type) {
    case CLEAN_UP:
      return {
        ...state,
        isMobile: undefined,
        isTelegram: undefined
      };
    case SET_DEVICE: {
      return {
        ...state,
        isMobile: action.isMobile,
        isTelegram: action.isTelegram
      }
    }
    default:
      return state
  }
}