import { FormState } from "react-hook-form";
import { FormPropsEditRealEstate, RealEstateSchemaConfigField } from "../../../../../../../types";

import { ComplexFields, SimpleFields } from "./InputRows";

import styles from "./index.module.scss";

type Props = {
  form: FormPropsEditRealEstate;
  fields: RealEstateSchemaConfigField[];
};

const getError = (formState: FormState<any>, name?: string, nameGroup?: string[]): string => {
  if (name && formState?.errors[ name ]) {
    return formState?.errors[ name ]?.type === "required"
      ? "Обязательное поле"
      : (formState?.errors[ name ]?.message?.toString() as string);
  }

  if (nameGroup) {
    for (let i = 0; i < nameGroup.length; i += 1) {
      if (formState?.errors[ nameGroup[ i ] ]) {
        return formState?.errors[ nameGroup[ i ] ]?.type === "required"
          ? "Обязательное поле"
          : (formState?.errors[ nameGroup[ i ] ]?.message?.toString() as string);
      }
    }
  }

  return "";
};

export const BuildingInfo = ({form, fields}: Props): JSX.Element => (
  <>
    {fields.map((field, index) => {
      const error = getError(form.formState, field.dataType.name, field.dataType.nameGroup);
      const {dataType, generalName} = field;
      const {name, nameGroup} = dataType;

      return (
        <div key={`${generalName}.${name || nameGroup?.join("-") || index}`}>
          {name && <SimpleFields form={form} field={field}/>}
          {nameGroup && <ComplexFields form={form} field={field}/>}

          {error && <div className={styles.error}>{error}</div>}
        </div>
      );
    })}
  </>
);
