import styles from "./index.module.scss";
import { ReactComponent as Filters } from "../../../../../assets/icons/filters.svg";
import { ReactComponent as CancelIcon } from "../../../../../assets/icons/cancel.svg";
import { closeDrawer, openDrawer } from "../../../../../service/redux/modules/drawer/drawer.action";
import { RequestFiltersModal } from "../../RequestFilters";
import { useForm } from "react-hook-form";
import { DEFAULT_TRAFFIC } from "../../../../../service/api/helpers";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useResponsibleUsers } from "../../../../../utils/hooks";
import { useLoadBusinessProcessSources } from "../../../../../service/api/queries";
import { ApplicationsFilter, ContactFilter, LeadFiltersType } from "../../../../../types";
import { LeadsFiltersMobile } from "../../LeadFilters/filters-mobile/LeadsFiltersMobile";
import { ContactsFiltersMobile } from "../../../Contacts/ContactsFilters/filters-mobile/ContactsFiltersMobile";

type Props = {
  filtersCount: number
  formSubmit: (values: ApplicationsFilter | LeadFiltersType | ContactFilter) => void;
  variant: "leads" | "applications" | "contacts"
  filter?: LeadFiltersType | ContactFilter;
  setPage?: (page: number) => void;
}

export const MobileAppFilterButtons = (
  {
    filtersCount,
    formSubmit,
    variant,
    filter,
    setPage
  }: Props) => {
  const dispatch = useDispatch();
  const {users} = useResponsibleUsers();
  const {getValues, watch, reset} = useForm({
    defaultValues: DEFAULT_TRAFFIC,
  });
  const [showViews, setShowViews] = useState(false);

  const {data: sourcesList} = useLoadBusinessProcessSources();

  const sourcesOptions = useMemo(
    () =>
      sourcesList.map((source) => ({
        value: source,
        label: source,
      })),
    [sourcesList]
  );

  const prepareSubmitForm = (values) => {
    reset(values);
    formSubmit(values);
  };

  const formWatcher = watch();

  const submittedFromModal = (values) => {
    dispatch(closeDrawer());
    prepareSubmitForm({
      ...values,
      fullTextSearch: formWatcher.fullTextSearch,
      typeId: formWatcher.typeId,
      responsibleUsers: formWatcher.responsibleUsers,
      signed: formWatcher.signed,
    });
  };

  const onOpenLeadsFilters = () => {
    dispatch(openDrawer({
      children: <LeadsFiltersMobile filter={filter || {}} formSubmit={formSubmit}/>,
      width: 500,
    }))
  }

  const onOpenContactsFilters = () => {
    dispatch(openDrawer({
      children: <ContactsFiltersMobile filter={filter || {}} formSubmit={formSubmit}/>,
      width: 500,
    }))
  }

  const onOpenRequests = () => {
    const curShowViews = !showViews;
    setShowViews(curShowViews);
    dispatch(
      openDrawer({
        children: (
          <RequestFiltersModal
            defaultValues={getValues()}
            users={users}
            sourcesOptions={sourcesOptions}
            submitFilter={submittedFromModal}
            formWatcher={formWatcher}
            isMobile
          />
        ),
        width: 500,
      })
    );
  };

  const onOpen = () => {
    switch (variant) {
      case "applications":
        return onOpenRequests()
      case "leads":
        return onOpenLeadsFilters()
      case "contacts":
        return onOpenContactsFilters()
      default:
        return onOpenRequests()
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <div className={styles.button} onClick={onOpen}>
          <Filters/>
          {filtersCount !== 0 &&
            <div className={styles.selectedFilters}>{filtersCount}</div>
          }
        </div>
        <div className={styles.buttonClear} onClick={() => {
          reset(DEFAULT_TRAFFIC)
          prepareSubmitForm(DEFAULT_TRAFFIC);
        }}>
          <span>Сбросить</span>
          <CancelIcon/>
        </div>
      </div>
    </div>
  )
}