import { Button, H3 } from "@sdelka_crm/sdelka-crm-component-library";
import { TableApplication } from "../../../../../UI";
import { ApplicationsSkeleton, NotApplication } from "./components";
import { useLoadApplications } from "../../../../../../service/api/queries";
import { ApplicationInList, ApplicationsFilter, Contact } from "../../../../../../types";
import styles from "./index.module.scss";
import { useIsTelegram } from "../../../../../../utils/telegram/useIsTelegram";

type Props = {
  id: string;
  contact: Contact;
  isMobile?: boolean
};

const DEFAULT_TAKE = 3;

export const Applications = (
  {
    id,
    contact,
    isMobile
  }: Props): JSX.Element => {
  const contactId = contact.id;

  const defaultFilter: ApplicationsFilter = {contacts: {contactId}};

  const {list, isLoading, isFetching, take, setTake, total} = useLoadApplications({
    defaultFilter,
    keepPreviousData: true,
    defaultTake: DEFAULT_TAKE,
  });

  const ClickMore = () => setTake(take + DEFAULT_TAKE);

  const SetTakeDefault = () => setTake(DEFAULT_TAKE);

  const {executeUrl} = useIsTelegram()

  const handleOpenApplication = ({id: applicationId, type: {whoIsClient}}: ApplicationInList) => {
    executeUrl(`/leadsanddeals?tab=applications&id=${applicationId}&client=${whoIsClient}`);
  };

  return (
    <div className={styles.root} id={id}>
      <H3
      >
        Заявки
      </H3>
      {isLoading ? (
        <ApplicationsSkeleton/>
      ) : (
        <>
          {list.length > 0 ? (
            <>
              <TableApplication
                data={list}
                isLoading={false}
                rowClass={styles.requestsTableRow}
                dots={false}
                rowsCount={take}
                withStage
                tableRowClick={handleOpenApplication}
                isMobile={isMobile}
              />

              <div className={styles.buttons}>
                {take !== 3 && !isFetching && (
                  <Button variant="text" onClick={SetTakeDefault}>
                    Свернуть
                  </Button>
                )}

                {total !== list.length && (
                  <Button variant="text" onClick={ClickMore} isLoading={isFetching}>
                    {`Еще ${total - take}`}
                  </Button>
                )}
              </div>
            </>
          ) : (
            <NotApplication/>
          )}
        </>
      )}
    </div>
  );
};
