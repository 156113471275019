import { ReactNode } from "react";
import cn from "classnames";
import { Badge } from "@sdelka_crm/sdelka-crm-component-library";

import styles from "./index.module.scss";

export type TableColumns<T> = {
  accessor: string;
  forceShow?: (row: T) => boolean;
  showOnHover?: boolean;
  // Надо
  // @ts-ignore
  render?: ({item: any, row: T}) => ReactNode;
  columnClassName?: string;
};


type Props<T> = {
  data: T[];
  columns: TableColumns<T>[];
  idKey: string;
  groupKey?: string;
  transformGroupTitle?: (item: string, data?: T) => string | ReactNode;
  rowClass?: string;
  selectedRowId?: string
  colClass?: string;
  loading?: {
    isLoading: boolean;
    rowsCount?: number;
  };
  onRowClick?: (row: T) => void;
  activeRow?: {
    onContextMenu?: (row: T) => void;
    activeId: string | number;
    accessor: string;
  };
  getBadge?: (row: T) => number;
  blinkRows?: string;
  isMobile?: boolean
};

type ObjectType = Record<string, unknown>;

export const Table = <T extends ObjectType>(
  {
    data,
    columns,
    idKey,
    groupKey,
    transformGroupTitle,
    rowClass,
    colClass,
    loading,
    onRowClick,
    activeRow,
    getBadge,
    blinkRows,
    isMobile,
    selectedRowId
  }: Props<T>): JSX.Element => {
  const onContextMenu = (event, row: T) => {
    event.preventDefault();
    activeRow?.onContextMenu?.({id: ""} as never);
    setTimeout(() => activeRow?.onContextMenu?.(row), 0);
  };
  const handleRowClick = (row: T) => onRowClick && onRowClick(row);

  return (
    <>
      {loading?.isLoading && (
        <>
          {Array.from(Array(loading?.rowsCount || 10).keys()).map((row) => (
            <div key={row} className={styles.rowLoading}/>
          ))}
        </>
      )}
      {!loading?.isLoading &&
        data.map((item: T, index) => {
          const badge = getBadge ? getBadge(item) : undefined;
          return (
            <Badge key={item[ idKey ] as string} number={badge} hideZero className={styles.tableBadge}>
              <div>
                {groupKey && (index === 0 || item[ groupKey ] !== data[ index - 1 ][ groupKey ]) && (
                  <div
                    className={cn(styles.groupTitle, {
                      [ styles.groupTitleFirst ]: index === 0,
                    })}
                  >
                    {!transformGroupTitle && (item[ groupKey ] as string)}
                    {transformGroupTitle && transformGroupTitle(item[ groupKey ] as string, item)}
                  </div>
                )}
                <div
                  className={cn(styles.row, rowClass, {
                    [ styles.activeRow ]: activeRow && activeRow.activeId === item[ activeRow.accessor ],
                    [ styles.blinkRow ]: blinkRows === item?.[ idKey ],
                    [ styles.isMobile ]: isMobile,
                    [styles.selected] : selectedRowId === item.id
                  })}
                  onContextMenu={(event) => onContextMenu(event, item)}
                  onClick={() => handleRowClick(item)}
                >
                  {columns.map((column) => (
                    <div
                      key={column.accessor}
                      className={cn(styles.column, colClass, column.columnClassName, {
                        [ styles.hidden ]: column.showOnHover && !(column.forceShow && column.forceShow(item)),
                      })}
                    >
                      {column.render &&
                        column.render({
                          item,
                          row: item,
                        })}
                      {!column.render && (item as ObjectType)}
                    </div>
                  ))}
                </div>
              </div>
            </Badge>
          );
        })}
    </>
  );
};
