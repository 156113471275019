import { CartPayload, ProductTypeEnum, SelectOption } from "../../../../../../types";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../../../service/redux/modules/modal/modal.action";
import { AddInCartModal } from "./AddInCartModal";
import styles
  from "../../../../Application/ApplicationCard/components/ApplicationInfo/component/Info/components/Body/components/BodyInfo/components/apply-service-modal/index.module.scss";

type Props = {
  title: string;
  type: ProductTypeEnum
  action: (payload: CartPayload[]) => void
  tariffLabel?: string
  tariffOptions?: SelectOption[]
  buyInOneClick?: boolean
  applicationId?: string
  isMobile?: boolean
  refetchCart?: () => void
  adPlatformAccountId?: string
}

type Return = {
  openAddInCartModal: (ids: string[], oneClick?: boolean) => void;
};

export const useAddInCartModal = (
  {
    title,
    type,
    action,
    tariffLabel,
    tariffOptions,
    buyInOneClick,
    applicationId,
    isMobile,
    refetchCart,
    adPlatformAccountId
  }: Props): Return => {
  const dispatch = useDispatch();

  const openAddInCartModal = (ids: string[], oneClick?: boolean) => {

    dispatch(
      openModal({
        body: (
          <AddInCartModal
            action={action}
            ids={ids}
            title={title}
            type={type}
            tariffLabel={tariffLabel}
            tariffOptions={tariffOptions}
            buyInOneClick={oneClick || buyInOneClick}
            applicationId={applicationId}
            refetchCart={refetchCart}
            adPlatformAccountId={adPlatformAccountId}
          />
        ),
        width: isMobile ? 500 : 660,
        classNames: {
          contentWrapper: isMobile ? styles.contentWrapperClassNameMobile : "",
          buttonClose: isMobile ? styles.modalClose : ""
        }
      })
    );
  };

  return {
    openAddInCartModal
  }
}