import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useEffect, useState } from "react";
import { MarkdownEditor } from "../../../UI";
import cn from "classnames";
import styles from "./index.module.scss"
import { ReactComponent as SaveText } from "../../../../assets/icons/saveComment.svg";
import { useUpdateProductOrder } from "../../../../service/api/mutations";

type Props = {
  orderId: string
  comment: string;
};

export const ProductOrderComment = (
  {
    orderId,
    comment,
  }: Props) => {
  const schema = yup.object().shape({
    innerComment: yup.string().max(2510, "Слишком длинный комментарий"),
  });

  const {handleSubmit, setValue, getValues, register, formState, control} = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      innerComment: comment,
    },
  });

  const commentWatcher = useWatch({
    name: "innerComment",
    control,
    defaultValue: getValues("innerComment"),
  });

  const [editMode, setEditMode] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [, setEnabled] = useState(false);

  const {mutate} = useUpdateProductOrder({
    onSuccess: () => {
      setEditMode(false);
    },
  });

  useEffect(() => {
    setEnabled(true)
  }, [])

  useEffect(() => {
    if (comment !== commentWatcher) {
      setValue("innerComment", comment)
    }
    setEditMode(false)
  }, [comment])

  const onSubmit = () => {
    const curComment = getValues().innerComment;

    if (orderId) {
      const payload = {
        id: orderId,
        payload: {description: curComment},
      };
      mutate(payload);
    }
  };

  const startEdit = () => setEditMode(true)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.row}>
        <div className={styles.title}>Примечание к заказу</div>
      </div>
      <div className={styles.textareaWrapper}>
        <MarkdownEditor
          name="innerComment"
          setValue={setValue}
          getValues={getValues}
          register={register}
          curValue={commentWatcher}
          error={formState.errors.innerComment?.message?.toString()}
          setEditMode={startEdit}
        />
        {editMode &&
          <div
            className={cn(styles.saveText)}
          >
            <div onClick={handleSubmit(onSubmit)}>
              <SaveText/>
            </div>
          </div>
        }
      </div>
    </form>
  );
}