import { useQuery } from "react-query";
import { Dispatch, SetStateAction, useState } from "react";
import { Conditions, RealEstate, RealEstateFilter, RealEstateSort } from "../../../../types";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/real-estate/load-parsed-real-estates/DEFAULT_QN";
export const PARSED_REAL_ESTATE_LIST_FOR_MAP_QN =
	"sdelka/parsed-real-estate/load-real-estates/REAL_ESTATE_LIST_FOR_MAP_QN";

type Props = {
	defaultTake?: number;
	defaultFilter?: RealEstateFilter;
	absoluteSearchConditions?: Conditions;
	defaultPage?: number;
	join?: string[];
	queryName?: string;
	options?: {
		keepPreviousData?: boolean;
	};
	enabled?: boolean;
};

type Return = {
	parsedRealEstatesList: RealEstate[];
	isLoading: boolean;
	isFetching: boolean;
	total: number;
	take: number;
	setTake: Dispatch<SetStateAction<number>>;
	page: number;
	setPage: Dispatch<SetStateAction<number>>;
	sort?: RealEstateSort;
	setSort: Dispatch<SetStateAction<RealEstateSort | undefined>>;
	filter: RealEstateFilter;
	setFilter: Dispatch<SetStateAction<RealEstateFilter>>;
	refetch: () => void
};

type UseLoadParsedRealEstates = (props: Props) => Return;

export const useLoadParsedRealEstates: UseLoadParsedRealEstates = ({
	defaultTake = 50,
	defaultFilter = {},
	defaultPage = 1,
	join,
	queryName = DEFAULT_QN,
	options = {},
	absoluteSearchConditions = [],
	enabled = true,
}) => {
	const { keepPreviousData } = options;
	const [filter, setFilter] = useState<RealEstateFilter>(defaultFilter);
	const [take, setTake] = useState(defaultTake);
	const [page, setPage] = useState(defaultPage);
	const [sort, setSort] = useState<RealEstateSort | undefined>({
		field: "createdAt",
		sort: "DESC",
	});

	const onSuccess = (data) => {
		const {
			data: { total },
		} = data;

		if (total && total < page) {
			setPage(1);
		}
	};

	const loadRealEstates = () =>
		api.parsedRealEstate.list({
			take,
			skip: take * (page - 1),
			sort,
			search: filter,
			join,
			absoluteSearchConditions,
		});

	const { data, isLoading, isFetching, refetch } = useQuery(
		[queryName, take, page, sort, filter, absoluteSearchConditions],
		loadRealEstates,
		{
			enabled,
			keepPreviousData,
			onSuccess,
		}
	);

	return {
		parsedRealEstatesList: data?.data?.data || [],
		isLoading,
		isFetching,
		total: data?.data?.total || 0,
		take,
		setTake,
		page,
		setPage,
		sort,
		setSort,
		filter,
		setFilter,
		refetch
	};
};
