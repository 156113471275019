export const BusinessProcessTranslateEnum: Record<SellBusinessProcess, string> = Object.freeze({
  rentOut: "Аренда",
  sale: "Продажа",
});

export type SellBusinessProcess = "sale" | "rentOut";

export const SellBusinessProcessEnum: Record<SellBusinessProcess, SellBusinessProcess> = Object.freeze({
  sale: "sale",
  rentOut: "rentOut",
});

export type BuyBusinessProcess = "buy" | "rent" | "buyNewBuilding";

export const BuyBusinessProcessEnum: Record<BuyBusinessProcess, BuyBusinessProcess> = Object.freeze({
  buy: "buy",
  rent: "rent",
  buyNewBuilding: "buyNewBuilding",
});

export type BusinessProcess = SellBusinessProcess | BuyBusinessProcess;

export const BusinessProcessEnum: Record<BusinessProcess, BusinessProcess> = Object.freeze({
  ...BuyBusinessProcessEnum,
  ...SellBusinessProcessEnum,
});

export type ApplicationBusinessProcessType = {
  id: string;
  label: string;
  value: BusinessProcess;
};
