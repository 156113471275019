import { createContext, ReactNode } from "react";
import { YMapsApi } from "react-yandex-maps";

export interface MapContextType {
	ymaps?: YMapsApi;
	mapInstance?: any;
}

export const MapContext = createContext<MapContextType>({});

type Props = {
	children: ReactNode;
	value: MapContextType;
};

export const MapContextProvider = ({ children, value }: Props): JSX.Element => (
	<MapContext.Provider value={value}>{children}</MapContext.Provider>
);
