import { useDispatch } from "react-redux";
import { openModal } from "../../../../../../../../../../../../../../service/redux/modules/modal/modal.action";
import { ApplyServiceModal } from "./ApplyServiceModal";
import styles from "./index.module.scss"

type Props = {
  applicationId: string
  isMobile?: boolean
  adPlatformAccountId?: string
}

type Return = {
  openApplyServiceModal: () => void;
};


export const useApplyServiceModal = (
  {
    applicationId,
    isMobile,
    adPlatformAccountId
  }: Props): Return => {
  const dispatch = useDispatch();

  const openApplyServiceModal = () => {
    dispatch(openModal({
      body: <ApplyServiceModal
        applicationId={applicationId}
        isMobile={isMobile}
        adPlatformAccountId={adPlatformAccountId}
      />,
      width: isMobile ? 500 : 800,
      classNames: {
        contentWrapper: isMobile ? styles.contentWrapperClassNameMobile : "",
        buttonClose: isMobile ? styles.modalClose : "",
        wrapper: styles.wrapper
      }
    }))
  }

  return {
    openApplyServiceModal
  }
}