import { ShowsViews } from "../../../../../types";
import styles from "./index.module.scss"
import { getIcon, getLabels, getMainIcon, getShowViewDate } from "./helpers";
import { ReactComponent as IconMax } from "../../../../../assets/icons/iconMax.svg";
import cn from "classnames";
import { Tooltip } from "@sdelka_crm/sdelka-crm-component-library";
import { ReactComponent as InfoIcon } from "../../../../../assets/icons/iconInfo.svg";
import { getPriceLabel } from "../../../../../utils/clientObjects";

type Props = {
  isViews: boolean;
  item: ShowsViews;
  isMobile?: boolean
}

export const CardHeader = (
  {
    isViews,
    item,
    isMobile
  }: Props): JSX.Element => {
  const {
    status,
    isConfirmed,
    plannedDate,
    actIsSigned,
    offer,
    isMaxOffer,
    finalPrice,
    realDate,
    cancelDate,
    isCreatedByOtherAgent
  } = item

  const finalPriceFormatted = getPriceLabel({price: finalPrice});
  const priceFormatted = getPriceLabel({price: offer});

  return (
    <div className={styles.root}>
      {!isMobile &&
        <div className={styles.mainIcon}>
          {getMainIcon(status)}
        </div>
      }
      <div className={styles.headerInfo}>
        <div className={styles.row}>
          <div className={styles.icon}>
            {getIcon(status, plannedDate)}
          </div>
          <div className={styles.title}>
            {getLabels(status, plannedDate, isViews, isConfirmed, actIsSigned, isCreatedByOtherAgent).title}
          </div>
          { (status === "finished") &&
            <Tooltip
              classNames={{
                root: styles.tooltipDescription,
                card: styles.tooltipCard,
              }}
              iconSize="big"
              CustomIcon={InfoIcon}
            >
              {getLabels(status, plannedDate, isViews, isConfirmed, actIsSigned, isCreatedByOtherAgent).tooltipText}
            </Tooltip>
          }
        </div>
        <div className={cn(styles.row, styles.date, styles.withoutIcon)}>
          {getShowViewDate(status, plannedDate, cancelDate, realDate)}
        </div>
        {(status === "success") &&
          <div className={styles.row}>
            {isMaxOffer && <IconMax style={{marginTop: "2px"}}/>}
            <div className={cn(styles.offer, {[ styles.withoutIcon ]: !isMaxOffer})}>
              {isViews ? "Встречное предложение" : "Ваше встречное предложение"}
            </div>
            <div>
              {priceFormatted}
            </div>
          </div>
        }
        {status === "successFinished" &&
          <div className={styles.row}>
            {isMaxOffer && <IconMax style={{marginTop: "2px"}}/>}
            <div className={cn(styles.offer, {[ styles.withoutIcon ]: !isMaxOffer})}>
              {isViews ? "Встречное предложение" : "Финальная стоимость"}
            </div>
            <div>
              {finalPriceFormatted}
            </div>
          </div>
        }
      </div>
    </div>
  )
}