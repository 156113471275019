import styles from "./index.module.scss";
import { Table } from "../../../../UI";
import { useBlinkRow, useQueryParams } from "../../../../../utils/hooks";
import { useColumns } from "./columns";
import { useCallback } from "react";
import { ProductTile, servicesCategory } from "../../../../../types/product";
import cn from "classnames";
import { useOpenProductCard } from "../../../../../service/servise";

type Props = {
  productsList: ProductTile[];
  isLoading: boolean;
  refetch: () => void;
  isMobile?: boolean
  refetchCart?: () => void
};

export const ProductsTable = (
  {
    productsList,
    isLoading,
    refetch,
    isMobile,
    refetchCart
  }: Props): JSX.Element => {
  // оставлено
  // eslint-disable-next-line no-unused-vars
  const {queryParams, changeQueryParams} = useQueryParams<{
    userId: string;
  }>([{name: "productId"}]);

  const columns = useColumns(refetch, isMobile, refetchCart);
  const {rowsToBlink} = useBlinkRow();

  // оставлено
  // eslint-disable-next-line no-unused-vars
  const {openProductDrawer} = useOpenProductCard({
    refetch,
  });

  const renderTitle: (item: string) => JSX.Element = useCallback(
    (category: string) =>
      (
        <div>
          <div className={styles.title}>
            {servicesCategory[ category ]}
          </div>
        </div>
      )
    ,
    []
  );

  // оставлено
  const handleRowClick = (row: ProductTile) => {
    // changeQueryParams([
    //   {name: "productId", newValue: row.id},
    // ]);
    // openProductDrawer(row.id)
  };

  return (
    <div className={cn(styles.wrapper, {[ styles.mini ]: isMobile})}>
      {productsList?.length > 0 || isLoading ? (
        <Table<ProductTile>
          idKey="id"
          data={productsList}
          columns={columns}
          groupKey="category"
          rowClass={styles.row}
          transformGroupTitle={renderTitle}
          onRowClick={handleRowClick}
          loading={{isLoading, rowsCount: 5}}
          activeRow={{
            accessor: "",
            activeId: 1,
          }}
          blinkRows={rowsToBlink}
        />
      ) : (
        <div className={styles.empty}>Похожих продуктов не найдено</div>
      )}
    </div>
  )
}