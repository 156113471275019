import { useMutation } from "react-query";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/compilation/use-delete-from-cart/DEFAULT_MN";

type UseDeleteFromCart = {
  onSuccess?: () => void;
};

export const useDeleteFromCart = ({ onSuccess }: UseDeleteFromCart) => {
  const mutate = useMutation(DEFAULT_MN, {
    mutationFn: (id: string) => api.cart.delete(id),
    onSuccess,
  });

  return {
    data: mutate.data?.data,
    mutate: mutate.mutate,
    isLoading: mutate.isLoading,
  };
};
