import { Avatar } from "../../../../index";
import { selectCurrentAccount, useSelect } from "../../../../../../service/redux/selectors";
import { formatPhone } from "../../../../../../utils/formatters";

import { ReactComponent as MainLogo } from "../../../../../../assets/icons/main-logo.svg";
import styles from "./index.module.scss";

export const Header = (): JSX.Element => {
	const { currentAccount } = useSelect(selectCurrentAccount);
	const { account } = currentAccount || {};
	const { avatar, name, phone } = account || {};

	return (
		<div className={styles.root}>
			<div className={styles.logo}>
				<MainLogo className={styles.logoLabel} />
			</div>

			<div className={styles.meBlock}>
				<div className={styles.namePhone}>
					<span className={styles.name}>{name}</span>

					<span className={styles.phone}>{formatPhone(phone || "")}</span>
				</div>

				<Avatar
					imgSrc={avatar}
					classNames={{
						noImgBlock: styles.avatarRound,
						noInitialsIcon: styles.avatarNoImgIcon,
					}}
				/>
			</div>
		</div>
	);
};
