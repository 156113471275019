import { useMutation } from "react-query";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/position/delete-position/DEFAULT_MN";

type DeleteProps = {
	onSuccess?: () => void;
	onError?: () => void;
};

export const usePositionDelete = ({ onSuccess, onError }: DeleteProps) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: ({ id }: { id: string }) => api.positions.delete(id),
		onSuccess,
		onError,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
