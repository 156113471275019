import { ReactNode } from "react";
import cn from "classnames";

import { selectAccounts, selectCurrentAccount, useSelect } from "../../../../service/redux/selectors";
import { AlertRedLine } from "./components";
import { Sidebar } from "./Sidebar";
import { ScrollBtn } from "./ScrollBtn";
import { useScroll } from "./helpers";

import styles from "./index.module.scss";
import { AlertPremium } from "./components/AlertPremium/AlertPremium";
import { BottomBar } from "./bottom-bar/BottomBar";

type Props = {
	children: ReactNode;
	fullSize?: boolean;
	services?: boolean
	background?: "white" | "blue";
	hat?: {
		element: ReactNode;
		elementHeight: number;
	};
	rightColumn?: ReactNode;
	withScrollBtn?: boolean;
	isMobile?: boolean
	withOutLabel?: boolean
	isContact?: boolean
	isTelegram?: boolean
};

export const SidebarLayout = ({
	children,
	fullSize,
	services,
	background = "blue",
	hat,
	rightColumn,
	isMobile,
	withScrollBtn = false,
	withOutLabel,
	isContact,
	isTelegram
}: Props): JSX.Element => {
	const { isAsEmployee } = useSelect(selectAccounts);
	const { showScrollBtn, scrollTop, scrollWrapper } = useScroll({
		withScrollBtn,
	});
	const {currentAccount} = useSelect(selectCurrentAccount);

	return (
		<div className={styles.wrapper}>
			<div className={styles.root}>
				<div
					className={cn(styles.container, styles[`background__${background}`], {
						[styles.containerFullSize]: !fullSize,
						[styles.containerServices]: services,
						[styles.isMobile]: isMobile,
					})}
				>
					{!withOutLabel && <>
						{isAsEmployee && <AlertRedLine/>}
						{currentAccount?.employerSubscribtionFinished &&
							<AlertRedLine employerSubscribtionFinished={currentAccount?.employerSubscribtionFinished}/>
						}
						{currentAccount?.isPremium === false && currentAccount.isOfficeSelected && <AlertPremium/>}
					</>}

					{hat && (
						<>
							<div
								className={cn(styles.header, {
									[styles.headerWithRedLine]: isAsEmployee || currentAccount?.employerSubscribtionFinished,
									[styles.headerPremiumAlert]: currentAccount?.isPremium === false && currentAccount.isOfficeSelected,
								})}
							>
								{hat.element}
							</div>
							<div style={{ height: hat.elementHeight }} />
						</>
					)}
					<div ref={scrollWrapper} className={cn({[styles.alert]: currentAccount?.isPremium === false && currentAccount.isOfficeSelected})}>{children}</div>
					{withScrollBtn && !isMobile && <ScrollBtn onClick={scrollTop} showScrollBtn={showScrollBtn} />}
				</div>
				{!isContact && <>
					{isMobile || isTelegram ? <BottomBar/> : <Sidebar/>}
				</>}
			</div>
			{rightColumn}
		</div>
	);
};
