import { ApplicationInfo, SkeletonApplicationPage } from "./components";
import { useLoadApplication } from "./helpers";
import { ApplicationCardResponse, KindType } from "../../../../types";
import styles from "./index.module.scss";
import { selectDevice, useSelect } from "service/redux/selectors";
import classNames from "classnames";
import {
  StickyFooterApplication
} from "./components/ApplicationInfo/component/Info/components/Body/components/BodyInfo/components/sticky-footer/StickyFooterApplication";

type Props = {
  applicationId: string;
  onTab?: (tab: number) => void;
  refetch?: () => void;
  onFirstLoad?: () => void;
  inDrawerInDrawer?: boolean;
  kind?: KindType
};

export const ApplicationCard = (
  {
    applicationId,
    onTab,
    refetch,
    onFirstLoad,
    inDrawerInDrawer,
    kind,
  }: Props): JSX.Element => {
  const {isMobile} = useSelect(selectDevice)
  const {
    application,
    isLoadingApplicationCard,
    refetch: refetchApplication,
  } = useLoadApplication({
    applicationId,
    onFirstLoad,
    kind,
  });

  const refetchAll = () => {
    if (refetch) {
      refetch();
    }
    refetchApplication();
  };

  return (
    <div className={classNames(styles.root, inDrawerInDrawer ? styles.inDrawer : "")}>
      {isLoadingApplicationCard
        ? <SkeletonApplicationPage/>
        : <>
          {application &&
            <>
              <ApplicationInfo
                application={application as ApplicationCardResponse}
                applicationId={applicationId}
                refetch={refetchAll}
                onTab={onTab}
                kind={kind}
                isMobile={isMobile}
              />
              <StickyFooterApplication
                applicationId={applicationId}
                kind={kind}
                whoIsClient={application?.type.whoIsClient}
                refetch={refetchApplication}
                applicationPublicLink={application?.publicPageLink}
                isArchive={application?.isArchived}
                isMobile={isMobile}
                status={application.status}
                guaranteePaymentStatus={application.guaranteePaymentStatus}
                inDrawerInDrawer={inDrawerInDrawer}
              />
            </>
          }
        </>
      }
    </div>
  );
};

