import { useDispatch } from "react-redux";
import { changeCurrentTabByLabel, changeCurrentTabInDrawerByLabel } from "../../../../redux/modules/drawer/drawer.action";

type Props = {
  atDrawerInDrawer?: boolean
}

type ChangeTab = {
  title: string
}

export const useChangeTab = (
  {
    atDrawerInDrawer
  }: Props) => {
  const dispatch = useDispatch()

  const changeTab = (
    {
      title
    }: ChangeTab) => {

    if (atDrawerInDrawer) {
      dispatch(changeCurrentTabInDrawerByLabel({title}))
    } else {
      dispatch(changeCurrentTabByLabel({title}))
    }
  }

  return {
    changeTab
  }
}