import { useEffect, useState, useMemo } from "react";
import { useQuery } from "react-query";
import { TrafficFilter } from "../../../../types";

import { api } from "../../index";
import { DEFAULT_TRAFFIC_FILTERS } from "../../helpers";

const DEFAULT_QN = "sdelka/traffic/load-traffic/DEFAULT_QN";

type UseLoadTraffic = {
	defaultTake?: number;
	enabled?: boolean;
	onSuccess?: () => void;
	defaultFilter?: TrafficFilter;
};

export const useLoadTraffic = ({
	defaultTake = 30,
	enabled,
	onSuccess,
	defaultFilter = DEFAULT_TRAFFIC_FILTERS,
}: UseLoadTraffic) => {
	const [filter, setFilter] = useState(defaultFilter);
	const [page, setPage] = useState(1);
	const [take, setTake] = useState(defaultTake);
	const skip = useMemo(() => (page - 1) * take, [page]);

	const loadTraffic = () => api.traffic.list({ take, skip, search: filter });

	const { data, isLoading, isFetching, refetch, isError } = useQuery(
		[DEFAULT_QN, take, page, filter],
		loadTraffic,
		{
			enabled,
			onSuccess,
		}
	);

	useEffect(() => {
		refetch();
	}, [filter]);

	return {
		list: data?.data.data || [],
		total: data?.data?.total || 0,
		isLoading,
		isFetching,
		isError,
		refetch,
		setFilter,
		filter,
		take,
		setTake,
		page,
		setPage,
	};
};
