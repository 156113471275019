import { useState } from "react";
import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { formatDate } from "@sdelka_crm/sdelka-crm-component-library";

import { Calendar, Time } from "../../../../UI";
import { TasksList } from "./TasksList";

import styles from "./index.module.scss";

type Props = {
	form: {
		control: Control<any>;
		calendarName: string;
		timeName: string;
		register: UseFormRegister<any>;
		setValue: UseFormSetValue<any>;
		getValues: UseFormGetValues<any>;
	};
};

export const NewDate = ({ form }: Props): JSX.Element => {
	const [selectedDate, setSelectedDate] = useState(new Date());

	const handleDateChange = (date: Date | Date[]) => setSelectedDate(date as Date);

	return (
		<div className={styles.root}>
			<div className={styles.dateAndTime}>
				<Calendar
					onDateChange={handleDateChange}
					form={{
						name: form.calendarName,
						register: form.register,
						setValue: form.setValue,
						getValues: form.getValues,
					}}
				/>

				<Time
					classNames={{
						root: styles.time,
					}}
					register={form.register}
					name={form.timeName}
					setValue={form.setValue}
				/>
			</div>

			<TasksList title={`Задачи на ${formatDate(selectedDate)}`} selectedDate={selectedDate} />
		</div>
	);
};
