import { useHistory } from "react-router";
import { useIsTelegram } from "../../../../../../../utils/telegram/useIsTelegram";

export const useOpenRealEstate = () => {
	const { location } = useHistory();
	const {executeUrl} = useIsTelegram()

	const handleOpenClientEstate = (applicationId: string) => {
		const encodedBackPath = encodeURIComponent(`${location.pathname}${location.search}`);

		executeUrl(
			`real-estate/application/${applicationId}?mode=view&backPath=${encodedBackPath}`
		);
	};

	return {
		handleOpenClientEstate,
	};
};
