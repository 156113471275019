import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { differenceInMinutes } from "date-fns";
import cn from "classnames";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { ButtonClose, GroupedSelect, Select } from "../../../../UI";
import { useCompanyConfirmAccession, useCompanyUpdateAccession } from "../../../../../service/api/mutations";
import { formatPhone } from "../../../../../utils/formatters";
import { requestDateCalculator } from "../../../../../utils/date";
import { getStatus } from "../../helpers/getStatus";
import { CompanyAccession, CompanyUpdateAccession, IMultiSelect, ISelect } from "../../../../../types";

import { ReactComponent as Clock } from "../../../../../assets/icons/clock.svg";
import { ReactComponent as Fire } from "../../../../../assets/icons/fire.svg";
import { ReactComponent as Waiting } from "../../../../../assets/icons/waiting.svg";
import styles from "./index.module.scss";

type ICompanyRequestForm = {
	departmentId: string;
	positionId: string;
};

type Props = {
	company: CompanyAccession;
	positionOptions: ISelect[];
	officeOptions: IMultiSelect[];
	refetch?: () => void;
};

// Company Request which user can approve or decline
export const CompanyRequest = ({ company, positionOptions, officeOptions, refetch }: Props): JSX.Element => {
	const difference = differenceInMinutes(new Date(), new Date(company.createdAt));
	const [status, setStatus] = useState(getStatus(difference));

	const schema = yup.object().shape({
		departmentId: yup.string().required(" "),
		positionId: yup.string().required(" "),
	});

	const {
		handleSubmit,
		clearErrors,
		formState: { errors },
		register,
		setValue,
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: { departmentId: "", positionId: "" },
	});

	const [positionIdValue, departmentIdValue] = watch(["positionId", "departmentId"]);
	const shouldDisableButton = !positionIdValue || !departmentIdValue;

	const confirmAccession = useCompanyConfirmAccession({
		onSuccess: () => {
			if (refetch) {
				refetch();
			}
			setStatus("accepted");
		},
	});

	const updateAccession = useCompanyUpdateAccession({});

	const onSubmit = (data: ICompanyRequestForm) => {
		const payload = {
			id: company.id,
			data,
		};

		confirmAccession.mutate(payload);
	};

	const declineRequest = () => {
		const payload: CompanyUpdateAccession = {
			status: "decline",
		};

		updateAccession
			.mutateAsync({ id: company.id, data: payload })
			.then(() => setStatus("deleted"))
			.catch((error) => console.log(error));
	};

	const revertDecline = () => {
		const payload: CompanyUpdateAccession = {
			status: null,
		};

		updateAccession
			.mutateAsync({ id: company.id, data: payload })
			.then(() => setStatus(getStatus(difference)))
			.catch((error) => console.log(error));
	};

	const onChangeValue = (field: "positionId" | "departmentId") => clearErrors(field);

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={cn(styles.root, styles[`status__${status}`])}>
			<div className={cn(styles.requestBlock, styles.userBlock)}>
				<span className={styles.userName}>
					{company.account?.lastName} {company.account?.name}
				</span>

				<span className={styles.userPhone}>тел {formatPhone(company.account?.phone)}</span>
			</div>

			{status !== "deleted" && (
				<>
					<div className={styles.requestBlock}>
						<Select
							name="positionId"
							options={positionOptions}
							placeholder="Укажите должность"
							register={register}
							classNames={{
								root: styles.requestSelect,
								error: styles.requestSelectError,
							}}
							setValue={setValue}
							error={errors?.positionId?.message?.toString()}
							onChange={() => onChangeValue("positionId")}
						/>
					</div>

					{officeOptions.length ? (
						<div className={styles.requestBlock}>
							<GroupedSelect
								name="departmentId"
								isSearchable
								placeholder="Выберите Офис/Отдел"
								classNames={{
									root: styles.requestSelect,
									error: styles.requestGroupSelectError,
								}}
								groups={officeOptions}
								register={register}
								error={errors?.departmentId?.message?.toString()}
								setValue={setValue}
								onChange={() => onChangeValue("departmentId")}
							/>
						</div>
					) : null}

					<div className={styles.requestBlock}>
						{status === "default" && <Clock className={styles.requestIcon} />}
						{status === "warning" && <Waiting className={styles.requestIcon} />}
						{status === "hot" && <Fire className={styles.requestIcon} />}

						<span className={styles.days}>{requestDateCalculator(new Date(company.createdAt))}</span>

						<Button disabled={shouldDisableButton} color="green" type="submit">
							Принять
						</Button>

						<ButtonClose classNames={{ root: styles.decline }} onClick={declineRequest} />
					</div>
				</>
			)}

			{status === "deleted" && (
				<div className={styles.requestBlock}>
					<span className={styles.declined}>Вы отклонили заявку на вступление</span>

					<button type="button" className={styles.revert} onClick={() => revertDecline()}>
						Восстановить
					</button>
				</div>
			)}
		</form>
	);
};
