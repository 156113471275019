import cn from "classnames";
import styles from "./index.module.scss";
import { Plug } from "../plug/Plug";
import { Contact, LeadStatusEnum } from "../../../../../../../types";
import { ContactView } from "./ContactView";
import { AddContactLabel } from "./AddLabel";
import { useQueryParams } from "../../../../../../../utils/hooks";
import { useIsTelegram } from "../../../../../../../utils/telegram/useIsTelegram";

type Props = {
  contact?: Contact | null
  status: LeadStatusEnum
  leadId: string
  refetch: () => void
  isMobile?: boolean
}

export const LeadContact = (
  {
    contact,
    status,
    leadId,
    refetch,
    isMobile
  }: Props): JSX.Element => {
  const {changeQueryParams, removeQueryParam} = useQueryParams<{
    contactId: string;
    phone: string;
  }>([{name: "contactId"}]);
  const {executeUrl} = useIsTelegram()
  const onClickContact = (contactId: string) => {
    removeQueryParam("id")
    changeQueryParams([{name: "contactId", newValue: contactId}])
    executeUrl(`/leadsanddeals?tab=contacts&contactId=${contactId}`);
  }

  const titleContact = "Контакт"
  const plugText = contact ? "" : "Нет контакта"

  const freezed = status === LeadStatusEnum.deferred
  const canSeeContact = status !== LeadStatusEnum.free && status !== LeadStatusEnum.new
  return (
    <div className={styles.root}>
      <div className={cn(styles.row, {[ styles.header ]: contact !== undefined})}>
        <div className={styles.title}>
          {titleContact}
        </div>
        {canSeeContact &&
          <AddContactLabel
            leadId={leadId}
            refetch={refetch}
            contact={contact}
            freezed={freezed}
            isMobile={isMobile}
          />
        }
      </div>
      {canSeeContact
        ? <ContactView
          contact={contact}
          onClickContact={onClickContact}
          plugText={plugText}
        />
        : <Plug plugText="Возьмите лид в работу, чтобы просмотреть"/>
      }
    </div>
  )
}
