import { Loader } from "@sdelka_crm/sdelka-crm-component-library";
import cn from "classnames";

import { IndividualCheckbox } from "../../../../../../../index";
import { arrValuesToMap } from "../../../../../../../../../utils/transformation";
import { DefaultSelectProps, MultiLayerSelectOption } from "../../../../../../../../../types";

import { ReactComponent as ArrowIconDefault } from "../../../../../../../../../assets/icons/arrow-down.svg";
import selectStyles from "../../../../../components/selectStyles.module.scss";
import styles from "./index.module.scss";

type FOption = MultiLayerSelectOption & {
  hidden?: boolean;
  hideChildren?: boolean;
};

const layerStyleMap = ["first", "second", "third"];

type Props = {
  filteredOption: FOption[];
  isLoading?: boolean;
  searchVal: string;
  selectOne?: boolean;
  selected: string[];
  selectAllLowerLevel: (value: string, layer: 0 | 1 | 2) => MultiLayerSelectOption[];
  setHiddenOptions: ({value, layer}: MultiLayerSelectOption) => void;
  selectItem: ({value, layer}: MultiLayerSelectOption) => void;
  showLastCheckbox?: boolean
} & Pick<DefaultSelectProps, "classNames">;

export const List = (
  {
    filteredOption,
    isLoading,
    searchVal,
    classNames,
    selectOne,
    selectAllLowerLevel,
    setHiddenOptions,
    selectItem,
    selected,
    showLastCheckbox
  }: Props): JSX.Element => {
  const checkForParents = (item: FOption) => {
    const parentsMap = arrValuesToMap(item.parents);

    return filteredOption.filter((option) => parentsMap[ option.value ]).length === item.parents.length;
  };

  const showArrow = (item: FOption) =>
    item.layer !== 2 && !item.hidden && selectAllLowerLevel(item.value, item.layer).length;

  const selectedMap = arrValuesToMap(selected);

  const checkSelectedItem = (item: MultiLayerSelectOption) => {
    const {layer} = item;
    if (layer === 0 || layer === 1) {
      setHiddenOptions(item);
    } else {
      selectItem(item);
    }
  };

  return (
    <>
      <Loader style={{display: !isLoading ? "none" : "block"}} height={250}/>

      <div className={selectStyles.optionsOverflow}>
        <div
          style={{display: !isLoading ? "block" : "none"}}
          className={cn(selectStyles.customSelectList, classNames?.selectListWrapper)}
        >
          {filteredOption.map((item, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`${item.value}-${index}`}
              style={{display: item.hidden ? "none" : "flex"}}
              className={cn(
                selectStyles.customOptionWrapper,
                styles.customOptionWrapper,
                styles[ layerStyleMap[ item.layer ] ],
                {
                  [ styles[ `${layerStyleMap[ item.layer ]}Offset` ] ]: !searchVal || checkForParents(item),
                }
              )}
            >
              <div
                className={cn(
                  selectStyles.customOptionNotSelected,
                  styles.rewriteNotSelected,
                  classNames?.optionNotSelected,
                  {
                    [ selectStyles.customOptionSelected ]: selectedMap[ item.value.toString() ],
                    [ styles.customOptionSelected ]: item.layer === 2 && selectedMap[ item.value.toString() ],
                  }
                )}
              >
                {!selectOne && (
                  <span onClick={() => selectItem(item)} className={cn({[ styles.withCheck ]: item.layer === 2})}>
										<IndividualCheckbox
                      readOnly
                      showBg={showLastCheckbox || item.layer !== 2}
                      className={selectStyles.optionCheckbox}
                      checked={Boolean(selectedMap[ item.value.toString() ])}
                    />
									</span>
                )}
                <span
                  onClick={() => (showLastCheckbox ? selectItem(item) : checkSelectedItem(item))}>{item.label.children}</span>
              </div>
              {showArrow(item) ? (
                <div
                  onClick={() => setHiddenOptions(item)}
                  className={cn(styles.arrowUp, {
                    [ styles.arrowDown ]: item.hideChildren,
                  })}
                >
                  <ArrowIconDefault/>
                </div>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
