import { useDispatch } from "react-redux";
import { openDrawer, openDrawerInDrawer } from "../../../../redux/modules/drawer/drawer.action";
import { useQueryParams } from "../../../../../utils/hooks";
import { FixedNavVerticalMenu } from "../../../../../components/UI";
import { selectDevice, useSelect } from "../../../../redux/selectors";
import { SelectedOfficeCard } from "../../../../../components/pages/selected-office-card";

type Return = {
  openOfficeDrawer: (officeId: string) => void;
};

type Props = {
  atDrawerInDrawer?: boolean
  disableClose?: boolean
  noRemoveQuery?: boolean
};

type UseOfficeCard = (props: Props) => Return;

export const useOpenSelectedOfficeCard: UseOfficeCard = ({atDrawerInDrawer, disableClose, noRemoveQuery}) => {
  const dispatch = useDispatch();
  const {isMobile} = useSelect(selectDevice)

  const {removeQueryParam} = useQueryParams<{
    officeId: string;
  }>([{name: "officeId"}]);

  const officeNav = {
    label: "Офис",
    height: 120,
    title: "office"
  };

  const yNavigation: Omit<FixedNavVerticalMenu, "id" | "changeTab">[] = [officeNav]

  const openOfficeDrawer = (officeId: string) => {
    if (atDrawerInDrawer) {
      dispatch(openDrawerInDrawer(
        {
          children: <SelectedOfficeCard officeId={officeId}/>,
          width: isMobile ? 500 : 800,
          yNavigation: {
            navigation: yNavigation,
            initialTab: 0
          },
        }))
    } else {
      dispatch(
        openDrawer({
          children: <SelectedOfficeCard officeId={officeId}/>,
          width: isMobile ? 500 : 800,
          yNavigation: {
            navigation: yNavigation,
            initialTab: 0,
          },
          disableClose,
          noRemoveQuery,
        })
      );
    }
  };

  return {
    openOfficeDrawer,
  };
};
