import { Call, UserAccountData } from "../../../../../types";

import { CallHistory } from "../CallHistory";
import { NotCalls } from "../NotCalls";
import { prepareShortUserOutput } from "../../../../../utils/transformation";

import styles from "./index.module.scss";

type Props = {
	calls: Call[];
	user: UserAccountData;
	id: string;
};

export const CallsWithUser = ({ calls, user, id }: Props): JSX.Element => {
	const displayName = prepareShortUserOutput(user);

	return (
		<div id={id}>
			<div className={styles.title}>
				История звонков с &ensp;
				<span className={styles.user}>{displayName}</span>
			</div>

			<div className={styles.calls}>
				{calls.length <= 0 && <NotCalls />}

				{calls.length > 0 && calls.map((call) => <CallHistory call={call} user={user} key={call.id} />)}
			</div>
		</div>
	);
};
