import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { EnterCodeForm } from "../../components";
import {
	setStep,
	setVerificationCode,
} from "../../../../../service/redux/modules/reset-pass-steps/reset-pass-steps.action";
import { useSelector } from "../../../../../service/redux/useSelector";
import { usePasswordCodeConfirm } from "../../../../../service/api/mutations";
import { useTimer } from "../../../../../utils/hooks";
import { digitOnly } from "../../../../../utils/string";

import styles from "./index.module.scss";

export const EnterCodeResetPasswordPage = (): JSX.Element => {
	const { token } = useSelector((state) => state.resetPassSteps);
	const dispatch = useDispatch();
	const [timer, handleTimer] = useTimer(30);

	const { mutate } = usePasswordCodeConfirm({
		onSuccess: () => {
			dispatch(setStep("reset-password"));
		},
	});

	useEffect(() => {
		handleTimer();
	}, []);

	const onSubmit = (values: { code: string }) => {
		if (token) {
			const payload = {
				code: digitOnly(values.code),
				token,
			};

			mutate(payload);
		}
	};

	const resetCode = () => {
		handleTimer();
	};

	return (
		<div className={styles.root}>
			<h1>Восстановление пароля</h1>

			<EnterCodeForm
				onSubmit={onSubmit}
				resetCode={resetCode}
				setCode={(value: string) => dispatch(setVerificationCode(value))}
				setBackStep={() => dispatch(setStep("phone-verification"))}
				label="Код для восстановления пароля"
				timer={timer}
			/>
		</div>
	);
};
