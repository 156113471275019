import { HTMLAttributes, ReactNode } from "react";

import { RealEstatePhoto } from "./real-estate";

export interface GalleryGridItemProps extends HTMLAttributes<HTMLButtonElement> {
	active?: boolean;
	clone?: boolean;
	insertPosition?: ImgPosition;
	id: string;
	img: {
		src?: string;
		width: number;
		height: number;
	};
	index?: number;
	onRemove?: (id: string) => void;
	onBar?: (val: boolean) => void;
	onPhotoPreviewClick?: (src?: string) => void;
	withRotationButtons?: boolean;
	// after rotation we receive a new photo
	// we need this method be provided to replace old photo with rotated
	changePhotoItself?: (newPhoto: RealEstatePhoto) => void;
	options?: {
		withoutRemove?: boolean;
		withoutLabels?: boolean;
		disableSort?: boolean;
	};
	isView?: boolean
}

export type WhoIsClient = "seller" | "buyer";

export type CommonListItem<T> = {
	label: string;
	id?: string | number;
	value?: T;
};

export type KnownListItem<T> = Required<CommonListItem<T>>;

export type CommonListItemWithChild = CommonListItem<undefined> & {
	children?: string | ReactNode;
};

export type CommonListType<T> = {
	id?: string | number;
	items: Array<T>;
};

export type ISelect = {
	value: string;
	label: string;
};

export type IMultiSelect = {
	label: string;
	options: ISelect[];
	group?: string;
};

// Common Inputs props
export type AnyInputProps = {
	name: string;
	label?: string;
	placeholder?: string;
	preNode?: ReactNode;
	error?: string;
	disabled?: boolean;
	loading?: boolean;
	className?: string;
	required?: boolean;
	readOnly?: boolean;
	mask?: any;
	pipe?: any;
	value?: any;
};

export type MultiLayerSelectOption = {
	value: string;
	label: { name: string; children: ReactNode };
	parents: string[];
	layer: 0 | 1 | 2;
};

export enum ImgPosition {
	Before = -1,
	After = 1,
}

export enum PaymentMethods {
	PACKAGE = "Package",
	SINGLE = "Single",
}
export enum KindType {
	APPLICATION = "application",
	PREAPPLICATION = "preApplication",
	DEAL = "deal",
	SUCCESSDEAL = "successDeal",
}
export const PaymentMethodsTranslation = {
	[PaymentMethods.PACKAGE]: "Пакетное размещение",
	[PaymentMethods.SINGLE]: "Разовое размещение",
};

export type PageInfo = {
	count: number;
	total: number;
	page: number;
	pageCount: number;
};

export type DateBorders = {
	startDate: Date;
	endDate: Date;
	duration: number;
};

export type PayMethod = {
	payMethod: typeof PaymentMethods;
};

export type SelectWithPreviewOption = {
	group: string;
	values: {
		label: string;
		value: string;
		src: string;
	}[];
};

export type MinMax = {
	min: number;
	max: number;
};

export type LocationType = {
	type?: string;
	coordinates: [number, number];
};

export type SocialLink = {
	link: string;
	type: string;
};

export type AddressValue = {
	label: string;
	value: string;
	location?: LocationType;
};

export type MedianPriceType = {
	direction?: "row" | "column";
	medianPrice: number;
	price: number;
	median: MinMax;
	border: MinMax;
	classNames?: {
		wrapper?: string;
		main?: string;
		overflow?: string;
	};
};

export type Gradient = {
	start: string;
	end: string;
};

export type Color = string | Gradient;
