import { ReactNode } from "react";
import { H3 } from "@sdelka_crm/sdelka-crm-component-library";
import cn from "classnames";

import styles from "./index.module.scss";

type Props = {
	title?: string;
	children: ReactNode;
	id?: string;
	margin?: "mediumBottom" | "bigBottom" | "mediumBottomAndRight" | "bigBottomAndRight";
};

// Component-wrapper for object's content
export const ContentBlock = ({ title = "", children, margin = "mediumBottom", id }: Props): JSX.Element => (
	<div id={id} className={cn(styles.root, styles[margin])}>
		{title && <H3 classNames={{ root: styles.title }}>{title}</H3>}

		<div className={styles.wrapper}>{children}</div>
	</div>
);
