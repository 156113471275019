import { Client } from "../../Client";
import { Office, SelectedOffice } from "../../../../types";

export const OfficesService = {
	list: () => Client.get<Office[]>("/company/office"),
	delete: (id: string) => Client.delete(`/company/office/${id}`),
	create: (data: Partial<Office>) => Client.post<Office>("/company/office", data),
	update: (id: string, data: Partial<Office>) => Client.put<Office>(`/company/office/${id}`, data),
	get: (id: string) => Client.get<Office>(`/company/office/${id}`),
	updatePhoto: (officeId: string, data: { avatar: string }) =>
		Client.put<Office>(`/office/photo/${officeId}`, data),

	selectOfficesList: (addressId: string) => Client.get<Office[]>(`/company/public?addressId=${addressId}`),
	getSelectedOffice: (id: string) => Client.get<SelectedOffice & Office>(`/company/public/${id}/forRegister`),
	joinOffice: (id: string) => Client.put(`/users/selectOffice/${id}`),
	setAddressId: (id: string) => Client.post(`/users/setCompany/${id}`)
};
