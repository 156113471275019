import { Client } from "../../Client";
import { getVideoLessonsFilters } from "../../helpers";
import {
  Conditions,
  GetVideoLessonsListPayload,
  VideoLessonsResponse
} from "../../../../types";

export const VideoLessonsService = {
  list: ({take, skip, search, tab}: GetVideoLessonsListPayload) => {
    const searchConditions: Conditions = search ? [...getVideoLessonsFilters(search)] : [];

   return  Client.post<VideoLessonsResponse>(
      `lessons?search?for=${tab}`,
      JSON.stringify({
      search: {
        filterType: "and",
        conditions: searchConditions,
      },
      take,
      skip,
    }))
  },

  listForPlug: (tab: string) => Client.post<VideoLessonsResponse>(`lessons?for=${tab}`)
}