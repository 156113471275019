import { Lead } from "../../../../types";
import { AxiosError } from "axios";
import { useQuery } from "react-query";

import { api } from "../../index";

type Props = {
	id: string;
	onSuccess?: (data) => void;
	onError?: (err: AxiosError) => void;
};

type Return = {
	lead: Lead | undefined;
	isLoading: boolean;
	isFetching: boolean;
	refetch: () => void;
	remove: () => void;
	error: AxiosError;
};

type UseReadLead = (props: Props) => Return;

export const useReadLead: UseReadLead = ({ id, onSuccess, onError }) => {
	const readLead = () => api.leads.readAndLock(id);
	const { data, isLoading, isFetching, refetch, remove, error } = useQuery(["loadLead", id], readLead, {
		enabled: !!id,
		onError,
		onSuccess,
		staleTime: 0,
		keepPreviousData: false
	});

	return {
		lead: data?.data,
		isLoading,
		isFetching,
		refetch,
		remove,
		error: error as AxiosError,
	};
};
