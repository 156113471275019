import styles from "./index.module.scss"
import { useGetSelectedOffice } from "../../../service/api/queries";
import { LayoutLoading } from "../Application/PreApplicationTemplate";
import { CardOfficeForSelect, CardProductForTable, toastError, toastSuccess } from "../../UI";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useHistory } from "react-router";
import cn from "classnames";
import Logo from "../../../assets/icons/OfficeLogo.png";

import { selectDevice, useSelect } from "../../../service/redux/selectors";
import { Plug } from "../../UI/ApplicationComponents/helpers";
import { UserListCards } from "../../UI/ApplicationComponents/UserList/components";
import { paths } from "../../../utils/paths";
import { useCloseDrawer } from "../../../service/servise";
import { TokenService } from "../../../service/api/services/inner/TokenService";
import { useSelectAddress } from "../../../service/api/mutations";
import { useEffect } from "react";

type Props = {
  officeId: string
}

export const SelectedOfficeCard = (
  {
    officeId
  }: Props) => {
  const {push, replace} = useHistory()
  const {office} = useGetSelectedOffice({
    officeId
  })
  const {isMobile} = useSelect(selectDevice)
  const {close} = useCloseDrawer({})
  const {asyncMutate, error} = useSelectAddress()

  const joinOffice = async () => {
    const token = TokenService.getCurrentToken()
    if (token) {
      const response = await asyncMutate(officeId)
      if (response) {
        replace(`/real-estate`)
        window.location.reload()
      }
    } else {
      close()
      replace(`${paths.registrationPath.default}?selectedOfficeId=${officeId}`)
    }
  }

  useEffect(() => {
    if ((error as any)?.response?.data?.error.errorCode === "RC001") {
      toastSuccess({text: "Ваша заявка на вступление в компанию на рассмотрении, ожидайте", autoClose: 3000})
    }
  }, [(error as any)?.response?.data?.error.errorCode])


  return (
    <>
      {office
        ? <div className={styles.root}>
          <div className={styles.content}>
            <img src={office.headerBanner || Logo} className={styles.logo}/>
            <div className={styles.office}>
              <CardOfficeForSelect office={office} onCardClick={() => {
              }} mini={isMobile}/>
            </div>
            <div className={styles.subtitle}>
              Администрация
            </div>
            {office.administration
              ? <div>
                <UserListCards
                  data={office.administration}
                  mini={isMobile}
                  atModal
                  withAvatar
                  withoutDots
                  atSelect
                />
              </div>
              : <Plug
                plugText={"Не указано"}
                classNames={styles.plug}
                mini={isMobile}/>
            }
            <div className={styles.subtitle}>
              Условия офиса
            </div>
            {office?.description &&
              <div className={styles.description} dangerouslySetInnerHTML={{__html: office?.description}}/>
            }
            <div className={styles.subtitle}>
              Эксклюзивные услуги
            </div>
            <div className={styles.list}>
              {office.products.map(element => (
                <CardProductForTable
                  product={element}
                  refetch={() => {
                  }}
                  type="available"
                  withOutStrap
                />
              ))}
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.footerDescription}>
              <div className={styles.footerLabel}>
                ID офиса
              </div>
              <div className={styles.footerShortId}>
                {office.shortId}
              </div>
            </div>
          </div>
          <div className={cn(styles.stickyFooter, {[ styles.withoutTariff ]: !office.tariff})}>
            {office.tariff &&
              <div className={styles.tariff}>
                {office.tariff}
              </div>
            }
            <Button
              onClick={joinOffice}
            >
              Вступить
            </Button>
          </div>
        </div>
        : <LayoutLoading/>
      }
    </>
  )
}