import { useEffect } from "react";
import {
  currencyValues,
  FormPropsEditRealEstate,
  RealEstateSchemaConfigField,
} from "../../../../../../../../../../types";

import { InfoRow, RadioButton } from "../../../../../../../../index";

import styles from "./index.module.scss";
import { NumberInput } from "../NumberInput";
import { createNumberMask } from "text-mask-addons";

type Props = {
  form: FormPropsEditRealEstate;
  field: RealEstateSchemaConfigField;
};

export const Price = ({form, field}: Props): JSX.Element => {
  const {
    dataType: {nameGroup},
  } = field;
  const {
    control,
    setValue,
    formState: {isSubmitted},
    watch,
    setError,
    clearErrors,
  } = form;

  if (!nameGroup) {
    return <></>;
  }

  const mask = createNumberMask({
    prefix: "",
    thousandsSeparatorSymbol: " ",
  })

  const [priceValue, squareValue] = watch([nameGroup[ 0 ], "saleSquare"]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const pricePerUnit = Number(String(priceValue).replaceAll(" ", "")) / Number(squareValue);

    if (pricePerUnit && priceValue && squareValue) {
      clearErrors(nameGroup[ 1 ]);
      setValue(nameGroup[ 1 ], Math.round(pricePerUnit));
    } else {
      setValue(nameGroup[ 1 ], "");

      if (isSubmitted) {
        setError(nameGroup[ 1 ], {message: "Укажите площадь и цену"});
      }
    }
  }, [priceValue, squareValue, isSubmitted]);

  
  const currencyOptions = Object.keys(currencyValues).map((currency) => ({
    label: currencyValues[ currency ].label,
    value: currencyValues[ currency ].value,
  }));

  return (
    <>
      <InfoRow
        label="Цена"
        labelFont="ubuntu"
        aspect={{label: 1, content: 1}}
        classNames={{root: styles.customInfoRow}}
      >
        <div className={styles.priceWithCurrency}>
          <div className={styles.priceInput}>
            <NumberInput
              form={form}
              field={field}
              placeholder="Не указано"
              name={nameGroup[ 0 ]}
              onlyPositive
              inputMask={mask}
            />
          </div>
          <RadioButton name={nameGroup[ 2 ]} control={control} options={currencyOptions}/>
        </div>
      </InfoRow>
      <InfoRow
        label="Цена за м2"
        aspect={{label: 1, content: 1}}
        classNames={{root: styles.customInfoRow}}
      >
        <NumberInput
          form={form}
          field={field}
          placeholder="Не указано"
          name={nameGroup[ 1 ]}
          onlyPositive
          inputMask={mask}
        />
      </InfoRow>
    </>
  );
};
