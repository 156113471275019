import { Dispatch, SetStateAction } from "react";
import { useDispatch } from "react-redux";
import { UseFormGetValues, UseFormReset } from "react-hook-form";
import { RealEstateFilter, RealEstateListTab } from "../../../../../../../types";

import { clearObject } from "../../../../../../../utils/objects";
import { ObjectListFiltersModal } from "../../ObjectListFiltersModal";
import { openDrawer } from "../../../../../../../service/redux/modules/drawer/drawer.action";
import { useLoadUsers } from "../../../../../../../service/api/queries";

type Props = {
	setFilter: Dispatch<SetStateAction<RealEstateFilter>>;
	reset: UseFormReset<any>;
	getValues: UseFormGetValues<any>;
	setObjectsFilterTypes: Dispatch<SetStateAction<string[]>>;
	tab: RealEstateListTab;
	isParser?: boolean
	isMobile?: boolean
};

type Return = {
	openFiltersDrawer: () => void;
};

type UseDrawFilter = (props: Props) => Return;

export const useDrawFilter: UseDrawFilter = (
	{
		setFilter,
		reset,
		getValues,
		setObjectsFilterTypes,
		tab ,
		isParser,
		isMobile,
	}) => {
	const dispatch = useDispatch();

	const { users } = useLoadUsers({});

	const prepareSubmitForm = (values: RealEstateFilter) => {
		const newFilter = clearObject(values);

		setFilter({ ...newFilter });
		setObjectsFilterTypes(newFilter?.category || []);
		reset(newFilter as any);
	};

	const openFiltersDrawer = () => {
		dispatch(
			openDrawer({
				children: (
					<ObjectListFiltersModal
						filter={{ ...getValues() }}
						prepareSubmitForm={prepareSubmitForm}
						users={users}
						tab={tab}
						isParser={isParser}
						isMobile={isMobile}
					/>
				),
				width: isMobile ? 500 : 684,
			})
		);
	};

	return {
		openFiltersDrawer,
	};
};
