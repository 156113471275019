import { Control } from "react-hook-form";

import { BusinessProcess, SellBusinessProcessEnum } from "../../../../../../types";
import { InfoRow, RadioButton } from "../../../../index";
import { objectFilters } from "../../../../../../utils/clientObjects";

type Props = {
	control: Control<any>;
	onChangeTypeValue?: (value: BusinessProcess) => void;
	label: string;
	disabled?: boolean;
};

export const DealType = ({ control, onChangeTypeValue, label, disabled }: Props): JSX.Element => {
	const handleOnChange = (value: string | number) => {
		if (
			onChangeTypeValue &&
			(value === SellBusinessProcessEnum.rentOut || value === SellBusinessProcessEnum.sale)
		) {
			onChangeTypeValue(value);
		}
	};

	const options = objectFilters.sellBusinessProcess.map(({ value, label: optionLabel }) => ({
		value,
		label: optionLabel,
		disabled,
	}));

	return (
		<InfoRow aspect={{ label: 8, content: 7.3 }} label={label}>
			<RadioButton
				name="dealType"
				options={options}
				control={control}
				background="blue"
				textColor="blue"
				handleOnChange={handleOnChange}
			/>
		</InfoRow>
	);
};
