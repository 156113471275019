import { memo } from "react";
import styles from "../index.module.scss"
import { UserPreview } from "../../../../../types";
import cn from "classnames";
import { Avatar } from "../../../Avatar";

type Props = {
  responsible?: UserPreview
  classNames?: string
}

export const CardApplicationResponsible = memo(({responsible, classNames}: Props): JSX.Element => (
  <div className={cn(styles.responsible, classNames)}>
    {responsible?.name && (
      <>
        {responsible.avatar
          ? <img className={styles.responsibleLogo} src={responsible.avatar} alt="responsibleIcon.svg"/>
          : <Avatar
            imgSrc={responsible?.avatar}
            name={responsible.name}
            lastName={responsible.lastName}
            color="primary"
            mini
          />
        }
        <span>{`${responsible.lastName} ${responsible.name}`}</span>
      </>
    )}
  </div>
))