import { AxiosResponse } from "axios";

import {
	useMarkSuccess,
	useMarkAgent,
	useMarkArchive,
	useMarkNotAvailable,
	useMarkSkip,
} from "../../../../../../../../../../../service/api/mutations";
import { CallCenterResponseData } from "../../../../../../../../../../../types";

type Args = {
	onSuccess?: (data: AxiosResponse<CallCenterResponseData>) => void;
	onError?: (error: unknown) => void;
};

export const useCallCenterActions = (callbacks: Args = {}) => {
	const actions = {
		markSuccess: useMarkSuccess(callbacks),
		markAgent: useMarkAgent(callbacks),
		markArchive: useMarkArchive(callbacks),
		markNotAvailable: useMarkNotAvailable(callbacks),
		markSkip: useMarkSkip(callbacks),
	};

	const isAnyActionLoading = Object.values(actions).some((mutation) => mutation.isLoading);

	return {
		actions,
		isAnyActionLoading,
	};
};
