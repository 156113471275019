import "./index.scss";

export const createIconsClusterTemplate = (content: string) =>
  `<div class="cluster-icon">
 
  </div>`;

export const iconTemplate = `<div class="object-icon">
    <span class="object-icon__text">
        {{ properties.iconContent }}
    </span>
   </div>`;

export const iconOrangeTemplate = `<div class="object-icon">
    <span class="object-icon__text object-icon__text-orange">
        {{ properties.iconContent }}
    </span>
   </div>`;

export const markTemplate = '<div class="mark-icon" />';

export const convertCoords = (bounds: number[][]) => (coords: number[]) =>
  [
    bounds[ 0 ][ 0 ] + (1 - coords[ 1 ]) * (bounds[ 1 ][ 0 ] - bounds[ 0 ][ 0 ]),
    bounds[ 0 ][ 1 ] + coords[ 0 ] * (bounds[ 1 ][ 1 ] - bounds[ 0 ][ 1 ]),
  ];

export const polyStyle = {
  strokeWidth: 3,
  strokeColor: "#336BEB",
  fillColor: "#336BEB",
  fillOpacity: 0.2,
};

export const clusterShape = {
  type: "Circle",
  coordinates: [0, 0],
  radius: 16,
};

export const formatter = new Intl.NumberFormat("ru");

export const options = [
  {value: "price", label: "Цена"},
  {value: "pricePerUnit", label: "Цена за кв. м"},
];
