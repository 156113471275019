import { MouseEvent, useState, useEffect } from "react";

export const useGrabScrollX = () => {
	const [isGrabbing, setGrabbing] = useState(false);
	const [curPageX, setCurPageX] = useState(0);

	const scroll = (event: MouseEvent<HTMLDivElement>) => {
		(event.target as HTMLDivElement).scrollBy(curPageX - event.pageX, 0);
		setCurPageX(event.pageX);
	};

	const galleryMouseDown = (event: MouseEvent<HTMLDivElement>) => {
		setGrabbing(true);
		scroll(event);
	};

	const galleryMouseUp = (event: MouseEvent<HTMLDivElement>) => {
		setGrabbing(false);
		setCurPageX(event.pageX);
	};

	const galleryMouseMove = (event: MouseEvent<HTMLDivElement>) => {
		if (!isGrabbing) return;
		scroll(event);
	};

	const handleOuterMouseUp = () => setGrabbing(false);

	useEffect(() => {
		window.addEventListener("mouseup", handleOuterMouseUp);

		return () => window.removeEventListener("mouseup", handleOuterMouseUp);
	}, []);

	return {
		galleryMouseDown,
		galleryMouseUp,
		galleryMouseMove,
		isGrabbing,
	};
};
