import { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Button, Loader, Underlay } from "@sdelka_crm/sdelka-crm-component-library";

import { Contact, RealEstate } from "../../../../../types";
import { IndividualCheckbox, usePinObjectModal } from "../../../../UI";
import { CardCreationObject, ContactSelect, DateOfView } from "./components";
import { closeModal } from "../../../../../service/redux/modules/modal/modal.action";
import { useStartShowOrView } from "../../../../../service/api/mutations";

import styles from "./index.module.scss";

type Props = {
	objectData: RealEstate;
	isViews: boolean;
	applicationId: string;
	onStartShow: (id: string) => void;
};

const getTitlesAndLabels = (isViews: boolean) => {
	if (isViews) {
		return {
			contactTitle: "Продавец",
			contactType: "seller",
			contactButtonTitle: "Указать контакт продавца",
			// eslint-disable-next-line sonarjs/no-duplicate-string
			agentButtonTitle: "Указать контакт его агента",
			checkboxContactLabel: "Контакт неизвестен",
			checkboxAgentLabel: "Продавец без агента",
			mainButtonTitle: "Назначить просмотр",
		};
	}

	return {
		contactTitle: "Покупатель",
		contactType: "buyer",
		contactButtonTitle: "Укажите контакт покупателя",
		agentButtonTitle: "Указать контакт его агента",
		checkboxContactLabel: undefined,
		checkboxAgentLabel: "Покупатель без агента",
		mainButtonTitle: "Назначить показ",
	};
};

export const CardCreation = ({ objectData, isViews, applicationId, onStartShow }: Props): JSX.Element => {
	const dispatch = useDispatch();

	const [selectedObject, setSelectedObject] = useState<RealEstate | undefined>(objectData);
	const [curContact, setContact] = useState<Contact | undefined>(undefined);
	const [agentContact, setAgentContact] = useState<Contact | undefined>(undefined);

	const [sellerWithoutAgent, setSellerWithoutAgent] = useState(false);
	const [sellerWithoutContact, setSellerWithoutContact] = useState(false);

	const { openPinObjectModal } = usePinObjectModal({
		title: "Прикрепить объект",
		onChange: (curObject) => setSelectedObject(curObject),
		onClose: () => setSelectedObject(undefined),
	});

	const { watch, ...form } = useForm({
		defaultValues: { calendar: "", time: "" },
	});
	const watchFields = watch();

	const { mutate, isLoading } = useStartShowOrView({
		onSuccess: ({ data }) => onStartShow(data.id),
	});

	const start = () => {
		const { getValues } = form;
		const { calendar, time } = getValues();

		const date = new Date(calendar);
		const [hours, minutes] = time.split(":").map((item) => +item);

		date.setHours(hours);
		date.setMinutes(minutes);

		const [contactId, agentContactId] = isViews
			? ["buyerContactId", "buyerAgentContactId"]
			: ["sellerContactId", "sellerAgentContactId"];

		mutate({
			applicationId,
			clientObjectApplicationId: (selectedObject as RealEstate).applicationId,
			clientObjectId: (selectedObject as RealEstate).id,
			[contactId]: sellerWithoutContact ? undefined : (curContact as Contact).id,
			[agentContactId]: sellerWithoutAgent ? undefined : (agentContact as Contact).id,
			plannedDate: date,
			type: isViews ? "view" : "show",
		});
	};

	const chooseObject = () => {
		setSelectedObject(undefined);
		openPinObjectModal();
	};

	const addContact = (contact: Contact | undefined, type: string) => {
		switch (type) {
			case "seller":
			case "buyer":
				setContact(contact);
				setSellerWithoutContact(false);
				break;
			case "agent":
				setAgentContact(contact);
				setSellerWithoutAgent(false);
				break;
			default:
				break;
		}
		dispatch(closeModal());
	};

	const checkForDisabled = () =>
		!(
			selectedObject &&
			(agentContact || sellerWithoutAgent) &&
			(curContact || sellerWithoutContact) &&
			watchFields.time &&
			watchFields.calendar
		);

	const {
		contactTitle,
		contactType,
		contactButtonTitle,
		agentButtonTitle,
		checkboxContactLabel,
		checkboxAgentLabel,
		mainButtonTitle,
	} = getTitlesAndLabels(isViews);

	const agentDefaultValue = {
		type: "agency",
	};

	return (
		<div className={styles.root}>
			<Loader height={250} style={{ display: isLoading ? "block" : "none" }} />
			<form onSubmit={form.handleSubmit(start)}>
				<Underlay className={styles.body} style={{ display: isLoading ? "none" : "block" }}>
					<CardCreationObject object={selectedObject} onClick={isViews ? chooseObject : undefined} />

					<DateOfView isViews={isViews} form={form} />

					<ContactSelect
						addContact={addContact}
						className={styles.sellerWithoutContact}
						contact={{
							contactData: curContact,
							contactTitle,
							contactType,
							contactButtonTitle,
							setContact,
						}}
					>
						{checkboxContactLabel ? (
							<IndividualCheckbox
								labelTextClassName={styles.radio}
								label={checkboxContactLabel}
								weight="bold"
								variant="noOpacity"
								checked={sellerWithoutContact}
								onChange={() => setSellerWithoutContact(!sellerWithoutContact)}
							/>
						) : null}
					</ContactSelect>

					<ContactSelect
						addContact={addContact}
						className={styles.sellerWithoutAgent}
						contact={{
							contactData: agentContact,
							contactTitle: "Агент",
							contactType: "agent",
							contactButtonTitle: agentButtonTitle,
							setContact: setAgentContact,
						}}
						defaultValues={agentDefaultValue}
					>
						<IndividualCheckbox
							labelTextClassName={styles.radio}
							label={checkboxAgentLabel}
							weight="bold"
							variant="noOpacity"
							checked={sellerWithoutAgent}
							onChange={() => setSellerWithoutAgent(!sellerWithoutAgent)}
						/>
					</ContactSelect>

					<div className={styles.showTime}>
						<Button onClick={start} disabled={checkForDisabled()}>
							{mainButtonTitle}
						</Button>
					</div>
				</Underlay>
			</form>
		</div>
	);
};
