import { Client } from "../../Client";
import {
  AppliedProductsResponse,
  AvailableToPurchaseResponse,
  ProductListResponse, ProductOrder,
  ProductsBuyHistoryResponse,
  PurchasedProductsResponse
} from "../../../../types/product";

export const ServicesService = {
  list: () => Client.get<ProductListResponse>(`/services`),
  get: (id: string) => Client.get(`/services/${id}`),
  getPurchased: (applicationId?: string) =>
    Client.post<PurchasedProductsResponse>(`/services/purchased${applicationId ? "?applicationId=" : ""}${applicationId || ""}`),
  getAvailableToPurchase: (applicationId?: string, adPlatformAccountId?: string) =>
    Client.post<AvailableToPurchaseResponse>(`/services/available${applicationId ? "?applicationId=" : ""}${applicationId || ""}${adPlatformAccountId ? "&adPlatformAccountId=" : ""}${adPlatformAccountId || ""}`),

  getAppliedServicesHistory: () => Client.post<AppliedProductsResponse>(`/services/appliedHistory`),

  getBuyHistory: () => Client.post<ProductsBuyHistoryResponse>(`/services/buyHistory`),

  getProductOrder: (id: string) => Client.get<ProductOrder>(`/services/appliedHistory/${id}`),

  deleteProductOrder: (id: string) => Client.delete(`/services/appliedHistory/${id}`),

  setDisputProductOrder: (id: string) => Client.patch(`/services/appliedHistory/${id}/disput`),

  updateProductOrder: (id: string, payload: {description: string}) => Client.put<ProductOrder>(`/services/appliedHistory/${id}`, payload),
}