import { useDispatch } from "react-redux";
import { openDrawer, openDrawerInDrawer } from "../../../../redux/modules/drawer/drawer.action";
import { OfficeCard } from "../../../../../components/pages/office-card/OfficeCard";
import { useQueryParams } from "../../../../../utils/hooks";
import { FixedNavVerticalMenu } from "../../../../../components/UI";
import { selectDevice, useSelect } from "../../../../redux/selectors";

type Return = {
  openOfficeDrawer: (officeId: string) => void;
};

type Props = {
  refetch?: () => void;
  atDrawerInDrawer?: boolean
};

type UseOfficeCard = (props: Props) => Return;

export const useOpenOfficeCard: UseOfficeCard = ({refetch, atDrawerInDrawer}) => {
  const dispatch = useDispatch();
  const {isMobile} = useSelect(selectDevice)

  const {removeQueryParam} = useQueryParams<{
    officeId: string;
  }>([{name: "officeId"}]);

  const officeNav = {
    label: "Офис",
    height: 120,
    title: "office"
  };

  const yNavigation: Omit<FixedNavVerticalMenu, "id" | "changeTab">[] = [officeNav]

  const openOfficeDrawer = (officeId: string) => {
    if (atDrawerInDrawer) {
      dispatch(openDrawerInDrawer(
        {
          children: <OfficeCard officeId={officeId} refetch={refetch}/>,
          width: isMobile ? 500 : 800,
          yNavigation: {
            navigation: yNavigation,
            initialTab: 0
          },
          onClose: () => {
            removeQueryParam("officeId")
          },
        }))
    } else {
      dispatch(
        openDrawer({
          children: <OfficeCard officeId={officeId} refetch={refetch}/>,
          width: isMobile ? 500 : 800,
          yNavigation: {
            navigation: yNavigation,
            initialTab: 0,
          },
          onClose: () => {
            removeQueryParam("officeId")
          },
        })
      );
    }
  };

  return {
    openOfficeDrawer,
  };
};
