import { useMutation } from "react-query";
import { api } from "../../api";
import { DocumentPayload } from "../../../../types";

const DEFAULT_MN = "sdelka/application/deal-add-document/DEFAULT_MN";

type UseAddDocument = {
  onSuccess?: () => void
}

export const useAddDocument= ({onSuccess}: UseAddDocument) => {
  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: ({id, payload}: {id: string, payload: DocumentPayload}) => api.deals.addDocument(id, payload),
    onSuccess
  })

  return {
    addDocument: mutation.mutate,
    addDocumentAsync: mutation.mutateAsync,
    addDocumentIsLoading: mutation.isLoading,
  }
}