import { useDispatch } from "react-redux";
import { openModal } from "../../../../../../service/redux/modules/modal/modal.action";
import { Join } from "./Join";
import { selectDevice, useSelect } from "../../../../../../service/redux/selectors";
import styles
  from "./index.module.scss";

export const JoinSdelkaProModal = () => {
  const dispatch = useDispatch()
  const {isMobile} = useSelect(selectDevice)

  const openJoinSdelkaProModal = () => {
    dispatch(
      openModal({
        body:
          <Join/>,
        width: isMobile ? 500 : 660,
        classNames: {
          contentWrapper: isMobile ? styles.modal : "",
          buttonClose: isMobile ? styles.modalClose : ""
        }
      })
    )
  }
  return {
    openJoinSdelkaProModal
  }
}