import { JSX, memo } from "react";
import styles from "../index.module.scss";
import { Tooltip } from "@sdelka_crm/sdelka-crm-component-library";
import { ReactComponent as SignedIcon } from "../../../../../assets/icons/signed.svg";
import { ReactComponent as YellowClockIcon } from "../../../../../assets/icons/failedDeadlineTasks.svg";
import { ApplicationIcons } from "../../../../../types";
import cn from "classnames";

type Props = {
  icons: ApplicationIcons
}

export const InfoIconBlock = memo(({icons}: Props): JSX.Element => (
 <>
   {icons &&
     <div className={styles.infoIconRow}>
     {icons.signedByMyAgency && <Tooltip
       classNames={{
         root: styles.tooltipDescription,
         card: cn(styles.tooltipCard, styles.tooltipActiveInfoLess)
       }}
       iconSize="big"
       CustomIcon={SignedIcon}
     >
       Подписан агентский договор
     </Tooltip>}
     {icons.hasWaitingTasks && <Tooltip
       classNames={{
         root: styles.tooltipDescription,
         card: cn(styles.tooltipCard, styles.tooltipActiveInfoLess)
       }}
       iconSize="big"
       CustomIcon={YellowClockIcon}
     >
       Просроченная задача
     </Tooltip>}
   </div>}
 </>

  ))