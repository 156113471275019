import { InfoRow } from "../../../../UI";
import { formatPhone } from "../../../../../utils/formatters";

type Props = {
	phone: string;
	id: string;
	fio?: string;
};

export const GeneralForm = ({ phone, id, fio }: Props): JSX.Element => (
	<div id={id}>
		<InfoRow label="Телефон">{formatPhone(phone)}</InfoRow>
		{fio && <InfoRow label="Псевдоним">{fio}</InfoRow>}
	</div>
);
