import { ReactNode } from "react";
import cn from "classnames";

import { useAdDescriptionModal } from "../../helpers";
import { AdPartnerHeaderButtons } from "./AdPartnerHeaderButtons";

import { ReactComponent as Share } from "../../../../../../../../../assets/icons/share-2.svg";
import styles from "./index.module.scss";
import { RequiredFieldsType } from "../../../../../../../../../types";
import { RequiredFields } from "../StartAd/components";

type Props = {
  img: null | string;
  link: null | string;
  alt: string;
  statusObject: ReactNode;
  adIsStarted?: boolean;
  adIsWaiting?: boolean;
  onAdStopClick?: () => void;
  onAdRestartClick?: () => void;
  onComplaintAutoRejectClick: () => void;
  feedId: string;
  cianError?: boolean;
  applicationId: string;
  isCian?: boolean;
  complaintAutoReject?: boolean;
  isPostedAndWaitingForStop?: boolean;
  adIsError?: boolean;
  adIsInArchive?: boolean;
  requiredFields: RequiredFieldsType;
  isMobile?: boolean
};

export const RealEstateAdPartnerHeader = (
  {
    img,
    link,
    statusObject,
    onAdStopClick,
    onAdRestartClick,
    onComplaintAutoRejectClick,
    feedId,
    adIsStarted = false,
    adIsWaiting = false,
    cianError = false,
    alt,
    applicationId,
    isCian,
    complaintAutoReject,
    isPostedAndWaitingForStop,
    adIsError,
    adIsInArchive,
    isMobile,
    requiredFields,
  }: Props): JSX.Element => {
  const {onModalOpen: onEditClick} = useAdDescriptionModal({
    feedId,
    error: cianError,
    applicationId,
  });

  const openLink = () => window.open(link as string, "_blank");

  return (
    <div className={styles.root}>
      {!isMobile &&
        <div className={cn(styles.img, {[ styles.withLink ]: !!link})}>
          <img src={img || ""} alt={alt}/>
          {link && (
            <div className={styles.link} onClick={openLink}>
              <Share/>
            </div>
          )}
        </div>}
      <div className={styles.body}>
        <div className={styles.statusObject}>{statusObject}</div>
        {requiredFields && !requiredFields.valid && (
          <RequiredFields requiredFields={requiredFields}/>
        )}
        {!isMobile &&
          <AdPartnerHeaderButtons
            adIsStarted={adIsStarted}
            adIsWaiting={adIsWaiting}
            adIsError={adIsError}
            adIsInArchive={adIsInArchive}
            onEditClick={onEditClick}
            onAdStopClick={onAdStopClick}
            onAdRestartClick={onAdRestartClick}
            onComplaintAutoRejectClick={onComplaintAutoRejectClick}
            isCian={isCian}
            complaintAutoReject={complaintAutoReject}
            isPostedAndWaitingForStop={isPostedAndWaitingForStop}
          />}
      </div>
    </div>
  );
};
