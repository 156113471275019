import { ReactComponent as Edit } from "../../../../../assets/icons/edit-page.svg";
import { RealEstateEdit } from "../../../../../components/pages/RealEstatePage/mode";
import { KindType } from "../../../../../types";
import { useDispatch } from "react-redux";
import {
  addTabInDrawer,
  addTabInDrawerInDrawer, changeCurrentTabByLabel,
  changeCurrentTabInDrawerByLabel
} from "../../../../redux/modules/drawer/drawer.action";
import { selectDevice, useSelect } from "../../../../redux/selectors";

type Props = {
  atDrawerInDrawer?: boolean
  kind?: KindType
}

type AddEdit = {
  id: string;
  refetch: () => void;
  enabled?: boolean
}

export const useAddEditComponent = (
  {
    atDrawerInDrawer,
    kind
  }: Props) => {
  const dispatch = useDispatch()
  const {isMobile} = useSelect(selectDevice)

  const addEditComponent = (
    {
      id,
      refetch,
      enabled
    }: AddEdit) => {


    const editNav = {
      label: <Edit/>,
      title: "edit",
      height: 90,
    };
    const editChildren = (
      <RealEstateEdit
        id={id}
        isMobile={isMobile}
        refetch={refetch}
        kind={kind || KindType.APPLICATION}
        enabled={enabled}
        atDrawerInDrawer={atDrawerInDrawer}
      />
    )

    if (atDrawerInDrawer) {
      dispatch(addTabInDrawerInDrawer({
        newNavigation: editNav,
        newChildren: editChildren
      }))
      dispatch(changeCurrentTabInDrawerByLabel({title: editNav.title}))
    } else {
      dispatch(addTabInDrawer({
        newNavigation: editNav,
        newChildren: editChildren
      }))
      dispatch(changeCurrentTabByLabel({title: editNav.title}))
    }
  }

  return {
    addEditComponent
  }
}