import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { toastSuccess } from "../../../../../index";
import { useContactModal } from "./helpers";
import { useCreateTaskModal, usePreCreateApplication } from "../../../../../../../utils/hooks";
import { closeModal } from "../../../../../../../service/redux/modules/modal/modal.action";
import { KindType, PreCreateApplicationPayload, PreCreateObjectPayload } from "../../../../../../../types";

import { ReactComponent as Checkmark } from "../../../../../../../assets/icons/checkmark-pixeled.svg";
import styles from "./index.module.scss";
import { useAddLeadModal } from "./helpers/useAddLeadModal";

export const AddModal = (): JSX.Element => {
  const dispatch = useDispatch();
  const {push} = useHistory();

  const handleClickButton = (path: string) => {
    push(path);
    dispatch(closeModal());
  };

  const {openContactModal} = useContactModal();
  const {openLeadModal} = useAddLeadModal();
  const {openCreateTaskModal} = useCreateTaskModal({
    onCreate: () => {
      dispatch(closeModal());
      toastSuccess({text: "Задача была создана", Icon: Checkmark});
    },
    kind: KindType.APPLICATION
  });

  const onObjectCreated = (values: PreCreateApplicationPayload | PreCreateObjectPayload) => {
    const {type, category} = values;

    const preCreateForm = {
      type,
      category,
    };

    const encode = encodeURIComponent(JSON.stringify(preCreateForm));
    const path = `/real-estate/application/new?preCreateForm=${encode}`;

    handleClickButton(path);
  };

  const handleCreateContact = () => openContactModal();

  const handleCreateLead = () => openLeadModal();

  const CREATE_OBJECT_TITLE = "Создать объект";

  const {handleCreateObject} = usePreCreateApplication({
    onCreated: onObjectCreated,
    title: CREATE_OBJECT_TITLE,
  });

  const buttons = [
    // { label: 'Заявку', onClick: () => handleCreateApplication(createApplication) },
    {label: "Объект", onClick: handleCreateObject},
    {label: "Задачу", onClick: () => openCreateTaskModal()},
    {label: "Контакт", onClick: handleCreateContact},
    {label: "Лид", onClick: handleCreateLead},
  ];

  return (
    <div className={styles.buttons}>
      {buttons.map((button) => (
        <Button
          key={button.label}
          variant="outlined"
          classNames={{root: styles.button}}
          onClick={button.onClick}
        >
          {button.label}
        </Button>
      ))}
    </div>
  );
};
