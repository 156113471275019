import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { openDrawer } from "../../../../../../service/redux/modules/drawer/drawer.action";
import { PublicOffer } from "../components/public-offer/PublicOffer";
import { selectDrawer, useSelect } from "../../../../../../service/redux/selectors";

export const FinishRegistrationWrapper = () => {
  const dispatch = useDispatch()
  const {body} = useSelect(selectDrawer)

  const publicOfferNav = {
    id: "view",
    label: "Публичная оферта",
    changeTab: () => {
    },
    height: 204,
  }

  useEffect(() => {
    if (!body) {
      dispatch(openDrawer({
        children: [<PublicOffer/>],
        width: 800,
        yNavigation: {
          navigation: [publicOfferNav]
        }
      }))
    }
  }, [])

  return (
    <>
    </>
  )
}