import { useState, useEffect, ReactNode } from "react";
import { AxiosResponse } from "axios";
import { QueryObserverResult, UseMutateAsyncFunction, UseMutateFunction } from "react-query";
import { Mutation } from "react-query/types/core/mutation";

import { Select } from "../Select";
import { DefaultSelectProps } from "../../../../../types";

type FindObject = {
	label: string;
	value: string;
};

type RemoteSelectProps<T> = {
	createLabel: (object: T) => string;
	getObjectQuery: ({ query, name }: { query: string; name?: string }) => {
		data: T | undefined;
		refetch: () => Promise<QueryObserverResult<AxiosResponse<T>, unknown>>;
	};
	objectMutate: () => {
		mutate: UseMutateFunction<AxiosResponse<T[]>, unknown, string, unknown>;
		mutateAsync: UseMutateAsyncFunction<AxiosResponse<T[]>, unknown, string, unknown>;
		isLoading: boolean;
		getLastMutationCache: () => Mutation<unknown, unknown, void, unknown>;
	};
};

type ObjectValue<T> = T & { id: string };

type Props<T> = {
	value: string;
	prepend?: ReactNode;
	append?: ReactNode;
	onChange?: (value: string) => void;
} & Omit<DefaultSelectProps, "isSearchable" & "onChange"> &
	RemoteSelectProps<T>;

export const RemoteSelect = <T,>({
	value,
	placeholder = "Введите адрес",
	name,
	register,
	setValue,
	onChange,
	error,
	classNames,
	prepend,
	append,
	size,
	required,
	isLoading,
	disabled,
	createLabel,
	getObjectQuery,
	objectMutate,
}: Props<ObjectValue<T>>): JSX.Element => {
	const DEFAULT_OBJECT = {
		label: "",
		value: "",
	};

	const [selectedObject, setSelectedObject] = useState<FindObject>(DEFAULT_OBJECT);

	const { data: loadedObject, refetch } = getObjectQuery({
		query: value,
		name,
	});

	const [options, setOptions] = useState<FindObject[]>([
		{
			label: selectedObject.label,
			value: selectedObject.value,
		},
	]);

	useEffect(() => {
		if (value === "") {
			setOptions([]);
		} else if (selectedObject.value !== value) {
			refetch();
		}
	}, [value]);

	useEffect(() => {
		if (loadedObject) {
			const newLabel = createLabel(loadedObject);

			setSelectedObject({
				label: newLabel,
				value,
			});
		}
	}, [loadedObject]);

	const findObjectMutate = objectMutate();

	const updateValue = (newValue: string) => {
		setValue(name, newValue);
		if (onChange) {
			onChange(newValue);
		}
	};

	useEffect(() => {
		updateValue(selectedObject.value);

		if (options.length === 0 && selectedObject.label !== "" && selectedObject.value !== "") {
			setOptions([selectedObject]);

			return;
		}

		if (options[0] && options[0]?.label === "" && options[0]?.value === "") {
			setOptions([selectedObject]);
		}
	}, [selectedObject]);

	const onSelectWrite = async (searchString: string) => {
		if (searchString === "") {
			setSelectedObject(DEFAULT_OBJECT);
			setOptions([]);

			return;
		}

		const { data } = await findObjectMutate.mutateAsync(searchString);

		if (data) {
			const foundObject = data.filter((object) => createLabel(object));

			const withoutDuplicate = foundObject.filter(
				(address, index, self) => index === self.findIndex((t) => createLabel(t) === createLabel(address))
			);

			if (data.length) {
				const newOptions = [
					{
						label: selectedObject.label as string,
						value: selectedObject.value as string,
					},
				].concat(
					withoutDuplicate.map((otherObject) => ({
						label: createLabel(otherObject),
						value: otherObject.id,
					}))
				);

				setOptions(newOptions);
			}
		}
	};

	const onSelectChange = (id: string) => {
		if (options?.length === 0) {
			return;
		}

		if (id === value) {
			return;
		}

		const lastMutation: any = findObjectMutate?.getLastMutationCache();

		const selectedObjectLastMutate: T & {
			id: string;
		} = lastMutation?.state?.data?.data?.find(
			(lastMutateData) => lastMutateData.id.toString() === id.toString()
		);

		if (!selectedObjectLastMutate) {
			return;
		}

		const newOptions: FindObject[] = [
			{
				label: createLabel(selectedObjectLastMutate),
				value: selectedObjectLastMutate.id as any,
			},
		];

		setOptions(newOptions);
		setSelectedObject(newOptions[0]);
	};

	const setCurrentObject = (_, values: string) => {
		onSelectChange(values);
	};

	return (
		<Select
			placeholder={placeholder}
			name={name}
			hideArrow
			defaultValue={selectedObject.value}
			defaultSearchValue={selectedObject.label}
			isSearchable
			size={size}
			options={options}
			register={register}
			setValue={setCurrentObject}
			classNames={classNames}
			customSearchFunction={onSelectWrite}
			onChange={onSelectChange}
			error={error}
			append={append}
			prepend={prepend}
			required={required}
			isLoading={isLoading}
			disabled={disabled}
		/>
	);
};
