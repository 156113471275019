import { PaidServicesType } from "../../types";

export type ConditionHelperType = boolean | string | JSX.Element;

export const conditionHelper = (
	currentItem: PaidServicesType,
	res1: ConditionHelperType,
	res2: ConditionHelperType,
	res3: ConditionHelperType
): ConditionHelperType => {
	if (currentItem.applied) return res1;

	return currentItem.limits ? res2 || res3 : res3;
};
