import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { EditForm } from "./components";
import { useSubmitForm, useContactForm, useCheckContactExist } from "./helpers";
import { Contact } from "../../../../../../../../types";

import styles from "./index.module.scss";

type Props = {
	contact: Contact;
	contactId: string;
	refetch: () => void;
	toggleMode: () => void;
};

export const EditMode = ({ contactId, refetch, toggleMode, contact }: Props): JSX.Element => {
	const { phones } = contact;
	const [firstPhone] = phones || [];
	const { phone } = firstPhone || {};

	const { control, register, formState, getValues, handleSubmit, setValue } = useContactForm({ contact });

	const { foundContact, isFetching: isFetchingChecking } = useCheckContactExist({
		control,
		getValues,
		defaultPhone: phone || "",
	});
	const { contact: existContact } = foundContact || {};

	const { onSubmit, isLoading: isLoadingSubmit } = useSubmitForm({
		contactId,
		refetch,
	});

	const isLoading = isLoadingSubmit || isFetchingChecking;
	const disabled = isLoading || !!existContact;

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<EditForm
				control={control}
				register={register}
				setValue={setValue}
				formState={formState}
				getValues={getValues}
				foundContact={foundContact}
			/>

			<div className={styles.submitBtns}>
				<Button variant="outlined" onClick={toggleMode} disabled={isLoading}>
					Отменить
				</Button>

				<Button type="submit" isLoading={isLoading} disabled={disabled}>
					Сохранить
				</Button>
			</div>
		</form>
	);
};
