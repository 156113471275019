import { LeadFiltersType, LeadSourceEnum, MultiLayerSelectOption } from "../../../../../types";
import { useEffect, useMemo, useState } from "react";
import { DEFAULT_LEAD_FILTERS } from "../../../../../service/api/helpers";
import { useLoadUsers } from "../../../../../service/api/queries";
import { prepareResponsibleUsers } from "../../../../../utils/transformation";
import { useFilterWatchers } from "../helpers";
import { useForm } from "react-hook-form";
import isEqual from "lodash.isequal";
import styles from "./index.module.scss"
import { CalendarRangeInPopup, InfoRow, MultiLayerSelect, MultiSelect } from "../../../../UI";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useCloseDrawer } from "../../../../../service/servise";

type Props = {
  formSubmit: (values: LeadFiltersType) => void;
  filter: LeadFiltersType;
}

type SourceOption = {
  label: string;
  value: LeadSourceEnum;
};

const sourceOptions: SourceOption[] = [
  {label: "Холодный звонок (парсер)", value: LeadSourceEnum.coldCall},
  {label: "Входящий звонок", value: LeadSourceEnum.call},
  {label: "Связанный лид", value: LeadSourceEnum.lead},
  {label: "С внешнего источника", value: LeadSourceEnum.external},
  {label: "Показ объекта", value: LeadSourceEnum.show},
  {label: "Просмотр объекта", value: LeadSourceEnum.view},
  {label: "Публичная страница", value: LeadSourceEnum.publicPage},
  {label: "Из архивных объектов", value: LeadSourceEnum.archivedEstateObject},
];

type FormValues = {
  responsibleUsers: MultiLayerSelectOption[];
} & LeadFiltersType;

const mapFormValues = (values: FormValues): LeadFiltersType => ({
  ...values,
  responsibleUsers: values.responsibleUsers.map((user) => user.value),
});

export const LeadsFiltersMobile = (
  {
    formSubmit,
    filter
  }: Props) => {
  const {register, setValue, handleSubmit, watch, reset, getValues, control} = useForm<FormValues>({
    defaultValues: DEFAULT_LEAD_FILTERS,
  });

  const [showBtn, setShowBtn] = useState(false);
  const [isResetting, setIsResetting] = useState(false);

  const {close} = useCloseDrawer({atDrawerInDrawer: false})

  const formWatcher = watch();

  useEffect(() => {
    if (!isEqual(formWatcher, filter)) {
      if (!showBtn) {
        setShowBtn(true);
      }
    } else {
      setShowBtn(false);
    }
  }, [Object.values(formWatcher)]);

  const prepareSubmitForm = (values: FormValues) => {
    reset(values);
    formSubmit(mapFormValues(values));
    setShowBtn(false);
    close()
  };

  useEffect(() => {
    reset(filter);
  }, [filter]);

  const {users} = useLoadUsers({});
  const responsibleUsers = useMemo((): MultiLayerSelectOption[] => prepareResponsibleUsers(users), [users]);

  const {responsibleUsersWatcher, createdAtFromWatcher, createdAtToWatcher, sourceWatcher} =
    useFilterWatchers({control, getValues});

  const defaultDate =
    !!createdAtFromWatcher && createdAtFromWatcher ? [createdAtFromWatcher, createdAtToWatcher] : undefined;


  return (
    <form className={styles.root} onSubmit={handleSubmit(prepareSubmitForm)}>
      <div className={styles.title}>
        Фильтры
      </div>
      <InfoRow aspect={{label: 8, content: 7.3}} label={"Сотрудник"}>
        <MultiLayerSelect
          name="responsibleUsers"
          options={responsibleUsers || []}
          register={register}
          setValue={setValue}
          isSearchable
          defaultValue={responsibleUsersWatcher}
        />
      </InfoRow>
      <InfoRow aspect={{label: 8, content: 7.3}} label={"От - До"}>
        <CalendarRangeInPopup
          defaultDate={defaultDate}
          classNames={{
            label: styles.periodLabelWrapper,
            wrapper: styles.periodPopupWrapper,
          }}
          form={{
            name: ["createdAtFrom", "createdAtTo"],
            register,
            setValue,
            getValues,
          }}
          isResetting={isResetting}
        />
      </InfoRow>
      <InfoRow aspect={{label: 8, content: 7.3}} label={"Источник"}>
        <MultiSelect
          name="source"
          setValue={setValue}
          options={sourceOptions}
          register={register}
          defaultValue={sourceWatcher}
        />
      </InfoRow>
      <div className={styles.submitWrapper}>
        <Button type="submit">Показать</Button>
      </div>
    </form>
  )
}