import type { Application, ApplicationInListV2, ApplicationToLeadRelations } from "../application";
import type { RealEstate } from "../real-estate";
import type { Contact, ContactPreview } from "../contact";
import type { User, UserAccountData } from "../user";
import { RefuseReason } from "./lead-history";
import { Kind } from "../contact";

export type JointType =
  | "Client"
  | "Stakeholder"
  | "Agent"
  | "Notary"
  | "Lawyer"
  | "Bank"
  | "Assessment"
  | "Other";

export type LeadContactJoint = {
  contactId: string;
  jointType?: JointType;
  side?: "seller" | "buyer";
};

export enum LeadStatusEnum {
  new = 'new', // Необработанный
  free = 'free', // Свободный
  in_progress = 'in_progress', // В работе
  deferred = 'deferred', // Замороженный
  done = 'done' // Отработанный
}

export enum LeadInvalidReason {
  agent = "agent",
  "non-targeted" = "non-targeted",
  "non-actual" = "non-actual",
  other = "other",
}

export type RelatedLead = {
  id: string;
  shortId: number;
  createdAt: string;
  stage: string;
  account?: UserAccountData;
  isParent: boolean;
  canView: boolean;
};

export enum LeadSourceEnum {
  call = "call", // из звонка
  coldCall = "coldCall", // из холодного звонка
  show = "show",
  view = "view",
  lead = "lead",
  archivedEstateObject = "archivedEstateObject",
  publicPage = "publicPage",
  external = "external",
}

export const LeadSourceEnumTranslate: Record<LeadSourceEnum, string> = {
  call: "Звонок",
  coldCall: "Холодный звонок",
  show: "Показ",
  view: "Просмотр",
  lead: "Лид",
  archivedEstateObject: "Арихв",
  publicPage: "Публичная страница",
  external: "Внешний"
}

export type LeadInList = {
  id: string;
  shortId: number;
  status: LeadStatusEnum;
  invalidReason?: LeadInvalidReason;
  invalidReasonComment?: string;
  /** @format date-time */
  deferredDemandDate?: string;
  /** @format date-time */
  createdAt: string;
  source?: LeadSourceEnum;
  responsibleUser?: User;
  canView: boolean;
  isReturned: boolean;
  adSource?: string
  contact?: ContactPreview;
  iAmResponsibleUser: boolean
  relatedKinds?: {
    applications: Kind[]
    deals: Kind[]
    preApplications: Kind[]
    successDeals: Kind[]
  };
};

export type LeadTarget = {
  clientObject: RealEstate | null;
};

export type Lead = LeadInList & {
  contact?: Contact;
  /** @format date-time */
  modifiedAt: string;
  creator?: User;
  visibleDepartments?: LeadsToDepartmentVisible[];
  target: LeadTarget;
  childApplications: ApplicationInListV2[] & ApplicationToLeadRelations[]
  relatedLeads?: {
    childRelatedLeads: LeadInList[];
  };
  parserObject?: ParserObject
  sourceApplication?: SourceApplication
  sourceLead?: SourceLead
  adSource?: string
  parentShowOrView?: any;
  avatarLink?: string;
  avitoId?: string;
  email?: string;
  fio?: string;
  instagram?: string;
  phones?: string[];
  profileLink?: string;
  adChannel?: string;
  telegram?: string;
  comment?: string;
  refuseReason?: RefuseReason;
  refuseReasonComment?: string;
  childPreApplications?: ApplicationInListV2[]
  sourceTraffic?: ParentTraffic
  archivedEstateObject?: ArchiveEstateObject
};

type ParentTraffic = {
  id: string,
  shortId: string
}

type ParserObject = {
  id: string;
  shortId: string;
  category?: string
};

type ArchiveEstateObject = {
  shortId: string
  preApplicationId?: string
  applicationId?: string
}

type SourceLead = {
  id: string,
  shortId: string
}

type SourceApplication = {
  id: string,
  shortId: string
  showId?: string,
  viewId?: string
}

export type LeadsToDepartmentVisible = {
  id: string;
  name: string;
  office: {
    id: string;
    name: string;
    addressLabel?: string;
  };
};
