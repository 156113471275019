import { CSSProperties, ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import {
  changeCurrentTabInDrawer,
  changeDrawerScrolled,
  closeDrawerInDrawer
} from "../../../../service/redux/modules/drawer/drawer.action";

import { ReactComponent as ArrowIcon } from "../../../../assets/icons/arrow-down.svg";
import styles from "./index.module.scss";
import { FixedNavVertical, FixedNavVerticalMenu } from "../../FixedNavVertical";
import { useQueryParams } from "../../../../utils/hooks";

const ANIMATION_CLASSES = ["animate__animated", "animate__faster", "animate__fadeInRightBig"];
export const DRAWER_IN_DRAWER_ID = "drawer-in-drawer-children-wrapper";
const CLOSE_BUTTON_SKIP = 64;

type Props = {
  width: number;
  parentWidth: number;
  children: ReactNode;
  onClose?: () => void;
  refetch?: () => void;
  withCloseArrow?: boolean
  changeTab?: number
  yNavigation?: { navigation: Omit<FixedNavVerticalMenu, "id" | "changeTab">[], initialTab?: number | undefined } | undefined
};

const useDrawerCss = (width: number, yNavigation?: Omit<FixedNavVerticalMenu, "id" | "changeTab">[]) => {
  const cssStyles: CSSProperties = {};
  const iconStyles: CSSProperties = {};
  const navStyles: CSSProperties = {animationDuration: "1s"};
  let navTop = 0;

  if (width) {
    const offsetForTabs = 55;
    navStyles.left = "auto";
    navStyles.right = `${width + 503}px`;
    cssStyles.width = `${width}px`;
    iconStyles.right = `${width + offsetForTabs - 48}px`;
  }

  if (yNavigation) {
    navTop = CLOSE_BUTTON_SKIP;
  }

  return {navStyles, cssStyles, iconStyles, navTop};
};

export const DrawerInDrawer = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const {
    width,
    parentWidth,
    children,
    onClose,
    refetch,
    withCloseArrow,
    yNavigation,
    changeTab,
  } = props

  const {
    queryParams: {mode}, changeQueryParams
  } = useQueryParams<{ mode: string }>([{name: "mode"}]);
  const handleChangeTab = (newTab: string) => changeQueryParams([{name: "mode", newValue: newTab}]);
  const [currentYTab, setCurrentYTab] = useState(0);
  const {navStyles, navTop} = useDrawerCss(width, yNavigation?.navigation);
  useEffect(() => {
    if (refetch) {
      refetch()
    }
  }, [])

  const queryNames = yNavigation?.navigation.map(el => ({
    name: el.query?.name || ""
  })).concat({name: "mode"})
  const queries = yNavigation?.navigation.map(el => el.query?.name || "").concat("mode")

  const {removeQueryParam} = useQueryParams(queryNames || []);

  const handleCloseDrawerInDrawer = () => {
    if (onClose) {
      onClose();
    }
    removeQueryParam(queries || [])
    dispatch(closeDrawerInDrawer());
  };

  const currentChild = Array.isArray(children) ? children[ currentYTab ] : children

  const drawerYNavigation = useMemo(() => {
    if (!yNavigation) {
      return undefined;
    }
    return yNavigation.navigation.map((nav, index) => ({
      ...nav,
      id: index,
      changeTab: () => {
        setCurrentYTab(index);
        changeCurrentTabInDrawer({newCurrentTab: index})
        handleChangeTab(index.toString())
      },
    }));
  }, [yNavigation, children, currentYTab, mode]);

  useEffect(() => {
    setCurrentYTab(yNavigation?.initialTab || 0);
  }, [yNavigation?.initialTab, yNavigation]);

  useEffect(() => {
    setCurrentYTab(changeTab || 0)
  }, [changeTab])

  const scroll = document.getElementById("drawer-in-drawer-children-wrapper")
  const [element, setElement] = useState<HTMLElement | null>(null)

  useEffect(() => {
    if (scroll !== null && element === null) {
      setElement(scroll)
    }
  },)

  useEffect(() => {
    if (element === null) {
      return
    }
    if (element !== undefined) {
      const listener = () => {
        if (element.offsetHeight + element.scrollTop >= element.scrollHeight) {
          dispatch(changeDrawerScrolled({scrolled: true}))
        }
      }
      element.addEventListener("scroll", listener)

      // eslint-disable-next-line consistent-return
      return () => {
        dispatch(changeDrawerScrolled({scrolled: false}))
        element.removeEventListener("scroll", listener)
      }
    }

  }, [scroll, element])

  return (
    <>
      {withCloseArrow !== false &&
        <div
          style={{right: `${parentWidth + width + 20}px `}}
          className={cn(styles.close, ...ANIMATION_CLASSES)}
          onClick={handleCloseDrawerInDrawer}
        >
          <ArrowIcon/>
        </div>}
      <FixedNavVertical
        style={navStyles}
        navigation={drawerYNavigation ?? []}
        top={navTop}
        activeTab={currentYTab}
        classNames={{
          container: ANIMATION_CLASSES
        }}
      />
      <div
        style={{right: parentWidth, width: `${width}`}}
        className={cn(styles.root, ...ANIMATION_CLASSES)}
        id={DRAWER_IN_DRAWER_ID}
      >
        {currentChild}
      </div>
    </>
  );
};
