import { useEffect, useMemo } from "react";
import { differenceInMinutes } from "date-fns";

import { SidebarLayout, TableCustomizable } from "../../UI";
import { CompanyRequest, EmployeesHeader, EmployeesListFilters, InviteApprove } from "./components";
import { usersColumns } from "./columns/columns";
import {
  EMPLOYEE_LIST_LOAD_OFFICES_QN,
  useLoadCompanyAccessions,
  useLoadCompanyInvites,
  useLoadOffices,
  useLoadUsers,
} from "../../../service/api/queries";
import { UseLoadUserPositions, } from "../../../service/api/queries/position/useLoadUserPositions";
import { LOAD_POSITIONS_REQUESTS_QN } from "../../../service/api/queries/position/useLoadUserPositions";
import { useQueryParams } from "../../../utils/hooks";
import { Invite } from "../../../types";

import styles from "./index.module.scss";
import { useOpenProfileCard } from "../../../service/servise";

export const EmployeesList = (): JSX.Element => {
  const {queryParams, changeQueryParams} = useQueryParams<{
    accountId: string;
    userId: string;
  }>([{name: "accountId"}, {name: "userId"}]);

  const {users, refetch: refetchUsers} = useLoadUsers({});
  const {
    offices,
    refetch: refetchOffices,
    isLoading: isLoadingOffices,
  } = useLoadOffices({queryName: EMPLOYEE_LIST_LOAD_OFFICES_QN});
  const {
    positions,
    refetch: refetchPositions,
    isLoading: isLoadingPositions,
  } = UseLoadUserPositions({queryName: LOAD_POSITIONS_REQUESTS_QN});
  const {invites, refetch: refetchInvites} = useLoadCompanyInvites({});

  const filteredInvites = useMemo(
    () =>
      invites.filter(
        (invite) => differenceInMinutes(new Date(), new Date(invite.createdAt)) < 4320 && invite.active
      ),
    [invites]
  );

  const {accessions, refetch: refetchAccessions} = useLoadCompanyAccessions({});

  const filteredAccessions = useMemo(
    () =>
      accessions.filter(
        (accession) =>
          differenceInMinutes(new Date(), new Date(accession.createdAt)) < 4320 &&
          !["accept", "decline"].includes(accession.status)
      ),
    [accessions]
  );

  const officeOptions = useMemo(
    () =>
      offices?.map((office) => ({
        label: office.name,
        options: office.departments.map((department) => ({
          label: department.name,
          value: department.id,
          group: office.name,
        })),
      })),
    [offices, offices.length]
  );

  const positionOptions = useMemo(
    () =>
      positions?.map((position) => ({
        label: position.name,
        value: position.id,
      })),
    [positions, positions.length]
  );

  const refetchDataAfterAccept = () => {
    refetchUsers();
    refetchInvites();
    refetchAccessions();
    refetchOffices();
    refetchPositions();
  };

  const {openProfileDrawer} = useOpenProfileCard({
    refetch: refetchDataAfterAccept,
  });

  const openUserSettingsDrawer = (accountId: string, userId: string) => {
    changeQueryParams([
      {name: "userId", newValue: userId},
      {name: "accountId", newValue: accountId},
    ]);

    openProfileDrawer(userId);
  };

  useEffect(() => {
    if (queryParams.accountId && queryParams.userId) {
      openUserSettingsDrawer(queryParams.accountId, queryParams.userId);
    }
  }, [queryParams.accountId, queryParams.userId, isLoadingOffices, isLoadingPositions]);

  const columns = useMemo(
    () => usersColumns(offices, openUserSettingsDrawer),
    [users.length, positions.length, offices.length]
  );

  return (
    <SidebarLayout
      hat={{
        element: <EmployeesListFilters/>,
        elementHeight: 50,
      }}
    >
      <div className={styles.container}>
        <EmployeesHeader
          refetchInvites={refetchInvites}
          officeOptions={officeOptions}
          positionOptions={positionOptions}
        />

        {!!filteredAccessions.length && officeOptions && positionOptions && (
          <div className={styles.requestBlock}>
            <span className={styles.smallHeader}>Заявки на вступление</span>

            {filteredAccessions.map((companyAccession) => (
              <CompanyRequest
                key={companyAccession.id}
                company={companyAccession}
                positionOptions={positionOptions}
                officeOptions={officeOptions}
                refetch={refetchDataAfterAccept}
              />
            ))}
          </div>
        )}

        {!!filteredInvites?.length && (
          <div className={styles.requestBlock}>
            <span className={styles.smallHeader}>Ожидает подтверждения</span>

            {filteredInvites?.map((invite: Invite) => (
              <InviteApprove key={invite.id} invite={invite} offices={offices} positions={positions}/>
            ))}
          </div>
        )}

        {positions && offices && users && (
          <div className={styles.table}>
            <TableCustomizable
              columns={columns}
              data={users}
              isFirstColumnFixed
            />
          </div>
        )}
      </div>
    </SidebarLayout>
  );
};
