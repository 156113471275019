import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/real-estate-ad/load-paid-services/DEFAULT_QN";

type UseLoadPaidServices = {
	applicationId: string;
	feedId: string;
	format?: string;
};

export const useLoadPaidServices = ({ applicationId, feedId, format = "default" }: UseLoadPaidServices) => {
	const loadPaidServices = () => api.realEstateAd.platformPaidServices(applicationId, feedId);

	const { data, refetch, remove, isLoading, isRefetching, isFetched } = useQuery(
		[DEFAULT_QN, feedId, format],
		loadPaidServices,
		{
			enabled: false,
		}
	);

	return {
		data: data?.data,
		isLoading: isLoading || isRefetching,
		refetch,
		remove,
		isFetched,
	};
};
