import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { RealEstateFilter } from "../../../../../../../types";

type UseDrawerForm = {
	filter: RealEstateFilter;
};
export const useDrawerForm = ({ filter }: UseDrawerForm) => {
	const { control, register, setValue, handleSubmit, getValues, reset } = useForm();

	// synchronize filters
	useEffect(() => {
		reset(filter);
	}, []);

	return {
		control,
		register,
		setValue,
		handleSubmit,
		getValues,
		reset,
	};
};
