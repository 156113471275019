import { Polygon, PolygonGeometry } from "react-yandex-maps";

import { polyStyle } from "../map-utils/utils";

/**
 * Компонент для сохранения нарисованных полигонов.
 */

type Props = {
	polyPoints: PolygonGeometry[];
};

export const DrawnPolygons = ({ polyPoints }: Props): JSX.Element => (
		<>
			{polyPoints.map((geometry, index) => (
				<Polygon options={polyStyle} key={`${index + 0}`} geometry={geometry}/>
			))}
		</>
	)
