import { Office, SelectedOffice } from "../../../../types";
import styles from "./index.module.scss"
import cn from "classnames";
import { ReactComponent as Avatar } from "../../../../assets/icons/office-briefcase-svgrepo-com 1.svg";
import { ReactComponent as Star } from "../../../../assets/icons/iconStar.svg";
import { ReactComponent as YouTube } from "../../../../assets/icons/youtube-flat.svg";
import { ReactComponent as Telegram } from "../../../../assets/icons/telegram.svg";
import { useIsTelegram } from "../../../../utils/telegram/useIsTelegram";

type Props = {
  office: Office & SelectedOffice
  selected?: boolean
  onCardClick: (id: string) => void
  mini?: boolean
}

export const CardOfficeForSelect = (
  {
    onCardClick,
    office,
    selected,
    mini
  }: Props) => {
  const {executeUrl} = useIsTelegram()


  const openYouTubeLink = () => {
    executeUrl(`${office.youTubeLink}`)
  }
  const openTelegramLink = () => {
    executeUrl(`${office.telegramLink}`)
  }

  return (
    <div
      className={cn(styles.root, {[ styles.selected ]: selected, [ styles.mini ]: mini})}
      onClick={() => onCardClick(office.id)}
    >
      <div className={styles.content}>
        <div className={styles.rowMain}>
          {office.image
            ? <img src={office.image} alt="avatar"/>
            : <div className={styles.logo}><Avatar/></div>
          }
          <div className={styles.mainCol}>
            <div className={styles.col}>
              <div className={styles.titleRow}>
                <div className={styles.row}>
                  <div className={styles.title}>
                    {office.name || office.title}
                  </div>
                  <div className={styles.users}>
                    {`${office?.users || 0} участников`}
                  </div>
                </div>
                <div className={styles.row}>
                  <Star style={{marginRight: "-4px"}}/>
                  <div className={styles.reputation}>
                    {office?.reputation}
                  </div>
                </div>
              </div>
              <div>
                {office.addressLabel}
              </div>
            </div>
            <div className={styles.row}>
              {office.youTubeLink &&
                <div onClick={openYouTubeLink}>
                  <YouTube/>
                </div>
              }
              {office.telegramLink &&
                <div onClick={openTelegramLink}>
                  <Telegram/>
                </div>
              }
              {office.mainPhone} {" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}