import { Client } from "../../Client";
import { getRequestFilters } from "../../helpers";
import {
  AddLeadToApplication,
  AddResponsibleToApplication,
  Application,
  ApplicationFilterRopeTemplate,
  ApplicationFilterRopeTemplateOneLevel,
  ApplicationListPayload,
  ApplicationListResponse,
  ApplicationUpdatePayload,
  ApplicationUserResponse,
  Bank,
  ChangeStagePayload,
  ChangeStageResponse,
  Conditions,
  Contact,
  CreateApplication, CreateGuaranteePayment,
  CreateGuaranteePaymentWithExistsApplication,
  CreateGuaranteePaymentWithNewApplication,
  DealGuarantee,
  FinishApplicationPayload, GuaranteePaymentStatusResponse,
  WhoIsClient,
} from "../../../../types";
import { AppliedProductsResponse } from "../../../../types/product";

export const ApplicationService = {
  getClient: ({applicationId}) => Client.get(`/application/${applicationId}/whoIsClient`),
  getCard: ({applicationId}) => Client.get<Application>(`/application/${applicationId}`),
  getCardBlocks: ({applicationId, stage}) => Client.get(`application/${applicationId}/blocks/${stage}`),

  contacts: ({applicationId, type}: { applicationId: string; type?: WhoIsClient }) =>
    Client.get<Contact[]>(`/application/${applicationId}/contacts-${type}`),
  employee: ({applicationId}) =>
    Client.get<ApplicationUserResponse[]>(`application/${applicationId}/employee`),

  create: ({
             additionalProperties,
             typeId,
             responsibleUserId,
             comment,
             sellerContacts,
             buyerContacts,
             clientObjectId,
           }: CreateApplication) =>
    Client.post("/application", {
      additionalProperties,
      typeId,
      responsibleUserId,
      comment,
      sellerContacts,
      buyerContacts,
      clientObjectId,
    }),
  createFromLead: ({leadId}: { leadId: string }) => Client.post(`application/fromLead/${leadId}`),
  setContactFromParentLead: ({applicationId}: { applicationId: string }) =>
    Client.patch(`application/relations-to-leads/${applicationId}/setContactFromLead`),

  deleteLead: ({applicationId, leadId}: AddLeadToApplication) =>
    Client.delete(`/application/relations-to-leads/${leadId}/${applicationId}`),
  addEmployee: ({applicationId, responsibleUsers}: AddResponsibleToApplication) =>
    Client.put(`/application/${applicationId}`, {responsibleUsers}),

  deleteResponsible: ({applicationId, id}: { applicationId: string; id: string }) =>
    Client.delete(`/application/${applicationId}/responsible/${id}`),

  deleteContact: ({applicationId, id, type}: { applicationId?: string; id: string; type?: string }) =>
    Client.delete(`/application/${applicationId}/contact-${type}/${id}`),
  updateContact: ({applicationId, id, type}: { applicationId: string; id: string; type: WhoIsClient }) =>
    Client.post(`/application/${applicationId}/contact-${type}/${id}`),

  updateInfo: ({applicationId, applicationData}: ApplicationUpdatePayload) =>
    Client.put(`/application/${applicationId}`, applicationData),

  list: ({take, skip, search, value}: ApplicationListPayload) => {
    const searchConditions: Conditions = search ? getRequestFilters(search) : [];

    return Client.post<ApplicationListResponse>(
      `/${value}/search`,
      JSON.stringify({
        search: {
          filterType: "and",
          conditions: searchConditions,
        },
        take,
        skip,
      })
    );
  },

  object: ({applicationId}: { applicationId: string }) =>
    Client.get(`/application/${applicationId}/object`),

  changeStage: ({applicationId, stageName}: ChangeStagePayload) =>
    Client.patch<ChangeStageResponse>(`/application/${applicationId}/stage/${stageName}`),

  remoteBanks: (query: string) => Client.get<Bank[]>(`/application/remote-selection/banks?query=${query}`),

  getBank: (id: string) => Client.get<Bank>(`/application/remote-selection/bank/${id}`),

  createTasks: ({applicationId, payload}: { applicationId: string; payload: any }) =>
    Client.post(`application/${applicationId}/fastCreateTasks`, payload),

  nextStage: (id: string) => Client.patch(`/application/${id}/nextStage`),

  finish: ({applicationId, action}: FinishApplicationPayload) =>
    Client.post(`application/${applicationId}/${action}`),

  createDeal: (id: string) => Client.post<Application>(`/deal`, {
      applicationId: id,
    }),

  finishApplication: (id: string) => Client.patch(`/application/${id}/cancel`),

  applyService: (applicationId: string, serviceId: string) => Client.post<{orderId: string}>(`/application/${applicationId}/services/apply/${serviceId}`),

  getAppliedServicesHistory: (applicationId: string) => Client.post<AppliedProductsResponse>(`/application/${applicationId}/appliedServicesHistory`),

  getRopeTemplates: () => Client.get<ApplicationFilterRopeTemplate[]>(`/settings/business-process/forFilters`),

  getRopeTemplatesOneLevel: () => Client.get<ApplicationFilterRopeTemplateOneLevel[]>(`/settings/business-process/forFiltersOneLevel`),

  createShow: (id: string, relatedApplicationOrPreApplicationId: string, plannedDate: Date) =>
    Client.post(`/applications/${id}/show/withExistsApplication/${relatedApplicationOrPreApplicationId}`, {
      plannedDate
    }),

  createShowWithoutApp: (applicationId: string, plannedDate: Date, contactId: string, leadSource?: string) =>
    Client.post(`/applications/${applicationId}/show`, {
      plannedDate,
      contactId,
      leadSource
    }),

  createView: (id: string, relatedApplicationOrPreApplicationId: string, plannedDate: Date) =>
    Client.post(`/applications/${id}/view/withExistsApplication/${relatedApplicationOrPreApplicationId}`, {
      plannedDate
    }),

  createViewWithoutApp: (applicationId: string, plannedDate: Date, contactId: string, leadSource?: string) =>
    Client.post(`/applications/${applicationId}/view`, {
      plannedDate,
      contactId,
      leadSource
    }),

  getGuaranteePaymentStatus: (applicationId: string) => Client.get<GuaranteePaymentStatusResponse>(`/applications/${applicationId}/guaranteePayment`),

  createGuaranteePaymentWithExistsApplication: (applicationId: string, payload: CreateGuaranteePaymentWithExistsApplication) =>
    Client.post(`/applications/${applicationId}/guaranteePayment/withExistsApplication`, payload),

  createGuaranteePaymentWithNewApplication: (applicationId: string, payload: CreateGuaranteePaymentWithNewApplication) =>
    Client.post(`/applications/${applicationId}/guaranteePayment/withNewApplication`, payload),

  createGuaranteePayment: (applicationId: string, payload: CreateGuaranteePayment) =>
    Client.post(`/applications/${applicationId}/guaranteePayment`, payload)

};
