import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { Container, Row, Col, TogglePass, TextField } from "../../../../UI";
import { useResetPassword } from "../../../../../service/api/mutations";
import { useSelector } from "../../../../../service/redux/useSelector";
import { paths } from "../../../../../utils/paths";
import { digitOnly } from "../../../../../utils/string";
import { ResetPasswordPayload } from "../../../../../types";

import { ReactComponent as CheckMark } from "../../../../../assets/icons/check-mark.svg";
import { ReactComponent as CheckMarkPixel } from "../../../../../assets/icons/checkmark-pixeled.svg";
import { ReactComponent as LockIcon } from "../../../../../assets/icons/lock.svg";
import styles from "./index.module.scss";

export const EnterPassword = (): JSX.Element => {
	const { verificationCode, token } = useSelector((state) => state.resetPassSteps);
	const [showOk, setShowOk] = useState(false);
	const [passwordInputType, setPasswordInputType] = useState<"password" | "text">("password");
	const history = useHistory();

	const schema = yup.object().shape({
		password: yup
			.string()
			.min(6, "Слишком простой пароль ")
			.matches(
				/((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-ZА-Я])(?=.*[a-zа-я]).*$/,
				"В пароле должны содержаться заглавные буквы и знаки"
			)
			.test("password", "В пароле не может быть пробелов", (password) => !password?.includes(" "))
			.required("Обязательное поле"),
		repeatPassword: yup
			.string()
			.min(6, "Слишком простой пароль ")
			.oneOf([yup.ref("password"), null], "Пароли не совпадают")
			.matches(
				/((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-ZА-Я])(?=.*[a-zа-я]).*$/,
				"В пароле должны содержаться заглавные буквы и знаки"
			)
			.test(
				"repeatPassword",
				"В пароле не может быть пробелов",
				(repeatPassword) => !repeatPassword?.includes(" ")
			)
			.required("Обязательное поле"),
	});

	const { control, handleSubmit, setError, formState, getValues } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			password: "",
			repeatPassword: "",
		},
	});

	const { mutate, isSuccess } = useResetPassword({
		onSuccess: () => {
			setTimeout(() => {
				history.push(paths.dashboardPath);
			}, 3000);
		},
		setError,
	});

	const togglePassword = () => {
		setPasswordInputType((prev) => {
			if (prev === "password") {
				return "text";
			}

			return "password";
		});
	};

	const onSubmit = async (values: { password: string; repeatPassword: string }) => {
		if (values.password !== values.repeatPassword) {
			setError("repeatPassword", { message: "Пароли не совпадают" });
		} else if (token && verificationCode) {
			const payload: Omit<ResetPasswordPayload, "phone"> & { token: string } = {
				token,
				code: String(digitOnly(verificationCode)),
				password: values.password,
			};

			mutate(payload);
		}
	};

	const compirePasswords = () => {
		const passwords = getValues(["password", "repeatPassword"]);

		if (passwords[0] && passwords[1] && passwords[0] === passwords[1]) {
			setShowOk(true);
		} else {
			setShowOk(false);
		}
	};

	if (isSuccess) {
		return (
			<div className={styles.changeDone}>
				<CheckMark />
				<h2>Ваш пароль успешно изменен</h2>
				<p>Через несколько секунд вы будете перенаправленны в систему.</p>
			</div>
		);
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<h1>Восстановление пароля</h1>
			<div className={styles.formContainer}>
				<Container>
					<Row marginBottom={20}>
						<Col xs={12}>
							<TextField
								label="Новый пароль"
								type={passwordInputType}
								name="password"
								control={control}
								error={formState?.errors?.password?.message?.toString()}
								append={<TogglePass type={passwordInputType} onClick={togglePassword} />}
								prepend={<LockIcon />}
							/>
						</Col>
					</Row>

					<Row marginBottom={20}>
						<Col xs={12}>
							<TextField
								label="Повторите пароль"
								type={passwordInputType}
								name="repeatPassword"
								control={control}
								onChange={compirePasswords}
								error={formState?.errors?.repeatPassword?.message?.toString()}
								append={
									showOk ? (
										<CheckMarkPixel className={styles.passTheSame} />
									) : (
										<TogglePass type={passwordInputType} onClick={togglePassword} />
									)
								}
								prepend={<LockIcon />}
							/>
						</Col>
					</Row>
				</Container>
				<span className={styles.passRule}>
					Пароль должен содержать цифры, прописные и заглавные буквы, а также иметь не менее 6 символов
				</span>
			</div>

			<div className={styles.buttonsContainer}>
				<Button type="submit" classNames={{ root: styles.button }}>
					Изменить
				</Button>

				<Link className={styles.link} to={paths.loginPath}>
					назад
				</Link>
			</div>
		</form>
	);
};
