import { useMutation } from "react-query";
import { ShowsViewsFinishPayload } from "../../../../types";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/shows-views/finish-show-view/DEFAULT_MN";

type UseFinishShowOrView = {
	onSuccess?: (data: any) => void;
	isViews?: boolean
};

export const useFinishShowOrView = ({ onSuccess, isViews }: UseFinishShowOrView) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (data: ShowsViewsFinishPayload) => isViews ? api.showsViews.finishView(data) : api.showsViews.finishShow(data),
		onSuccess,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
