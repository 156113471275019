import { Difference } from "../types";

// We can build those strings by a template
//  as they are pretty similar to each other,
//  but it's better to keep it simple

const getLabelForCrossedDeadlineDiff = ({ value, type }: Difference) => {
	switch (type) {
		case "months":
			return "Просрочена более мес.";
		case "weeks":
			return `Просрочена более ${value} нед.`;
		case "days":
			return `Просрочена более ${value} дн.`;
		case "hours":
			return `Просрочена более ${value} ч.`;
		case "minutes":
			return "Просрочена менее 1ч.";

		// normally this will never happen
		default:
			return `Expired ${value} ${type}`;
	}
};

const getLabelForNiceDiff = ({ value, type }: Difference) => {
	switch (type) {
		case "months":
			return "Осталось более мес.";
		case "weeks":
			return `Осталось ${value} нед.`;
		case "days":
			return `Осталось ${value} дн.`;
		case "hours":
			return `Осталось ${value} ч.`;
		case "minutes":
			return `Осталось ${value} мин.`;

		// normally this will never happen
		default:
			return `Left ${value} ${type}`;
	}
};

export const mapDiffToLabel = (diff: Difference, isDeadlineCrossed: boolean) =>
	isDeadlineCrossed ? getLabelForCrossedDeadlineDiff(diff) : getLabelForNiceDiff(diff);
