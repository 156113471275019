import { useMutation } from "react-query";
import { ShowsViewsUpdataPayload } from "../../../../types";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/shows-views/update-shows-or-views/DEFAULT_MN";

type UseUpdateShowOrView = {
	onSuccess?: (data: any) => void;
};

export const useUpdateShowOrView = ({ onSuccess }: UseUpdateShowOrView) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (payload: ShowsViewsUpdataPayload) => api.showsViews.update(payload),
		onSuccess,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
