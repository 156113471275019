import { AxiosResponse } from "axios";
import { useState, useEffect, SetStateAction, Dispatch } from "react";
import { QueryObserverResult, RefetchOptions, useQuery } from "react-query";
import { RealEstateGetConfigPayload, RealEstateSchema } from "../../../../types";
import isEqual from "lodash.isequal";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/real-estate/load-real-estate-config/DEFAULT_QN";

type Props = {
	enabled?: boolean;
	defaultCategory?: RealEstateGetConfigPayload;
};

type Return = {
	objectConfig?: RealEstateSchema;
	isFetching: boolean;
	refetch: (
		options?: RefetchOptions | undefined
	) => Promise<QueryObserverResult<AxiosResponse<RealEstateSchema>, unknown>>;
	remove: () => void;
	removeData: () => void;
	setCategory: Dispatch<SetStateAction<RealEstateGetConfigPayload | undefined>>;
	currentCategory: RealEstateGetConfigPayload | undefined;
};

type UseLoadConfig = (props: Props) => Return;

export const useLoadConfig: UseLoadConfig = ({ enabled = true, defaultCategory }) => {
	const [currentCategory, setCategory] = useState<RealEstateGetConfigPayload | undefined>(
		defaultCategory || undefined
	);
	const loadConfig = () => api.realEstate.getConfig(currentCategory as RealEstateGetConfigPayload);

	useEffect(() => {
		if (!isEqual(defaultCategory, currentCategory)) {
			setCategory(defaultCategory);
		}
	}, [defaultCategory]);

	const { data, isFetching, refetch, remove } = useQuery([DEFAULT_QN, currentCategory], loadConfig, {
		enabled: enabled && !!currentCategory,
	});

	const removeData = () => {
		setCategory(undefined);
		remove();
	};

	return {
		objectConfig: data?.data,
		isFetching,
		refetch,
		remove,
		removeData,
		setCategory,
		currentCategory,
	};
};
