import { RealEstateFilterField, MaskFieldName } from "../../../../../../types";
import { UseFormRegister, UseFormSetValue, useWatch, Control, UseFormGetValues } from "react-hook-form";
import cn from "classnames";

import { DoubleInput, InfoRow, Select } from "../../../../index";
import { useMask } from "./helpers";

import styles from "./index.module.scss";

type Props = {
	field: RealEstateFilterField;
	register: UseFormRegister<any>;
	name: string;
	setValue: UseFormSetValue<any>;
	control: Control<any>;
	getValues: UseFormGetValues<any>;
	disabled?: boolean;
	mask?: MaskFieldName;
};

// Autogenerated double input component.
// Masks, placeholders and size generates automatically.
export const RangeNumber = ({
	field,
	register,
	name,
	setValue,
	control,
	getValues,
	disabled,
	mask,
}: Props): JSX.Element => {
	const {
		dataType: { maxSymbols, splitter: dataTypeSplitter, unit: dataTypeUnit },
	} = field;
	const placeholderFirst = maxSymbols ? "0".repeat(maxSymbols) : "От";
	const placeholderSecond = maxSymbols ? "0".repeat(maxSymbols) : "До";
	const splitter = dataTypeSplitter?.length && dataTypeSplitter !== "-" ? dataTypeSplitter : undefined;

	const getMinWidth = () => {
		if (Number(maxSymbols) === 3) {
			return 48;
		}
		if (Number(maxSymbols) === 4) {
			return 55;
		}
		if (Number(maxSymbols) === 5) {
			return 83;
		}
		if (Number(maxSymbols) === 6) {
			return 100;
		}

		return 20;
	};
	const formatUnitsOptions = (units) =>
		units.map((unit) => ({
			value: unit.k,
			label: unit.name,
		}));

	const nameFrom = `${name}From`;
	const nameTo = `${name}To`;
	const nameSelect = `${name}Units`;

	const watcherSelect = useWatch({
		control,
		name: nameSelect,
		defaultValue:
			getValues(nameSelect) ||
			(dataTypeUnit && dataTypeUnit[0] && dataTypeUnit[0].k ? dataTypeUnit[0].k : ""),
	});

	const watcherInputFrom = useWatch({
		control,
		name: nameFrom,
		defaultValue: getValues(nameFrom) || "",
	});

	const watcherInputTo = useWatch({
		control,
		name: nameTo,
		defaultValue: getValues(nameTo) || "",
	});

	const units = field?.dataType.unit?.length;

	const { inputsMask } = useMask({ mask, maxSymbols });

	return (
		<InfoRow aspect={{ label: 8, content: 7.3 }} label={field.label}>
			<div className={styles.wrapper}>
				<div className={styles.rangeContent}>
					<DoubleInput
						register={register}
						size="unFixed"
						variant="noPadding"
						names={[nameFrom, nameTo]}
						placeholders={[placeholderFirst, placeholderSecond]}
						separator={splitter}
						masks={inputsMask}
						setValue={setValue}
						value={[watcherInputFrom, watcherInputTo] as [string, string]}
						minWidth={getMinWidth()}
						maxWidth={120}
						disabled={disabled}
					/>
					{!!(field?.dataType.unit?.length && units) && (
						<>
							&nbsp;
							{units > 1 ? (
								<div style={{ width: 100 }}>
									<Select
										options={formatUnitsOptions(dataTypeUnit)}
										name={nameSelect}
										register={register}
										size="small"
										defaultValue={watcherSelect}
										setValue={setValue}
										classNames={{ selected: styles.unitSelect }}
										disabled={disabled}
									/>
								</div>
							) : (
								<span className={styles.unit}>{dataTypeUnit?.[0]?.name}</span>
							)}
						</>
					)}
					<div
						className={cn(styles.unFixedDoubleInput, {
							[styles.withSelect]: dataTypeUnit?.length,
						})}
					/>
				</div>
			</div>
		</InfoRow>
	);
};
