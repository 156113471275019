import styles from "./index.module.scss"
import { Contact, ReverseContactTypeMap, UserListItem } from "../../../../types";
import { ContactStrap } from "../../../pages/Contacts/ContactStrap/ContactStrap";
import { ReactComponent as CallIn } from "../../../../assets/icons/callIn.svg";
import { ReactComponent as CallOut } from "../../../../assets/icons/callOut.svg";
import { ReactComponent as CallMissed } from "../../../../assets/icons/callMissed.svg";
import { ReactComponent as CallBusy } from "../../../../assets/icons/callBusy.svg";
import { ReactComponent as Chat } from "../../../../assets/icons/iconChat.svg";
import { formatDateWithTime } from "../../../../utils/date";
import cn from "classnames";
import { ReactComponent as Telegramm } from "../../../../assets/icons/telegram.svg";
import { ReactComponent as Whatsapp } from "../../../../assets/icons/whatsapp.svg";
import { capitalizeFirstLetter, formatPhone } from "../../../../utils/formatters";
import { translateContactType } from "../../../pages/ContactCard/ContactInfo/components/General/components";
import { useDeleteContactsPreApp } from "../../../../service/api/mutations/pre-application-template";
import { PopupMenu } from "../../Popup";
import { ReactComponent as IconCancel } from "../../../../assets/icons/iconClear.svg";
import { ReactComponent as OpenInNew } from "../../../../assets/icons/openInNewBlue.svg";
import { useDeleteContacts } from "../../../../service/api/mutations";
import { useDeleteContactModal } from "./delete-contact-modal/useDeleteContactModal";
import { Avatar } from "../../Avatar";
import { ReactComponent as BuildingsIcon } from "../../../../assets/icons/iconResponsibleBig.svg";
import { useState } from "react";
import { useIsTelegram } from "../../../../utils/telegram/useIsTelegram";

type Props = {
  contact: Contact | UserListItem;
  mini?: boolean;
  backgroundWhite?: boolean
  canOpenNew?: boolean
  atList?: boolean
  classNames?: {
    root?: string
  }
  refetch?: () => void;
  ondelete?: (id: string) => void
  canDelete?: boolean
  withoutDots?: boolean
  withoutLinks?: boolean
  atAC?: boolean
  atGP?: boolean
  withAvatar?: boolean
  atSelect?: boolean
  allowOpenInNewTab?: boolean
}

export const CardContact = (
  {
    contact,
    mini,
    backgroundWhite,
    canOpenNew,
    atList,
    classNames,
    refetch,
    ondelete,
    canDelete,
    withoutDots,
    withoutLinks,
    atAC,
    atGP,
    withAvatar,
    atSelect,
    allowOpenInNewTab,
  }: Props): JSX.Element => {

  const {
    iAmResponsibleUser,
    responsibleUser,
    relatedKinds,
    phones,
    lastCommunication,
    phone
  } = contact

  const secondName = capitalizeFirstLetter(contact?.secondName || "");
  const name = capitalizeFirstLetter(contact?.name || "");
  const lastName = capitalizeFirstLetter(contact?.lastName || "");
  const pseudonym = capitalizeFirstLetter(contact?.pseudonym || "");
  const type = capitalizeFirstLetter(translateContactType(contact?.type) || "")

  const FIO = `${pseudonym} ${lastName} ${name} ${secondName}`;

  const getIcon = (call): JSX.Element => {
    if (!call) {
      return <></>
    }
    if (call?.status && call?.status === "success") {
      if (call?.type && call?.type === "in") {
        return <CallIn/>
      }
      if (call?.type && call?.type === "out") {
        return <CallOut/>
      }
      return <Chat/>
    }
    if (call?.status && call?.status === "missed") {
      return <CallMissed/>
    }
    return <CallBusy/>
  }
  const {executeUrl} = useIsTelegram()

  const openInNewTab = () => {
   executeUrl(`/leadsanddeals?tab=contacts&contactId=${(contact as Contact).userId || (contact as UserListItem).id}`)
  }

  const deleteAppId = ((contact as UserListItem).preApplicationId ? (contact as UserListItem).preApplicationId : (contact as UserListItem).applicationId)

  const {mutate: mutatePreapp} = useDeleteContactsPreApp({onSuccess: refetch})
  const {mutate: mutateApp} = useDeleteContacts({onSuccess: refetch})

  const action = (contact as UserListItem).preApplicationId
    ? mutatePreapp
    : mutateApp

  const handleDelete = () => {
    if (ondelete) {
      ondelete(contact.id)
    } else {
      // Надо
      // @ts-ignore
      action({applicationId: deleteAppId, id: contact.id, type: ReverseContactTypeMap[ contact.type ]})
    }
  }

  const {openDeleteContactModal} = useDeleteContactModal({onDelete: handleDelete})

  const menu = [
    {
      label: "Открыть в новой вкладке",
      icon: <OpenInNew/>,
      action: openInNewTab,
    },
    {
      label: "Открепить",
      className: styles.red,
      icon: <IconCancel/>,
      action: () => {
        openDeleteContactModal()
        if (refetch) {
          setTimeout(() => {
            refetch()
          }, 1000)
        }
      },
    },
  ]

  const {avatar} = (contact as UserListItem)
  const actualMenu = canDelete ? menu.slice(0, 1) : menu
  const [isImageError, setIsImageError] = useState(false);
  const onImageError = () => setIsImageError(true);



  return (
    <div className={cn(styles.root, classNames?.root,
      {
        [ styles.backgroundWhite ]: backgroundWhite,
        [ styles.mini ]: mini,
        [ styles.withStrap ]: relatedKinds,
        [ styles.atAC ]: atAC,
        [ styles.atGPmini ]: atGP,
        [ styles.atSelect ]: atSelect,
      })
    }
    onClick={() => {
      if (allowOpenInNewTab) {
        openInNewTab()
      }
    }}
    >
      {withAvatar &&
        <div className={cn(styles.img, {[ styles.miniImg ]: mini})}>
          {avatar && !isImageError ? (
            <img onError={onImageError} alt="object" src={avatar}/>
          ) : (
            <div className={cn(styles.noImg, {[ styles.miniImg ]: mini})}>
              <BuildingsIcon/>
            </div>
          )}
        </div>
      }
      <div className={styles.column}>
        <div className={styles.rowBetween}>
          <div className={styles.title}>
            {FIO}
          </div>
          <div className={styles.row}>
            <div>
              {getIcon(lastCommunication)}
            </div>
            {lastCommunication &&
              <div className={styles.date}>
                {formatDateWithTime(lastCommunication?.start ?? new Date, true, true)}
                <br/>
                {formatDateWithTime(lastCommunication?.start ?? new Date, true, false)}
              </div>
            }
            <div>
              {!withoutDots &&
                <div className={styles.popup}>
                  <PopupMenu
                    menuItems={actualMenu}
                    options={{primaryOpen: "left"}}
                    classNames={{
                      card: styles.popupCard
                    }}
                  />
                </div>}
            </div>
          </div>
        </div>
        <div className={styles.rowBetween}>
          <div className={styles.row}>
            {!withoutLinks &&
              <>
                <div onClick={(event) => {
                  event?.stopPropagation();
                  executeUrl(`https://t.me/+${contact && phones ? phones[ 0 ].phone : phone}`)
                }}>
                  <Telegramm/>
                </div>
                <div style={{marginLeft: "8px"}} onClick={(event) => {
                  event?.stopPropagation();
                  executeUrl(`https://api.whatsapp.com/send?phone=${contact && phones ? phones[ 0 ].phone : phone}`)
                }}>
                  <Whatsapp/>
                </div>
              </>
            }
            {phones && phones.length > 0 &&
              <div className={styles.phone}>
                {formatPhone(String(phones[ 0 ].phone))}
              </div>}
            {phone && <div className={styles.phone}>
              {formatPhone(String(phone))}
            </div>}
            <div className={styles.type}>
              {type}
            </div>
          </div>
          <div className={cn(styles.responsible)}>
            {responsibleUser?.name && !iAmResponsibleUser && (
              <>
                {responsibleUser?.avatar
                  ? <img className={styles.responsibleLogo} src={responsibleUser?.avatar} alt="responsibleIcon.svg"/>
                  : <Avatar
                    imgSrc={responsibleUser?.avatar}
                    name={responsibleUser?.name}
                    lastName={responsibleUser?.lastName}
                    color="primary"
                    mini
                  />
                }
                <span>{`${responsibleUser?.lastName} ${responsibleUser?.name}`}</span>
              </>
            )}
            {iAmResponsibleUser &&
              <div className={styles.responsible}>
                <Avatar
                  imgSrc={responsibleUser?.avatar}
                  name={responsibleUser?.name}
                  lastName={responsibleUser?.lastName}
                  color="primary"
                  mini
                />
                <span>{`${responsibleUser?.lastName} ${responsibleUser?.name}`}</span>
              </div>
            }
          </div>
        </div>
        {relatedKinds &&
          <ContactStrap
            relatedKinds={relatedKinds}
            classNames={styles.strap}
            mini={mini}
            canOpenANewTab={canOpenNew}
            onClick={openInNewTab}
            atList={atList}
          />
        }
      </div>
    </div>
  )
}