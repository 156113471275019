import { useDispatch } from "react-redux";
import { openDrawer, openDrawerInDrawer } from "../../../../redux/modules/drawer/drawer.action";
import { ProductCard } from "../../../../../components/pages/product-card/ProductCard";
import { useQueryParams } from "../../../../../utils/hooks";
import { selectDevice, useSelect } from "../../../../redux/selectors";
import { FixedNavVerticalMenu } from "../../../../../components/UI";

type Return = {
  openProductDrawer: (officeId: string) => void;
};

type Props = {
  refetch?: () => void;
  atDrawerInDrawer?: boolean
};

type UseProductCard = (props: Props) => Return;

export const useOpenProductCard: UseProductCard = ({refetch, atDrawerInDrawer}) => {
  const dispatch = useDispatch();
  const {isMobile} = useSelect(selectDevice)

  const {removeQueryParam} = useQueryParams<{
    productId: string;
  }>([{name: "productId"}]);

  const productNav = {
    label: "Продукт",
    height: 160,
    title: "product"
  };

  const yNavigation: Omit<FixedNavVerticalMenu, "id" | "changeTab">[] = [productNav]

  const openProductDrawer = (productId: string) => {
    if (atDrawerInDrawer) {
      dispatch(openDrawerInDrawer(
        {
          children: <ProductCard productId={productId} refetch={refetch}/>,
          width: isMobile ? 500 : 800,
          yNavigation: {
            navigation: yNavigation,
            initialTab: 0
          },
          onClose: () => {
            removeQueryParam("productId")
          },
        }))
    } else {
      dispatch(
        openDrawer({
          children: <ProductCard productId={productId} refetch={refetch}/>,
          width: isMobile ? 500 : 800,
          yNavigation: {
            navigation: yNavigation,
            initialTab: 0
          },
          onClose: () => {
            removeQueryParam("productId")
          },
        })
      );
    }
  };

  return {
    openProductDrawer,
  };
};
