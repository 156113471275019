import { useDispatch } from "react-redux";

import { closeModal, openModal } from "../../../../service/redux/modules/modal/modal.action";
import { useDeleteTask } from "../../../../service/api/mutations";

type Props = {
	onSuccessDelete: () => void;
};

type Return = {
	openUnpinResult: (taskId: string) => void;
};

type UseDeleteTaskModal = (props: Props) => Return;

export const useDeleteTaskModal: UseDeleteTaskModal = ({ onSuccessDelete }) => {
	const dispatch = useDispatch();

	const { mutate: deleteTarget } = useDeleteTask({
		onSuccess: () => {
			onSuccessDelete();
			dispatch(closeModal());
		},
	});

	const handleUnpin = (id: string) => deleteTarget({ id });

	const openUnpinResult = (taskId: string) => {
		dispatch(
			openModal({
				title: "Вы хотите удалить задачу?",
				body: "",
				variant: "alert",
				alertIcon: "warning",
				alertButtonSubmit: {
					label: "Удалить",
					onClick: () => handleUnpin(taskId),
				},
				alertButtonDecline: {
					label: "Отменить",
					onClick: () => dispatch(closeModal()),
				},
			})
		);
	};

	return {
		openUnpinResult,
	};
};
