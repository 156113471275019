import { KindType, RealEstate } from "../../../../../../../../types";
import { CardObject, CardObjectMini, CardObjectProps } from "../../../../../../../UI";
import { useQueryParams } from "../../../../../../../../utils/hooks";
import { useOpenRealEstate } from "../../../../../../../../utils/objects/useOpenRealEstate";
import {
  useOpenObjectApplication,
  useOpenObjectPreApplication,
  useOpenParserObject
} from "../../../../../../../../service/servise";

type Props = {
  object: RealEstate;
  isParser: boolean;
  classNames?: {
    root?: string
  }
  refetchList?: () => void
  isMobile?: boolean
  isSdelka?: boolean
} & Omit<CardObjectProps, "clientObject" | "id">;

export const CardObjectWithPopupMenu = (
  {
    object,
    isParser,
    refetchList,
    classNames,
    isMobile,
    isSdelka,
    ...otherProps
  }: Props) => {
  const id = isParser ? object.id : (object.shortType.kind === KindType.APPLICATION ? object.applicationId : object.preApplicationId);
  const locationFrom = isParser ? "parser-list" : "regular-list";
  const {changeQueryParams} = useQueryParams<{ parserObjectId: string }>([{name: "parserObjectId"}])

  const openObject = useOpenRealEstate({
    locationFrom,
    options: {inNewTab: true},
  });

  const {openObjectApplication} = useOpenObjectApplication({atDrawerInDrawer: false})
  const {openObjectPreApplication} = useOpenObjectPreApplication({atDrawerInDrawer: false})
  const {openParserObject} = useOpenParserObject({atDrawerInDrawer: false})

  const onCardClick = () => {
    if (isParser) {
      changeQueryParams([{name: "parserObjectId", newValue: id}])
      openParserObject({
        parsedRealEstateId: id,
        hasMap: true,
      })
    } else if (object.shortType.kind === KindType.PREAPPLICATION) {
      openObjectPreApplication({
        applicationId: object.preApplicationId,
        whoIsClient: object.shortType.whoIsClient,
        refetchList,
        hasMap: true
      })
    } else {
      openObjectApplication({
        kind: object.shortType.kind,
        applicationId: object.applicationId,
        whoIsClient: object.shortType.whoIsClient,
        refetchParent: refetchList,
        hasMap: true
      })
    }

  };

  const onAuxClick = () => openObject(object);

  return (
    <>
      {isMobile ? <CardObjectMini
          id={id}
          objectData={object}
          onCardClick={onCardClick}
          onAuxClick={onAuxClick}
          isHovered
          withPrice
          isParser={isParser}
        />
        : <CardObject
          id={id}
          clientObject={object}
          cardBackground="white"
          onCardClick={onCardClick}
          onAuxClick={onAuxClick}
          options={{showApplication: true}}
          {...otherProps}
          variant="standard"
          classNames={{
            root: classNames?.root
          }}
          isSdelka={isSdelka}
        />}
    </>

  );
};
