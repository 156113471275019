import { Loader } from "@sdelka_crm/sdelka-crm-component-library";
import { forwardRef } from "react";
import cn from "classnames";

import { MainPhotoLabels } from "./components";
import { HEIGHT, WIDTH } from "./helpers";
import { GalleryGridItemProps } from "../../../types";
import { useObjectRotatePhoto } from "../../../service/api/mutations";

import { ReactComponent as PhotoPreviewIcon } from "../../../assets/icons/photo-preview.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { ReactComponent as RotateRightIcon } from "../../../assets/icons/rotate.svg";
import styles from "./index.module.scss";
import { toastError } from "../Toasts";

export const GalleryGridItem = forwardRef<HTMLButtonElement, GalleryGridItemProps>(
  (
    {
      id,
      index,
      active,
      clone,
      onRemove,
      onPhotoPreviewClick,
      withRotationButtons,
      changePhotoItself,
      style,
      img,
      options,
      isView,
      ...props
    }: // eslint-disable-next-line sonarjs/cognitive-complexity
      GalleryGridItemProps,
    ref
  ) => {
    if (withRotationButtons && !changePhotoItself) {
      throw new Error('"changePhotoItself" must be provided within "withRotationButtons"');
    }

    const {mutate: rotate, isLoading} = useObjectRotatePhoto({
      onSuccess: (res) => changePhotoItself?.(res.data),
      onError: () => toastError({text: "При повороте фото произошла ошибка"}),
    });

    const rotateLeft = () => rotate({photoId: id, rotation: "left"});
    const rotateRight = () => rotate({photoId: id, rotation: "right"});

    const {withoutRemove, withoutLabels, disableSort} = options || {};
    let computedWidth = img.width;
    let computedHeight = img.height;

    computedHeight = !computedHeight || computedHeight > HEIGHT ? HEIGHT : computedHeight;
    computedWidth = !computedWidth || computedWidth > WIDTH ? WIDTH : computedWidth;

    return (
      <>
        {img.src && <li
          className={cn(styles.Wrapper, active && styles.active, clone && styles.clone, {
            [ styles.withoutHoverEffects ]: disableSort,
          })}
          style={style}
        >
          <button type="button" ref={ref} className={styles.Page} data-id={id} {...props}>
            {index === 1 && !withoutLabels ? <MainPhotoLabels/> : null}
            <img
              style={{width: computedWidth, height: computedHeight}}
              className={styles.gridImg}
              alt={`${index} Изображение объекта`}
              src={img.src}
            />
            {isLoading && <Loader className={styles.loader}/>}
          </button>
          {!isLoading && (
            <div className={styles.buttons}>
              {(
                <button type="button" onClick={() => onPhotoPreviewClick ? onPhotoPreviewClick(img.src) : {}}>
                  <PhotoPreviewIcon/>
                </button>
              )}
              {withRotationButtons && (
                <>
                  <button type="button" onClick={rotateLeft}>
                    <RotateRightIcon style={{transform: "scaleX(-1)"}}/>
                  </button>

                  <button type="button" onClick={rotateRight}>
                    <RotateRightIcon/>
                  </button>
                </>
              )}
            </div>
          )}
          {!active && onRemove && !withoutRemove && !isLoading && !isView && (
            <button type="button" className={styles.Remove} onClick={() => onRemove(id)}>
              <CloseIcon className={styles.removeIcon}/>
            </button>
          )}
        </li>}
      </>
    );
  }
);
