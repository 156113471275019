import { IUserEditFormRequest, IUserEditFormResponse } from "../../../../../types";
import { useState } from "react";
import styles from "./index.module.scss"
import { ReactComponent as Plug } from "../../../../../assets/icons/employee-card-plug.svg";
import { formatPhone } from "../../../../../utils/formatters";
import { Control, FormState, useWatch } from "react-hook-form";
import { InfoRow, TransparentInput } from "../../../../UI";
import { Button, formatDate } from "@sdelka_crm/sdelka-crm-component-library";
import { Photo } from "./Photo";
import { UseLoadUserPositions } from "../../../../../service/api/queries/position/useLoadUserPositions";
import { UseFormRegister, UseFormSetValue } from "react-hook-form/dist/types/form";

type Props = {
  user?: IUserEditFormResponse;
  canEdit?: boolean;
  refetch?: () => void;
  register: UseFormRegister<IUserEditFormRequest>;
  control: Control<IUserEditFormRequest, any>
  formState: FormState<IUserEditFormRequest>
  setValue: UseFormSetValue<IUserEditFormRequest>;
  editMode: boolean
};

export const MainInfo = (
  {
    user,
    canEdit,
    refetch,
    register,
    control,
    formState,
    setValue,
    editMode
  }: Props) => {

  const {getPositionName} = UseLoadUserPositions({});
  const [avatar, setAvatar] = useState(user?.avatar)
  const handleSetAvatar = (photo: string) => {
    setAvatar(photo)
  }

  const nameWatcher = useWatch({
    control,
    name: "name",
    defaultValue: user?.name || "",
  })
  const secondWatcher = useWatch({
    control,
    name: "secondName",
    defaultValue: user?.secondName || "",
  })
  const lastWatcher = useWatch({
    control,
    name: "lastName",
    defaultValue: user?.lastName || "",
  })
  const emailWatcher = useWatch({
    control,
    name: "email",
    defaultValue: user?.email || "",
  })

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        {editMode
          ? <Photo user={user} canEdit={canEdit} refetchUser={refetch} setPhoto={handleSetAvatar}/>
          : <div className={styles.image}>
            {avatar
              ? <img src={avatar} alt="avatar"/>
              : <Plug/>
            }
          </div>
        }
        {editMode &&
          <div className={styles.editContent}>
            <InfoRow label="Фамилия">
              <TransparentInput
                className={styles.input}
                register={register}
                placeholder="Фамилия"
                withCleanUp={{setValue}}
                name="lastName"
                error={formState?.errors?.lastName?.message?.toString()}
                required
                value={lastWatcher}
              />
            </InfoRow>
            <InfoRow label="Имя">
              <TransparentInput
                className={styles.input}
                register={register}
                placeholder="Имя"
                name="name"
                withCleanUp={{setValue}}
                error={formState?.errors?.name?.message?.toString()}
                required
                value={nameWatcher}
              />
            </InfoRow>
            <InfoRow label="Отчество">
              <TransparentInput
                className={styles.input}
                register={register}
                placeholder="Отчество"
                name="secondName"
                withCleanUp={{setValue}}
                value={secondWatcher}
                error={formState?.errors?.secondName?.message?.toString()}
              />
            </InfoRow>
            <InfoRow label="E-mail">
              <TransparentInput
                className={styles.input}
                register={register}
                placeholder="e-mail"
                name="email"
                withCleanUp={{setValue}}
                value={emailWatcher}
                error={formState?.errors?.secondName?.message?.toString()}
              />
            </InfoRow>
          </div>
        }
        {!editMode &&
          <div className={styles.column} style={{height: "164px"}}>
              <div className={styles.column}>
                <div className={styles.rowBetween}>
                  <div className={styles.firstName}>
                    {user?.lastName}
                  </div>
                  {user?.position &&
                    <div className={styles.position}>
                      {getPositionName(user?.position.id) === "Агент в коворкинге" ? "Риэлтор Сделка ПРО" : getPositionName(user?.position.id)}
                    </div>
                  }
                </div>
                <div className={styles.name}>{`${user?.name} ${user?.secondName ?? ""}`}</div>
                <div
                  className={styles.phone}>{`${formatPhone(user?.phone ?? "")} ${user?.email ? user?.email : ""}`}</div>
              </div>
              <div className={styles.rowBetween}>
                <div className={styles.status}>
                  {user?.isPremium &&
                    <>
                      <div>Premium</div>
                      <div>{`до ${formatDate(user?.paidUntill ?? "")}`}</div>
                    </>
                  }
                </div>
                {user?.isVerified === false &&
                  <Button color="red">
                    Пройти верификацию
                  </Button>
                }
              </div>
            </div>
        }
      </div>
    </div>
  )
}