import { LeadInfo } from "./index";
import { Lead } from "../../../../../types";
import { useState } from "react";
import { api } from "../../../../../service/api";

type  Props = {
  lead: Lead
}

export const NewLeadInfo = ({lead}: Props) => {
  const [state, setState] = useState(lead)
  const [isLoading, setIsloading] = useState(false)

  const finalData = state

  const readLead = () => api.leads.readAndLock(lead.id).then((data) => {
    setState(data.data)
  });
  const ref = () => {
    setTimeout(() => {
      readLead()
      setIsloading(false)
    }, 1000)
    
    setIsloading(true)
  }

  return (
    <LeadInfo
        lead={finalData}
        refetch={ref}
        isLoading={isLoading}
      />
  )
}