import { AxiosResponse } from "axios";
import { UseMutateFunction, useMutation } from "react-query";
import { Lead } from "../../../../types";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/lead/create-sub-lead/DEFAULT_MN";

type Props = {
  onSuccess?: (data: AxiosResponse<Lead>) => void;
};

type Return = {
  mutate: UseMutateFunction<AxiosResponse<Lead>,
    unknown,
    {
      parentId: string;
    },
    unknown>;
  isLoading: boolean;

};

type UseCreateSubLead = (props: Props) => Return;

export const useCreateSubLead: UseCreateSubLead = ({onSuccess}) => {
  const {mutate, isLoading} = useMutation(DEFAULT_MN, {
    mutationFn: ({parentId}: { parentId: string }) => api.leads.createSubLead(parentId),
    onSuccess,
  });

  return {
    mutate,
    isLoading,
  };
};
