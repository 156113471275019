import { ReactNode } from "react";
import { Redirect } from "react-router";

import { UserAccountType } from "../../types";
import { useSelect, selectCurrentAccount } from "../redux/selectors";
import { paths } from "../../utils/paths";

type Props = {
	children: ReactNode;
	only: UserAccountType | UserAccountType[];
};

export const RoleGuard = ({ children, only }: Props): JSX.Element => {
	const { currentAccount } = useSelect(selectCurrentAccount);

	const checkUserType = (type: UserAccountType): boolean =>
		!!(currentAccount?.type !== type && (only === type || only?.includes(type)));

	if (checkUserType("singleUser")) {
		return <Redirect to={paths.dashboardPath} />;
		// eslint-disable-next-line
	} else if (checkUserType("estateAgencyUser")) {
		return <Redirect to={paths.dashboardPath} />;
	}

	return <div>{children}</div>;
};
