import { useOpenLead } from "../../../../../../LeadsAndDeals/helpers";
import { toastError, toastSuccess } from "../../../../../../../UI";
import { AxiosResponse } from "axios";
import { KindType, Lead } from "../../../../../../../../types";
import { useCreateLeadFromArchivedEstateObject } from "../../../../../../../../service/api/mutations";
import { useCloseDrawer, useOpenObjectApplication, useOpenObjectPreApplication } from "../../../../../../../../service/servise";

type Return = {
  handleGetFromArchive: (object: string) => void;
  isLoading: boolean;
};

type UseGetFromArchive = (applicationId?: string, preApp?: boolean, enabled?: boolean) => Return;

export const useGetFromArchive: UseGetFromArchive = (applicationId, preApp) => {
  const {handleOpenLead} = useOpenLead({
    refetch: () => {
    }
  })

  const onError = () => {
    toastError({text: "Не удалось создать лид"});
  };

  const {openObjectApplication} = useOpenObjectApplication({atDrawerInDrawer: true})
  const {openObjectPreApplication} = useOpenObjectPreApplication({atDrawerInDrawer: true})
  const {close} = useCloseDrawer({atDrawerInDrawer: false})

  const onSuccess = (res: AxiosResponse<Lead>) => {
    const {
      data: {id},
    } = res;

    close()
    toastSuccess({text: "Лид успешно создан"});
    handleOpenLead(id);

    setTimeout(() => {
      if (preApp) {
        openObjectPreApplication({
          applicationId: res.data.archivedEstateObject?.applicationId || res.data.archivedEstateObject?.preApplicationId || "",
          isFromLead: true
        })
      } else {
        openObjectApplication({
          applicationId: res.data.archivedEstateObject?.applicationId || res.data.archivedEstateObject?.preApplicationId || "",
          kind: KindType.APPLICATION,
        })
      }
    }, 200)
  };

  const {mutate, isLoading} = useCreateLeadFromArchivedEstateObject({
    onSuccess,
    onError,
  });

  const handleGetFromArchive = (objectId: string) => {
    if (!isLoading) {
      mutate(objectId);
    }
  };

  return {
    handleGetFromArchive,
    isLoading,
  };
};