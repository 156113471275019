import { ReactElement, ReactNode } from "react";
import { StagesType } from "../../../../types";
import { Banner, BannersSkeleton, StageErrors, Tabs } from "./components";
import { useChildrenComponent, useConditions, useDisplayStages, useSelectedTab } from "./helpers";

import styles from "./index.module.scss";

type TabStageProps = {
  children: ReactNode;
};

export const TabStage = ({children}: TabStageProps): JSX.Element => <div>{children}</div>;

type TabsStageProps = {
  children?: ReactElement | ReactElement[];
  stages: StagesType[];
  currentStage: number;
  onNextStage?: (nextStage: number) => void;
  onChange?: (val: number) => void;
  errorsLabel?: string[];
  isFetchingBlocks?: boolean;
  purchasedProductTitle?: string
};

const GREY_BACKGROUND_COLOR = "#C5C5C5";

// eslint-disable-next-line sonarjs/cognitive-complexity
export const TabsStage = (
  {
    onChange,
    children,
    stages,
    currentStage,
    isFetchingBlocks,
    onNextStage,
    errorsLabel,
    purchasedProductTitle,
  }: TabsStageProps): JSX.Element => {
  const {selectedTab, handleChangeStage, handleStageItemClick, handleReturnStage, goToCurrentStage} =
    useSelectedTab({
      currentStage,
      onNextStage,
      onChange,
      stages,
    });

  const {displayStages} = useDisplayStages({stages, selectedTab});

  const {isGrey, showPrevArrow, showNextArrow, showNextStageBtn, showLastStageBtn, showReturnBtn} =
    useConditions({
      currentStage,
      selectedTab,
      stages,
    });

  const getStyle = (stage: StagesType) => {
    let color = "white";

    if (stages[ selectedTab ]?.stageNumber === stage?.stageNumber) {
      color = isGrey ? GREY_BACKGROUND_COLOR : (stage?.background || "");
    }

    return {background: color};
  };

  const {currentChildren} = useChildrenComponent({children, selectedTab});

  return (
    <>
      <div className={styles.root}>
        <Banner
          getStyle={getStyle}
          stages={stages}
          selectedTab={selectedTab}
          handleChangeStage={handleChangeStage}
          isGrey={isGrey}
          showPrevArrow={showPrevArrow}
          showNextArrow={showNextArrow}
          showNextStageBtn={showNextStageBtn}
          showLastStageBtn={showLastStageBtn}
          currentStage={currentStage}
          showReturnBtn={showReturnBtn}
          handleReturnStage={handleReturnStage}
          goToCurrentStage={goToCurrentStage}
          purchasedProductTitle={purchasedProductTitle}
        />
        <Tabs
          getStyle={getStyle}
          currentStage={currentStage}
          isGrey={isGrey}
          selectedTab={selectedTab}
          displayStages={displayStages}
          handleStageItemClick={handleStageItemClick}
        />
      </div>
      {isFetchingBlocks ? (
        <BannersSkeleton/>
      ) : (
        <>
          <StageErrors errorsLabel={errorsLabel}/>
          {currentChildren}
        </>
      )}
    </>
  );
};
