import { useQuery } from "react-query";

import { api } from "../../index";
import { KindType } from "types";

const DEFAULT_QN = "sdelka/application/load-card-blocks/DEFAULT_QN";

type Props = {
	applicationId: string;
	stage: string;
	enabled: boolean;
	kind?:KindType
};

type Return = {
	data: any;
	isLoading: boolean;
	refetch: () => void;
	remove: () => void;
	isFetched: boolean;
	isFetching: boolean;
};

type UseLoadCardBlocks = (props: Props) => Return;

export const useLoadCardBlocks: UseLoadCardBlocks = ({ applicationId, stage, enabled, kind }) => {
	
	const loadCardBlocks = () => kind===KindType.APPLICATION 
		? api.application.getCardBlocks({ applicationId, stage })
		: api.deals.getCardBlocks({ applicationId, stage })
	const { data, refetch, remove, isLoading, isFetched, isFetching } = useQuery(
		[DEFAULT_QN, stage],
		loadCardBlocks,
		{
			enabled,
			keepPreviousData: true,
		}
	);

	return {
		data: data?.data,
		isLoading,
		refetch,
		remove,
		isFetched,
		isFetching,
	};
};
