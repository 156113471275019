import { CartResponse } from "../../../../../types";
import { Cart } from "../../../../../components/pages/Servises/cart";
import { ReactComponent as CartIcon } from "../../../../../assets/icons/Shopping_Cart.svg";
import { useDispatch } from "react-redux";
import { useQueryParams } from "../../../../../utils/hooks";
import { openDrawer } from "../../../../redux/modules/drawer/drawer.action";
import { FixedNavVerticalMenu } from "../../../../../components/UI";

type Props = {
  refetchTable: () => void
  cart?: CartResponse
  refetch: () => void
  isCoWorking?: boolean
}

export const useOpenCart = () => {
  const dispatch = useDispatch()
  const {removeQueryParam} = useQueryParams<{ cart: string }>([{name: "cart"}])

  const onCloseDrawer = () => {
    removeQueryParam(["cart"])
  }

  const openCart = (
    {
     refetch,
     refetchTable,
     cart,
     isCoWorking,
    }: Props) => {

    const cartNav = {
      label: <CartIcon/>,
      title: "cart",
      height: 90,
      query: {
        name: "cart",
        value: "true"
      }
    };

    const cartChildren = (
      <Cart
        refetchTable={refetchTable}
        refetch={refetch}
        cart={cart}
        isCoWorking={isCoWorking}
        isMobile
      />
    )

    const yNavigation: Omit<FixedNavVerticalMenu, "id" | "changeTab">[] = [cartNav]

    dispatch(openDrawer({
      children: cartChildren,
      width: 500,
      onClose: onCloseDrawer,
      yNavigation: {
        navigation: yNavigation,
        initialTab: 0
      },
    }))
  }

  return {
    openCart
  }
}