import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./index.module.scss";
import {
  setJwtToken,
  setStep,
  setVerificationCode,
} from "../../../../../service/redux/modules/registration-steps/reg-steps.action";
import {
  SmsCodeConfirmationResponse,
  useRegistration,
  useSmsCodeConfirmation,
} from "../../../../../service/api/mutations";
import { useTimer } from "../../../../../utils/hooks";
import { digitOnly } from "../../../../../utils/string";
import { RegistrationResponse } from "../../../../../types";
import { TokenService } from "service/api/services/inner/TokenService";
import { CodeInput } from "components/UI/CodeInput/CodeInput";
import { selectRegisterSteps, useSelect } from "service/redux/selectors";
import { toastError } from "../../../../UI";

export const EnterCodeRegistrationPage = (): JSX.Element => {
  const [sessionData, setSessionData] = useState<RegistrationResponse | null>(null);
  const token = TokenService.getCurrentToken()
  const {registrationData} = useSelect(selectRegisterSteps);
  const dispatch = useDispatch();
  const [timer, handleTimer] = useTimer(30);
  const [initialValue, setInitialValue] = useState("")
  const {getLastMutationCache} = useRegistration({});
  const onSmsCodeConfirmationSuccess = ({data}: SmsCodeConfirmationResponse) => {
    dispatch(setJwtToken(data.error.payload.token));
    if (token) {
      dispatch(setStep("personal-data"));
    }
  };

  const {mutate: confirmCode, isSuccess, error} = useSmsCodeConfirmation({
    onSuccess: onSmsCodeConfirmationSuccess,
  });

  const errorMessage = (error as any)?.response?.data?.error.errorCode && "Неверный код"
  useEffect(() => {
    handleTimer();
  }, []);

  useEffect(() => {
    const lastRegisterMutation = getLastMutationCache();
    if (lastRegisterMutation?.state?.status === "success") {
      const response = lastRegisterMutation.state?.data as any;
      setSessionData(response.data);
    }
  }, []);

  const onSubmit = (values: { code: string }) => {

    dispatch(setVerificationCode(values.code))
    confirmCode({
      code: digitOnly(values.code),
      token: sessionData?.token ?? "",
    });
    if (!isSuccess) {
      setInitialValue("")
    }
  };
  useEffect(() => {
    if (isSuccess) {
      dispatch(setStep("personal-data"))
    }
    if (error) {
      toastError({text: "Неверный код", autoClose: 3000})
    }
  }, [onSubmit])

  const resetCode = async () => {
    try {
      const lastRegisterMutation = getLastMutationCache();
      const {data}: any = await lastRegisterMutation.execute();
      setSessionData(data as RegistrationResponse);
      handleTimer();
    } catch (err) {
      console.error("Error resetting code:", err);
    }
  };
  if (!sessionData) {
    <>Произошла ошибка, попробуйте снова</>;
  }

  return (
    <div className={styles.wrap}>
      <h1>Подтверждение телефона</h1>
      <p className={styles.message}>
        {registrationData?.phone && `Код отправлен на номер ${registrationData.phone.replace(/[()]/g, '')}`}
      </p>
      <CodeInput
        onSubmit={onSubmit}
        resetCode={resetCode}
        setBackStep={() => dispatch(setStep("first-step"))}
        timer={timer}
        initialValue={initialValue}
        errorMessage={errorMessage}
      />
    </div>
  );
};
