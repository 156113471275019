import styles from "./index.module.scss"
import { useLoadGuaranteePayment } from "../../../../service/api/queries";
import { GuaranteePaymentEnum, GuaranteePaymentStatusMap, WhoIsClient } from "../../../../types";
import cn from "classnames";
import { ReactComponent as AdminPanel } from "../../../../assets/icons/admin_panel_settings.svg";
import { CardContact } from "../../Card/card-contact/CardContact";
import { Plug } from "../helpers";
import { RadioButton, TransparentInput } from "../../Form";
import { FieldErrors, FieldValues, useForm, useWatch } from "react-hook-form";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { InfoRow } from "../../InfoRow";
import { CalendarInPopup } from "../../Calendar";
import { createNumberMask } from "text-mask-addons";
import { useCreateGuaranteePayment } from "../../../../service/api/mutations";
import { useOpenSelectContactModal } from "./select-contact-modal/useOpenSelectContactModal";
import * as yup from "yup";
import { commonErrors } from "../../../../utils/errors";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";


type Props = {
  applicationId: string
  isMobile: boolean
  refetch: () => void
  whoIsClient?: WhoIsClient
}

const termsOptions = [
  {label: "Возвратный", value: "refundable"},
  {label: "Невозвратный", value: "nonRefundable"},
]

export const GuaranteePayment = (
  {
    applicationId,
    isMobile,
    refetch,
    whoIsClient
  }: Props) => {
  const {guaranteePayment, refetch: refetchGP} = useLoadGuaranteePayment({applicationId})
  const {createGuaranteePayment} = useCreateGuaranteePayment({onSuccess: () => {
      refetch()
      refetchGP()
    }})
  const contactLabel = whoIsClient === "seller" ? "Продавец" : "Покупатель"

  const schema = yup.object().shape({
    activeFrom: yup.date().required(commonErrors.required),
    activeTo: yup.date().min(yup.ref("activeFrom"), "Неверная дата").required(commonErrors.required),
    sum: yup.string().required(commonErrors.required),
  })

  const {control, register, setValue, handleSubmit, getValues, formState} = useForm({
    resolver: yupResolver(schema)
  })

  const {openSelectContactModal} = useOpenSelectContactModal({
    applicationId, refetchList: () => {
      refetch()
      refetchGP()
    }
  })

  const mask = createNumberMask({
    prefix: "",
    thousandsSeparatorSymbol: " ",
  })

  const [term, setTerm] = useState<string | number>(termsOptions[ 0 ].value)
  const [guaranteeSum, setGuaranteeSum] = useState<string | undefined>(undefined)

  const sumWatcher = useWatch({
    control,
    name: "sum",
    defaultValue: guaranteePayment?.sum || ""
  })

  const onSubmit = () => {
    const payload = {
      activeFrom: getValues("activeFrom"),
      activeTo: getValues("activeTo"),
      sum: Number(String(sumWatcher).replaceAll(" ", "")),
      refundTerms: String(term) as 'refundable' | 'nonRefundable',
    }

    if (guaranteePayment?.contact) {
      createGuaranteePayment({
        applicationId,
        payload
      })
    } else {
      openSelectContactModal(payload)
    }
  }

  const [error, setError] = useState<FieldErrors<FieldValues> | undefined>(undefined)

  const {errors} = formState

  useEffect(() => {
    setError(errors)
  }, [errors])

  useEffect(() => {
    setGuaranteeSum(guaranteePayment?.sum)
  }, [guaranteePayment])

  return (
    <>
      {guaranteePayment &&
        <div className={styles.root}>
          <div className={styles.title}>
            Гарантийный платеж
          </div>
          <div className={styles.content}>
            <div className={styles.headerRow}>
              <div className={cn(styles.mainIcon,
                {[ styles.statusInactive ]: guaranteePayment.status === "inactive"},
                {[ styles.statusActive ]: guaranteePayment.status === "active"},
              )}>
                <AdminPanel/>
              </div>
              <div className={styles.mainDescription}>
                <div className={styles.statusDescription}>
                  {GuaranteePaymentStatusMap[ guaranteePayment.status ]}
                </div>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.subtitle}>
                {contactLabel}
              </div>
              {guaranteePayment.contact
                ? <CardContact contact={guaranteePayment.contact} withoutLinks withoutDots mini={isMobile}
                               atGP={isMobile}/>
                : <Plug plugText="Покупатель пока не найден" mini={isMobile} classNames={styles.plug} atGP/>
              }
              <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <InfoRow label="Действует с  |  до" classNames={{root: styles.infoRow}} aspect={{label: 4, content: 9}}>
                  <div className={styles.row}>
                    <div className={styles.calendar}>
                      <CalendarInPopup
                        form={{
                          name: "activeFrom",
                          register,
                          setValue,
                          getValues,
                        }}
                        defaultDate={guaranteePayment.activeFrom === undefined ? undefined : new Date(guaranteePayment.activeFrom)}
                        disabled={guaranteePayment.status === GuaranteePaymentEnum.active}
                      />
                    </div>
                    <div className={styles.separator}>|</div>
                    <div className={styles.calendar}>
                      <CalendarInPopup
                        form={{
                          name: "activeTo",
                          register,
                          setValue,
                          getValues,
                        }}
                        defaultDate={guaranteePayment.activeTo === undefined ? undefined : new Date(guaranteePayment.activeTo)}
                        disabled={guaranteePayment.status === GuaranteePaymentEnum.active}
                      />
                    </div>
                  </div>
                  {(error?.activeFrom || error?.activeTo) &&
                    <div className={styles.error}>{error?.activeTo?.message?.toString()}</div>}
                </InfoRow>
                <InfoRow label="Сумма платежа" classNames={{root: styles.infoRow}}  aspect={{label: 4, content: 9}}>
                  <TransparentInput
                    placeholder="Указать ₽"
                    name="sum"
                    mask={mask}
                    setValueForMask={setValue}
                    register={register}
                    required
                    value={guaranteeSum}
                    onChange={(value) => {
                      setGuaranteeSum(value)
                    }
                    }
                    disabled={guaranteePayment.status === GuaranteePaymentEnum.active}
                  />
                  {error?.sum && <div className={styles.error}>{error?.sum?.message?.toString()}</div>}
                </InfoRow>
                <div className={styles.termsRow}>
                  <div className={styles.terms}>
                    Условия
                  </div>
                  <RadioButton
                    name="terms"
                    options={termsOptions}
                    control={control}
                    background="gray"
                    backgroundButtons="white"
                    separate
                    handleOnChange={(value) => {
                      if (guaranteePayment.status !== GuaranteePaymentEnum.active) {
                        setTerm(value)
                      }
                    }
                    }
                    currentValue={term}
                    disabled={guaranteePayment.status === GuaranteePaymentEnum.active}
                    settings={{
                      preventOnChange: guaranteePayment.status === GuaranteePaymentEnum.active,
                      disabledOptional: guaranteePayment.status === GuaranteePaymentEnum.active
                    }}
                  />
                </div>

                <div className={styles.footer}>
                  {guaranteePayment.status === GuaranteePaymentEnum.inactive &&
                    <Button
                      type="submit"
                    >
                      Сформировать
                    </Button>}
                </div>
              </form>
            </div>
          </div>
        </div>
      }
    </>
  )
}