import { KindType } from "types";
import { useDispatch } from "react-redux";
import { ApplicationCard } from "../../../components/pages/Application";
import { changeCurrentTab, openDrawer } from "../../../service/redux/modules/drawer/drawer.action";
import { PreApplicationTemplate } from "components/pages/Application/PreApplicationTemplate";
import { useQueryParams } from "../helpers";
import { FixedNavVerticalMenu } from "../../../components/UI";

type Return = {
  handleOpenApplicationCard: (props: HandleOpenApplicationCardPayload) => void;
  kind?: KindType
};

type Props = {
  refetchList?: () => void
}

type UseOpenApplicationInDrawer = ({refetchList}: Props) => Return;

type HandleOpenApplicationCardPayload = {
  id: string;
  kind?: KindType;
  beforeOpen?: () => void;
  afterClose?: () => void;
  refetch?: () => void;
  width?: number;
  yNavigation?: {
    navigation: Omit<FixedNavVerticalMenu, "id" | "changeTab">[];
    initialTab?: number | undefined;
  };
  onFirstLoad?: () => void;
};

export const useOpenApplicationInDrawer: UseOpenApplicationInDrawer = ({refetchList}: Props) => {
  const dispatch = useDispatch();

  const {
    changeQueryParams,
    removeQueryParam
  } = useQueryParams<{ preApplicationId: string, applicationId: string, dealId: string, mode: string }>(
    [{name: "preApplicationId"}, {name: "applicationId"}, {name: "dealId"}, {name: "mode"}]);

  const handleOpenApplicationCard = (
    {
      id,
      kind,
      beforeOpen,
      afterClose,
      refetch,
      width = 800,
      yNavigation,
      onFirstLoad,
    }: HandleOpenApplicationCardPayload) => {

    if (beforeOpen) {
      beforeOpen();
    }

    const handleChangeTab = (newName: string, newTab: string) => changeQueryParams([{name: newName, newValue: newTab}]);
    const onTab = (newTabIndex: number) => {
      dispatch(changeCurrentTab({newCurrentTab: newTabIndex}));
    };
    if (kind === KindType.APPLICATION) {
      handleChangeTab("applicationId", id)
      dispatch(
        openDrawer({
          children: [
            <ApplicationCard
              applicationId={id}
              refetch={refetch}
              onFirstLoad={onFirstLoad}
              onTab={onTab}
              kind={KindType.APPLICATION}
            />,
          ],
          width,
          onClose: () => {
            removeQueryParam("mode")
            if (afterClose) {
              afterClose();
            }
          },
          yNavigation,
        })
      );
    }
    if (kind === KindType.DEAL) {
      handleChangeTab("dealId", id)
      dispatch(
        openDrawer({
          children: [
            <ApplicationCard
              applicationId={id}
              refetch={refetch}
              onFirstLoad={onFirstLoad}
              onTab={onTab}
              kind={KindType.DEAL}/>,
          ],
          width,
          onClose: () => {
            removeQueryParam("mode")
            if (afterClose) {
              afterClose();
            }
          },
          yNavigation,
        })
      );
    }
    if (kind === KindType.PREAPPLICATION) {
      handleChangeTab("preApplicationId", id)
      if (id) {
        dispatch(
          openDrawer({
            children: [
              <PreApplicationTemplate applicationId={id} isFromLead={false}
                                      refetchList={refetchList}/>
            ],
            width,
            onClose: () => {
              removeQueryParam("mode")
              if (afterClose) {
                afterClose();
              }
            },
            yNavigation,
          })
        );
      }
    }
  };

  return {
    handleOpenApplicationCard,
  };
};

