import { useDispatch } from "react-redux";

import styles from "./index.module.scss"
import { selectDevice, useSelect } from "../../../../../service/redux/selectors";
import { openModal } from "../../../../../service/redux/modules/modal/modal.action";
import { SelectContactModal } from "./SelectContactModal";
import { CreateGuaranteePayment } from "../../../../../types";


type Props = {
  applicationId: string
  refetchList: () => void

}

export const useOpenSelectContactModal = (
  {
    applicationId,
    refetchList,

  }: Props) => {
  const dispatch = useDispatch()
  const {isMobile} = useSelect(selectDevice)
  const width = isMobile ? 500 : 800

  const openSelectContactModal = (payloadTerms: CreateGuaranteePayment) => {
    dispatch(
      openModal({
        width,
        contentClass: styles.root,
        classNames:{
          contentBody: styles.root,
          contentWrapper: isMobile ? styles.modal : styles.wrapper,
          buttonClose: isMobile ? styles.modalClose : ""
        },
        body:
        <SelectContactModal
          applicationId={applicationId}
          refetchList={refetchList}
          isMobile={isMobile}
          payloadTerms={payloadTerms}
        />
      })
    )
  }

  return {
    openSelectContactModal
  }
}