import { ProductTypeEnum } from "../cart";
import { SelectOption } from "../inputs";
import { ApplicationInListV2 } from "../application";
import { Contact } from "../contact";
import { Task } from "../task";
import { User } from "../user";

export enum CategoryEnum {
  service = 'service',
  onlineAds = 'onlineAds',
  offlineAds = "offlineAds",
  premium = "premium"
}

export const servicesCategory: Record<CategoryEnum, string> = {
  service: "Услуги",
  onlineAds: "Онлайн реклама",
  offlineAds: "Офлайн реклама",
  premium: "Premium"
}

export type ProductTile = {
  id: string
  photo: string
  title: string
  price: number
  priceWithDiscount?: number
  isInCart?: boolean
  productType: ProductTypeEnum,
  category: CategoryEnum
  tariffOptions: SelectOption[]
  tariffLabel: string
}

export type Product = ProductTile & {
  description?: string | TrustedHTML
}

export type PurchasedProduct = Product & {
  isPurchased?: true
  availableAmount?: number
  tariff?: string
}

export type ProductListResponse = {
  data: ProductTile[]
  total: number
}

export enum AppliedProductStatusEnum {
  inProgress = "inProgress",
  success = "success",
  notPaid = "notPaid",
  atDisput = "atDisput"
}

export type ProductOrder = {
  id: string
  shortId: string
  applicationShortId
  applicationId
  application: ApplicationInListV2
  createdAt: Date
  description: string
  executorUser: User
  task: Task
  stages: {
    "name": string,
    "order": string,
    "background": string,
    "image": string,
    "title": string,
    "stageNumber": number
  }[]
  stage: string
  stageId: string
  stageNo: number
  purchasedProductTitle: string
  status: AppliedProductStatusEnum
  paymentUrl?: string
  chatUrl?: string
}

export type AppliedProduct = PurchasedProduct & {
  status?: AppliedProductStatusEnum
  createdAt?: Date
  applicationTitle?: string
}

export const appliedProductStatus: Record<AppliedProductStatusEnum, string> = {
  success: "Исполнено",
  inProgress: "В процессе",
  atDisput: "На диспуте",
  notPaid: "Не оплачен",
}

export type PurchasedProductsResponse = {
  data: PurchasedProduct[]
  total: number
}

export type AvailableToPurchaseResponse = {
  data: Product[]
  total: number
}

export type AppliedProductsResponse = {
  data: AppliedProduct[]
  total: number
}

export type ProductsBuyHistoryItem = {
  id: string
  productName: string
  purchaseDate: Date
  price: number
  tariff: string
}

export type ProductsBuyHistoryResponse = {
  data: ProductsBuyHistoryItem[]
  total: number
}
