import { useForm } from "react-hook-form";
import styles from "./index.module.scss";
import { Block } from "./components/Block";
import { ApplicationCardResponse } from "types";

type Props = {
  id: string;
  block: any;
  application?: ApplicationCardResponse
};

export const DealCharacteristics = (
  {
    id,
    block,
    application
  }: Props): JSX.Element => {

  const {
    control,
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState,
    clearErrors,
    setError,
  } = useForm<any>({
    defaultValues: application,
  });
  const form = {
    control,
    register,
    setValue,
    getValues,
    watch,
    clearErrors,
    setError,
    formState,
    reset,
    handleSubmit,
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {block?.title}
      </div>
      <div className={styles.blocks}>
        {block?.blocks && block.blocks.map(((el, index) =>
            <ul key={index}>
              <li>
                <div>
                  <Block
                    id={id}
                    form={form}
                    blockName={el.blockName}
                    editFields={el.editFields}
                    viewFields={el.viewFields}
                  />
                </div>
              </li>
            </ul>
        ))}
      </div>
    </div>
  );
};
