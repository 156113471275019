import cn from "classnames";
import { TaskStatusEnum, TaskStatusVariant } from "../../../../types";

import { StatusBadge, StatusIcon } from "./components";

import styles from "./index.module.scss";

type Props = {
	status: TaskStatusVariant;
	classNames?: {
		root?: string;
		icon?: string;
	};
	isRework?: boolean;
	isSuccessFinished?: boolean;
	deadline?: Date;
	withBadge?: boolean;
};

export const TagTaskStatus = ({
	status,
	classNames,
	isRework,
	isSuccessFinished,
	deadline,
	withBadge,
}: Props): JSX.Element => {
	const isDeadlineCrossed = new Date() > new Date(deadline ?? "");

	const pickColor = () => {
		if (isRework) return styles.reload;

		switch (status) {
			case TaskStatusEnum.inWork:
				return isDeadlineCrossed ? styles.red : styles.green;

			case TaskStatusEnum.failed:
				return styles.warn;

			case TaskStatusEnum.done:
				return isSuccessFinished ? styles.green : styles.cancel;

			default:
				return "";
		}
	};

	const color = pickColor();

	return (
		<div className={cn(styles.root, color, classNames?.root)}>
			<StatusIcon
				status={status}
				isRework={isRework}
				isSuccessFinished={isSuccessFinished}
				className={classNames?.icon}
			/>

			{withBadge && (
				<StatusBadge
					status={status}
					isRework={isRework}
					isSuccessFinished={isSuccessFinished}
					deadline={deadline}
				/>
			)}
		</div>
	);
};
