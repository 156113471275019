import { RefetchOptions } from "react-query";
import { CollectionObject, SampleType, AddProps, RemoveProps } from "../../../../../../../../types";

import { useCompilation, useCompare } from "./helpers";
import { useSampleType } from "../../../../../../../../utils/hooks";

type Props = {
	isCompilation: boolean;
	isCompare: boolean;
	sampledApplicationId: string;
};

type Return = {
	objects: CollectionObject[];
	refetch: (options?: RefetchOptions | undefined) => any;
	onAdd: AddProps;
	onRemove: RemoveProps;
};

type UseSample = (props: Props) => Return;

type SampleObject = [
	CollectionObject[],
	(options?: RefetchOptions | undefined) => any,
	AddProps,
	RemoveProps
];
export const useSample: UseSample = ({ sampledApplicationId, isCompare, isCompilation }) => {
	const { sampleType } = useSampleType({ isCompare, isCompilation });

	const { compilationObjects, refetchCompilation, onAddCompilation, onDeleteCompilation } = useCompilation({
		sampledApplicationId,
		isCompilation,
	});

	const { compareObjects, refetchCompare, onAddCompare, onDeleteCompare } = useCompare({
		sampledApplicationId,
		isCompare,
	});

	const SAMPLE_OBJECTS: Record<SampleType, SampleObject> = {
		compilation: [compilationObjects, refetchCompilation, onAddCompilation, onDeleteCompilation],
		compare: [compareObjects, refetchCompare, onAddCompare, onDeleteCompare],
		none: [[], () => {}, {}, {}],
	};
	const curType = SAMPLE_OBJECTS[sampleType()];

	const objects = curType[0];
	const refetch = curType[1];
	const onAdd = curType[2];
	const onRemove = curType[3];

	return {
		objects,
		refetch,
		onAdd,
		onRemove,
	};
};
