import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { IndividualInput } from "../../../../../../UI";
import { useCreateDepartment } from "../../../../../../../service/api/mutations";
import { commonErrors } from "../../../../../../../utils/errors";
import { Office } from "../../../../../../../types";

import { ReactComponent as PlusIcon } from "../../../../../../../assets/icons/plus.svg";
import styles from "./index.module.scss";

type Props = {
	refetchOffices: () => void;
	office: Office;
};

export const CreateDepartmentForm = ({ refetchOffices, office }: Props): JSX.Element => {
	const [isInCreation, setIsInCreation] = useState(false);

	const schema = yup.object().shape({
		name: yup.string().required(commonErrors.required).max(30, "Слишком длинное название"),
	});

	const { register, handleSubmit, setError, setValue, formState } = useForm({
		resolver: yupResolver(schema),
		defaultValues: { name: "" },
	});

	const { mutate, isLoading } = useCreateDepartment({
		onSuccess: () => {
			setIsInCreation(false);
			setValue("name", "");
			refetchOffices();
		},
		setError,
	});

	const createDepartment = (values: { name: string }) => {
		const payload = {
			name: values.name,
			officeId: office.id,
		};

		mutate(payload);
	};

	return (
		<>
			{isInCreation && (
				<form onSubmit={handleSubmit(createDepartment)} className={styles.form}>
					<IndividualInput
						name="name"
						defaultValue=""
						isLoading={isLoading}
						register={register}
						error={formState.errors.name?.message?.toString()}
						placeholder="Название отдела"
						options={{
							initialEditMode: true,
							stopCloseOnClickOutside: true,
						}}
					/>
				</form>
			)}

			<Button
				type="button"
				variant="text"
				Icon={!isInCreation ? PlusIcon : undefined}
				onClick={() => setIsInCreation((prev) => !prev)}
				classNames={{ root: styles.button }}
			>
				{isInCreation ? "Отменить создание" : "Создать отдел"}
			</Button>
		</>
	);
};
