import { ClusterType } from "../Map";
import { ClusterPoint } from "./ClusterPoint";
import useYMaps from "../map-utils/useYMaps";

type Props = {
	clusters?: ClusterType[];
	filterByMapClick?: (ids?: string[]) => void
};

/**
 * Компонент отображает кластеры недвижимости.
 */
export const Points = ({ clusters, filterByMapClick }: Props): JSX.Element => {
	const { mapInstance } = useYMaps();

	const clickOnCluster = (coords: number[], ids?: string[]) => {
		const zoom = mapInstance.getZoom();
		if (zoom < 13) {
			mapInstance.setCenter(coords, zoom + 2, {
				checkZoomRange: true,
			});
		} else if (filterByMapClick) {
				filterByMapClick(ids)
			}
	};

	return (
		<>
			{clusters?.map((cluster, index) => (
				<ClusterPoint
					key={`${index + 0}`}
					cluster={cluster}
					clickOnCluster={clickOnCluster}
				/>
			))}
		</>
	);
};
