import styles from "./index.module.scss";

type Props = {
	list: string[];
};

export const SelectList = ({ list }: Props): JSX.Element => (
	<div className={styles.root}>
		{list.map((item, index) => (
			<div
				// eslint-disable-next-line react/no-array-index-key
				key={`select-list-item-${index}`}
				className={styles.listItem}
			>
				{item}
			</div>
		))}
	</div>
);
