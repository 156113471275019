import { RealEstateFilter } from "../../types";

/**
 * Parse encoded filter as JSON and normalize fields
 *
 * Some fields of filter are better be to be normalized. For example, field "feedAds.endDate" is
 * frequently encoded from "Date" to "String", so here we reverse it back.
 */
export const parseStringEncodedRealEstateFilter = (encodedFilter: string): RealEstateFilter => {
	const filter = JSON.parse(encodedFilter);

	// TODO: Normalize all fields
	// https://tracker.yandex.ru/SDF-170
	if (filter.feedAds?.endDate) {
		filter.feedAds.endDate = new Date(filter.feedAds.endDate);
	}

	return filter;
};
