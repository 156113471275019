import { Control } from "react-hook-form";

import { Checkbox } from "../../../../../../../../index";

import styles from "./index.module.scss";

type Props = {
	name: string;
	control: Control<any>;
	allowedValues: any;
};

export const CheckboxGroup = ({ name, control, allowedValues }: Props): JSX.Element => {
	const values = allowedValues.map((allowed: any) => ({
		name: allowed,
		label: allowed,
	}));

	return (
		<div className={styles.root}>
			{values.map((group: any) => (
				<Checkbox key={group.name} name={`${name}.${group.name}`} control={control} label={group.label} />
			))}
		</div>
	);
};
