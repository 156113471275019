import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, formatDate } from "@sdelka_crm/sdelka-crm-component-library";

import { ContactTypeMap, CreateContactForm, CreateContactPayload, User, } from "../../../../../types";

import {
  AddressSelect,
  CalendarInPopup,
  Col,
  Container,
  InfoRow,
  Row,
  Select,
  TransparentInput,
} from "../../../../UI";
import { phoneMask } from "../../../../../utils/masks";
import { contactSchema } from "../../../../../utils/contact";

import styles from "./index.module.scss";

type Props = {
  onSubmit: (payload: CreateContactForm) => void;
  onCancel: () => void;
  creator: User | undefined;
  mainPhone: string;
};

export const ContactForm = ({onSubmit, onCancel, creator, mainPhone}: Props): JSX.Element => {
  const typeOptions = Object.keys(ContactTypeMap).map((key) => ({
    value: key,
    label: ContactTypeMap[ key ],
  }));

  const {register, handleSubmit, formState, setValue, getValues, control} = useForm({
    resolver: yupResolver(contactSchema),
    defaultValues: {
      name: "",
      secondName: "",
      lastName: "",
      pseudonym: "",
      mainPhone,
      extraPhone: "",
      type: "",
      otherType: "",
      email: "",
      dob: "",
      addressObject: {
        value: "",
        label: "",
      },
    },
  });

  const [phoneWatcher, extraPhoneWatcher, addressWatcher, typeWatcher] = [
    useWatch({
      control,
      name: "mainPhone",
      defaultValue: mainPhone.slice(1, 12),
    }),
    useWatch({
      control,
      name: "extraPhone",
      defaultValue: "",
    }),
    useWatch({
      control,
      name: "addressObject",
      defaultValue: {
        value: "",
        label: "",
      },
    }),
    useWatch({
      control,
      name: "type",
      defaultValue: "",
    }),
  ];

  const createPayloadApi = (form: CreateContactPayload) => {
    const phones = [
      {
        phone: form.mainPhone,
        type: "main",
      },
    ];

    if (form.extraPhone) {
      phones.push({
        phone: form.extraPhone,
        type: "extra",
      });
    }

    const dob = form?.dob ? new Date(form.dob).toISOString() : "";

    const {
      name,
      secondName,
      lastName,
      email,
      type,
      otherType,
      addressObject,
      pseudonym
    } = form;

    const payload: CreateContactForm = {
      name,
      secondName,
      lastName,
      phones,
      email,
      type,
      otherType,
      dob,
      addressId: addressObject?.value,
      addressLabel: addressObject?.label,
      pseudonym
    };
    onSubmit(payload);
  };

  const creatorName = `${creator?.account?.lastName || ""} ${creator?.account?.name || ""}`;
  const today = formatDate(new Date());

  const showOtherType = typeWatcher === "other";

  return (
    <form onSubmit={handleSubmit(createPayloadApi)}>
      <Container>
        <Row>
          <Col xs={4}>
            <TransparentInput
              className={styles.input}
              placeholder="Фамилия"
              name="lastName"
              error={formState?.errors?.lastName?.message?.toString()}
              withCleanUp={{setValue}}
              register={register}
            />
          </Col>

          <Col xs={4}>
            <TransparentInput
              className={styles.input}
              register={register}
              placeholder="Имя"
              name="name"
              withCleanUp={{setValue}}
              error={formState?.errors?.name?.message?.toString()}
            />
          </Col>

          <Col xs={4}>
            <TransparentInput
              className={styles.input}
              register={register}
              placeholder="Отчество"
              name="secondName"
              withCleanUp={{setValue}}
              error={formState?.errors?.secondName?.message?.toString()}
            />
          </Col>
          <Col xs={4} style={{paddingLeft: "0"}}>
            <TransparentInput
              className={styles.input}
              register={register}
              placeholder="Псевдоним"
              name="pseudonym"
              withCleanUp={{setValue}}
              error={formState?.errors?.pseudonym?.message?.toString()}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <InfoRow
              label="Телефон*"
              aspect={{label: 2, content: 3}}
              classNames={{root: styles.row}}
              labelFont="ubuntu"
            >
              <TransparentInput
                placeholder="Укажите телефон"
                name="mainPhone"
                mask={phoneMask}
                register={register}
                setValueForMask={setValue}
                value={phoneWatcher}
                error={formState?.errors?.mainPhone?.message?.toString()}
              />
            </InfoRow>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <InfoRow
              label="Доп."
              aspect={{label: 2, content: 3}}
              classNames={{root: styles.row}}
              labelFont="ubuntu"
            >
              <TransparentInput
                placeholder="Укажите телефон"
                name="extraPhone"
                mask={phoneMask}
                withCleanUp={{setValue}}
                register={register}
                setValueForMask={setValue}
                value={extraPhoneWatcher}
                error={formState?.errors?.extraPhone?.message?.toString()}
              />
            </InfoRow>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <InfoRow
              label="Тип"
              aspect={{label: 2, content: 3}}
              classNames={{root: styles.row}}
              labelFont="ubuntu"
            >
              <Select
                name="type"
                options={typeOptions}
                register={register}
                error={formState?.errors?.type?.message?.toString()}
                setValue={setValue}
                placeholder="Выберите тип"
                label="Выберите тип"
              />
            </InfoRow>

            {showOtherType && (
              <InfoRow
                label=" "
                aspect={{label: 2, content: 3}}
                classNames={{root: styles.row}}
                labelFont="ubuntu"
              >
                <TransparentInput
                  placeholder="Тип"
                  name="otherType"
                  withCleanUp={{setValue}}
                  register={register}
                  error={formState?.errors?.otherType?.message?.toString()}
                />
              </InfoRow>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <InfoRow
              label="E-mail"
              aspect={{label: 2, content: 3}}
              classNames={{root: styles.row}}
              labelFont="ubuntu"
            >
              <TransparentInput
                placeholder="Укажите E-mail"
                name="email"
                withCleanUp={{setValue}}
                register={register}
                error={formState?.errors?.email?.message?.toString()}
              />
            </InfoRow>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <InfoRow
              label="Дата рождения"
              aspect={{label: 2, content: 3}}
              classNames={{root: styles.row}}
              labelFont="ubuntu"
            >
              <div className={styles.calendar}>
                <CalendarInPopup
                  form={{name: "dob", register, setValue, getValues}}
                  error={formState?.errors?.dob?.message?.toString()}
                />
              </div>
            </InfoRow>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            {" "}
            <InfoRow
              label="Адрес"
              aspect={{label: 2, content: 3}}
              classNames={{root: styles.row}}
              labelFont="ubuntu"
            >
              <AddressSelect
                value={addressWatcher}
                name="addressObject"
                register={register}
                setValue={setValue}
                error={formState.errors.addressObject?.value?.message?.toString()}
                classNames={{input: styles.placeholder}}
              />
            </InfoRow>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            {" "}
            <InfoRow
              label="Кто добавил"
              aspect={{label: 2, content: 3}}
              classNames={{root: styles.row}}
              labelFont="ubuntu"
            >
              <div>
                {creatorName}
                &emsp;&emsp;
                {today}
              </div>
            </InfoRow>
          </Col>
        </Row>
      </Container>

      <div className={styles.actions}>
        <Button variant="text" onClick={onCancel}>
          Отменить
        </Button>

        <Button type="submit" classNames={{root: styles.submitBtn}}>
          Сохранить
        </Button>
      </div>
    </form>
  );
};
