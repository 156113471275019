import { useMutation } from "react-query";
import { api } from "../../index";

const DEFAULT_MN = "registration/sdelkaPro/DEFAULT_MN";

export const useJoinToSdelkaPro = () => {
  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: (payload?: any) =>
      api.auth.joinTosdelkaPro(payload),
  });

  return {
    data: mutation.data,
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
    mutation,
    asyncMutate: mutation.mutateAsync,
  };
};
