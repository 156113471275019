import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form";

import styles from "./index.module.scss";
import "./index.scss";

type Props = {
	required?: boolean;
	getValues: UseFormGetValues<any>;
	name: string;
	register: UseFormRegister<any>;
	setValue: UseFormSetValue<any>;
	curValue?: string;
	error?: string;
	setEditMode?: () => void
	onChange?: (value) => void
	asPlain?: boolean
};


const config = {
	toolbar: ["bold", "|", "numberedList", "bulletedList"],
};

const configPlain = {
 toolbar: []
}

export const MarkdownEditor = ({
	curValue = "",
	name,
	register,
	required = false,
	setValue,
	getValues,
	error,
	setEditMode,
	onChange,
	asPlain
}: Props): JSX.Element => {
	const value = curValue || getValues(name);

	const registerProp = register(name, {
		required,
		value: getValues(name),
	});

	return (
		<>
			<input type="hidden" {...registerProp} />
			<CKEditor
				editor={ClassicEditor}
				config={asPlain ? configPlain : config}
				data={value}
				onChange={(_: any, editor: any) => {
					setValue(name, editor.getData())
					if (setEditMode) {
						setEditMode()
					}
					if (onChange) {
						onChange(editor.getData())
					}
				}}
			/>
			{error && <div className={styles.error}>{error}</div>}
		</>
	);
};
