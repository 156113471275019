import { Dispatch, SetStateAction } from "react";
import { ApplicationFilterPayload, LeadStatusEnum } from "../../../../../../types";

import { leadButtons, StageButtons } from "./helpers";

type Props = {
	filter: ApplicationFilterPayload;
	setFilter: Dispatch<SetStateAction<ApplicationFilterPayload>>;
	handleFilterClick: (status: LeadStatusEnum, fieldName: string) => void;
};

export const LeadStatus = ({ filter, setFilter, handleFilterClick }: Props): JSX.Element => {
	const filterClick = (val: any) => handleFilterClick(val, "status");

	const buttons = leadButtons(filter as { status?: LeadStatusEnum[] }, setFilter, filterClick);

	return <StageButtons title="Статус" buttons={buttons} />;
};
