import { Dispatch, SetStateAction, useEffect } from "react";

import { useQueryParams } from "../../../../../utils/hooks";
import { parseStringEncodedRealEstateFilter } from "../../../../../utils/url";
import { AdStatusEnum, RealEstateFilter } from "../../../../../types";

type QueryParams = {
	page: number;
	filter: string;
};

type Props = {
	filter: RealEstateFilter;
	setFilter: Dispatch<SetStateAction<RealEstateFilter>>;
	setCurrentStatus: Dispatch<SetStateAction<AdStatusEnum[]>>;
	setPage: Dispatch<SetStateAction<number>>;
};

type Return = {
	changeStatus: (newStatus: AdStatusEnum) => void;
	handlePageChange: (nextPage: number) => void;
	changeFilter: (payload: RealEstateFilter) => void;
};

type UseFilterAction = (props: Props) => Return;

export const useFilterAction: UseFilterAction = ({ setFilter, filter, setCurrentStatus, setPage }) => {
	const { queryParams, changeQueryParams } = useQueryParams<QueryParams>([
		{ name: "page", type: "number" },
		{ name: "filter" },
	]);

	const handlePageChange = (nextPage: number) => {
		changeQueryParams([{ name: "page", newValue: nextPage }]);

		setPage(nextPage);
	};

	const changeFilter = (payload: RealEstateFilter) => {
		const encodedBackPath = encodeURIComponent(JSON.stringify(payload));

		changeQueryParams([{ name: "filter", newValue: encodedBackPath, isEncode: true }]);

		setFilter(payload);
	};

	const changeStatus = (newStatus: AdStatusEnum) => {
		changeFilter({
			...filter,
			...{
				feedAds: [{
					...filter.feedAds,
					status: newStatus,
				}],
			},
		});

		setPage(1);
		setCurrentStatus([newStatus]);
	};

	useEffect(() => {
		if (queryParams?.page) {
			setPage(Number(queryParams?.page || 1));
		}

		if (queryParams?.filter) {
			const newFilter = parseStringEncodedRealEstateFilter(queryParams.filter);

			if (newFilter?.feedAds?.[0] && newFilter?.feedAds?.[0].status) {
				setCurrentStatus(newFilter?.feedAds?.[0].status);
			}

			setTimeout(() => {
				setFilter({ ...newFilter });
			}, 500);
		}
	}, [queryParams.page, queryParams?.filter]);

	return {
		changeStatus,
		handlePageChange,
		changeFilter,
	};
};
