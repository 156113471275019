import styles from "./index.module.scss";
import { RealEstateMap, SidebarLayout } from "../../UI";
import { Cart } from "./cart";
import { useRef, useState } from "react";
import { useQueryParams } from "../../../utils/hooks";
import cn from "classnames";
import { ServicesTabs } from "../../UI/services-tabs/ServicesTabs";
import { NoData } from "@sdelka_crm/sdelka-crm-component-library";
import { useServices } from "./helpers/useServices";
import { UsersTable } from "./tables";
import { OfficesTable } from "./tables/offices-table";
import { selectCurrentAccount, selectDevice, useSelect } from "../../../service/redux/selectors";
import { ProductsTable } from "./tables/products-table";
import { useLoadCart } from "../../../service/api/queries";
import { OrdersTable } from "./tables/orders-table";
import { ReactComponent as IconCart } from "../../../assets/icons/Shopping_Cart.svg";
import { useOpenCart } from "../../../service/servise";

enum TabTypeEnum {
  PRODUCTS = "PRODUCTS",
  OFFICES = "OFFICES",
  EMPLOYERS = "EMPLOYERS",
  ORDERS = "ORDERS",
}

type QueryParams = {
  officeId: string;
  productId: string;
  employeeId: string;
  page: number;
  filter: TabTypeEnum;
  tab: TabTypeEnum;
};

export const Services = (): React.ReactElement => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const {queryParams} = useQueryParams<QueryParams>([
    {name: "officeId"},
    {name: "productId"},
    {name: "orderId"},
    {name: "page", type: "number"},
    {name: "filter"},
    {name: "employeeId", type: "string"},
    {name: "tab", type: "string"},
  ]);

  const currentTab = queryParams.tab ?? TabTypeEnum.ORDERS;
  const {isMobile} = useSelect(selectDevice)

  const {
    isLoading,
    list,
    take,
    page,
    setPage,
    total,
    refetch,
    isFetching
  } = useServices({tab: currentTab, defaultPage: 1})

  const [centerXY] = useState<[number | typeof NaN, number | typeof NaN]>([NaN, NaN]);
  const {currentAccount} = useSelect(selectCurrentAccount);
  const isCoWorking = currentAccount && currentAccount.position?.name === "Агент в коворкинге"
  const isBoss = currentAccount && currentAccount.position?.name === "Руководитель компании"
  const mapZoom = Number(currentAccount?.company?.mapZoom);
  const mapCenter = currentAccount?.company?.mapCenter?.split(",")?.map((cord) => Number(cord)) as [
    number,
    number
  ];
  const getDefaultCenter = (initialCenter?: [number, number]): [number, number] => {
    if (!initialCenter || !initialCenter[ 0 ] || !initialCenter[ 1 ]) {
      return [59.94506601286178, 30.31705281156423];
    }
    return [initialCenter[ 0 ], initialCenter[ 1 ]];
  };

  const pointsForMap = list ? list.map(elem => JSON.parse(JSON.stringify({
    objects: [
      {
        id: "",
        price: 0,
        pricePreUnit: 0,
        unut: elem.addressLabel,
      }
    ],
    lat: elem.location?.coordinates?.[ 0 ] ?? 59.94506601286178,
    lon: elem.location?.coordinates?.[ 1 ] ?? 30.31705281156423,
  }))) : []

  const {refetch: refetchCart, cart} = useLoadCart()
  const handleRefetch = () => {
    refetch()
    refetchCart()
  }

  const {openCart} = useOpenCart()

  const handleOpenCart = () => {
    if (isMobile) {
      openCart({
        refetch: refetchCart,
        refetchTable: refetch,
        cart,
        isCoWorking
      })
    }
  }

  return (
    <>
      {isMobile &&
        <div className={styles.cart} onClick={handleOpenCart}>
          <IconCart/>
        </div>
      }
      <SidebarLayout
        services
        fullSize
        isMobile={isMobile}
        hat={!isMobile ? {
          element: <></>,
          elementHeight: 0,
        } : {element: <></>, elementHeight: 0}}
        rightColumn={
          !isMobile &&
          <Cart
            refetchTable={refetch}
            cart={cart}
            refetch={refetchCart}
            isCoWorking={isCoWorking}
          />
        }
        withScrollBtn
      >
        <>

          <div className={styles.root}>

            <div ref={wrapperRef} className={styles.listWrapper}>
              <ServicesTabs isCoWorking={isCoWorking} isBoss={isBoss}/>
              <div
                className={cn(styles.list, {
                  // [styles.listWithNoFilters]: !objectsFilterTypes?.length,
                })}
              >

                {!isCoWorking && isBoss &&
                  <>
                    {currentTab === "EMPLOYERS" &&
                      <div className={styles.tabContent}>
                        <div className={styles.tableWithPagination}>
                          {!isLoading && !list
                            ? <NoData onRefetch={refetch}/>
                            : <>
                              <UsersTable
                                usersList={list}
                                isLoading={isFetching}
                                refetch={handleRefetch}
                                isMobile={isMobile}
                                refetchCart={refetchCart}
                              />
                              {/*{!isFetching && total !== 0 && (*/}
                              {/*  <Pagination*/}
                              {/*    classNames={{root: styles.pagination}}*/}
                              {/*    totalCount={total || 0}*/}
                              {/*    pageSize={take}*/}
                              {/*    currentPage={page || 0}*/}
                              {/*    onPageChange={(val: number) => setPage ? setPage(val) : () => {*/}
                              {/*    }}*/}
                              {/*    isLoading={isFetching}*/}
                              {/*  />*/}
                              {/*)}*/}
                            </>
                          }
                        </div>
                      </div>
                    }
                    {currentTab === "OFFICES" &&
                      <div className={styles.tabContent}>
                        <div className={styles.tableWithPagination}>
                          {!isLoading && !list
                            ? <NoData onRefetch={refetch}/>
                            : <>
                              <OfficesTable
                                officesList={list}
                                isLoading={isFetching}
                                refetch={refetch}
                                isMobile={isMobile}
                              />
                              {/*{!isFetching && total !== 0 && (*/}
                              {/*  <Pagination*/}
                              {/*    classNames={{root: styles.pagination}}*/}
                              {/*    totalCount={total || 0}*/}
                              {/*    pageSize={take}*/}
                              {/*    currentPage={page || 0}*/}
                              {/*    onPageChange={(val: number) => setPage ? setPage(val) : () => {*/}
                              {/*    }}*/}
                              {/*    isLoading={isFetching}*/}
                              {/*  />*/}
                              {/*)}*/}
                            </>
                          }
                        </div>
                      </div>
                    }
                  </>
                }
                {currentTab === "PRODUCTS" &&
                  <div className={styles.tabContent}>
                    <div className={styles.tableWithPagination}>
                      {!isLoading && !list
                        ? <NoData onRefetch={refetch}/>
                        : <>
                          <ProductsTable
                            productsList={list}
                            isLoading={isFetching}
                            refetch={handleRefetch}
                            isMobile={isMobile}
                            refetchCart={refetchCart}
                          />
                          {/*{!isFetching && total !== 0 && (*/}
                          {/*  <Pagination*/}
                          {/*    classNames={{root: styles.pagination}}*/}
                          {/*    totalCount={total || 0}*/}
                          {/*    pageSize={take}*/}
                          {/*    currentPage={page || 0}*/}
                          {/*    onPageChange={(val: number) => setPage ? setPage(val) : () => {*/}
                          {/*    }}*/}
                          {/*    isLoading={isFetching}*/}
                          {/*  />*/}
                          {/*)}*/}
                        </>
                      }
                    </div>
                  </div>
                }
                {currentTab === "ORDERS" &&
                  <div className={styles.tabContent}>
                    <div className={styles.tableWithPagination}>
                      {!isLoading && !list
                        ? <NoData onRefetch={refetch}/>
                        : <>
                          <OrdersTable
                            productsList={list}
                            isLoading={isFetching}
                            refetch={handleRefetch}
                            isMobile={isMobile}
                          />
                          {/*{!isFetching && total !== 0 &&*/}
                          {/*  <Pagination*/}
                          {/*    classNames={{root: styles.pagination}}*/}
                          {/*    totalCount={total || 0}*/}
                          {/*    pageSize={take}*/}
                          {/*    currentPage={page || 0}*/}
                          {/*    onPageChange={(val: number) => setPage ? setPage(val) : () => {*/}
                          {/*    }}*/}
                          {/*    isLoading={isFetching}*/}
                          {/*  />}*/}
                        </>
                      }
                    </div>
                  </div>
                }
              </div>
            </div>
            {currentTab === "OFFICES" && !isMobile &&
              <div className={styles.map}>
                <RealEstateMap
                  points={pointsForMap}
                  priceTypeAlternative="office"
                  options={{
                    order: "map-first",
                    center: mapCenter ?? getDefaultCenter(centerXY),
                    zoom: mapZoom,
                    updateCenterIfChanged: true,
                  }}
                />
              </div>}
          </div>
        </>

      </SidebarLayout>
    </>

  )
}