import styles from "../index.module.scss"
import { useLoadBuyHistory } from "../../../../../../service/api/queries";
import { formatDateWithTime } from "../../../../../../utils/date";

export const BuyHistory = () => {

  const {buyHistoryItems} = useLoadBuyHistory({})

  return (
    <>
      <div className={styles.title} style={{marginTop: "60px"}}>
        <div>
          История покупок
        </div>
      </div>
      {buyHistoryItems.length > 0 ?
        <div className={styles.history}>
          <div className={styles.historyHeader}>
            <div className={styles.elements}>
              <div className={styles.element}>Товар</div>
              <div className={styles.element}>Тариф</div>
              <div className={styles.element}>Дата</div>
              <div className={styles.element}>Цена</div>
            </div>
          </div>
          <div className={styles.historyContent}>
            {buyHistoryItems && buyHistoryItems.map(item => (
              <div className={styles.historyItem} key={item.id}>
                <div>{item.productName}</div>
                <div>{item.tariff}</div>
                <div className={styles.historyItemDate}>{formatDateWithTime(item.purchaseDate, true, false)}</div>
                <div>{item.price}</div>
              </div>
            ))}
          </div>
        </div>
        : <div className={styles.plug}>
          Покупок не найдено
        </div>
      }
    </>
  )
}