import { useQuery } from "react-query";

import { api } from "../../index";

// Load user public link
export const useLoadPublicLink = () => {
	const loadPublicLink = () => api.invites.getPublicLink();

	const { data, isLoading, refetch } = useQuery("loadPublicLink", loadPublicLink);

	return {
		publicLink: data?.data,
		isLoading,
		refetch,
	};
};
