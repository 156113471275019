import { useLoadProduct } from "../../../service/api/queries";
import styles from "./index.module.scss"
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useAddInCartModal } from "../Servises/cart/helpers/addInCartModal/useAddInCartModal";
import { ProductTypeEnum } from "../../../types";
import { useAddToCart } from "../../../service/api/mutations";
import { selectDevice, useSelect } from "../../../service/redux/selectors";
import { LayoutLoading } from "../RealEstatePage/mode/RealEstateViewWithTabs/LayoutLoading";

type Props = {
  productId: string
  refetch?: () => void
}

export const ProductCard = ({productId, refetch}: Props) => {
  const {isMobile} = useSelect(selectDevice)
  const {product, refetch: refetchProduct} = useLoadProduct({
    productId
  })
  const refetchAll = () => {
    refetch?.()
    refetchProduct()
  }

  const {mutate} = useAddToCart({
    onSuccess: () => {
      refetchAll()
    }
  })

  const {openAddInCartModal} = useAddInCartModal({
    type: ProductTypeEnum.service,
    title: "Выберите услугу",
    action: mutate,
    tariffOptions: product?.tariffOptions,
    tariffLabel: product?.tariffLabel,
    isMobile
  })

  return (
    <>
      {product
        ? <div className={styles.root}>
          <div className={styles.content}>
            <div className={styles.title}>
              {product?.title}
            </div>
            {/* eslint-disable-next-line react/no-danger */}
            {product?.description &&
              <div className={styles.description} dangerouslySetInnerHTML={{__html: product?.description}} />}
          </div>
          <div className={styles.footer}>
            <Button
              onClick={() => openAddInCartModal([productId ?? ""])}
            >
              Добавить в корзину
            </Button>
          </div>
        </div>
        : <LayoutLoading/>
      }
    </>
  )
}