import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, InfoRow } from "@sdelka_crm/sdelka-crm-component-library";
import { openDrawer } from "service/redux/modules/drawer/drawer.action";
import { Container, TransparentInput } from "../../../../UI";
import { TermsBlock } from "../../components";
import { validationPersonalDataFormSchema } from "../formResolvers";
import { setStep } from "../../../../../service/redux/modules/registration-steps/reg-steps.action";
import { paths } from "../../../../../utils/paths";
import { PersonalDataFormType } from "../../../../../types";
import styles from "./index.module.scss";
import { useCheckDataForm } from "service/api/mutations/auth/useCheckDataForm";
import { selectRegisterSteps, useSelect } from "service/redux/selectors";
import { TokenService } from "service/api/services/inner/TokenService";
import Cookies from "js-cookie";
import { AccessTokenCurrentUser } from "service/api/Client";
import { ReactComponent as PasswordIcon } from "../../../../../assets/icons/eye-closed 1.svg";
import { ReactComponent as PasswordIconOpen } from "../../../../../assets/icons/eye.svg";
import { FinishRegistrationWrapper } from "../FinishRegistration/wrapper";
import { useQueryParams } from "../../../../../utils/hooks";

export const PersonalDataForm = (): JSX.Element => {
  const {verificationCode} = useSelect(selectRegisterSteps);
  const [showPassword, setShowPassword] = useState(false);
  const firstStepLabel = "first-step"
  const dispatch = useDispatch();
  const user = useSelect(selectRegisterSteps);
  const history = useHistory()
  const {
    queryParams,
    removeQueryParam
  } = useQueryParams<{ promoCode: string, selectedOfficeId: string }>([{name: "promoCode"}, {
    name: "selectedOfficeId",
    type: "string"
  }])

  useEffect(() => {
    if (!user) {
      setStep(firstStepLabel)
    }
    if (!verificationCode) {
      setStep(firstStepLabel)
    }
  }, [user])

  const {control, handleSubmit, formState, watch, register, setValue,} = useForm({
    resolver: yupResolver(validationPersonalDataFormSchema),
    defaultValues: {
      password: "",
      name: "",
      lastName: "",
      promoCode: queryParams.promoCode
    },
  });
  const [passwordWatcher, nameWatcher, lastNameWatcher, promoCodeWatcher] = [
    useWatch({
      control,
      name: "password",
      defaultValue: "",
    }),
    useWatch({
      control,
      name: "name",
      defaultValue: "",
    }),
    useWatch({
      control,
      name: "lastName",
      defaultValue: "",
    }),
    useWatch({
      control,
      name: "promoCode",
      defaultValue: queryParams.promoCode,
    }),
  ];

  const openRegDrawer = () => {
    removeQueryParam(["promoCode", "selectedOfficeId"])
    history.push("/real-estate?page=1")
    dispatch(
      openDrawer({
        children: <FinishRegistrationWrapper/>,
        width: 800,
      })
    );
  };

  const passwordRules = {
    minLength: watch("password")?.length >= 6,
    hasUpperCase: /[A-Z]/.test(watch("password")),
    hasLowerCase: /[a-z]/.test(watch("password")),
    hasDigit: /\d/.test(watch("password")),
  };

  const onRegistrationSuccess = () => {
  };
  const onErrorSuccess = () => {
    if (errorCode === "RE003" && token) {
      Cookies.set(AccessTokenCurrentUser, token)
      dispatch(setStep("finish-registration"))
    }
  };
  const {mutate, getLastMutationCache, isLoading} = useCheckDataForm({
    onSuccess: onRegistrationSuccess,
    setError: onErrorSuccess,
  });

  const lastMutationCache = getLastMutationCache() as any
  const token = TokenService.getCurrentToken()

  useEffect(() => {
    if (!token) {
      setStep(firstStepLabel)
    }
  }, [token])
  const errorCode = lastMutationCache?.state?.data?.data?.error?.errorCode
  const newToken = lastMutationCache?.state?.data?.data?.error?.payload?.token
  const onSubmit = async (values: Omit<PersonalDataFormType, "token" | "code">) => {

    if (token) {
      mutate({
          ...values,
          token,
          code: verificationCode || "",
          selectedOfficeId: queryParams.selectedOfficeId
        }
      )
    }
    if (token && errorCode === "RE003") {
      openRegDrawer()
    }
  };

  useEffect(() => {
    if (newToken) {
      TokenService.setCurrentToken(newToken)
      Cookies.set(AccessTokenCurrentUser, newToken)
    }
    if (token && errorCode === "RE003") {
      openRegDrawer()
    }
  }, [lastMutationCache, errorCode, token, newToken])

  return (
    <div className={styles.root}>
      <h1>Завершение регистрации</h1>
      <p>Заполните персональные данные</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.fieldsContainer}>
          <InfoRow label="Имя" classNames={{root: styles.row}}>
            <TransparentInput
              placeholder="Указать"
              withCleanUp={{setValue}}
              name="name"
              register={register}
              error={formState?.errors?.name?.message?.toString()}
              value={nameWatcher}
            />
          </InfoRow>
          <InfoRow label="Фамилия" classNames={{root: styles.row}}>
            <TransparentInput
              placeholder="Указать"
              withCleanUp={{setValue}}
              name="lastName"
              register={register}
              error={formState?.errors?.lastName?.message?.toString()}
              value={lastNameWatcher}
              autoComplete="new-password"
            />
          </InfoRow>
          <InfoRow label="Промокод" classNames={{root: styles.row}}>
            <TransparentInput
              placeholder="Указать"
              withCleanUp={{setValue}}
              name="promoCode"
              register={register}
              error={formState?.errors?.promoCode?.message?.toString()}
              value={promoCodeWatcher}
            />
          </InfoRow>
          <InfoRow label="Пароль" classNames={{root: styles.passwordRow}}>
            <TransparentInput
              placeholder="Указать"
              withCleanUp={{setValue}}
              name="password"
              type={showPassword ? "text" : "password"}
              register={register}
              value={passwordWatcher}
              autoComplete="new-password"
            />
            {showPassword ?
              <PasswordIconOpen fill="#AFAFAF" onClick={() => setShowPassword(prev => !prev)}
                                className={styles.passwordIcon}/>
              : <PasswordIcon onClick={() => setShowPassword(prev => !prev)} className={styles.passwordIcon}/>
            }
          </InfoRow>
          <Container>
            <ul className={styles.passwordRulesContainer}>
              <li className={`
									${styles.passwordRule} 
									${passwordRules.minLength ? styles.green : ` ${styles.gray}`}
									${formState?.errors?.password && !passwordRules.minLength ? styles.error : ""}
								`}>
                Длина пароля минимум 6 символов
              </li>
              <li className={`
										${styles.passwordRule} 
										${passwordRules.hasUpperCase && passwordRules.hasLowerCase ? styles.green : ` ${styles.gray}
										${formState?.errors?.password && !passwordRules.hasUpperCase && !passwordRules.hasLowerCase ? styles.error : ""}
								`}
								`}>
                Должен содержать латинские буквы верхнего и нижнего регистров
              </li>
              <li className={`
									${styles.passwordRule} 
									${passwordRules.hasDigit ? styles.green : ` ${styles.gray}`}
									${formState?.errors?.password && !passwordRules.hasDigit && !passwordRules.hasDigit ? styles.error : ""}
								`}>
                Должен содержать хотя бы одну цифру
              </li>
            </ul>
          </Container>
        </div>
        <div className={styles.submitContainer}>
          <Button type="submit" isLoading={isLoading}>Создать аккаунт</Button>
          <div className={styles.loginLinkContainer}>
            <Link className={styles.link} to={paths.loginPath}>
              Отменить регистрацию
            </Link>
          </div>
        </div>
        <TermsBlock/>
      </form>
    </div>
  );
};
