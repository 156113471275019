import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import cn from "classnames";

import { Position } from "../../../../../../../types";
import { IndividualInput } from "../../../../../../UI";
import { useDeletePositionModal } from "../../../../helpers";
import { usePositionUpdate } from "../../../../../../../service/api/mutations";
import { commonErrors } from "../../../../../../../utils/errors";

import { ReactComponent as TrashIcon } from "../../../../../../../assets/icons/trash.svg";
import styles from "./index.module.scss";

type Props = {
	refetch: () => void;
	position: Position;
	activePosition: string;
	setActivePosition: (id: string) => void;
};

export const PositionForm = ({
	activePosition,
	setActivePosition,
	refetch,
	position,
}: Props): JSX.Element => {
	const [closeEditMode, setCloseEditMode] = useState(false);

	const { mutate, isLoading } = usePositionUpdate({
		onSuccess: () => {
			setCloseEditMode(true);
			refetch();
		},
	});

	const schema = yup.object().shape({
		name: yup.string().required(commonErrors.required).max(30, "Слишком длинное название"),
	});

	const { register, handleSubmit, reset, formState } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			name: position.name,
		},
	});

	useEffect(() => {
		reset({ name: position.name });
	}, [position]);

	const onSubmit = (values: { name: string }) => {
		setCloseEditMode(false);
		mutate({ id: position.id, name: values.name });
	};

	const { showConfirmModal } = useDeletePositionModal({ refetch });

	return (
		<div className={styles.root} onClick={() => setActivePosition(position.id)}>
			<div className={styles.delete} onClick={() => showConfirmModal(position.id)}>
				<TrashIcon />
			</div>

			<form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
				<IndividualInput
					name="name"
					defaultValue={position.name}
					isLoading={isLoading}
					register={register}
					error={formState.errors.name?.message?.toString()}
					options={{
						closeEditMode,
						displayInsteadOfValue: (
							<div
								className={cn(styles.position, {
									[styles.selectedPosition]: activePosition === position.id,
								})}
							>
								{position.name}
							</div>
						),
					}}
				/>
			</form>
		</div>
	);
};
