import { useState } from "react";
import { Loader } from "@sdelka_crm/sdelka-crm-component-library";

import { IndividualCheckbox } from "../../../index";
import { useApplicationRealEstateUpdate } from "../../../../../service/api/mutations";

import styles from "./index.module.scss";

type Props = {
	applicationId: string;
	switcherValue: boolean;
	refetch?: () => void;
};

export const WatermarkSwitcher = ({ applicationId, switcherValue, refetch }: Props): JSX.Element => {
	const [withWatermark, setWithWatermark] = useState(switcherValue || false);

	const { mutate, isLoading } = useApplicationRealEstateUpdate({
		onSuccess: () => {
			if (refetch) {
				refetch();
			}
		},
	});

	const handleOnChange = () => {
		const payload = {
			id: applicationId,
			data: {
				additionalProperties: {
					watermarkEnabled: !withWatermark,
				},
			},
		};

		mutate(payload);
		setWithWatermark((prev) => !prev);
	};

	return (
		<div className={styles.root}>
			<IndividualCheckbox
				label="вкл. водные знаки на фото"
				labelTextClassName={styles.checkbox}
				onChange={handleOnChange}
				checked={withWatermark}
			/>

			{isLoading && (
				<div className={styles.loaderContainer}>
					<Loader height={16} />
				</div>
			)}
		</div>
	);
};
