import { Dispatch, SetStateAction, useEffect } from "react";
import { useForm } from "react-hook-form";
import styles from "./index.module.scss";
import { RealEstateSort } from "../../../../../../../types";
import { useQueryParams } from "../../../../../../../utils/hooks";
import { Select } from "../../../../../../UI";

type Props = {
  total: number;
  setTake: Dispatch<SetStateAction<number>>;
  setSort: Dispatch<SetStateAction<RealEstateSort | undefined>>;
  isLoading: boolean;
  isMobile?: boolean
};

export const AdListHeader = (
  {
    setTake,
    setSort,
    isLoading,
    total,
    isMobile
  }: Props): JSX.Element => {
  const {setValue, register} = useForm();
  const {queryParams, changeQueryParams} = useQueryParams<{
    compilation: boolean;
    pageSize: string;
    sort: string;
  }>([{name: "compilation", type: "boolean"}, {name: "pageSize"}, {name: "sort"}]);

  const priceOptions = [
    {label: "Сначала новые", value: "createdAt DESC"},
    {label: "Сначала дешевые", value: "price ASC"},
    {label: "Сначала дорогие", value: "price DESC"},
    {label: "Сначала старые", value: "createdAt ASC"},
  ];

  const handleChangeSort = (value: string) => {
    const splited = value.split(" ") as ["price" | "createdAt", "ASC" | "DESC"];

    setSort({field: splited[ 0 ], sort: splited[ 1 ]});
    changeQueryParams([{name: "sort", newValue: value}]);
  };

  useEffect(() => {
    if (queryParams.pageSize) {
      setTake(Number(queryParams.pageSize));
    }
  }, [queryParams.pageSize]);

  useEffect(() => {
    if (queryParams.sort) {
      const decodedSort = decodeURIComponent(queryParams.sort).split(" ") as [
          "price" | "createdAt",
          "ASC" | "DESC"
      ];
      setSort({field: decodedSort[ 0 ], sort: decodedSort[ 1 ]});
    }
  }, [queryParams.sort]);

  return (
    <div className={styles.root}>
      <form className={styles.objectManipulations}>
        {/* <div className={styles.selectActionsContainer}> */}
        {/*	/!* <IndividualCheckbox *!/ */}
        {/*	/!*  onChange={selectAll} *!/ */}
        {/*	/!*  checked={isSelectedAll} *!/ */}
        {/*	/!*  label="Выделить все" *!/ */}
        {/*	/!*  disabled={isLoading || queryParams.compilation} *!/ */}
        {/*	/!* /> *!/ */}

        {/*	/!* <SelectedActions selectedItemsIds={selectedItemsIds} /> *!/ */}

        {/*	/!* {isLoading ? ( *!/ */}
        {/*	/!*  <div className={styles.skeleton} /> *!/ */}
        {/*	/!* ) : ( *!/ */}
        {/*	/!*  <div className={styles.totalAmount}> *!/ */}
        {/*	/!*    {`(${selectedItemsIds.length}/${total})`} *!/ */}
        {/*	/!*  </div> *!/ */}
        {/*	/!* )} *!/ */}
        {/* </div> */}
        <div className={styles.total}>
          {`${total} объектов`}
        </div>

        {!isMobile && <div className={styles.objectDisplay}>
          {/* <Select */}
          {/*  name="size" */}
          {/*  defaultValue={take.toString()} */}
          {/*  onChange={handleChangeTake} */}
          {/*  options={sizeOptions} */}
          {/*  register={register} */}
          {/*  setValue={setValue} */}
          {/*  size="small" */}
          {/*  classNames={{root: styles.select}} */}
          {/*  disabled={isLoading} */}
          {/* /> */}

          <Select
            name="price"
            defaultValue="createdAt DESC"
            onChange={handleChangeSort}
            options={priceOptions}
            register={register}
            setValue={setValue}
            size="small"
            disabled={isLoading}
          />
        </div>}
      </form>
    </div>
  );
};
