import { UseFormRegister, UseFormSetValue, useWatch, Control } from "react-hook-form";
import { createNumberMask } from "text-mask-addons";

import { DoubleInput, InfoRow, MultiCheckbox } from "../../../../index";
import { RealEstateFilterField } from "../../../../../../types";

type Props = {
	field: RealEstateFilterField;
	setValue: UseFormSetValue<any>;
	register: UseFormRegister<any>;
	options: { label: string; value: string }[];
	control: Control<any>;
	disabled?: boolean;
};

export const Floor = ({ field, register, setValue, options, control, disabled }: Props): JSX.Element => {
	const floorFromWatcher = useWatch({
		control,
		name: "floorFrom",
	});

	const floorToWatcher = useWatch({
		control,
		name: "floorTo",
	});

	const floorWishesWatcher = useWatch({
		control,
		name: "floor_wishes",
	});

	const optionWithDisable = options.map(({ label, value }) => ({
		label,
		value,
		disabled,
	}));

	return (
		<InfoRow aspect={{ label: 8, content: 7.3 }} label={field.label}>
			{field.label === "Этаж" && (
				<DoubleInput
					register={register}
					names={["floorFrom", "floorTo"]}
					value={[floorFromWatcher, floorToWatcher]}
					placeholders={["000", "000"]}
					masks={[
						createNumberMask({
							prefix: "",
							integerLimit: 3,
						}),
						createNumberMask({
							prefix: "",
							integerLimit: 3,
						}),
					]}
					variant="noPadding"
					setValue={setValue}
					textAlign="right"
					separator={field.dataType.splitter?.length ? field.dataType.splitter : undefined}
					disabled={disabled}
				/>
			)}
			{field.label === "Пожелания этажа" && (
				<MultiCheckbox
					name="floor_wishes"
					register={register}
					setValue={setValue}
					options={optionWithDisable}
					direction="column"
					defaultValue={floorWishesWatcher}
				/>
			)}
		</InfoRow>
	);
};
