import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/real-estate-ad/load-platform-descriptions/DEFAULT_QN";

type UseLoadPlatformDescription = {
	applicationId: string;
	feedId: string;
};

export const useLoadPlatformDescription = ({ applicationId, feedId }: UseLoadPlatformDescription) => {
	const loadDescription = () => api.realEstateAd.platformDescription({ applicationId, feedId });

	const { data, refetch, remove, isLoading, isFetched, isError } = useQuery(DEFAULT_QN, loadDescription, {
		enabled: false,
	});

	return {
		data: data?.data,
		isLoading,
		refetch,
		remove,
		isFetched,
		isError,
	};
};
